/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SetLinksForGlimpseRequest
 */
export interface SetLinksForGlimpseRequest {
  /**
   *
   * @type {string}
   * @memberof SetLinksForGlimpseRequest
   */
  glimpse: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SetLinksForGlimpseRequest
   */
  links: Array<string>;
}

export function SetLinksForGlimpseRequestFromJSON(
  json: any
): SetLinksForGlimpseRequest {
  return SetLinksForGlimpseRequestFromJSONTyped(json, false);
}

export function SetLinksForGlimpseRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SetLinksForGlimpseRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    glimpse: json["glimpse"],
    links: json["links"],
  };
}

export function SetLinksForGlimpseRequestToJSON(
  value?: SetLinksForGlimpseRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    glimpse: value.glimpse,
    links: value.links,
  };
}
