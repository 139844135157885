import React from "react";
import { classNames } from "../../util/strings";

type MNotifBubbleProps = {
  count: number;
};

const MNotifBubbleComponent = (props: MNotifBubbleProps) => {
  const { count } = props;

  const countContent = count > 9 ? "9+" : count;

  return (
    <div
      className={classNames(
        "absolute top-0 left-0 -translate-y-1/2 -translate-x-1/2",
        "bg-primary text-m-white rounded-full h-5 w-auto flex",
        "flex-col justify-center items-center font-semibold text-xs px-2"
      )}
    >
      {countContent}
    </div>
  );
};

export default MNotifBubbleComponent;
