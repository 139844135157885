/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface EitherOr
 */
export interface EitherOr {
  /**
   *
   * @type {string}
   * @memberof EitherOr
   */
  question: string;
  /**
   *
   * @type {string}
   * @memberof EitherOr
   */
  statement: string;
  /**
   *
   * @type {Array<string>}
   * @memberof EitherOr
   */
  tags?: Array<EitherOrTagsEnum>;
  /**
   *
   * @type {Array<Array<string>>}
   * @memberof EitherOr
   */
  choices: Array<Array<string>>;
}

/**
 * @export
 * @enum {string}
 */
export enum EitherOrTagsEnum {
  deep = "deep",
  weird = "weird",
  awkward = "awkward",
  would_you_rather = "would you rather",
  practicals = "practicals",
  daily = "daily",
  other = "other",
}

export function EitherOrFromJSON(json: any): EitherOr {
  return EitherOrFromJSONTyped(json, false);
}

export function EitherOrFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EitherOr {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    question: json["question"],
    statement: json["statement"],
    tags: !exists(json, "tags") ? undefined : json["tags"],
    choices: json["choices"],
  };
}

export function EitherOrToJSON(value?: EitherOr | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    question: value.question,
    statement: value.statement,
    tags: value.tags,
    choices: value.choices,
  };
}
