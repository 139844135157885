/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  UserProfileSummary,
  UserProfileSummaryFromJSON,
  UserProfileSummaryFromJSONTyped,
  UserProfileSummaryToJSON,
} from "./UserProfileSummary";

/**
 *
 * @export
 * @interface UserProfileAccessRelatedDetail
 */
export interface UserProfileAccessRelatedDetail {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof UserProfileAccessRelatedDetail
   */
  readonly guid?: string;
  /**
   * The type of access being granted.
   * @type {string}
   * @memberof UserProfileAccessRelatedDetail
   */
  access_type?: UserProfileAccessRelatedDetailAccessTypeEnum;
  /**
   * The email address being granted access to the profile.
   * @type {string}
   * @memberof UserProfileAccessRelatedDetail
   */
  email?: string | null;
  /**
   * Whether the referenced party is allowed to view the profile.
   * @type {boolean}
   * @memberof UserProfileAccessRelatedDetail
   */
  is_allowed?: boolean;
  /**
   *
   * @type {UserProfileSummary}
   * @memberof UserProfileAccessRelatedDetail
   */
  other_user?: UserProfileSummary;
}

/**
 * @export
 * @enum {string}
 */
export enum UserProfileAccessRelatedDetailAccessTypeEnum {
  user = "user",
  email = "email",
}

export function UserProfileAccessRelatedDetailFromJSON(
  json: any
): UserProfileAccessRelatedDetail {
  return UserProfileAccessRelatedDetailFromJSONTyped(json, false);
}

export function UserProfileAccessRelatedDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserProfileAccessRelatedDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    access_type: !exists(json, "access_type") ? undefined : json["access_type"],
    email: !exists(json, "email") ? undefined : json["email"],
    is_allowed: !exists(json, "is_allowed") ? undefined : json["is_allowed"],
    other_user: !exists(json, "other_user")
      ? undefined
      : UserProfileSummaryFromJSON(json["other_user"]),
  };
}

export function UserProfileAccessRelatedDetailToJSON(
  value?: UserProfileAccessRelatedDetail | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    access_type: value.access_type,
    email: value.email,
    is_allowed: value.is_allowed,
    other_user: UserProfileSummaryToJSON(value.other_user),
  };
}
