/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  GroupMembers,
  GroupMembersFromJSON,
  GroupMembersFromJSONTyped,
  GroupMembersToJSON,
} from "./GroupMembers";
import {
  UserProfileSummary,
  UserProfileSummaryFromJSON,
  UserProfileSummaryFromJSONTyped,
  UserProfileSummaryToJSON,
} from "./UserProfileSummary";

/**
 *
 * @export
 * @interface UserGroup
 */
export interface UserGroup {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof UserGroup
   */
  readonly guid?: string;
  /**
   * Name of the group
   * @type {string}
   * @memberof UserGroup
   */
  name: string;
  /**
   * Description of the group
   * @type {string}
   * @memberof UserGroup
   */
  description?: string | null;
  /**
   *
   * @type {UserProfileSummary}
   * @memberof UserGroup
   */
  group_owner: UserProfileSummary;
  /**
   *
   * @type {Array<GroupMembers>}
   * @memberof UserGroup
   */
  members: Array<GroupMembers>;
}

export function UserGroupFromJSON(json: any): UserGroup {
  return UserGroupFromJSONTyped(json, false);
}

export function UserGroupFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserGroup {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    name: json["name"],
    description: !exists(json, "description") ? undefined : json["description"],
    group_owner: UserProfileSummaryFromJSON(json["group_owner"]),
    members: (json["members"] as Array<any>).map(GroupMembersFromJSON),
  };
}

export function UserGroupToJSON(value?: UserGroup | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    description: value.description,
    group_owner: UserProfileSummaryToJSON(value.group_owner),
    members: (value.members as Array<any>).map(GroupMembersToJSON),
  };
}
