import React, { useEffect, useState } from "react";
import { MNavProps } from "../../ui/nav/MNav";
import {
  GetCollabByIdResponse,
  SearchForConnectionResponse,
  UserProfileSummary,
} from "../../../api/models";
import { useBackTimer, useBusyWatcher } from "../../../util/hooks";
import { useWrappedConnector } from "../../../api/connector";
import { errorMessagesForKeyFromResponse } from "../../../api/helpers";
import WizardScreen, { WizardScreenPanel } from "../../ui/screens/WizardScreen";
import LoadingScreen from "../../ui/screens/LoadingScreen";
import UserSearchScreen from "../../ui/screens/UserSearchScreen";
import ViewCollabScreen from "../insights/ViewCollabScreen";
import BaseNavbarPage from "../BaseNavbarPage";
import waitForTask from "../../../util/tasks";

type CreateCollabPageProps = {
  navbarProps: MNavProps;
  nextUrl: string;
  userGuid?: string | null;
};

enum CreateCollabStage {
  LOADING,
  PICK_USER,
  PROCESSING,
  VIEW_RESULT,
}

const CreateCollabPageComponent = (props: CreateCollabPageProps) => {
  const { navbarProps, nextUrl, userGuid } = props;

  const [errors, setErrors] = useState<string[]>([]);
  const [stage, setStage] = useState<CreateCollabStage>(
    CreateCollabStage.LOADING
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] =
    useState<SearchForConnectionResponse | null>(null);
  const [user, setUser] = useState<UserProfileSummary | null>(null);
  const [collabResponse, setCollabResponse] =
    useState<GetCollabByIdResponse | null>(null);
  const [isBack, asBack] = useBackTimer(stage);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);

  const goToStage = async (nextStage: CreateCollabStage) => {
    setErrors([]);
    // eslint-disable-next-line default-case
    switch (nextStage) {
      case CreateCollabStage.PICK_USER: {
        setSearchTerm("");
        setSearchResults(null);
        break;
      }
    }
    setStage(nextStage);
  };

  const onUserSelected = async (inputUserGuid: string | null = null) => {
    setErrors([]);
    let otherUserGuid = inputUserGuid;
    if (otherUserGuid === null) {
      otherUserGuid = user!.id!;
    }
    setStage(CreateCollabStage.PROCESSING);

    // First we check to see if we have an existing collab with the referenced user
    // making use of freshest state. If so, we don't need to create and process a
    // new one.

    const lastForUser = await connector.getLastCollabForUser({
      user_id: otherUserGuid!,
    });
    if (lastForUser.c!.exist && !lastForUser.c!.stale) {
      setCollabResponse({
        stale: false,
        collab: lastForUser.c!.collab!,
        content: lastForUser.c!.content,
      });
      await goToStage(CreateCollabStage.VIEW_RESULT);
      return;
    }

    // We don't have a fresh existing collab, so let's create one and wait for it
    // to be done processing.

    const createResponse = await connector.createCollab({
      user_id: otherUserGuid!,
    });
    if (createResponse.c!.is_task_completed) {
      const response = await connector.getCollabById(createResponse.c!.guid!);
      setCollabResponse(response.c!);
      await goToStage(CreateCollabStage.VIEW_RESULT);
      return;
    }
    const success = await waitForTask(createResponse.c!.task_id!, connector);
    if (!success) {
      setErrors([
        "something broke while processing your relationship analysis :( try again in a little bit",
      ]);
      return;
    }

    const resultResponse = await connector.getCollabById(
      createResponse.c!.guid!
    );
    setCollabResponse(resultResponse.c!);
    await goToStage(CreateCollabStage.VIEW_RESULT);
  };

  const refreshSearchResults = async (newSearchTerm: string) => {
    if (newSearchTerm === searchTerm) {
      return;
    }
    setSearchTerm(newSearchTerm);
    if (newSearchTerm === "") {
      setSearchResults(null);
    } else {
      const result = await connector.searchConnections({
        search_term: newSearchTerm,
      });
      setSearchResults(result.c!);
    }
  };

  const loadUserFromGuid = async () => {
    try {
      const response = await connector.getUserProfileSummary({
        user_id: userGuid!,
      });
      setUser(response.c!);
      await onUserSelected(response.c!.id!);
    } catch (e: any) {
      const parsed = await errorMessagesForKeyFromResponse(e, "user_id", true);
      setErrors(parsed);
    }
  };

  const onSelectUserClicked = async (newUser: UserProfileSummary) => {
    setUser(newUser);
    await onUserSelected(newUser.id!);
  };

  useEffect(() => {
    if (userGuid) {
      loadUserFromGuid();
    } else {
      goToStage(CreateCollabStage.PICK_USER);
    }
  }, []);

  const getElements = (): WizardScreenPanel[] => [
    {
      stage: CreateCollabStage.LOADING,
      content: (
        <LoadingScreen
          errors={errors}
          onBackClicked={() => window.history.back()}
        />
      ),
    },
    {
      stage: CreateCollabStage.PICK_USER,
      content: (
        <UserSearchScreen
          title="who do you want to see a relationship analysis with?"
          searchTerm={searchTerm}
          searchResults={searchResults?.results ?? null}
          onSearchTermUpdated={refreshSearchResults}
          onBackClicked={() => window.history.back()}
          onUserClicked={onSelectUserClicked}
          errors={errors}
          searchPlaceholder="search my people"
          startSearchingPlaceholder={
            "start typing above to search through your people by name or username.\n\nnote that only accounts that have been added to 'my people' will be shown here."
          }
        />
      ),
    },
    {
      stage: CreateCollabStage.PROCESSING,
      content: (
        <LoadingScreen
          errors={errors}
          onBackClicked={asBack(() => goToStage(CreateCollabStage.PICK_USER))}
          message="processing your relationship analysis..."
        />
      ),
    },
    {
      stage: CreateCollabStage.VIEW_RESULT,
      content: collabResponse && (
        <ViewCollabScreen
          collab={collabResponse}
          onDoneClicked={() => {
            window.location.href = nextUrl;
          }}
        />
      ),
    },
  ];

  return (
    <BaseNavbarPage
      navbarProps={{
        ...navbarProps,
        forceLoading: stage === CreateCollabStage.PROCESSING,
      }}
    >
      <WizardScreen isBack={isBack} elements={getElements()} stage={stage} />
    </BaseNavbarPage>
  );
};

CreateCollabPageComponent.defaultProps = {
  userGuid: null,
};

export default CreateCollabPageComponent;
