import React, { ReactNode, useEffect, useState } from "react";
import {
  MultiSelect,
  MultiSelectTagsEnum,
  PromptRelatedDetail,
} from "../../../../api/models";
import { useBusyWatcher } from "../../../../util/hooks";
import MTextArea from "../../../ui/MTextArea";
import MLabel from "../../../ui/MLabel";
import MInput from "../../../ui/MInput";
import MWellDeleteButton from "../../../ui/wells/MWellDeleteButton";
import MultiSelectQuestion from "../../../ui/forms/questions/MultiSelectQuestion";
import { classNames, hasDuplicateStrings } from "../../../../util/strings";
import { clickableText } from "../../../../util/style";
import TagSearchAndSelect from "../../../ui/TagSearchAndSelect";
import {
  CommonEditQuestionProps,
  maxMultipleChoiceChoiceLength,
  maxMultipleChoiceChoices,
  maxQuestionLength,
  maxStatementLength,
} from "./common";
import CommonEditQuestionScreen from "./CommonEditQuestionScreen";
import { MultiSelectPromptMeta } from "../../../../api/types";

type EditMultiSelectQuestionScreenProps = CommonEditQuestionProps & {
  onNextClicked: (result: MultiSelect) => void;
};

const EditMultiSelectQuestionScreenComponent = (
  props: EditMultiSelectQuestionScreenProps
) => {
  const {
    onBackClicked,
    onNextClicked: onNextClickedInput,
    errors,
    errorMap,
    onTagSearchTermUpdated,
    tagsSearching,
    tags,
    prompt,
    isCreate,
  } = props;

  const [question, setQuestion] = useState<string>("");
  const [statement, setStatement] = useState<string>("");
  const [answerCount, setAnswerCount] = useState<number>();
  const [minAnswerCount, setMinAnswerCount] = useState<number>();
  const [maxAnswerCount, setMaxAnswerCount] = useState<number>();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [options, setOptions] = useState<string[]>([]);
  const [selected, setSelected] = useState<number[]>([]);
  const [rowCount, setRowCount] = useState<number>(1);
  const [busy, _] = useBusyWatcher();

  const getTitle = (): string => {
    const verb = isCreate ? "Create" : "Edit";
    return `${verb} 'Multiple Choice' Question`;
  };

  const populateFromPrompt = (fromPrompt: PromptRelatedDetail) => {
    setQuestion(fromPrompt.question);
    setStatement(fromPrompt.statement);
    setSelectedTags(fromPrompt.tags);
    if (fromPrompt.meta) {
      const promptMeta = fromPrompt.meta as MultiSelectPromptMeta;
      setOptions(promptMeta.choices);
      setRowCount(promptMeta.choices.length);
      if (promptMeta.min_answer_count) {
        setMinAnswerCount(promptMeta.min_answer_count);
      }
      if (promptMeta.max_answer_count) {
        setMaxAnswerCount(promptMeta.max_answer_count);
      }
      if (promptMeta.answer_count) {
        setAnswerCount(promptMeta.answer_count);
      }
    }
  };

  const onNextClicked = () => {
    onNextClickedInput({
      question,
      statement,
      tags: selectedTags as MultiSelectTagsEnum[],
      choices: options,
    });
  };

  const areOptionsValid = (): boolean => {
    for (let i = 0; i < options.length; i += 1) {
      if (options[i].trim().length === 0) {
        return false;
      }
      if (options[i].length > maxMultipleChoiceChoiceLength) {
        return false;
      }
    }
    if (hasDuplicateStrings(options.flat().map((o) => o.trim()))) {
      return false;
    }
    return true;
  };

  const canSubmit = (): boolean => {
    if (question.length === 0) {
      return false;
    }
    if (question.length > maxQuestionLength) {
      return false;
    }
    if (statement.length === 0) {
      return false;
    }
    if (statement.length > maxStatementLength) {
      return false;
    }
    if (options.length < 2) {
      return false;
    }
    if (options.length > maxMultipleChoiceChoices) {
      return false;
    }
    if (!areOptionsValid()) {
      return false;
    }
    if (answerCount && answerCount > options.length) {
      return false;
    }
    if (answerCount && answerCount < 1) {
      return false;
    }
    if (minAnswerCount && minAnswerCount < 1) {
      return false;
    }
    if (minAnswerCount && minAnswerCount > options.length) {
      return false;
    }
    if (maxAnswerCount && maxAnswerCount < 1) {
      return false;
    }
    if (maxAnswerCount && maxAnswerCount > options.length) {
      return false;
    }
    if (minAnswerCount && maxAnswerCount && minAnswerCount > maxAnswerCount) {
      return false;
    }
    return true;
  };

  const onDeleteRowClicked = (row: number) => {
    const newOptions = [...options];
    newOptions.splice(row, 1);
    setOptions(newOptions);
    setRowCount(rowCount - 1);
  };

  const getChoicesRow = (row: number): ReactNode => (
    <div key={row} className="flex flex-row gap-3 items-center">
      <MInput
        className="grow"
        type="text"
        maxLength={50}
        value={options[row]}
        onChange={(e) => {
          const newOptions = [...options];
          newOptions[row] = e.target.value;
          setOptions(newOptions);
        }}
        onEnterPressed={() => canSubmit() && onNextClicked()}
      />
      <MWellDeleteButton
        onClick={() => onDeleteRowClicked(row)}
        disabled={rowCount === 1}
      />
    </div>
  );

  const getChoicesRows = (): ReactNode => {
    const rows = [];
    for (let i = 0; i < rowCount; i += 1) {
      rows.push(getChoicesRow(i));
    }
    return rows;
  };

  const getSelections = (): string[] => {
    const selections = [];
    for (let i = 0; i < selected.length; i += 1) {
      selections.push(options[selected[i]]);
    }
    return selections;
  };

  const getAnswerCountErrors = (): string[] => {
    const answerCountErrors = [];
    if (errorMap?.answer_count) {
      answerCountErrors.push(...errorMap.answer_count);
    }
    if (answerCount && answerCount > options.length) {
      answerCountErrors.push(
        "answer count cannot be greater than the number of choices"
      );
    }
    if (
      answerCount &&
      (minAnswerCount !== undefined || maxAnswerCount !== undefined)
    ) {
      answerCountErrors.push(
        "cannot set both answer count and min/max answer count"
      );
    }
    return answerCountErrors;
  };

  const getMinAnswerCountErrors = (): string[] => {
    const minAnswerCountErrors = [];
    if (errorMap?.min_answer_count) {
      minAnswerCountErrors.push(...errorMap.min_answer_count);
    }
    if (minAnswerCount && minAnswerCount > options.length) {
      minAnswerCountErrors.push(
        "minimum answer count cannot be greater than the number of choices"
      );
    }
    if (minAnswerCount && answerCount) {
      minAnswerCountErrors.push(
        "cannot set both answer count and min answer count"
      );
    }
    if (minAnswerCount && maxAnswerCount && minAnswerCount > maxAnswerCount) {
      minAnswerCountErrors.push("min answer count cannot be greater than max");
    }
    return minAnswerCountErrors;
  };

  const getMaxAnswerCountErrors = (): string[] => {
    const maxAnswerCountErrors = [];
    if (errorMap?.max_answer_count) {
      maxAnswerCountErrors.push(...errorMap.max_answer_count);
    }
    if (maxAnswerCount && maxAnswerCount > options.length) {
      maxAnswerCountErrors.push(
        "maximum answer count cannot be greater than the number of choices"
      );
    }
    if (maxAnswerCount && answerCount) {
      maxAnswerCountErrors.push(
        "cannot set both answer count and max answer count"
      );
    }
    if (maxAnswerCount && minAnswerCount && maxAnswerCount < minAnswerCount) {
      maxAnswerCountErrors.push("max answer count cannot be less than min");
    }
    return maxAnswerCountErrors;
  };

  useEffect(() => {
    if (prompt) {
      populateFromPrompt(prompt);
    }
  }, [prompt]);

  return (
    <CommonEditQuestionScreen
      title={getTitle()}
      previewQuestion={
        <MultiSelectQuestion
          question={question || "Your question text will show up here"}
          options={
            options.length > 0 ? options : ["your options will be shown here"]
          }
          selected={selected}
          onSelected={setSelected}
          disabled={options.length === 0}
          numSelectable={answerCount}
          minNumSelectable={minAnswerCount}
          maxNumSelectable={maxAnswerCount}
        />
      }
      previewResponse={{
        prompt: {
          question,
          statement:
            statement ||
            "your statement shows up here and is a rephrasing of your question in statement format",
          question_type: "multi_select",
          tags: [],
          meta: {
            choices:
              options.length > 0
                ? options
                : ["your options will be shown here"],
          },
        },
        response: {
          meta: {
            response: selected ? getSelections() : [],
          },
        },
      }}
      onNextClicked={onNextClicked}
      nextDisabled={busy || !canSubmit()}
      onBackClicked={onBackClicked}
      backDisabled={busy}
      errors={errors}
    >
      <MTextArea
        label="question"
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        characterLimit={maxQuestionLength}
        placeholder="how do you like your eggs?"
        errors={errorMap?.question}
        required
      />
      <MTextArea
        label="statement"
        value={statement}
        onChange={(e) => setStatement(e.target.value)}
        characterLimit={maxStatementLength}
        placeholder="i like my eggs..."
        errors={errorMap?.statement}
        required
      />
      <div>
        <MLabel label="tags" />
        <TagSearchAndSelect
          selected={selectedTags}
          onSearchTermUpdated={onTagSearchTermUpdated}
          onSelectedTagsUpdated={setSelectedTags}
          tags={tags?.tags ?? []}
          loading={tagsSearching}
          disabled={busy}
        />
      </div>
      <div className="flex flex-col gap-2">
        <div>
          <MLabel label="choices" required />
          <div className="text-xs">
            all options must be unique (ie: no two choices can have the same
            value)
          </div>
        </div>
        {getChoicesRows()}
        <div>
          <button
            type="button"
            className={classNames(
              rowCount < maxMultipleChoiceChoices ? clickableText : null,
              rowCount >= maxMultipleChoiceChoices
                ? "line-through text-m-light-gray cursor-not-allowed"
                : null
            )}
            onClick={() => {
              setOptions([...options, ""]);
              setRowCount(rowCount + 1);
            }}
            disabled={rowCount >= maxMultipleChoiceChoices}
          >
            + choice
          </button>
        </div>
      </div>
      <MInput
        label="# of answers"
        type="number"
        value={answerCount}
        onChange={(e) => {
          if (e.target.value === "") {
            setAnswerCount(undefined);
            return;
          }
          setAnswerCount(parseInt(e.target.value, 10));
        }}
        errors={getAnswerCountErrors()}
        min={1}
      />
      <MInput
        label="min # of answers"
        type="number"
        value={minAnswerCount}
        onChange={(e) => {
          if (e.target.value === "") {
            setMinAnswerCount(undefined);
            return;
          }
          setMinAnswerCount(parseInt(e.target.value, 10));
        }}
        errors={getMinAnswerCountErrors()}
        min={1}
      />
      <MInput
        label="max # of answers"
        type="number"
        value={maxAnswerCount}
        onChange={(e) => {
          if (e.target.value === "") {
            setMaxAnswerCount(undefined);
            return;
          }
          setMaxAnswerCount(parseInt(e.target.value, 10));
        }}
        errors={getMaxAnswerCountErrors()}
        min={1}
      />
    </CommonEditQuestionScreen>
  );
};

export default EditMultiSelectQuestionScreenComponent;
