/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UserSettingsDetail
 */
export interface UserSettingsDetail {
  /**
   * Whether the user has enrolled in marketing emails
   * @type {boolean}
   * @memberof UserSettingsDetail
   */
  is_enrolled_in_marketing_email?: boolean;
}

export function UserSettingsDetailFromJSON(json: any): UserSettingsDetail {
  return UserSettingsDetailFromJSONTyped(json, false);
}

export function UserSettingsDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserSettingsDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    is_enrolled_in_marketing_email: !exists(
      json,
      "is_enrolled_in_marketing_email"
    )
      ? undefined
      : json["is_enrolled_in_marketing_email"],
  };
}

export function UserSettingsDetailToJSON(
  value?: UserSettingsDetail | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    is_enrolled_in_marketing_email: value.is_enrolled_in_marketing_email,
  };
}
