import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import {
  GetSubscriptionResponse,
  GetSubscriptionTiersResponse,
} from "../../../api/models";
import SidebarSection from "../../ui/screens/sidebarnav/SidebarSection";
import MButton from "../../ui/buttons/MButton";

import { addNextToUrl } from "../../../util/url";
import SubscriptionUpsellDialog from "../../dialogs/SubscriptionUpsellDialog";
import SubscriptionChoicesView from "./SubscriptionChoicesView";
import connector from "../../../api/connector";

type SettingsSubscriptionScreenComponentProps = {
  loading: boolean;
  subscription: GetSubscriptionResponse | null;
  manageSubscriptionUrl: string;
};

const SettingsSubscriptionScreenComponent = (
  props: SettingsSubscriptionScreenComponentProps
) => {
  const { loading, subscription, manageSubscriptionUrl } = props;

  const [showSubscriptionUpsell, setShowSubscriptionUpsell] =
    useState<boolean>(false);

  const [response, setResponse] = useState<GetSubscriptionTiersResponse>();

  useEffect(() => {
    connector.getSubscriptionTiers().then((r) => {
      setResponse(r.c);
    });
  }, []);

  const redirectManageSubscriptions = () => {
    if (subscription && subscription.manage_link) {
      window.location.href = subscription.manage_link;
    } else {
      window.location.href = manageSubscriptionUrl;
    }
  };

  const onSubscribeButtonClicked = () => {
    if (subscription!.subscription) {
      window.location.href = addNextToUrl(subscription!.manage_link);
    } else {
      setShowSubscriptionUpsell(true);
    }
  };

  return (
    <SidebarSection
      title="Subscription Status"
      description="Manage your Manual Subscription."
    >
      <>
        <div className="text-m-white">
          {loading && <Skeleton />}
          {!loading && (
            <div className="flex flex-col gap-1 my-4">
              {subscription!.subscription && (
                <SubscriptionChoicesView
                  highlightTier={subscription!.subscription.tier}
                  highlightReason="Current Plan"
                  response={response}
                />
              )}
              <div className="flex justify-center mb-4">
                {!subscription!.subscription && (
                  <MButton kind="primary" onClick={onSubscribeButtonClicked}>
                    {subscription!.subscription
                      ? "Manage Subscription"
                      : "Subscribe"}
                  </MButton>
                )}
                {subscription!.subscription && (
                  <MButton kind="primary" onClick={redirectManageSubscriptions}>
                    go to billing dashboard
                  </MButton>
                )}
              </div>
            </div>
          )}
        </div>
        <SubscriptionUpsellDialog
          upsellText="gain access to more features through a Manual subscription"
          show={showSubscriptionUpsell}
          onClose={() => setShowSubscriptionUpsell(false)}
        />
      </>
    </SidebarSection>
  );
};

export default SettingsSubscriptionScreenComponent;
