import React, { PropsWithChildren, ReactNode, useRef } from "react";
import MNav, { MNavProps } from "../ui/nav/MNav";
import { maxWidth } from "../../util/style";
import { classNames } from "../../util/strings";
import { useSize } from "../../util/hooks";

type BaseNavbarPageProps = {
  navbarProps: MNavProps;
  pushdown?: ReactNode;
  className?: string | null;
  pushdownClassname?: string | null;
  fullWidth?: boolean;
};

const BaseNavbarPageComponent = (
  props: PropsWithChildren<BaseNavbarPageProps>
) => {
  const {
    navbarProps,
    pushdown,
    className,
    pushdownClassname,
    fullWidth,
    children,
  } = props;

  const navRef = useRef<HTMLDivElement>(null);
  const navSize = useSize(navRef);

  const getTopMarginForBody = (): number => {
    if (navSize) {
      return navSize.height;
    }
    return 76;
  };

  return (
    <>
      <div className="w-screen fixed top-0 left-0 z-10" ref={navRef}>
        <MNav
          {...navbarProps}
          pushdown={pushdown}
          pushdownClassname={pushdownClassname}
          fullWidth={fullWidth}
        />
      </div>
      <div
        className={classNames("flex-1 w-full mx-auto", maxWidth, className)}
        style={{
          marginTop: `${getTopMarginForBody()}px`,
        }}
      >
        <div className="min-h-full h-full text-m-dark-gray">{children}</div>
      </div>
    </>
  );
};

BaseNavbarPageComponent.defaultProps = {
  pushdown: null,
  className: null,
  pushdownClassname: null,
  fullWidth: false,
};

export default BaseNavbarPageComponent;
