import React, { ReactElement } from "react";
import { ManualTodo } from "../../../api/models";
import { useBusyWatcher } from "../../../util/hooks";
import MNextButton from "../buttons/MNextButton";
import MBackButton from "../buttons/MBackButton";
import CenterColumnStickyButtonScreen from "./CenterColumnStickyButtonScreen";
import MTitle from "../MTitle";
import MSubtext from "../MSubtext";
import TodoList from "../TodoList";

type TodoScreenProps = {
  todos: ManualTodo[];
  onNextClicked: () => void;
  title: string;
  outstandingCopy: string;
  completedCopy: string;
  onBackClicked?: (() => void) | null;
};

const TodoScreenComponent = (props: TodoScreenProps) => {
  const {
    todos,
    onNextClicked,
    title,
    outstandingCopy,
    completedCopy,
    onBackClicked,
  } = props;

  const [busy, _] = useBusyWatcher();

  const hasOutstanding = (): boolean =>
    todos.map((todo) => todo.completed).includes(false);

  const getButtons = (): ReactElement[] => {
    const toReturn: ReactElement[] = [];
    if (hasOutstanding()) {
      toReturn.push(
        <MNextButton
          onClick={onNextClicked}
          disabled={busy}
          key="next-button"
          kind="warning"
          text="continue anyways"
        />
      );
    } else {
      toReturn.push(
        <MNextButton
          onClick={onNextClicked}
          disabled={busy}
          key="next-button"
          kind="primary"
          text="continue"
        />
      );
    }
    if (onBackClicked) {
      toReturn.push(
        <MBackButton
          onClick={onBackClicked}
          disabled={busy}
          key="back-button"
          kind="secondary"
        />
      );
    }
    return toReturn;
  };

  return (
    <CenterColumnStickyButtonScreen buttons={getButtons()} stacked fullWidth>
      <MTitle text={title} className="text-center" />
      <MSubtext
        className="text-center"
        text={hasOutstanding() ? outstandingCopy : completedCopy}
      />
      <TodoList className="mx-auto my-10" entries={todos} />
    </CenterColumnStickyButtonScreen>
  );
};

TodoScreenComponent.defaultProps = {
  onBackClicked: null,
};

export default TodoScreenComponent;
