import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { MNavProps } from "../../ui/nav/MNav";
import { useBusyWatcher } from "../../../util/hooks";
import { useWrappedConnector } from "../../../api/connector";
import { GetProfileResponse } from "../../../api/models";
import ProfileV2Screen from "./ProfileV2Screen";
import { addNextToUrl, addUserIdToUrl } from "../../../util/url";
import { useAsyncConfirmationDialog } from "../../dialogs/hooks";
import { useAsyncConnectionResponseDialog } from "./ConnectionResponseDialog";
import { PubSubEvent, useSubscribe } from "../../../util/usePubSub";

type ProfileV2PageProps = {
  navbarProps: MNavProps;
  username: string;
  workHistoryUrl: string;
  profilePictureUrl: string;
  linksUrl: string;
  vitalsUrl: string;
  taglineUrl: string;
  editBasicsUrl: string;
  questionsUrl: string;
  insightsUrl: string;
  askQuestionUrl: string;
  isAuthed: boolean;
  loginUrl: string;
  listGlimpseUrl: string;
  listInsightsUrl: string;
  glimpseLoomUrl: string;
  insightsLoomUrl: string;
  attachmentsUrl: string;
};

const ProfileV2PageComponent = (props: ProfileV2PageProps) => {
  const {
    navbarProps,
    username,
    profilePictureUrl,
    workHistoryUrl,
    linksUrl,
    vitalsUrl,
    taglineUrl,
    editBasicsUrl,
    questionsUrl,
    insightsUrl,
    askQuestionUrl,
    isAuthed,
    loginUrl,
    listGlimpseUrl,
    listInsightsUrl,
    glimpseLoomUrl,
    insightsLoomUrl,
    attachmentsUrl,
  } = props;

  const [profile, setProfile] = useState<GetProfileResponse | null>(null);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);
  const confirmDialog = useAsyncConfirmationDialog();
  const connectRespondDialog = useAsyncConnectionResponseDialog();

  const fetchProfile = async () => {
    const response = await connector.getProfile({ username });
    setProfile(response.c!);
  };

  const showEditButtons = (): boolean => {
    if (!profile) {
      return false;
    }
    if (!profile.is_self) {
      return false;
    }
    return true;
  };

  const onAddToMyPeopleClicked = async () => {
    await connector.submitConnectionRequest({
      user: profile!.profile.user.id!,
    });
    toast.success("request sent");
    await fetchProfile();
  };

  const onRespondToConnectRequestClicked = async () => {
    const response = await connectRespondDialog.respond({
      profile: profile!,
    });
    if (response === null) {
      return;
    }
    await connector.respondToConnectionRequest({
      request: profile!.connection!.request!.guid!,
      accepted: response,
    });
    if (response) {
      toast.success("accepted");
    } else {
      toast.success("declined");
    }
    await fetchProfile();
  };

  const onRemoveFromMyPeopleClicked = async () => {
    const result = await confirmDialog.confirm({
      title: `remove ${profile?.profile?.user?.name} from your people?`,
      body: `are you sure you want to remove ${profile?.profile?.user?.name} from your people? once you do, you'll have to request to connect again.`,
    });
    if (!result) {
      return;
    }
    await connector.removeConnection({ user: profile!.profile.user.id! });
    toast.success("removed from your people");
    try {
      await fetchProfile();
    } catch {
      window.location.href = "/";
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  useSubscribe(PubSubEvent.ConnectionChanged, fetchProfile);

  return (
    <>
      <ProfileV2Screen
        navbarProps={navbarProps}
        response={profile}
        editPictureUrl={
          showEditButtons() ? addNextToUrl(profilePictureUrl) : null
        }
        editWorkHistoryUrl={
          showEditButtons() ? addNextToUrl(workHistoryUrl) : null
        }
        linksUrl={showEditButtons() ? addNextToUrl(linksUrl) : null}
        vitalsUrl={showEditButtons() ? addNextToUrl(vitalsUrl) : null}
        questionsUrl={showEditButtons() ? addNextToUrl(questionsUrl) : null}
        taglineUrl={showEditButtons() ? addNextToUrl(taglineUrl) : null}
        editBasicsUrl={showEditButtons() ? addNextToUrl(editBasicsUrl) : null}
        attachmentsUrl={showEditButtons() ? addNextToUrl(attachmentsUrl) : null}
        insightsUrl={addNextToUrl(
          addUserIdToUrl(insightsUrl, profile?.profile?.user?.id)
        )}
        askQuestionUrl={addUserIdToUrl(
          askQuestionUrl,
          profile?.profile?.user?.id
        )}
        isAuthed={isAuthed}
        loginUrl={loginUrl}
        listGlimpseUrl={listGlimpseUrl}
        listInsightsUrl={listInsightsUrl}
        glimpseLoomUrl={glimpseLoomUrl}
        insightsLoomUrl={insightsLoomUrl}
        onAddToMyPeopleClicked={onAddToMyPeopleClicked}
        onRespondToConnectRequestClicked={onRespondToConnectRequestClicked}
        onRemoveFromMyPeopleClicked={onRemoveFromMyPeopleClicked}
      />
      {confirmDialog.dialog}
      {connectRespondDialog.dialog}
    </>
  );
};

export default ProfileV2PageComponent;
