/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ResendInviteRequest
 */
export interface ResendInviteRequest {
  /**
   *
   * @type {string}
   * @memberof ResendInviteRequest
   */
  invite: string;
}

export function ResendInviteRequestFromJSON(json: any): ResendInviteRequest {
  return ResendInviteRequestFromJSONTyped(json, false);
}

export function ResendInviteRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ResendInviteRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    invite: json["invite"],
  };
}

export function ResendInviteRequestToJSON(
  value?: ResendInviteRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    invite: value.invite,
  };
}
