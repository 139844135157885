import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import React, { ReactElement, useState } from "react";
import MSpinner from "../../../ui/MSpinner";
import MErrors from "../../../ui/MErrors";
import CenterColumnStickyButtonScreen from "../../../ui/screens/CenterColumnStickyButtonScreen";
import MBackButton from "../../../ui/buttons/MBackButton";
import MLabel from "../../../ui/MLabel";
import MCard from "../../../ui/cards/MCard";
import PromptDisplayCard from "../../../ui/cards/prompt_display/PromptDisplayCard";
import MButton from "../../../ui/buttons/MButton";
import WizardScreen, {
  WizardScreenPanel,
} from "../../../ui/screens/WizardScreen";
import { useBackTimer } from "../../../../util/hooks";
import MNextButton from "../../../ui/buttons/MNextButton";

type GroupInsightLoadingScreenProps = {
  showNextButton: boolean;
  featuredQuestions?: string[];
  relevantPromptResponses?: any[];
  message?: string;
  errors?: string[];
  onNextClicked?: (() => void) | null;
  onBackClicked?: (() => void) | null;
};

enum ViewStage {
  FEATURED_QUESTIONS,
  RELEVANT_PROMPT_RESPONSES,
}

const GroupInsightLoadingScreenComponent = (
  props: GroupInsightLoadingScreenProps
) => {
  const {
    showNextButton,
    featuredQuestions,
    relevantPromptResponses,
    errors,
    message,
    onNextClicked,
    onBackClicked,
  } = props;

  const [stage, setStage] = useState<ViewStage>(ViewStage.FEATURED_QUESTIONS);
  const [isBack, asBack] = useBackTimer(stage);

  const hasPromptResponses =
    relevantPromptResponses && relevantPromptResponses.length > 0;
  const hasFeaturedQuestions =
    featuredQuestions && featuredQuestions.length > 0;

  const featuredQuestionsView = (
    <div className="flex flex-col items-center justify-center gap-3">
      <MLabel label="here is what other people have asked..." />
      {hasFeaturedQuestions &&
        featuredQuestions.map((data) => <MCard>{data}</MCard>)}
    </div>
  );

  const relevantPromptResponsesView = (
    <div className="flex flex-col items-center justify-center gap-3">
      <MLabel label="these responses help us come up with your result" />
      {hasPromptResponses &&
        relevantPromptResponses.map((pr) => (
          <PromptDisplayCard
            key={pr.prompt.guid}
            prompt={pr}
            collapsible={false}
          />
        ))}
    </div>
  );

  const viewPromptResponses = () => {
    if (stage === ViewStage.FEATURED_QUESTIONS) {
      setStage(ViewStage.RELEVANT_PROMPT_RESPONSES);
      return;
    }
  };

  const viewFeaturedQuestions = () => {
    if (stage === ViewStage.RELEVANT_PROMPT_RESPONSES) {
      setStage(ViewStage.FEATURED_QUESTIONS);
      return;
    }
  };

  const getButtons = (): ReactElement[] => {
    if (errors && errors.length > 0 && onBackClicked) {
      return [
        <MBackButton
          onClick={onBackClicked}
          kind="primary"
          className="w-full"
        />,
      ];
    }

    return [
      <MNextButton
        disabled={
          !relevantPromptResponses ||
          stage === ViewStage.RELEVANT_PROMPT_RESPONSES
        }
        onClick={viewPromptResponses}
        kind="primary"
        className="w-full"
      />,
      <MBackButton
        disabled={stage === ViewStage.FEATURED_QUESTIONS}
        onClick={asBack(viewFeaturedQuestions)}
        kind="primary"
        className="w-full"
      />,
    ];
  };

  const getElements = (): WizardScreenPanel[] => [
    {
      stage: ViewStage.FEATURED_QUESTIONS,
      content: featuredQuestionsView,
    },
    {
      stage: ViewStage.RELEVANT_PROMPT_RESPONSES,
      content: relevantPromptResponsesView,
    },
  ];

  return (
    <CenterColumnStickyButtonScreen buttons={getButtons()}>
      <div className="flex flex-col items-center justify-center gap-3 text-center">
        {!errors || errors.length === 0 ? (
          <>
            {!showNextButton ? (
              <>
                <div className="h-10 w-10">
                  <MSpinner />
                </div>
                <div>{message}</div>
              </>
            ) : (
              <MButton
                disabled={!showNextButton}
                onClick={onNextClicked!}
                kind="primary"
                className="w-full"
              >
                your result is ready!
              </MButton>
            )}
            <WizardScreen
              elements={getElements()}
              stage={stage}
              isBack={isBack}
            />
          </>
        ) : (
          <>
            <ExclamationCircleIcon className="h-10 w-10 text-danger" />
            <MErrors errors={errors} />
          </>
        )}
      </div>
    </CenterColumnStickyButtonScreen>
  );
};

GroupInsightLoadingScreenComponent.defaultProps = {
  featuredQuestions: [],
  relevantPromptResponses: [],
  errors: [],
  message: "loading...\n\ngive us just a moment please",
  onNextClicked: null,
  onBackClicked: null,
};

export default GroupInsightLoadingScreenComponent;
