/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  UserProfileSummary,
  UserProfileSummaryFromJSON,
  UserProfileSummaryFromJSONTyped,
  UserProfileSummaryToJSON,
} from "./UserProfileSummary";

/**
 *
 * @export
 * @interface AIQuestionAndResponseDetail
 */
export interface AIQuestionAndResponseDetail {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof AIQuestionAndResponseDetail
   */
  readonly guid?: string;
  /**
   *
   * @type {UserProfileSummary}
   * @memberof AIQuestionAndResponseDetail
   */
  user: UserProfileSummary;
  /**
   * The question we want an AI to answer.
   * @type {string}
   * @memberof AIQuestionAndResponseDetail
   */
  question: string;
  /**
   * The task ID for the Celery task that was enqueued to ask the referenced question.
   * @type {string}
   * @memberof AIQuestionAndResponseDetail
   */
  task_id?: string | null;
  /**
   * Whether the task has completed successfully or not.
   * @type {boolean}
   * @memberof AIQuestionAndResponseDetail
   */
  task_success?: boolean | null;
  /**
   * The response that we received from the AI.
   * @type {string}
   * @memberof AIQuestionAndResponseDetail
   */
  response?: string | null;
}

export function AIQuestionAndResponseDetailFromJSON(
  json: any
): AIQuestionAndResponseDetail {
  return AIQuestionAndResponseDetailFromJSONTyped(json, false);
}

export function AIQuestionAndResponseDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AIQuestionAndResponseDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    user: UserProfileSummaryFromJSON(json["user"]),
    question: json["question"],
    task_id: !exists(json, "task_id") ? undefined : json["task_id"],
    task_success: !exists(json, "task_success")
      ? undefined
      : json["task_success"],
    response: !exists(json, "response") ? undefined : json["response"],
  };
}

export function AIQuestionAndResponseDetailToJSON(
  value?: AIQuestionAndResponseDetail | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    user: UserProfileSummaryToJSON(value.user),
    question: value.question,
    task_id: value.task_id,
    task_success: value.task_success,
    response: value.response,
  };
}
