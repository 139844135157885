/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ProfileCompletionStage,
  ProfileCompletionStageFromJSON,
  ProfileCompletionStageFromJSONTyped,
  ProfileCompletionStageToJSON,
} from "./ProfileCompletionStage";

/**
 *
 * @export
 * @interface SelfProfileContent
 */
export interface SelfProfileContent {
  /**
   *
   * @type {Array<ProfileCompletionStage>}
   * @memberof SelfProfileContent
   */
  completion_stages?: Array<ProfileCompletionStage>;
  /**
   *
   * @type {boolean}
   * @memberof SelfProfileContent
   */
  onboarding_completed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SelfProfileContent
   */
  profile_completed: boolean;
}

export function SelfProfileContentFromJSON(json: any): SelfProfileContent {
  return SelfProfileContentFromJSONTyped(json, false);
}

export function SelfProfileContentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SelfProfileContent {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    completion_stages: !exists(json, "completion_stages")
      ? undefined
      : (json["completion_stages"] as Array<any>).map(
          ProfileCompletionStageFromJSON
        ),
    onboarding_completed: json["onboarding_completed"],
    profile_completed: json["profile_completed"],
  };
}

export function SelfProfileContentToJSON(
  value?: SelfProfileContent | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    completion_stages:
      value.completion_stages === undefined
        ? undefined
        : (value.completion_stages as Array<any>).map(
            ProfileCompletionStageToJSON
          ),
    onboarding_completed: value.onboarding_completed,
    profile_completed: value.profile_completed,
  };
}
