import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import UpdateGroupScreen from "./UpdateGroupScreen";
import PickGroupEditTypeScreen from "./PickGroupEditTypeScreen";
import EditGroupMembersScreen from "./EditGroupMembersScreen";
import { MNavProps } from "../../../ui/nav/MNav";
import { useBackTimer, useBusyWatcher } from "../../../../util/hooks";
import { useWrappedConnector } from "../../../../api/connector";
import WizardScreen, {
  WizardScreenPanel,
} from "../../../ui/screens/WizardScreen";
import LoadingScreen from "../../../ui/screens/LoadingScreen";
import BaseNavbarPage from "../../BaseNavbarPage";
import { errorMessagesFromResponse } from "../../../../api/helpers";
import {
  AddUsersRequest,
  RemoveUsersRequest,
} from "../../../../api/apis/GroupsApi";
import { addQueryParamToPath } from "../../../../util/url";
import {
  SearchForConnectionResponse,
  UpdateGroupRequest,
  UserGroup,
  UserProfileSummary,
} from "../../../../api/models";

type EditGroupPageProps = {
  groupId: string;
  nextUrl: string;
  navbarProps: MNavProps;
};

enum EditGroupStage {
  LOADING,
  PICK_EDIT_TYPE,
  UPDATE_GROUP,
  UPDATE_MEMBERS,
}

const EditGroupPageComponent = (props: EditGroupPageProps) => {
  const { groupId, nextUrl, navbarProps } = props;

  const [group, setGroup] = useState<UserGroup | null>(null);
  const [connections, setConnections] =
    useState<SearchForConnectionResponse | null>(null);
  const [userSearchTerm, setUserSearchTerm] = useState<string>("");
  const [stage, setStage] = useState<EditGroupStage>(EditGroupStage.LOADING);
  const [errors, setErrors] = useState<any>({});
  const [isBack, asBack] = useBackTimer(stage);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);

  const loadGroup = async () => {
    const response = await connector.getGroup(groupId);
    setGroup(response.c!);
  };

  const loadConnections = async (connectionsSearchTerm: string) => {
    setErrors({});
    try {
      const response = await connector.searchConnections({
        search_term: connectionsSearchTerm,
      });
      setConnections(response.c!);
      setUserSearchTerm(connectionsSearchTerm);
    } catch (e: any) {
      const newErrors = await errorMessagesFromResponse(e);
      setErrors(newErrors);
    }
  };

  const goToStage = async (nextStage: EditGroupStage) => {
    await loadGroup();
    setStage(nextStage);
  };

  const onUpdateGroupClicked = async (request: UpdateGroupRequest) => {
    setErrors({});
    try {
      await connector.updateGroup(request);
    } catch (e: any) {
      const newErrors = await errorMessagesFromResponse(e);
      setErrors(newErrors);
      return;
    }
    toast.success("update successful!");
    await goToStage(EditGroupStage.PICK_EDIT_TYPE);
  };

  const addUsers = async (usersToAdd: AddUsersRequest) => {
    if (usersToAdd.data.user_guids.length === 0) {
      return;
    }
    await connector.addUsersToGroup(usersToAdd);
  };

  const removeUsers = async (usersToRemove: RemoveUsersRequest) => {
    if (usersToRemove.data.user_guids.length === 0) {
      return;
    }
    await connector.removeUsersFromGroup(usersToRemove);
  };

  const onUpdateMembersNextClicked = async (
    usersToAdd: UserProfileSummary[],
    usersToRemove: UserProfileSummary[]
  ) => {
    try {
      const userGuidsToAdd = usersToAdd.map((user) => user.id!);
      await addUsers({
        data: { group: group!.guid!, user_guids: userGuidsToAdd },
      });
      const userGuidsToRemove = usersToRemove.map((user) => user.id!);
      await removeUsers({
        data: { group: group!.guid!, user_guids: userGuidsToRemove },
      });
    } catch (e: any) {
      const newErrors = await errorMessagesFromResponse(e);
      setErrors(newErrors);
      return;
    }

    toast.success("update successful!");
    await goToStage(EditGroupStage.PICK_EDIT_TYPE);
  };

  useEffect(() => {
    goToStage(EditGroupStage.PICK_EDIT_TYPE);
  }, []);

  const getElements = (): WizardScreenPanel[] => [
    {
      stage: EditGroupStage.LOADING,
      content: <LoadingScreen />,
    },
    {
      stage: EditGroupStage.PICK_EDIT_TYPE,
      content: group && (
        <PickGroupEditTypeScreen
          group={group}
          onEditGroupClicked={() => goToStage(EditGroupStage.UPDATE_GROUP)}
          onEditMembersClicked={() => goToStage(EditGroupStage.UPDATE_MEMBERS)}
          onDoneClicked={() => {
            window.location.href = addQueryParamToPath(
              nextUrl,
              "next",
              "my-groups"
            );
          }}
        />
      ),
    },
    {
      stage: EditGroupStage.UPDATE_GROUP,
      content: group && (
        <UpdateGroupScreen
          group={group}
          errors={errors}
          onNextClicked={onUpdateGroupClicked}
          onBackClicked={asBack(() => goToStage(EditGroupStage.PICK_EDIT_TYPE))}
        />
      ),
    },
    {
      stage: EditGroupStage.UPDATE_MEMBERS,
      content: group && (
        <EditGroupMembersScreen
          group={group}
          connections={connections}
          searchTerm={userSearchTerm}
          errors={errors}
          onSearchTermUpdated={loadConnections}
          onNextClicked={onUpdateMembersNextClicked}
          onBackClicked={asBack(() => goToStage(EditGroupStage.PICK_EDIT_TYPE))}
        />
      ),
    },
  ];

  return (
    <BaseNavbarPage navbarProps={navbarProps}>
      <WizardScreen elements={getElements()} stage={stage} isBack={isBack} />
    </BaseNavbarPage>
  );
};

export default EditGroupPageComponent;
