import React from "react";
import { QuestionnaireCompletion } from "../../../api/models";
import MChecked from "../../ui/MChecked";
import MButton from "../../ui/buttons/MButton";
import MCard from "../../ui/cards/MCard";

type QuestionnaireCardProps = {
  questionnaire: QuestionnaireCompletion;
  editUrl: string;
};

const QuestionnaireCardComponent = (props: QuestionnaireCardProps) => {
  const { questionnaire, editUrl } = props;

  return (
    <MCard
      header={
        <div className="flex flex-row items-center">
          <div className="grow">{questionnaire.questionnaire.title}</div>
          <MChecked checked={questionnaire.is_completed} className="w-6 h-6" />
        </div>
      }
    >
      {questionnaire.questionnaire.description}
      <MButton
        href={editUrl}
        kind={questionnaire.is_completed ? "secondary" : "primary"}
        className="w-full mt-5"
      >
        {questionnaire.is_completed ? "re-take quiz" : "take quiz"}
      </MButton>
    </MCard>
  );
};

export default QuestionnaireCardComponent;
