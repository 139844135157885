import React, { useEffect, useState } from "react";
import { MNavProps } from "../../../ui/nav/MNav";
import { useBackTimer, useBusyWatcher } from "../../../../util/hooks";
import { useWrappedConnector } from "../../../../api/connector";
import WizardScreen, {
  WizardScreenPanel,
} from "../../../ui/screens/WizardScreen";
import LoadingScreen from "../../../ui/screens/LoadingScreen";
import BaseNavbarPage from "../../BaseNavbarPage";
import { Attachments, GetResumeResponse } from "../../../../api/models";
import AttachmentsLandingScreen from "./AttachmentsLandingScreen";
import ViewResumeScreen from "./ViewResumeScreen";
import UploadResumeScreen from "./UploadResumeScreen";
import { useAsyncConfirmationDialog } from "../../../dialogs/hooks";

enum AttachmentsStage {
  LOADING,
  LANDING,
  VIEW_RESUME,
  UPLOAD_RESUME,
}

type AttachmentsPageProps = {
  nextUrl: string;
  navbarProps: MNavProps;
};

const AttachmentsPageComponent = (props: AttachmentsPageProps) => {
  const { nextUrl, navbarProps } = props;

  const [attachments, setAttachments] = useState<Attachments | null>(null);
  const [resume, setResume] = useState<GetResumeResponse | null>(null);
  const [stage, setStage] = useState<AttachmentsStage>(
    AttachmentsStage.LOADING
  );
  const [isBack, asBack] = useBackTimer(stage);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);
  const confirmDialog = useAsyncConfirmationDialog();

  const loadAttachments = async () => {
    const response = await connector.getAttachments();
    setAttachments(response.c!);
  };

  const loadResume = async () => {
    const response = await connector.getResume();
    setResume(response.c!);
  };

  const goToStage = async (nextStage: AttachmentsStage) => {
    // eslint-disable-next-line default-case
    switch (nextStage) {
      case AttachmentsStage.LANDING: {
        await loadAttachments();
        break;
      }
      case AttachmentsStage.VIEW_RESUME: {
        await loadResume();
        break;
      }
    }
    setStage(nextStage);
  };

  const onClearResumeClicked = async () => {
    const result = await confirmDialog.confirm({
      title: "delete resume?",
      body: "this will delete your resume from your profile. this action cannot be undone.",
      kind: "warning",
    });
    if (!result) {
      return;
    }
    await connector.deleteResume();
    await goToStage(AttachmentsStage.LANDING);
  };

  const onUploadResumeClicked = async (content: [string, string]) => {
    const [fileContent, fileName] = content;
    await connector.uploadResume({
      resume_data_url: fileContent,
      file_name: fileName,
    });
    await goToStage(AttachmentsStage.VIEW_RESUME);
  };

  useEffect(() => {
    goToStage(AttachmentsStage.LANDING);
  }, []);

  const getElements = (): WizardScreenPanel[] => [
    {
      stage: AttachmentsStage.LOADING,
      content: <LoadingScreen onBackClicked={() => window.history.back()} />,
    },
    {
      stage: AttachmentsStage.LANDING,
      content: attachments && (
        <AttachmentsLandingScreen
          attachments={attachments}
          onDoneClicked={() => {
            window.location.href = nextUrl;
          }}
          onResumeClicked={() => {
            if (attachments!.resume) {
              goToStage(AttachmentsStage.VIEW_RESUME);
            } else {
              goToStage(AttachmentsStage.UPLOAD_RESUME);
            }
          }}
        />
      ),
    },
    {
      stage: AttachmentsStage.VIEW_RESUME,
      content: resume && (
        <ViewResumeScreen
          resume={resume}
          onBackClicked={asBack(() => goToStage(AttachmentsStage.LANDING))}
          onClearClicked={onClearResumeClicked}
          onUpdateClicked={() => goToStage(AttachmentsStage.UPLOAD_RESUME)}
        />
      ),
    },
    {
      stage: AttachmentsStage.UPLOAD_RESUME,
      content: (
        <UploadResumeScreen
          onBackClicked={asBack(() => goToStage(AttachmentsStage.LANDING))}
          onUploadClicked={onUploadResumeClicked}
        />
      ),
    },
  ];

  return (
    <>
      <BaseNavbarPage navbarProps={navbarProps}>
        <WizardScreen isBack={isBack} elements={getElements()} stage={stage} />
      </BaseNavbarPage>
      {confirmDialog.dialog}
    </>
  );
};

export default AttachmentsPageComponent;
