import React, { ReactElement } from "react";
import { useBusyWatcher } from "../../../util/hooks";
import MNextButton from "../../ui/buttons/MNextButton";
import CenterColumnStickyButtonScreen from "../../ui/screens/CenterColumnStickyButtonScreen";
import MButton from "../../ui/buttons/MButton";
import { commentText } from "../../../util/style";
import { classNames } from "../../../util/strings";
import MTitle from "../../ui/MTitle";

type QuestionsLandingScreenProps = {
  onAssessmentsClicked: () => void;
  onQuestionnairesClicked: () => void;
  onQuestionsClicked: () => void;
  onDoneClicked: () => void;
};

const QuestionsLandingScreenComponent = (
  props: QuestionsLandingScreenProps
) => {
  const {
    onAssessmentsClicked,
    onQuestionnairesClicked,
    onQuestionsClicked,
    onDoneClicked,
  } = props;

  const [busy, _] = useBusyWatcher();

  const getButtons = (): ReactElement[] => [
    <MNextButton
      kind="secondary"
      onClick={onDoneClicked}
      disabled={busy}
      key="done-button"
      text="done"
    />,
  ];

  return (
    <CenterColumnStickyButtonScreen buttons={getButtons()} stacked fullWidth>
      <MTitle text="questions" className="text-center" />
      <div className="flex flex-col gap-5 mt-5">
        <div className="flex flex-col">
          <MButton
            kind="primary"
            onClick={onAssessmentsClicked}
            disabled={busy}
          >
            run an assessment
          </MButton>
          <span className={classNames(commentText, "text-sm text-center")}>
            an assessment is a brief quiz that is used to help write free
            response content for your profile
          </span>
        </div>
        <div className="flex flex-col">
          <MButton
            kind="primary"
            onClick={onQuestionnairesClicked}
            disabled={busy}
          >
            take a quiz
          </MButton>
          <span className={classNames(commentText, "text-sm text-center")}>
            a quiz is a collection of related questions of different types,
            including longer free response questions
          </span>
        </div>
        <div className="flex flex-col">
          <MButton kind="primary" onClick={onQuestionsClicked} disabled={busy}>
            edit your answers
          </MButton>
          <span className={classNames(commentText, "text-sm text-center")}>
            edit your answers to any question you've answered before
          </span>
        </div>
      </div>
    </CenterColumnStickyButtonScreen>
  );
};

export default QuestionsLandingScreenComponent;
