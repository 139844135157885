export const classNames = (...classes: (string | undefined | null)[]): string =>
  classes.filter(Boolean).join(" ");

export const durationFromMs = (ms: number): string => `duration-[${ms}ms]`;

export const splitGlimpseContent = (content: string): string[] => {
  const toReturn: string[] = [];
  const firstIndex = content.indexOf(".");
  const firstSentence = content.slice(0, firstIndex + 1);
  toReturn.push(firstSentence);
  const rest = content.slice(firstIndex + 1);
  const restSplit = rest.split("\n");
  for (let i = 0; i < restSplit.length; i += 1) {
    const sentence = restSplit[i];
    if (sentence.length > 0) {
      toReturn.push(sentence.trim());
    }
  }
  return toReturn;
};

export const hasDuplicateStrings = (arr: string[]): boolean => {
  const set = new Set(arr);
  return set.size !== arr.length;
};

export const capitalize = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1);
