import React, { useEffect, useState } from "react";
import { usePostHog } from "posthog-js/react";
import IntroScreen from "./IntroScreen";
import BasicsScreen from "../profile_v2/basics/BasicsScreen";
import { useWrappedConnector } from "../../../api/connector";
import {
  GetBasicInfo,
  GetEventTypeEventEnum,
  GetOnboardingStageResponseStageEnum,
  GetProfilePublicResponse,
  GetQuestionnaireWithResponsesResponse,
  PromptWithResponse,
  PublishablePromptWithResponse,
  SetBasicInfo,
  UserSettingsDetail,
} from "../../../api/models";
import {
  errorMessagesForKeyFromResponse,
  errorMessagesFromResponse,
} from "../../../api/helpers";
import { useBackTimer, useBusyWatcher } from "../../../util/hooks";
import UsernameScreen from "./UsernameScreen";
import { MNavProps } from "../../ui/nav/MNav";
import BaseNavbarPage from "../BaseNavbarPage";
import LoadingScreen from "../../ui/screens/LoadingScreen";
import { Step, StepStatus } from "../../ui/MStepsCircles";
import WizardScreen, { WizardScreenPanel } from "../../ui/screens/WizardScreen";
import PromptRespondTransitionScreen from "../../ui/forms/PromptRespondTransitionScreen";
import OnboardingSettingsScreen from "./OnboardingSettingsScreen";

type OnboardingV2PageProps = {
  navbarProps: MNavProps;
};

enum OnboardingStage {
  LOADING,
  INTRO,
  BASICS,
  QUESTIONNAIRE,
  USERNAME,
  COMPLETED,
}

const OnboardingV2PageComponent = (props: OnboardingV2PageProps) => {
  const { navbarProps } = props;

  const [loading, setLoading] = useState<boolean>(true);
  const [stage, setStage] = useState<GetOnboardingStageResponseStageEnum>(
    GetOnboardingStageResponseStageEnum.intro
  );
  const [errors, setErrors] = useState<any>({});
  const [basics, setBasics] = useState<GetBasicInfo | null>(null);
  const [questionnaire, setQuestionnaire] =
    useState<GetQuestionnaireWithResponsesResponse | null>(null);
  const [promptIndex, setPromptIndex] = useState<number>(0);
  const [promptWithResponse, setPromptWithResponse] =
    useState<PublishablePromptWithResponse | null>(null);
  const [promptErrors, setPromptErrors] = useState<string[]>([]);
  const [username, setUsername] = useState<string>("");
  const [usernameErrors, setUsernameErrors] = useState<string[]>([]);
  const [publicProfile, setPublicProfile] =
    useState<GetProfilePublicResponse | null>(null);
  const [userSettings, setUserSettings] = useState<UserSettingsDetail | null>(
    null
  );
  const [isBack, asBack] = useBackTimer(stage);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);
  const posthog = usePostHog();

  const loadPublicProfile = async () => {
    const response = await connector.getProfilePublicStatus();
    setPublicProfile(response.c!);
  };

  const loadUserSettings = async () => {
    const response = await connector.getSettings();
    setUserSettings(response.c!);
  };

  const fetchPrompt = async (
    index: number,
    promptId: string | null = null
  ): Promise<PromptWithResponse> => {
    const sendId = promptId ?? questionnaire!.prompts[index].prompt.guid!;
    const pr = await connector.getPromptWithResponse(sendId);
    setPromptWithResponse(pr.c!);
    setPromptIndex(index);
    return pr.c!;
  };

  const load = async (): Promise<GetOnboardingStageResponseStageEnum> => {
    const stageResponse = await connector.getOnboardingStage();
    const questionnaireResponse = await connector.getQuestionnaire(
      stageResponse.c!.questionnaire
    );
    setQuestionnaire(questionnaireResponse.c!);
    await fetchPrompt(0, questionnaireResponse.c!.prompts[0].prompt.guid!);
    return stageResponse.c!.stage;
  };

  const goToStage = async (nextStage: GetOnboardingStageResponseStageEnum) => {
    setErrors({});
    setPromptErrors([]);
    setUsernameErrors([]);
    // eslint-disable-next-line default-case
    switch (nextStage) {
      case GetOnboardingStageResponseStageEnum.basics: {
        const basicsResponse = await connector.getOnboardingBasics();
        setBasics(basicsResponse.c!);
        if (stage === GetOnboardingStageResponseStageEnum.intro) {
          posthog.capture(GetEventTypeEventEnum.onboarding_started);
        }
        break;
      }
      case GetOnboardingStageResponseStageEnum.questionnaire: {
        if (stage === GetOnboardingStageResponseStageEnum.username) {
          await fetchPrompt(questionnaire!.prompts.length - 1);
        } else if (promptIndex !== 0) {
          await fetchPrompt(0);
        } else if (stage === GetOnboardingStageResponseStageEnum.basics) {
          posthog.capture(GetEventTypeEventEnum.questionnaire_started, {
            questionnaire_guid: questionnaire?.questionnaire.guid,
            prompt_guid: questionnaire?.prompts[promptIndex].prompt.guid,
          });
        }
        break;
      }
      case GetOnboardingStageResponseStageEnum.username: {
        const usernameResponse = await connector.getUsername();
        setUsername(usernameResponse.c!.username || "");
        break;
      }
    }
    setStage(nextStage);
  };

  const onSaveBasicsClicked = async (data: SetBasicInfo) => {
    setErrors({});
    try {
      await connector.setOnboardingBasics(data);
    } catch (e) {
      const newErrors = await errorMessagesFromResponse(e as Response);
      setErrors(newErrors);
      return;
    }
    await goToStage(GetOnboardingStageResponseStageEnum.questionnaire);
  };

  const onNextPromptClicked = async (results: any, visible: boolean | null) => {
    try {
      await connector.submitOnboardingPromptResponse({
        prompt: promptWithResponse!.prompt.guid!,
        response: results,
        publish: visible,
      });
    } catch (e) {
      const newErrors = await errorMessagesForKeyFromResponse(
        e as Response,
        "response",
        true
      );
      setPromptErrors(newErrors);
      return;
    }
    if (promptIndex === questionnaire!.prompts.length - 1) {
      await connector.markQuestionnaireAsCompleted(
        questionnaire!.questionnaire.guid!
      );
      posthog.capture(GetEventTypeEventEnum.onboarding_questions_completed, {
        questionnaire_guid: questionnaire?.questionnaire.guid,
      });
      await goToStage(GetOnboardingStageResponseStageEnum.username);
      return;
    }
    const newIndex = promptIndex + 1;
    await fetchPrompt(newIndex);
    setPromptIndex(newIndex);
  };

  const onSetPublicProfileStatus = async (publicStatus: boolean) => {
    await connector.setProfilePublicStatus(publicStatus);
    await loadPublicProfile();
  };

  const onSetMarketingOptIn = async (marketingOptIn: boolean) => {
    await connector.updateSettings({
      enroll_in_marketing_emails: marketingOptIn,
    });
    await loadUserSettings();
  };

  const onBackPromptClicked = async () => {
    if (promptIndex === 0) {
      await goToStage(GetOnboardingStageResponseStageEnum.basics);
      return;
    }
    const newIndex = promptIndex - 1;
    await fetchPrompt(newIndex);
    setPromptIndex(newIndex);
  };

  const onNextUsernameClicked = async (newUsername: string) => {
    setUsernameErrors([]);
    try {
      await connector.setOnboardingUsername(newUsername);
    } catch (e) {
      const newErrors = await errorMessagesForKeyFromResponse(
        e as Response,
        "username"
      );
      setUsernameErrors(newErrors);
      return;
    }
    setUsername(newUsername);
    await Promise.all([loadPublicProfile(), loadUserSettings()]);
    posthog.capture(GetEventTypeEventEnum.onboarding_completed);
    await goToStage(GetOnboardingStageResponseStageEnum.completed);
  };

  const onNextSettingsClicked = () => {
    window.location.href = `/@${username}`;
  };

  const onBackUsernameClicked = async () => {
    await goToStage(GetOnboardingStageResponseStageEnum.questionnaire);
  };

  const getSteps = (): Step[] => {
    const statusFromStage = (
      curStage: GetOnboardingStageResponseStageEnum
    ): StepStatus => {
      if (stage > curStage) {
        return "complete";
      }
      if (stage === curStage) {
        return "current";
      }
      return "upcoming";
    };

    const statusFromPromptIndex = (index: number): StepStatus => {
      if (stage < GetOnboardingStageResponseStageEnum.questionnaire) {
        return "upcoming";
      }
      if (stage > GetOnboardingStageResponseStageEnum.questionnaire) {
        return "complete";
      }
      if (promptIndex > index) {
        return "complete";
      }
      if (promptIndex === index) {
        return "current";
      }
      return "upcoming";
    };

    const toReturn = [
      {
        name: "set your basic info",
        status: statusFromStage(GetOnboardingStageResponseStageEnum.basics),
        key: "basics",
      },
    ];

    if (questionnaire) {
      for (let i = 0; i < questionnaire.prompts.length; i += 1) {
        toReturn.push({
          name: questionnaire.prompts[i].prompt.question,
          status: statusFromPromptIndex(i),
          key: questionnaire.prompts[i].prompt.guid!,
        });
      }
    }

    toReturn.push({
      name: "set your username",
      status: statusFromStage(GetOnboardingStageResponseStageEnum.username),
      key: "username",
    });

    return toReturn;
  };

  const getStageEnum = (): OnboardingStage => {
    if (loading) {
      return OnboardingStage.LOADING;
    }
    switch (stage) {
      case GetOnboardingStageResponseStageEnum.intro:
        return OnboardingStage.INTRO;
      case GetOnboardingStageResponseStageEnum.basics:
        return OnboardingStage.BASICS;
      case GetOnboardingStageResponseStageEnum.questionnaire:
        return OnboardingStage.QUESTIONNAIRE;
      case GetOnboardingStageResponseStageEnum.username:
        return OnboardingStage.USERNAME;
      case GetOnboardingStageResponseStageEnum.completed:
        return OnboardingStage.COMPLETED;
      default:
        throw new Error(`invalid onboarding stage ${stage}`);
    }
  };

  useEffect(() => {
    load()
      .then((curStage) => goToStage(curStage))
      .finally(() => setLoading(false));
  }, []);

  const getElements = (): WizardScreenPanel[] => [
    {
      stage: OnboardingStage.LOADING,
      content: (
        <LoadingScreen message="welcome to manual! give us just a second to tidy up for you..." />
      ),
    },
    {
      stage: OnboardingStage.INTRO,
      content: (
        <IntroScreen
          onCreateClicked={() =>
            goToStage(GetOnboardingStageResponseStageEnum.basics)
          }
        />
      ),
    },
    {
      stage: OnboardingStage.BASICS,
      content: (
        <BasicsScreen
          onNextClicked={onSaveBasicsClicked}
          errors={errors}
          basics={basics!}
          steps={getSteps()}
          formTitle="first, the basics..."
          formComment="you'll be able to edit or remove this info later."
        />
      ),
    },
    {
      stage: OnboardingStage.QUESTIONNAIRE,
      content: promptWithResponse && (
        <PromptRespondTransitionScreen
          prompt={promptWithResponse!}
          onNextClicked={onNextPromptClicked}
          onBackClicked={asBack(onBackPromptClicked)}
          errors={promptErrors}
          steps={{ steps: getSteps() }}
        />
      ),
    },
    {
      stage: OnboardingStage.USERNAME,
      content: (
        <UsernameScreen
          onNextClicked={onNextUsernameClicked}
          errors={usernameErrors}
          onBackClicked={asBack(onBackUsernameClicked)}
          username={username}
          steps={getSteps()}
        />
      ),
    },
    {
      stage: OnboardingStage.COMPLETED,
      content: publicProfile && userSettings && (
        <OnboardingSettingsScreen
          onNextClicked={onNextSettingsClicked}
          setProfilePublicStatus={onSetPublicProfileStatus}
          isPublic={publicProfile.is_public}
          setMarketingOptIn={onSetMarketingOptIn}
          isOptedIn={userSettings.is_enrolled_in_marketing_email!}
        />
      ),
    },
  ];

  return (
    <BaseNavbarPage navbarProps={navbarProps}>
      <WizardScreen
        elements={getElements()}
        stage={getStageEnum()}
        isBack={isBack}
      />
    </BaseNavbarPage>
  );
};

export default OnboardingV2PageComponent;
