import React, { ReactNode } from "react";
import { classNames } from "../../util/strings";

type MDividerKind = "regular" | "gray";

type MDividerProps = {
  title?: string | null;
  className?: string | null;
  button?: ReactNode;
  kind?: MDividerKind;
  bgColor?: string | null;
};

const MDividerComponent = (props: MDividerProps) => {
  const { title, className, kind, button, bgColor } = props;

  const getBgColor = (): string => {
    if (bgColor) {
      return bgColor;
    }
    if (kind === "gray") {
      return "bg-secondary";
    }
    return "bg-m-white";
  };

  return (
    <div className={classNames("relative", className)}>
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div
          className={classNames(
            "w-full border-t",
            kind === "regular" ? "border-m-light-gray" : null,
            kind === "gray" ? "border-m-gray" : null
          )}
        />
      </div>
      <div className="relative flex items-center justify-between">
        {title && (
          <span
            className={classNames(
              "pr-3 text-base font-semibold leading-6 text-m-dark-gray",
              getBgColor()
            )}
          >
            {title}
          </span>
        )}
        {button && <div className={getBgColor()}>{button}</div>}
      </div>
    </div>
  );
};

MDividerComponent.defaultProps = {
  title: null,
  className: null,
  button: null,
  kind: "regular" as MDividerKind,
  bgColor: null,
};

export default MDividerComponent;
