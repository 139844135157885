/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface RateLLMResultsRequest
 */
export interface RateLLMResultsRequest {
  /**
   *
   * @type {string}
   * @memberof RateLLMResultsRequest
   */
  guid: string;
  /**
   *
   * @type {string}
   * @memberof RateLLMResultsRequest
   */
  call_type: string;
  /**
   *
   * @type {number}
   * @memberof RateLLMResultsRequest
   */
  rating: number;
}

export function RateLLMResultsRequestFromJSON(
  json: any
): RateLLMResultsRequest {
  return RateLLMResultsRequestFromJSONTyped(json, false);
}

export function RateLLMResultsRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RateLLMResultsRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: json["guid"],
    call_type: json["call_type"],
    rating: json["rating"],
  };
}

export function RateLLMResultsRequestToJSON(
  value?: RateLLMResultsRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    guid: value.guid,
    call_type: value.call_type,
    rating: value.rating,
  };
}
