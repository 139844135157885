/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  UserConnectionRequestRelatedDetail,
  UserConnectionRequestRelatedDetailFromJSON,
  UserConnectionRequestRelatedDetailFromJSONTyped,
  UserConnectionRequestRelatedDetailToJSON,
} from "./UserConnectionRequestRelatedDetail";

/**
 *
 * @export
 * @interface ListPendingReceivedConnectionRequestsResponse
 */
export interface ListPendingReceivedConnectionRequestsResponse {
  /**
   *
   * @type {Array<UserConnectionRequestRelatedDetail>}
   * @memberof ListPendingReceivedConnectionRequestsResponse
   */
  requests: Array<UserConnectionRequestRelatedDetail>;
  /**
   *
   * @type {number}
   * @memberof ListPendingReceivedConnectionRequestsResponse
   */
  count: number;
}

export function ListPendingReceivedConnectionRequestsResponseFromJSON(
  json: any
): ListPendingReceivedConnectionRequestsResponse {
  return ListPendingReceivedConnectionRequestsResponseFromJSONTyped(
    json,
    false
  );
}

export function ListPendingReceivedConnectionRequestsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ListPendingReceivedConnectionRequestsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    requests: (json["requests"] as Array<any>).map(
      UserConnectionRequestRelatedDetailFromJSON
    ),
    count: json["count"],
  };
}

export function ListPendingReceivedConnectionRequestsResponseToJSON(
  value?: ListPendingReceivedConnectionRequestsResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    requests: (value.requests as Array<any>).map(
      UserConnectionRequestRelatedDetailToJSON
    ),
    count: value.count,
  };
}
