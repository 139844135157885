import React, { useState } from "react";
import {
  GlimpseDetail,
  UpdateGlimpseTitleRequest,
} from "../../../../api/models";
import FormScreen from "../../../ui/screens/FormScreen";
import { useBusyWatcher } from "../../../../util/hooks";
import MInput from "../../../ui/MInput";
import MTextArea from "../../../ui/MTextArea";

type UpdateGlimpseTitleScreenProps = {
  glimpse: GlimpseDetail;
  errors: { [key: string]: string[] };
  onNextClicked: (newTitle: UpdateGlimpseTitleRequest) => void;
  onBackClicked: () => void;
};

const UpdateGlimpseTitleScreenComponent = (
  props: UpdateGlimpseTitleScreenProps
) => {
  const {
    glimpse,
    errors,
    onNextClicked: onNextClickedInput,
    onBackClicked,
  } = props;

  const [title, setTitle] = useState<string>(glimpse.title ?? "");
  const [description, setDescription] = useState<string>(
    glimpse.description ?? ""
  );
  const [busy, _] = useBusyWatcher();

  const getTitleErrors = (): string[] => {
    const toReturn: string[] = [];
    if (errors.title) {
      toReturn.push(...errors.title);
    }
    if (title.length > 64) {
      toReturn.push("title must be less than 64 characters");
    }
    return toReturn;
  };

  const getDescriptionErrors = (): string[] => {
    const toReturn: string[] = [];
    if (errors.description) {
      toReturn.push(...errors.description);
    }
    if (description.length > 256) {
      toReturn.push("description must be less than 256 characters");
    }
    return toReturn;
  };

  const canSubmit = () => {
    if (title.length > 64) {
      return false;
    }
    if (description.length > 256) {
      return false;
    }
    return true;
  };

  const onNextClicked = () => {
    const toSubmit: UpdateGlimpseTitleRequest = {
      glimpse: glimpse.guid!,
    };
    if (title !== "") {
      toSubmit.title = title;
    }
    if (description !== "") {
      toSubmit.description = description;
    }
    onNextClickedInput(toSubmit);
  };

  return (
    <FormScreen
      title="set your glimpse title"
      comment="this is optional and strictly for your own organization. other users will never see the glimpse's title or description."
      nextDisabled={busy || !canSubmit()}
      onNextClicked={onNextClicked}
      backDisabled={busy}
      onBackClicked={onBackClicked}
      stacked
    >
      <MInput
        label="title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        disabled={busy}
        onEnterPressed={() => canSubmit() && onNextClicked()}
        errors={getTitleErrors()}
        placeholder={'e.g. "Google SWE Internship"'}
      />
      <MTextArea
        label="description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        disabled={busy}
        errors={getDescriptionErrors()}
        placeholder="e.g. 'Job application on 10/28, submitted through Lever'"
      />
    </FormScreen>
  );
};

export default UpdateGlimpseTitleScreenComponent;
