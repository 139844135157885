import { createPortal } from "react-dom";
import React, { useState } from "react";
import { GlimpseLimits } from "../../../../api/models";
import SubscriptionUpsellDialog from "../../../dialogs/SubscriptionUpsellDialog";
import MVisibilityToggle from "../../../ui/MVisibilityToggle";
import MTooltipIcon from "../../../ui/MTooltipIcon";

type GlimpseVisibilityToggleProps = {
  isVisible: boolean;
  onToggleClicked: () => void;
  limits: GlimpseLimits;
};

const GlimpsesVisibilityToggleComponent = (
  props: GlimpseVisibilityToggleProps
) => {
  const { isVisible, onToggleClicked: onToggleClickedInput, limits } = props;

  const [showSubscriptionUpsell, setShowSubscriptionUpsell] =
    useState<boolean>(false);

  const onToggleClicked = () => {
    if (!isVisible && limits.remaining <= 0) {
      setShowSubscriptionUpsell(true);
      return;
    }
    onToggleClickedInput();
  };

  return (
    <>
      <div className="flex flex-row items-center gap-3">
        <MVisibilityToggle
          onClick={onToggleClicked}
          copy="live"
          isVisible={isVisible}
        />
        <MTooltipIcon
          tooltip={`when your glimpse is 'live' it will be viewable by anyone that knows the link. when a glimpse is not 'live' then it is only visible to you. you can only have ${limits.max} live glimpses at a time (you currently have ${limits.current} live and your maximum number can be increased via subscribing).`}
        />
      </div>
      {createPortal(
        <SubscriptionUpsellDialog
          upsellText={`you've reached your maximum number of live glimpses (${limits.max}). upgrade to a paid subscription to create more.`}
          show={showSubscriptionUpsell}
          onClose={() => setShowSubscriptionUpsell(false)}
        />,
        document.body
      )}
    </>
  );
};

export default GlimpsesVisibilityToggleComponent;
