/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CommCoachSingleEntryRelatedDetail,
  CommCoachSingleEntryRelatedDetailFromJSON,
  CommCoachSingleEntryRelatedDetailFromJSONTyped,
  CommCoachSingleEntryRelatedDetailToJSON,
} from "./CommCoachSingleEntryRelatedDetail";

/**
 *
 * @export
 * @interface GetSingleEntryResponse
 */
export interface GetSingleEntryResponse {
  /**
   *
   * @type {CommCoachSingleEntryRelatedDetail}
   * @memberof GetSingleEntryResponse
   */
  entry: CommCoachSingleEntryRelatedDetail;
  /**
   *
   * @type {boolean}
   * @memberof GetSingleEntryResponse
   */
  ready_for_submission: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof GetSingleEntryResponse
   */
  submission_errors: Array<string>;
  /**
   *
   * @type {string}
   * @memberof GetSingleEntryResponse
   */
  content?: string | null;
}

export function GetSingleEntryResponseFromJSON(
  json: any
): GetSingleEntryResponse {
  return GetSingleEntryResponseFromJSONTyped(json, false);
}

export function GetSingleEntryResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetSingleEntryResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    entry: CommCoachSingleEntryRelatedDetailFromJSON(json["entry"]),
    ready_for_submission: json["ready_for_submission"],
    submission_errors: json["submission_errors"],
    content: !exists(json, "content") ? undefined : json["content"],
  };
}

export function GetSingleEntryResponseToJSON(
  value?: GetSingleEntryResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    entry: CommCoachSingleEntryRelatedDetailToJSON(value.entry),
    ready_for_submission: value.ready_for_submission,
    submission_errors: value.submission_errors,
    content: value.content,
  };
}
