/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CommonProfileContents,
  CommonProfileContentsFromJSON,
  CommonProfileContentsFromJSONTyped,
  CommonProfileContentsToJSON,
} from "./CommonProfileContents";
import {
  ConnectionStatus,
  ConnectionStatusFromJSON,
  ConnectionStatusFromJSONTyped,
  ConnectionStatusToJSON,
} from "./ConnectionStatus";
import {
  SelfProfileContent,
  SelfProfileContentFromJSON,
  SelfProfileContentFromJSONTyped,
  SelfProfileContentToJSON,
} from "./SelfProfileContent";

/**
 *
 * @export
 * @interface GetProfileResponse
 */
export interface GetProfileResponse {
  /**
   *
   * @type {boolean}
   * @memberof GetProfileResponse
   */
  is_authed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetProfileResponse
   */
  is_onboarded: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetProfileResponse
   */
  is_self: boolean;
  /**
   *
   * @type {CommonProfileContents}
   * @memberof GetProfileResponse
   */
  profile: CommonProfileContents;
  /**
   *
   * @type {SelfProfileContent}
   * @memberof GetProfileResponse
   */
  self?: SelfProfileContent | null;
  /**
   *
   * @type {ConnectionStatus}
   * @memberof GetProfileResponse
   */
  connection?: ConnectionStatus | null;
}

export function GetProfileResponseFromJSON(json: any): GetProfileResponse {
  return GetProfileResponseFromJSONTyped(json, false);
}

export function GetProfileResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetProfileResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    is_authed: json["is_authed"],
    is_onboarded: json["is_onboarded"],
    is_self: json["is_self"],
    profile: CommonProfileContentsFromJSON(json["profile"]),
    self: !exists(json, "self")
      ? undefined
      : SelfProfileContentFromJSON(json["self"]),
    connection: !exists(json, "connection")
      ? undefined
      : ConnectionStatusFromJSON(json["connection"]),
  };
}

export function GetProfileResponseToJSON(
  value?: GetProfileResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    is_authed: value.is_authed,
    is_onboarded: value.is_onboarded,
    is_self: value.is_self,
    profile: CommonProfileContentsToJSON(value.profile),
    self: SelfProfileContentToJSON(value.self),
    connection: ConnectionStatusToJSON(value.connection),
  };
}
