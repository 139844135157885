/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GetOnboardingStageResponse
 */
export interface GetOnboardingStageResponse {
  /**
   *
   * @type {string}
   * @memberof GetOnboardingStageResponse
   */
  stage: GetOnboardingStageResponseStageEnum;
  /**
   *
   * @type {string}
   * @memberof GetOnboardingStageResponse
   */
  questionnaire: string;
}

/**
 * @export
 * @enum {string}
 */
export enum GetOnboardingStageResponseStageEnum {
  intro = "intro",
  basics = "basics",
  questionnaire = "questionnaire",
  username = "username",
  completed = "completed",
}

export function GetOnboardingStageResponseFromJSON(
  json: any
): GetOnboardingStageResponse {
  return GetOnboardingStageResponseFromJSONTyped(json, false);
}

export function GetOnboardingStageResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOnboardingStageResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    stage: json["stage"],
    questionnaire: json["questionnaire"],
  };
}

export function GetOnboardingStageResponseToJSON(
  value?: GetOnboardingStageResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    stage: value.stage,
    questionnaire: value.questionnaire,
  };
}
