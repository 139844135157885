import React from "react";
import { CommCoachSingleEntryRelatedDetail } from "../../../api/models";
import InsightContainer from "./InsightContainer";
import { CommonInsightCardProps } from "./common";

type CommCoachSingleEntryCardProps = CommonInsightCardProps & {
  entry: CommCoachSingleEntryRelatedDetail;
};

const CommCoachSingleEntryCardComponent = (
  props: CommCoachSingleEntryCardProps
) => {
  const { entry, ...rest } = props;

  return (
    <InsightContainer
      buttonLabel={`conversation plan for ${entry.about_user?.name} and ${entry.asking_user?.name}`}
      askingUser={entry.asking_user}
      aboutUser={entry.about_user!}
      title="CONVERSATION PLAN"
      timeCreated={entry.time_created!}
      body={entry.conversation}
      {...rest}
    />
  );
};

export default CommCoachSingleEntryCardComponent;
