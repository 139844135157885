import React, { Fragment, ReactNode } from "react";
import { Popover, Transition } from "@headlessui/react";
import { classNames } from "../../util/strings";

type MPopoverMenuProps = {
  button: ReactNode;
  panel: ReactNode;
};

const MPopoverMenuComponent = (props: MPopoverMenuProps) => {
  const { button, panel } = props;

  return (
    <Popover className="relative h-full">
      <Popover.Button className="h-full flex flex-col items-center gap-x-1 text-sm font-semibold leading-6 text-m-dark-gray rounded-lg bg-m-white">
        {button}
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          className={classNames(
            "absolute z-10 flex w-screen max-w-max translate-y-[5px] right-0"
          )}
        >
          <div className="max-w-md flex-auto overflow-hidden rounded-lg bg-m-white text-sm leading-6 shadow-lg ring-1 ring-m-light-gray">
            {panel}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default MPopoverMenuComponent;
