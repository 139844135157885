import React, { ReactNode, useState } from "react";
import { MNavProps } from "../../../ui/nav/MNav";
import {
  ContactUserViaGlimpseRequest,
  ViewGlimpseResponse,
} from "../../../../api/models";
import BaseNavbarPage from "../../BaseNavbarPage";
import ProfileCard from "../../../ui/cards/ProfileCard";
import { classNames, splitGlimpseContent } from "../../../../util/strings";
import { clickableText, titleText } from "../../../../util/style";
import MButton from "../../../ui/buttons/MButton";
import { useBusyWatcher } from "../../../../util/hooks";
import WorkHistorySection from "../../profile_v2/WorkHistorySection";
import LinksSection from "../../profile_v2/LinksSection";
import PromptResponsesSection from "../../profile_v2/PromptResponsesSection";
import { userDetailsHasVitals } from "../../../../util/user";
import VitalsSection from "../../profile_v2/VitalsSection";
import {
  addNextToUrl,
  addOriginToPath,
  addQueryParamToPath,
} from "../../../../util/url";
import MWellCopyLinkButton from "../../../ui/wells/MWellCopyLinkButton";
import MWellDeleteButton from "../../../ui/wells/MWellDeleteButton";
import { useAsyncConfirmationDialog } from "../../../dialogs/hooks";
import MWellEditButton from "../../../ui/wells/MWellEditButton";
import ProfileLayoutScreen from "../../../ui/screens/ProfileLayoutScreen";
import MEllipsisMenu from "../../../ui/MEllipsisMenu";
import GlimpseContactDialog from "./GlimpseContactDialog";
import GlimpseVisibilityToggle from "../edit/GlimpseVisibilityToggle";
import AttachmentsSection from "../../profile_v2/AttachmentsSection";

type ViewGlimpseScreenProps = {
  viewGlimpseUrl: string;
  editGlimpseUrl: string;
  forceOther: boolean;
  navbarProps: MNavProps;
  glimpse: ViewGlimpseResponse | null;
  glimpseId: string;
  onDeleteGlimpse: () => void;
  onToggleVisibility: () => void;
  onContactSubmitted: (
    toSend: ContactUserViaGlimpseRequest
  ) => Promise<boolean>;
  errors: { [key: string]: string[] };
  errorList: string[];
  isAuthed: boolean;
};

const ViewGlimpseScreenComponent = (props: ViewGlimpseScreenProps) => {
  const {
    viewGlimpseUrl,
    editGlimpseUrl,
    forceOther,
    navbarProps,
    glimpse,
    glimpseId,
    onDeleteGlimpse,
    onToggleVisibility,
    onContactSubmitted: onContactSubmittedInput,
    errors,
    errorList,
    isAuthed,
  } = props;

  const viewGlimpseLink = addOriginToPath(
    addQueryParamToPath(viewGlimpseUrl, "id", glimpseId)
  );
  const editGlimpseLink = addNextToUrl(
    addQueryParamToPath(editGlimpseUrl, "id", glimpseId)
  );
  const hasButtons =
    glimpse?.profile_can_be_viewed || glimpse?.can_be_contacted;
  const [showContactDialog, setShowContactDialog] = useState<boolean>(false);
  const [contactSent, setContactSent] = useState<boolean>(false);
  const isSelf = Boolean(!forceOther && glimpse?.self);
  const confirmDialog = useAsyncConfirmationDialog();
  const [busy, _] = useBusyWatcher();

  const contentChunks: string[] | null = glimpse?.glimpse?.content
    ? splitGlimpseContent(glimpse.glimpse.content)
    : null;

  const onContactClicked = () => {
    setContactSent(false);
    setShowContactDialog(true);
  };

  const onContactSubmitted = async (
    toSend: ContactUserViaGlimpseRequest
  ): Promise<boolean> => {
    const result = await onContactSubmittedInput(toSend);
    if (result) {
      setContactSent(true);
    }
    return result;
  };

  const onDeleteClicked = async () => {
    const result = await confirmDialog.confirm({
      title: `delete glimpse?`,
      body: "are you sure you want to delete this glimpse? this action cannot be undone.",
    });
    if (!result) {
      return;
    }
    onDeleteGlimpse();
  };

  const selfPushdown = (
    <div className="flex flex-row gap-3 text-m-white">
      <MWellCopyLinkButton className="text-m-white" url={viewGlimpseLink} />
      <MWellEditButton className="text-m-white" href={editGlimpseLink} />
      <MWellDeleteButton
        className="text-m-white"
        disabled={busy}
        onClick={onDeleteClicked}
      />
      <div className="grow" />
      {glimpse && glimpse.self && (
        <GlimpseVisibilityToggle
          isVisible={glimpse.self.visible}
          onToggleClicked={onToggleVisibility}
          limits={glimpse.self.limits}
        />
      )}
    </div>
  );

  const getProfileCard = (): ReactNode =>
    glimpse && <ProfileCard user={glimpse.user} />;

  const getProfileButtons = (): ReactNode => {
    if (!hasButtons) {
      return null;
    }
    if (!glimpse) {
      return null;
    }
    const buttons: ReactNode[] = [];
    if (glimpse.profile_can_be_viewed && !glimpse.can_be_contacted) {
      buttons.push(
        <MButton
          kind="primary"
          className="grow"
          href={`/@${glimpse.user.username}`}
        >
          view full profile
        </MButton>
      );
    } else {
      buttons.push(
        <MButton kind="primary" className="grow" onClick={onContactClicked}>
          get in touch
        </MButton>
      );
      if (glimpse.profile_can_be_viewed) {
        buttons.push(
          <MEllipsisMenu>
            <div className="flex flex-col items-center p-2">
              <div className={clickableText}>
                <a href={`/@${glimpse.user.username}`}>view full profile</a>
              </div>
            </div>
          </MEllipsisMenu>
        );
      }
    }
    return <div className="flex flex-row gap-3 items-center">{buttons}</div>;
  };

  const getAttachmentsSection = (): ReactNode =>
    glimpse &&
    glimpse.attachments.resume && (
      <AttachmentsSection
        bgColor="bg-primary-light"
        className="my-3"
        attachments={glimpse.attachments}
        location="glimpse"
      />
    );

  const getVitalsSection = (): ReactNode =>
    glimpse &&
    userDetailsHasVitals(glimpse.user) && (
      <VitalsSection
        bgColor="bg-primary-light"
        className="my-3"
        user={glimpse.user}
      />
    );

  const getLinksSection = (): ReactNode =>
    glimpse?.links &&
    glimpse.links.length > 0 && (
      <LinksSection
        bgColor="bg-primary-light"
        className="my-3"
        links={glimpse.links}
      />
    );

  const getContentSection = (): ReactNode =>
    contentChunks?.map((chunk, i) => {
      if (i === 0) {
        return <div className={classNames(titleText)}>{chunk}</div>;
      }
      return <div>{chunk}</div>;
    });

  const getWorkHistorySection = (): ReactNode =>
    glimpse?.work_history &&
    glimpse.work_history.length > 0 && (
      <WorkHistorySection
        bgColor="bg-primary-light"
        className="my-3"
        workHistory={glimpse.work_history}
      />
    );

  const getQuestionsSection = (): ReactNode =>
    glimpse?.prompts &&
    glimpse.prompts.length > 0 && (
      <PromptResponsesSection
        bgColor="bg-primary-light"
        className="my-3"
        promptResponses={glimpse.prompts}
      />
    );

  return (
    <>
      <BaseNavbarPage
        navbarProps={navbarProps}
        pushdownClassname="bg-m-gray"
        pushdown={isSelf ? selfPushdown : null}
        fullWidth
      >
        <ProfileLayoutScreen
          loading={!glimpse}
          leftColumn={
            <>
              {getProfileCard()}
              {getProfileButtons()}
              {getAttachmentsSection()}
              {getVitalsSection()}
              {getLinksSection()}
            </>
          }
          rightTopColumn={getContentSection()}
          bottomLeftColumn={getWorkHistorySection()}
          bottomRightColumn={getQuestionsSection()}
          mobileColumn={
            <>
              {getProfileCard()}
              {getProfileButtons()}
              {getContentSection()}
              {getVitalsSection()}
              {getLinksSection()}
              {getAttachmentsSection()}
              {getWorkHistorySection()}
              {getQuestionsSection()}
            </>
          }
        />
      </BaseNavbarPage>
      {confirmDialog.dialog}
      <GlimpseContactDialog
        show={showContactDialog}
        glimpseId={glimpseId}
        onClose={() => setShowContactDialog(false)}
        onSend={onContactSubmitted}
        recipientName={glimpse?.user.name ?? ""}
        isAuthenticated={isAuthed}
        errors={errors}
        errorList={errorList}
        success={contactSent}
      />
    </>
  );
};

export default ViewGlimpseScreenComponent;
