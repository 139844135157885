import React, { useState } from "react";
import BaseDialog from "./BaseDialog";
import MFileSelect from "../ui/MFileSelect";
import { useBusyWatcher } from "../../util/hooks";
import MNextButton from "../ui/buttons/MNextButton";

export type FileSelectDialogProps = {
  title?: string;
  allowImages?: boolean;
  allowPdfs?: boolean;
  allowText?: boolean;
  maxFiles?: number;
  maxSize?: number;
};

type innerProps = FileSelectDialogProps & {
  onClose: (files: File[] | null) => void;
  show: boolean;
};

const FileSelectDialogComponent = (props: innerProps) => {
  const { show, onClose, title, ...rest } = props;

  const [files, setFiles] = useState<File[]>([]);
  const [busy, _] = useBusyWatcher();

  return (
    <BaseDialog
      title={title!}
      show={show}
      onClose={() => onClose(null)}
      className="max-w-lg"
    >
      <MFileSelect {...rest} onFilesChanged={setFiles} disabled={busy} />
      <div className="flex flex-row-reverse">
        <MNextButton
          kind="primary"
          disabled={busy || files.length === 0}
          onClick={() => onClose(files)}
          text="done"
        />
      </div>
    </BaseDialog>
  );
};

FileSelectDialogComponent.defaultProps = {
  title: "pick file",
  allowImages: false,
  allowPdfs: false,
  allowText: false,
  maxFiles: 1,
  maxSize: 2 ** 21, // 2MB
};

export default FileSelectDialogComponent;
