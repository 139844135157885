import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { createPortal } from "react-dom";
import {
  middleColumnContent,
  middleColumnWrapper,
  middleFormContent,
  middleFormWrapper,
} from "../../../util/style";
import { classNames } from "../../../util/strings";
import FadeInOutTransition from "../transitions/FadeInOutTransition";

type CenterColumnScreenProps = {
  className?: string | null;
  fullWidth?: boolean;
  stickyFooterContent?: ReactNode | null;
};

const CenterColumnScreenComponent = (
  props: PropsWithChildren<CenterColumnScreenProps>
) => {
  const { className, fullWidth, stickyFooterContent, children } = props;

  const [showFooter, setShowFooter] = useState<boolean>(false);

  useEffect(() => {
    if (!stickyFooterContent) {
      setShowFooter(false);
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }
    setTimeout(() => {
      setShowFooter(true);
    }, 10);
    return () => setShowFooter(false);
  }, []);

  return (
    <div className={fullWidth ? middleColumnWrapper : middleFormWrapper}>
      <div
        className={classNames(
          fullWidth ? middleColumnContent : middleFormContent,
          "flex-1 flex flex-col",
          className
        )}
      >
        {children}
        {stickyFooterContent && (
          <>
            <div className="grow sm:hidden" />
            <div className="mt-8 invisible sm:visible">
              {stickyFooterContent}
            </div>
            {createPortal(
              <FadeInOutTransition show={showFooter}>
                <div className="visible sm:invisible fixed bottom-0 left-0 w-full px-6 pt-3 pb-6 bg-secondary drop-shadow-m-white-up">
                  {stickyFooterContent}
                </div>
              </FadeInOutTransition>,
              document.body
            )}
          </>
        )}
      </div>
    </div>
  );
};

CenterColumnScreenComponent.defaultProps = {
  className: null,
  fullWidth: false,
  stickyFooterContent: null,
};

export default CenterColumnScreenComponent;
