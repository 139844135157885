import React from "react";
import { classNames } from "../../util/strings";

type Kind = "small" | "regular";

type MPillProps = {
  content: string;
  kind?: Kind;
  className?: string | null;
};

const MPillComponent = (props: MPillProps) => {
  const { content, kind, className } = props;

  return (
    <span
      className={classNames(
        "inline-flex items-center rounded-full bg-primary/30 font-medium text-primary",
        kind === "small" ? "text-xs px-1.5 py-0.5" : "px-2 py-1",
        className
      )}
    >
      {content}
    </span>
  );
};

MPillComponent.defaultProps = {
  kind: "regular" as Kind,
  className: null,
};

export default MPillComponent;
