import React from "react";
import MTitleWell from "../../../ui/wells/MTitleWell";
import MAIWell from "../../../ui/wells/MAIWell";
import MTextArea from "../../../ui/MTextArea";
import { useBusyWatcher } from "../../../../util/hooks";

type BioSuggestionProps = {
  existingComment: string;
  existing?: string | null;
  suggestedComment: string;
  suggested: string;
  onContentUpdated: (content: string) => void;
  errors: string[];
};

const BioSuggestionsComponent = (props: BioSuggestionProps) => {
  const {
    existingComment,
    existing,
    suggestedComment,
    suggested,
    onContentUpdated,
    errors,
  } = props;

  const [busy, _] = useBusyWatcher();

  return (
    <div className="flex flex-col gap-3">
      {existing && (
        <MTitleWell title={existingComment} kind="white">
          {existing}
        </MTitleWell>
      )}
      <MAIWell title={suggestedComment}>
        <MTextArea
          value={suggested}
          errors={errors}
          disabled={busy}
          onChange={(e) => onContentUpdated(e.target.value)}
        />
      </MAIWell>
    </div>
  );
};

BioSuggestionsComponent.defaultProps = {
  existing: null,
};

export default BioSuggestionsComponent;
