import {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useResizeObserver from "@react-hook/resize-observer";
import { GlobalContext, StateUpdate } from "./context";
import {
  DEFAULT_TRANSITION_ENTER_DURATION,
  DEFAULT_TRANSITION_LEAVE_DURATION,
  DEFAULT_TRANSITION_PADDING,
} from "./constants";
import { getUniqueId } from "./random";

export const useFocus = (): [any, () => void] => {
  const htmlElRef = useRef<HTMLInputElement>(null);
  const setFocus = () => {
    if (htmlElRef.current) {
      htmlElRef.current.focus();
    }
  };

  return [htmlElRef, setFocus];
};

// https://keyholesoftware.com/2022/07/13/cancel-a-react-modal-with-escape-key-or-external-click/

const KEY_NAME_ESC = "Escape";
const KEY_EVENT_TYPE = "keyup";

export const useEscapeKey = (callback: () => void) => {
  const handleEscKey = useCallback(
    (event: any) => {
      if (event.key === KEY_NAME_ESC) {
        callback();
      }
    },
    [callback]
  );

  useEffect(() => {
    document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);

    return () => {
      document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
    };
  }, [handleEscKey]);
};

export const useBusyWatcher = (): [
  boolean,
  <Type>(p: Promise<Type>) => Promise<Type>
] => {
  // One thing that's tricky here is that requests are often made back to back, which will
  // result in the busy state being set to true and then immediately set to false. To avoid
  // this, we immediately set busy state to true when we observe an outbound request, but we
  // debounce the busy state to false so that it only goes back to false after a short delay.

  const busyRef = useRef<boolean>(false);
  const [state, dispatch] = useContext(GlobalContext);
  const [isBusy, setIsBusy] = useState<boolean>(busyRef.current);

  useEffect(() => {
    busyRef.current = state.busy;
    if (state.busy && !isBusy) {
      setIsBusy(true);
    }
    if (!state.busy && isBusy) {
      setTimeout(() => {
        if (!busyRef.current) {
          setIsBusy(false);
        }
      }, 100);
    }
  }, [state.busy]);

  const promiseWrapper = async <Type>(
    promise: Promise<Type>
  ): Promise<Type> => {
    dispatch(StateUpdate.INCR_BUSY_COUNTER);
    try {
      return await promise;
    } finally {
      dispatch(StateUpdate.DECR_BUSY_COUNTER);
    }
  };

  return [isBusy, promiseWrapper];
};

export const useBackTimer = (
  stage: any,
  durationMs = DEFAULT_TRANSITION_ENTER_DURATION +
    DEFAULT_TRANSITION_LEAVE_DURATION +
    DEFAULT_TRANSITION_PADDING
): [boolean, (fn?: () => any) => () => any] => {
  const [back, setBack] = useState<boolean>(false);

  const asBack =
    (fn?: () => any): (() => any) =>
    () => {
      setBack(true);
      if (fn) {
        fn();
      }
    };

  useEffect(() => {
    setTimeout(() => {
      if (back) {
        setBack(false);
      }
    }, durationMs);
  }, [stage]);

  return [back, asBack];
};

export const useUniqueId = (): string => useMemo(() => getUniqueId(), []);

// https://www.npmjs.com/package/@react-hook/resize-observer

export const useSize = <T extends HTMLElement>(
  target: RefObject<T>
): DOMRect | null => {
  const [size, setSize] = useState<DOMRect | null>(null);

  useLayoutEffect(() => {
    if (target && target.current) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [target]);

  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};
