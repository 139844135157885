/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface QuestionOperationsResponse
 */
export interface QuestionOperationsResponse {
  /**
   *
   * @type {boolean}
   * @memberof QuestionOperationsResponse
   */
  can_delete: boolean;
  /**
   *
   * @type {boolean}
   * @memberof QuestionOperationsResponse
   */
  can_anonymize: boolean;
  /**
   *
   * @type {boolean}
   * @memberof QuestionOperationsResponse
   */
  can_update: boolean;
}

export function QuestionOperationsResponseFromJSON(
  json: any
): QuestionOperationsResponse {
  return QuestionOperationsResponseFromJSONTyped(json, false);
}

export function QuestionOperationsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): QuestionOperationsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    can_delete: json["can_delete"],
    can_anonymize: json["can_anonymize"],
    can_update: json["can_update"],
  };
}

export function QuestionOperationsResponseToJSON(
  value?: QuestionOperationsResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    can_delete: value.can_delete,
    can_anonymize: value.can_anonymize,
    can_update: value.can_update,
  };
}
