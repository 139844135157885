import React, { useMemo } from "react";
import MStepsCircles, { MStepsCirclesProps } from "../../ui/MStepsCircles";
import MTitle from "../../ui/MTitle";

type CommCoachHeaderProps = {
  progress: MStepsCirclesProps;
};

const CommCoachHeaderComponent = (props: CommCoachHeaderProps) => {
  const { progress: progressInput } = props;

  const progress = useMemo(() => progressInput, []);

  return (
    <div className="w-full bg-primary p-2 mb-3">
      <MTitle
        text="conversation planner"
        className="text-center text-m-white font-bold"
      />
      <div className="text-m-white">
        <MStepsCircles {...progress} kind="light" />
      </div>
    </div>
  );
};

export default CommCoachHeaderComponent;
