import React, { ReactNode } from "react";
import NotificationBox from "./NotificationBox";
import MButton from "../buttons/MButton";
import NotificationUndoBox from "./NotificationUndoBox";
import { CommonNotificationProps } from "./common";

type MessageWithLinkNotificationBoxProps = CommonNotificationProps & {
  onUndoClicked: () => void;
};

const MessageWithLinkNotificationBoxComponent = (
  props: MessageWithLinkNotificationBoxProps
) => {
  const { notif, isCleared, onClearClicked, onUndoClicked } = props;

  const getFooter = (): ReactNode => {
    if (!notif.message_with_link!.href) {
      return null;
    }
    return (
      <MButton
        kind="primary"
        href={notif.message_with_link!.href}
        target={notif.message_with_link!.separate_window ? "_blank" : undefined}
      >
        {notif.message_with_link!.button_text}
      </MButton>
    );
  };

  if (isCleared) {
    return (
      <NotificationUndoBox
        onUndoClicked={onUndoClicked}
        onClearClicked={onClearClicked}
      />
    );
  }

  return (
    <NotificationBox
      body={notif.message_with_link!.message}
      footer={getFooter()}
      onClearClicked={onClearClicked}
      isNew={!notif.is_seen}
      imageHref={notif.image_url}
      timeCreated={notif.time_created!}
    />
  );
};

export default MessageWithLinkNotificationBoxComponent;
