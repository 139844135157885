/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface InsightsLimits
 */
export interface InsightsLimits {
  /**
   *
   * @type {number}
   * @memberof InsightsLimits
   */
  max: number;
  /**
   *
   * @type {number}
   * @memberof InsightsLimits
   */
  current: number;
  /**
   *
   * @type {number}
   * @memberof InsightsLimits
   */
  remaining: number;
}

export function InsightsLimitsFromJSON(json: any): InsightsLimits {
  return InsightsLimitsFromJSONTyped(json, false);
}

export function InsightsLimitsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InsightsLimits {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    max: json["max"],
    current: json["current"],
    remaining: json["remaining"],
  };
}

export function InsightsLimitsToJSON(value?: InsightsLimits | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    max: value.max,
    current: value.current,
    remaining: value.remaining,
  };
}
