/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  UserProfileSummary,
  UserProfileSummaryFromJSON,
  UserProfileSummaryFromJSONTyped,
  UserProfileSummaryToJSON,
} from "./UserProfileSummary";

/**
 *
 * @export
 * @interface UserCollabRelatedDetail
 */
export interface UserCollabRelatedDetail {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof UserCollabRelatedDetail
   */
  readonly guid?: string;
  /**
   * The task ID for the Celery task that was enqueued to perform the collab.
   * @type {string}
   * @memberof UserCollabRelatedDetail
   */
  task_id?: string | null;
  /**
   * Whether or not the referenced Celery task has been completed.
   * @type {boolean}
   * @memberof UserCollabRelatedDetail
   */
  is_task_completed?: boolean;
  /**
   * Whether or not the asking user's profile state, as used for this collaboration, can now be considered stale (as their profile has new information available).
   * @type {boolean}
   * @memberof UserCollabRelatedDetail
   */
  asking_user_state_stale?: boolean;
  /**
   * Whether or not the about user's profile state, as used for this collaboration, can now be considered stale (as their profile has new information available).
   * @type {boolean}
   * @memberof UserCollabRelatedDetail
   */
  about_user_state_stale?: boolean;
  /**
   * The time at which this record was created.
   * @type {Date}
   * @memberof UserCollabRelatedDetail
   */
  readonly time_created?: Date;
  /**
   *
   * @type {UserProfileSummary}
   * @memberof UserCollabRelatedDetail
   */
  asking_user?: UserProfileSummary;
  /**
   *
   * @type {UserProfileSummary}
   * @memberof UserCollabRelatedDetail
   */
  about_user?: UserProfileSummary;
}

export function UserCollabRelatedDetailFromJSON(
  json: any
): UserCollabRelatedDetail {
  return UserCollabRelatedDetailFromJSONTyped(json, false);
}

export function UserCollabRelatedDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserCollabRelatedDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    task_id: !exists(json, "task_id") ? undefined : json["task_id"],
    is_task_completed: !exists(json, "is_task_completed")
      ? undefined
      : json["is_task_completed"],
    asking_user_state_stale: !exists(json, "asking_user_state_stale")
      ? undefined
      : json["asking_user_state_stale"],
    about_user_state_stale: !exists(json, "about_user_state_stale")
      ? undefined
      : json["about_user_state_stale"],
    time_created: !exists(json, "time_created")
      ? undefined
      : new Date(json["time_created"]),
    asking_user: !exists(json, "asking_user")
      ? undefined
      : UserProfileSummaryFromJSON(json["asking_user"]),
    about_user: !exists(json, "about_user")
      ? undefined
      : UserProfileSummaryFromJSON(json["about_user"]),
  };
}

export function UserCollabRelatedDetailToJSON(
  value?: UserCollabRelatedDetail | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    task_id: value.task_id,
    is_task_completed: value.is_task_completed,
    asking_user_state_stale: value.asking_user_state_stale,
    about_user_state_stale: value.about_user_state_stale,
    asking_user: UserProfileSummaryToJSON(value.asking_user),
    about_user: UserProfileSummaryToJSON(value.about_user),
  };
}
