import React, { ReactElement } from "react";
import MessageBox from "../ui/MessageBox";
import { MNavProps } from "../ui/nav/MNav";
import BaseNavbarPage from "./BaseNavbarPage";

type MagicLinkNotFoundPageProps = {
  navbarProps: MNavProps;
  loginUrl: string;
  linkType: string;
};

const MagicLinkNotFoundPageComponent = (props: MagicLinkNotFoundPageProps) => {
  const { navbarProps, loginUrl, linkType } = props;

  const getResolutionBody = (): ReactElement => {
    if (linkType === "auth") {
      return (
        <a className="text-blue-500 hover:underline" href={loginUrl}>
          request a fresh link when you're ready here.
        </a>
      );
    }
    if (linkType === "pair_slack") {
      return (
        <span>head back to Slack and request a new link to try again.</span>
      );
    }
    return <div />;
  };

  return (
    <BaseNavbarPage navbarProps={navbarProps}>
      <div className="p-4">
        <MessageBox>
          <div className="max-w-2xl flex flex-col gap-12">
            <div>oops, that link didn't work.</div>
            <div>
              most likely this happened because our login links are only valid
              for 5 minutes (it's safer that way). {getResolutionBody()}
            </div>
          </div>
        </MessageBox>
      </div>
    </BaseNavbarPage>
  );
};

export default MagicLinkNotFoundPageComponent;
