/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  UserGroup,
  UserGroupFromJSON,
  UserGroupFromJSONTyped,
  UserGroupToJSON,
} from "./UserGroup";

/**
 *
 * @export
 * @interface ListGroupsResponse
 */
export interface ListGroupsResponse {
  /**
   *
   * @type {Array<UserGroup>}
   * @memberof ListGroupsResponse
   */
  groups: Array<UserGroup>;
}

export function ListGroupsResponseFromJSON(json: any): ListGroupsResponse {
  return ListGroupsResponseFromJSONTyped(json, false);
}

export function ListGroupsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ListGroupsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    groups: (json["groups"] as Array<any>).map(UserGroupFromJSON),
  };
}

export function ListGroupsResponseToJSON(
  value?: ListGroupsResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    groups: (value.groups as Array<any>).map(UserGroupToJSON),
  };
}
