import React, { useEffect, useState } from "react";
import { usePostHog } from "posthog-js/react";
import { MNavProps } from "../../../ui/nav/MNav";
import { useBackTimer, useBusyWatcher } from "../../../../util/hooks";
import WizardScreen, {
  WizardScreenPanel,
} from "../../../ui/screens/WizardScreen";
import LoadingScreen from "../../../ui/screens/LoadingScreen";
import BaseNavbarPage from "../../BaseNavbarPage";
import PickQuestionTypeScreen from "./PickQuestionTypeScreen";
import EditFillInBlankQuestionScreen from "../edit/EditFillInBlankQuestionScreen";
import { useWrappedConnector } from "../../../../api/connector";
import {
  EitherOr,
  FillInBlank,
  GetEventTypeEventEnum,
  MultiSelect,
  PromptContent,
  PromptRelatedDetailSerializer,
  SearchValidTagsResponse,
} from "../../../../api/models";
import { addQueryParamToPath } from "../../../../util/url";
import EditEitherOrQuestionScreen from "../edit/EditEitherOrQuestionScreen";
import EditMultiSelectQuestionScreen from "../edit/EditMultiSelectQuestionScreen";

type CreateQuestionPageProps = {
  navbarProps: MNavProps;
  viewQuestionUrl: string;
};

enum CreateQuestionStage {
  LOADING,
  LANDING,
  FILL_IN_BLANK,
  EITHER_OR,
  MULTI_SELECT,
}

const CreateQuestionPageComponent = (props: CreateQuestionPageProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { navbarProps, viewQuestionUrl } = props;

  const [errors, setErrors] = useState<string[]>([]);
  const [errorMap, setErrorMap] = useState<{ [key: string]: string[] }>({});
  const [stage, setStage] = useState<CreateQuestionStage>(
    CreateQuestionStage.LOADING
  );
  const [isBack, asBack] = useBackTimer(stage);
  const [_, busyWatcher] = useBusyWatcher();
  const [tagsSearchResults, setTagsSearchResults] =
    useState<SearchValidTagsResponse | null>(null);
  const [tagsSearching, setTagsSearching] = useState<boolean>(false);
  const posthog = usePostHog();
  const connector = useWrappedConnector(busyWatcher);

  const goToStage = async (nextStage: CreateQuestionStage) => {
    setErrors([]);
    setErrorMap({});
    // eslint-disable-next-line default-case,no-empty
    switch (nextStage) {
    }
    setStage(nextStage);
  };

  const createQuestion = async (
    toSave: PromptContent
  ): Promise<PromptRelatedDetailSerializer | null> => {
    setErrors([]);
    setErrorMap({});
    try {
      return await connector.createQuestion(toSave);
    } catch (e: any) {
      const jsonBody = await e.json();
      if (jsonBody.m) {
        setErrors([jsonBody.m]);
      }
      if (jsonBody.e?.fill_in_blank) {
        setErrorMap(jsonBody.e.fill_in_blank);
      }
      if (jsonBody.e?.either_or) {
        setErrorMap(jsonBody.e.either_or);
      }
      if (jsonBody.e?.multi_select) {
        setErrorMap(jsonBody.e.multi_select);
      }
    }
    return null;
  };

  const onCreateQuestionNextClicked = async (toSave: PromptContent) => {
    const newPrompt = await createQuestion(toSave);
    if (!newPrompt) {
      return;
    }
    window.location.href = addQueryParamToPath(
      viewQuestionUrl,
      "prompt",
      newPrompt.c!.guid
    );
  };

  const onTagsSearchTermUpdated = async (newTerm: string) => {
    setTagsSearching(true);
    const result = await connector.searchQuestionTags(newTerm);
    setTagsSearchResults(result.c!);
    setTagsSearching(false);
  };

  useEffect(() => {
    goToStage(CreateQuestionStage.LANDING);
    posthog.capture(GetEventTypeEventEnum.create_question_page_viewed);
  }, []);

  const getElements = (): WizardScreenPanel[] => [
    {
      stage: CreateQuestionStage.LOADING,
      content: (
        <LoadingScreen
          errors={errors}
          onBackClicked={() => window.history.back()}
        />
      ),
    },
    {
      stage: CreateQuestionStage.LANDING,
      content: (
        <PickQuestionTypeScreen
          onFillInBlankClicked={() =>
            goToStage(CreateQuestionStage.FILL_IN_BLANK)
          }
          onEitherOrClicked={() => goToStage(CreateQuestionStage.EITHER_OR)}
          onMultiSelectClicked={() =>
            goToStage(CreateQuestionStage.MULTI_SELECT)
          }
          onBackClicked={() => window.history.back()}
        />
      ),
    },
    {
      stage: CreateQuestionStage.FILL_IN_BLANK,
      content: (
        <EditFillInBlankQuestionScreen
          onBackClicked={asBack(() => goToStage(CreateQuestionStage.LANDING))}
          onNextClicked={(result: FillInBlank) =>
            onCreateQuestionNextClicked({ fill_in_blank: result })
          }
          errors={errors}
          errorMap={errorMap}
          onTagSearchTermUpdated={onTagsSearchTermUpdated}
          tagsSearching={tagsSearching}
          tags={tagsSearchResults}
          isCreate
        />
      ),
    },
    {
      stage: CreateQuestionStage.EITHER_OR,
      content: (
        <EditEitherOrQuestionScreen
          onBackClicked={asBack(() => goToStage(CreateQuestionStage.LANDING))}
          onNextClicked={(result: EitherOr) =>
            onCreateQuestionNextClicked({ either_or: result })
          }
          errors={errors}
          errorMap={errorMap}
          onTagSearchTermUpdated={onTagsSearchTermUpdated}
          tagsSearching={tagsSearching}
          tags={tagsSearchResults}
          isCreate
        />
      ),
    },
    {
      stage: CreateQuestionStage.MULTI_SELECT,
      content: (
        <EditMultiSelectQuestionScreen
          onBackClicked={asBack(() => goToStage(CreateQuestionStage.LANDING))}
          onNextClicked={(result: MultiSelect) =>
            onCreateQuestionNextClicked({ multi_select: result })
          }
          errors={errors}
          errorMap={errorMap}
          onTagSearchTermUpdated={onTagsSearchTermUpdated}
          tagsSearching={tagsSearching}
          tags={tagsSearchResults}
          isCreate
        />
      ),
    },
  ];

  return (
    <BaseNavbarPage navbarProps={navbarProps}>
      <WizardScreen isBack={isBack} elements={getElements()} stage={stage} />
    </BaseNavbarPage>
  );
};

export default CreateQuestionPageComponent;
