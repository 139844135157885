/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface DeleteProfileAccessRequest
 */
export interface DeleteProfileAccessRequest {
  /**
   *
   * @type {string}
   * @memberof DeleteProfileAccessRequest
   */
  access: string;
}

export function DeleteProfileAccessRequestFromJSON(
  json: any
): DeleteProfileAccessRequest {
  return DeleteProfileAccessRequestFromJSONTyped(json, false);
}

export function DeleteProfileAccessRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeleteProfileAccessRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    access: json["access"],
  };
}

export function DeleteProfileAccessRequestToJSON(
  value?: DeleteProfileAccessRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    access: value.access,
  };
}
