import React from "react";
import { CommonInputRowProps } from "./common";
import { useBusyWatcher } from "../../../../util/hooks";
import SettingsLabelRow from "./SidebarLabelRow";
import MButton from "../../buttons/MButton";
import MLoadingDateInput from "../../MLoadingDateInput";

type SidebarDateInputRowProps = CommonInputRowProps & {
  value: Date | null;
  onValueUpdated: (value: Date | null) => void;
  onUpdateClicked: () => void;
};

const SidebarDateInputRowComponent = (props: SidebarDateInputRowProps) => {
  const {
    label,
    value,
    onValueUpdated,
    onUpdateClicked,
    loading,
    updateDisabled,
    errors,
    tooltip,
  } = props;

  const [busy, _] = useBusyWatcher();

  return (
    <SettingsLabelRow
      label={label}
      tooltip={tooltip}
      middle={
        <div className="w-full max-w-sm">
          <MLoadingDateInput
            loading={loading}
            dateValue={value}
            onDateChanged={onValueUpdated}
            errors={errors}
          />
        </div>
      }
      end={
        <MButton disabled={busy || updateDisabled} onClick={onUpdateClicked}>
          update
        </MButton>
      }
    />
  );
};

export default SidebarDateInputRowComponent;
