import React, { PropsWithChildren } from "react";

const MessageBoxComponent = (props: PropsWithChildren<object>) => {
  const { children } = props;
  return (
    <div className="w-full flex flex-row justify-center">
      <div className="bg-m-white text-m-dark-gray">
        <div className="px-6 py-6 text-2xl">{children}</div>
      </div>
    </div>
  );
};

export default MessageBoxComponent;
