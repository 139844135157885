import React from "react";
import MPaginationDescription from "./MPaginationDescription";
import MPaginationNumbers from "./MPaginationNumbers";

type MPaginationRowProps = {
  pageSize: number | null;
  startIndex: number | null;
  totalCount: number | null;
  onPageClicked: (page: number) => void;
  disabled?: boolean;
  copy?: string;
};

const MPaginationRowComponent = (props: MPaginationRowProps) => {
  const {
    pageSize: pageSizeInput,
    startIndex: startIndexInput,
    totalCount: totalCountInput,
    onPageClicked,
    disabled,
    copy,
  } = props;

  const pageSize = pageSizeInput || 0;
  const startIndex = startIndexInput || 0;
  const totalCount = totalCountInput || 0;
  let curPage: number;
  let maxPage: number;
  if (pageSize !== 0) {
    curPage = Math.floor(startIndex / pageSize) + 1;
    maxPage = Math.ceil(totalCount / pageSize);
  } else {
    curPage = 0;
    maxPage = 0;
  }

  const getShowPaginationNumbers = (): boolean => {
    if (maxPage === curPage && curPage <= 1) {
      return false;
    }
    return true;
  };

  return (
    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center w-full gap-3">
      <MPaginationDescription
        startIndex={startIndex}
        pageSize={pageSize}
        total={totalCount}
        copy={copy}
      />
      {getShowPaginationNumbers() && (
        <MPaginationNumbers
          curPage={curPage}
          maxPage={maxPage}
          onPageClicked={onPageClicked}
          disabled={disabled}
        />
      )}
    </div>
  );
};

MPaginationRowComponent.defaultProps = {
  disabled: false,
  copy: "results",
};

export default MPaginationRowComponent;
