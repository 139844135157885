import React, { useState } from "react";
import {
  GetUserVitalsResponse,
  SetUserVitalsRequest,
  SetUserVitalsRequestBeliefsEnum,
  SetUserVitalsRequestRelationshipEnum,
  SetUserVitalsRequestSexualOrientationEnum,
} from "../../../../api/models";
import { isInteger } from "../../../../util/numbers";
import FormScreen from "../../../ui/screens/FormScreen";
import { useBusyWatcher } from "../../../../util/hooks";
import MInput from "../../../ui/MInput";
import MSelect, { MSelectOption } from "../../../ui/MSelect";

type UpdateVitalsScreenProps = {
  vitals: GetUserVitalsResponse;
  onSaveClicked: (date: SetUserVitalsRequest) => void;
  errors: { [key: string]: string[] };
};

const UpdateVitalsScreenComponent = (props: UpdateVitalsScreenProps) => {
  const { vitals, onSaveClicked, errors } = props;

  const [pronouns, setPronouns] = useState<string | null>(vitals.pronouns);
  const [relationshipStatus, setRelationshipStatus] = useState<string | null>(
    vitals.relationship
  );
  const [kids, setKids] = useState<string | null>(
    vitals.kids ? vitals.kids.toString() : null
  );
  const [beliefs, setBeliefs] = useState<string | null>(vitals.beliefs);
  const [sexualOrientation, setSexualOrientation] = useState<string | null>(
    vitals.sexual_orientation
  );
  const [ethnicity, setEthnicity] = useState<string | null>(vitals.ethnicity);
  const [mbti, setMbti] = useState<string | null>(vitals.mbti);
  const [loveLanguage, setLoveLanguage] = useState<string | null>(
    vitals.love_language
  );
  const [enneagram, setEnneagram] = useState<string | null>(vitals.enneagram);
  const [zodiac, setZodiac] = useState<string | null>(vitals.zodiac);
  const [humanDesign, setHumanDesign] = useState<string | null>(
    vitals.human_design
  );
  const [busy, _] = useBusyWatcher();

  const canSubmit = (): boolean => {
    if (pronouns && pronouns.length > 32) {
      return false;
    }
    if (kids && (kids.length > 10 || !isInteger(kids))) {
      return false;
    }
    if (ethnicity && ethnicity.length > 64) {
      return false;
    }
    if (mbti && mbti.length > 64) {
      return false;
    }
    if (loveLanguage && loveLanguage.length > 64) {
      return false;
    }
    if (enneagram && enneagram.length > 64) {
      return false;
    }
    if (zodiac && zodiac.length > 64) {
      return false;
    }
    if (humanDesign && humanDesign.length > 64) {
      return false;
    }
    return true;
  };

  const submit = () => {
    onSaveClicked({
      pronouns: pronouns !== "" ? pronouns : null,
      relationship:
        (relationshipStatus as SetUserVitalsRequestRelationshipEnum) || null,
      kids: kids && kids !== "" ? parseInt(kids, 10) : null,
      beliefs: (beliefs as SetUserVitalsRequestBeliefsEnum) || null,
      sexual_orientation:
        (sexualOrientation as SetUserVitalsRequestSexualOrientationEnum) ||
        null,
      ethnicity: ethnicity !== "" ? ethnicity : null,
      mbti: mbti !== "" ? mbti : null,
      love_language: loveLanguage !== "" ? loveLanguage : null,
      enneagram: enneagram !== "" ? enneagram : null,
      zodiac: zodiac !== "" ? zodiac : null,
      human_design: humanDesign !== "" ? humanDesign : null,
    });
  };

  const optionsFromEnum = (e: any): MSelectOption[] =>
    Object.values(e).map((v) => ({
      name: v as string,
    }));

  const getKidsErrors = (): string[] => {
    const errs = [];
    if (errors.kids) {
      errs.push(...errors.kids);
    }
    if (kids && kids.length > 10) {
      errs.push("kids cannot be longer than 10 characters");
    }
    if (kids && !isInteger(kids)) {
      errs.push("kids must be an integer");
    }
    return errs;
  };

  return (
    <FormScreen
      title="set your vitals"
      comment="these are all optional!"
      nextDisabled={busy || !canSubmit()}
      onNextClicked={submit}
      nextText="done"
    >
      <MInput
        value={pronouns || ""}
        onChange={(e) => setPronouns(e.target.value)}
        onEnterPressed={() => canSubmit() && submit()}
        disabled={busy}
        errors={errors.pronouns}
        label="pronouns"
        placeholder="ex: she/her"
      />
      <MInput
        value={kids || ""}
        onChange={(e) => setKids(e.target.value)}
        onEnterPressed={() => canSubmit() && submit()}
        disabled={busy}
        errors={getKidsErrors()}
        label="kids"
        placeholder="ex: 1"
      />
      <MInput
        value={ethnicity || ""}
        onChange={(e) => setEthnicity(e.target.value)}
        onEnterPressed={() => canSubmit() && submit()}
        disabled={busy}
        errors={errors.ethnicity}
        label="race/ethnicity"
        placeholder="answer in your own words"
      />
      <MInput
        value={mbti || ""}
        onChange={(e) => setMbti(e.target.value)}
        onEnterPressed={() => canSubmit() && submit()}
        disabled={busy}
        errors={errors.mbti}
        label="meyer-briggs type indicator"
        placeholder="ex: INFJ"
      />
      <MInput
        value={loveLanguage || ""}
        onChange={(e) => setLoveLanguage(e.target.value)}
        onEnterPressed={() => canSubmit() && submit()}
        disabled={busy}
        errors={errors.love_language}
        label="love language"
        placeholder="ex: words of affirmation"
      />
      <MInput
        value={enneagram || ""}
        onChange={(e) => setEnneagram(e.target.value)}
        onEnterPressed={() => canSubmit() && submit()}
        disabled={busy}
        errors={errors.enneagram}
        label="enneagram"
        placeholder="ex: 4w5"
      />
      <MInput
        value={zodiac || ""}
        onChange={(e) => setZodiac(e.target.value)}
        onEnterPressed={() => canSubmit() && submit()}
        disabled={busy}
        errors={errors.zodiac}
        label="zodiac sign"
        placeholder="ex: leo"
      />
      <MInput
        value={humanDesign || ""}
        onChange={(e) => setHumanDesign(e.target.value)}
        onEnterPressed={() => canSubmit() && submit()}
        disabled={busy}
        errors={errors.human_design}
        label="human design"
        placeholder="ex: generator"
      />
      <MSelect
        options={optionsFromEnum(SetUserVitalsRequestRelationshipEnum)}
        onSelected={(e) => setRelationshipStatus(e?.name || null)}
        selectedName={relationshipStatus}
        label="relationship status"
        placeholder="pick one..."
        errors={errors.relationship_status}
      />
      <MSelect
        options={optionsFromEnum(SetUserVitalsRequestBeliefsEnum)}
        onSelected={(e) => setBeliefs(e?.name || null)}
        selectedName={beliefs}
        label="beliefs"
        placeholder="pick one..."
        errors={errors.beliefs}
      />
      <MSelect
        options={optionsFromEnum(SetUserVitalsRequestSexualOrientationEnum)}
        onSelected={(e) => setSexualOrientation(e?.name || null)}
        selectedName={sexualOrientation}
        label="sexual orientation"
        placeholder="pick one..."
        errors={errors.sexual_orientation}
      />
    </FormScreen>
  );
};

export default UpdateVitalsScreenComponent;
