import React, { ReactElement } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import MSpinner from "../MSpinner";
import MErrors from "../MErrors";
import CenterColumnStickyButtonScreen from "./CenterColumnStickyButtonScreen";
import MBackButton from "../buttons/MBackButton";

type LoadingScreenProps = {
  errors?: string[];
  message?: string;
  onBackClicked?: (() => void) | null;
};

const LoadingScreenComponent = (props: LoadingScreenProps) => {
  const { errors, message, onBackClicked } = props;

  const getButtons = (): ReactElement[] => {
    if (!errors || errors.length === 0) {
      return [];
    }
    if (!onBackClicked) {
      return [];
    }
    return [
      <MBackButton onClick={onBackClicked} kind="primary" className="w-full" />,
    ];
  };

  return (
    <CenterColumnStickyButtonScreen buttons={getButtons()}>
      <div className="flex flex-col items-center justify-center gap-3 text-center">
        {!errors || errors.length === 0 ? (
          <>
            <div className="h-10 w-10">
              <MSpinner />
            </div>
            <div>{message}</div>
          </>
        ) : (
          <>
            <ExclamationCircleIcon className="h-10 w-10 text-danger" />
            <MErrors errors={errors} />
          </>
        )}
      </div>
    </CenterColumnStickyButtonScreen>
  );
};

LoadingScreenComponent.defaultProps = {
  errors: [],
  message: "loading...\n\ngive us just a moment please",
  onBackClicked: null,
};

export default LoadingScreenComponent;
