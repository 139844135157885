import React from "react";
import { UserConnectionRequestRelatedDetail } from "../../../api/models";
import SidebarThreeColumnRow from "../../ui/screens/sidebarnav/SidebarThreeColumnRow";
import { clickableText } from "../../../util/style";
import MButton from "../../ui/buttons/MButton";
import { useBusyWatcher } from "../../../util/hooks";

type ReceivedConnectionRequestRowProps = {
  request: UserConnectionRequestRelatedDetail;
  onAcceptClicked: () => void;
  onRejectClicked: () => void;
};

const ReceivedConnectionRequestRowComponent = (
  props: ReceivedConnectionRequestRowProps
) => {
  const { request, onAcceptClicked, onRejectClicked } = props;

  const [busy, _] = useBusyWatcher();

  return (
    <SidebarThreeColumnRow
      start={
        <a
          href={request.sending_user.profile_url}
          target="_blank"
          rel="noreferrer"
          className={clickableText}
        >
          {request.sending_user.name} (@{request.sending_user.username})
        </a>
      }
      middle={<span>received {request.time_created!.toLocaleString()}</span>}
      end={
        <div className="flex flex-row gap-3">
          <MButton kind="primary" onClick={onAcceptClicked} disabled={busy}>
            accept
          </MButton>
          <MButton kind="secondary" onClick={onRejectClicked} disabled={busy}>
            ignore
          </MButton>
        </div>
      }
    />
  );
};

export default ReceivedConnectionRequestRowComponent;
