import React from "react";
import MCard from "../../ui/cards/MCard";
import { titleText } from "../../../util/style";
import { addOriginToPath, addQueryParamToPath } from "../../../util/url";
import MShowMoreText from "../../ui/MShowMoreText";
import VisibilityFooter from "../../ui/cards/VisibilityFooter";
import { useBusyWatcher } from "../../../util/hooks";
import MWellDeleteButton from "../../ui/wells/MWellDeleteButton";
import MWellEditButton from "../../ui/wells/MWellEditButton";
import MAvatar from "../../ui/MAvatar";
import { UserGroup } from "../../../api/models";
import MWellSparklesButton from "../../ui/wells/MWellSparklesButton";

type GroupSummaryCardProps = {
  group: UserGroup;
  viewGroupUrl: string;
  editGroupUrl: string;
  hideDeleteButton: boolean;
  hideLeaveButton: boolean;
  onDeleteClicked?: (() => Promise<void>) | null;
};

const GroupSummaryCardComponent = (props: GroupSummaryCardProps) => {
  const {
    group,
    viewGroupUrl,
    editGroupUrl,
    hideDeleteButton,
    hideLeaveButton,
    onDeleteClicked,
  } = props;

  const viewGroupLink = addOriginToPath(
    addQueryParamToPath(viewGroupUrl, "id", group.guid!)
  );
  const editGroupLink = addQueryParamToPath(editGroupUrl, "id", group.guid!);
  const groupTitle = group.name ?? "untitled";
  const [busy, _] = useBusyWatcher();

  const onToggleVisibilityClicked = () => {
    // coming soon tm
  };

  const buttons = [<MWellEditButton key="edit" href={editGroupLink} />];
  if (!hideDeleteButton) {
    buttons.push(
      <MWellDeleteButton
        key="delete"
        disabled={busy}
        onClick={onDeleteClicked}
      />
    );
  }

  return (
    <MCard
      footer={
        <VisibilityFooter
          onToggleVisibilityClicked={onToggleVisibilityClicked}
          visibilityCopy="private"
          toggleVisibilityDisabled
          buttons={buttons}
        />
      }
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between items-start gap-2">
          <div className="flex flex-col">
            <div className={titleText}>{groupTitle}</div>
            <div className="text-xs font-light">
              {group.members.length} members
            </div>
          </div>
          {!hideLeaveButton && (
            <div>
              <MWellSparklesButton
                href={viewGroupLink}
                label={`view '${groupTitle}' group`}
              />
            </div>
          )}
        </div>
        {group.description && (
          <div className="text-sm">
            <MShowMoreText content={group.description} />
          </div>
        )}
        <div className="flex flex-row gap-2">
          {group.members &&
            group.members.map((user) => (
              <MAvatar
                picUrl={user.summary.photo || null}
                alt={
                  user.summary.name || user.summary.username || "user picture"
                }
                size="mini"
              />
            ))}
        </div>
      </div>
    </MCard>
  );
};

GroupSummaryCardComponent.defaultProps = {
  onDeleteClicked: null,
};

export default GroupSummaryCardComponent;
