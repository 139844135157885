/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  UserNotificationRelatedDetail,
  UserNotificationRelatedDetailFromJSON,
  UserNotificationRelatedDetailFromJSONTyped,
  UserNotificationRelatedDetailToJSON,
} from "./UserNotificationRelatedDetail";

/**
 *
 * @export
 * @interface ListNotificationsResponse
 */
export interface ListNotificationsResponse {
  /**
   *
   * @type {Array<UserNotificationRelatedDetail>}
   * @memberof ListNotificationsResponse
   */
  notifs: Array<UserNotificationRelatedDetail>;
}

export function ListNotificationsResponseFromJSON(
  json: any
): ListNotificationsResponse {
  return ListNotificationsResponseFromJSONTyped(json, false);
}

export function ListNotificationsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ListNotificationsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    notifs: (json["notifs"] as Array<any>).map(
      UserNotificationRelatedDetailFromJSON
    ),
  };
}

export function ListNotificationsResponseToJSON(
  value?: ListNotificationsResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    notifs: (value.notifs as Array<any>).map(
      UserNotificationRelatedDetailToJSON
    ),
  };
}
