/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ListGroupQuestionAndAnswerResponse,
  ListGroupQuestionAndAnswerResponseFromJSON,
  ListGroupQuestionAndAnswerResponseFromJSONTyped,
  ListGroupQuestionAndAnswerResponseToJSON,
} from "./ListGroupQuestionAndAnswerResponse";

/**
 *
 * @export
 * @interface ListGroupQuestionAndAnswerResponseSerializer
 */
export interface ListGroupQuestionAndAnswerResponseSerializer {
  /**
   *
   * @type {string}
   * @memberof ListGroupQuestionAndAnswerResponseSerializer
   */
  m?: string;
  /**
   *
   * @type {boolean}
   * @memberof ListGroupQuestionAndAnswerResponseSerializer
   */
  s: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ListGroupQuestionAndAnswerResponseSerializer
   */
  e?: { [key: string]: string } | null;
  /**
   *
   * @type {ListGroupQuestionAndAnswerResponse}
   * @memberof ListGroupQuestionAndAnswerResponseSerializer
   */
  c?: ListGroupQuestionAndAnswerResponse;
}

export function ListGroupQuestionAndAnswerResponseSerializerFromJSON(
  json: any
): ListGroupQuestionAndAnswerResponseSerializer {
  return ListGroupQuestionAndAnswerResponseSerializerFromJSONTyped(json, false);
}

export function ListGroupQuestionAndAnswerResponseSerializerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ListGroupQuestionAndAnswerResponseSerializer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    m: !exists(json, "m") ? undefined : json["m"],
    s: json["s"],
    e: !exists(json, "e") ? undefined : json["e"],
    c: !exists(json, "c")
      ? undefined
      : ListGroupQuestionAndAnswerResponseFromJSON(json["c"]),
  };
}

export function ListGroupQuestionAndAnswerResponseSerializerToJSON(
  value?: ListGroupQuestionAndAnswerResponseSerializer | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    m: value.m,
    s: value.s,
    e: value.e,
    c: ListGroupQuestionAndAnswerResponseToJSON(value.c),
  };
}
