/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  GlimpseDetail,
  GlimpseDetailFromJSON,
  GlimpseDetailFromJSONTyped,
  GlimpseDetailToJSON,
} from "./GlimpseDetail";

/**
 *
 * @export
 * @interface GlimpseDetailSerializer
 */
export interface GlimpseDetailSerializer {
  /**
   *
   * @type {string}
   * @memberof GlimpseDetailSerializer
   */
  m?: string;
  /**
   *
   * @type {boolean}
   * @memberof GlimpseDetailSerializer
   */
  s: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof GlimpseDetailSerializer
   */
  e?: { [key: string]: string } | null;
  /**
   *
   * @type {GlimpseDetail}
   * @memberof GlimpseDetailSerializer
   */
  c?: GlimpseDetail;
}

export function GlimpseDetailSerializerFromJSON(
  json: any
): GlimpseDetailSerializer {
  return GlimpseDetailSerializerFromJSONTyped(json, false);
}

export function GlimpseDetailSerializerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GlimpseDetailSerializer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    m: !exists(json, "m") ? undefined : json["m"],
    s: json["s"],
    e: !exists(json, "e") ? undefined : json["e"],
    c: !exists(json, "c") ? undefined : GlimpseDetailFromJSON(json["c"]),
  };
}

export function GlimpseDetailSerializerToJSON(
  value?: GlimpseDetailSerializer | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    m: value.m,
    s: value.s,
    e: value.e,
    c: GlimpseDetailToJSON(value.c),
  };
}
