import React, { useCallback, useState } from "react";
import { GetProfileResponse } from "../../../api/models";
import BaseDialog from "../../dialogs/BaseDialog";
import MButton from "../../ui/buttons/MButton";
import { useBusyWatcher } from "../../../util/hooks";

type ConnectionResponseDialogProps = {
  profile?: GetProfileResponse;
};

type innerProps = ConnectionResponseDialogProps & {
  onClose: (response: boolean | null) => void;
  show: boolean;
};

const ConnectionResponseDialogComponent = (props: innerProps) => {
  const { show, onClose, profile } = props;

  const [busy, _] = useBusyWatcher();

  return (
    <BaseDialog
      title={`add ${profile?.profile.user.name} to your people?`}
      show={show}
      onClose={() => onClose(null)}
      className="max-w-md"
    >
      <div className="text-sm text-left">
        {profile?.profile.user.name} wants to add you on Manual!
      </div>
      <div className="text-sm text-left">
        accepting their request will add them to your people as well. if
        accepted, {profile?.profile.user.name} will be able to see your profile,
        even if you turn it private. if denied, {profile?.profile.user.name}{" "}
        won’t receive any notification and they’ll be able to add you again in
        the future.
      </div>
      <div className="text-sm text-left mb-3">
        many of Manual's features are only available to use with others that are
        in your people.
      </div>
      <div className="flex flex-row gap-3 w-full">
        <MButton
          kind="primary"
          disabled={busy}
          className="grow"
          onClick={() => onClose(true)}
        >
          accept
        </MButton>
        <MButton
          kind="secondary"
          disabled={busy}
          className="grow"
          onClick={() => onClose(false)}
        >
          decline
        </MButton>
      </div>
    </BaseDialog>
  );
};

ConnectionResponseDialogComponent.defaultProps = {
  profile: undefined,
};

export const useAsyncConnectionResponseDialog = () => {
  const [show, setShow] = useState<boolean>(false);
  const [props, setProps] = useState<ConnectionResponseDialogProps>({});
  const [promiseResolve, setPromiseResolve] = useState<
    ((response: boolean | null) => void) | null
  >(null);

  const respond = useCallback(
    async (innerProps: ConnectionResponseDialogProps) => {
      setShow(true);
      setProps(innerProps);

      return new Promise<boolean | null>((resolve) => {
        // NOTE: If you pass a callback to setState, React will call that callback,
        // so that's why this is wrapped in a pointless arrow function.
        setPromiseResolve(() => resolve);
      });
    },
    []
  );

  return {
    dialog: (
      <ConnectionResponseDialogComponent
        onClose={(result: boolean | null) => {
          setShow(false);
          promiseResolve?.(result);
        }}
        show={show}
        {...props}
      />
    ),
    respond,
  };
};

export default ConnectionResponseDialogComponent;
