import React, { ReactNode } from "react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import MButton, { MButtonProps } from "./MButton";

type MNextButtonProps = MButtonProps & {
  text?: string;
  icon?: ReactNode;
};

const MNextButtonComponent = (props: MNextButtonProps) => {
  const { text, ...rest } = props;

  return <MButton {...rest}>{text}</MButton>;
};

MNextButtonComponent.defaultProps = {
  text: "next",
  icon: <ChevronRightIcon className="h-6 w-5 flex-none" aria-hidden="true" />,
};

export default MNextButtonComponent;
