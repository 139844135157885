/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  PromptContent,
  PromptContentFromJSON,
  PromptContentFromJSONTyped,
  PromptContentToJSON,
} from "./PromptContent";

/**
 *
 * @export
 * @interface UpdateQuestionRequest
 */
export interface UpdateQuestionRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateQuestionRequest
   */
  prompt: string;
  /**
   *
   * @type {PromptContent}
   * @memberof UpdateQuestionRequest
   */
  content: PromptContent;
}

export function UpdateQuestionRequestFromJSON(
  json: any
): UpdateQuestionRequest {
  return UpdateQuestionRequestFromJSONTyped(json, false);
}

export function UpdateQuestionRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateQuestionRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    prompt: json["prompt"],
    content: PromptContentFromJSON(json["content"]),
  };
}

export function UpdateQuestionRequestToJSON(
  value?: UpdateQuestionRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    prompt: value.prompt,
    content: PromptContentToJSON(value.content),
  };
}
