/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface LLMInvocation
 */
export interface LLMInvocation {
  /**
   * The resulting dictionary returned from the LLM invocation.
   * @type {object}
   * @memberof LLMInvocation
   */
  results?: object | null;
  /**
   * A numeric rating of 0 or 1 to indicate the quality and relevance of the LLM result.
   * @type {number}
   * @memberof LLMInvocation
   */
  rating?: number | null;
  /**
   * Name of the LLM operation, used for analytics.
   * @type {string}
   * @memberof LLMInvocation
   */
  call_type: string;
}

export function LLMInvocationFromJSON(json: any): LLMInvocation {
  return LLMInvocationFromJSONTyped(json, false);
}

export function LLMInvocationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LLMInvocation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    results: !exists(json, "results") ? undefined : json["results"],
    rating: !exists(json, "rating") ? undefined : json["rating"],
    call_type: json["call_type"],
  };
}

export function LLMInvocationToJSON(value?: LLMInvocation | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    results: value.results,
    rating: value.rating,
    call_type: value.call_type,
  };
}
