import React, { ReactElement } from "react";
import { GetQuestionnaireWithResponsesResponse } from "../../../api/models";
import { useBusyWatcher } from "../../../util/hooks";
import MNextButton from "../../ui/buttons/MNextButton";
import MBackButton from "../../ui/buttons/MBackButton";
import CenterColumnStickyButtonScreen from "../../ui/screens/CenterColumnStickyButtonScreen";
import { classNames } from "../../../util/strings";
import { commentText, titleText } from "../../../util/style";
import PromptDisplayCard from "../../ui/cards/prompt_display/PromptDisplayCard";
import PromptVisibilityInfo from "../../ui/forms/PromptVisibilityInfo";

type QuestionnaireReviewScreenProps = {
  title: string;
  content: GetQuestionnaireWithResponsesResponse;
  onBackClicked?: (() => void) | null;
  onNextClicked: () => void;
  nextText: string;
  onEditClicked: (promptId: string) => void;
  completedFlavorText?: string | null;
};

const QuestionnaireReviewScreenComponent = (
  props: QuestionnaireReviewScreenProps
) => {
  const {
    title,
    content,
    onNextClicked,
    nextText,
    onBackClicked,
    onEditClicked,
    completedFlavorText,
  } = props;

  const [busy, _] = useBusyWatcher();

  const getNumRequired = (): number =>
    content.questionnaire.num_answers_required !== undefined
      ? content.questionnaire.num_answers_required!
      : content.prompts.length;

  const isCompleted = (): boolean => {
    const numRequired = getNumRequired();
    const numAnswered = content.response_count;
    return numAnswered >= numRequired;
  };

  const getButtons = (): ReactElement[] => {
    const toReturn: ReactElement[] = [
      <MNextButton
        key="primary"
        kind="primary"
        disabled={busy || !isCompleted()}
        onClick={onNextClicked}
        text={nextText}
      />,
    ];
    if (onBackClicked) {
      toReturn.push(
        <MBackButton key="back" disabled={busy} onClick={onBackClicked} />
      );
    }
    return toReturn;
  };

  return (
    <CenterColumnStickyButtonScreen fullWidth buttons={getButtons()} stacked>
      <h2 className={classNames("text-center mb-2", titleText)}>{title}</h2>
      <div className={classNames("mb-4 text-center", commentText)}>
        {isCompleted() ? (
          <>
            <div className="mb-2">you've completed this quiz!</div>
            {completedFlavorText && <div>{completedFlavorText}</div>}
          </>
        ) : (
          `you must answer at least ${getNumRequired()} to continue`
        )}
      </div>
      <div className="flex flex-col gap-3">
        {content.prompts.map((p) => (
          <PromptDisplayCard
            key={p.prompt.guid}
            prompt={p}
            type={p.response ? "regular" : "warning"}
            onEditClicked={() => onEditClicked(p.prompt.guid!)}
            visibleStatus={
              p.response ? (
                <PromptVisibilityInfo
                  visible={p.visible}
                  publishable={p.publishable}
                />
              ) : null
            }
          />
        ))}
      </div>
    </CenterColumnStickyButtonScreen>
  );
};

QuestionnaireReviewScreenComponent.defaultProps = {
  onBackClicked: null,
  completedFlavorText: null,
};

export default QuestionnaireReviewScreenComponent;
