import React, { PropsWithChildren, useState } from "react";
import MErrors from "../../MErrors";
import PromptVisibilityToggle from "../PromptVisibilityToggle";
import MTitle from "../../MTitle";
import MSubtext from "../../MSubtext";

export type CommonQuestionProps = {
  errors?: string[];
  question: string;
  comment?: string | null;
  showVisibilityToggle?: boolean;
  onVisibilityUpdated?: ((visible: boolean) => void) | null;
  visible?: boolean | null;
  publishable?: boolean | null;
};

const CommonQuestionComponent = (
  props: PropsWithChildren<CommonQuestionProps>
) => {
  const {
    question,
    comment,
    showVisibilityToggle,
    errors,
    onVisibilityUpdated,
    visible: visibleInput,
    publishable,
    children,
  } = props;

  const [visible, setVisible] = useState<boolean>(visibleInput ?? true);

  const onVisibilityToggled = () => {
    setVisible(!visible);
    if (onVisibilityUpdated) {
      onVisibilityUpdated(!visible);
    }
  };

  return (
    <div>
      <MTitle text={question} className="text-center" />
      <MSubtext text={comment} className="text-center" />
      <div className="flex flex-col gap-3">
        <MErrors errors={errors} />
        {showVisibilityToggle && (
          <PromptVisibilityToggle
            publishable={publishable ?? true}
            onToggleClicked={onVisibilityToggled}
            visible={visible}
          />
        )}
        {children}
      </div>
    </div>
  );
};

CommonQuestionComponent.defaultProps = {
  errors: [],
  comment: null,
  showVisibilityToggle: false,
  onVisibilityUpdated: null,
  visible: null,
  publishable: null,
};

export default CommonQuestionComponent;
