import React from "react";
import FormScreen from "../../../ui/screens/FormScreen";
import MButton from "../../../ui/buttons/MButton";

type PickQuestionTypeScreenProps = {
  onFillInBlankClicked: () => void;
  onEitherOrClicked: () => void;
  onMultiSelectClicked: () => void;
  onBackClicked: () => void;
};

const PickQuestionTypeScreenComponent = (
  props: PickQuestionTypeScreenProps
) => {
  const {
    onFillInBlankClicked,
    onMultiSelectClicked,
    onEitherOrClicked,
    onBackClicked,
  } = props;

  return (
    <FormScreen
      title="what type of question would you like to create?"
      onBackClicked={onBackClicked}
    >
      <MButton onClick={onFillInBlankClicked} kind="primary">
        fill in the blank
      </MButton>
      <MButton onClick={onMultiSelectClicked} kind="primary">
        multiple choice
      </MButton>
      <MButton onClick={onEitherOrClicked} kind="primary">
        either or
      </MButton>
    </FormScreen>
  );
};

export default PickQuestionTypeScreenComponent;
