/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  AnsweredPromptDetail,
  AnsweredPromptDetailFromJSON,
  AnsweredPromptDetailFromJSONTyped,
  AnsweredPromptDetailToJSON,
} from "./AnsweredPromptDetail";

/**
 *
 * @export
 * @interface GetAnsweredPromptDetailsResponse
 */
export interface GetAnsweredPromptDetailsResponse {
  /**
   *
   * @type {Array<AnsweredPromptDetail>}
   * @memberof GetAnsweredPromptDetailsResponse
   */
  responses: Array<AnsweredPromptDetail>;
}

export function GetAnsweredPromptDetailsResponseFromJSON(
  json: any
): GetAnsweredPromptDetailsResponse {
  return GetAnsweredPromptDetailsResponseFromJSONTyped(json, false);
}

export function GetAnsweredPromptDetailsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAnsweredPromptDetailsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    responses: (json["responses"] as Array<any>).map(
      AnsweredPromptDetailFromJSON
    ),
  };
}

export function GetAnsweredPromptDetailsResponseToJSON(
  value?: GetAnsweredPromptDetailsResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    responses: (value.responses as Array<any>).map(AnsweredPromptDetailToJSON),
  };
}
