import React from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../util/strings";

type MCheckedProps = {
  checked: boolean;
  className?: string | null;
};

const MCheckedComponent = (props: MCheckedProps) => {
  const { checked, className } = props;

  return (
    <div
      className={classNames(
        "rounded-md border-2 border-primary font-bold",
        className
      )}
    >
      <div className={checked ? "bg-primary" : ""}>
        <CheckIcon
          className={classNames(
            "w-full h-full text-m-white",
            checked ? null : "invisible"
          )}
        />
      </div>
    </div>
  );
};

MCheckedComponent.defaultProps = {
  className: null,
};

export default MCheckedComponent;
