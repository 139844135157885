/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  GlimpseLimits,
  GlimpseLimitsFromJSON,
  GlimpseLimitsFromJSONTyped,
  GlimpseLimitsToJSON,
} from "./GlimpseLimits";
import {
  GlimpseSummary,
  GlimpseSummaryFromJSON,
  GlimpseSummaryFromJSONTyped,
  GlimpseSummaryToJSON,
} from "./GlimpseSummary";

/**
 *
 * @export
 * @interface ListGlimpsesResponse
 */
export interface ListGlimpsesResponse {
  /**
   *
   * @type {Array<GlimpseSummary>}
   * @memberof ListGlimpsesResponse
   */
  glimpses: Array<GlimpseSummary>;
  /**
   *
   * @type {number}
   * @memberof ListGlimpsesResponse
   */
  count: number;
  /**
   *
   * @type {GlimpseLimits}
   * @memberof ListGlimpsesResponse
   */
  limits: GlimpseLimits;
}

export function ListGlimpsesResponseFromJSON(json: any): ListGlimpsesResponse {
  return ListGlimpsesResponseFromJSONTyped(json, false);
}

export function ListGlimpsesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ListGlimpsesResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    glimpses: (json["glimpses"] as Array<any>).map(GlimpseSummaryFromJSON),
    count: json["count"],
    limits: GlimpseLimitsFromJSON(json["limits"]),
  };
}

export function ListGlimpsesResponseToJSON(
  value?: ListGlimpsesResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    glimpses: (value.glimpses as Array<any>).map(GlimpseSummaryToJSON),
    count: value.count,
    limits: GlimpseLimitsToJSON(value.limits),
  };
}
