import React from "react";
import { PencilSquareIcon, PlusIcon } from "@heroicons/react/24/outline";
import MButton from "../../../ui/buttons/MButton";
import FormScreen from "../../../ui/screens/FormScreen";
import { useBusyWatcher } from "../../../../util/hooks";
import { Attachments } from "../../../../api/models";

type AttachmentsLandingScreenProps = {
  attachments: Attachments;
  onDoneClicked: () => void;
  onResumeClicked: () => void;
};

const AttachmentsLandingScreenComponent = (
  props: AttachmentsLandingScreenProps
) => {
  const { attachments, onDoneClicked, onResumeClicked } = props;

  const [busy, _] = useBusyWatcher();

  return (
    <FormScreen
      title="attachments"
      comment="you can add attachments to your profile or edit existing attachments here"
      onNextClicked={onDoneClicked}
      nextDisabled={busy}
      nextText="done"
      fullWidth
    >
      <MButton
        disabled={busy}
        onClick={onResumeClicked}
        kind={attachments.resume ? "secondary" : "primary"}
        icon={
          attachments.resume ? (
            <PencilSquareIcon className="h-5 w-5" />
          ) : (
            <PlusIcon className="h-5 w-5" />
          )
        }
      >
        {attachments.resume ? "modify resume" : "add resume"}
      </MButton>
    </FormScreen>
  );
};

export default AttachmentsLandingScreenComponent;
