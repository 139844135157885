import React from "react";
import { GetQuestionnaireWithResponsesResponse } from "../../../api/models";
import { useBusyWatcher } from "../../../util/hooks";
import CenterColumnScreen from "../../ui/screens/CenterColumnScreen";
import { classNames } from "../../../util/strings";
import { commentText, paragraphText, titleTextSize } from "../../../util/style";
import MButton from "../../ui/buttons/MButton";

type QuestionnaireIntroScreenProps = {
  questionnaire: GetQuestionnaireWithResponsesResponse;
  startClicked: () => void;
};

const QuestionnaireIntroScreenComponent = (
  props: QuestionnaireIntroScreenProps
) => {
  const { questionnaire, startClicked } = props;

  const [busy, _] = useBusyWatcher();

  const numAnsweredRequired =
    questionnaire.questionnaire.num_answers_required ??
    questionnaire.prompts.length;

  const getRequiredCopy = (): string => {
    if (numAnsweredRequired === 0) {
      return "all are skippable";
    }
    if (numAnsweredRequired === questionnaire.prompts.length) {
      return "all are required";
    }
    return `must answer at least ${numAnsweredRequired}`;
  };

  return (
    <CenterColumnScreen className="items-center text-center">
      <h2 className={classNames(titleTextSize, "mb-12")}>
        {questionnaire.questionnaire.title}
      </h2>
      <div className={paragraphText}>
        {questionnaire.questionnaire.description}
      </div>
      <MButton
        className="mt-12 mb-6"
        kind="primary"
        onClick={startClicked}
        disabled={busy}
      >
        get started
      </MButton>
      <div className={classNames(commentText, "mt-2")}>
        {questionnaire.prompts.length} questions to answer in this quiz (
        {getRequiredCopy()})
      </div>
    </CenterColumnScreen>
  );
};

export default QuestionnaireIntroScreenComponent;
