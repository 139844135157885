import React, { ReactElement, RefObject, useEffect, useState } from "react";
import ChatboxMessageBox from "./ChatboxMessageBox";
import MSpinner from "../../ui/MSpinner";

export type ChatboxMessage = {
  id: string;
  text: string;
  left: boolean;
  seconds?: number;
};

type ChatboxMessagesContainerProps = {
  messages: ChatboxMessage[];
  loading?: number;
};

const ChatboxMessagesContainerComponent = (
  props: ChatboxMessagesContainerProps
) => {
  const { messages, loading } = props;

  const [loadingSince, setLoadingSince] = useState<number>();
  const messagesEndRef: RefObject<HTMLDivElement> = React.createRef();

  const getMessageRows = (): ReactElement[] =>
    messages.map((message) => {
      const { id, text, left, seconds } = message;
      let className = "flex flex-row justify-end";
      if (left) {
        className = "flex flex-row justify-start";
      }
      return (
        <div key={id} className={className}>
          <ChatboxMessageBox text={text} left={left} seconds={seconds} />
        </div>
      );
    });

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  const getLoadingSinceString = (): string => {
    if (loadingSince === undefined) {
      return "";
    }
    const seconds = Math.round(loadingSince / 100) / 10;
    return `${seconds.toFixed(1)}s`;
  };

  const getChatboxRows = (): ReactElement[] => {
    const messageRows = getMessageRows();
    if (loading) {
      messageRows.push(
        <div
          key="loading"
          className="flex flex-row justify-center gap-3 items-center"
        >
          <div className="h-5 w-5">
            <MSpinner />
          </div>
          <div className="flex flex-col">
            {loadingSince !== undefined && (
              <div>loading... ({getLoadingSinceString()})</div>
            )}
            {loadingSince === undefined && <div>loading...</div>}
          </div>
        </div>
      );
    }
    return messageRows;
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (loading !== undefined) {
      const interval = setInterval(
        () => setLoadingSince(Date.now() - loading),
        100
      );
      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [loading]);

  return (
    <div className="w-full min-h-full flex flex-col gap-3 justify-end">
      {getChatboxRows()}
      <div ref={messagesEndRef} />
    </div>
  );
};

ChatboxMessagesContainerComponent.defaultProps = {
  loading: undefined,
};

export default ChatboxMessagesContainerComponent;
