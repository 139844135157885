/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  SubscriptionTier,
  SubscriptionTierFromJSON,
  SubscriptionTierFromJSONTyped,
  SubscriptionTierToJSON,
} from "./SubscriptionTier";

/**
 *
 * @export
 * @interface GetSubscriptionTiersResponse
 */
export interface GetSubscriptionTiersResponse {
  /**
   *
   * @type {Array<SubscriptionTier>}
   * @memberof GetSubscriptionTiersResponse
   */
  tiers: Array<SubscriptionTier>;
  /**
   *
   * @type {string}
   * @memberof GetSubscriptionTiersResponse
   */
  subscription_price: string | null;
  /**
   *
   * @type {string}
   * @memberof GetSubscriptionTiersResponse
   */
  subscribe_link: string;
  /**
   *
   * @type {string}
   * @memberof GetSubscriptionTiersResponse
   */
  manage_link: string;
}

export function GetSubscriptionTiersResponseFromJSON(
  json: any
): GetSubscriptionTiersResponse {
  return GetSubscriptionTiersResponseFromJSONTyped(json, false);
}

export function GetSubscriptionTiersResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetSubscriptionTiersResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tiers: (json["tiers"] as Array<any>).map(SubscriptionTierFromJSON),
    subscription_price: json["subscription_price"],
    subscribe_link: json["subscribe_link"],
    manage_link: json["manage_link"],
  };
}

export function GetSubscriptionTiersResponseToJSON(
  value?: GetSubscriptionTiersResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    tiers: (value.tiers as Array<any>).map(SubscriptionTierToJSON),
    subscription_price: value.subscription_price,
    subscribe_link: value.subscribe_link,
    manage_link: value.manage_link,
  };
}
