import React, { ReactElement } from "react";
import { GetSingleEntryResponse } from "../../../api/models";
import MBackButton from "../../ui/buttons/MBackButton";
import { useBusyWatcher } from "../../../util/hooks";
import CenterColumnStickyButtonScreen from "../../ui/screens/CenterColumnStickyButtonScreen";
import CommCoachSingleEntryCard from "./CommCoachSingleEntryCard";
import MMarkdown from "../../ui/MMarkdown";
import MNextButton from "../../ui/buttons/MNextButton";

type ViewCommCoachSingleScreenProps = {
  entry: GetSingleEntryResponse;
  onDoneClicked?: (() => void) | null;
  onBackClicked?: (() => void) | null;
};

const ViewCommCoachSingleScreenComponent = (
  props: ViewCommCoachSingleScreenProps
) => {
  const { entry, onBackClicked, onDoneClicked } = props;

  const [busy, _] = useBusyWatcher();

  const getButtons = (): ReactElement[] => {
    const toReturn: ReactElement[] = [];
    if (onDoneClicked) {
      toReturn.push(
        <MNextButton
          kind="primary"
          key="done-button"
          onClick={onDoneClicked}
          disabled={busy}
          text="done"
        />
      );
    }
    if (onBackClicked) {
      toReturn.push(
        <MBackButton
          kind="secondary"
          key="back-button"
          onClick={onBackClicked}
          disabled={busy}
        />
      );
    }
    return toReturn;
  };

  return (
    <CenterColumnStickyButtonScreen buttons={getButtons()} stacked fullWidth>
      <div className="flex flex-col gap-5">
        <CommCoachSingleEntryCard entry={entry.entry} />
        <div className="flex flex-col gap-2">
          <div className="font-bold">{entry.entry.conversation}</div>
          {entry.entry.concerns && (
            <div className="text-xs">
              <div className="font-bold">concerns</div>
              <div className="italic">{entry.entry.concerns}</div>
            </div>
          )}
          {entry.entry.goal && (
            <div className="text-xs">
              <div className="font-bold">goal</div>
              <div className="italic">{entry.entry.goal}</div>
            </div>
          )}
          {entry.entry.relationship && (
            <div className="text-xs">
              <div className="font-bold">relationship</div>
              <div className="italic">{entry.entry.relationship}</div>
            </div>
          )}
        </div>
        <MMarkdown content={entry.content!} />
      </div>
    </CenterColumnStickyButtonScreen>
  );
};

ViewCommCoachSingleScreenComponent.defaultProps = {
  onDoneClicked: null,
  onBackClicked: null,
};

export default ViewCommCoachSingleScreenComponent;
