import React from "react";
import MessageBox from "../ui/MessageBox";
import { MNavProps } from "../ui/nav/MNav";
import BaseNavbarPage from "./BaseNavbarPage";

type MessagePageProps = {
  navbarProps: MNavProps;
  messages: string[];
};

const MessagePageComponent = (props: MessagePageProps) => {
  const { navbarProps, messages } = props;

  return (
    <BaseNavbarPage navbarProps={navbarProps}>
      <div className="p-4">
        <MessageBox>
          <div className="max-w-2xl flex flex-col gap-12">
            {messages.map((message) => (
              <div>{message}</div>
            ))}
          </div>
        </MessageBox>
      </div>
    </BaseNavbarPage>
  );
};

export default MessagePageComponent;
