import React, { PropsWithChildren } from "react";
import { Transition } from "@headlessui/react";
import { classNames, durationFromMs } from "../../../util/strings";
import {
  DEFAULT_TRANSITION_ENTER_DURATION,
  DEFAULT_TRANSITION_LEAVE_DURATION,
} from "../../../util/constants";

type LeftRightTransitionProps = {
  show: boolean;
  isBack?: boolean;
  enterMs?: number;
  leaveMs?: number;
  appear?: boolean;
};

const LeftRightTransitionComponent = (
  props: PropsWithChildren<LeftRightTransitionProps>
) => {
  const { show, appear, isBack, enterMs, leaveMs, children } = props;

  return (
    <Transition
      show={show}
      enter={classNames("transition-all", durationFromMs(enterMs!))}
      enterFrom={classNames(
        "opacity-0",
        isBack ? "-translate-x-10" : "translate-x-10"
      )}
      enterTo="opacity-100"
      leave={classNames("transition-all", durationFromMs(leaveMs!))}
      leaveFrom="opacity-100"
      leaveTo={classNames(
        "opacity-0",
        isBack ? "translate-x-10" : "-translate-x-10"
      )}
      appear={appear}
    >
      {children}
    </Transition>
  );
};

LeftRightTransitionComponent.defaultProps = {
  isBack: false,
  enterMs: DEFAULT_TRANSITION_ENTER_DURATION,
  leaveMs: DEFAULT_TRANSITION_LEAVE_DURATION,
  appear: true,
};

export default LeftRightTransitionComponent;
