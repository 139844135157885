import React, { ReactNode } from "react";
import { SearchForConnectionResponse } from "../../../api/models";
import MSearchBox from "../../ui/MSearchBox";
import LoadingProfileCard from "../../ui/loading/LoadingProfileCard";
import ProfileCard from "../../ui/cards/ProfileCard";
import { useBusyWatcher } from "../../../util/hooks";
import SidebarSection from "../../ui/screens/sidebarnav/SidebarSection";
import SidebarCommentRow from "../../ui/screens/sidebarnav/SidebarCommentRow";
import MPaginationRow from "../../ui/MPaginationRow";

type ListMyPeopleScreenProps = {
  loading: boolean;
  response: SearchForConnectionResponse | null;
  errors?: string[] | null;
  searchTerm: string;
  onSearchTermUpdated: (newTerm: string) => void;
  onPageClicked: (page: number) => void;
  offset: number;
  pageSize: number;
};

const ListMyPeopleScreenComponent = (props: ListMyPeopleScreenProps) => {
  const {
    loading,
    response,
    errors,
    searchTerm,
    onSearchTermUpdated: onSearchTermUpdatedInput,
    onPageClicked,
    offset,
    pageSize,
  } = props;

  const [busy, _] = useBusyWatcher();

  const getGrid = (content: ReactNode): ReactNode => (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 mt-3">{content}</div>
  );

  const onSearchTermUpdated = (newTerm: string) => {
    if (searchTerm === newTerm) {
      return;
    }
    onSearchTermUpdatedInput(newTerm);
  };

  return (
    <SidebarSection
      title="my people"
      divideY={false}
      cta={
        <MSearchBox
          onSearchTermUpdated={onSearchTermUpdated}
          searchTerm={searchTerm}
          errors={errors}
          placeholder="search my people"
        />
      }
    >
      <>
        {loading &&
          getGrid(
            <>
              <LoadingProfileCard />
              <LoadingProfileCard />
              <LoadingProfileCard />
              <LoadingProfileCard />
              <LoadingProfileCard />
              <LoadingProfileCard />
            </>
          )}
        {!loading && response && response.results.length > 0 && (
          <div>
            {getGrid(
              response.results.map((user) => (
                <ProfileCard
                  user={user}
                  key={user.id}
                  disabled={busy}
                  separateWindow={false}
                  asAnchor
                />
              ))
            )}
            <div className="mt-3">
              <MPaginationRow
                pageSize={pageSize}
                startIndex={offset}
                totalCount={response?.count}
                onPageClicked={onPageClicked}
                disabled={busy}
                copy="people"
              />
            </div>
          </div>
        )}
        {!loading && (!response || response.results.length === 0) && (
          <SidebarCommentRow
            comment={
              searchTerm === ""
                ? "you haven't added anyone to your people yet. once you do they'll all be listed here."
                : "no results found."
            }
          />
        )}
      </>
    </SidebarSection>
  );
};

ListMyPeopleScreenComponent.defaultProps = {
  errors: null,
};

export default ListMyPeopleScreenComponent;
