import React from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useBusyWatcher } from "../../../util/hooks";
import { clickableText } from "../../../util/style";
import { classNames } from "../../../util/strings";
import MMarkdown from "../MMarkdown";

type NotificationUndoBoxProps = {
  text?: string;
  onUndoClicked?: (() => void) | null;
  onClearClicked: () => void;
};

const NotificationUndoBoxComponent = (props: NotificationUndoBoxProps) => {
  const { text, onUndoClicked, onClearClicked } = props;

  const [busy, _] = useBusyWatcher();

  return (
    <div className="flex items-center p-3">
      <div className="flex w-0 flex-1 justify-between">
        <MMarkdown
          content={text!}
          className="text-m-dark-gray notif-markdown"
        />
        {onUndoClicked && (
          <button
            type="button"
            className={classNames(
              "ml-3 flex-shrink-0 rounded-md bg-m-white text-sm font-medium text-primary hover:brightness-110 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2",
              clickableText
            )}
            onClick={onUndoClicked}
            disabled={busy}
          >
            undo
          </button>
        )}
      </div>
      <div className="ml-4 flex flex-shrink-0">
        <button
          type="button"
          className="inline-flex rounded-md bg-m-white text-m-gray hover:brightness-110 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
          onClick={onClearClicked}
        >
          <span className="sr-only">Close</span>
          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

NotificationUndoBoxComponent.defaultProps = {
  text: "notification cleared",
  onUndoClicked: null,
};

export default NotificationUndoBoxComponent;
