import React from "react";

type ChatboxMessageBoxProps = {
  text: string;
  seconds?: number;
  left: boolean;
};

const ChatboxMessageBoxComponent = (props: ChatboxMessageBoxProps) => {
  const { text, seconds, left } = props;

  const getClassName = (): string => {
    const elements = ["w-full", "max-w-md", "p-2"];
    if (left) {
      elements.push(...["bg-blue-500", "text-m-white"]);
    } else {
      elements.push(...["bg-slate-300", "text-black"]);
    }
    return elements.join(" ");
  };

  return (
    <div className="flex flex-col">
      <div className={getClassName()}>{text}</div>
      <div className="h-6 text-xs">
        {seconds !== undefined && `${seconds.toFixed(2)} seconds`}
      </div>
    </div>
  );
};

ChatboxMessageBoxComponent.defaultProps = {
  seconds: undefined,
};

export default ChatboxMessageBoxComponent;
