import React, { ReactNode, useEffect, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import MInput from "./MInput";
import { classNames } from "../../util/strings";

type MSearchBoxProps = React.ComponentPropsWithoutRef<"input"> & {
  searchTerm?: string | null;
  onEnterPressed?: (() => void) | null;
  onSearchTermUpdated: (newTerm: string) => void;
  errors?: string[] | null;
  label?: string | null;
  placeholder?: string | null;
  below?: ReactNode;
};

const MSearchBoxComponent = (props: MSearchBoxProps) => {
  const {
    searchTerm: searchTermInput,
    onSearchTermUpdated,
    onEnterPressed,
    errors,
    className,
    label,
    placeholder,
    below,
    ...rest
  } = props;

  const [searchTerm, setSearchTerm] = useState<string>(searchTermInput || "");
  const debouncedSearchTerm = useDebounce(searchTerm, 600);

  useEffect(() => {
    onSearchTermUpdated(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  return (
    <div className="w-full relative">
      <MInput
        errors={errors || []}
        onEnterPressed={onEnterPressed}
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
        type="search"
        className={classNames("w-full max-w-lg lg:max-w-xs", className)}
        icon={
          <MagnifyingGlassIcon
            className="h-5 w-5 text-m-gray"
            aria-hidden="true"
          />
        }
        placeholder={placeholder}
        label={label}
        {...rest}
      />
      {below && <div className="absolute left-0 z-20">{below}</div>}
    </div>
  );
};

MSearchBoxComponent.defaultProps = {
  searchTerm: null,
  onEnterPressed: null,
  errors: null,
  placeholder: "search",
  label: null,
  below: null,
};

export default MSearchBoxComponent;
