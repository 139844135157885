import React, { useEffect, useState } from "react";

import CommonQuestion, { CommonQuestionProps } from "./CommonQuestion";
import MTextArea from "../../MTextArea";

type FillInBlankQuestionProps = CommonQuestionProps & {
  content?: string | null;
  maxContentLength?: number | null;
  placeholder?: string;
  disabled?: boolean;
  onUpdated: (content: string) => void;
};

const FillInBlankQuestionComponent = (props: FillInBlankQuestionProps) => {
  const {
    content: contentInput,
    maxContentLength,
    placeholder,
    onUpdated,
    disabled,
    errors,
    ...rest
  } = props;

  const [content, setContent] = useState<string>("");

  useEffect(() => {
    setContent(contentInput ?? "");
  }, [contentInput]);

  return (
    <CommonQuestion {...rest}>
      <MTextArea
        errors={errors}
        onChange={(e) => onUpdated(e.target.value)}
        value={content}
        placeholder={placeholder}
        disabled={disabled}
        characterLimit={maxContentLength}
      />
    </CommonQuestion>
  );
};

FillInBlankQuestionComponent.defaultProps = {
  content: null,
  maxContentLength: null,
  placeholder: "",
  disabled: false,
};

export default FillInBlankQuestionComponent;
