import React, { PropsWithChildren } from "react";
import MWell, { MWellProps } from "./MWell";
import { classNames } from "../../../util/strings";

type MLabeledWellProps = MWellProps & {
  label: string;
};

const MLabeledWellComponent = (props: PropsWithChildren<MLabeledWellProps>) => {
  const { label, children, className, ...rest } = props;

  return (
    <div>
      <div className="font-semibold text-sm text-m-dark-gray">{label}</div>
      <MWell
        className={classNames("text-xs whitespace-pre-line", className)}
        {...rest}
      >
        {children}
      </MWell>
    </div>
  );
};

export default MLabeledWellComponent;
