import React, { useEffect, useState } from "react";
import { usePostHog } from "posthog-js/react";
import { MNavProps } from "../../ui/nav/MNav";
import {
  CreateOrUpdateSingleEntryRequest,
  GetEventTypeEventEnum,
  GetSingleEntryResponse,
  SearchForConnectionResponse,
  UserProfileSummary,
} from "../../../api/models";
import { useBackTimer, useBusyWatcher } from "../../../util/hooks";
import { useWrappedConnector } from "../../../api/connector";
import WizardScreen, { WizardScreenPanel } from "../../ui/screens/WizardScreen";
import LoadingScreen from "../../ui/screens/LoadingScreen";
import BaseNavbarPage from "../BaseNavbarPage";
import CommCoachSelectUserScreen from "./CommCoachSelectUserScreen";
import { MStepsCirclesProps, StepStatus } from "../../ui/MStepsCircles";
import CommCoachTopicScreen from "./CommCoachTopicScreen";
import { errorMessagesForKeyFromResponse } from "../../../api/helpers";
import CommCoachGoalsScreen from "./CommCoachGoalsScreen";
import CommCoachConcernsScreen from "./CommCoachConcernsScreen";
import CommCoachRelationshipScreen from "./CommCoachRelationshipScreen";
import waitForTask from "../../../util/tasks";
import ViewCommCoachSingleScreen from "../insights/ViewCommCoachSingleScreen";

type AskCommCoachPageProps = {
  navbarProps: MNavProps;
  nextUrl: string;
  userGuid?: string | null;
};

enum AskCommCoachStage {
  LOADING,
  PICK_USER,
  PICK_TOPIC,
  PICK_GOALS,
  PICK_CONCERNS,
  PICK_RELATIONSHIP,
  PROCESSING,
  VIEW_RESULT,
}

const AskCommCoachPageComponent = (props: AskCommCoachPageProps) => {
  const { navbarProps, nextUrl, userGuid } = props;

  const [errors, setErrors] = useState<string[]>([]);
  const [stage, setStage] = useState<AskCommCoachStage>(
    AskCommCoachStage.LOADING
  );
  const [maxStage, setMaxStage] = useState<AskCommCoachStage>(
    AskCommCoachStage.LOADING
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] =
    useState<SearchForConnectionResponse | null>(null);
  const [user, setUser] = useState<UserProfileSummary | null>(null);
  const [topic, setTopic] = useState<string>("");
  const [goals, setGoals] = useState<string[]>([]);
  const [concerns, setConcerns] = useState<string[]>([]);
  const [relationship, setRelationship] = useState<string>("");
  const [result, setResult] = useState<GetSingleEntryResponse | null>(null);
  const [isBack, asBack] = useBackTimer(stage);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);
  const posthog = usePostHog();

  useEffect(() => {
    if (stage > maxStage) {
      setMaxStage(stage);
    }
  }, [stage]);

  const goToStage = async (nextStage: AskCommCoachStage) => {
    setErrors([]);
    setStage(nextStage);
  };

  const getProgressProps = (): MStepsCirclesProps => {
    const getStepStatus = (stepIndex: number): StepStatus => {
      if (stepIndex < stage) {
        return "complete";
      }
      if (stepIndex === stage) {
        return "current";
      }
      return "upcoming";
    };

    return {
      steps: [
        {
          name: "Pick User",
          status: getStepStatus(AskCommCoachStage.PICK_USER),
          key: "pick-user",
        },
        {
          name: "Pick Topic",
          status: getStepStatus(AskCommCoachStage.PICK_TOPIC),
          key: "pick-topic",
        },
        {
          name: "Pick Goals",
          status: getStepStatus(AskCommCoachStage.PICK_GOALS),
          key: "pick-goals",
        },
        {
          name: "Pick Concerns",
          status: getStepStatus(AskCommCoachStage.PICK_CONCERNS),
          key: "pick-concerns",
        },
        {
          name: "Pick Relationship",
          status: getStepStatus(AskCommCoachStage.PICK_RELATIONSHIP),
          key: "pick-relationship",
        },
      ],
      onStepClicked: async (stepIndex: number) => {
        // Step index starts with loading, so we need to add 1
        asBack(() => goToStage(stepIndex + 1))();
      },
      maxClickableIndex: maxStage - 1,
    };
  };

  const onUserSelected = async (selectedUser: UserProfileSummary) => {
    setUser(selectedUser);
    posthog.capture(GetEventTypeEventEnum.comm_coach_started, {
      selected_user: selectedUser,
    });
    await goToStage(AskCommCoachStage.PICK_TOPIC);
  };

  const onTopicSet = async (selectedTopic: string) => {
    setTopic(selectedTopic);
    posthog.capture(GetEventTypeEventEnum.comm_coach_topic_set, {
      selected_topic: selectedTopic,
    });
    await goToStage(AskCommCoachStage.PICK_GOALS);
  };

  const onGoalsSet = async (newGoals: string[]) => {
    setGoals(newGoals);
    posthog.capture(GetEventTypeEventEnum.comm_coach_goals_set, {
      selected_goals: newGoals,
    });
    await goToStage(AskCommCoachStage.PICK_CONCERNS);
  };

  const onConcernsSet = async (newConcerns: string[]) => {
    setConcerns(newConcerns);
    posthog.capture(GetEventTypeEventEnum.comm_coach_concerns_set, {
      selected_concerns: newConcerns,
    });
    await goToStage(AskCommCoachStage.PICK_RELATIONSHIP);
  };

  const refreshSearchResults = async (newSearchTerm: string) => {
    if (newSearchTerm === searchTerm) {
      return;
    }
    setSearchTerm(newSearchTerm);
    if (newSearchTerm === "") {
      setSearchResults(null);
    } else {
      const searchResult = await connector.searchConnections({
        search_term: newSearchTerm,
      });
      setSearchResults(searchResult.c!);
    }
  };

  const processCommCoach = async (newRelationship: string | null = null) => {
    setErrors([]);
    setStage(AskCommCoachStage.PROCESSING);
    const relationshipInput = newRelationship || relationship;

    // First we send off the request to create the entry and then process it

    const request: CreateOrUpdateSingleEntryRequest = {
      about_username: user!.username!,
      conversation: topic!,
    };
    if (goals.length > 0) {
      request.goal = goals.join(", ");
    }
    if (concerns.length > 0) {
      request.concerns = concerns.join(", ");
    }
    if (relationshipInput.length > 0) {
      request.relationship = relationshipInput;
    }
    const createResponse = await connector.createOrUpdateCommCoachSingleEntry(
      request
    );
    const processResponse = await connector.submitCommCoachSingleEntry(
      createResponse.c!.entry.guid!
    );

    // If we're running locally then the task is likely already done, so let's check
    // and see if that's the case and finish if so

    if (processResponse.c!.entry.is_task_completed) {
      const resultResponse = await connector.getCommCoachSingleEntry(
        createResponse.c!.entry.guid!
      );
      setResult(resultResponse.c!);
      await goToStage(AskCommCoachStage.VIEW_RESULT);
      return;
    }

    // Now we wait for the processing to finish and then redirect to the result page

    const success = await waitForTask(
      processResponse.c!.entry.task_id!,
      connector
    );
    if (!success) {
      setErrors([
        "something broke while processing your conversation plan :( try again in a little bit",
      ]);
      return;
    }

    // The task has completed so let's load the data and display it

    const resultResponse = await connector.getCommCoachSingleEntry(
      createResponse.c!.entry.guid!
    );
    setResult(resultResponse.c!);
    await goToStage(AskCommCoachStage.VIEW_RESULT);
  };

  const onRelationshipSet = async (newRelationship: string) => {
    setRelationship(newRelationship);
    posthog.capture(GetEventTypeEventEnum.comm_coach_relationship_set, {
      selected_relationship: newRelationship,
    });
    await processCommCoach(newRelationship);
  };

  const loadUserFromGuid = async () => {
    try {
      const response = await connector.getUserProfileSummary({
        user_id: userGuid!,
      });
      setUser(response.c!);
      await goToStage(AskCommCoachStage.PICK_TOPIC);
    } catch (e: any) {
      const parsed = await errorMessagesForKeyFromResponse(e, "user_id", true);
      setErrors(parsed);
    }
  };

  useEffect(() => {
    if (userGuid) {
      loadUserFromGuid();
    } else {
      goToStage(AskCommCoachStage.PICK_USER);
    }
  }, []);

  const getElements = (): WizardScreenPanel[] => [
    {
      stage: AskCommCoachStage.LOADING,
      content: (
        <LoadingScreen
          errors={errors}
          onBackClicked={() => window.history.back()}
        />
      ),
    },
    {
      stage: AskCommCoachStage.PICK_USER,
      content: (
        <CommCoachSelectUserScreen
          progress={getProgressProps()}
          searchTerm={searchTerm}
          searchResults={searchResults?.results ?? null}
          onSearchTermUpdated={refreshSearchResults}
          onBackClicked={() => window.history.back()}
          onUserClicked={onUserSelected}
          errors={errors}
        />
      ),
    },
    {
      stage: AskCommCoachStage.PICK_TOPIC,
      content: user && (
        <CommCoachTopicScreen
          progress={getProgressProps()}
          user={user}
          onEditUserClicked={asBack(() =>
            goToStage(AskCommCoachStage.PICK_USER)
          )}
          topic={topic}
          onTopicSet={onTopicSet}
          onBackClicked={asBack(() => goToStage(AskCommCoachStage.PICK_USER))}
        />
      ),
    },
    {
      stage: AskCommCoachStage.PICK_GOALS,
      content: user && (
        <CommCoachGoalsScreen
          progress={getProgressProps()}
          user={user}
          onEditUserClicked={asBack(() =>
            goToStage(AskCommCoachStage.PICK_USER)
          )}
          topic={topic}
          onEditTopicClicked={asBack(() =>
            goToStage(AskCommCoachStage.PICK_TOPIC)
          )}
          options={[
            `understand ${user.name} better`,
            "set a boundary",
            "get on the same page",
            "serious feedback",
            "encouragement",
            "empathy",
            "excitement",
            "buy-in",
          ]}
          goals={goals}
          onGoalsSet={onGoalsSet}
          onBackClicked={asBack(() => goToStage(AskCommCoachStage.PICK_TOPIC))}
        />
      ),
    },
    {
      stage: AskCommCoachStage.PICK_CONCERNS,
      content: user && (
        <CommCoachConcernsScreen
          progress={getProgressProps()}
          user={user}
          onEditUserClicked={asBack(() =>
            goToStage(AskCommCoachStage.PICK_USER)
          )}
          topic={topic}
          onEditTopicClicked={asBack(() =>
            goToStage(AskCommCoachStage.PICK_TOPIC)
          )}
          goals={goals}
          onEditGoalsClicked={asBack(() =>
            goToStage(AskCommCoachStage.PICK_GOALS)
          )}
          options={[
            "hurting feelings",
            "micromanaging",
            "feelings of judgment",
            "fear",
            "loss of enthusiasm",
            "lack of clarity",
            "uncertain next steps",
          ]}
          concerns={concerns}
          onConcernsSet={onConcernsSet}
          onBackClicked={asBack(() => goToStage(AskCommCoachStage.PICK_GOALS))}
        />
      ),
    },
    {
      stage: AskCommCoachStage.PICK_RELATIONSHIP,
      content: user && (
        <CommCoachRelationshipScreen
          progress={getProgressProps()}
          user={user}
          onEditUserClicked={asBack(() =>
            goToStage(AskCommCoachStage.PICK_USER)
          )}
          topic={topic}
          onEditTopicClicked={asBack(() =>
            goToStage(AskCommCoachStage.PICK_TOPIC)
          )}
          goals={goals}
          onEditGoalsClicked={asBack(() =>
            goToStage(AskCommCoachStage.PICK_GOALS)
          )}
          concerns={concerns}
          onEditConcernsClicked={asBack(() =>
            goToStage(AskCommCoachStage.PICK_CONCERNS)
          )}
          relationship={relationship}
          onRelationshipSet={onRelationshipSet}
          onBackClicked={asBack(() =>
            goToStage(AskCommCoachStage.PICK_CONCERNS)
          )}
        />
      ),
    },
    {
      stage: AskCommCoachStage.PROCESSING,
      content: (
        <LoadingScreen
          errors={errors}
          onBackClicked={asBack(() =>
            goToStage(AskCommCoachStage.PICK_RELATIONSHIP)
          )}
        />
      ),
    },
    {
      stage: AskCommCoachStage.VIEW_RESULT,
      content: result && (
        <ViewCommCoachSingleScreen
          entry={result}
          onDoneClicked={() => {
            window.location.href = nextUrl;
          }}
        />
      ),
    },
  ];

  return (
    <BaseNavbarPage
      navbarProps={{
        ...navbarProps,
        forceLoading: stage === AskCommCoachStage.PROCESSING,
      }}
    >
      <WizardScreen isBack={isBack} elements={getElements()} stage={stage} />
    </BaseNavbarPage>
  );
};

AskCommCoachPageComponent.defaultProps = {
  userGuid: null,
};

export default AskCommCoachPageComponent;
