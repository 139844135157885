import React, { useState } from "react";
import * as EmailValidator from "email-validator";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import BaseDialog from "./BaseDialog";
import MInput from "../ui/MInput";
import { useBusyWatcher } from "../../util/hooks";
import MButton from "../ui/buttons/MButton";
import { clickableText } from "../../util/style";

type InviteUserDialogProps = {
  show: boolean;
  onClose: () => void;
  errors: string[];
  onEmailSubmitted: (email: string) => void;
  settingsUrl?: string | null;
  sent: boolean;
  onInviteAnotherClicked: () => void;
};

const InviteUserDialogComponent = (props: InviteUserDialogProps) => {
  const {
    show,
    onClose: onCloseInput,
    errors: errorsInput,
    onEmailSubmitted,
    settingsUrl,
    sent,
    onInviteAnotherClicked: onInviteAnotherClickedInput,
  } = props;

  const [email, setEmail] = useState<string>("");
  const [busy, _] = useBusyWatcher();

  const canSubmit = (): boolean => {
    if (email === "") {
      return false;
    }
    if (!EmailValidator.validate(email)) {
      return false;
    }
    return true;
  };

  const getErrors = (): string[] => {
    const errors = [...errorsInput];
    if (email !== "" && !EmailValidator.validate(email)) {
      errors.push("invalid email");
    }
    return errors;
  };

  const onInviteAnotherClicked = () => {
    setEmail("");
    onInviteAnotherClickedInput();
  };

  const onClose = () => {
    setEmail("");
    onCloseInput();
  };

  return (
    <BaseDialog
      title={sent ? "invite sent!" : "invite to Manual"}
      show={show}
      onClose={onClose}
    >
      <div className="max-w-lg text-sm flex flex-col gap-6 text-m-dark-gray">
        {sent ? (
          <>
            <div>
              we've sent an invite to{" "}
              <span className="font-semibold">{email}</span>!
            </div>
            <div>
              we'll send you an email once they accept your invite and join
              Manual. thank you for helping us grow our community. our ability
              to make an impact depends on people like you!
            </div>
            <div>
              you can view all of the invites that you've sent on the{" "}
              {settingsUrl ? (
                <a className={clickableText} href={settingsUrl}>
                  settings page{" "}
                </a>
              ) : (
                "settings page "
              )}
              under the "invites" tab.
            </div>
            <div className="w-full flex flex-row justify-between">
              <MButton kind="secondary" onClick={onClose}>
                close
              </MButton>
              <MButton
                kind="primary"
                onClick={onInviteAnotherClicked}
                icon={<UserPlusIcon className="h-5 w-5" />}
              >
                invite another
              </MButton>
            </div>
          </>
        ) : (
          <>
            <div>can't find who you're looking for on Manual? invite them!</div>
            <div>
              invites that you've already sent out can be viewed via the
              "invites" tab on the{" "}
              {settingsUrl ? (
                <a className={clickableText} href={settingsUrl}>
                  settings page
                </a>
              ) : (
                "settings page"
              )}
              .
            </div>
            <MInput
              type="email"
              value={email}
              label="email"
              onChange={(e) => setEmail(e.target.value)}
              disabled={busy}
              errors={getErrors()}
              onEnterPressed={() => canSubmit() && onEmailSubmitted(email)}
              required
            />
            <div className="w-full flex flex-row-reverse">
              <MButton
                kind="primary"
                disabled={busy || !canSubmit()}
                onClick={() => onEmailSubmitted(email)}
                icon={<UserPlusIcon className="h-5 w-5" />}
              >
                invite
              </MButton>
            </div>
          </>
        )}
      </div>
    </BaseDialog>
  );
};

InviteUserDialogComponent.defaultProps = {
  settingsUrl: null,
};

export default InviteUserDialogComponent;
