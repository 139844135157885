import React from "react";
import {
  Attachments,
  GlimpseAttachmentVisibilityDetail,
} from "../../../../api/models";
import FormScreen from "../../../ui/screens/FormScreen";
import { useBusyWatcher, useUniqueId } from "../../../../util/hooks";
import MToggle from "../../../ui/MToggle";
import { hasAnyAttachments } from "../../../../util/user";
import { classNames } from "../../../../util/strings";
import MTooltip from "../../../ui/MTooltip";

type UpdateGlimpseAttachmentsScreenProps = {
  attachments: Attachments;
  visibility: GlimpseAttachmentVisibilityDetail;
  onResumeVisibilityChanged: (newVisibility: boolean) => void;
  onNextClicked?: (() => void) | null;
  onBackClicked: () => void;
};

const UpdateGlimpseAttachmentsScreenComponent = (
  props: UpdateGlimpseAttachmentsScreenProps
) => {
  const {
    attachments,
    visibility,
    onResumeVisibilityChanged,
    onNextClicked,
    onBackClicked,
  } = props;

  const uniqueId = useUniqueId();
  const [busy, _] = useBusyWatcher();
  const hasAttachments = hasAnyAttachments(attachments);

  const getComment = (): string => {
    let baseComment =
      "these will be displayed in the 'attachments' section of your glimpse";
    if (!hasAttachments) {
      baseComment = `${baseComment}. you haven't added any attachments to your profile yet, so there's nothing for you to do on this page.`;
    }
    return baseComment;
  };

  return (
    <>
      <FormScreen
        title="set your glimpse attachments"
        comment={getComment()}
        onNextClicked={onNextClicked}
        nextDisabled={busy}
        onBackClicked={onBackClicked}
        backDisabled={busy}
        stacked
      >
        <div className="w-full flex flex-row justify-between gap-3">
          <div
            className={classNames(attachments.resume ? null : "line-through")}
          >
            include your resume
          </div>
          <div
            data-tooltip-id={uniqueId}
            data-tooltip-content={
              attachments.resume
                ? null
                : "you haven't uploaded a resume yet. you can upload a resume from the attachments section on your profile"
            }
          >
            <MToggle
              isOn={visibility.resume_is_visible!}
              disabled={busy || !attachments.resume}
              onToggle={() =>
                onResumeVisibilityChanged(!visibility.resume_is_visible!)
              }
            />
          </div>
        </div>
      </FormScreen>
      <MTooltip id={uniqueId} />
    </>
  );
};

UpdateGlimpseAttachmentsScreenComponent.defaultProps = {
  onNextClicked: null,
};

export default UpdateGlimpseAttachmentsScreenComponent;
