import React from "react";
import MLoadingInput from "../../MLoadingInput";
import MButton from "../../buttons/MButton";
import { useBusyWatcher } from "../../../../util/hooks";
import SettingsLabelRow from "./SidebarLabelRow";
import { CommonInputRowProps } from "./common";

type SidebarTextInputRowProps = CommonInputRowProps & {
  value: string | null;
  onValueUpdated: (value: string) => void;
  onUpdateClicked: () => void;
  placeholder: string;
};

const SidebarTextInputRowComponent = (props: SidebarTextInputRowProps) => {
  const {
    label,
    value,
    onValueUpdated,
    onUpdateClicked,
    loading,
    placeholder,
    updateDisabled,
    errors,
    tooltip,
  } = props;

  const [busy, _] = useBusyWatcher();

  const canSubmit = !busy && !updateDisabled;

  return (
    <SettingsLabelRow
      label={label}
      tooltip={tooltip}
      middle={
        <div className="w-full max-w-sm">
          <MLoadingInput
            type="text"
            loading={loading}
            value={value ?? ""}
            disabled={busy}
            onChange={(e) => onValueUpdated(e.target.value)}
            placeholder={placeholder}
            errors={errors}
            onEnterPressed={() => canSubmit && onUpdateClicked()}
          />
        </div>
      }
      end={
        <MButton disabled={busy || updateDisabled} onClick={onUpdateClicked}>
          update
        </MButton>
      }
    />
  );
};

export default SidebarTextInputRowComponent;
