import React, { useMemo, useState } from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { RadioGroup } from "@headlessui/react";
import Skeleton from "react-loading-skeleton";
import {
  GetSubscriptionTiersResponse,
  SubscriptionTierPeriodEnum,
  SubscriptionTierTierEnum,
} from "../../../api/models";
import { capitalize, classNames } from "../../../util/strings";
import { addNextToUrl } from "../../../util/url";
import MButton from "../../ui/buttons/MButton";

type BillingPeriod = {
  period: SubscriptionTierPeriodEnum;
  label: string;
  priceSuffix: string;
};

const MONTHLY: BillingPeriod = {
  period: SubscriptionTierPeriodEnum.monthly,
  label: "Monthly",
  priceSuffix: "/mo",
};
const ANNUAL: BillingPeriod = {
  period: SubscriptionTierPeriodEnum.annual,
  label: "Annually",
  priceSuffix: "/yr",
};

const POPULAR_TIER = SubscriptionTierTierEnum.essentials;

type SubscriptionChoicesViewProps = {
  response?: GetSubscriptionTiersResponse;
  highlightTier?: SubscriptionTierTierEnum;
  highlightReason?: string;
};

const SubscriptionChoicesViewComponent = (
  props: SubscriptionChoicesViewProps
) => {
  const { response, highlightTier, highlightReason } = props;

  const subscribedPrice = response?.tiers.find(
    (tier) => tier.price_id === response?.subscription_price
  );

  const [period, setPeriod] = useState<BillingPeriod>(
    subscribedPrice?.period === "annual" ? ANNUAL : MONTHLY
  );

  const [highlightedPrices, highlightedLabel] = useMemo(() => {
    if (highlightTier && response) {
      return [
        response.tiers
          .filter((tier) => tier.tier === highlightTier)
          .map((tier) => tier.price_id),
        highlightReason ?? "Recommended",
      ];
    }
    if (subscribedPrice) {
      return [[subscribedPrice.price_id], "Current plan"];
    }
    if (response) {
      return [
        response.tiers
          .filter((tier) => tier.tier === POPULAR_TIER)
          .map((tier) => tier.price_id),
        "Most popular",
      ];
    }
    return ["", "Most popular"];
  }, [highlightTier, subscribedPrice, response?.tiers]);

  const getHrefForPrice = (priceId: string): string => {
    if (response && response.subscription_price) {
      return addNextToUrl(response!.manage_link);
    }
    return addNextToUrl(`${response?.subscribe_link}?price_id=${priceId}`);
  };

  const currentPrices = response?.tiers
    .filter((tier) => tier.period === period.period)
    .map((tier) => {
      const metadata = response.tiers.find((meta) => meta.tier === tier.tier);

      const highlighted = highlightedPrices.includes(tier.price_id);

      return {
        ...metadata,
        ...tier,
        highlighted,
        label: highlighted ? highlightedLabel : undefined,
        price: `$${(tier.price / 100).toFixed(2)}`,
        buyHref: getHrefForPrice(tier.price_id),
        subscribed: tier.price_id === response?.subscription_price,
      };
    });
  const getBuyButtonCopy = (subscribed: boolean): string => {
    if (subscribed) {
      return "Manage Plan";
    }
    if (subscribedPrice) {
      return "Switch Plan";
    }
    return "Buy Plan";
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex justify-center">
        <RadioGroup
          value={period}
          disabled={!currentPrices}
          onChange={(val) => setPeriod(val)}
          className="bg-m-secondary grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-m-light-gray"
        >
          <RadioGroup.Label className="sr-only">
            Payment frequency
          </RadioGroup.Label>
          {[MONTHLY, ANNUAL].map((option) => (
            <RadioGroup.Option
              key={option.period}
              value={option}
              className={({ checked }) =>
                classNames(
                  checked ? "bg-primary text-secondary" : "text-m-gray",
                  "cursor-pointer rounded-full px-2.5 py-1"
                )
              }
            >
              <span>{option.label}</span>
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      </div>
      <div className="isolate mx-auto flex gap-8 justify-center py-4 grid grid-cols-1 sm:grid-cols-2">
        {!currentPrices && (
          <div className="ring-1 ring-m-light-gray bg-m-secondary rounded-3xl p-8 xl:p-10 w-96">
            <Skeleton />
            <p className="mt-4 text-sm leading-6 text-m-gray">
              <Skeleton count={2} />
            </p>
            <Skeleton className="mt-6 flex items-baseline gap-x-1 h-16" />
            <Skeleton
              className={classNames(
                "text-blue-600 ring-1 ring-inset ring-blue-200 hover:ring-blue-300",
                "block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              )}
            />
            <div className="mt-6">
              <Skeleton count={3} />
            </div>
          </div>
        )}
        {currentPrices &&
          currentPrices.map((priceWithMetadata) => (
            <div
              key={priceWithMetadata.tier}
              className={classNames(
                priceWithMetadata.highlighted
                  ? "ring-2 ring-primary bg-slate-50"
                  : "ring-1 ring-m-light-gray",
                "bg-m-secondary rounded-3xl p-8 xl:p-10 w-96"
              )}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h3
                  id={priceWithMetadata.tier}
                  className={classNames(
                    "text-m-black",
                    "text-lg font-semibold leading-8"
                  )}
                >
                  {capitalize(priceWithMetadata.tier)}
                </h3>
                {priceWithMetadata.label ? (
                  <p className="rounded-full bg-primary-light px-2.5 py-1 text-xs font-semibold leading-5 text-primary">
                    {priceWithMetadata.label}
                  </p>
                ) : null}
              </div>
              <p className="mt-4 text-sm leading-6 text-m-dark-gray">
                {priceWithMetadata.description}
              </p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-m-black">
                  {priceWithMetadata.price}
                </span>
                <span className="text-sm font-semibold leading-6 text-dark-gray">
                  {period.priceSuffix}
                </span>
              </p>
              <div className="mt-6">
                <MButton
                  kind="primary"
                  href={priceWithMetadata.buyHref}
                  aria-describedby={priceWithMetadata.tier}
                  className="w-full"
                >
                  {getBuyButtonCopy(priceWithMetadata.subscribed)}
                </MButton>
              </div>
              {priceWithMetadata.features && (
                <ul className="mt-8 space-y-3 text-sm leading-6 text-dark-gray xl:mt-10">
                  {(priceWithMetadata.features as [string]).map(
                    (feature: string) => (
                      <li
                        key={feature}
                        className="flex gap-x-3 text-m-dark-gray text-left"
                      >
                        <CheckIcon
                          className="h-6 w-5 flex-none text-primary"
                          aria-hidden="true"
                        />
                        {feature}
                      </li>
                    )
                  )}
                </ul>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

SubscriptionChoicesViewComponent.defaultProps = {
  highlightTier: undefined,
  highlightReason: undefined,
  response: undefined,
};

export default SubscriptionChoicesViewComponent;
