/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  EmptyResponseWrapper,
  EmptyResponseWrapperFromJSON,
  EmptyResponseWrapperToJSON,
  ListPendingReceivedConnectionRequestsResponseSerializer,
  ListPendingReceivedConnectionRequestsResponseSerializerFromJSON,
  ListPendingReceivedConnectionRequestsResponseSerializerToJSON,
  ListPendingSentConnectionRequestsResponseSerializer,
  ListPendingSentConnectionRequestsResponseSerializerFromJSON,
  ListPendingSentConnectionRequestsResponseSerializerToJSON,
  RemoveConnectionRequest,
  RemoveConnectionRequestFromJSON,
  RemoveConnectionRequestToJSON,
  RequestConnectionRequest,
  RequestConnectionRequestFromJSON,
  RequestConnectionRequestToJSON,
  RespondToConnectionRequestRequest,
  RespondToConnectionRequestRequestFromJSON,
  RespondToConnectionRequestRequestToJSON,
  SearchForConnectionResponseSerializer,
  SearchForConnectionResponseSerializerFromJSON,
  SearchForConnectionResponseSerializerToJSON,
} from "../models";

export interface ListReceivedConnectionRequestsRequest {
  offset?: number;
  limit?: number;
}

export interface ListSentConnectionRequestsRequest {
  offset?: number;
  limit?: number;
}

export interface RemoveConnectionOperationRequest {
  data: RemoveConnectionRequest;
}

export interface RequestConnectionOperationRequest {
  data: RequestConnectionRequest;
}

export interface RespondToConnectionRequestOperationRequest {
  data: RespondToConnectionRequestRequest;
}

export interface SearchForConnectionRequest {
  offset?: number;
  limit?: number;
  search_term?: string | null;
}

/**
 *
 */
export class ConnectionApi extends runtime.BaseAPI {
  /**
   * List all outstanding connection requests received by the requesting user.
   */
  async listReceivedConnectionRequestsRaw(
    requestParameters: ListReceivedConnectionRequestsRequest,
    initOverrides?: RequestInit
  ): Promise<
    runtime.ApiResponse<ListPendingReceivedConnectionRequestsResponseSerializer>
  > {
    const queryParameters: any = {};

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/connection/list-received-requests`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ListPendingReceivedConnectionRequestsResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * List all outstanding connection requests received by the requesting user.
   */
  async listReceivedConnectionRequests(
    requestParameters: ListReceivedConnectionRequestsRequest = {},
    initOverrides?: RequestInit
  ): Promise<ListPendingReceivedConnectionRequestsResponseSerializer> {
    const response = await this.listReceivedConnectionRequestsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * List all outstanding connection requests sent by the requesting user.
   */
  async listSentConnectionRequestsRaw(
    requestParameters: ListSentConnectionRequestsRequest,
    initOverrides?: RequestInit
  ): Promise<
    runtime.ApiResponse<ListPendingSentConnectionRequestsResponseSerializer>
  > {
    const queryParameters: any = {};

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/connection/list-sent-requests`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ListPendingSentConnectionRequestsResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * List all outstanding connection requests sent by the requesting user.
   */
  async listSentConnectionRequests(
    requestParameters: ListSentConnectionRequestsRequest = {},
    initOverrides?: RequestInit
  ): Promise<ListPendingSentConnectionRequestsResponseSerializer> {
    const response = await this.listSentConnectionRequestsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Remove a connection with another user.
   */
  async removeConnectionRaw(
    requestParameters: RemoveConnectionOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling removeConnection."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/connection/remove`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: RemoveConnectionRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Remove a connection with another user.
   */
  async removeConnection(
    requestParameters: RemoveConnectionOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.removeConnectionRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Submit a connection request to connect with another user.
   */
  async requestConnectionRaw(
    requestParameters: RequestConnectionOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling requestConnection."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/connection/request`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: RequestConnectionRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Submit a connection request to connect with another user.
   */
  async requestConnection(
    requestParameters: RequestConnectionOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.requestConnectionRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Respond to a connection request.
   */
  async respondToConnectionRequestRaw(
    requestParameters: RespondToConnectionRequestOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling respondToConnectionRequest."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/connection/respond`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: RespondToConnectionRequestRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Respond to a connection request.
   */
  async respondToConnectionRequest(
    requestParameters: RespondToConnectionRequestOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.respondToConnectionRequestRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Search through the users that the requesting user is already connected with.
   */
  async searchForConnectionRaw(
    requestParameters: SearchForConnectionRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<SearchForConnectionResponseSerializer>> {
    const queryParameters: any = {};

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.search_term !== undefined) {
      queryParameters["search_term"] = requestParameters.search_term;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/connection/search`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SearchForConnectionResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Search through the users that the requesting user is already connected with.
   */
  async searchForConnection(
    requestParameters: SearchForConnectionRequest = {},
    initOverrides?: RequestInit
  ): Promise<SearchForConnectionResponseSerializer> {
    const response = await this.searchForConnectionRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
