import React, { useRef } from "react";
import MButton, { MButtonProps } from "./MButton";

type MFileButtonProps = MButtonProps & {
  onFileSelected: (file: File) => void;
  text?: string;
  accept?: string | null;
};

const MFileButtonComponent = (props: MFileButtonProps) => {
  const { onFileSelected, text, accept, ...rest } = props;

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      onFileSelected(files[0]);
    }
  };

  const getProps = (): MButtonProps => {
    const toReturn = { ...rest };
    toReturn.onClick = () => hiddenFileInput.current?.click();
    return toReturn;
  };

  return (
    <>
      <input
        type="file"
        accept={accept ?? "*"}
        ref={hiddenFileInput}
        onChange={onChange}
        className="hidden"
      />
      <MButton {...getProps()}>{text}</MButton>
    </>
  );
};

MFileButtonComponent.defaultProps = {
  text: "pick a file",
  accept: null,
};

export default MFileButtonComponent;
