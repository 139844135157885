import React, { useEffect, useState } from "react";
import { MNavProps } from "../../../ui/nav/MNav";
import { useBackTimer, useBusyWatcher } from "../../../../util/hooks";
import { useWrappedConnector } from "../../../../api/connector";
import { useAsyncConfirmationDialog } from "../../../dialogs/hooks";
import WizardScreen, {
  WizardScreenPanel,
} from "../../../ui/screens/WizardScreen";
import LoadingScreen from "../../../ui/screens/LoadingScreen";
import BaseNavbarPage from "../../BaseNavbarPage";
import {
  errorMessagesForKeyFromResponse,
  errorMessagesFromResponse,
} from "../../../../api/helpers";
import {
  CreateEmployerRequest,
  EmployerRelatedDetail,
  EmployerRoleDetail,
  ListEmployersResponse,
} from "../../../../api/models";
import ListWorkHistoryScreen from "./ListWorkHistoryScreen";
import EditEmployerScreen from "./EditEmployerScreen";
import EditEmployerRoleScreen, {
  EmployerRoleEdit,
} from "./EditEmployerRoleScreen";

type WorkHistoryPageProps = {
  nextUrl: string;
  navbarProps: MNavProps;
};

enum WorkHistoryStage {
  LOADING,
  LIST,
  ADD_EMPLOYER,
  EDIT_EMPLOYER,
  ADD_ROLE,
  EDIT_ROLE,
}

const WorkHistoryPageComponent = (props: WorkHistoryPageProps) => {
  const { nextUrl, navbarProps } = props;

  const [errors, setErrors] = useState<string[]>([]);
  const [errorMap, setErrorMap] = useState<{ [key: string]: string[] }>({});
  const [stage, setStage] = useState<WorkHistoryStage>(
    WorkHistoryStage.LOADING
  );
  const [employers, setEmployers] = useState<ListEmployersResponse | null>(
    null
  );
  const [employer, setEmployer] = useState<EmployerRelatedDetail | null>(null);
  const [role, setRole] = useState<EmployerRoleDetail | null>(null);
  const [isBack, asBack] = useBackTimer(stage);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);
  const confirmDialog = useAsyncConfirmationDialog();

  const goToStage = async (nextStage: WorkHistoryStage) => {
    setErrorMap({});
    setErrors([]);
    // eslint-disable-next-line default-case
    switch (nextStage) {
      case WorkHistoryStage.LIST: {
        try {
          const response = await connector.listEmployers();
          setEmployers(response.c!);
        } catch (e: any) {
          const parsed = await errorMessagesForKeyFromResponse(e, "", true);
          setErrors(parsed);
        }
        break;
      }
    }
    setStage(nextStage);
  };

  const onEditRoleClicked = async (toEdit: EmployerRoleDetail) => {
    setRole(toEdit);
    await goToStage(WorkHistoryStage.EDIT_ROLE);
  };

  const onEditEmployerClicked = async (toEdit: EmployerRelatedDetail) => {
    setEmployer(toEdit);
    await goToStage(WorkHistoryStage.EDIT_EMPLOYER);
  };

  const onAddRoleClicked = async (toAdd: EmployerRelatedDetail) => {
    setEmployer(toAdd);
    await goToStage(WorkHistoryStage.ADD_ROLE);
  };

  const onDeleteEmployerClicked = async (toDelete: EmployerRelatedDetail) => {
    const result = await confirmDialog.confirm({
      title: `delete '${toDelete.name}'?`,
      body: `are you sure you want to delete the employer '${toDelete.name}'? this action cannot be undone.`,
    });
    if (!result) {
      return;
    }
    await connector.deleteEmployer(toDelete.guid!);
    const newEmployers = await connector.listEmployers();
    setEmployers(newEmployers.c!);
  };

  const onDeleteRoleClicked = async (toDelete: EmployerRoleDetail) => {
    const result = await confirmDialog.confirm({
      title: `delete '${toDelete.title}'?`,
      body: `are you sure you want to delete the role '${toDelete.title}'? this action cannot be undone.`,
    });
    if (!result) {
      return;
    }
    await connector.deleteRole(toDelete.guid!);
    const newEmployers = await connector.listEmployers();
    setEmployers(newEmployers.c!);
  };

  const onSaveEditedEmployerClicked = async (toSave: CreateEmployerRequest) => {
    setErrorMap({});
    const data = { employer: employer!.guid!, ...toSave };
    try {
      await connector.updateEmployer(data);
    } catch (e: any) {
      const responseErrors = await errorMessagesFromResponse(e);
      setErrorMap(responseErrors);
      return;
    }
    await goToStage(WorkHistoryStage.LIST);
  };

  const onSaveNewEmployerClicked = async (toSave: CreateEmployerRequest) => {
    setErrorMap({});
    try {
      await connector.createEmployer(toSave);
    } catch (e: any) {
      const responseErrors = await errorMessagesFromResponse(e);
      setErrorMap(responseErrors);
      return;
    }
    await goToStage(WorkHistoryStage.LIST);
  };

  const onSaveAddRoleClicked = async (toSave: EmployerRoleEdit) => {
    setErrorMap({});
    const data = { employer: employer!.guid!, ...toSave };
    try {
      await connector.createRole(data);
    } catch (e: any) {
      const responseErrors = await errorMessagesFromResponse(e);
      setErrorMap(responseErrors);
      return;
    }
    await goToStage(WorkHistoryStage.LIST);
  };

  const onSaveEditRoleClicked = async (toSave: EmployerRoleEdit) => {
    setErrorMap({});
    const data = { role: role!.guid!, ...toSave };
    try {
      await connector.updateRole(data);
    } catch (e: any) {
      const responseErrors = await errorMessagesFromResponse(e);
      setErrorMap(responseErrors);
      return;
    }
    await goToStage(WorkHistoryStage.LIST);
  };

  const getElements = (): WizardScreenPanel[] => [
    {
      stage: WorkHistoryStage.LOADING,
      content: (
        <LoadingScreen
          errors={errors}
          onBackClicked={() => window.history.back()}
        />
      ),
    },
    {
      stage: WorkHistoryStage.LIST,
      content: employers && (
        <ListWorkHistoryScreen
          employers={employers}
          onAddEmployerClicked={() => goToStage(WorkHistoryStage.ADD_EMPLOYER)}
          onEditEmployerClicked={onEditEmployerClicked}
          onDeleteEmployerClicked={onDeleteEmployerClicked}
          onAddRoleClicked={onAddRoleClicked}
          onEditRoleClicked={onEditRoleClicked}
          onDeleteRoleClicked={onDeleteRoleClicked}
          onDoneClicked={() => {
            window.location.href = nextUrl;
          }}
        />
      ),
    },
    {
      stage: WorkHistoryStage.EDIT_EMPLOYER,
      content: employer && (
        <EditEmployerScreen
          employer={employer}
          errors={errorMap}
          onSaveClicked={onSaveEditedEmployerClicked}
          onBackClicked={asBack(() => goToStage(WorkHistoryStage.LIST))}
        />
      ),
    },
    {
      stage: WorkHistoryStage.ADD_EMPLOYER,
      content: (
        <EditEmployerScreen
          errors={errorMap}
          onSaveClicked={onSaveNewEmployerClicked}
          onBackClicked={asBack(() => goToStage(WorkHistoryStage.LIST))}
        />
      ),
    },
    {
      stage: WorkHistoryStage.ADD_ROLE,
      content: (
        <EditEmployerRoleScreen
          errors={errorMap}
          onSaveClicked={onSaveAddRoleClicked}
          onBackClicked={asBack(() => goToStage(WorkHistoryStage.LIST))}
        />
      ),
    },
    {
      stage: WorkHistoryStage.EDIT_ROLE,
      content: role && (
        <EditEmployerRoleScreen
          role={role}
          errors={errorMap}
          onSaveClicked={onSaveEditRoleClicked}
          onBackClicked={asBack(() => goToStage(WorkHistoryStage.LIST))}
        />
      ),
    },
  ];

  useEffect(() => {
    goToStage(WorkHistoryStage.LIST);
  }, []);

  return (
    <BaseNavbarPage navbarProps={navbarProps}>
      {confirmDialog.dialog}
      <WizardScreen isBack={isBack} elements={getElements()} stage={stage} />
    </BaseNavbarPage>
  );
};

export default WorkHistoryPageComponent;
