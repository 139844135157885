import React, { PropsWithChildren, ReactElement } from "react";
import CenterColumnScreen from "./CenterColumnScreen";

type CenterColumnStickyButtonScreenProps = {
  className?: string | null;
  fullWidth?: boolean;
  buttons?: ReactElement[];
  stacked?: boolean;
};

const CenterColumnStickyButtonScreenComponent = (
  props: PropsWithChildren<CenterColumnStickyButtonScreenProps>
) => {
  const { className, fullWidth, buttons, stacked, children } = props;

  const getClassName = (): string => {
    if (stacked) {
      return "flex flex-col gap-1";
    }
    if (buttons!.length <= 2) {
      return "flex flex-row-reverse gap-3";
    }
    return "flex flex-col gap-1";
  };

  return (
    <CenterColumnScreen
      className={className}
      fullWidth={fullWidth}
      stickyFooterContent={
        buttons && buttons.length > 0 ? (
          <div className={getClassName()}>{buttons}</div>
        ) : null
      }
    >
      {children}
    </CenterColumnScreen>
  );
};

CenterColumnStickyButtonScreenComponent.defaultProps = {
  className: null,
  fullWidth: false,
  stacked: false,
  buttons: [],
};

export default CenterColumnStickyButtonScreenComponent;
