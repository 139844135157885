import React, { RefObject, useState } from "react";
import FormScreen from "../../ui/screens/FormScreen";
import { useBusyWatcher } from "../../../util/hooks";
import ImageCropAndUploadContainer from "./ImageCropAndUploadContainer";

type AddProfilePictureScreenProps = {
  onBackClicked: () => void;
  onSaveClicked: (dataUrl: string) => void;
};

const AddProfilePictureScreenComponent = (
  props: AddProfilePictureScreenProps
) => {
  const { onBackClicked, onSaveClicked: onSaveClickedInput } = props;

  const [canvasRef, setCanvasRef] =
    useState<RefObject<HTMLCanvasElement> | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [busy, _] = useBusyWatcher();

  const canSubmit = (): boolean => canvasRef !== null;

  const onSaveClicked = () => {
    setProcessing(true);
    onSaveClickedInput(canvasRef!.current!.toDataURL("image/jpeg", 0.7));
    setProcessing(false);
  };

  return (
    <FormScreen
      title="add new photo"
      onNextClicked={onSaveClicked}
      nextDisabled={busy || processing || !canSubmit()}
      nextText="save"
      onBackClicked={onBackClicked}
      backDisabled={busy || processing}
    >
      <ImageCropAndUploadContainer onCroppedImageAvailable={setCanvasRef} />
    </FormScreen>
  );
};

export default AddProfilePictureScreenComponent;
