/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  AssessmentDetail,
  AssessmentDetailFromJSON,
  AssessmentDetailFromJSONTyped,
  AssessmentDetailToJSON,
} from "./AssessmentDetail";

/**
 *
 * @export
 * @interface GetAssessmentsAssessment
 */
export interface GetAssessmentsAssessment {
  /**
   *
   * @type {AssessmentDetail}
   * @memberof GetAssessmentsAssessment
   */
  assessment: AssessmentDetail;
  /**
   *
   * @type {number}
   * @memberof GetAssessmentsAssessment
   */
  num_prompts: number;
  /**
   *
   * @type {number}
   * @memberof GetAssessmentsAssessment
   */
  num_answers_required: number;
  /**
   *
   * @type {number}
   * @memberof GetAssessmentsAssessment
   */
  num_answers: number;
  /**
   *
   * @type {boolean}
   * @memberof GetAssessmentsAssessment
   */
  response_available: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetAssessmentsAssessment
   */
  is_completed: boolean;
}

export function GetAssessmentsAssessmentFromJSON(
  json: any
): GetAssessmentsAssessment {
  return GetAssessmentsAssessmentFromJSONTyped(json, false);
}

export function GetAssessmentsAssessmentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAssessmentsAssessment {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    assessment: AssessmentDetailFromJSON(json["assessment"]),
    num_prompts: json["num_prompts"],
    num_answers_required: json["num_answers_required"],
    num_answers: json["num_answers"],
    response_available: json["response_available"],
    is_completed: json["is_completed"],
  };
}

export function GetAssessmentsAssessmentToJSON(
  value?: GetAssessmentsAssessment | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    assessment: AssessmentDetailToJSON(value.assessment),
    num_prompts: value.num_prompts,
    num_answers_required: value.num_answers_required,
    num_answers: value.num_answers,
    response_available: value.response_available,
    is_completed: value.is_completed,
  };
}
