/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  PromptWithSelected,
  PromptWithSelectedFromJSON,
  PromptWithSelectedFromJSONTyped,
  PromptWithSelectedToJSON,
} from "./PromptWithSelected";

/**
 *
 * @export
 * @interface ListPromptResponsesForGlimpseResponse
 */
export interface ListPromptResponsesForGlimpseResponse {
  /**
   *
   * @type {Array<PromptWithSelected>}
   * @memberof ListPromptResponsesForGlimpseResponse
   */
  prompt_responses: Array<PromptWithSelected>;
  /**
   *
   * @type {number}
   * @memberof ListPromptResponsesForGlimpseResponse
   */
  cur_selected_count: number;
  /**
   *
   * @type {number}
   * @memberof ListPromptResponsesForGlimpseResponse
   */
  max_selected_count: number;
}

export function ListPromptResponsesForGlimpseResponseFromJSON(
  json: any
): ListPromptResponsesForGlimpseResponse {
  return ListPromptResponsesForGlimpseResponseFromJSONTyped(json, false);
}

export function ListPromptResponsesForGlimpseResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ListPromptResponsesForGlimpseResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    prompt_responses: (json["prompt_responses"] as Array<any>).map(
      PromptWithSelectedFromJSON
    ),
    cur_selected_count: json["cur_selected_count"],
    max_selected_count: json["max_selected_count"],
  };
}

export function ListPromptResponsesForGlimpseResponseToJSON(
  value?: ListPromptResponsesForGlimpseResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    prompt_responses: (value.prompt_responses as Array<any>).map(
      PromptWithSelectedToJSON
    ),
    cur_selected_count: value.cur_selected_count,
    max_selected_count: value.max_selected_count,
  };
}
