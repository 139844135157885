/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GlimpseDetail
 */
export interface GlimpseDetail {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof GlimpseDetail
   */
  readonly guid?: string;
  /**
   * An optional name that a user can apply to a Glimpse.
   * @type {string}
   * @memberof GlimpseDetail
   */
  title?: string | null;
  /**
   * An optional description that a user can apply to a Glimpse.
   * @type {string}
   * @memberof GlimpseDetail
   */
  description?: string | null;
  /**
   * The time at which this record was created.
   * @type {Date}
   * @memberof GlimpseDetail
   */
  readonly time_created?: Date;
  /**
   * Whether or not this glimpse is visible to the public.
   * @type {boolean}
   * @memberof GlimpseDetail
   */
  visible?: boolean;
  /**
   * The pitch context that this glimpse is generated based on.
   * @type {string}
   * @memberof GlimpseDetail
   */
  pitch_context?: string | null;
  /**
   * The intended audience that the glimpse is being made for.
   * @type {string}
   * @memberof GlimpseDetail
   */
  pitch_audience?: string | null;
  /**
   * The contents (initially generated by LLM) of this glimpse.
   * @type {string}
   * @memberof GlimpseDetail
   */
  content?: string | null;
  /**
   * The task ID for the Celery task that was enqueued to perform the communication coach flow.
   * @type {string}
   * @memberof GlimpseDetail
   */
  task_id?: string | null;
  /**
   * Whether or not the referenced Celery task has been completed.
   * @type {boolean}
   * @memberof GlimpseDetail
   */
  is_task_completed?: boolean;
}

export function GlimpseDetailFromJSON(json: any): GlimpseDetail {
  return GlimpseDetailFromJSONTyped(json, false);
}

export function GlimpseDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GlimpseDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    title: !exists(json, "title") ? undefined : json["title"],
    description: !exists(json, "description") ? undefined : json["description"],
    time_created: !exists(json, "time_created")
      ? undefined
      : new Date(json["time_created"]),
    visible: !exists(json, "visible") ? undefined : json["visible"],
    pitch_context: !exists(json, "pitch_context")
      ? undefined
      : json["pitch_context"],
    pitch_audience: !exists(json, "pitch_audience")
      ? undefined
      : json["pitch_audience"],
    content: !exists(json, "content") ? undefined : json["content"],
    task_id: !exists(json, "task_id") ? undefined : json["task_id"],
    is_task_completed: !exists(json, "is_task_completed")
      ? undefined
      : json["is_task_completed"],
  };
}

export function GlimpseDetailToJSON(value?: GlimpseDetail | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    title: value.title,
    description: value.description,
    visible: value.visible,
    pitch_context: value.pitch_context,
    pitch_audience: value.pitch_audience,
    content: value.content,
    task_id: value.task_id,
    is_task_completed: value.is_task_completed,
  };
}
