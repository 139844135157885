/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface RetrieveDirResponse
 */
export interface RetrieveDirResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof RetrieveDirResponse
   */
  files: Array<string>;
}

export function RetrieveDirResponseFromJSON(json: any): RetrieveDirResponse {
  return RetrieveDirResponseFromJSONTyped(json, false);
}

export function RetrieveDirResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RetrieveDirResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    files: json["files"],
  };
}

export function RetrieveDirResponseToJSON(
  value?: RetrieveDirResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    files: value.files,
  };
}
