import React from "react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import MWellButton from "./MWellButton";
import { CommonWellButtonProps } from "./common";

type MWellCollapseButtonProps = CommonWellButtonProps & {
  collapsed: boolean;
};

const MWellCollapseButtonComponent = (props: MWellCollapseButtonProps) => {
  const { collapsed, ...rest } = props;

  return (
    <MWellButton
      icon={collapsed ? <PlusIcon /> : <MinusIcon />}
      className="text-m-dark-gray"
      {...rest}
    />
  );
};

export default MWellCollapseButtonComponent;
