import React from "react";
import { classNames } from "../../util/strings";
import MToggle from "./MToggle";
import MTooltip from "./MTooltip";
import { useUniqueId } from "../../util/hooks";

type MVisibilityToggleProps = {
  onClick: () => void;
  disabled?: boolean;
  copy?: string;
  isVisible?: boolean;
  disabledTooltip?: string | null;
};

const MVisibilityToggleComponent = (props: MVisibilityToggleProps) => {
  const { onClick, disabled, copy, isVisible, disabledTooltip } = props;

  const uniqueId = useUniqueId();

  return (
    <div className="flex flex-row items-center gap-3">
      <div
        className={classNames(
          !isVisible ? "line-through opacity-80" : null,
          "text-sm"
        )}
      >
        {copy}
      </div>
      <div
        data-tooltip-id={uniqueId}
        data-tooltip-content={disabledTooltip}
        data-tooltip-hidden={isVisible}
      >
        <MToggle isOn={isVisible!} disabled={disabled} onToggle={onClick} />
      </div>
      <MTooltip id={uniqueId} />
    </div>
  );
};

MVisibilityToggleComponent.defaultProps = {
  disabled: false,
  copy: "show",
  isVisible: true,
  disabledTooltip: null,
};

export default MVisibilityToggleComponent;
