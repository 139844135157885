/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  AddUserLinkRequest,
  AddUserLinkRequestFromJSON,
  AddUserLinkRequestToJSON,
  DeleteLinkRequest,
  DeleteLinkRequestFromJSON,
  DeleteLinkRequestToJSON,
  EmptyResponseWrapper,
  EmptyResponseWrapperFromJSON,
  EmptyResponseWrapperToJSON,
  GetUserLinksResponseSerializer,
  GetUserLinksResponseSerializerFromJSON,
  GetUserLinksResponseSerializerToJSON,
  LinkSummarySerializer,
  LinkSummarySerializerFromJSON,
  LinkSummarySerializerToJSON,
  UpdateLinkRequest,
  UpdateLinkRequestFromJSON,
  UpdateLinkRequestToJSON,
} from "../models";

export interface AddLinkRequest {
  data: AddUserLinkRequest;
}

export interface DeleteLinkOperationRequest {
  data: DeleteLinkRequest;
}

export interface UpdateLinkOperationRequest {
  data: UpdateLinkRequest;
}

/**
 *
 */
export class LinksApi extends runtime.BaseAPI {
  /**
   * Add a new link for the given user.
   */
  async addLinkRaw(
    requestParameters: AddLinkRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<LinkSummarySerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling addLink."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/links/add`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: AddUserLinkRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LinkSummarySerializerFromJSON(jsonValue)
    );
  }

  /**
   * Add a new link for the given user.
   */
  async addLink(
    requestParameters: AddLinkRequest,
    initOverrides?: RequestInit
  ): Promise<LinkSummarySerializer> {
    const response = await this.addLinkRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete the referenced link.
   */
  async deleteLinkRaw(
    requestParameters: DeleteLinkOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling deleteLink."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/links/delete`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: DeleteLinkRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Delete the referenced link.
   */
  async deleteLink(
    requestParameters: DeleteLinkOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.deleteLinkRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get a list of links for the given user.
   */
  async listLinksRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetUserLinksResponseSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/links/list`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetUserLinksResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get a list of links for the given user.
   */
  async listLinks(
    initOverrides?: RequestInit
  ): Promise<GetUserLinksResponseSerializer> {
    const response = await this.listLinksRaw(initOverrides);
    return await response.value();
  }

  /**
   * Update the referenced link with the given data.
   */
  async updateLinkRaw(
    requestParameters: UpdateLinkOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling updateLink."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/links/update`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateLinkRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Update the referenced link with the given data.
   */
  async updateLink(
    requestParameters: UpdateLinkOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.updateLinkRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
