/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ProcessAssessmentRequest
 */
export interface ProcessAssessmentRequest {
  /**
   *
   * @type {string}
   * @memberof ProcessAssessmentRequest
   */
  assessment: string;
}

export function ProcessAssessmentRequestFromJSON(
  json: any
): ProcessAssessmentRequest {
  return ProcessAssessmentRequestFromJSONTyped(json, false);
}

export function ProcessAssessmentRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProcessAssessmentRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    assessment: json["assessment"],
  };
}

export function ProcessAssessmentRequestToJSON(
  value?: ProcessAssessmentRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    assessment: value.assessment,
  };
}
