import {
  PromptRelatedDetail,
  SearchValidTagsResponse,
} from "../../../../api/models";

export type CommonEditQuestionProps = {
  onBackClicked?: (() => void) | null;
  errors?: string[];
  errorMap?: { [key: string]: string[] };
  onTagSearchTermUpdated: (newTerm: string) => void;
  tagsSearching: boolean;
  tags: SearchValidTagsResponse | null;
  prompt?: PromptRelatedDetail | null;
  isCreate?: boolean;
};

export const maxQuestionLength = 100;
export const maxStatementLength = 100;
export const maxPlaceholderLength = 250;
export const minResponseLength = 100;
export const maxResponseLength = 2000;
export const maxEitherOrChoices = 10;
export const maxEitherOrChoiceLength = 25;
export const maxMultipleChoiceChoices = 10;
export const maxMultipleChoiceChoiceLength = 100;
