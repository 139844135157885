import React, { ReactElement } from "react";
import { PromptWithResponse } from "../../../api/models";
import PromptDisplayCard from "../../ui/cards/prompt_display/PromptDisplayCard";
import { classNames } from "../../../util/strings";
import MDivider from "../../ui/MDivider";
import { useBusyWatcher } from "../../../util/hooks";
import MEditButton from "../../ui/buttons/MEditButton";
import MPlusButton from "../../ui/buttons/MPlusButton";
import { commentText } from "../../../util/style";

type PromptResponsesSectionProps = {
  promptResponses: PromptWithResponse[];
  className?: string;
  editUrl?: string | null;
  title?: string;
  bgColor?: string | null;
};

const PromptResponsesSectionComponent = (
  props: PromptResponsesSectionProps
) => {
  const { promptResponses, className, editUrl, title, bgColor } = props;

  const [busy, _] = useBusyWatcher();

  const getAddButton = (): ReactElement => {
    if (promptResponses.length === 0) {
      return <MPlusButton href={editUrl!} disabled={busy} text={null} />;
    }
    return <MEditButton href={editUrl!} disabled={busy} text={null} />;
  };
  return (
    <div className={classNames(className, "flex flex-col gap-3")}>
      <MDivider
        title={title}
        bgColor={bgColor}
        button={editUrl ? getAddButton() : null}
      />
      {promptResponses.map(
        (pr) =>
          pr.prompt && (
            <PromptDisplayCard key={pr.prompt.guid} prompt={pr} collapsible />
          )
      )}
      {promptResponses.length === 0 && (
        <div className={classNames(commentText, "my-3")}>
          you haven't answered any questions yet
        </div>
      )}
    </div>
  );
};

PromptResponsesSectionComponent.defaultProps = {
  className: undefined,
  editUrl: null,
  title: "questions",
  bgColor: null,
};

export default PromptResponsesSectionComponent;
