/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UserConnectionRequestDetail
 */
export interface UserConnectionRequestDetail {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof UserConnectionRequestDetail
   */
  readonly guid?: string;
  /**
   * The time at which this record was created.
   * @type {Date}
   * @memberof UserConnectionRequestDetail
   */
  readonly time_created?: Date;
}

export function UserConnectionRequestDetailFromJSON(
  json: any
): UserConnectionRequestDetail {
  return UserConnectionRequestDetailFromJSONTyped(json, false);
}

export function UserConnectionRequestDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserConnectionRequestDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    time_created: !exists(json, "time_created")
      ? undefined
      : new Date(json["time_created"]),
  };
}

export function UserConnectionRequestDetailToJSON(
  value?: UserConnectionRequestDetail | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {};
}
