import React, { ReactElement, useMemo } from "react";
import { UserProfileSummary } from "../../../api/models";
import MAvatar from "../MAvatar";
import MCornerEditButton from "../buttons/MCornerEditButton";
import { classNames } from "../../../util/strings";

type ProfileCardProps = {
  user: UserProfileSummary;
  editPictureUrl?: string | null;
  editBasicsUrl?: string | null;
  button?: ReactElement | null;
  disabled?: boolean;
  raised?: boolean;
  onClick?: (() => void) | null;
  asAnchor?: boolean;
  className?: string;
  separateWindow?: boolean;
};

const ProfileCardComponent = (props: ProfileCardProps) => {
  const {
    user,
    editPictureUrl,
    editBasicsUrl,
    button,
    disabled,
    raised,
    onClick: onClickInput,
    asAnchor,
    className,
    separateWindow,
  } = props;

  const getTagline = (): string | null => {
    if (!user.current_employer && !user.current_title) {
      return null;
    }
    if (user.current_employer && user.current_title) {
      return `${user.current_title} @ ${user.current_employer}`;
    }
    if (user.current_employer) {
      return `@ ${user.current_employer}`;
    }
    return user.current_title!;
  };

  const tagLine = useMemo(
    () => getTagline(),
    [user.current_employer, user.current_title]
  );

  const clickable = Boolean(onClickInput || asAnchor);

  const onClick = (e: React.MouseEvent): void => {
    if (disabled) {
      e.preventDefault();
      return;
    }
    if (onClickInput) {
      onClickInput();
    }
  };

  const content = (
    <div
      className={classNames(
        "relative rounded-lg bg-m-white flex flex-row items-center w-full p-0.5",
        clickable && !disabled
          ? "cursor-pointer hover:shadow-blue-600 hover:bg-primary-light"
          : null,
        clickable && disabled ? "cursor-not-allowed" : null,
        !clickable ? "cursor-auto" : null,
        raised ? "shadow shadow-gray-400 border-2 border-secondary" : null,
        !asAnchor && !onClickInput ? className : null
      )}
    >
      <MAvatar
        picUrl={user.photo}
        alt={user.name || user.username || "user picture"}
        editUrl={editPictureUrl}
      />
      <div className="grow flex flex-col h-full justify-center py-2 px-3 text-left">
        <div>
          <span className="font-bold text-m-black">{user.name}</span>
          {user.pronouns && (
            <span className="text-sm text-m-dark-gray"> ({user.pronouns})</span>
          )}
        </div>
        <div className="text-sm text-m-dark-gray">@{user.username}</div>
        {tagLine && (
          <>
            <div className="grow" />
            <div className="text-sm text-m-dark-gray">{tagLine}</div>
          </>
        )}
      </div>
      {button && <div className="mr-5">{button}</div>}
      {editBasicsUrl && (
        <MCornerEditButton editUrl={editBasicsUrl} tooltip="edit basic info" />
      )}
    </div>
  );

  if (asAnchor) {
    return (
      <a
        href={`/@${user.username}`}
        target={separateWindow ? "_blank" : "_self"}
        onClick={onClick}
        rel="noreferrer"
        className={className}
      >
        {content}
      </a>
    );
  }

  if (onClickInput) {
    return (
      <button
        type="button"
        className={className}
        onClick={onClick}
        disabled={disabled}
      >
        {content}
      </button>
    );
  }

  return content;
};

ProfileCardComponent.defaultProps = {
  editPictureUrl: null,
  editBasicsUrl: null,
  button: null,
  disabled: false,
  raised: true,
  onClick: null,
  asAnchor: false,
  className: undefined,
  separateWindow: true,
};

export default ProfileCardComponent;
