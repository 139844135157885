/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  EmptyResponseWrapper,
  EmptyResponseWrapperFromJSON,
  EmptyResponseWrapperToJSON,
  GetEventType,
  GetEventTypeFromJSON,
  GetEventTypeToJSON,
  GetEventTypeResponseSerializer,
  GetEventTypeResponseSerializerFromJSON,
  GetEventTypeResponseSerializerToJSON,
} from "../models";

export interface GetEventTypesRequest {
  data: GetEventType;
}

/**
 *
 */
export class EventsApi extends runtime.BaseAPI {
  /**
   * Update our database records to reflect that the requesting user has performed the referenced action.
   */
  async getEventTypesRaw(
    requestParameters: GetEventTypesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetEventTypeResponseSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling getEventTypes."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/events/get`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: GetEventTypeToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetEventTypeResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Update our database records to reflect that the requesting user has performed the referenced action.
   */
  async getEventTypes(
    requestParameters: GetEventTypesRequest,
    initOverrides?: RequestInit
  ): Promise<GetEventTypeResponseSerializer> {
    const response = await this.getEventTypesRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
