import React, { ReactElement } from "react";
import { EmployerRelatedDetail } from "../../../api/models";
import MDivider from "../../ui/MDivider";
import { useBusyWatcher } from "../../../util/hooks";
import EmployerSummary from "./work_history/EmployerSummary";
import { commentText } from "../../../util/style";
import MPlusButton from "../../ui/buttons/MPlusButton";
import MEditButton from "../../ui/buttons/MEditButton";
import { classNames } from "../../../util/strings";

type WorkHistorySectionProps = {
  workHistory: EmployerRelatedDetail[];
  title?: string;
  editUrl?: string | null;
  className?: string | null;
  bgColor?: string | null;
};

const WorkHistorySectionComponent = (props: WorkHistorySectionProps) => {
  const { workHistory, className, editUrl, title, bgColor } = props;

  const [busy, _] = useBusyWatcher();

  const getAddButton = (): ReactElement => {
    if (workHistory.length === 0) {
      return <MPlusButton href={editUrl!} disabled={busy} text={null} />;
    }
    return <MEditButton href={editUrl!} disabled={busy} text={null} />;
  };

  return (
    <div className={classNames("flex flex-col gap-2", className)}>
      <MDivider
        bgColor={bgColor}
        title={title}
        button={editUrl ? getAddButton() : null}
      />
      {workHistory.map((employer) => (
        <EmployerSummary employer={employer} key={employer.guid} collapsible />
      ))}
      {workHistory.length === 0 && (
        <div className={classNames(commentText, "my-3")}>
          you haven't added any work history to your profile yet
        </div>
      )}
    </div>
  );
};

WorkHistorySectionComponent.defaultProps = {
  editUrl: null,
  className: null,
  title: "work history",
  bgColor: null,
};

export default WorkHistorySectionComponent;
