/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  UserProfileSummary,
  UserProfileSummaryFromJSON,
  UserProfileSummaryFromJSONTyped,
  UserProfileSummaryToJSON,
} from "./UserProfileSummary";

/**
 *
 * @export
 * @interface SingleUserQuestionInsightRelatedDetail
 */
export interface SingleUserQuestionInsightRelatedDetail {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof SingleUserQuestionInsightRelatedDetail
   */
  readonly guid?: string;
  /**
   * The task ID for the Celery task that was enqueued to ask the question.
   * @type {string}
   * @memberof SingleUserQuestionInsightRelatedDetail
   */
  task_id?: string | null;
  /**
   * Whether or not the referenced Celery task has been completed.
   * @type {boolean}
   * @memberof SingleUserQuestionInsightRelatedDetail
   */
  is_task_completed?: boolean;
  /**
   * The contents of the question being asked.
   * @type {string}
   * @memberof SingleUserQuestionInsightRelatedDetail
   */
  question?: string | null;
  /**
   * The time at which this record was created.
   * @type {Date}
   * @memberof SingleUserQuestionInsightRelatedDetail
   */
  readonly time_created?: Date;
  /**
   *
   * @type {UserProfileSummary}
   * @memberof SingleUserQuestionInsightRelatedDetail
   */
  asking_user?: UserProfileSummary;
  /**
   *
   * @type {UserProfileSummary}
   * @memberof SingleUserQuestionInsightRelatedDetail
   */
  about_user?: UserProfileSummary;
}

export function SingleUserQuestionInsightRelatedDetailFromJSON(
  json: any
): SingleUserQuestionInsightRelatedDetail {
  return SingleUserQuestionInsightRelatedDetailFromJSONTyped(json, false);
}

export function SingleUserQuestionInsightRelatedDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SingleUserQuestionInsightRelatedDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    task_id: !exists(json, "task_id") ? undefined : json["task_id"],
    is_task_completed: !exists(json, "is_task_completed")
      ? undefined
      : json["is_task_completed"],
    question: !exists(json, "question") ? undefined : json["question"],
    time_created: !exists(json, "time_created")
      ? undefined
      : new Date(json["time_created"]),
    asking_user: !exists(json, "asking_user")
      ? undefined
      : UserProfileSummaryFromJSON(json["asking_user"]),
    about_user: !exists(json, "about_user")
      ? undefined
      : UserProfileSummaryFromJSON(json["about_user"]),
  };
}

export function SingleUserQuestionInsightRelatedDetailToJSON(
  value?: SingleUserQuestionInsightRelatedDetail | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    task_id: value.task_id,
    is_task_completed: value.is_task_completed,
    question: value.question,
    asking_user: UserProfileSummaryToJSON(value.asking_user),
    about_user: UserProfileSummaryToJSON(value.about_user),
  };
}
