// The wrapper container that center forms should have
export const middleFormWrapper =
  "flex min-h-full flex-col justify-center px-4 py-12 lg:px-6";

// The inner container that center forms should have
export const middleFormContent = "sm:mx-auto sm:w-full sm:max-w-sm";

// Wrapper for full page content that should fit in the middle of the page, not worrying
// about margins underneath the nav bar
export const middleColumnWrapperNoTopMargin =
  "flex min-h-full flex-col flex-1 justify-center px-4 lg:px-6 pb-6";

export const standardGutters = "mx-auto max-w-7xl px-2 sm:px-4 lg:px-8";

export const middleColumnWrapper = `${middleColumnWrapperNoTopMargin} pt-6 sm:pt-0`;

export const middleColumnContent = "sm:mx-auto sm:w-full sm:max-w-lg";

export const maxWidth = "max-w-7xl";

export const titleTextSize = "text-2xl font-bold leading-9 tracking-tight";

export const titleText = `${titleTextSize} text-m-black`;

export const subtitleTextSize = "text-lg font-bold leading-7";

export const subtitleText = `${subtitleTextSize} text-m-black`;

export const paragraphText = "text-m-black";

export const commentText = "text-m-dark-gray italic";

export const labelText = "block text-sm font-medium leading-3 text-m-dark-gray";

export const clickableText =
  "text-primary hover:text-primary/80 hover:underline cursor-pointer not-italic";
