/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  PromptResponseRelatedDetail,
  PromptResponseRelatedDetailFromJSON,
  PromptResponseRelatedDetailFromJSONTyped,
  PromptResponseRelatedDetailToJSON,
} from "./PromptResponseRelatedDetail";

/**
 *
 * @export
 * @interface PromptResponsePresentationRelatedDetail
 */
export interface PromptResponsePresentationRelatedDetail {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof PromptResponsePresentationRelatedDetail
   */
  readonly guid?: string;
  /**
   * Whether this PromptResponse should be visible in the presentation layer.
   * @type {boolean}
   * @memberof PromptResponsePresentationRelatedDetail
   */
  is_visible?: boolean;
  /**
   * The order in which this PromptResponse should be displayed in the presentation layer.
   * @type {number}
   * @memberof PromptResponsePresentationRelatedDetail
   */
  order?: number | null;
  /**
   *
   * @type {PromptResponseRelatedDetail}
   * @memberof PromptResponsePresentationRelatedDetail
   */
  prompt_response: PromptResponseRelatedDetail;
}

export function PromptResponsePresentationRelatedDetailFromJSON(
  json: any
): PromptResponsePresentationRelatedDetail {
  return PromptResponsePresentationRelatedDetailFromJSONTyped(json, false);
}

export function PromptResponsePresentationRelatedDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PromptResponsePresentationRelatedDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    is_visible: !exists(json, "is_visible") ? undefined : json["is_visible"],
    order: !exists(json, "order") ? undefined : json["order"],
    prompt_response: PromptResponseRelatedDetailFromJSON(
      json["prompt_response"]
    ),
  };
}

export function PromptResponsePresentationRelatedDetailToJSON(
  value?: PromptResponsePresentationRelatedDetail | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    is_visible: value.is_visible,
    order: value.order,
    prompt_response: PromptResponseRelatedDetailToJSON(value.prompt_response),
  };
}
