import connector, { ManualAPIConnector } from "../api/connector";
import { PromptWithResponse } from "../api/models";

const waitForResponseDataAvailable = async (
  guid: string,
  c: ManualAPIConnector | null = null,
  timeout = 180_000,
  checkInterval = 1000
): Promise<PromptWithResponse[]> => {
  const innerConnector = c || connector;
  const maxLoops = timeout / checkInterval;
  for (let i = 0; i < maxLoops; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    const response = await innerConnector.getGroupQuestionAndAnswer({
      group_qna_guid: guid,
    });
    const responseData = response.c!.relevant_prompt_responses;
    if (responseData && responseData.length > 0) {
      return responseData;
    }
    // eslint-disable-next-line no-await-in-loop
    await new Promise((resolve) => {
      setTimeout(resolve, checkInterval);
    });
  }
  return [];
};

export default waitForResponseDataAvailable;
