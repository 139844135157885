import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { MNavProps } from "../../../ui/nav/MNav";
import { useBackTimer, useBusyWatcher } from "../../../../util/hooks";
import { useWrappedConnector } from "../../../../api/connector";
import {
  Attachments,
  GlimpseAttachmentVisibilityDetail,
  GlimpseDetail,
  GlimpseLimits,
  ListLinksForGlimpseResponse,
  ListPromptResponsesForGlimpseResponse,
  SetLinksForGlimpseRequest,
  SetPromptResponsesForGlimpseRequest,
  UpdateGlimpseContentRequest,
  UpdateGlimpseTitleRequest,
} from "../../../../api/models";
import WizardScreen, {
  WizardScreenPanel,
} from "../../../ui/screens/WizardScreen";
import LoadingScreen from "../../../ui/screens/LoadingScreen";
import BaseNavbarPage from "../../BaseNavbarPage";
import PickGlimpseEditTypeScreen from "./PickGlimpseEditTypeScreen";
import { errorMessagesFromResponse } from "../../../../api/helpers";
import UpdateGlimpseTitleScreen from "./UpdateGlimpseTitleScreen";
import PickGlimpseLinksScreen from "./PickGlimpseLinksScreen";
import PickGlimpseQuestionsScreen from "./PickGlimpseQuestionsScreen";
import ReviewGlimpseContentScreen from "./ReviewGlimpseContentScreen";
import ViewGlimpseAudienceScreen from "./ViewGlimpseAudienceScreen";
import UpdateGlimpseAttachmentsScreen from "./UpdateGlimpseAttachmentsScreen";

type EditGlimpsePageProps = {
  nextUrl: string;
  navbarProps: MNavProps;
  glimpseId: string;
};

enum EditGlimpseStage {
  LOADING,
  PICK_EDIT_TYPE,
  EDIT_TITLE,
  VIEW_CONTEXT,
  EDIT_CONTENT,
  EDIT_QUESTIONS,
  EDIT_LINKS,
  EDIT_ATTACHMENTS,
}

const EditGlimpsePageComponent = (props: EditGlimpsePageProps) => {
  const { nextUrl, navbarProps, glimpseId } = props;

  const [stage, setStage] = useState<EditGlimpseStage>(
    EditGlimpseStage.LOADING
  );
  const [glimpse, setGlimpse] = useState<GlimpseDetail | null>(null);
  const [limits, setLimits] = useState<GlimpseLimits | null>(null);
  const [links, setLinks] = useState<ListLinksForGlimpseResponse | null>(null);
  const [questions, setQuestions] =
    useState<ListPromptResponsesForGlimpseResponse | null>(null);
  const [attachments, setAttachments] = useState<Attachments | null>(null);
  const [attachmentVisibility, setAttachmentVisibility] =
    useState<GlimpseAttachmentVisibilityDetail | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [isBack, asBack] = useBackTimer(stage);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);

  const loadAttachments = async () => {
    const response = await connector.getAttachments();
    setAttachments(response.c!);
  };

  const loadAttachmentVisibility = async () => {
    const response = await connector.getGlimpseAttachmentVisibility(glimpseId);
    setAttachmentVisibility(response.c!);
  };

  const loadAttachmentPageContent = async () => {
    const promises = [loadAttachments(), loadAttachmentVisibility()];
    await Promise.all(promises);
  };

  const loadGlimpse = async () => {
    const response = await connector.getGlimpse(glimpseId);
    setGlimpse(response.c!);
  };

  const loadLimits = async () => {
    const response = await connector.getGlimpseLimits();
    setLimits(response.c!);
  };

  const loadLinks = async () => {
    const response = await connector.listGlimpseLinks(glimpseId);
    setLinks(response.c!);
  };

  const loadQuestions = async () => {
    const response = await connector.listGlimpsePromptResponses(glimpseId);
    setQuestions(response.c!);
  };

  const goToStage = async (nextStage: EditGlimpseStage) => {
    setErrors({});
    // eslint-disable-next-line default-case
    switch (nextStage) {
      case EditGlimpseStage.PICK_EDIT_TYPE: {
        const promises = [loadGlimpse()];
        if (limits === null) {
          promises.push(loadLimits());
        }
        await Promise.all(promises);
        break;
      }
      case EditGlimpseStage.EDIT_LINKS: {
        await loadLinks();
        break;
      }
      case EditGlimpseStage.EDIT_QUESTIONS: {
        await loadQuestions();
        break;
      }
      case EditGlimpseStage.EDIT_ATTACHMENTS: {
        await loadAttachmentPageContent();
        break;
      }
    }
    setStage(nextStage);
  };

  const onToggleVisibilityClicked = async (newVisibility: boolean) => {
    await connector.setGlimpseVisibility({
      glimpse: glimpseId,
      visible: newVisibility,
    });
    await loadGlimpse();
  };

  const onUpdateTitleClicked = async (newTitle: UpdateGlimpseTitleRequest) => {
    setErrors({});
    try {
      await connector.updateGlimpseTitle(newTitle);
    } catch (e: any) {
      const newErrors = await errorMessagesFromResponse(e);
      setErrors(newErrors);
      return;
    }
    toast.success("update successful!");
    await goToStage(EditGlimpseStage.PICK_EDIT_TYPE);
  };

  const onSetLinksClicked = async (newLinks: SetLinksForGlimpseRequest) => {
    setErrors({});
    try {
      await connector.setGlimpseLinks(newLinks);
    } catch (e: any) {
      const newErrors = await errorMessagesFromResponse(e);
      setErrors(newErrors);
      return;
    }
    toast.success("update successful!");
    await goToStage(EditGlimpseStage.PICK_EDIT_TYPE);
  };

  const onSetQuestionsClicked = async (
    newSelected: SetPromptResponsesForGlimpseRequest
  ) => {
    setErrors({});
    try {
      await connector.setGlimpsePromptResponses(newSelected);
    } catch (e: any) {
      const newErrors = await errorMessagesFromResponse(e);
      setErrors(newErrors);
      return;
    }
    toast.success("update successful!");
    await goToStage(EditGlimpseStage.PICK_EDIT_TYPE);
  };

  const onSetContentClicked = async (
    newContent: UpdateGlimpseContentRequest
  ) => {
    setErrors({});
    try {
      await connector.updateGlimpseContent(newContent);
    } catch (e: any) {
      const newErrors = await errorMessagesFromResponse(e);
      setErrors(newErrors);
      return;
    }
    toast.success("update successful!");
    await goToStage(EditGlimpseStage.PICK_EDIT_TYPE);
  };

  const onResumeVisibilityToggled = async (newVisibility: boolean) => {
    await connector.setGlimpseResumeVisibility({
      glimpse: glimpseId,
      visible: newVisibility,
    });
    await loadAttachmentPageContent();
  };

  useEffect(() => {
    goToStage(EditGlimpseStage.PICK_EDIT_TYPE);
  }, []);

  const getElements = (): WizardScreenPanel[] => [
    {
      stage: EditGlimpseStage.LOADING,
      content: <LoadingScreen />,
    },
    {
      stage: EditGlimpseStage.PICK_EDIT_TYPE,
      content: glimpse && limits && (
        <PickGlimpseEditTypeScreen
          onEditTitleClicked={() => goToStage(EditGlimpseStage.EDIT_TITLE)}
          onViewContextClicked={() => goToStage(EditGlimpseStage.VIEW_CONTEXT)}
          onEditContentClicked={() => goToStage(EditGlimpseStage.EDIT_CONTENT)}
          onEditQuestionsClicked={() =>
            goToStage(EditGlimpseStage.EDIT_QUESTIONS)
          }
          onEditLinksClicked={() => goToStage(EditGlimpseStage.EDIT_LINKS)}
          onEditAttachmentsClicked={() =>
            goToStage(EditGlimpseStage.EDIT_ATTACHMENTS)
          }
          onToggleVisibilityClicked={onToggleVisibilityClicked}
          glimpse={glimpse}
          limits={limits}
          onDoneClicked={() => {
            window.location.href = nextUrl;
          }}
        />
      ),
    },
    {
      stage: EditGlimpseStage.EDIT_TITLE,
      content: glimpse && (
        <UpdateGlimpseTitleScreen
          glimpse={glimpse}
          errors={errors}
          onNextClicked={onUpdateTitleClicked}
          onBackClicked={asBack(() =>
            goToStage(EditGlimpseStage.PICK_EDIT_TYPE)
          )}
        />
      ),
    },
    {
      stage: EditGlimpseStage.EDIT_LINKS,
      content: links && (
        <PickGlimpseLinksScreen
          glimpseId={glimpseId}
          selected={links}
          errors={errors.links ?? []}
          onNextClicked={onSetLinksClicked}
          onBackClicked={asBack(() =>
            goToStage(EditGlimpseStage.PICK_EDIT_TYPE)
          )}
        />
      ),
    },
    {
      stage: EditGlimpseStage.EDIT_QUESTIONS,
      content: questions && (
        <PickGlimpseQuestionsScreen
          glimpseId={glimpseId}
          selected={questions}
          errors={errors.prompt_responses ?? []}
          onNextClicked={onSetQuestionsClicked}
          onBackClicked={asBack(() =>
            goToStage(EditGlimpseStage.PICK_EDIT_TYPE)
          )}
        />
      ),
    },
    {
      stage: EditGlimpseStage.EDIT_CONTENT,
      content: glimpse && (
        <ReviewGlimpseContentScreen
          glimpse={glimpse}
          errors={errors.content ?? []}
          onNextClicked={onSetContentClicked}
          onBackClicked={asBack(() =>
            goToStage(EditGlimpseStage.PICK_EDIT_TYPE)
          )}
        />
      ),
    },
    {
      stage: EditGlimpseStage.VIEW_CONTEXT,
      content: glimpse && (
        <ViewGlimpseAudienceScreen
          glimpse={glimpse}
          onBackClicked={asBack(() =>
            goToStage(EditGlimpseStage.PICK_EDIT_TYPE)
          )}
        />
      ),
    },
    {
      stage: EditGlimpseStage.EDIT_ATTACHMENTS,
      content: attachments && attachmentVisibility && (
        <UpdateGlimpseAttachmentsScreen
          attachments={attachments}
          visibility={attachmentVisibility}
          onResumeVisibilityChanged={onResumeVisibilityToggled}
          onBackClicked={asBack(() =>
            goToStage(EditGlimpseStage.PICK_EDIT_TYPE)
          )}
        />
      ),
    },
  ];

  return (
    <BaseNavbarPage navbarProps={navbarProps}>
      <WizardScreen elements={getElements()} stage={stage} isBack={isBack} />
    </BaseNavbarPage>
  );
};

export default EditGlimpsePageComponent;
