import React from "react";
import CommonQuestion, { CommonQuestionProps } from "./CommonQuestion";
import MButton from "../../buttons/MButton";

type ChoicesQuestionProps = CommonQuestionProps & {
  options: string[];
  selected?: number | null;
  disabled?: boolean;
  onSelectedUpdated: (selected: number) => void;
};

const ChoicesQuestionComponent = (props: ChoicesQuestionProps) => {
  const {
    options,
    selected,
    onSelectedUpdated: onSelectedUpdatedInput,
    disabled,
    ...rest
  } = props;

  const onSelectedUpdated = (newSelected: number) => {
    if (newSelected === selected) {
      onSelectedUpdatedInput(-1);
    } else {
      onSelectedUpdatedInput(newSelected);
    }
  };

  return (
    <CommonQuestion comment="pick 1 option below" {...rest}>
      <div className="flex flex-col gap-1">
        {options.map((o, i) => (
          <MButton
            key={o}
            onClick={() => onSelectedUpdated(i)}
            className="w-full rounded-md"
            kind={selected === i ? "primary" : "secondary"}
            disabled={disabled}
          >
            {o}
          </MButton>
        ))}
      </div>
    </CommonQuestion>
  );
};

ChoicesQuestionComponent.defaultProps = {
  selected: null,
  disabled: false,
};

export default ChoicesQuestionComponent;
