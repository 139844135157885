/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  GroupSummary,
  GroupSummaryFromJSON,
  GroupSummaryFromJSONTyped,
  GroupSummaryToJSON,
} from "./GroupSummary";

/**
 *
 * @export
 * @interface ListGroupSummaryResponse
 */
export interface ListGroupSummaryResponse {
  /**
   *
   * @type {Array<GroupSummary>}
   * @memberof ListGroupSummaryResponse
   */
  group_summaries: Array<GroupSummary>;
}

export function ListGroupSummaryResponseFromJSON(
  json: any
): ListGroupSummaryResponse {
  return ListGroupSummaryResponseFromJSONTyped(json, false);
}

export function ListGroupSummaryResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ListGroupSummaryResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    group_summaries: (json["group_summaries"] as Array<any>).map(
      GroupSummaryFromJSON
    ),
  };
}

export function ListGroupSummaryResponseToJSON(
  value?: ListGroupSummaryResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    group_summaries: (value.group_summaries as Array<any>).map(
      GroupSummaryToJSON
    ),
  };
}
