/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SubscriptionTier
 */
export interface SubscriptionTier {
  /**
   *
   * @type {string}
   * @memberof SubscriptionTier
   */
  tier: SubscriptionTierTierEnum;
  /**
   *
   * @type {string}
   * @memberof SubscriptionTier
   */
  period: SubscriptionTierPeriodEnum;
  /**
   *
   * @type {string}
   * @memberof SubscriptionTier
   */
  price_id: string;
  /**
   *
   * @type {number}
   * @memberof SubscriptionTier
   */
  price: number;
  /**
   *
   * @type {object}
   * @memberof SubscriptionTier
   */
  features?: object | null;
  /**
   *
   * @type {string}
   * @memberof SubscriptionTier
   */
  description?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum SubscriptionTierTierEnum {
  essentials = "essentials",
  infinity = "infinity",
}
/**
 * @export
 * @enum {string}
 */
export enum SubscriptionTierPeriodEnum {
  monthly = "monthly",
  annual = "annual",
}

export function SubscriptionTierFromJSON(json: any): SubscriptionTier {
  return SubscriptionTierFromJSONTyped(json, false);
}

export function SubscriptionTierFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SubscriptionTier {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tier: json["tier"],
    period: json["period"],
    price_id: json["price_id"],
    price: json["price"],
    features: !exists(json, "features") ? undefined : json["features"],
    description: !exists(json, "description") ? undefined : json["description"],
  };
}

export function SubscriptionTierToJSON(value?: SubscriptionTier | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    tier: value.tier,
    period: value.period,
    price_id: value.price_id,
    price: value.price,
    features: value.features,
    description: value.description,
  };
}
