import React, { useEffect, useState } from "react";
import { GlimpseSummary, ListGlimpsesResponse } from "../../../../api/models";
import { MNavProps } from "../../../ui/nav/MNav";
import { useBusyWatcher } from "../../../../util/hooks";
import { useWrappedConnector } from "../../../../api/connector";
import ListGlimpsesScreen from "./ListGlimpsesScreen";

type ListGlimpsesPageProps = {
  viewGlimpseUrl: string;
  createGlimpseUrl: string;
  editGlimpseUrl: string;
  navbarProps: MNavProps;
};

const ListGlimpsesPageComponent = (props: ListGlimpsesPageProps) => {
  const { viewGlimpseUrl, createGlimpseUrl, editGlimpseUrl, navbarProps } =
    props;

  const [glimpses, setGlimpses] = useState<ListGlimpsesResponse | null>(null);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);

  const loadGlimpses = async () => {
    const response = await connector.listGlimpses();
    setGlimpses(response.c!);
  };

  const onToggleVisibilityClicked = async (toToggle: GlimpseSummary) => {
    await connector.setGlimpseVisibility({
      glimpse: toToggle.guid!,
      visible: !toToggle.visible!,
    });
    await loadGlimpses();
  };

  const onDeleteGlimpse = async (toDelete: GlimpseSummary) => {
    await connector.deleteGlimpse(toDelete.guid!);
    await loadGlimpses();
  };

  useEffect(() => {
    loadGlimpses();
  }, []);

  return (
    <ListGlimpsesScreen
      glimpses={glimpses}
      navbarProps={navbarProps}
      createGlimpseUrl={createGlimpseUrl}
      viewGlimpseUrl={viewGlimpseUrl}
      editGlimpseUrl={editGlimpseUrl}
      onDeleteGlimpse={onDeleteGlimpse}
      onToggleVisibilityClicked={onToggleVisibilityClicked}
    />
  );
};

export default ListGlimpsesPageComponent;
