/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ManualTodo,
  ManualTodoFromJSON,
  ManualTodoFromJSONTyped,
  ManualTodoToJSON,
} from "./ManualTodo";

/**
 *
 * @export
 * @interface GetGlimpseTodosResponse
 */
export interface GetGlimpseTodosResponse {
  /**
   *
   * @type {Array<ManualTodo>}
   * @memberof GetGlimpseTodosResponse
   */
  todos: Array<ManualTodo>;
}

export function GetGlimpseTodosResponseFromJSON(
  json: any
): GetGlimpseTodosResponse {
  return GetGlimpseTodosResponseFromJSONTyped(json, false);
}

export function GetGlimpseTodosResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetGlimpseTodosResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    todos: (json["todos"] as Array<any>).map(ManualTodoFromJSON),
  };
}

export function GetGlimpseTodosResponseToJSON(
  value?: GetGlimpseTodosResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    todos: (value.todos as Array<any>).map(ManualTodoToJSON),
  };
}
