import { Attachments, UserProfileDetail } from "../api/models";

export const userDetailsHasVitals = (toCheck: UserProfileDetail): boolean => {
  if (
    toCheck.location ||
    toCheck.relationship ||
    toCheck.beliefs ||
    toCheck.sexual_orientation ||
    toCheck.ethnicity ||
    toCheck.kids ||
    toCheck.mbti ||
    toCheck.love_language ||
    toCheck.enneagram ||
    toCheck.zodiac ||
    toCheck.human_design ||
    toCheck.location
  ) {
    return true;
  }
  return false;
};

export const hasAnyAttachments = (toCheck: Attachments): boolean => {
  if (toCheck.resume) {
    return true;
  }
  return false;
};
