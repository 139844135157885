/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  PromptRelatedDetail,
  PromptRelatedDetailFromJSON,
  PromptRelatedDetailFromJSONTyped,
  PromptRelatedDetailToJSON,
} from "./PromptRelatedDetail";

/**
 *
 * @export
 * @interface SearchForQuestionsResponse
 */
export interface SearchForQuestionsResponse {
  /**
   *
   * @type {Array<PromptRelatedDetail>}
   * @memberof SearchForQuestionsResponse
   */
  results: Array<PromptRelatedDetail>;
  /**
   *
   * @type {number}
   * @memberof SearchForQuestionsResponse
   */
  count: number;
}

export function SearchForQuestionsResponseFromJSON(
  json: any
): SearchForQuestionsResponse {
  return SearchForQuestionsResponseFromJSONTyped(json, false);
}

export function SearchForQuestionsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SearchForQuestionsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    results: (json["results"] as Array<any>).map(PromptRelatedDetailFromJSON),
    count: json["count"],
  };
}

export function SearchForQuestionsResponseToJSON(
  value?: SearchForQuestionsResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    results: (value.results as Array<any>).map(PromptRelatedDetailToJSON),
    count: value.count,
  };
}
