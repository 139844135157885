import React, { ReactNode } from "react";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { UserInviteRelatedDetail } from "../../../api/models";
import { clickableText } from "../../../util/style";
import MButton from "../../ui/buttons/MButton";
import { useBusyWatcher, useUniqueId } from "../../../util/hooks";
import MTooltip from "../../ui/MTooltip";
import SidebarThreeColumnRow from "../../ui/screens/sidebarnav/SidebarThreeColumnRow";

type SettingsInviteRowProps = {
  invite: UserInviteRelatedDetail;
  onResendClicked: () => void;
};

const SettingsInviteRowComponent = (props: SettingsInviteRowProps) => {
  const { invite, onResendClicked } = props;

  const [busy, _] = useBusyWatcher();
  const uniqueId = useUniqueId();

  const getMiddle = (): ReactNode => {
    if (invite.time_accepted) {
      return (
        <div className="text-xs text-success">
          accepted {invite.time_accepted.toLocaleString()}
        </div>
      );
    }
    if (invite.time_resent) {
      return (
        <div className="text-xs">
          resent {invite.time_resent.toLocaleString()}
        </div>
      );
    }
    return (
      <div className="text-xs">
        sent {invite.time_created!.toLocaleString()}
      </div>
    );
  };

  const getEnd = (): ReactNode => {
    if (invite.time_accepted) {
      return (
        <a className={clickableText} href={invite.user?.profile_url}>
          {`@${invite.user?.username ?? "unknown"}`}
        </a>
      );
    }
    return (
      <MButton
        className="whitespace-pre"
        kind="secondary"
        disabled={busy || !invite.resend_available}
        icon={<PaperAirplaneIcon className="w-5 h-5" />}
        onClick={onResendClicked}
        data-tooltip-id={uniqueId}
        data-tooltip-content={
          !invite.resend_available
            ? "you've resent this invite too recently to resend it again"
            : null
        }
      >
        resend invite
      </MButton>
    );
  };

  return (
    <>
      <SidebarThreeColumnRow
        start={<span>{invite.email}</span>}
        middle={getMiddle()}
        end={getEnd()}
      />
      <MTooltip id={uniqueId} />
    </>
  );
};

export default SettingsInviteRowComponent;
