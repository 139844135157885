import React from "react";
import FormScreen from "../../../ui/screens/FormScreen";
import { useBusyWatcher } from "../../../../util/hooks";
import MButton from "../../../ui/buttons/MButton";
import { UserGroup } from "../../../../api/models";

type PickGroupEditTypeScreenProps = {
  group: UserGroup;
  onEditGroupClicked: () => void;
  onEditMembersClicked: () => void;
  onDoneClicked: () => void;
};

const PickGroupEditTypeScreenComponent = (
  props: PickGroupEditTypeScreenProps
) => {
  const { group, onEditGroupClicked, onEditMembersClicked, onDoneClicked } =
    props;

  const [busy, _] = useBusyWatcher();

  return (
    <FormScreen
      title={`edit group '${group.name ?? "untitled"}'`}
      onNextClicked={onDoneClicked}
      nextDisabled={busy}
      nextText="done"
      fullWidth
    >
      <MButton disabled={busy} onClick={onEditGroupClicked}>
        edit title + description
      </MButton>
      <MButton disabled={busy} onClick={onEditMembersClicked}>
        edit group members
      </MButton>
    </FormScreen>
  );
};

export default PickGroupEditTypeScreenComponent;
