/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  BothUsersConnectionRequestRelatedDetail,
  BothUsersConnectionRequestRelatedDetailFromJSON,
  BothUsersConnectionRequestRelatedDetailFromJSONTyped,
  BothUsersConnectionRequestRelatedDetailToJSON,
} from "./BothUsersConnectionRequestRelatedDetail";

/**
 *
 * @export
 * @interface ListPendingSentConnectionRequestsResponse
 */
export interface ListPendingSentConnectionRequestsResponse {
  /**
   *
   * @type {Array<BothUsersConnectionRequestRelatedDetail>}
   * @memberof ListPendingSentConnectionRequestsResponse
   */
  requests: Array<BothUsersConnectionRequestRelatedDetail>;
  /**
   *
   * @type {number}
   * @memberof ListPendingSentConnectionRequestsResponse
   */
  count: number;
}

export function ListPendingSentConnectionRequestsResponseFromJSON(
  json: any
): ListPendingSentConnectionRequestsResponse {
  return ListPendingSentConnectionRequestsResponseFromJSONTyped(json, false);
}

export function ListPendingSentConnectionRequestsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ListPendingSentConnectionRequestsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    requests: (json["requests"] as Array<any>).map(
      BothUsersConnectionRequestRelatedDetailFromJSON
    ),
    count: json["count"],
  };
}

export function ListPendingSentConnectionRequestsResponseToJSON(
  value?: ListPendingSentConnectionRequestsResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    requests: (value.requests as Array<any>).map(
      BothUsersConnectionRequestRelatedDetailToJSON
    ),
    count: value.count,
  };
}
