import React, { useEffect, useState } from "react";
import * as EmailValidator from "email-validator";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { ContactUserViaGlimpseRequest } from "../../../../api/models";
import BaseDialog from "../../../dialogs/BaseDialog";
import MInput from "../../../ui/MInput";
import { useBusyWatcher } from "../../../../util/hooks";
import MErrors from "../../../ui/MErrors";
import MTextArea from "../../../ui/MTextArea";
import MButton from "../../../ui/buttons/MButton";
import { classNames } from "../../../../util/strings";
import MNextButton from "../../../ui/buttons/MNextButton";

type GlimpseContactDialogProps = {
  show: boolean;
  glimpseId: string;
  onClose: () => void;
  onSend: (toSend: ContactUserViaGlimpseRequest) => void;
  recipientName: string;
  isAuthenticated: boolean;
  errors: { [key: string]: string[] };
  errorList: string[];
  success: boolean;
};

const GlimpseContactDialogComponent = (props: GlimpseContactDialogProps) => {
  const {
    show,
    glimpseId,
    onClose,
    onSend,
    recipientName,
    isAuthenticated,
    errors,
    errorList,
    success,
  } = props;

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [busy, _] = useBusyWatcher();

  const canSubmit = (): boolean => {
    if (!isAuthenticated && name === "") {
      return false;
    }
    if (name.length > 64) {
      return false;
    }
    if (email.length > 0 && !EmailValidator.validate(email)) {
      return false;
    }
    if (email.length > 128) {
      return false;
    }
    if (phone.length > 32) {
      return false;
    }
    if (message.length === 0) {
      return false;
    }
    if (message.length > 512) {
      return false;
    }
    if (email.length === 0 && phone.length === 0) {
      return false;
    }
    return true;
  };

  const getNameErrors = (): string[] => {
    const toReturn: string[] = [];
    if (errors.name) {
      toReturn.push(...errors.name);
    }
    if (name.length > 64) {
      toReturn.push("name must be less than 64 characters");
    }
    return toReturn;
  };

  const getEmailErrors = (): string[] => {
    const toReturn: string[] = [];
    if (errors.email) {
      toReturn.push(...errors.email);
    }
    if (email.length > 0 && !EmailValidator.validate(email)) {
      toReturn.push("email is invalid");
    }
    if (email.length > 128) {
      toReturn.push("email must be less than 128 characters");
    }
    return toReturn;
  };

  const getPhoneErrors = (): string[] => {
    const toReturn: string[] = [];
    if (errors.phone) {
      toReturn.push(...errors.phone);
    }
    if (phone.length > 32) {
      toReturn.push("phone must be less than 32 characters");
    }
    return toReturn;
  };

  const getMessageErrors = (): string[] => {
    const toReturn: string[] = [];
    if (errors.message) {
      toReturn.push(...errors.message);
    }
    if (message.length > 512) {
      toReturn.push("message must be less than 2048 characters");
    }
    return toReturn;
  };

  const submit = () => {
    onSend({
      glimpse: glimpseId,
      name: name.length > 0 ? name : null,
      email: email.length > 0 ? email : null,
      phone: phone.length > 0 ? phone : null,
      message,
    });
  };

  useEffect(() => {
    if (show) {
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
    }
  }, [show]);

  return (
    <BaseDialog
      title={`contact ${recipientName}`}
      show={show}
      onClose={onClose}
      className="max-w-xl"
    >
      {success ? (
        <>
          <div>
            your information was submitted successfully! {recipientName} should
            be in touch soon.
          </div>
          <div className="flex flex-row items-center justify-end">
            <MNextButton kind="primary" onClick={onClose} text="done" />
          </div>
        </>
      ) : (
        <>
          <div className="text-xs text-left">
            provide your information below and we'll share it with{" "}
            {recipientName}. make sure to include at least an email address or
            phone number for them to contact you back.
          </div>
          <div
            className={classNames(
              "text-left",
              errorList.length > 0 ? null : "hidden"
            )}
          >
            <MErrors errors={errorList} />
          </div>
          {!isAuthenticated && (
            <MInput
              label="name"
              type="text"
              placeholder="your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={busy}
              onEnterPressed={() => canSubmit() && submit()}
              errors={getNameErrors()}
              required
            />
          )}
          <MInput
            label="email"
            type="email"
            placeholder="your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={busy}
            onEnterPressed={() => canSubmit() && submit()}
            errors={getEmailErrors()}
          />
          <MInput
            label="phone"
            type="text"
            placeholder="your phone number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            disabled={busy}
            onEnterPressed={() => canSubmit() && submit()}
            errors={getPhoneErrors()}
          />
          <MTextArea
            label="message"
            placeholder={`your message to ${recipientName}`}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            disabled={busy}
            errors={getMessageErrors()}
            required
          />
          <div className="flex flex-row items-center justify-between">
            <MButton kind="secondary" onClick={onClose}>
              cancel
            </MButton>
            <MButton
              kind="primary"
              onClick={submit}
              disabled={busy || !canSubmit()}
              icon={<PaperAirplaneIcon className="h-6 w-6" />}
            >
              send
            </MButton>
          </div>
        </>
      )}
    </BaseDialog>
  );
};

export default GlimpseContactDialogComponent;
