import React, { PropsWithChildren, ReactNode } from "react";
import { PromptWithResponse } from "../../../../api/models";
import { classNames } from "../../../../util/strings";
import { standardGutters } from "../../../../util/style";
import MTitle from "../../../ui/MTitle";
import MErrors from "../../../ui/MErrors";
import MCard from "../../../ui/cards/MCard";
import PromptDisplayCard from "../../../ui/cards/prompt_display/PromptDisplayCard";
import MBackButton from "../../../ui/buttons/MBackButton";
import MNextButton from "../../../ui/buttons/MNextButton";

type CommonEditQuestionScreenProps = {
  errors?: string[];
  title: string;
  previewQuestion: ReactNode;
  previewResponse: PromptWithResponse;
  onNextClicked: () => void;
  nextDisabled: boolean;
  onBackClicked?: (() => void) | null;
  backDisabled: boolean;
};

const CommonEditQuestionScreenComponent = (
  props: PropsWithChildren<CommonEditQuestionScreenProps>
) => {
  const {
    errors,
    title,
    previewQuestion,
    previewResponse,
    onNextClicked,
    nextDisabled,
    onBackClicked,
    backDisabled,
    children,
  } = props;

  return (
    <div className={classNames("flex flex-col gap-5", standardGutters)}>
      <MTitle text={title} />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div className="flex flex-col gap-3">
          <MErrors errors={errors} />
          {children}
        </div>

        <div className="flex flex-col gap-2">
          <div>question preview:</div>
          <MCard>{previewQuestion}</MCard>
          <div className="mt-4">response preview:</div>
          <PromptDisplayCard prompt={previewResponse} />
        </div>
      </div>
      <div className="w-full flex flex-row justify-start gap-3">
        {onBackClicked ? (
          <MBackButton onClick={onBackClicked} disabled={backDisabled} />
        ) : null}
        <MNextButton
          onClick={onNextClicked}
          disabled={nextDisabled}
          kind="primary"
        />
      </div>
    </div>
  );
};

CommonEditQuestionScreenComponent.defaultProps = {
  errors: [],
  onBackClicked: null,
};

export default CommonEditQuestionScreenComponent;
