import React from "react";
import Skeleton from "react-loading-skeleton";

const SidebarLoadingRowComponent = () => (
  <div className="pt-4 sm:flex">
    <Skeleton className="w-full min-w-64 h-8" />
  </div>
);

export default SidebarLoadingRowComponent;
