/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  GroupQuestionAndAnswer,
  GroupQuestionAndAnswerFromJSON,
  GroupQuestionAndAnswerFromJSONTyped,
  GroupQuestionAndAnswerToJSON,
} from "./GroupQuestionAndAnswer";
import {
  PromptWithResponse,
  PromptWithResponseFromJSON,
  PromptWithResponseFromJSONTyped,
  PromptWithResponseToJSON,
} from "./PromptWithResponse";

/**
 *
 * @export
 * @interface GroupQuestionAndAnswerResponse
 */
export interface GroupQuestionAndAnswerResponse {
  /**
   *
   * @type {GroupQuestionAndAnswer}
   * @memberof GroupQuestionAndAnswerResponse
   */
  group_qna: GroupQuestionAndAnswer;
  /**
   *
   * @type {Array<PromptWithResponse>}
   * @memberof GroupQuestionAndAnswerResponse
   */
  relevant_prompt_responses?: Array<PromptWithResponse>;
}

export function GroupQuestionAndAnswerResponseFromJSON(
  json: any
): GroupQuestionAndAnswerResponse {
  return GroupQuestionAndAnswerResponseFromJSONTyped(json, false);
}

export function GroupQuestionAndAnswerResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GroupQuestionAndAnswerResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    group_qna: GroupQuestionAndAnswerFromJSON(json["group_qna"]),
    relevant_prompt_responses: !exists(json, "relevant_prompt_responses")
      ? undefined
      : (json["relevant_prompt_responses"] as Array<any>).map(
          PromptWithResponseFromJSON
        ),
  };
}

export function GroupQuestionAndAnswerResponseToJSON(
  value?: GroupQuestionAndAnswerResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    group_qna: GroupQuestionAndAnswerToJSON(value.group_qna),
    relevant_prompt_responses:
      value.relevant_prompt_responses === undefined
        ? undefined
        : (value.relevant_prompt_responses as Array<any>).map(
            PromptWithResponseToJSON
          ),
  };
}
