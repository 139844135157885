import { XCircleIcon } from "@heroicons/react/20/solid";
import React from "react";
import { CommonWellButtonProps } from "./common";
import MWellButton from "./MWellButton";

const MWellCancelButtonComponent = (props: CommonWellButtonProps) => (
  <MWellButton {...props} icon={<XCircleIcon />} />
);

MWellCancelButtonComponent.defaultProps = {
  disabled: false,
};

export default MWellCancelButtonComponent;
