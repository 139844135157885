import React from "react";
import FormScreen from "../../ui/screens/FormScreen";
import { useBusyWatcher } from "../../../util/hooks";
import MVisibilityToggle from "../../ui/MVisibilityToggle";
import MTooltipIcon from "../../ui/MTooltipIcon";

type OnboardingSettingsScreenProps = {
  onNextClicked: () => void;
  setProfilePublicStatus: (publicStatus: boolean) => void;
  isPublic: boolean;
  setMarketingOptIn: (marketingOptIn: boolean) => void;
  isOptedIn: boolean;
};

const OnboardingSettingsScreenComponent = (
  props: OnboardingSettingsScreenProps
) => {
  const {
    onNextClicked,
    setProfilePublicStatus,
    setMarketingOptIn,
    isPublic,
    isOptedIn,
  } = props;

  const [busy, _] = useBusyWatcher();

  return (
    <FormScreen
      title="account settings"
      comment="one last thing before we show you your profile"
      onNextClicked={onNextClicked}
      nextDisabled={busy}
    >
      <div className="flex flex-row justify-between items-center">
        <div className="font-bold">profile visibility</div>
        <div className="flex flex-row items-center gap-2">
          <MVisibilityToggle
            onClick={() => setProfilePublicStatus(!isPublic)}
            isVisible={isPublic}
            copy="public"
          />
          <MTooltipIcon tooltip="When enabled, your profile will be visible to other users and discoverable via search. When disabled, your profile will only be visible and searchable by yourself and anyone you add to your private access list found on the Settings page." />
        </div>
      </div>
      <div className="flex flex-row justify-between items-center">
        <div className="font-bold">marketing emails</div>
        <div className="flex flex-row items-center gap-2">
          <MVisibilityToggle
            onClick={() => setMarketingOptIn(!isOptedIn)}
            isVisible={isOptedIn}
            copy="opt in"
          />
          <MTooltipIcon tooltip="As we continue to develop Manual we'll be rolling out new features and updates. We'd like to share those updates with you via email." />
        </div>
      </div>
    </FormScreen>
  );
};

export default OnboardingSettingsScreenComponent;
