import React, { useState } from "react";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { ListUserInvitesResponse } from "../../../api/models";
import SidebarSection from "../../ui/screens/sidebarnav/SidebarSection";
import MButton from "../../ui/buttons/MButton";
import SettingsButtonRow from "../../ui/screens/sidebarnav/SidebarButtonRow";
import { useBusyWatcher } from "../../../util/hooks";
import InviteUserDialog from "../../dialogs/InviteUserDialog";
import SettingsCommentRow from "../../ui/screens/sidebarnav/SidebarCommentRow";
import SettingsLoadingRow from "../../ui/screens/sidebarnav/SidebarLoadingRow";
import SettingsInviteRow from "./SettingsInviteRow";

type SettingsInvitesScreenProps = {
  loading: boolean;
  errors: string[];
  invites: ListUserInvitesResponse | null;
  onInviteEmail: (email: string) => Promise<boolean>;
  onResendInvite: (inviteId: string) => void;
};

const SettingsInvitesScreenComponent = (props: SettingsInvitesScreenProps) => {
  const { loading, errors, invites, onInviteEmail, onResendInvite } = props;

  const [showInviteDialog, setShowInviteDialog] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);
  const [busy, _] = useBusyWatcher();

  const onInviteAnotherClicked = () => {
    setSent(false);
    setShowInviteDialog(true);
  };

  const onInviteEmailSubmitted = async (email: string) => {
    const result = await onInviteEmail(email);
    if (result) {
      setSent(true);
    }
  };

  return (
    <>
      <SidebarSection
        title="Your Invites"
        description={<>All invites you have sent to others are listed below.</>}
      >
        <>
          {loading && (
            <>
              <SettingsLoadingRow />
              <SettingsLoadingRow />
              <SettingsLoadingRow />
            </>
          )}
          {!loading &&
            invites !== null &&
            invites.invites.length > 0 &&
            invites.invites.map((invite) => (
              <SettingsInviteRow
                key={invite.guid!}
                invite={invite}
                onResendClicked={() => onResendInvite(invite.guid!)}
              />
            ))}
          {!loading && invites !== null && invites.invites.length === 0 && (
            <SettingsCommentRow comment="You have not invited anyone yet." />
          )}
          <SettingsButtonRow
            label="Invite someone else"
            button={
              <MButton
                kind="primary"
                disabled={busy}
                onClick={onInviteAnotherClicked}
                icon={<UserPlusIcon className="h-5 w-5" />}
              >
                invite to Manual
              </MButton>
            }
          />
        </>
      </SidebarSection>
      <InviteUserDialog
        show={showInviteDialog}
        onClose={() => setShowInviteDialog(false)}
        errors={errors}
        onEmailSubmitted={onInviteEmailSubmitted}
        sent={sent}
        onInviteAnotherClicked={onInviteAnotherClicked}
      />
    </>
  );
};

export default SettingsInvitesScreenComponent;
