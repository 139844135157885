import React, { ReactNode } from "react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import MButton, { MButtonProps } from "./MButton";

type MEditButtonProps = MButtonProps & {
  text?: string | null;
  icon?: ReactNode;
};

const MEditButtonComponent = (props: MEditButtonProps) => {
  const { text, ...rest } = props;

  return <MButton {...rest}>{text}</MButton>;
};

MEditButtonComponent.defaultProps = {
  text: "edit",
  icon: <PencilSquareIcon className="h-6 w-5 flex-none" aria-hidden="true" />,
};

export default MEditButtonComponent;
