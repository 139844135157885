import React, { useEffect, useState } from "react";
import { MNavProps } from "../../../ui/nav/MNav";
import { useBusyWatcher } from "../../../../util/hooks";
import { useWrappedConnector } from "../../../../api/connector";
import WizardScreen, {
  WizardScreenPanel,
} from "../../../ui/screens/WizardScreen";
import LoadingScreen from "../../../ui/screens/LoadingScreen";
import BaseNavbarPage from "../../BaseNavbarPage";
import { GetBasicInfo, SetBasicInfo } from "../../../../api/models";
import BasicsScreen from "./BasicsScreen";
import {
  errorMessageFromResponse,
  errorMessagesFromResponse,
} from "../../../../api/helpers";

type BasicsPageProps = {
  nextUrl: string;
  navbarProps: MNavProps;
};

enum BasicsStage {
  LOADING,
  VIEW,
}

const BasicsPageComponent = (props: BasicsPageProps) => {
  const { nextUrl, navbarProps } = props;

  const [errors, setErrors] = useState<string[]>([]);
  const [errorsMap, setErrorsMap] = useState<{ [key: string]: string[] }>({});
  const [stage, setStage] = useState<BasicsStage>(BasicsStage.LOADING);
  const [basics, setBasics] = useState<GetBasicInfo | null>(null);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);

  const goToStage = async (nextStage: BasicsStage) => {
    setErrors([]);
    setErrorsMap({});
    // eslint-disable-next-line default-case
    switch (nextStage) {
      case BasicsStage.VIEW: {
        try {
          const basicsResponse = await connector.getProfileBasics();
          setBasics(basicsResponse.c!);
        } catch (e: any) {
          const newError = await errorMessageFromResponse(e as Response);
          setErrors([newError ?? "unknown error"]);
        }
        break;
      }
    }
    setStage(nextStage);
  };

  const onSaveBasicsClicked = async (data: SetBasicInfo) => {
    setErrorsMap({});
    try {
      await connector.setProfileBasics(data);
    } catch (e) {
      const newErrors = await errorMessagesFromResponse(e as Response);
      setErrorsMap(newErrors);
      return;
    }
    window.location.href = nextUrl;
  };

  const getElements = (): WizardScreenPanel[] => [
    {
      stage: BasicsStage.LOADING,
      content: (
        <LoadingScreen
          errors={errors}
          onBackClicked={() => window.history.back()}
        />
      ),
    },
    {
      stage: BasicsStage.VIEW,
      content: (
        <BasicsScreen
          onNextClicked={onSaveBasicsClicked}
          errors={errorsMap}
          basics={basics!}
          formTitle="edit basic information"
        />
      ),
    },
  ];

  useEffect(() => {
    goToStage(BasicsStage.VIEW);
  }, []);

  return (
    <BaseNavbarPage navbarProps={navbarProps}>
      <WizardScreen elements={getElements()} stage={stage} />
    </BaseNavbarPage>
  );
};

export default BasicsPageComponent;
