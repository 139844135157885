/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  GetAssessmentsAssessment,
  GetAssessmentsAssessmentFromJSON,
  GetAssessmentsAssessmentFromJSONTyped,
  GetAssessmentsAssessmentToJSON,
} from "./GetAssessmentsAssessment";

/**
 *
 * @export
 * @interface GetAssessmentsResponse
 */
export interface GetAssessmentsResponse {
  /**
   *
   * @type {Array<GetAssessmentsAssessment>}
   * @memberof GetAssessmentsResponse
   */
  assessments: Array<GetAssessmentsAssessment>;
}

export function GetAssessmentsResponseFromJSON(
  json: any
): GetAssessmentsResponse {
  return GetAssessmentsResponseFromJSONTyped(json, false);
}

export function GetAssessmentsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAssessmentsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    assessments: (json["assessments"] as Array<any>).map(
      GetAssessmentsAssessmentFromJSON
    ),
  };
}

export function GetAssessmentsResponseToJSON(
  value?: GetAssessmentsResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    assessments: (value.assessments as Array<any>).map(
      GetAssessmentsAssessmentToJSON
    ),
  };
}
