/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FillInBlank
 */
export interface FillInBlank {
  /**
   *
   * @type {string}
   * @memberof FillInBlank
   */
  question: string;
  /**
   *
   * @type {string}
   * @memberof FillInBlank
   */
  statement: string;
  /**
   *
   * @type {Array<string>}
   * @memberof FillInBlank
   */
  tags?: Array<FillInBlankTagsEnum>;
  /**
   *
   * @type {number}
   * @memberof FillInBlank
   */
  max_chars?: number;
  /**
   *
   * @type {string}
   * @memberof FillInBlank
   */
  placeholder?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum FillInBlankTagsEnum {
  deep = "deep",
  weird = "weird",
  awkward = "awkward",
  would_you_rather = "would you rather",
  practicals = "practicals",
  daily = "daily",
  other = "other",
}

export function FillInBlankFromJSON(json: any): FillInBlank {
  return FillInBlankFromJSONTyped(json, false);
}

export function FillInBlankFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FillInBlank {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    question: json["question"],
    statement: json["statement"],
    tags: !exists(json, "tags") ? undefined : json["tags"],
    max_chars: !exists(json, "max_chars") ? undefined : json["max_chars"],
    placeholder: !exists(json, "placeholder") ? undefined : json["placeholder"],
  };
}

export function FillInBlankToJSON(value?: FillInBlank | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    question: value.question,
    statement: value.statement,
    tags: value.tags,
    max_chars: value.max_chars,
    placeholder: value.placeholder,
  };
}
