/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UsernameOrId
 */
export interface UsernameOrId {
  /**
   *
   * @type {string}
   * @memberof UsernameOrId
   */
  username?: string | null;
  /**
   *
   * @type {string}
   * @memberof UsernameOrId
   */
  user_id?: string | null;
}

export function UsernameOrIdFromJSON(json: any): UsernameOrId {
  return UsernameOrIdFromJSONTyped(json, false);
}

export function UsernameOrIdFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UsernameOrId {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    username: !exists(json, "username") ? undefined : json["username"],
    user_id: !exists(json, "user_id") ? undefined : json["user_id"],
  };
}

export function UsernameOrIdToJSON(value?: UsernameOrId | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    username: value.username,
    user_id: value.user_id,
  };
}
