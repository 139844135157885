/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  LinkSummary,
  LinkSummaryFromJSON,
  LinkSummaryFromJSONTyped,
  LinkSummaryToJSON,
} from "./LinkSummary";

/**
 *
 * @export
 * @interface LinkSummarySerializer
 */
export interface LinkSummarySerializer {
  /**
   *
   * @type {string}
   * @memberof LinkSummarySerializer
   */
  m?: string;
  /**
   *
   * @type {boolean}
   * @memberof LinkSummarySerializer
   */
  s: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof LinkSummarySerializer
   */
  e?: { [key: string]: string } | null;
  /**
   *
   * @type {LinkSummary}
   * @memberof LinkSummarySerializer
   */
  c?: LinkSummary;
}

export function LinkSummarySerializerFromJSON(
  json: any
): LinkSummarySerializer {
  return LinkSummarySerializerFromJSONTyped(json, false);
}

export function LinkSummarySerializerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LinkSummarySerializer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    m: !exists(json, "m") ? undefined : json["m"],
    s: json["s"],
    e: !exists(json, "e") ? undefined : json["e"],
    c: !exists(json, "c") ? undefined : LinkSummaryFromJSON(json["c"]),
  };
}

export function LinkSummarySerializerToJSON(
  value?: LinkSummarySerializer | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    m: value.m,
    s: value.s,
    e: value.e,
    c: LinkSummaryToJSON(value.c),
  };
}
