import React, { PropsWithChildren, ReactNode } from "react";
import { classNames } from "../../../util/strings";

export type MCardType = "regular" | "warning";

type MCardProps = {
  header?: ReactNode;
  footer?: ReactNode;
  flush?: boolean;
  flushFooter?: boolean;
  className?: string | null;
  type?: MCardType;
  href?: string;
};

const MCardComponent = (props: PropsWithChildren<MCardProps>) => {
  const {
    header,
    footer,
    flush,
    flushFooter,
    children,
    className,
    type,
    href,
  } = props;
  const contentClasses = flush ? "body" : "body px-4 py-5 sm:p-6";
  const content = href ? (
    <a
      href={href}
      className={classNames(contentClasses, href ? "block" : null)}
    >
      {children}
    </a>
  ) : (
    <div className={classNames(contentClasses)}>{children}</div>
  );

  return (
    <article
      className={classNames(
        "divide-y divide-m-light-gray overflow-hidden rounded-lg shadow shadow-m-gray",
        !className || className.indexOf("bg-") === -1 ? "bg-m-white" : null,
        className
      )}
    >
      {header && (
        <header
          className={classNames(
            "px-4 py-5 sm:px-6 bg-secondary font-bold",
            type === "regular" ? "bg-secondary" : "bg-warning/10"
          )}
        >
          {header}
        </header>
      )}
      {content}
      {footer && (
        <footer
          className={classNames(
            "bg-m-secondary",
            flushFooter ? null : "px-4 py-4 sm:px-6 bg-m-secondary"
          )}
        >
          {footer}
        </footer>
      )}
    </article>
  );
};

MCardComponent.defaultProps = {
  header: undefined,
  footer: undefined,
  flush: false,
  flushFooter: false,
  className: null,
  type: "regular" as MCardType,
  href: undefined,
};

export default MCardComponent;
