import React from "react";
import {
  ListPendingReceivedConnectionRequestsResponse,
  ListPendingSentConnectionRequestsResponse,
  UserConnectionRequestRelatedDetail,
} from "../../../api/models";
import SidebarSection from "../../ui/screens/sidebarnav/SidebarSection";
import SidebarLoadingRow from "../../ui/screens/sidebarnav/SidebarLoadingRow";
import SidebarCommentRow from "../../ui/screens/sidebarnav/SidebarCommentRow";
import { useAsyncConfirmationDialog } from "../../dialogs/hooks";
import ReceivedConnectionRequestRow from "./ReceivedConnectionRequestRow";
import SentConnectionRequestRow from "./SentConnectionRequestRow";

type ListPendingRequestsScreenProps = {
  loading: boolean;
  pendingSentRequests: ListPendingSentConnectionRequestsResponse | null;
  pendingReceivedRequests: ListPendingReceivedConnectionRequestsResponse | null;
  onAcceptClicked: (request: UserConnectionRequestRelatedDetail) => void;
  onRejectClicked: (request: UserConnectionRequestRelatedDetail) => void;
};

const ListPendingRequestsScreenComponent = (
  props: ListPendingRequestsScreenProps
) => {
  const {
    loading,
    pendingSentRequests,
    pendingReceivedRequests,
    onAcceptClicked,
    onRejectClicked: onRejectClickedInput,
  } = props;

  const confirmDialog = useAsyncConfirmationDialog();

  const onRejectClicked = async (
    request: UserConnectionRequestRelatedDetail
  ) => {
    const result = await confirmDialog.confirm({
      title: "ignore request?",
      body: `are you sure you want to ignore this request? if you do, ${request.sending_user.name} will not be notified and the request will be removed from your pending requests. you can request to add them to your people later if you change your mind.`,
    });
    if (!result) {
      return;
    }
    onRejectClickedInput(request);
  };

  return (
    <>
      <SidebarSection
        title="received requests"
        description="outstanding requests that others have sent to you to add you to their people"
      >
        {loading && (
          <>
            <SidebarLoadingRow />
            <SidebarLoadingRow />
            <SidebarLoadingRow />
          </>
        )}
        {!loading &&
          (!pendingReceivedRequests ||
            pendingReceivedRequests.requests.length === 0) && (
            <SidebarCommentRow comment="no pending requests. when someone sends you a connection request you will be able to respond to it here." />
          )}
        {!loading &&
          pendingReceivedRequests &&
          pendingReceivedRequests.requests.length > 0 &&
          pendingReceivedRequests.requests.map((request) => (
            <ReceivedConnectionRequestRow
              key={request.guid!}
              request={request}
              onAcceptClicked={() => onAcceptClicked(request)}
              onRejectClicked={() => onRejectClicked(request)}
            />
          ))}
      </SidebarSection>
      <SidebarSection
        title="sent requests"
        description="outstanding requests that you've sent to others to add them to your people"
      >
        {loading && (
          <>
            <SidebarLoadingRow />
            <SidebarLoadingRow />
            <SidebarLoadingRow />
          </>
        )}
        {!loading &&
          (!pendingSentRequests ||
            pendingSentRequests.requests.length === 0) && (
            <SidebarCommentRow comment="you haven't sent any connection requests that are still outstanding." />
          )}
        {!loading &&
          pendingSentRequests &&
          pendingSentRequests.requests.length > 0 &&
          pendingSentRequests.requests.map((request) => (
            <SentConnectionRequestRow key={request.guid!} request={request} />
          ))}
      </SidebarSection>
      {confirmDialog.dialog}
    </>
  );
};

export default ListPendingRequestsScreenComponent;
