import React, { ReactNode } from "react";
import { ChevronDoubleRightIcon } from "@heroicons/react/20/solid";
import MButton, { MButtonProps } from "./MButton";

type MSkipButtonProps = MButtonProps & {
  text?: string;
  icon?: ReactNode;
};

const MSkipButtonComponent = (props: MSkipButtonProps) => {
  const { text, ...rest } = props;

  return <MButton {...rest}>{text}</MButton>;
};

MSkipButtonComponent.defaultProps = {
  text: "skip",
  icon: (
    <ChevronDoubleRightIcon className="h-6 w-5 flex-none" aria-hidden="true" />
  ),
};

export default MSkipButtonComponent;
