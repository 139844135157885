import React, { useEffect, useState } from "react";
import { CommonPromptTypeScreenProps } from "./PromptRespondScreen";
import CommonQuestionScreen from "./CommonQuestionScreen";
import MultiSelectQuestion from "./questions/MultiSelectQuestion";
import { useBusyWatcher } from "../../../util/hooks";

type MultiSelectScreenProps = CommonPromptTypeScreenProps & {
  minNumSelectable?: number | null;
  maxNumSelectable?: number | null;
  numSelectable?: number | null;
  options: string[];
  onNextClicked: (selected: number[], visible: boolean | null) => void;
  selected?: number[];
};

const MultiSelectScreenComponent = (props: MultiSelectScreenProps) => {
  const {
    title,
    visible: visibleInput,
    onNextClicked,
    selected: selectedInput,
    minNumSelectable,
    maxNumSelectable,
    numSelectable,
    options,
    publishable,
    errors,
    ...rest
  } = props;

  const [innerSelected, setInnerSelected] = useState<number[]>([]);
  const [visible, setVisible] = useState<boolean | null>(visibleInput);
  const [busy, _] = useBusyWatcher();

  const canSubmit = (): boolean => {
    if (innerSelected.length === 0) {
      return false;
    }
    if (minNumSelectable && innerSelected.length < minNumSelectable) {
      return false;
    }
    if (maxNumSelectable && innerSelected.length > maxNumSelectable) {
      return false;
    }
    if (numSelectable && innerSelected.length !== numSelectable) {
      return false;
    }
    return true;
  };

  const submit = () => {
    onNextClicked(innerSelected, visible);
  };

  const updatePromptResponseVisibility = () => {
    if (rest.prompt.response) {
      onNextClicked(innerSelected, !visible);
    }
    setVisible(!visible); // This needs to be set after the call because setState is async
  };

  useEffect(() => {
    if (selectedInput) {
      setInnerSelected(selectedInput);
    } else {
      setInnerSelected([]);
    }
  }, [selectedInput]);

  return (
    <CommonQuestionScreen {...rest} canSubmit={canSubmit()} submit={submit}>
      <MultiSelectQuestion
        question={title}
        onSelected={setInnerSelected}
        onVisibilityUpdated={updatePromptResponseVisibility}
        showVisibilityToggle
        options={options}
        errors={errors}
        selected={innerSelected}
        visible={visible}
        publishable={publishable}
        minNumSelectable={minNumSelectable}
        maxNumSelectable={maxNumSelectable}
        numSelectable={numSelectable}
        disabled={busy}
      />
    </CommonQuestionScreen>
  );
};

MultiSelectScreenComponent.defaultProps = {
  selected: undefined,
  minNumSelectable: null,
  maxNumSelectable: null,
  numSelectable: null,
};

export default MultiSelectScreenComponent;
