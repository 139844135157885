import React, { PropsWithChildren } from "react";
import FormScreen from "../screens/FormScreen";
import { MStepsCirclesProps } from "../MStepsCircles";
import { useBusyWatcher } from "../../../util/hooks";
import MCard from "../cards/MCard";
import MWellDeleteButton from "../wells/MWellDeleteButton";
import MWellEditButton from "../wells/MWellEditButton";
import MWellVoteButton from "../wells/MWellVoteButton";
import {
  PromptWithResponse,
  PublishablePromptWithResponse,
  QuestionOperationsResponse,
} from "../../../api/models";
import MBadge from "../MBadge";

type CommonQuestionScreenProps = {
  prompt: PublishablePromptWithResponse;
  questionOperations?: QuestionOperationsResponse | null;
  onVoteClicked?: (() => void) | null;
  onEditClicked?: (() => void) | null;
  onDeleteClicked?: ((toDelete: PromptWithResponse) => void) | null;
  onBackClicked?: (() => void) | null;
  onClearClicked?: (() => void) | null;
  onSkipClicked?: (() => void) | null;
  onTagClicked?: ((tag: string) => void) | null;
  steps?: MStepsCirclesProps | null;
  canSubmit: boolean;
  submit: () => void;
};

const CommonQuestionScreenComponent = (
  props: PropsWithChildren<CommonQuestionScreenProps>
) => {
  const {
    canSubmit,
    submit,
    children,
    prompt,
    questionOperations,
    onVoteClicked,
    onEditClicked,
    onDeleteClicked,
    onClearClicked,
    onTagClicked,
    ...rest
  } = props;

  const [busy, _] = useBusyWatcher();

  const hasControls =
    questionOperations && onVoteClicked && onEditClicked && onDeleteClicked;
  const canClear = prompt.response && onClearClicked;

  return (
    <FormScreen
      nextDisabled={busy || !canSubmit}
      onNextClicked={submit}
      onClearClicked={canClear ? onClearClicked : null}
      backDisabled={busy}
      clearDisabled={busy}
      skipDisabled={busy}
      {...rest}
    >
      {hasControls ? (
        <MCard
          className="mt-5"
          footer={
            <div className="flex flex-row gap-3">
              <div className="flex flex-row gap-3 justify-self-start grow">
                {prompt.prompt.tags.length > 0 && (
                  <div className="p-2 flex gap-2">
                    {prompt.prompt.tags.map((tag) => (
                      <MBadge
                        content={tag}
                        key={tag}
                        onClick={() => {
                          if (onTagClicked) {
                            onTagClicked(tag);
                          }
                        }}
                      />
                    ))}
                  </div>
                )}
              </div>
              <div className="flex flex-row gap-3 items-center justify-self-end">
                <MWellVoteButton
                  upvoted={prompt.user_voted ?? false}
                  onClick={() => onVoteClicked()}
                />
                {questionOperations.can_update && (
                  <MWellEditButton onClick={() => onEditClicked()} />
                )}
                {(questionOperations.can_delete ||
                  questionOperations.can_anonymize) && (
                  <MWellDeleteButton onClick={() => onDeleteClicked(prompt)} />
                )}
              </div>
            </div>
          }
        >
          {children}
        </MCard>
      ) : (
        children
      )}
    </FormScreen>
  );
};

CommonQuestionScreenComponent.defaultProps = {
  questionOperations: null,
  onBackClicked: null,
  onClearClicked: null,
  onSkipClicked: null,
  onVoteClicked: null,
  onEditClicked: null,
  onDeleteClicked: null,
  onTagClicked: null,
  steps: null,
};

export default CommonQuestionScreenComponent;
