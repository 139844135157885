import React, { PropsWithChildren, ReactElement } from "react";
import { MStepsCirclesProps } from "../../ui/MStepsCircles";
import CenterColumnStickyButtonScreen from "../../ui/screens/CenterColumnStickyButtonScreen";
import { UserProfileSummary } from "../../../api/models";
import CommCoachTopicRow from "./CommCoachTopicRow";
import MPill from "../../ui/MPill";
import CommCoachHeader from "./CommCoachHeader";

type CommCoachFormScreenProps = {
  progress: MStepsCirclesProps;
  buttons: ReactElement[];
  user?: UserProfileSummary | null;
  onEditUserClicked?: (() => void) | null;
  topic?: string | null;
  onEditTopicClicked?: (() => void) | null;
  goals?: string[] | null;
  onEditGoalsClicked?: (() => void) | null;
  concerns?: string[] | null;
  onEditConcernsClicked?: (() => void) | null;
};

const CommCoachFormScreenComponent = (
  props: PropsWithChildren<CommCoachFormScreenProps>
) => {
  const {
    progress,
    buttons,
    user,
    onEditUserClicked,
    topic,
    onEditTopicClicked,
    goals,
    onEditGoalsClicked,
    concerns,
    onEditConcernsClicked,
    children,
  } = props;

  return (
    <CenterColumnStickyButtonScreen buttons={buttons} stacked fullWidth>
      <CommCoachHeader progress={progress} />
      <div className="flex flex-col gap-2">
        {user && (
          <CommCoachTopicRow
            title="talking to"
            onEditClicked={onEditUserClicked}
          >
            {user.name}
          </CommCoachTopicRow>
        )}
        {topic && (
          <CommCoachTopicRow title="about" onEditClicked={onEditTopicClicked}>
            {topic}
          </CommCoachTopicRow>
        )}
        {goals && goals.length > 0 && (
          <CommCoachTopicRow title="goals" onEditClicked={onEditGoalsClicked}>
            <div className="flex flex-row flex-wrap gap-2">
              {goals.map((goal) => (
                <MPill key={goal} content={goal} />
              ))}
            </div>
          </CommCoachTopicRow>
        )}
        {concerns && concerns.length > 0 && (
          <CommCoachTopicRow
            title="concerns"
            onEditClicked={onEditConcernsClicked}
          >
            <div className="flex flex-row flex-wrap gap-2">
              {concerns.map((concern) => (
                <MPill key={concern} content={concern} />
              ))}
            </div>
          </CommCoachTopicRow>
        )}
      </div>
      <div className="mt-5">{children}</div>
    </CenterColumnStickyButtonScreen>
  );
};

CommCoachFormScreenComponent.defaultProps = {
  user: null,
  onEditUserClicked: null,
  topic: null,
  onEditTopicClicked: null,
  goals: null,
  onEditGoalsClicked: null,
  concerns: null,
  onEditConcernsClicked: null,
};

export default CommCoachFormScreenComponent;
