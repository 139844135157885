import React, { PropsWithChildren, ReactNode, useState } from "react";
import { MTitleCardProps } from "./MTitleCard";
import MCard from "./MCard";
import MWell from "../wells/MWell";
import MWellEditButton from "../wells/MWellEditButton";
import MWellDeleteButton from "../wells/MWellDeleteButton";
import { classNames } from "../../../util/strings";
import MWellCollapseButton from "../wells/MWellCollapseButton";
import VisibilityFooter from "./VisibilityFooter";

type MInsetTitleCardProps = MTitleCardProps & {
  collapsible?: boolean;
  visibilityCopy?: string;
  onToggleVisibilityClicked?: (() => void) | null;
  toggleVisibilityDisabled?: boolean;
  isVisible?: boolean;
  visibilityToggleDisabledTooltip?: string | null;
  visibleStatus?: ReactNode;
};

const MInsetTitleCardComponent = (
  props: PropsWithChildren<MInsetTitleCardProps>
) => {
  const {
    title,
    type,
    onEditClicked,
    editDisabled,
    onDeleteClicked,
    deleteDisabled,
    onToggleVisibilityClicked,
    toggleVisibilityDisabled,
    isVisible,
    visibilityCopy,
    children,
    collapsible,
    visibilityToggleDisabledTooltip,
    visibleStatus,
    ...rest
  } = props;

  const hasButtons = Boolean(onEditClicked || onDeleteClicked || collapsible);

  const [showBody, setShowBody] = useState<boolean>(!collapsible);

  const getFooter = (): ReactNode => {
    if (visibleStatus) {
      return <div className="flex flex-row-reverse">{visibleStatus}</div>;
    }
    if (onToggleVisibilityClicked && isVisible !== undefined) {
      return (
        <VisibilityFooter
          onToggleVisibilityClicked={onToggleVisibilityClicked}
          toggleVisibilityDisabled={toggleVisibilityDisabled}
          visibilityCopy={visibilityCopy}
          isVisible={isVisible}
          disabledTooltip={visibilityToggleDisabledTooltip}
        />
      );
    }
    return null;
  };

  return (
    <MCard
      className={type === "regular" ? "bg-secondary" : "bg-warning/10"}
      footer={getFooter()}
      {...rest}
    >
      <div
        className={classNames(
          "flex flex-row items-start gap-4",
          showBody ? "mb-3" : null
        )}
      >
        {typeof title === "string" ? (
          <div className="font-bold text-m-dark-gray grow">{title}</div>
        ) : (
          title
        )}
        {hasButtons && (
          <div className="flex flex-row gap-2">
            {onEditClicked && (
              <MWellEditButton
                onClick={onEditClicked}
                disabled={editDisabled}
              />
            )}
            {onDeleteClicked && (
              <MWellDeleteButton
                onClick={onDeleteClicked}
                disabled={deleteDisabled}
              />
            )}
            {collapsible && (
              <MWellCollapseButton
                collapsed={!showBody}
                onClick={() => setShowBody(!showBody)}
              />
            )}
          </div>
        )}
      </div>
      {showBody && <MWell kind="white">{children}</MWell>}
    </MCard>
  );
};

MInsetTitleCardComponent.defaultProps = {
  collapsible: false,
  visibilityCopy: "show on profile",
  onToggleVisibilityClicked: null,
  toggleVisibilityDisabled: false,
  isVisible: true,
  visibilityToggleDisabledTooltip: null,
  visibleStatus: null,
};

export default MInsetTitleCardComponent;
