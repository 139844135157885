import React from "react";
import { CommonEditQuestionProps } from "./common";
import EditMultiSelectQuestionScreen from "./EditMultiSelectQuestionScreen";
import {
  EitherOr,
  FillInBlank,
  MultiSelect,
  UpdateQuestionRequest,
} from "../../../../api/models";
import EditFillInBlankQuestionScreen from "./EditFillInBlankQuestionScreen";
import EditEitherOrQuestionScreen from "./EditEitherOrQuestionScreen";

type EditPromptScreenProps = CommonEditQuestionProps & {
  onUpdate: (request: UpdateQuestionRequest) => void;
};

const EditPromptScreenComponent = (props: EditPromptScreenProps) => {
  const { onUpdate, ...rest } = props;

  switch (rest.prompt!.question_type) {
    case "multi_select": {
      return (
        <EditMultiSelectQuestionScreen
          onNextClicked={(result: MultiSelect) =>
            onUpdate({
              prompt: rest.prompt!.guid!,
              content: {
                multi_select: result,
              },
            })
          }
          isCreate={false}
          {...rest}
        />
      );
    }
    case "fill_in_blank": {
      return (
        <EditFillInBlankQuestionScreen
          onNextClicked={(result: FillInBlank) =>
            onUpdate({
              prompt: rest.prompt!.guid!,
              content: {
                fill_in_blank: result,
              },
            })
          }
          isCreate={false}
          {...rest}
        />
      );
    }
    case "either_or": {
      return (
        <EditEitherOrQuestionScreen
          onNextClicked={(result: EitherOr) =>
            onUpdate({
              prompt: rest.prompt!.guid!,
              content: {
                either_or: result,
              },
            })
          }
          isCreate={false}
          {...rest}
        />
      );
    }
    default: {
      throw new Error(`Not Supported ${rest.prompt!.question_type}`);
    }
  }
};

export default EditPromptScreenComponent;
