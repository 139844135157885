/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  ContactUserViaGlimpseRequest,
  ContactUserViaGlimpseRequestFromJSON,
  ContactUserViaGlimpseRequestToJSON,
  DeleteGlimpseRequest,
  DeleteGlimpseRequestFromJSON,
  DeleteGlimpseRequestToJSON,
  EmptyResponseWrapper,
  EmptyResponseWrapperFromJSON,
  EmptyResponseWrapperToJSON,
  GetGlimpseProcessingReadyStatusResponseSerializer,
  GetGlimpseProcessingReadyStatusResponseSerializerFromJSON,
  GetGlimpseProcessingReadyStatusResponseSerializerToJSON,
  GetGlimpseTodosResponseSerializer,
  GetGlimpseTodosResponseSerializerFromJSON,
  GetGlimpseTodosResponseSerializerToJSON,
  GlimpseAttachmentVisibilityDetailSerializer,
  GlimpseAttachmentVisibilityDetailSerializerFromJSON,
  GlimpseAttachmentVisibilityDetailSerializerToJSON,
  GlimpseDetailSerializer,
  GlimpseDetailSerializerFromJSON,
  GlimpseDetailSerializerToJSON,
  GlimpseLimitsSerializer,
  GlimpseLimitsSerializerFromJSON,
  GlimpseLimitsSerializerToJSON,
  ListGlimpsesResponseSerializer,
  ListGlimpsesResponseSerializerFromJSON,
  ListGlimpsesResponseSerializerToJSON,
  ListLinksForGlimpseResponseSerializer,
  ListLinksForGlimpseResponseSerializerFromJSON,
  ListLinksForGlimpseResponseSerializerToJSON,
  ListPromptResponsesForGlimpseResponseSerializer,
  ListPromptResponsesForGlimpseResponseSerializerFromJSON,
  ListPromptResponsesForGlimpseResponseSerializerToJSON,
  SetGlimpseResumeVisibilityRequest,
  SetGlimpseResumeVisibilityRequestFromJSON,
  SetGlimpseResumeVisibilityRequestToJSON,
  SetGlimpseVisibilityRequest,
  SetGlimpseVisibilityRequestFromJSON,
  SetGlimpseVisibilityRequestToJSON,
  SetLinksForGlimpseRequest,
  SetLinksForGlimpseRequestFromJSON,
  SetLinksForGlimpseRequestToJSON,
  SetPromptResponsesForGlimpseRequest,
  SetPromptResponsesForGlimpseRequestFromJSON,
  SetPromptResponsesForGlimpseRequestToJSON,
  SubmitGlimpseForProcessingRequest,
  SubmitGlimpseForProcessingRequestFromJSON,
  SubmitGlimpseForProcessingRequestToJSON,
  UpdateGlimpseContentRequest,
  UpdateGlimpseContentRequestFromJSON,
  UpdateGlimpseContentRequestToJSON,
  UpdateGlimpsePitchAudienceRequest,
  UpdateGlimpsePitchAudienceRequestFromJSON,
  UpdateGlimpsePitchAudienceRequestToJSON,
  UpdateGlimpsePitchContextRequest,
  UpdateGlimpsePitchContextRequestFromJSON,
  UpdateGlimpsePitchContextRequestToJSON,
  UpdateGlimpseTitleRequest,
  UpdateGlimpseTitleRequestFromJSON,
  UpdateGlimpseTitleRequestToJSON,
  ViewGlimpseResponseSerializer,
  ViewGlimpseResponseSerializerFromJSON,
  ViewGlimpseResponseSerializerToJSON,
} from "../models";

export interface ContactUserViaGlimpseOperationRequest {
  data: ContactUserViaGlimpseRequest;
}

export interface CreateGlimpseRequest {
  data: object;
}

export interface DeleteGlimpseOperationRequest {
  data: DeleteGlimpseRequest;
}

export interface GetGlimpseRequest {
  glimpse: string;
}

export interface GetGlimpseAttachmentVisibilityRequest {
  glimpse: string;
}

export interface GlimpseProcessingReadyStatusRequest {
  glimpse: string;
}

export interface ListGlimpseLinksRequest {
  glimpse: string;
}

export interface ListGlimpsePromptResponsesRequest {
  glimpse: string;
}

export interface ListGlimpsesRequest {
  offset?: number;
  limit?: number;
}

export interface SetGlimpseLinksRequest {
  data: SetLinksForGlimpseRequest;
}

export interface SetGlimpseResumeVisibilityOperationRequest {
  data: SetGlimpseResumeVisibilityRequest;
}

export interface SetGlimpseVisibilityOperationRequest {
  data: SetGlimpseVisibilityRequest;
}

export interface SetPromptResponsesForGlimpseOperationRequest {
  data: SetPromptResponsesForGlimpseRequest;
}

export interface SubmitGlimpseForProcessingOperationRequest {
  data: SubmitGlimpseForProcessingRequest;
}

export interface UpdateGlimpseContentOperationRequest {
  data: UpdateGlimpseContentRequest;
}

export interface UpdateGlimpsePitchAudienceOperationRequest {
  data: UpdateGlimpsePitchAudienceRequest;
}

export interface UpdateGlimpsePitchContextOperationRequest {
  data: UpdateGlimpsePitchContextRequest;
}

export interface UpdateGlimpseTitleOperationRequest {
  data: UpdateGlimpseTitleRequest;
}

export interface ViewGlimpseRequest {
  glimpse: string;
}

/**
 *
 */
export class GlimpseApi extends runtime.BaseAPI {
  /**
   * Contact the user who owns the referenced glimpse, if that user allows for being contacted via the Glimpse.
   */
  async contactUserViaGlimpseRaw(
    requestParameters: ContactUserViaGlimpseOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling contactUserViaGlimpse."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/glimpse/contact`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ContactUserViaGlimpseRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Contact the user who owns the referenced glimpse, if that user allows for being contacted via the Glimpse.
   */
  async contactUserViaGlimpse(
    requestParameters: ContactUserViaGlimpseOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.contactUserViaGlimpseRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create a new glimpse for the requesting user.
   */
  async createGlimpseRaw(
    requestParameters: CreateGlimpseRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GlimpseDetailSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling createGlimpse."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/glimpse/create`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.data as any,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GlimpseDetailSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Create a new glimpse for the requesting user.
   */
  async createGlimpse(
    requestParameters: CreateGlimpseRequest,
    initOverrides?: RequestInit
  ): Promise<GlimpseDetailSerializer> {
    const response = await this.createGlimpseRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Delete the referenced glimpse.
   */
  async deleteGlimpseRaw(
    requestParameters: DeleteGlimpseOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling deleteGlimpse."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/glimpse/delete`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: DeleteGlimpseRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Delete the referenced glimpse.
   */
  async deleteGlimpse(
    requestParameters: DeleteGlimpseOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.deleteGlimpseRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get the details for the referenced glimpse.
   */
  async getGlimpseRaw(
    requestParameters: GetGlimpseRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GlimpseDetailSerializer>> {
    if (
      requestParameters.glimpse === null ||
      requestParameters.glimpse === undefined
    ) {
      throw new runtime.RequiredError(
        "glimpse",
        "Required parameter requestParameters.glimpse was null or undefined when calling getGlimpse."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.glimpse !== undefined) {
      queryParameters["glimpse"] = requestParameters.glimpse;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/glimpse/get`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GlimpseDetailSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the details for the referenced glimpse.
   */
  async getGlimpse(
    requestParameters: GetGlimpseRequest,
    initOverrides?: RequestInit
  ): Promise<GlimpseDetailSerializer> {
    const response = await this.getGlimpseRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get the attachment visibility for the requesting user.
   */
  async getGlimpseAttachmentVisibilityRaw(
    requestParameters: GetGlimpseAttachmentVisibilityRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GlimpseAttachmentVisibilityDetailSerializer>> {
    if (
      requestParameters.glimpse === null ||
      requestParameters.glimpse === undefined
    ) {
      throw new runtime.RequiredError(
        "glimpse",
        "Required parameter requestParameters.glimpse was null or undefined when calling getGlimpseAttachmentVisibility."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.glimpse !== undefined) {
      queryParameters["glimpse"] = requestParameters.glimpse;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/glimpse/get-attachments-visibility`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GlimpseAttachmentVisibilityDetailSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the attachment visibility for the requesting user.
   */
  async getGlimpseAttachmentVisibility(
    requestParameters: GetGlimpseAttachmentVisibilityRequest,
    initOverrides?: RequestInit
  ): Promise<GlimpseAttachmentVisibilityDetailSerializer> {
    const response = await this.getGlimpseAttachmentVisibilityRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get the limits for the requesting user.
   */
  async getGlimpseLimitsRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GlimpseLimitsSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/glimpse/get-limits`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GlimpseLimitsSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the limits for the requesting user.
   */
  async getGlimpseLimits(
    initOverrides?: RequestInit
  ): Promise<GlimpseLimitsSerializer> {
    const response = await this.getGlimpseLimitsRaw(initOverrides);
    return await response.value();
  }

  /**
   * Get a list of items that the requesting user should perform before they go through the process of creating a Glimpse.
   */
  async getGlimpseTodosRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetGlimpseTodosResponseSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/glimpse/get-todos`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetGlimpseTodosResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get a list of items that the requesting user should perform before they go through the process of creating a Glimpse.
   */
  async getGlimpseTodos(
    initOverrides?: RequestInit
  ): Promise<GetGlimpseTodosResponseSerializer> {
    const response = await this.getGlimpseTodosRaw(initOverrides);
    return await response.value();
  }

  /**
   * Get a boolean indicating whether the referenced glimpse is ready to be sent off to an LLM.
   */
  async glimpseProcessingReadyStatusRaw(
    requestParameters: GlimpseProcessingReadyStatusRequest,
    initOverrides?: RequestInit
  ): Promise<
    runtime.ApiResponse<GetGlimpseProcessingReadyStatusResponseSerializer>
  > {
    if (
      requestParameters.glimpse === null ||
      requestParameters.glimpse === undefined
    ) {
      throw new runtime.RequiredError(
        "glimpse",
        "Required parameter requestParameters.glimpse was null or undefined when calling glimpseProcessingReadyStatus."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.glimpse !== undefined) {
      queryParameters["glimpse"] = requestParameters.glimpse;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/glimpse/processing-ready-status`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetGlimpseProcessingReadyStatusResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get a boolean indicating whether the referenced glimpse is ready to be sent off to an LLM.
   */
  async glimpseProcessingReadyStatus(
    requestParameters: GlimpseProcessingReadyStatusRequest,
    initOverrides?: RequestInit
  ): Promise<GetGlimpseProcessingReadyStatusResponseSerializer> {
    const response = await this.glimpseProcessingReadyStatusRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * List all the links for the referenced glimpse alongside whether the link has been selected for the glimpse.
   */
  async listGlimpseLinksRaw(
    requestParameters: ListGlimpseLinksRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ListLinksForGlimpseResponseSerializer>> {
    if (
      requestParameters.glimpse === null ||
      requestParameters.glimpse === undefined
    ) {
      throw new runtime.RequiredError(
        "glimpse",
        "Required parameter requestParameters.glimpse was null or undefined when calling listGlimpseLinks."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.glimpse !== undefined) {
      queryParameters["glimpse"] = requestParameters.glimpse;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/glimpse/list-links`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ListLinksForGlimpseResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * List all the links for the referenced glimpse alongside whether the link has been selected for the glimpse.
   */
  async listGlimpseLinks(
    requestParameters: ListGlimpseLinksRequest,
    initOverrides?: RequestInit
  ): Promise<ListLinksForGlimpseResponseSerializer> {
    const response = await this.listGlimpseLinksRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * List all the prompt responses for the referenced glimpse alongside whether the prompt response has been selected for the glimpse.
   */
  async listGlimpsePromptResponsesRaw(
    requestParameters: ListGlimpsePromptResponsesRequest,
    initOverrides?: RequestInit
  ): Promise<
    runtime.ApiResponse<ListPromptResponsesForGlimpseResponseSerializer>
  > {
    if (
      requestParameters.glimpse === null ||
      requestParameters.glimpse === undefined
    ) {
      throw new runtime.RequiredError(
        "glimpse",
        "Required parameter requestParameters.glimpse was null or undefined when calling listGlimpsePromptResponses."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.glimpse !== undefined) {
      queryParameters["glimpse"] = requestParameters.glimpse;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/glimpse/list-prompt-responses`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ListPromptResponsesForGlimpseResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * List all the prompt responses for the referenced glimpse alongside whether the prompt response has been selected for the glimpse.
   */
  async listGlimpsePromptResponses(
    requestParameters: ListGlimpsePromptResponsesRequest,
    initOverrides?: RequestInit
  ): Promise<ListPromptResponsesForGlimpseResponseSerializer> {
    const response = await this.listGlimpsePromptResponsesRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * List all the glimpses for the requesting user.
   */
  async listGlimpsesRaw(
    requestParameters: ListGlimpsesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ListGlimpsesResponseSerializer>> {
    const queryParameters: any = {};

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/glimpse/list`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ListGlimpsesResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * List all the glimpses for the requesting user.
   */
  async listGlimpses(
    requestParameters: ListGlimpsesRequest = {},
    initOverrides?: RequestInit
  ): Promise<ListGlimpsesResponseSerializer> {
    const response = await this.listGlimpsesRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Set the links for the referenced glimpse.
   */
  async setGlimpseLinksRaw(
    requestParameters: SetGlimpseLinksRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling setGlimpseLinks."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/glimpse/set-links`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SetLinksForGlimpseRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Set the links for the referenced glimpse.
   */
  async setGlimpseLinks(
    requestParameters: SetGlimpseLinksRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.setGlimpseLinksRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Set the visibility of the resume for the referenced glimpse.
   */
  async setGlimpseResumeVisibilityRaw(
    requestParameters: SetGlimpseResumeVisibilityOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling setGlimpseResumeVisibility."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/glimpse/set-resume-visibility`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SetGlimpseResumeVisibilityRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Set the visibility of the resume for the referenced glimpse.
   */
  async setGlimpseResumeVisibility(
    requestParameters: SetGlimpseResumeVisibilityOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.setGlimpseResumeVisibilityRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Set the visibility of the referenced Glimpse to the specified value if possible.
   */
  async setGlimpseVisibilityRaw(
    requestParameters: SetGlimpseVisibilityOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GlimpseLimitsSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling setGlimpseVisibility."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/glimpse/set-visibility`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SetGlimpseVisibilityRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GlimpseLimitsSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Set the visibility of the referenced Glimpse to the specified value if possible.
   */
  async setGlimpseVisibility(
    requestParameters: SetGlimpseVisibilityOperationRequest,
    initOverrides?: RequestInit
  ): Promise<GlimpseLimitsSerializer> {
    const response = await this.setGlimpseVisibilityRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Set the prompt responses for the referenced glimpse.
   */
  async setPromptResponsesForGlimpseRaw(
    requestParameters: SetPromptResponsesForGlimpseOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling setPromptResponsesForGlimpse."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/glimpse/set-prompt_responses`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SetPromptResponsesForGlimpseRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Set the prompt responses for the referenced glimpse.
   */
  async setPromptResponsesForGlimpse(
    requestParameters: SetPromptResponsesForGlimpseOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.setPromptResponsesForGlimpseRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Submit the referenced glimpse for processing by an LLM.
   */
  async submitGlimpseForProcessingRaw(
    requestParameters: SubmitGlimpseForProcessingOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GlimpseDetailSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling submitGlimpseForProcessing."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/glimpse/process`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SubmitGlimpseForProcessingRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GlimpseDetailSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Submit the referenced glimpse for processing by an LLM.
   */
  async submitGlimpseForProcessing(
    requestParameters: SubmitGlimpseForProcessingOperationRequest,
    initOverrides?: RequestInit
  ): Promise<GlimpseDetailSerializer> {
    const response = await this.submitGlimpseForProcessingRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update the content for the referenced glimpse.
   */
  async updateGlimpseContentRaw(
    requestParameters: UpdateGlimpseContentOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GlimpseDetailSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling updateGlimpseContent."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/glimpse/update-content`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateGlimpseContentRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GlimpseDetailSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Update the content for the referenced glimpse.
   */
  async updateGlimpseContent(
    requestParameters: UpdateGlimpseContentOperationRequest,
    initOverrides?: RequestInit
  ): Promise<GlimpseDetailSerializer> {
    const response = await this.updateGlimpseContentRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update the pitch audience for the referenced glimpse.
   */
  async updateGlimpsePitchAudienceRaw(
    requestParameters: UpdateGlimpsePitchAudienceOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GlimpseDetailSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling updateGlimpsePitchAudience."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/glimpse/update-pitch-audience`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateGlimpsePitchAudienceRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GlimpseDetailSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Update the pitch audience for the referenced glimpse.
   */
  async updateGlimpsePitchAudience(
    requestParameters: UpdateGlimpsePitchAudienceOperationRequest,
    initOverrides?: RequestInit
  ): Promise<GlimpseDetailSerializer> {
    const response = await this.updateGlimpsePitchAudienceRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update the pitch context for the referenced glimpse.
   */
  async updateGlimpsePitchContextRaw(
    requestParameters: UpdateGlimpsePitchContextOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GlimpseDetailSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling updateGlimpsePitchContext."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/glimpse/update-pitch-context`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateGlimpsePitchContextRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GlimpseDetailSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Update the pitch context for the referenced glimpse.
   */
  async updateGlimpsePitchContext(
    requestParameters: UpdateGlimpsePitchContextOperationRequest,
    initOverrides?: RequestInit
  ): Promise<GlimpseDetailSerializer> {
    const response = await this.updateGlimpsePitchContextRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update the title and description for the referenced glimpse.
   */
  async updateGlimpseTitleRaw(
    requestParameters: UpdateGlimpseTitleOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GlimpseDetailSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling updateGlimpseTitle."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/glimpse/update-title`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateGlimpseTitleRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GlimpseDetailSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Update the title and description for the referenced glimpse.
   */
  async updateGlimpseTitle(
    requestParameters: UpdateGlimpseTitleOperationRequest,
    initOverrides?: RequestInit
  ): Promise<GlimpseDetailSerializer> {
    const response = await this.updateGlimpseTitleRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get the content for the referenced glimpse.
   */
  async viewGlimpseRaw(
    requestParameters: ViewGlimpseRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ViewGlimpseResponseSerializer>> {
    if (
      requestParameters.glimpse === null ||
      requestParameters.glimpse === undefined
    ) {
      throw new runtime.RequiredError(
        "glimpse",
        "Required parameter requestParameters.glimpse was null or undefined when calling viewGlimpse."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.glimpse !== undefined) {
      queryParameters["glimpse"] = requestParameters.glimpse;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/glimpse/view`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ViewGlimpseResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the content for the referenced glimpse.
   */
  async viewGlimpse(
    requestParameters: ViewGlimpseRequest,
    initOverrides?: RequestInit
  ): Promise<ViewGlimpseResponseSerializer> {
    const response = await this.viewGlimpseRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
