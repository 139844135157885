/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  PromptDetail,
  PromptDetailFromJSON,
  PromptDetailFromJSONTyped,
  PromptDetailToJSON,
} from "./PromptDetail";

/**
 *
 * @export
 * @interface PromptResponseRelatedDetail
 */
export interface PromptResponseRelatedDetail {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof PromptResponseRelatedDetail
   */
  readonly guid?: string;
  /**
   * Metadata associated with the response
   * @type {object}
   * @memberof PromptResponseRelatedDetail
   */
  meta: object;
  /**
   *
   * @type {PromptDetail}
   * @memberof PromptResponseRelatedDetail
   */
  prompt?: PromptDetail;
}

export function PromptResponseRelatedDetailFromJSON(
  json: any
): PromptResponseRelatedDetail {
  return PromptResponseRelatedDetailFromJSONTyped(json, false);
}

export function PromptResponseRelatedDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PromptResponseRelatedDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    meta: json["meta"],
    prompt: !exists(json, "prompt")
      ? undefined
      : PromptDetailFromJSON(json["prompt"]),
  };
}

export function PromptResponseRelatedDetailToJSON(
  value?: PromptResponseRelatedDetail | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    meta: value.meta,
    prompt: PromptDetailToJSON(value.prompt),
  };
}
