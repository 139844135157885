export const dateToYMD = (date: Date): string =>
  date.toISOString().substring(0, 10);

export const dateToEnglishMDY = (date: Date): string => {
  const month = date.toLocaleString("default", { month: "short" });
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
};

export const dateFromYMD = (ymd: string): Date => {
  const [year, month, day] = ymd.split("-").map(Number);
  return new Date(year, month - 1, day);
};

export const areSameDates = (date1: Date, date2: Date): boolean =>
  dateToYMD(date1) === dateToYMD(date2);

export const areDatesEquivalent = (
  date1: Date | null,
  date2: Date | null
): boolean => {
  if (date1 === null && date2 === null) {
    return true;
  }
  if (date1 === null || date2 === null) {
    return false;
  }
  return areSameDates(date1, date2);
};

export const isToday = (date: Date): boolean => areSameDates(date, new Date());

export const localeString = (date: Date): string => `${date.toLocaleString()}`;
