import React from "react";
import ShowMore from "react-show-more";
import { clickableText } from "../../util/style";

type MShowMoreTextProps = {
  moreCopy?: string;
  lessCopy?: string;
  content: string;
};

const MShowMoreTextComponent = (props: MShowMoreTextProps) => {
  const { moreCopy, lessCopy, content } = props;
  return (
    <ShowMore
      lines={2}
      more={moreCopy}
      less={lessCopy}
      anchorClass={clickableText}
    >
      {content}
    </ShowMore>
  );
};

MShowMoreTextComponent.defaultProps = {
  moreCopy: "show more",
  lessCopy: "show less",
};

export default MShowMoreTextComponent;
