import React, { ReactElement } from "react";
import {
  ChatBubbleLeftRightIcon,
  PuzzlePieceIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import CenterColumnStickyButtonScreen from "../../ui/screens/CenterColumnStickyButtonScreen";
import { useBusyWatcher } from "../../../util/hooks";
import MBackButton from "../../ui/buttons/MBackButton";
import MButton from "../../ui/buttons/MButton";
import { addNextToUrl, addUserIdToUrl } from "../../../util/url";
import { GetProfileResponse } from "../../../api/models";

type NewQuestionScreenProps = {
  askAboutSelfUrl: string;
  askAboutOtherUrl: string;
  commCoachUrl: string;
  collabUrl: string;
  onBackClicked: () => void;
  aboutUser?: GetProfileResponse | null;
};

const NewQuestionScreenComponent = (props: NewQuestionScreenProps) => {
  const {
    askAboutSelfUrl,
    askAboutOtherUrl,
    commCoachUrl,
    collabUrl,
    onBackClicked,
    aboutUser,
  } = props;

  const [busy, _] = useBusyWatcher();

  const getButtons = (): ReactElement[] => [
    <MBackButton
      kind="secondary"
      key="back-button"
      onClick={onBackClicked}
      disabled={busy}
    />,
  ];

  return (
    <CenterColumnStickyButtonScreen buttons={getButtons()} stacked fullWidth>
      <div className="flex flex-col gap-3 mt-20 items-center">
        {!aboutUser && (
          <MButton
            href={addNextToUrl(askAboutSelfUrl)}
            disabled={busy}
            kind="primary"
            icon={<UserIcon className="h-5 w-5" aria-hidden />}
          >
            ask about yourself
          </MButton>
        )}
        <MButton
          href={
            aboutUser
              ? addNextToUrl(
                  addUserIdToUrl(askAboutOtherUrl, aboutUser.profile.user.id!)
                )
              : addNextToUrl(askAboutOtherUrl)
          }
          disabled={busy}
          kind="primary"
          icon={<UsersIcon className="h-5 w-5" aria-hidden />}
        >
          {aboutUser
            ? `ask about ${aboutUser.profile.user.name}`
            : "ask about someone else"}
        </MButton>
        <MButton
          href={
            aboutUser
              ? addNextToUrl(
                  addUserIdToUrl(commCoachUrl, aboutUser.profile.user.id!)
                )
              : addNextToUrl(commCoachUrl)
          }
          disabled={busy}
          kind="primary"
          icon={<ChatBubbleLeftRightIcon className="h-5 w-5" aria-hidden />}
        >
          {aboutUser
            ? `plan a conversation w/ ${aboutUser.profile.user.name}`
            : "plan a conversation"}
        </MButton>
        <MButton
          href={
            aboutUser
              ? addNextToUrl(
                  addUserIdToUrl(collabUrl, aboutUser.profile.user.id!)
                )
              : addNextToUrl(collabUrl)
          }
          disabled={busy}
          kind="primary"
          icon={<PuzzlePieceIcon className="h-5 w-5" aria-hidden />}
        >
          {aboutUser
            ? `relationship analysis w/ ${aboutUser.profile.user.name}`
            : "relationship analysis"}
        </MButton>
      </div>
    </CenterColumnStickyButtonScreen>
  );
};

NewQuestionScreenComponent.defaultProps = {
  aboutUser: null,
};

export default NewQuestionScreenComponent;
