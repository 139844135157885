/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CreateGroupRequest
 */
export interface CreateGroupRequest {
  /**
   *
   * @type {string}
   * @memberof CreateGroupRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateGroupRequest
   */
  description?: string | null;
}

export function CreateGroupRequestFromJSON(json: any): CreateGroupRequest {
  return CreateGroupRequestFromJSONTyped(json, false);
}

export function CreateGroupRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateGroupRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    description: !exists(json, "description") ? undefined : json["description"],
  };
}

export function CreateGroupRequestToJSON(
  value?: CreateGroupRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    description: value.description,
  };
}
