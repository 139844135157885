/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CollabQnaEntry,
  CollabQnaEntryFromJSON,
  CollabQnaEntryFromJSONTyped,
  CollabQnaEntryToJSON,
} from "./CollabQnaEntry";
import {
  UserCollabRelatedDetail,
  UserCollabRelatedDetailFromJSON,
  UserCollabRelatedDetailFromJSONTyped,
  UserCollabRelatedDetailToJSON,
} from "./UserCollabRelatedDetail";

/**
 *
 * @export
 * @interface GetLastCollabForUserResponse
 */
export interface GetLastCollabForUserResponse {
  /**
   *
   * @type {boolean}
   * @memberof GetLastCollabForUserResponse
   */
  exist: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetLastCollabForUserResponse
   */
  stale: boolean;
  /**
   *
   * @type {UserCollabRelatedDetail}
   * @memberof GetLastCollabForUserResponse
   */
  collab?: UserCollabRelatedDetail;
  /**
   *
   * @type {Array<CollabQnaEntry>}
   * @memberof GetLastCollabForUserResponse
   */
  content?: Array<CollabQnaEntry> | null;
}

export function GetLastCollabForUserResponseFromJSON(
  json: any
): GetLastCollabForUserResponse {
  return GetLastCollabForUserResponseFromJSONTyped(json, false);
}

export function GetLastCollabForUserResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetLastCollabForUserResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    exist: json["exist"],
    stale: json["stale"],
    collab: !exists(json, "collab")
      ? undefined
      : UserCollabRelatedDetailFromJSON(json["collab"]),
    content: !exists(json, "content")
      ? undefined
      : json["content"] === null
      ? null
      : (json["content"] as Array<any>).map(CollabQnaEntryFromJSON),
  };
}

export function GetLastCollabForUserResponseToJSON(
  value?: GetLastCollabForUserResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    exist: value.exist,
    stale: value.stale,
    collab: UserCollabRelatedDetailToJSON(value.collab),
    content:
      value.content === undefined
        ? undefined
        : value.content === null
        ? null
        : (value.content as Array<any>).map(CollabQnaEntryToJSON),
  };
}
