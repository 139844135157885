import React, { ReactNode } from "react";
import MPlusButton from "../../ui/buttons/MPlusButton";
import { useBusyWatcher } from "../../../util/hooks";
import { useAsyncConfirmationDialog } from "../../dialogs/hooks";
import GroupSummaryCard from "../groups/GroupSummaryCard";
import { ListGroupsResponse, UserGroup } from "../../../api/models";
import LoadingGroupSummaryCard from "../../ui/loading/LoadingGroupSummaryCard";
import SidebarSection from "../../ui/screens/sidebarnav/SidebarSection";
import SidebarCommentRow from "../../ui/screens/sidebarnav/SidebarCommentRow";

type ListGroupsScreenProps = {
  loading: boolean;
  groups: ListGroupsResponse | null;
  createGroupUrl: string;
  viewGroupUrl: string;
  editGroupUrl: string;
  onDeleteGroup: (toDelete: UserGroup) => void;
};

const ListGroupsScreenComponent = (props: ListGroupsScreenProps) => {
  const {
    loading,
    groups,
    createGroupUrl,
    viewGroupUrl,
    editGroupUrl,
    onDeleteGroup: onDeleteGroupInput,
  } = props;

  const confirmDialog = useAsyncConfirmationDialog();
  const [busy, _] = useBusyWatcher();

  const onDeleteGroupClicked = async (toDelete: UserGroup) => {
    const result = await confirmDialog.confirm({
      title: `delete group '${toDelete.name ?? "untitled"}'?`,
      body: "are you sure you want to delete this group? this action cannot be undone.",
    });
    if (!result) {
      return;
    }
    onDeleteGroupInput(toDelete);
  };

  const getGrid = (content: ReactNode): ReactNode => (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 mt-3">{content}</div>
  );

  return (
    <>
      <SidebarSection
        title="my groups"
        divideY={false}
        cta={
          <MPlusButton
            href={createGroupUrl}
            disabled={busy}
            kind="primary"
            text="create new"
          />
        }
      >
        <>
          {loading &&
            getGrid(
              <>
                <LoadingGroupSummaryCard />
                <LoadingGroupSummaryCard />
                <LoadingGroupSummaryCard />
                <LoadingGroupSummaryCard />
                <LoadingGroupSummaryCard />
                <LoadingGroupSummaryCard />
              </>
            )}
          {!loading &&
            groups &&
            groups.groups.length > 0 &&
            getGrid(
              groups.groups.map((group) => (
                <GroupSummaryCard
                  key={group.guid!}
                  group={group}
                  viewGroupUrl={viewGroupUrl}
                  editGroupUrl={editGroupUrl}
                  onDeleteClicked={() => onDeleteGroupClicked(group)}
                  hideDeleteButton={false}
                  hideLeaveButton={false}
                />
              ))
            )}
          {!loading && (!groups || groups.groups.length === 0) && (
            <SidebarCommentRow comment="you haven't created any groups yet. create one now!" />
          )}
        </>
      </SidebarSection>
      {confirmDialog.dialog}
    </>
  );
};

export default ListGroupsScreenComponent;
