/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  AIQuestionAndResponseDetailSerializer,
  AIQuestionAndResponseDetailSerializerFromJSON,
  AIQuestionAndResponseDetailSerializerToJSON,
  AnonymizeQuestionRequest,
  AnonymizeQuestionRequestFromJSON,
  AnonymizeQuestionRequestToJSON,
  AskUserQuestionRequest,
  AskUserQuestionRequestFromJSON,
  AskUserQuestionRequestToJSON,
  AskUserQuestionResponseSerializer,
  AskUserQuestionResponseSerializerFromJSON,
  AskUserQuestionResponseSerializerToJSON,
  ClearPromptResponseRequest,
  ClearPromptResponseRequestFromJSON,
  ClearPromptResponseRequestToJSON,
  DeleteQuestionRequest,
  DeleteQuestionRequestFromJSON,
  DeleteQuestionRequestToJSON,
  EmptyResponseWrapper,
  EmptyResponseWrapperFromJSON,
  EmptyResponseWrapperToJSON,
  GetAnsweredPromptDetailsResponseSerializer,
  GetAnsweredPromptDetailsResponseSerializerFromJSON,
  GetAnsweredPromptDetailsResponseSerializerToJSON,
  GetPromptResponseHistoryResponseSerializer,
  GetPromptResponseHistoryResponseSerializerFromJSON,
  GetPromptResponseHistoryResponseSerializerToJSON,
  GetQuestionnaireWithResponsesResponseSerializer,
  GetQuestionnaireWithResponsesResponseSerializerFromJSON,
  GetQuestionnaireWithResponsesResponseSerializerToJSON,
  ListQuestionnairesResponseSerializer,
  ListQuestionnairesResponseSerializerFromJSON,
  ListQuestionnairesResponseSerializerToJSON,
  MarkQuestionnaireAsCompletedRequest,
  MarkQuestionnaireAsCompletedRequestFromJSON,
  MarkQuestionnaireAsCompletedRequestToJSON,
  PromptContent,
  PromptContentFromJSON,
  PromptContentToJSON,
  PromptRelatedDetailSerializer,
  PromptRelatedDetailSerializerFromJSON,
  PromptRelatedDetailSerializerToJSON,
  PublishablePromptWithResponseSerializer,
  PublishablePromptWithResponseSerializerFromJSON,
  PublishablePromptWithResponseSerializerToJSON,
  QuestionOperationsResponseSerializer,
  QuestionOperationsResponseSerializerFromJSON,
  QuestionOperationsResponseSerializerToJSON,
  SearchForQuestionsResponseSerializer,
  SearchForQuestionsResponseSerializerFromJSON,
  SearchForQuestionsResponseSerializerToJSON,
  SearchValidTagsResponseSerializer,
  SearchValidTagsResponseSerializerFromJSON,
  SearchValidTagsResponseSerializerToJSON,
  SubmitPromptResponseRequest,
  SubmitPromptResponseRequestFromJSON,
  SubmitPromptResponseRequestToJSON,
  UpdateQuestionRequest,
  UpdateQuestionRequestFromJSON,
  UpdateQuestionRequestToJSON,
  VoteOnQuestionRequest,
  VoteOnQuestionRequestFromJSON,
  VoteOnQuestionRequestToJSON,
} from "../models";

export interface AnonymizeQuestionOperationRequest {
  data: AnonymizeQuestionRequest;
}

export interface AskUserQuestionOperationRequest {
  data: AskUserQuestionRequest;
}

export interface ClearPromptResponseOperationRequest {
  data: ClearPromptResponseRequest;
}

export interface CreateQuestionRequest {
  data: PromptContent;
}

export interface DeleteQuestionOperationRequest {
  data: DeleteQuestionRequest;
}

export interface GetPromptRequest {
  prompt: string;
}

export interface GetPromptResponseHistoryRequest {
  prompt: string;
}

export interface GetPromptWithResponseRequest {
  prompt: string;
  presentation_layer?: string | null;
}

export interface GetQnrRequest {
  qnr: string;
}

export interface GetQuestionnaireRequest {
  questionnaire: string;
  presentation_layer?: string | null;
}

export interface ListResponseDetailsRequest {
  presentation?: string | null;
}

export interface MarkQuestionnaireAsCompletedOperationRequest {
  data: MarkQuestionnaireAsCompletedRequest;
}

export interface QuestionOperationsRequest {
  prompt: string;
}

export interface RespondToPromptRequest {
  data: SubmitPromptResponseRequest;
}

export interface SearchForQuestionsRequest {
  offset?: number;
  limit?: number;
  search_term?: string | null;
  author?: string | null;
  tags?: Array<string>;
  self_only?: boolean;
}

export interface SearchValidQuestionTagsRequest {
  search_term?: string | null;
}

export interface UpdateQuestionOperationRequest {
  data: UpdateQuestionRequest;
}

export interface VoteOnQuestionOperationRequest {
  data: VoteOnQuestionRequest;
}

/**
 *
 */
export class QuestionsApi extends runtime.BaseAPI {
  /**
   * Anonymize the given question.
   */
  async anonymizeQuestionRaw(
    requestParameters: AnonymizeQuestionOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling anonymizeQuestion."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/questions/creation/anonymize`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: AnonymizeQuestionRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Anonymize the given question.
   */
  async anonymizeQuestion(
    requestParameters: AnonymizeQuestionOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.anonymizeQuestionRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Ask the requesting user a question.
   */
  async askUserQuestionRaw(
    requestParameters: AskUserQuestionOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<AskUserQuestionResponseSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling askUserQuestion."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/questions/ask-about-user`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: AskUserQuestionRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AskUserQuestionResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Ask the requesting user a question.
   */
  async askUserQuestion(
    requestParameters: AskUserQuestionOperationRequest,
    initOverrides?: RequestInit
  ): Promise<AskUserQuestionResponseSerializer> {
    const response = await this.askUserQuestionRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Clear the existing response for the requesting user and the specified prompt.
   */
  async clearPromptResponseRaw(
    requestParameters: ClearPromptResponseOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling clearPromptResponse."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/questions/clear-response`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ClearPromptResponseRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Clear the existing response for the requesting user and the specified prompt.
   */
  async clearPromptResponse(
    requestParameters: ClearPromptResponseOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.clearPromptResponseRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create a new prompt for the given user.
   */
  async createQuestionRaw(
    requestParameters: CreateQuestionRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PromptRelatedDetailSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling createQuestion."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/questions/creation/create`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PromptContentToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PromptRelatedDetailSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Create a new prompt for the given user.
   */
  async createQuestion(
    requestParameters: CreateQuestionRequest,
    initOverrides?: RequestInit
  ): Promise<PromptRelatedDetailSerializer> {
    const response = await this.createQuestionRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Delete the given question.
   */
  async deleteQuestionRaw(
    requestParameters: DeleteQuestionOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling deleteQuestion."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/questions/creation/delete`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: DeleteQuestionRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Delete the given question.
   */
  async deleteQuestion(
    requestParameters: DeleteQuestionOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.deleteQuestionRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get the specified prompt if it exists.
   */
  async getPromptRaw(
    requestParameters: GetPromptRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PromptRelatedDetailSerializer>> {
    if (
      requestParameters.prompt === null ||
      requestParameters.prompt === undefined
    ) {
      throw new runtime.RequiredError(
        "prompt",
        "Required parameter requestParameters.prompt was null or undefined when calling getPrompt."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.prompt !== undefined) {
      queryParameters["prompt"] = requestParameters.prompt;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/questions/get-prompt`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PromptRelatedDetailSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the specified prompt if it exists.
   */
  async getPrompt(
    requestParameters: GetPromptRequest,
    initOverrides?: RequestInit
  ): Promise<PromptRelatedDetailSerializer> {
    const response = await this.getPromptRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all of the responses that the requesting user has submitted for the referenced prompt. If the user has not answered the prompt yet then return a 404.
   */
  async getPromptResponseHistoryRaw(
    requestParameters: GetPromptResponseHistoryRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetPromptResponseHistoryResponseSerializer>> {
    if (
      requestParameters.prompt === null ||
      requestParameters.prompt === undefined
    ) {
      throw new runtime.RequiredError(
        "prompt",
        "Required parameter requestParameters.prompt was null or undefined when calling getPromptResponseHistory."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.prompt !== undefined) {
      queryParameters["prompt"] = requestParameters.prompt;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/questions/get-prompt-response-history`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetPromptResponseHistoryResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get all of the responses that the requesting user has submitted for the referenced prompt. If the user has not answered the prompt yet then return a 404.
   */
  async getPromptResponseHistory(
    requestParameters: GetPromptResponseHistoryRequest,
    initOverrides?: RequestInit
  ): Promise<GetPromptResponseHistoryResponseSerializer> {
    const response = await this.getPromptResponseHistoryRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get the specified prompt if it exists. Also get the requesting user\'s response to that request, if it exists.
   */
  async getPromptWithResponseRaw(
    requestParameters: GetPromptWithResponseRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PublishablePromptWithResponseSerializer>> {
    if (
      requestParameters.prompt === null ||
      requestParameters.prompt === undefined
    ) {
      throw new runtime.RequiredError(
        "prompt",
        "Required parameter requestParameters.prompt was null or undefined when calling getPromptWithResponse."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.prompt !== undefined) {
      queryParameters["prompt"] = requestParameters.prompt;
    }

    if (requestParameters.presentation_layer !== undefined) {
      queryParameters["presentation_layer"] =
        requestParameters.presentation_layer;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/questions/get-prompt-with-response`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PublishablePromptWithResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the specified prompt if it exists. Also get the requesting user\'s response to that request, if it exists.
   */
  async getPromptWithResponse(
    requestParameters: GetPromptWithResponseRequest,
    initOverrides?: RequestInit
  ): Promise<PublishablePromptWithResponseSerializer> {
    const response = await this.getPromptWithResponseRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get a specific QuestionAndResponse by its ID if the requesting user has permissions to see it.
   */
  async getQnrRaw(
    requestParameters: GetQnrRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<AIQuestionAndResponseDetailSerializer>> {
    if (requestParameters.qnr === null || requestParameters.qnr === undefined) {
      throw new runtime.RequiredError(
        "qnr",
        "Required parameter requestParameters.qnr was null or undefined when calling getQnr."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.qnr !== undefined) {
      queryParameters["qnr"] = requestParameters.qnr;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/questions/get-qnr`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AIQuestionAndResponseDetailSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get a specific QuestionAndResponse by its ID if the requesting user has permissions to see it.
   */
  async getQnr(
    requestParameters: GetQnrRequest,
    initOverrides?: RequestInit
  ): Promise<AIQuestionAndResponseDetailSerializer> {
    const response = await this.getQnrRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get a list of questions and corresponding active responses for each for the authenticated user.
   */
  async getQuestionnaireRaw(
    requestParameters: GetQuestionnaireRequest,
    initOverrides?: RequestInit
  ): Promise<
    runtime.ApiResponse<GetQuestionnaireWithResponsesResponseSerializer>
  > {
    if (
      requestParameters.questionnaire === null ||
      requestParameters.questionnaire === undefined
    ) {
      throw new runtime.RequiredError(
        "questionnaire",
        "Required parameter requestParameters.questionnaire was null or undefined when calling getQuestionnaire."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.questionnaire !== undefined) {
      queryParameters["questionnaire"] = requestParameters.questionnaire;
    }

    if (requestParameters.presentation_layer !== undefined) {
      queryParameters["presentation_layer"] =
        requestParameters.presentation_layer;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/questions/get-questionnaire`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetQuestionnaireWithResponsesResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get a list of questions and corresponding active responses for each for the authenticated user.
   */
  async getQuestionnaire(
    requestParameters: GetQuestionnaireRequest,
    initOverrides?: RequestInit
  ): Promise<GetQuestionnaireWithResponsesResponseSerializer> {
    const response = await this.getQuestionnaireRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get a list of all questionnaires and whether or not the requesting user has completed them.
   */
  async listQuestionnairesRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ListQuestionnairesResponseSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/questions/list-questionnaires`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ListQuestionnairesResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get a list of all questionnaires and whether or not the requesting user has completed them.
   */
  async listQuestionnaires(
    initOverrides?: RequestInit
  ): Promise<ListQuestionnairesResponseSerializer> {
    const response = await this.listQuestionnairesRaw(initOverrides);
    return await response.value();
  }

  /**
   * List details about all the prompts that the user has responded to in the specified presentation layer. If no presentation layer is specified then use the user\'s default layer.
   */
  async listResponseDetailsRaw(
    requestParameters: ListResponseDetailsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetAnsweredPromptDetailsResponseSerializer>> {
    const queryParameters: any = {};

    if (requestParameters.presentation !== undefined) {
      queryParameters["presentation"] = requestParameters.presentation;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/questions/list-response-details`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetAnsweredPromptDetailsResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * List details about all the prompts that the user has responded to in the specified presentation layer. If no presentation layer is specified then use the user\'s default layer.
   */
  async listResponseDetails(
    requestParameters: ListResponseDetailsRequest = {},
    initOverrides?: RequestInit
  ): Promise<GetAnsweredPromptDetailsResponseSerializer> {
    const response = await this.listResponseDetailsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Mark the referenced questionnaire as completed by the requesting user.
   */
  async markQuestionnaireAsCompletedRaw(
    requestParameters: MarkQuestionnaireAsCompletedOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling markQuestionnaireAsCompleted."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/questions/mark-as-completed`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: MarkQuestionnaireAsCompletedRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Mark the referenced questionnaire as completed by the requesting user.
   */
  async markQuestionnaireAsCompleted(
    requestParameters: MarkQuestionnaireAsCompletedOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.markQuestionnaireAsCompletedRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Check if the given user can delete the given question.
   */
  async questionOperationsRaw(
    requestParameters: QuestionOperationsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<QuestionOperationsResponseSerializer>> {
    if (
      requestParameters.prompt === null ||
      requestParameters.prompt === undefined
    ) {
      throw new runtime.RequiredError(
        "prompt",
        "Required parameter requestParameters.prompt was null or undefined when calling questionOperations."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.prompt !== undefined) {
      queryParameters["prompt"] = requestParameters.prompt;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/questions/creation/operations`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      QuestionOperationsResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Check if the given user can delete the given question.
   */
  async questionOperations(
    requestParameters: QuestionOperationsRequest,
    initOverrides?: RequestInit
  ): Promise<QuestionOperationsResponseSerializer> {
    const response = await this.questionOperationsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Accept and save a response for the given prompt.
   */
  async respondToPromptRaw(
    requestParameters: RespondToPromptRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling respondToPrompt."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/questions/respond-to-prompt`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SubmitPromptResponseRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Accept and save a response for the given prompt.
   */
  async respondToPrompt(
    requestParameters: RespondToPromptRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.respondToPromptRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Search for questions based on search term, with filter by author and tags option.
   */
  async searchForQuestionsRaw(
    requestParameters: SearchForQuestionsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<SearchForQuestionsResponseSerializer>> {
    const queryParameters: any = {};

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.search_term !== undefined) {
      queryParameters["search_term"] = requestParameters.search_term;
    }

    if (requestParameters.author !== undefined) {
      queryParameters["author"] = requestParameters.author;
    }

    if (requestParameters.tags) {
      queryParameters["tags"] = requestParameters.tags.join(
        runtime.COLLECTION_FORMATS["csv"]
      );
    }

    if (requestParameters.self_only !== undefined) {
      queryParameters["self_only"] = requestParameters.self_only;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/questions/search`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SearchForQuestionsResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Search for questions based on search term, with filter by author and tags option.
   */
  async searchForQuestions(
    requestParameters: SearchForQuestionsRequest = {},
    initOverrides?: RequestInit
  ): Promise<SearchForQuestionsResponseSerializer> {
    const response = await this.searchForQuestionsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Search for valid tags based on the search term.
   */
  async searchValidQuestionTagsRaw(
    requestParameters: SearchValidQuestionTagsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<SearchValidTagsResponseSerializer>> {
    const queryParameters: any = {};

    if (requestParameters.search_term !== undefined) {
      queryParameters["search_term"] = requestParameters.search_term;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/questions/search-tags`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SearchValidTagsResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Search for valid tags based on the search term.
   */
  async searchValidQuestionTags(
    requestParameters: SearchValidQuestionTagsRequest = {},
    initOverrides?: RequestInit
  ): Promise<SearchValidTagsResponseSerializer> {
    const response = await this.searchValidQuestionTagsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update the given question.
   */
  async updateQuestionRaw(
    requestParameters: UpdateQuestionOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PromptRelatedDetailSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling updateQuestion."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/questions/creation/update`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateQuestionRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PromptRelatedDetailSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Update the given question.
   */
  async updateQuestion(
    requestParameters: UpdateQuestionOperationRequest,
    initOverrides?: RequestInit
  ): Promise<PromptRelatedDetailSerializer> {
    const response = await this.updateQuestionRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Vote on the given question.
   */
  async voteOnQuestionRaw(
    requestParameters: VoteOnQuestionOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling voteOnQuestion."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/questions/vote`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: VoteOnQuestionRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Vote on the given question.
   */
  async voteOnQuestion(
    requestParameters: VoteOnQuestionOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.voteOnQuestionRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
