import React, { useState } from "react";
import { EmployerRoleDetail } from "../../../../api/models";
import FormScreen from "../../../ui/screens/FormScreen";
import MInput from "../../../ui/MInput";
import { useBusyWatcher } from "../../../../util/hooks";
import MTextArea from "../../../ui/MTextArea";
import MDateInput from "../../../ui/MDateInput";

export type EmployerRoleEdit = {
  title: string;
  responsibilities?: string | null;
  impact?: string | null;
  date_started: Date;
  date_ended?: Date;
};

type EditEmployerRoleScreenProps = {
  role?: EmployerRoleDetail | null;
  errors: { [key: string]: string[] };
  onSaveClicked: (employer: EmployerRoleEdit) => void;
  onBackClicked: () => void;
};

const EditEmloyerRoleScreenComponent = (props: EditEmployerRoleScreenProps) => {
  const { role, errors, onSaveClicked, onBackClicked } = props;

  const [title, setTitle] = useState<string>(role?.title ?? "");
  const [responsibilities, setResponsibilities] = useState<string>(
    role?.responsibilities ?? ""
  );
  const [impact, setImpact] = useState<string>(role?.impact ?? "");
  const [dateStarted, setDateStarted] = useState<Date | null>(
    role?.date_started ?? null
  );
  const [dateEnded, setDateEnded] = useState<Date | null>(
    role?.date_ended ?? null
  );
  const [busy, _] = useBusyWatcher();

  const canSubmit = (): boolean => {
    if (title.length > 128 || title.length === 0) {
      return false;
    }
    if (responsibilities.length > 2048) {
      return false;
    }
    if (impact.length > 2048) {
      return false;
    }
    if (dateStarted === null) {
      return false;
    }
    return true;
  };

  const submit = () => {
    const toEmit: EmployerRoleEdit = {
      title,
      date_started: dateStarted!,
    };
    if (responsibilities.length > 0) {
      toEmit.responsibilities = responsibilities;
    }
    if (impact.length > 0) {
      toEmit.impact = impact;
    }
    if (dateEnded !== null) {
      toEmit.date_ended = dateEnded;
    }
    onSaveClicked(toEmit);
  };

  return (
    <FormScreen
      title={role ? "edit role" : "add role"}
      onNextClicked={submit}
      nextDisabled={busy || !canSubmit()}
      onBackClicked={onBackClicked}
      backDisabled={busy}
      nextText="save role"
      fullWidth
    >
      <MInput
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        onEnterPressed={() => canSubmit() && submit()}
        disabled={busy}
        errors={errors.title}
        label="Role Title"
        placeholder="ex: Product Manager II"
        required
      />
      <MTextArea
        value={responsibilities}
        onChange={(e) => setResponsibilities(e.target.value)}
        disabled={busy}
        errors={errors.responsibilities}
        label="Responsibilities"
        placeholder="ex: I was responsible for managing the product roadmap and working with the engineering team to deliver on it."
      />
      <MTextArea
        value={impact}
        onChange={(e) => setImpact(e.target.value)}
        disabled={busy}
        errors={errors.impact}
        label="Impact"
        placeholder="ex: I was able to deliver a new feature that increased our revenue by 10%."
      />
      <MDateInput
        label="Start Date"
        dateValue={dateStarted}
        onDateChanged={setDateStarted}
        errors={errors.date_started}
        disabled={busy}
        required
      />
      <MDateInput
        label="End Date"
        dateValue={dateEnded}
        onDateChanged={setDateEnded}
        errors={errors.date_ended}
        disabled={busy}
      />
    </FormScreen>
  );
};

EditEmloyerRoleScreenComponent.defaultProps = {
  role: null,
};

export default EditEmloyerRoleScreenComponent;
