import React, { ReactNode } from "react";
import MTooltipIcon from "../../MTooltipIcon";
import { classNames } from "../../../../util/strings";

type SidebarLabelRowProps = {
  label?: string | null;
  tooltip?: string | null;
  middle?: ReactNode | null;
  end: ReactNode;
};

const SidebarLabelRowComponent = (props: SidebarLabelRowProps) => {
  const { label, tooltip, middle, end } = props;

  return (
    <div
      className={classNames(
        "pt-4 items-center",
        middle === null ? "flex" : "sm:flex"
      )}
    >
      <dt className="grow font-semibold text-m-dark-gray sm:w-64 sm:flex-none sm:pr-6 flex flex-row gap-2 items-center">
        {label}
        {tooltip && <MTooltipIcon tooltip={tooltip} />}
      </dt>
      <dd
        className={classNames(
          "mt-1 flex items-center gap-x-6 sm:mt-0 sm:flex-auto",
          middle === null ? "justify-end" : "justify-between"
        )}
      >
        {middle}
        {end}
      </dd>
    </div>
  );
};

SidebarLabelRowComponent.defaultProps = {
  middle: null,
  tooltip: null,
  label: null,
};

export default SidebarLabelRowComponent;
