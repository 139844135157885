import React, { useEffect, useState } from "react";
import { PromptDisplayCardProps } from "./PromptDisplayCard";
import { FillInBlankPromptResponseMeta } from "../../../../api/types";
import EmptyPromptResponseContent from "./EmptyPromptResponseContent";
import MInsetTitleCard from "../MInsetTitleCard";

const FillInBlankDisplayCardComponent = (props: PromptDisplayCardProps) => {
  const { prompt, ...rest } = props;

  const [title, setTitle] = useState<string>("");
  const [body, setBody] = useState<string>("");

  useEffect(() => {
    setTitle(prompt.prompt.statement);
    if (prompt.response) {
      const responseMeta = prompt.response!
        .meta as FillInBlankPromptResponseMeta;
      setBody(responseMeta.response);
    } else {
      setBody("");
    }
  }, [prompt.prompt.guid, prompt.response?.meta]);

  return (
    <MInsetTitleCard
      type={body !== "" ? "regular" : "warning"}
      title={title}
      {...rest}
    >
      {body !== "" ? (
        <div className="whitespace-pre-line">{body}</div>
      ) : (
        <EmptyPromptResponseContent />
      )}
    </MInsetTitleCard>
  );
};

export default FillInBlankDisplayCardComponent;
