import React from "react";
import {
  HandThumbDownIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";
import MWellButton from "./wells/MWellButton";
import { CommonWellButtonProps } from "./wells/common";

type MThumbsUpOrDownButtonsProps = CommonWellButtonProps & {
  label: string;
  onThumbsUpClick: () => void;
  onThumbsDownClick: () => void;
};

const MThumbsUpOrDownButtons = (props: MThumbsUpOrDownButtonsProps) => {
  const { label, onThumbsUpClick, onThumbsDownClick, ...rest } = props;

  return (
    <div className="flex flex-col gap-2">
      <span className="text-sm text-m-dark-gray">{label}</span>
      <div className="flex flex-row gap-8 justify-center">
        <MWellButton
          {...rest}
          onClick={onThumbsDownClick}
          icon={
            <HandThumbDownIcon
              className="h-10 w-10 flex-none active:fill-primary hover:brightness-110 focus:outline-none focus:ring focus:ring-primary focus:fill-primary"
              aria-hidden="true"
            />
          }
        />
        <MWellButton
          {...rest}
          onClick={onThumbsUpClick}
          icon={
            <HandThumbUpIcon
              className="h-10 w-10 flex-none active:fill-primary hover:brightness-110 focus:outline-none focus:ring focus:ring-primary focus:fill-primary"
              aria-hidden="true"
            />
          }
        />
      </div>
    </div>
  );
};

export default MThumbsUpOrDownButtons;
