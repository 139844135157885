import React from "react";
import { classNames } from "../../../util/strings";
import { paragraphText, titleText } from "../../../util/style";
import CenterColumnScreen from "./CenterColumnScreen";
import MNextButton from "../buttons/MNextButton";

type MessageScreenProps = {
  title?: string;
  content: string;
  nextButtonText?: string;
  nextDisabled?: boolean;
  onNextClicked?: () => void;
  href?: string;
};

const MessageScreenComponent = (props: MessageScreenProps) => {
  const { title, content, nextButtonText, nextDisabled, onNextClicked, href } =
    props;

  return (
    <CenterColumnScreen className="gap-6">
      <h2 className={classNames("text-center", titleText)}>{title}</h2>
      <div className={classNames("text-center", paragraphText)}>{content}</div>
      <div className="grow sm:hidden" />
      <MNextButton
        onClick={onNextClicked}
        href={href}
        kind="primary"
        disabled={nextDisabled}
        text={nextButtonText!}
      />
    </CenterColumnScreen>
  );
};

MessageScreenComponent.defaultProps = {
  title: "welcome!",
  nextButtonText: "next",
  nextDisabled: false,
  onNextClicked: undefined,
  href: undefined,
};

export default MessageScreenComponent;
