import React, { ReactElement } from "react";
import { GetAssessmentsResponse } from "../../../api/models";
import MBackButton from "../../ui/buttons/MBackButton";
import { useBusyWatcher } from "../../../util/hooks";
import CenterColumnStickyButtonScreen from "../../ui/screens/CenterColumnStickyButtonScreen";
import MTitle from "../../ui/MTitle";
import AssessmentCard from "./AssessmentCard";
import { retainNextWithUrl } from "../../../util/url";
import { commentText } from "../../../util/style";
import { classNames } from "../../../util/strings";

type ListAssessmentsScreenProps = {
  assessments: GetAssessmentsResponse;
  assessmentUrl: string;
  onBackClicked: () => void;
};

const ListAssessmentsScreenComponent = (props: ListAssessmentsScreenProps) => {
  const { assessments, assessmentUrl, onBackClicked } = props;

  const [busy, _] = useBusyWatcher();

  const getButtons = (): ReactElement[] => [
    <MBackButton
      kind="secondary"
      onClick={onBackClicked}
      disabled={busy}
      key="back-button"
    />,
  ];

  return (
    <CenterColumnStickyButtonScreen buttons={getButtons()} stacked fullWidth>
      <MTitle text="assessments" className="text-center mb-5" />
      <div className="flex flex-col gap-3">
        {assessments.assessments.map((assessment) => (
          <AssessmentCard
            key={assessment.assessment.guid!}
            assessment={assessment}
            editUrl={retainNextWithUrl(
              `${assessmentUrl}?assessment=${assessment.assessment.guid}`
            )}
          />
        ))}
      </div>
      {assessments.assessments.length === 0 && (
        <div className={classNames(commentText, "text-center")}>
          no assessments available as this time. please check back later!
        </div>
      )}
    </CenterColumnStickyButtonScreen>
  );
};

export default ListAssessmentsScreenComponent;
