import React, { ReactElement } from "react";
import { GetCollabByIdResponse } from "../../../api/models";
import MBackButton from "../../ui/buttons/MBackButton";
import { useBusyWatcher } from "../../../util/hooks";
import CenterColumnStickyButtonScreen from "../../ui/screens/CenterColumnStickyButtonScreen";
import UserCollabCard from "./UserCollabCard";
import MMarkdown from "../../ui/MMarkdown";
import MNextButton from "../../ui/buttons/MNextButton";

type ViewCollabScreenProps = {
  collab: GetCollabByIdResponse;
  onBackClicked?: (() => void) | null;
  onDoneClicked?: (() => void) | null;
};

const ViewCollabScreenComponent = (props: ViewCollabScreenProps) => {
  const { collab, onBackClicked, onDoneClicked } = props;

  const [busy, _] = useBusyWatcher();

  const getButtons = (): ReactElement[] => {
    const toReturn: ReactElement[] = [];
    if (onDoneClicked) {
      toReturn.push(
        <MNextButton
          kind="primary"
          key="done-button"
          onClick={onDoneClicked}
          disabled={busy}
          text="done"
        />
      );
    }
    if (onBackClicked) {
      toReturn.push(
        <MBackButton
          kind="secondary"
          key="back-button"
          onClick={onBackClicked}
          disabled={busy}
        />
      );
    }
    return toReturn;
  };

  return (
    <CenterColumnStickyButtonScreen buttons={getButtons()} stacked fullWidth>
      <div className="flex flex-col gap-5">
        <UserCollabCard collab={collab.collab} />
        {collab.content!.map((content) => (
          <div key={content.question}>
            <div className="font-bold mb-1">{content.question}</div>
            <MMarkdown content={content.answer} />
          </div>
        ))}
      </div>
    </CenterColumnStickyButtonScreen>
  );
};

ViewCollabScreenComponent.defaultProps = {
  onBackClicked: null,
  onDoneClicked: null,
};

export default ViewCollabScreenComponent;
