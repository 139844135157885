/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  EmptyResponseWrapper,
  EmptyResponseWrapperFromJSON,
  EmptyResponseWrapperToJSON,
  GetSubscriptionResponseSerializer,
  GetSubscriptionResponseSerializerFromJSON,
  GetSubscriptionResponseSerializerToJSON,
  GetSubscriptionTiersResponseSerializer,
  GetSubscriptionTiersResponseSerializerFromJSON,
  GetSubscriptionTiersResponseSerializerToJSON,
} from "../models";

/**
 *
 */
export class StripeApi extends runtime.BaseAPI {
  /**
   * Get the subscription that the authenticated user is currently on.
   */
  async getStripeSubscriptionRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetSubscriptionResponseSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/stripe/get-subscription`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetSubscriptionResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the subscription that the authenticated user is currently on.
   */
  async getStripeSubscription(
    initOverrides?: RequestInit
  ): Promise<GetSubscriptionResponseSerializer> {
    const response = await this.getStripeSubscriptionRaw(initOverrides);
    return await response.value();
  }

  /**
   * Get all the subscription tiers that are available for the authenticated user.
   */
  async getStripeSubscriptionTiersRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetSubscriptionTiersResponseSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/stripe/list-subscriptions`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetSubscriptionTiersResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get all the subscription tiers that are available for the authenticated user.
   */
  async getStripeSubscriptionTiers(
    initOverrides?: RequestInit
  ): Promise<GetSubscriptionTiersResponseSerializer> {
    const response = await this.getStripeSubscriptionTiersRaw(initOverrides);
    return await response.value();
  }
}
