import React, { ReactNode } from "react";
import NotificationBox from "./NotificationBox";
import MButton from "../buttons/MButton";
import { useBusyWatcher } from "../../../util/hooks";
import NotificationUndoBox from "./NotificationUndoBox";
import { CommonNotificationProps } from "./common";

type ConnectionRequestNotificationBoxProps = CommonNotificationProps & {
  onAcceptClicked: () => void;
  onRejectClicked: () => void;
  isAccepted: boolean;
};

const ConnectionRequestNotificationBoxComponent = (
  props: ConnectionRequestNotificationBoxProps
) => {
  const {
    notif,
    isCleared,
    isAccepted,
    onClearClicked,
    onAcceptClicked,
    onRejectClicked,
  } = props;

  const [busy, _] = useBusyWatcher();

  const getMessage = (): string =>
    `# request from ${notif.connection_request!.sending_user!.name}\n[${
      notif.connection_request!.sending_user!.name
    }](${notif.connection_request!.sending_user
      .profile_url!}) wants to be added to your people.`;

  const getFooter = (): ReactNode => (
    <>
      <MButton kind="primary" disabled={busy} onClick={onAcceptClicked!}>
        accept
      </MButton>
      <MButton kind="secondary" disabled={busy} onClick={onRejectClicked!}>
        ignore
      </MButton>
    </>
  );

  if (isCleared) {
    if (isAccepted) {
      return (
        <NotificationUndoBox
          text={`[${
            notif.connection_request!.sending_user!.name
          }](${notif.connection_request!.sending_user
            .profile_url!}) was added to your people`}
          onClearClicked={onClearClicked}
        />
      );
    }
    return (
      <NotificationUndoBox
        text="request ignored"
        onClearClicked={onClearClicked}
      />
    );
  }

  return (
    <NotificationBox
      body={getMessage()}
      footer={getFooter()}
      isNew={!notif.is_seen}
      imageHref={notif.image_url}
      timeCreated={notif.time_created!}
    />
  );
};

export default ConnectionRequestNotificationBoxComponent;
