import React from "react";
import { GetAssessmentsAssessment } from "../../../api/models";
import MCard from "../../ui/cards/MCard";
import MChecked from "../../ui/MChecked";
import MButton from "../../ui/buttons/MButton";

type AssessmentCardProps = {
  assessment: GetAssessmentsAssessment;
  editUrl: string;
};

const AssessmentCardComponent = (props: AssessmentCardProps) => {
  const { assessment, editUrl } = props;

  return (
    <MCard
      header={
        <div className="flex flex-row items-center">
          <div className="grow">{assessment.assessment.title}</div>
          <MChecked checked={assessment.is_completed} className="w-6 h-6" />
        </div>
      }
    >
      {assessment.assessment.description}
      <MButton
        href={editUrl}
        kind={assessment.is_completed ? "secondary" : "primary"}
        className="w-full mt-5"
      >
        {assessment.is_completed ? "re-take assessment" : "take assessment"}
      </MButton>
    </MCard>
  );
};

export default AssessmentCardComponent;
