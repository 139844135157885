import React from "react";
import "./index.scss";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { ToastContainer } from "react-toastify";
import posthog, { PostHog } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import reportWebVitals from "./reportWebVitals";
import connector from "./api/connector";
import MagicLinkNotFoundPage from "./components/pages/MagicLinkNotFoundPage";
import AskUserQuestionPage from "./components/pages/admin/AskUserQuestionPage";
import AskGroupQuestionPage from "./components/pages/admin/AskGroupQuestionPage";
import MessagePage from "./components/pages/MessagePage";
import MarkdownPage from "./components/pages/MarkdownPage";
import AssessmentPage from "./components/pages/assessment/AssessmentPage";
import LoginPage from "./components/pages/LoginPage";
import MNav from "./components/ui/nav/MNav";
import OnboardingV2Page from "./components/pages/onboarding_v2/OnboardingV2Page";
import { GlobalContextProvider } from "./util/context";
import ProfileV2Page from "./components/pages/profile_v2/ProfileV2Page";
import QuestionnairePage from "./components/pages/questionnaire/QuestionnairePage";
import QuestionSearchPage from "./components/pages/questions/QuestionSearchPage";
import VitalsPage from "./components/pages/profile_v2/vitals/VitalsPage";
import LinksPage from "./components/pages/profile_v2/links/LinksPage";
import WorkHistoryPage from "./components/pages/profile_v2/work_history/WorkHistoryPage";
import ProfilePicturePage from "./components/pages/profile_picture/ProfilePicturePage";
import BioPage from "./components/pages/profile_v2/bio/BioPage";
import BasicsPage from "./components/pages/profile_v2/basics/BasicsPage";
import QuestionsPage from "./components/pages/questions/QuestionsPage";
import InsightsPage from "./components/pages/insights/InsightsPage";
import AskQuestionPage from "./components/pages/ask_question/AskQuestionPage";
import CreateCollabPage from "./components/pages/create_collab/CreateCollabPage";
import AskCommCoachPage from "./components/pages/ask_comm_coach/AskCommCoachPage";
import SettingsPage from "./components/pages/settings/SettingsPage";
import CreateGlimpsePage from "./components/pages/glimpse/create/CreateGlimpsePage";
import ListGlimpsesPage from "./components/pages/glimpse/list/ListGlimpsesPage";
import ViewGlimpsePage from "./components/pages/glimpse/view/ViewGlimpsePage";
import EditGlimpsePage from "./components/pages/glimpse/edit/EditGlimpsePage";
import AttachmentsPage from "./components/pages/profile_v2/attachments/AttachmentsPage";
import CreateGroupPage from "./components/pages/groups/create/CreateGroupPage";
import EditGroupPage from "./components/pages/groups/edit/EditGroupPage";
import ViewGroupPage from "./components/pages/groups/view/ViewGroupPage";
import MyPeoplePage from "./components/pages/people/MyPeoplePage";
import CreateQuestionPage from "./components/pages/questions/create/CreateQuestionPage";
import ViewSingleQuestionPage from "./components/pages/questions/view/ViewSingleQuestionPage";

import { PubSubProvider } from "./util/usePubSub";
import EditQuestionPage from "./components/pages/questions/edit/EditQuestionPage";

// https://stackoverflow.com/questions/75446831/how-disabled-dark-mode-in-tailwind-css
localStorage.theme = "light";

type ReactDataInput = {
  api_base_path?: string;
};

type UserInfo = {
  guid: string;
  onboarding_completed: boolean;
  name?: string;
  username?: string;
  is_public?: boolean;
};

const getUserInfo = (): UserInfo | null => {
  const userInfoElement = document.getElementById("user-info");
  if (userInfoElement) {
    return JSON.parse(userInfoElement.textContent!);
  }
  return null;
};

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY!, {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST!,
  loaded: (ph: PostHog) => {
    const userInfo = getUserInfo();
    if (!userInfo) {
      return;
    }
    ph.identify(userInfo.guid);
  },
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new posthog.SentryIntegration(
      posthog,
      process.env.REACT_APP_SENTRY_ORGANIZATION,
      parseInt(process.env.REACT_APP_SENTRY_PROJECT_ID!, 10)
    ),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const elementMap: any = {
  MagicLinkNotFoundPage,
  AskUserQuestionPage,
  AskGroupQuestionPage,
  MessagePage,
  MarkdownPage,
  AssessmentPage,
  LoginPage,
  MNav,
  OnboardingV2Page,
  ProfileV2Page,
  QuestionnairePage,
  VitalsPage,
  LinksPage,
  WorkHistoryPage,
  ProfilePicturePage,
  BioPage,
  BasicsPage,
  QuestionsPage,
  InsightsPage,
  AskQuestionPage,
  CreateCollabPage,
  AskCommCoachPage,
  SettingsPage,
  CreateGlimpsePage,
  ListGlimpsesPage,
  ViewGlimpsePage,
  EditGlimpsePage,
  AttachmentsPage,
  CreateGroupPage,
  EditGroupPage,
  ViewGroupPage,
  MyPeoplePage,
  CreateQuestionPage,
  ViewSingleQuestionPage,
  QuestionSearchPage,
  EditQuestionPage,
};

const renderReactComponent = (container: HTMLElement) => {
  // The first child of the container is the element we want to render into

  const placement = container.children[0];

  // The second child of the container is the props for the component

  let props: any = {};
  if (container.children[1].textContent !== "") {
    props = JSON.parse(container.children[1].textContent!);
  }

  // Render the component

  const componentType = (placement as HTMLDivElement).dataset.component;
  const root = createRoot(placement);
  if (window.location.host !== "app.manual.me") {
    root.render(
      <GlobalContextProvider>
        <PostHogProvider client={posthog}>
          <PubSubProvider>
            {React.createElement(elementMap[componentType!], props, null)}
          </PubSubProvider>
        </PostHogProvider>
      </GlobalContextProvider>
    );
  } else {
    root.render(
      <GlobalContextProvider>
        <PubSubProvider>
          {React.createElement(elementMap[componentType!], props, null)}
        </PubSubProvider>
      </GlobalContextProvider>
    );
  }
};

document.addEventListener("DOMContentLoaded", () => {
  // Pull any React data input that is found on the page

  let reactData: ReactDataInput = {};
  const reactDataContainer = document.getElementById("react_input_data");
  if (reactDataContainer) {
    reactData = JSON.parse(reactDataContainer.innerText);
  }

  // Update global state as needed

  if (reactData.api_base_path) {
    // eslint-disable-next-line no-console
    console.log("Setting API base path to ", reactData.api_base_path);
    connector.setApiBasePath(reactData.api_base_path);
  }

  // Render toaster on the page as needed

  const element = document.getElementById("toaster");
  if (element !== null) {
    const root = createRoot(element);
    root.render(
      <div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    );
  }

  // Find all the React components on the page and render them as necessary
  const reactElements = document.querySelectorAll(".react-component-container");
  for (let i = 0; i < reactElements.length; i += 1) {
    renderReactComponent(reactElements[i] as HTMLElement);
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
