import React, { useEffect, useState } from "react";
import { PromptRespondScreenProps } from "./PromptRespondScreen";
import FillInBlankListScreen from "./FillInBlankListScreen";
import {
  FillInBlankListPromptMeta,
  FillInBlankListPromptResponseMeta,
} from "../../../api/types";

const PromptRespondFillInBlankListScreenComponent = (
  props: PromptRespondScreenProps
) => {
  const { prompt, ...rest } = props;

  const [title, setTitle] = useState<string>("");
  const [numAnswers, setNumAnswers] = useState<number>(-1);
  const [responses, setResponses] = useState<string[] | null>(null);
  const [examples, setExamples] = useState<string[] | null>(null);
  const [maxContentLength, setMaxContentLength] = useState<number | null>(null);

  useEffect(() => {
    const promptMeta = prompt.prompt.meta as FillInBlankListPromptMeta;
    setTitle(prompt.prompt.question);
    setNumAnswers(promptMeta.answer_count);
    setExamples(promptMeta.examples ?? []);
    setMaxContentLength(promptMeta.max_chars ?? null);
    if (prompt.response) {
      const responseMeta = prompt.response
        .meta as FillInBlankListPromptResponseMeta;
      setResponses(responseMeta.response);
    } else {
      setResponses([]);
    }
  }, [prompt.prompt.guid, prompt.response?.meta]);

  return (
    <FillInBlankListScreen
      title={title}
      numAnswers={numAnswers}
      content={responses}
      examples={examples}
      maxContentLength={maxContentLength}
      visible={prompt.visible ?? true}
      publishable={prompt.publishable}
      prompt={prompt}
      {...rest}
    />
  );
};

export default PromptRespondFillInBlankListScreenComponent;
