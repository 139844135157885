/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  CommonSingleUserQuestionInsightResponseSerializer,
  CommonSingleUserQuestionInsightResponseSerializerFromJSON,
  CommonSingleUserQuestionInsightResponseSerializerToJSON,
  CreateSingleUserQuestionInsightRequest,
  CreateSingleUserQuestionInsightRequestFromJSON,
  CreateSingleUserQuestionInsightRequestToJSON,
  EmptyResponseWrapper,
  EmptyResponseWrapperFromJSON,
  EmptyResponseWrapperToJSON,
} from "../models";

export interface CreateSingleUserQuestionInsightOperationRequest {
  data: CreateSingleUserQuestionInsightRequest;
}

export interface GetSingleUserQuestionInsightRequest {
  insight_id: string;
}

/**
 *
 */
export class InsightsApi extends runtime.BaseAPI {
  /**
   * Create a SingleUserQuestionInsight object.
   */
  async createSingleUserQuestionInsightRaw(
    requestParameters: CreateSingleUserQuestionInsightOperationRequest,
    initOverrides?: RequestInit
  ): Promise<
    runtime.ApiResponse<CommonSingleUserQuestionInsightResponseSerializer>
  > {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling createSingleUserQuestionInsight."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/insights/create-single-user-question`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CreateSingleUserQuestionInsightRequestToJSON(
          requestParameters.data
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CommonSingleUserQuestionInsightResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Create a SingleUserQuestionInsight object.
   */
  async createSingleUserQuestionInsight(
    requestParameters: CreateSingleUserQuestionInsightOperationRequest,
    initOverrides?: RequestInit
  ): Promise<CommonSingleUserQuestionInsightResponseSerializer> {
    const response = await this.createSingleUserQuestionInsightRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get the contents of a SingleUserQuestionInsight object.
   */
  async getSingleUserQuestionInsightRaw(
    requestParameters: GetSingleUserQuestionInsightRequest,
    initOverrides?: RequestInit
  ): Promise<
    runtime.ApiResponse<CommonSingleUserQuestionInsightResponseSerializer>
  > {
    if (
      requestParameters.insight_id === null ||
      requestParameters.insight_id === undefined
    ) {
      throw new runtime.RequiredError(
        "insight_id",
        "Required parameter requestParameters.insight_id was null or undefined when calling getSingleUserQuestionInsight."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.insight_id !== undefined) {
      queryParameters["insight_id"] = requestParameters.insight_id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/insights/get-single-user-question`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CommonSingleUserQuestionInsightResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the contents of a SingleUserQuestionInsight object.
   */
  async getSingleUserQuestionInsight(
    requestParameters: GetSingleUserQuestionInsightRequest,
    initOverrides?: RequestInit
  ): Promise<CommonSingleUserQuestionInsightResponseSerializer> {
    const response = await this.getSingleUserQuestionInsightRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
