import React, { ReactElement } from "react";
import { useBusyWatcher } from "../../../util/hooks";
import MPlusButton from "../../ui/buttons/MPlusButton";
import MEditButton from "../../ui/buttons/MEditButton";
import { classNames } from "../../../util/strings";
import { commentText } from "../../../util/style";
import MTitle from "../../ui/MTitle";

type ProfileTaglineProps = {
  bio?: string | null;
  headline?: string | null;
  className?: string | null;
  editUrl?: string | null;
};

const ProfileTaglineComponent = (props: ProfileTaglineProps) => {
  const { bio, headline, className, editUrl } = props;
  const [busy, _] = useBusyWatcher();

  const getAddButton = (): ReactElement => {
    if (!bio && !headline) {
      return <MPlusButton href={editUrl!} disabled={busy} text={null} />;
    }
    return <MEditButton href={editUrl!} disabled={busy} text={null} />;
  };

  return (
    <div className={classNames("flex flex-col gap-2 text-left", className)}>
      {headline && (
        <div className="flex flex-row items-start justify-between gap-3">
          <MTitle text={headline} />
          {editUrl ? getAddButton() : null}
        </div>
      )}
      {bio && (
        <div className="flex flex-row items-start justify-between gap-3">
          {bio}
          {!headline && editUrl && getAddButton()}
        </div>
      )}
      {!bio && !headline && (
        <div className={classNames(commentText, "my-3")}>
          <div className="flex flex-row items-start justify-between gap-3">
            you haven't added a tagline to your profile yet
            {editUrl ? getAddButton() : null}
          </div>
        </div>
      )}
    </div>
  );
};

ProfileTaglineComponent.defaultProps = {
  bio: null,
  headline: null,
  className: null,
  editUrl: null,
};

export default ProfileTaglineComponent;
