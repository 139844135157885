/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GetGenTaglineResponse
 */
export interface GetGenTaglineResponse {
  /**
   *
   * @type {string}
   * @memberof GetGenTaglineResponse
   */
  headline: string;
  /**
   *
   * @type {string}
   * @memberof GetGenTaglineResponse
   */
  bio: string;
}

export function GetGenTaglineResponseFromJSON(
  json: any
): GetGenTaglineResponse {
  return GetGenTaglineResponseFromJSONTyped(json, false);
}

export function GetGenTaglineResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetGenTaglineResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    headline: json["headline"],
    bio: json["bio"],
  };
}

export function GetGenTaglineResponseToJSON(
  value?: GetGenTaglineResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    headline: value.headline,
    bio: value.bio,
  };
}
