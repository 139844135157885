/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  UserProfileSummary,
  UserProfileSummaryFromJSON,
  UserProfileSummaryFromJSONTyped,
  UserProfileSummaryToJSON,
} from "./UserProfileSummary";

/**
 *
 * @export
 * @interface UserSearchResponse
 */
export interface UserSearchResponse {
  /**
   *
   * @type {Array<UserProfileSummary>}
   * @memberof UserSearchResponse
   */
  users: Array<UserProfileSummary>;
}

export function UserSearchResponseFromJSON(json: any): UserSearchResponse {
  return UserSearchResponseFromJSONTyped(json, false);
}

export function UserSearchResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserSearchResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    users: (json["users"] as Array<any>).map(UserProfileSummaryFromJSON),
  };
}

export function UserSearchResponseToJSON(
  value?: UserSearchResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    users: (value.users as Array<any>).map(UserProfileSummaryToJSON),
  };
}
