/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CreateEmployerRequest
 */
export interface CreateEmployerRequest {
  /**
   *
   * @type {string}
   * @memberof CreateEmployerRequest
   */
  name: string;
}

export function CreateEmployerRequestFromJSON(
  json: any
): CreateEmployerRequest {
  return CreateEmployerRequestFromJSONTyped(json, false);
}

export function CreateEmployerRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateEmployerRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
  };
}

export function CreateEmployerRequestToJSON(
  value?: CreateEmployerRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
  };
}
