/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  DeleteInsightRequest,
  DeleteInsightRequestFromJSON,
  DeleteInsightRequestToJSON,
  EmptyResponseWrapper,
  EmptyResponseWrapperFromJSON,
  EmptyResponseWrapperToJSON,
  GenTaglineCheckResponseSerializer,
  GenTaglineCheckResponseSerializerFromJSON,
  GenTaglineCheckResponseSerializerToJSON,
  GetGenTaglineResponseSerializer,
  GetGenTaglineResponseSerializerFromJSON,
  GetGenTaglineResponseSerializerToJSON,
  SearchForInsightsResponseSerializer,
  SearchForInsightsResponseSerializerFromJSON,
  SearchForInsightsResponseSerializerToJSON,
  TaglineGenerationDetailSerializer,
  TaglineGenerationDetailSerializerFromJSON,
  TaglineGenerationDetailSerializerToJSON,
} from "../models";

export interface DeleteInsightOperationRequest {
  data: DeleteInsightRequest;
}

export interface GenTaglineRequest {
  data: object;
}

export interface GetGenTaglineRequest {
  tagline: string;
}

export interface SearchForInsightsRequest {
  offset?: number;
  limit?: number;
  search?: string | null;
  include_single_user_insights?: boolean;
  include_collabs?: boolean;
  include_single_comm_coach?: boolean;
}

/**
 *
 */
export class AiApi extends runtime.BaseAPI {
  /**
   */
  async deleteInsightRaw(
    requestParameters: DeleteInsightOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling deleteInsight."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/ai/delete-insight`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: DeleteInsightRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   */
  async deleteInsight(
    requestParameters: DeleteInsightOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.deleteInsightRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Generate a tagline for the requesting user.
   */
  async genTaglineRaw(
    requestParameters: GenTaglineRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<TaglineGenerationDetailSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling genTagline."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/ai/gen-tagline`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.data as any,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TaglineGenerationDetailSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Generate a tagline for the requesting user.
   */
  async genTagline(
    requestParameters: GenTaglineRequest,
    initOverrides?: RequestInit
  ): Promise<TaglineGenerationDetailSerializer> {
    const response = await this.genTaglineRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get a list of items that the requesting user should perform before attempting to generate a tagline via AI.
   */
  async genTaglineCheckRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GenTaglineCheckResponseSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/ai/gen-tagline-check`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GenTaglineCheckResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get a list of items that the requesting user should perform before attempting to generate a tagline via AI.
   */
  async genTaglineCheck(
    initOverrides?: RequestInit
  ): Promise<GenTaglineCheckResponseSerializer> {
    const response = await this.genTaglineCheckRaw(initOverrides);
    return await response.value();
  }

  /**
   * Get the tagline for the requesting user.
   */
  async getGenTaglineRaw(
    requestParameters: GetGenTaglineRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetGenTaglineResponseSerializer>> {
    if (
      requestParameters.tagline === null ||
      requestParameters.tagline === undefined
    ) {
      throw new runtime.RequiredError(
        "tagline",
        "Required parameter requestParameters.tagline was null or undefined when calling getGenTagline."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.tagline !== undefined) {
      queryParameters["tagline"] = requestParameters.tagline;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/ai/get-gen-tagline`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetGenTaglineResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the tagline for the requesting user.
   */
  async getGenTagline(
    requestParameters: GetGenTaglineRequest,
    initOverrides?: RequestInit
  ): Promise<GetGenTaglineResponseSerializer> {
    const response = await this.getGenTaglineRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Search through all the requesting user\'s insights based on the search term that they provide.
   */
  async searchForInsightsRaw(
    requestParameters: SearchForInsightsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<SearchForInsightsResponseSerializer>> {
    const queryParameters: any = {};

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.search !== undefined) {
      queryParameters["search"] = requestParameters.search;
    }

    if (requestParameters.include_single_user_insights !== undefined) {
      queryParameters["include_single_user_insights"] =
        requestParameters.include_single_user_insights;
    }

    if (requestParameters.include_collabs !== undefined) {
      queryParameters["include_collabs"] = requestParameters.include_collabs;
    }

    if (requestParameters.include_single_comm_coach !== undefined) {
      queryParameters["include_single_comm_coach"] =
        requestParameters.include_single_comm_coach;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/ai/search-for-insights`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SearchForInsightsResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Search through all the requesting user\'s insights based on the search term that they provide.
   */
  async searchForInsights(
    requestParameters: SearchForInsightsRequest = {},
    initOverrides?: RequestInit
  ): Promise<SearchForInsightsResponseSerializer> {
    const response = await this.searchForInsightsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
