import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import {
  PromptRelatedDetail,
  SearchValidTagsResponse,
  UpdateQuestionRequest,
} from "../../../../api/models";
import { useBusyWatcher } from "../../../../util/hooks";
import { useWrappedConnector } from "../../../../api/connector";
import { MNavProps } from "../../../ui/nav/MNav";
import BaseNavbarPage from "../../BaseNavbarPage";
import EditPromptScreen from "./EditPromptScreen";

type EditQuestionPageProps = {
  navbarProps: MNavProps;
  promptId: string;
};

const EditQuestionPage = (props: EditQuestionPageProps) => {
  const { navbarProps, promptId } = props;

  const [prompt, setPrompt] = useState<PromptRelatedDetail | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [errorMap, setErrorMap] = useState<{ [key: string]: string[] }>({});
  const [tagsSearchResults, setTagsSearchResults] =
    useState<SearchValidTagsResponse | null>(null);
  const [tagsSearching, setTagsSearching] = useState<boolean>(false);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);

  const loadPrompt = async () => {
    const result = await connector.getPrompt(promptId);
    setPrompt(result.c!);
  };

  const onTagsSearchTermUpdated = async (newTerm: string) => {
    setTagsSearching(true);
    const result = await connector.searchQuestionTags(newTerm);
    setTagsSearchResults(result.c!);
    setTagsSearching(false);
  };

  const onUpdate = async (update: UpdateQuestionRequest) => {
    setErrors([]);
    setErrorMap({});
    try {
      const response = await connector.updateQuestion({ data: update });
      window.location.href = response.c!.view_url!;
    } catch (e: any) {
      const jsonBody = await e.json();
      if (jsonBody.m) {
        setErrors([jsonBody.m]);
      }
      if (jsonBody.e?.fill_in_blank) {
        setErrorMap(jsonBody.e.fill_in_blank);
      }
      if (jsonBody.e?.either_or) {
        setErrorMap(jsonBody.e.either_or);
      }
      if (jsonBody.e?.multi_select) {
        setErrorMap(jsonBody.e.multi_select);
      }
    }
  };

  useEffect(() => {
    loadPrompt();
  }, []);

  return (
    <BaseNavbarPage navbarProps={navbarProps}>
      {prompt ? (
        <EditPromptScreen
          onBackClicked={() => {
            window.history.back();
          }}
          errors={errors}
          errorMap={errorMap}
          onTagSearchTermUpdated={onTagsSearchTermUpdated}
          tagsSearching={tagsSearching}
          tags={tagsSearchResults}
          prompt={prompt}
          onUpdate={onUpdate}
        />
      ) : (
        <Skeleton className="flex flex-col gap-1" />
      )}
    </BaseNavbarPage>
  );
};

export default EditQuestionPage;
