import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React from "react";
import MWellButton from "./MWellButton";
import { CommonWellButtonProps } from "./common";

const MWellEditButtonComponent = (props: CommonWellButtonProps) => (
  <MWellButton {...props} icon={<PencilSquareIcon />} />
);

MWellEditButtonComponent.defaultProps = {
  disabled: false,
  tooltip: "edit",
  className: null,
  kind: "regular",
};

export default MWellEditButtonComponent;
