import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import MErrors from "./MErrors";
import { classNames } from "../../util/strings";
import { useBusyWatcher } from "../../util/hooks";
import MWellCancelButton from "./wells/MWellCancelButton";

export type MFileSelectProps = {
  disabled?: boolean;
  allowImages?: boolean;
  allowPdfs?: boolean;
  allowText?: boolean;
  maxFiles?: number;
  maxSize?: number;
  onFilesChanged?: ((files: File[]) => void) | null;
};

const MFileSelectComponent = (props: MFileSelectProps) => {
  const {
    disabled,
    allowImages,
    allowPdfs,
    allowText,
    maxFiles,
    maxSize,
    onFilesChanged,
  } = props;

  const [errors, setErrors] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    onFilesChanged?.(files);
  }, [files]);

  const getAccept = (): { [key: string]: string[] } => {
    const accept: { [key: string]: string[] } = {};
    if (allowImages) {
      accept["image/jpeg"] = ["jpg", "jpeg"];
      accept["image/png"] = ["png"];
    }
    if (allowPdfs) {
      accept["application/pdf"] = ["pdf"];
    }
    if (allowText) {
      accept["text/plain"] = ["txt"];
    }
    return accept;
  };

  const onError = (error: Error) => {
    setErrors([error.message]);
  };

  const multiple = maxFiles ? maxFiles > 1 : false;
  const hasAccept = Object.keys(getAccept()).length > 0;
  const hasMaxSize = maxSize !== undefined;
  const [busy, _] = useBusyWatcher();

  const { getRootProps, getInputProps } = useDropzone({
    accept: getAccept(),
    maxFiles,
    disabled,
    maxSize,
    multiple,
    onError,
    onDrop: () => setErrors([]),
    onDropAccepted: setFiles,
  });

  const getAcceptedFileTypesDescription = (): string | null => {
    if (!hasAccept) {
      return null;
    }
    const accept = getAccept();
    const extensions: string[] = [];
    Object.keys(getAccept()).forEach((key) => {
      extensions.push(...accept[key]);
    });
    const descriptions = extensions.map((e) => `.${e}`);
    if (descriptions.length === 1) {
      return `only accepts ${descriptions[0]} files`;
    }
    if (descriptions.length === 2) {
      return `only accepts ${descriptions[0]} and ${descriptions[1]} files`;
    }
    return `only accepts ${descriptions.slice(0, -1).join(", ")}, and ${
      descriptions[descriptions.length - 1]
    } files`;
  };

  const getMaxSizeDescription = (): string | null => {
    if (!hasMaxSize) {
      return null;
    }
    const mb = maxSize! / 2 ** 20;
    return `max file size is ${mb}MB`;
  };

  const spliceFileAtIndex = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  return (
    <>
      <MErrors errors={errors} />
      {files.length === 0 ? (
        <div
          className={classNames(
            "rounded-lg border-dashed border-2 p-5 text-m-dark-gray flex flex-col items-center justify-center w-full",
            disabled
              ? "cursor-not-allowed bg-m-light-gray/50 border-m-gray"
              : "cursor-pointer border-primary hover:bg-primary-light"
          )}
        >
          <div
            {...getRootProps({
              className:
                "w-full h-full flex flex-col items-center justify-center gap-2 text-center",
            })}
          >
            <input {...getInputProps()} />
            <p>drag 'n' drop some files here or click to select files</p>
            {hasAccept ? (
              <div className="italic text-xs">
                {getAcceptedFileTypesDescription()}
              </div>
            ) : null}
            {hasMaxSize ? (
              <div className="italic text-xs">{getMaxSizeDescription()}</div>
            ) : null}
          </div>
        </div>
      ) : (
        <div
          className={classNames(
            "rounded-lg border-dashed border-2 p-5 text-m-dark-gray flex",
            "flex-col items-center justify-center w-full border-primary gap-2"
          )}
        >
          {files.map((file, index) => (
            <div
              className="flex flex-row items-center text-start font-semibold gap-2"
              key={file.name}
            >
              • <span>{file.name}</span>
              <MWellCancelButton
                disabled={busy}
                onClick={() => spliceFileAtIndex(index)}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

MFileSelectComponent.defaultProps = {
  disabled: false,
  allowImages: false,
  allowPdfs: false,
  allowText: false,
  maxFiles: 1,
  maxSize: 2 ** 21, // 2MB
  onFilesChanged: null,
};

export default MFileSelectComponent;
