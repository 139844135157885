import React, { useState } from "react";
import {
  InsightResponseEnvelope,
  InsightResponseEnvelopeInsightTypeEnum,
  SearchForInsightsResponse,
} from "../../../api/models";
import { useBusyWatcher } from "../../../util/hooks";
import MTitle from "../../ui/MTitle";
import CenterColumnStickyButtonScreen from "../../ui/screens/CenterColumnStickyButtonScreen";
import { classNames } from "../../../util/strings";
import { clickableText, commentText } from "../../../util/style";
import InsightCard from "./InsightCard";
import MDivider from "../../ui/MDivider";
import MPlusButton from "../../ui/buttons/MPlusButton";
import MSearchBox from "../../ui/MSearchBox";
import SubscriptionUpsellDialog from "../../dialogs/SubscriptionUpsellDialog";
import { useAsyncConfirmationDialog } from "../../dialogs/hooks";

type ListInsightsScreenProps = {
  searchTerm?: string | null;
  onSearchTermUpdated: (newTerm: string) => void;
  insights?: SearchForInsightsResponse | null;
  onInsightClicked: (insight: InsightResponseEnvelope) => void;
  onDeleteInsight: (insight: InsightResponseEnvelope) => void;
  onNewQuestionClicked: () => void;
  errors: string[];
};

const ListInsightsScreenComponent = (props: ListInsightsScreenProps) => {
  const {
    searchTerm,
    onSearchTermUpdated,
    insights,
    onInsightClicked,
    onDeleteInsight: onDeleteInsightInput,
    onNewQuestionClicked,
    errors,
  } = props;

  const [showSubscriptionUpsell, setShowSubscriptionUpsell] =
    useState<boolean>(false);
  const [upsellCopy, setUpsellCopy] = useState<string>("");
  const [busy, _] = useBusyWatcher();
  const confirmDialog = useAsyncConfirmationDialog();

  const onDeleteInsightClicked = async (insight: InsightResponseEnvelope) => {
    const result = await confirmDialog.confirm({
      title: "delete insight?",
      body: "are you sure you want to delete this insight? this action cannot be undone.",
    });
    if (!result) {
      return;
    }
    onDeleteInsightInput(insight);
  };

  const keyFromInsight = (insight: InsightResponseEnvelope): string => {
    switch (insight.insight_type) {
      case InsightResponseEnvelopeInsightTypeEnum.single_user_insight: {
        return `single-user-insight-${insight.single_user_insight!.guid!}`;
      }
      case InsightResponseEnvelopeInsightTypeEnum.single_comm_coach: {
        return `single-comm-coach-${insight.single_comm_coach!.guid!}`;
      }
      case InsightResponseEnvelopeInsightTypeEnum.user_collab: {
        return `user-collab-${insight.collab!.guid!}`;
      }
      default: {
        throw new Error(`Unsupported insight type ${insight.insight_type}`);
      }
    }
  };

  const showUpsellDialog = (
    copy = "gain access to more features through a Manual subscription"
  ) => {
    setUpsellCopy(copy);
    setShowSubscriptionUpsell(true);
  };

  const checkLimitsBeforeCreate = () => {
    if (insights && insights.limits?.remaining > 0) {
      onNewQuestionClicked();
    } else {
      showUpsellDialog(
        `you've reached your maximum number of live insights (${insights?.limits.max}). upgrade to a paid subscription to create more.`
      );
    }
  };

  return (
    <>
      {confirmDialog.dialog}
      <CenterColumnStickyButtonScreen stacked fullWidth>
        <MTitle text="insights" className="text-center mb-5 text-primary" />
        <div className="flex flex-col items-center gap-3 mb-3">
          <div
            className={`flex flex-row items-end w-full ${
              insights && insights.limits!.max < 2000
                ? "justify-between"
                : "justify-center"
            }`}
          >
            {insights && insights.limits.max < 2000 && (
              <div className="text-sm flex flex-col items-start gap-1">
                <span>
                  {insights?.limits.current}/{insights?.limits.max} insights
                </span>
                <button
                  type="button"
                  onClick={() => showUpsellDialog()}
                  className={clickableText}
                >
                  + get more
                </button>
              </div>
            )}
            <div>
              <MPlusButton
                onClick={checkLimitsBeforeCreate}
                disabled={busy}
                kind="primary"
                text="create new"
              />
            </div>
          </div>
          <MDivider className="w-full" />
          <div className="w-full flex flex-row gap-2 justify-center">
            <MSearchBox
              searchTerm={searchTerm}
              onSearchTermUpdated={onSearchTermUpdated}
              errors={errors}
            />
          </div>
        </div>
        {insights && insights.insights.length > 0 ? (
          <div className="flex flex-col gap-2">
            {insights.insights.map((insight) => (
              <InsightCard
                key={keyFromInsight(insight)}
                insight={insight}
                disabled={busy}
                onClick={() => onInsightClicked(insight)}
                onDeleteClick={() => onDeleteInsightClicked(insight)}
                usersClickable={false}
              />
            ))}
          </div>
        ) : (
          <div className={classNames(commentText, "text-center")}>
            no results found
          </div>
        )}
      </CenterColumnStickyButtonScreen>
      <SubscriptionUpsellDialog
        upsellText={upsellCopy}
        show={showSubscriptionUpsell}
        onClose={() => setShowSubscriptionUpsell(false)}
      />
    </>
  );
};

ListInsightsScreenComponent.defaultProps = {
  searchTerm: null,
  insights: null,
};

export default ListInsightsScreenComponent;
