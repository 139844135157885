import React from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../../util/strings";
import { clickableText } from "../../../util/style";

type MWellLeaveButtonProps = {
  href: string;
  label: string;
  otherWindow?: boolean;
};

const MWellLeaveButton = (props: MWellLeaveButtonProps) => {
  const { href, label, otherWindow } = props;

  return (
    <a
      href={href}
      target={otherWindow ? "_blank" : "_self"}
      rel="noreferrer"
      aria-label={label}
    >
      <ArrowTopRightOnSquareIcon
        className={classNames("w-6 h-6", clickableText)}
      />
    </a>
  );
};

MWellLeaveButton.defaultProps = {
  otherWindow: false,
};

export default MWellLeaveButton;
