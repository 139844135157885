import React, { ReactElement } from "react";
import {
  AnsweredPromptDetail,
  GetAnsweredPromptDetailsResponse,
  PromptWithResponse,
} from "../../../api/models";
import MBackButton from "../../ui/buttons/MBackButton";
import { useBusyWatcher } from "../../../util/hooks";
import CenterColumnStickyButtonScreen from "../../ui/screens/CenterColumnStickyButtonScreen";
import MTitle from "../../ui/MTitle";
import { classNames } from "../../../util/strings";
import { commentText } from "../../../util/style";
import PromptDisplayCard from "../../ui/cards/prompt_display/PromptDisplayCard";
import MNextButton from "../../ui/buttons/MNextButton";

type ListResponsesScreenProps = {
  responses: GetAnsweredPromptDetailsResponse;
  onEditClicked: (toEdit: AnsweredPromptDetail) => void;
  onDeleteClicked: (toDelete: PromptWithResponse) => void;
  onToggleVisibilityClicked: (toToggle: AnsweredPromptDetail) => void;
  onBackClicked: () => void;
  onDoneClicked: () => void;
};

const ListResponsesScreenComponent = (props: ListResponsesScreenProps) => {
  const {
    responses,
    onEditClicked,
    onDeleteClicked,
    onToggleVisibilityClicked,
    onBackClicked,
    onDoneClicked,
  } = props;

  const [busy, _] = useBusyWatcher();

  const getButtons = (): ReactElement[] => [
    <MNextButton
      kind="primary"
      onClick={onDoneClicked}
      disabled={busy}
      key="done-button"
      text="done"
    />,
    <MBackButton
      kind="secondary"
      onClick={onBackClicked}
      disabled={busy}
      key="back-button"
    />,
  ];

  return (
    <CenterColumnStickyButtonScreen buttons={getButtons()} stacked fullWidth>
      <MTitle text="your responses" className="text-center mb-5" />
      <div className="flex flex-col gap-3">
        {responses.responses.map((prompt) => (
          <PromptDisplayCard
            key={prompt.prompt_response.prompt.guid!}
            prompt={prompt.prompt_response}
            onEditClicked={() => onEditClicked(prompt)}
            editDisabled={busy}
            onDeleteClicked={() => onDeleteClicked(prompt.prompt_response)}
            deleteDisabled={busy}
            onToggleVisibilityClicked={() => onToggleVisibilityClicked(prompt)}
            toggleVisibilityDisabled={busy}
            isVisible={prompt.is_visible}
          />
        ))}
      </div>
      {responses.responses.length === 0 && (
        <div className={classNames(commentText, "text-center")}>
          you haven't answered any questions yet! when you do, they will all be
          listed here.
        </div>
      )}
    </CenterColumnStickyButtonScreen>
  );
};

export default ListResponsesScreenComponent;
