import React, { ReactElement } from "react";
import { GetTaglineResponse } from "../../../../api/models";
import { useBusyWatcher } from "../../../../util/hooks";
import MBackButton from "../../../ui/buttons/MBackButton";
import MNextButton from "../../../ui/buttons/MNextButton";
import CenterColumnStickyButtonScreen from "../../../ui/screens/CenterColumnStickyButtonScreen";
import MTitle from "../../../ui/MTitle";
import TextReviewCard from "../../../ui/cards/TextReviewCard";

type BioViewScreenProps = {
  tagline: GetTaglineResponse;
  onEditHeadlineClicked: () => void;
  onClearHeadlineClicked: () => void;
  onEditBioClicked: () => void;
  onClearBioClicked: () => void;
  onDoneClicked: () => void;
  onBackClicked?: (() => void) | null;
};

const BioViewScreenComponent = (props: BioViewScreenProps) => {
  const {
    tagline,
    onEditHeadlineClicked,
    onClearHeadlineClicked,
    onEditBioClicked,
    onClearBioClicked,
    onBackClicked,
    onDoneClicked,
  } = props;

  const [busy, _] = useBusyWatcher();

  const getButtons = (): ReactElement[] => {
    const toReturn: ReactElement[] = [
      <MNextButton
        kind="primary"
        disabled={busy}
        onClick={onDoneClicked}
        className="w-full"
        key="done-button"
        text="done"
      />,
    ];
    if (onBackClicked) {
      toReturn.push(
        <MBackButton
          kind="secondary"
          disabled={busy}
          onClick={onBackClicked}
          className="w-full"
          key="back-button"
        />
      );
    }
    return toReturn;
  };

  return (
    <CenterColumnStickyButtonScreen buttons={getButtons()} stacked fullWidth>
      <MTitle text="edit your tagline" className="text-center" />
      <div className="flex flex-col gap-3 mt-5">
        <TextReviewCard
          title="headline"
          content={tagline.headline}
          onEditClicked={onEditHeadlineClicked}
          onDeleteClicked={tagline.headline ? onClearHeadlineClicked : null}
        />
        <TextReviewCard
          title="bio"
          content={tagline.bio}
          onEditClicked={onEditBioClicked}
          onDeleteClicked={tagline.bio ? onClearBioClicked : null}
        />
      </div>
    </CenterColumnStickyButtonScreen>
  );
};

BioViewScreenComponent.defaultProps = {
  onBackClicked: null,
};

export default BioViewScreenComponent;
