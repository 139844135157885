import React, { ReactElement, useEffect, useRef, useState } from "react";
import LeftRightTransition from "../transitions/LeftRightTransition";
import {
  DEFAULT_TRANSITION_ENTER_DURATION,
  DEFAULT_TRANSITION_LEAVE_DURATION,
  DEFAULT_TRANSITION_PADDING,
} from "../../../util/constants";

export type WizardScreenPanel = {
  stage: number;
  content: ReactElement | undefined | null;
};

type WizardScreenProps = {
  elements: WizardScreenPanel[];
  stage: number;
  isBack?: boolean;
  onTransitionStart?: () => void | null;
  onTransitionEnd?: () => void | null;
  enterMs?: number;
  leaveMs?: number;
  paddingMs?: number;
};

const WizardScreenComponent = (props: WizardScreenProps) => {
  const {
    elements,
    stage: stageInput,
    enterMs,
    leaveMs,
    paddingMs,
    isBack,
    onTransitionStart,
    onTransitionEnd,
  } = props;

  const [nextStage, setNextStage] = useState<number>(-1);
  const [stage, setStage] = useState<number>(stageInput);
  const nextStageRef = useRef(nextStage);
  nextStageRef.current = nextStage;
  const stageRef = useRef(stage);
  stageRef.current = stage;

  // We watch stageInput and pause our transitions a bit so that we don't have two
  // panels on the screen at the same time (which makes things jumpy).
  useEffect(() => {
    if (stageInput === stage) {
      return;
    }
    if (onTransitionStart) {
      onTransitionStart();
    }
    setStage(-1);
    setNextStage(stageInput);
    setTimeout(() => {
      if (
        stageRef.current !== nextStageRef.current &&
        nextStageRef.current === stageInput
      ) {
        setStage(nextStageRef.current);
        setNextStage(-1);
        if (onTransitionEnd) {
          setTimeout(() => {
            onTransitionEnd();
          }, leaveMs!);
        }
      }
    }, enterMs! + paddingMs!);
  }, [stageInput]);

  return (
    <>
      {elements.map((element) => (
        <LeftRightTransition
          key={element.stage}
          show={element.stage === stage}
          isBack={isBack}
          enterMs={enterMs}
          leaveMs={leaveMs}
        >
          {element.content}
        </LeftRightTransition>
      ))}
    </>
  );
};

WizardScreenComponent.defaultProps = {
  enterMs: DEFAULT_TRANSITION_ENTER_DURATION,
  leaveMs: DEFAULT_TRANSITION_LEAVE_DURATION,
  paddingMs: DEFAULT_TRANSITION_PADDING,
  onTransitionStart: null,
  onTransitionEnd: null,
  isBack: false,
};

export default WizardScreenComponent;
