import React, { useState } from "react";
import ChatboxMessageBar from "./ChatboxMessageBar";
import ChatboxMessagesContainer, {
  ChatboxMessage,
} from "./ChatboxMessagesContainer";

type ChatboxContainerProps = {
  newMessageReady: (message: string) => Promise<string>;
};

const ChatboxContainerComponent = (props: ChatboxContainerProps) => {
  const { newMessageReady } = props;

  const [busy, setBusy] = useState<boolean>(false);
  const [loading, setLoading] = useState<number>();
  const [messages, setMessages] = useState<ChatboxMessage[]>([]);

  const handleNewMessageReady = async (message: string) => {
    setBusy(true);
    setMessages((oldMessages) => [
      ...oldMessages,
      {
        id: `${messages.length}-r`,
        text: message,
        left: false,
      },
    ]);
    const curTime = Date.now();
    setLoading(curTime);
    const newMessage = await newMessageReady(message);
    setMessages((oldMessages) => [
      ...oldMessages,
      {
        id: `${messages.length}-l`,
        text: newMessage,
        left: true,
        seconds: Math.round((Date.now() - curTime) / 10) / 100,
      },
    ]);
    setLoading(undefined);
    setBusy(false);
  };

  return (
    <div className="h-full w-full max-w-3xl flex flex-col gap-3">
      <div className="grow overflow-y-auto">
        <ChatboxMessagesContainer messages={messages} loading={loading} />
      </div>
      <div className="bg-slate-200">
        <ChatboxMessageBar onMessageReady={handleNewMessageReady} busy={busy} />
      </div>
    </div>
  );
};

export default ChatboxContainerComponent;
