/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GetEventType
 */
export interface GetEventType {
  /**
   *
   * @type {string}
   * @memberof GetEventType
   */
  event: GetEventTypeEventEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum GetEventTypeEventEnum {
  unknown = "unknown",
  new_user_registered = "new_user_registered",
  search_started = "search_started",
  profile_visited = "profile_visited",
  email_sent = "email_sent",
  onboarding_started = "onboarding_started",
  onboarding_basic_info_completed = "onboarding_basic_info_completed",
  onboarding_questions_started = "onboarding_questions_started",
  onboarding_questions_submitted = "onboarding_questions_submitted",
  onboarding_questions_completed = "onboarding_questions_completed",
  onboarding_username_completed = "onboarding_username_completed",
  onboarding_completed = "onboarding_completed",
  questionnaire_started = "questionnaire_started",
  questionnaire_question_submitted = "questionnaire_question_submitted",
  questionnaire_question_skipped = "questionnaire_question_skipped",
  questionnaire_completed = "questionnaire_completed",
  prompt_created = "prompt_created",
  prompt_answered = "prompt_answered",
  prompt_deleted = "prompt_deleted",
  prompt_updated = "prompt_updated",
  prompt_toggle_visibility = "prompt_toggle_visibility",
  question_searched = "question_searched",
  vitals_completed = "vitals_completed",
  assessment_started = "assessment_started",
  assessment_inference_generated = "assessment_inference_generated",
  assessment_completed = "assessment_completed",
  link_added = "link_added",
  employer_added = "employer_added",
  employer_updated = "employer_updated",
  employer_deleted = "employer_deleted",
  employer_role_added = "employer_role_added",
  employer_role_updated = "employer_role_updated",
  employer_role_deleted = "employer_role_deleted",
  profile_photo_added = "profile_photo_added",
  profile_photo_deleted = "profile_photo_deleted",
  headline_generated = "headline_generated",
  headline_set = "headline_set",
  bio_set = "bio_set",
  llm_call_metrics = "llm_call_metrics",
  user_collab_generated = "user_collab_generated",
  user_insight_generated = "user_insight_generated",
  comm_coach_started = "comm_coach_started",
  comm_coach_topic_set = "comm_coach_topic_set",
  comm_coach_goals_set = "comm_coach_goals_set",
  comm_coach_concerns_set = "comm_coach_concerns_set",
  comm_coach_relationship_set = "comm_coach_relationship_set",
  comm_coach_generated = "comm_coach_generated",
  group_qna_generated = "group_qna_generated",
  group_qna_deleted = "group_qna_deleted",
  user_collab_viewed = "user_collab_viewed",
  user_insight_viewed = "user_insight_viewed",
  comm_coach_viewed = "comm_coach_viewed",
  glimpse_started = "glimpse_started",
  glimpse_title_set = "glimpse_title_set",
  glimpse_context_set = "glimpse_context_set",
  glimpse_audience_set = "glimpse_audience_set",
  glimpse_content_generated = "glimpse_content_generated",
  glimpse_prompts_set = "glimpse_prompts_set",
  glimpse_links_set = "glimpse_links_set",
  glimpse_resume_visibility_set = "glimpse_resume_visibility_set",
  glimpse_content_updated = "glimpse_content_updated",
  glimpse_deleted = "glimpse_deleted",
  glimpse_contact_user = "glimpse_contact_user",
  glimpse_completed = "glimpse_completed",
  glimpse_toggle_visibility = "glimpse_toggle_visibility",
  glimpse_visited = "glimpse_visited",
  group_created = "group_created",
  group_updated = "group_updated",
  group_deleted = "group_deleted",
  group_member_added = "group_member_added",
  group_member_removed = "group_member_removed",
  group_invite_created = "group_invite_created",
  group_invite_responded = "group_invite_responded",
  group_owner_changed = "group_owner_changed",
  stripe_new_customer = "stripe_new_customer",
  stripe_checkout_complete = "stripe_checkout_complete",
  stripe_subscription_started = "stripe_subscription_started",
  slack_account_paired = "slack_account_paired",
  google_account_unpaired = "google_account_unpaired",
  slack_account_unpaired = "slack_account_unpaired",
  basic_info_updated = "basic_info_updated",
  profile_toggle_visibility = "profile_toggle_visibility",
  set_profile_access_user_id = "set_profile_access_user_id",
  set_profile_access_email = "set_profile_access_email",
  marketing_email_setting = "marketing_email_setting",
  reset_account = "reset_account",
  logout = "logout",
  invites_screen_visited = "invites_screen_visited",
  manual_invite_sent = "manual_invite_sent",
  manual_invite_resent = "manual_invite_resent",
  manual_invite_accepted = "manual_invite_accepted",
  resume_added = "resume_added",
  resume_deleted = "resume_deleted",
  resume_viewed = "resume_viewed",
  magic_link_created = "magic_link_created",
  magic_link_used = "magic_link_used",
  notif_inserted = "notif_inserted",
  notif_idempotency_conflict = "notif_idempotency_conflict",
  notif_cleared = "notif_cleared",
  notif_uncleared = "notif_uncleared",
  notif_seen = "notif_seen",
  notifs_opened = "notifs_opened",
  connection_requested = "connection_requested",
  connection_accepted = "connection_accepted",
  connection_rejected = "connection_rejected",
  connection_deleted = "connection_deleted",
  connections_searched = "connections_searched",
  create_question_page_viewed = "create_question_page_viewed",
  question_created = "question_created",
  question_creation_denied = "question_creation_denied",
  question_anonymized = "question_anonymized",
  question_updated = "question_updated",
  question_deleted = "question_deleted",
}

export function GetEventTypeFromJSON(json: any): GetEventType {
  return GetEventTypeFromJSONTyped(json, false);
}

export function GetEventTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetEventType {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    event: json["event"],
  };
}

export function GetEventTypeToJSON(value?: GetEventType | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    event: value.event,
  };
}
