/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CommonSingleUserQuestionInsightResponse,
  CommonSingleUserQuestionInsightResponseFromJSON,
  CommonSingleUserQuestionInsightResponseFromJSONTyped,
  CommonSingleUserQuestionInsightResponseToJSON,
} from "./CommonSingleUserQuestionInsightResponse";

/**
 *
 * @export
 * @interface CommonSingleUserQuestionInsightResponseSerializer
 */
export interface CommonSingleUserQuestionInsightResponseSerializer {
  /**
   *
   * @type {string}
   * @memberof CommonSingleUserQuestionInsightResponseSerializer
   */
  m?: string;
  /**
   *
   * @type {boolean}
   * @memberof CommonSingleUserQuestionInsightResponseSerializer
   */
  s: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CommonSingleUserQuestionInsightResponseSerializer
   */
  e?: { [key: string]: string } | null;
  /**
   *
   * @type {CommonSingleUserQuestionInsightResponse}
   * @memberof CommonSingleUserQuestionInsightResponseSerializer
   */
  c?: CommonSingleUserQuestionInsightResponse;
}

export function CommonSingleUserQuestionInsightResponseSerializerFromJSON(
  json: any
): CommonSingleUserQuestionInsightResponseSerializer {
  return CommonSingleUserQuestionInsightResponseSerializerFromJSONTyped(
    json,
    false
  );
}

export function CommonSingleUserQuestionInsightResponseSerializerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommonSingleUserQuestionInsightResponseSerializer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    m: !exists(json, "m") ? undefined : json["m"],
    s: json["s"],
    e: !exists(json, "e") ? undefined : json["e"],
    c: !exists(json, "c")
      ? undefined
      : CommonSingleUserQuestionInsightResponseFromJSON(json["c"]),
  };
}

export function CommonSingleUserQuestionInsightResponseSerializerToJSON(
  value?: CommonSingleUserQuestionInsightResponseSerializer | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    m: value.m,
    s: value.s,
    e: value.e,
    c: CommonSingleUserQuestionInsightResponseToJSON(value.c),
  };
}
