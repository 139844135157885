import React from "react";
import MessageScreen from "../../../ui/screens/MessageScreen";
import { useBusyWatcher } from "../../../../util/hooks";

type GroupCompletedScreenProps = {
  groupName: string;

  onNextClicked: () => void;
};

const GroupCompletedScreenComponent = (props: GroupCompletedScreenProps) => {
  const { groupName, onNextClicked } = props;

  const [busy, _] = useBusyWatcher();

  return (
    <MessageScreen
      title={`you created a group called ${groupName}`}
      content="the groups you create are private to you only (no one else will be notified about it)."
      nextButtonText="see your groups"
      onNextClicked={onNextClicked}
      nextDisabled={busy}
    />
  );
};

export default GroupCompletedScreenComponent;
