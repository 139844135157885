/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GetBasicInfo
 */
export interface GetBasicInfo {
  /**
   *
   * @type {string}
   * @memberof GetBasicInfo
   */
  name: string | null;
  /**
   *
   * @type {string}
   * @memberof GetBasicInfo
   */
  location: string | null;
  /**
   *
   * @type {string}
   * @memberof GetBasicInfo
   */
  title: string | null;
  /**
   *
   * @type {string}
   * @memberof GetBasicInfo
   */
  employer: string | null;
  /**
   *
   * @type {Date}
   * @memberof GetBasicInfo
   */
  dob: Date | null;
}

export function GetBasicInfoFromJSON(json: any): GetBasicInfo {
  return GetBasicInfoFromJSONTyped(json, false);
}

export function GetBasicInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetBasicInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    location: json["location"],
    title: json["title"],
    employer: json["employer"],
    dob: json["dob"] === null ? null : new Date(json["dob"]),
  };
}

export function GetBasicInfoToJSON(value?: GetBasicInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    location: value.location,
    title: value.title,
    employer: value.employer,
    dob: value.dob === null ? null : value.dob.toISOString().substr(0, 10),
  };
}
