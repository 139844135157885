import connector, { ManualAPIConnector } from "../api/connector";

const waitForTask = async (
  taskId: string,
  c: ManualAPIConnector | null = null,
  timeout = 180_000,
  checkInterval = 1000
): Promise<boolean> => {
  const innerConnector = c || connector;
  const maxLoops = timeout / checkInterval;
  for (let i = 0; i < maxLoops; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    const status = await innerConnector.getTaskStatus(taskId);
    if (status.c?.ready) {
      return true;
    }
    // eslint-disable-next-line no-await-in-loop
    await new Promise((resolve) => {
      setTimeout(resolve, checkInterval);
    });
  }
  return false;
};

export default waitForTask;
