import React from "react";
import MCard from "./MCard";
import MWellEditButton from "../wells/MWellEditButton";
import MWellDeleteButton from "../wells/MWellDeleteButton";
import { useBusyWatcher } from "../../../util/hooks";
import { commentText } from "../../../util/style";

type TextReviewCardProps = {
  title: string;
  content?: string | null;
  onEditClicked?: (() => void) | null;
  onDeleteClicked?: (() => void) | null;
};

const TextReviewCardComponent = (props: TextReviewCardProps) => {
  const { title, content, onEditClicked, onDeleteClicked } = props;

  const [busy, _] = useBusyWatcher();

  return (
    <MCard
      header={
        <div className="flex flex-row gap-4 items-center">
          <div className="grow">{title}</div>
          {onEditClicked && (
            <MWellEditButton onClick={onEditClicked} disabled={busy} />
          )}
          {onDeleteClicked && (
            <MWellDeleteButton onClick={onDeleteClicked} disabled={busy} />
          )}
        </div>
      }
    >
      {content || <div className={commentText}>empty</div>}
    </MCard>
  );
};

TextReviewCardComponent.defaultProps = {
  onEditClicked: null,
  onDeleteClicked: null,
  content: null,
};

export default TextReviewCardComponent;
