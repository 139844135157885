/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface LinkSummary
 */
export interface LinkSummary {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof LinkSummary
   */
  readonly guid?: string;
  /**
   * The URL that the link points to
   * @type {string}
   * @memberof LinkSummary
   */
  url: string;
  /**
   * An optional title to use for displaying the link
   * @type {string}
   * @memberof LinkSummary
   */
  title?: string | null;
  /**
   * A FontAwesome icon to display next to the link
   * @type {string}
   * @memberof LinkSummary
   */
  fa_icon?: string | null;
}

export function LinkSummaryFromJSON(json: any): LinkSummary {
  return LinkSummaryFromJSONTyped(json, false);
}

export function LinkSummaryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LinkSummary {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    url: json["url"],
    title: !exists(json, "title") ? undefined : json["title"],
    fa_icon: !exists(json, "fa_icon") ? undefined : json["fa_icon"],
  };
}

export function LinkSummaryToJSON(value?: LinkSummary | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    url: value.url,
    title: value.title,
    fa_icon: value.fa_icon,
  };
}
