/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  LLMInvocation,
  LLMInvocationFromJSON,
  LLMInvocationFromJSONTyped,
  LLMInvocationToJSON,
} from "./LLMInvocation";
import {
  UserGroup,
  UserGroupFromJSON,
  UserGroupFromJSONTyped,
  UserGroupToJSON,
} from "./UserGroup";

/**
 *
 * @export
 * @interface GroupSummary
 */
export interface GroupSummary {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof GroupSummary
   */
  readonly guid?: string;
  /**
   *
   * @type {LLMInvocation}
   * @memberof GroupSummary
   */
  llm_invocation?: LLMInvocation;
  /**
   * Whether or not the referenced Celery task has been completed.
   * @type {boolean}
   * @memberof GroupSummary
   */
  is_task_completed?: boolean;
  /**
   * The task ID for the Celery task that was enqueued to perform the group Q&A.
   * @type {string}
   * @memberof GroupSummary
   */
  task_id?: string | null;
  /**
   *
   * @type {UserGroup}
   * @memberof GroupSummary
   */
  group?: UserGroup;
}

export function GroupSummaryFromJSON(json: any): GroupSummary {
  return GroupSummaryFromJSONTyped(json, false);
}

export function GroupSummaryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GroupSummary {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    llm_invocation: !exists(json, "llm_invocation")
      ? undefined
      : LLMInvocationFromJSON(json["llm_invocation"]),
    is_task_completed: !exists(json, "is_task_completed")
      ? undefined
      : json["is_task_completed"],
    task_id: !exists(json, "task_id") ? undefined : json["task_id"],
    group: !exists(json, "group")
      ? undefined
      : UserGroupFromJSON(json["group"]),
  };
}

export function GroupSummaryToJSON(value?: GroupSummary | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    llm_invocation: LLMInvocationToJSON(value.llm_invocation),
    is_task_completed: value.is_task_completed,
    task_id: value.task_id,
    group: UserGroupToJSON(value.group),
  };
}
