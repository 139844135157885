/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  UserProfileSummary,
  UserProfileSummaryFromJSON,
  UserProfileSummaryFromJSONTyped,
  UserProfileSummaryToJSON,
} from "./UserProfileSummary";

/**
 *
 * @export
 * @interface UserConnectionRequestRelatedDetail
 */
export interface UserConnectionRequestRelatedDetail {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof UserConnectionRequestRelatedDetail
   */
  readonly guid?: string;
  /**
   * The time at which this record was created.
   * @type {Date}
   * @memberof UserConnectionRequestRelatedDetail
   */
  readonly time_created?: Date;
  /**
   *
   * @type {UserProfileSummary}
   * @memberof UserConnectionRequestRelatedDetail
   */
  sending_user: UserProfileSummary;
}

export function UserConnectionRequestRelatedDetailFromJSON(
  json: any
): UserConnectionRequestRelatedDetail {
  return UserConnectionRequestRelatedDetailFromJSONTyped(json, false);
}

export function UserConnectionRequestRelatedDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserConnectionRequestRelatedDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    time_created: !exists(json, "time_created")
      ? undefined
      : new Date(json["time_created"]),
    sending_user: UserProfileSummaryFromJSON(json["sending_user"]),
  };
}

export function UserConnectionRequestRelatedDetailToJSON(
  value?: UserConnectionRequestRelatedDetail | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    sending_user: UserProfileSummaryToJSON(value.sending_user),
  };
}
