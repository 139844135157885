/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface DeleteEmployerRequest
 */
export interface DeleteEmployerRequest {
  /**
   *
   * @type {string}
   * @memberof DeleteEmployerRequest
   */
  employer: string;
}

export function DeleteEmployerRequestFromJSON(
  json: any
): DeleteEmployerRequest {
  return DeleteEmployerRequestFromJSONTyped(json, false);
}

export function DeleteEmployerRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeleteEmployerRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    employer: json["employer"],
  };
}

export function DeleteEmployerRequestToJSON(
  value?: DeleteEmployerRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    employer: value.employer,
  };
}
