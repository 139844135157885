/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GlimpseAttachmentVisibilityDetail
 */
export interface GlimpseAttachmentVisibilityDetail {
  /**
   * Whether or not the user's resume is visible.
   * @type {boolean}
   * @memberof GlimpseAttachmentVisibilityDetail
   */
  resume_is_visible?: boolean;
}

export function GlimpseAttachmentVisibilityDetailFromJSON(
  json: any
): GlimpseAttachmentVisibilityDetail {
  return GlimpseAttachmentVisibilityDetailFromJSONTyped(json, false);
}

export function GlimpseAttachmentVisibilityDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GlimpseAttachmentVisibilityDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    resume_is_visible: !exists(json, "resume_is_visible")
      ? undefined
      : json["resume_is_visible"],
  };
}

export function GlimpseAttachmentVisibilityDetailToJSON(
  value?: GlimpseAttachmentVisibilityDetail | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    resume_is_visible: value.resume_is_visible,
  };
}
