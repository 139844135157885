import React, { useState } from "react";
import MButton from "./buttons/MButton";
import { useBusyWatcher } from "../../util/hooks";
import { commentText } from "../../util/style";
import { classNames } from "../../util/strings";

type MSelectManyButtonsProps = {
  options: string[];
  selected?: string[] | null;
  onChange: (selected: string[]) => void;
  maxCount: number;
};

const MSelectManyButtonsComponent = (props: MSelectManyButtonsProps) => {
  const { options, selected: selectedInput, onChange, maxCount } = props;

  const [selected, setSelected] = useState<string[]>(selectedInput ?? []);
  const [busy, _] = useBusyWatcher();

  const onOptionClicked = (option: string) => {
    let newSelected: string[] = [];
    if (selected.includes(option)) {
      newSelected = selected.filter((o) => o !== option);
    } else {
      newSelected = [...selected, option];
    }
    setSelected(newSelected);
    onChange(newSelected);
  };

  return (
    <div>
      <div className={classNames(commentText, "text-xs mb-2")}>
        select up to {maxCount}
      </div>
      <div className="flex flex-row flex-wrap gap-2">
        {options.map((option) => {
          const isSelected = selected.includes(option);
          return (
            <MButton
              kind={isSelected ? "primary" : "secondary"}
              key={option}
              disabled={isSelected ? busy : busy || selected.length >= maxCount}
              onClick={() => onOptionClicked(option)}
            >
              {option}
            </MButton>
          );
        })}
      </div>
    </div>
  );
};

MSelectManyButtonsComponent.defaultProps = {
  selected: null,
};

export default MSelectManyButtonsComponent;
