import React, { useState } from "react";
import { AddUserLinkRequest, LinkSummary } from "../../../../api/models";
import FormScreen from "../../../ui/screens/FormScreen";
import MInput from "../../../ui/MInput";
import { useBusyWatcher } from "../../../../util/hooks";

type EditLinkScreenProps = {
  link?: LinkSummary | null;
  errors: { [key: string]: string[] };
  onSaveClicked: (link: AddUserLinkRequest) => void;
  onBackClicked: () => void;
};

const EditLinkScreenComponent = (props: EditLinkScreenProps) => {
  const { link, errors, onSaveClicked, onBackClicked } = props;

  const [title, setTitle] = useState<string>(link?.title ?? "");
  const [url, setUrl] = useState<string>(link?.url ?? "");
  const [busy, _] = useBusyWatcher();

  const canSubmit = (): boolean => {
    if (title.length > 64 || title.length === 0) {
      return false;
    }
    if (url.length > 512 || url.length === 0) {
      return false;
    }
    return true;
  };

  const submit = () => {
    onSaveClicked({ title, url });
  };

  return (
    <FormScreen
      title={link ? "edit link" : "add link"}
      onNextClicked={submit}
      nextDisabled={busy || !canSubmit()}
      onBackClicked={onBackClicked}
      backDisabled={busy}
      nextText="save link"
      fullWidth
    >
      <MInput
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        onEnterPressed={() => canSubmit() && submit()}
        disabled={busy}
        errors={errors.title}
        label="Display Title"
        placeholder="ex: portfolio"
      />
      <MInput
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        onEnterPressed={() => canSubmit() && submit()}
        disabled={busy}
        errors={errors.url}
        label="URL"
      />
    </FormScreen>
  );
};

EditLinkScreenComponent.defaultProps = {
  link: null,
};

export default EditLinkScreenComponent;
