import React from "react";
import { classNames } from "../../util/strings";
import { commentText } from "../../util/style";

type MSubtextProps = {
  text?: string | null;
  className?: string | null;
};

const MSubtextComponent = (props: MSubtextProps) => {
  const { text, className } = props;

  return text ? (
    <div className={classNames("mt-6 mb-4 text-xs", commentText, className)}>
      {text}
    </div>
  ) : (
    <div className="h-6" />
  );
};

MSubtextComponent.defaultProps = {
  text: null,
  className: null,
};

export default MSubtextComponent;
