import React, { ReactElement, useState } from "react";
import { MStepsCirclesProps } from "../../ui/MStepsCircles";
import { UserProfileSummary } from "../../../api/models";
import MNextButton from "../../ui/buttons/MNextButton";
import MBackButton from "../../ui/buttons/MBackButton";
import CommCoachFormScreen from "./CommCoachFormScreen";
import MInput from "../../ui/MInput";
import { useBusyWatcher } from "../../../util/hooks";

type CommCoachRelationshipScreenProps = {
  progress: MStepsCirclesProps;
  user: UserProfileSummary;
  onEditUserClicked: () => void;
  topic: string;
  onEditTopicClicked: () => void;
  goals?: string[] | null;
  onEditGoalsClicked: () => void;
  concerns?: string[] | null;
  onEditConcernsClicked: () => void;
  relationship?: string | null;
  onRelationshipSet: (relationship: string) => void;
  onBackClicked: () => void;
};

const CommCoachRelationshipScreenComponent = (
  props: CommCoachRelationshipScreenProps
) => {
  const {
    progress,
    user,
    onEditUserClicked,
    topic,
    onEditTopicClicked,
    goals,
    onEditGoalsClicked,
    concerns,
    onEditConcernsClicked,
    relationship: relationshipInput,
    onRelationshipSet,
    onBackClicked,
  } = props;

  const [relationship, setRelationship] = useState<string>(
    relationshipInput || ""
  );
  const [busy, _] = useBusyWatcher();

  const canSubmit = (): boolean => {
    if (relationship.length > 64) {
      return false;
    }
    return true;
  };

  const getButtons = (): ReactElement[] => [
    <MNextButton
      kind="primary"
      key="next-button"
      onClick={() => onRelationshipSet(relationship)}
      disabled={!canSubmit || busy}
      text="create conversation plan"
    />,
    <MBackButton
      kind="secondary"
      key="back-button"
      disabled={busy}
      onClick={onBackClicked}
    />,
  ];

  const getErrors = (): string[] => {
    const toReturn: string[] = [];
    if (relationship.length > 64) {
      toReturn.push("relationship must be less than 64 characters");
    }
    return toReturn;
  };

  return (
    <CommCoachFormScreen
      progress={progress}
      buttons={getButtons()}
      user={user}
      onEditUserClicked={onEditUserClicked}
      topic={topic}
      onEditTopicClicked={onEditTopicClicked}
      goals={goals}
      onEditGoalsClicked={onEditGoalsClicked}
      concerns={concerns}
      onEditConcernsClicked={onEditConcernsClicked}
    >
      <MInput
        label={`what is your relationship with ${user.name}? (optional)`}
        disabled={busy}
        value={relationship}
        onChange={(e) => setRelationship(e.target.value)}
        errors={getErrors()}
        placeholder="ex: friend and coworker"
        onEnterPressed={() => canSubmit() && onRelationshipSet(relationship)}
      />
    </CommCoachFormScreen>
  );
};

CommCoachRelationshipScreenComponent.defaultProps = {
  goals: null,
  concerns: null,
  relationship: null,
};

export default CommCoachRelationshipScreenComponent;
