/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  GetEventType,
  GetEventTypeFromJSON,
  GetEventTypeFromJSONTyped,
  GetEventTypeToJSON,
} from "./GetEventType";

/**
 *
 * @export
 * @interface GetEventTypeResponse
 */
export interface GetEventTypeResponse {
  /**
   *
   * @type {Array<GetEventType>}
   * @memberof GetEventTypeResponse
   */
  events: Array<GetEventType>;
}

export function GetEventTypeResponseFromJSON(json: any): GetEventTypeResponse {
  return GetEventTypeResponseFromJSONTyped(json, false);
}

export function GetEventTypeResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetEventTypeResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    events: (json["events"] as Array<any>).map(GetEventTypeFromJSON),
  };
}

export function GetEventTypeResponseToJSON(
  value?: GetEventTypeResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    events: (value.events as Array<any>).map(GetEventTypeToJSON),
  };
}
