import React, { useEffect, useState } from "react";
import MSearchBox from "./MSearchBox";
import { TEXT_BLUR_DELAY } from "../../util/constants";
import ClickableTagList from "./ClickableTagList";
import MBadge from "./MBadge";

type TagSearchAndSelectProps = {
  searchTerm?: string | null;
  selected?: string[] | null;
  onSearchTermUpdated: (newTerm: string) => void;
  onSelectedTagsUpdated: (newTags: string[]) => void;
  tags: string[] | null;
  placeholder?: string;
  loading?: boolean;
  disabled?: boolean;
};

const TagSearchAndSelectComponent = (props: TagSearchAndSelectProps) => {
  const {
    searchTerm: searchTermInput,
    selected: selectedInput,
    onSearchTermUpdated,
    onSelectedTagsUpdated: onSelectedTagsUpdatedInput,
    tags: tagsInput,
    placeholder,
    loading,
    disabled,
  } = props;

  const [tags, setTags] = useState<string[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [showTagResults, setShowTagResults] = useState<boolean>(false);

  const filteredTags = tags.filter((tag) => !selected.includes(tag));

  const onTagSelected = (tag: string) => {
    const newTags = [...selected, tag];
    setSelected(newTags);
    onSelectedTagsUpdatedInput(newTags);
  };

  const onTagUnselected = (tag: string) => {
    const newTags = selected.filter((t) => t !== tag);
    setSelected(newTags);
    onSelectedTagsUpdatedInput(newTags);
  };

  useEffect(() => {
    setTags(tagsInput ?? []);
  }, [tagsInput]);

  useEffect(() => {
    setSelected(selectedInput ?? []);
  }, [selectedInput]);

  return (
    <div className="flex flex-row w-full gap-3 items-center">
      <div className="grow">
        {selected.length > 0 && (
          <div className="flex flex-row gap-2">
            {selected.map((tag) => (
              <MBadge
                key={tag}
                content={tag}
                onCancelClick={() => onTagUnselected(tag)}
              />
            ))}
          </div>
        )}
        {selected.length === 0 && (
          <div className="text-sm text-m-gray">no tags selected</div>
        )}
      </div>
      <div>
        <MSearchBox
          onSearchTermUpdated={onSearchTermUpdated}
          searchTerm={searchTermInput}
          placeholder={placeholder}
          disabled={disabled}
          onFocus={() => setShowTagResults(true)}
          onBlur={() =>
            setTimeout(() => {
              setShowTagResults(false);
            }, TEXT_BLUR_DELAY)
          }
          below={
            showTagResults && (
              <ClickableTagList
                tags={filteredTags}
                onClick={onTagSelected}
                disabled={disabled}
                loading={loading}
              />
            )
          }
        />
      </div>
    </div>
  );
};

TagSearchAndSelectComponent.defaultProps = {
  searchTerm: null,
  placeholder: "search tags",
  loading: false,
  disabled: false,
  selected: null,
};

export default TagSearchAndSelectComponent;
