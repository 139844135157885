/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UpdatePromptResponseOrderRequest
 */
export interface UpdatePromptResponseOrderRequest {
  /**
   *
   * @type {string}
   * @memberof UpdatePromptResponseOrderRequest
   */
  presentation: string;
  /**
   *
   * @type {number}
   * @memberof UpdatePromptResponseOrderRequest
   */
  order: number;
}

export function UpdatePromptResponseOrderRequestFromJSON(
  json: any
): UpdatePromptResponseOrderRequest {
  return UpdatePromptResponseOrderRequestFromJSONTyped(json, false);
}

export function UpdatePromptResponseOrderRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdatePromptResponseOrderRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    presentation: json["presentation"],
    order: json["order"],
  };
}

export function UpdatePromptResponseOrderRequestToJSON(
  value?: UpdatePromptResponseOrderRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    presentation: value.presentation,
    order: value.order,
  };
}
