/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UserProfileSummary
 */
export interface UserProfileSummary {
  /**
   *
   * @type {string}
   * @memberof UserProfileSummary
   */
  username?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserProfileSummary
   */
  readonly photo?: string;
  /**
   *
   * @type {string}
   * @memberof UserProfileSummary
   */
  readonly id?: string;
  /**
   *
   * @type {string}
   * @memberof UserProfileSummary
   */
  readonly profile_url?: string;
  /**
   *
   * @type {string}
   * @memberof UserProfileSummary
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserProfileSummary
   */
  pronouns?: string | null;
  /**
   * The user's current title at their current employer.
   * @type {string}
   * @memberof UserProfileSummary
   */
  current_title?: string | null;
  /**
   * The user's current employer.
   * @type {string}
   * @memberof UserProfileSummary
   */
  current_employer?: string | null;
}

export function UserProfileSummaryFromJSON(json: any): UserProfileSummary {
  return UserProfileSummaryFromJSONTyped(json, false);
}

export function UserProfileSummaryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserProfileSummary {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    username: !exists(json, "username") ? undefined : json["username"],
    photo: !exists(json, "photo") ? undefined : json["photo"],
    id: !exists(json, "id") ? undefined : json["id"],
    profile_url: !exists(json, "profile_url") ? undefined : json["profile_url"],
    name: !exists(json, "name") ? undefined : json["name"],
    pronouns: !exists(json, "pronouns") ? undefined : json["pronouns"],
    current_title: !exists(json, "current_title")
      ? undefined
      : json["current_title"],
    current_employer: !exists(json, "current_employer")
      ? undefined
      : json["current_employer"],
  };
}

export function UserProfileSummaryToJSON(
  value?: UserProfileSummary | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    username: value.username,
    name: value.name,
    pronouns: value.pronouns,
    current_title: value.current_title,
    current_employer: value.current_employer,
  };
}
