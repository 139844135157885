import React, { useEffect, useState } from "react";
import { MNavProps } from "../../../ui/nav/MNav";
import BaseNavbarPage from "../../BaseNavbarPage";
import WizardScreen, {
  WizardScreenPanel,
} from "../../../ui/screens/WizardScreen";
import LoadingScreen from "../../../ui/screens/LoadingScreen";
import {
  GetUserVitalsResponse,
  SetUserVitalsRequest,
} from "../../../../api/models";
import { useWrappedConnector } from "../../../../api/connector";
import { useBusyWatcher } from "../../../../util/hooks";
import {
  errorMessagesForKeyFromResponse,
  errorMessagesFromResponse,
} from "../../../../api/helpers";
import UpdateVitalsScreen from "./UpdateVitalsScreen";

type VitalsPageProps = {
  nextUrl: string;
  navbarProps: MNavProps;
};

enum VitalsStage {
  LOADING,
  UPDATE,
}

const VitalsPageComponent = (props: VitalsPageProps) => {
  const { nextUrl, navbarProps } = props;

  const [errors, setErrors] = useState<string[]>([]);
  const [stage, setStage] = useState<VitalsStage>(VitalsStage.LOADING);
  const [vitals, setVitals] = useState<GetUserVitalsResponse | null>(null);
  const [vitalsErrors, setVitalsErrors] = useState<{ [key: string]: string[] }>(
    {}
  );
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);

  const goToStage = async (nextStage: VitalsStage) => {
    // eslint-disable-next-line default-case
    switch (nextStage) {
      case VitalsStage.UPDATE: {
        try {
          const response = await connector.getVitals();
          setVitalsErrors({});
          setVitals(response.c!);
        } catch (e: any) {
          const parsed = await errorMessagesForKeyFromResponse(e, "", true);
          setErrors(parsed);
        }
        break;
      }
    }
    setStage(nextStage);
  };

  const onSaveClicked = async (newVitals: SetUserVitalsRequest) => {
    try {
      await connector.setVitals(newVitals);
      window.location.href = nextUrl;
    } catch (e: any) {
      const responseErrors = await errorMessagesFromResponse(e);
      setVitalsErrors(responseErrors);
    }
  };

  const getElements = (): WizardScreenPanel[] => [
    {
      stage: VitalsStage.LOADING,
      content: (
        <LoadingScreen
          errors={errors}
          onBackClicked={() => window.history.back()}
        />
      ),
    },
    {
      stage: VitalsStage.UPDATE,
      content: vitals && (
        <UpdateVitalsScreen
          vitals={vitals}
          onSaveClicked={onSaveClicked}
          errors={vitalsErrors}
        />
      ),
    },
  ];

  useEffect(() => {
    goToStage(VitalsStage.UPDATE);
  }, []);

  return (
    <BaseNavbarPage navbarProps={navbarProps}>
      <WizardScreen elements={getElements()} stage={stage} />
    </BaseNavbarPage>
  );
};

export default VitalsPageComponent;
