import {
  AtSymbolIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { MNavProps } from "../../ui/nav/MNav";
import SidebarNavScreen, {
  NavigationItem,
} from "../../ui/screens/sidebarnav/SidebarNavScreen";
import { useBusyWatcher } from "../../../util/hooks";
import { useWrappedConnector } from "../../../api/connector";
import BaseNavbarPage from "../BaseNavbarPage";
import { SearchForQuestionsRequest } from "../../../api/apis/QuestionsApi";
import { SearchForQuestionsResponse } from "../../../api/models";
import QuestionSearchScreen from "./QuestionSearchScreen";
import { errorMessagesForKeyFromResponse } from "../../../api/helpers";
import { getQueryParam } from "../../../util/url";
import { PAGINATION_LIMIT } from "../../../util/constants";

const secondaryNavigation: NavigationItem[] = [
  { name: "questions", icon: QuestionMarkCircleIcon },
  { name: "my questions", icon: AtSymbolIcon },
];

type QuestionSearchPageProps = {
  questionsSearchUrl: string;
  createQuestionUrl?: string | null;
  navbarProps: MNavProps;
};

/**
 * Since there are so many different ways to put a search query together, we
 * want to model those possibilities in the placeholder. This is the
 * predictable, testable method that returns a string, so that the
 * `generateRandomPlaceholder` can randomize it.
 * @param {boolean} keyword - A boolean flagging if the placeholder should
 *   include search terms or not.
 * @param {string | null} author - If set to `null`, the placeholder will not
 *   specify an author filter. If set to a string, that string will be
 *   prepended to our example author, `jdoe` (expected values are `null`, a
 *   null string to present `jdoe`, or `@` to present `@jdoe`).
 * @param {string | null} tags - If set to `null`, the placeholder will not
 *   specify any tag filters. If set to a null string, the tags will be
 *   individually tagged with `tag:`. If set to another string, that string
 *   will be used as a separator (expected values are `null`, a null string,
 *   a comma, or a semicolon).
 */
export const generatePlaceholder = (
  keyword: boolean,
  author: string | null,
  tags: string | null
): string => {
  const elems = [];
  if (keyword) elems.push("search terms");
  if (author !== null) elems.push(`author: ${author}jdoe`);
  if (tags !== null) {
    const t = ["work", "hobbies"];
    elems.push(
      tags === ""
        ? t.map((tag) => `tag: ${tag}`).join(" ")
        : `tag: ${t.join(`${tags} `)}`
    );
  }
  return elems.join(" ");
};

export const randFromArr = <T,>(arr: T[]): T | null => {
  if (arr.length === 0) return null;
  const index = Math.floor(Math.random() * arr.length);
  return arr[index];
};

const generateRandomPlaceholder = (): string => {
  const searchTerm = randFromArr([true, false]) ?? false;
  const author = randFromArr([null, "", "@"]);
  const tags = randFromArr([null, "", ",", ";"]);
  return generatePlaceholder(searchTerm, author, tags);
};

/**
 * Parses the query string that a user submits into a SearchForQuestionsRequest
 * object.
 * @param {string} query - The string that a user submits in the search box.
 * @returns {SearchForQuestionsRequest} - An object that can be sent to the API
 *   to conduct a search.
 * @property {string} searchTerm - The keyword used to search question text.
 * @property {string | undefined} author - An author to filter the results by.
 * @property {string[] | undefined} tags - An array of tags that the search
 *   results can be filtered by.
 */

export const parseQuestionSearchQuery = (
  query: string
): SearchForQuestionsRequest => {
  /**
   * While the search term does have to come first, users can enter tags and
   * the author in any order. Tags can be separated by commas or semicolons,
   * or you can identify each tag separately. This even allows for the
   * possibility of defining one or more tags, then specifying the author, and
   * then defining a few more tags. This complexity is beyond even the most
   * sophisticted regular expressions, so we need to build a finite state
   * machine to parse it.
   */

  const tokens = query.split(" "); // Go word by word
  const searchTerm: string[] = []; // We'll build up terms as we find them
  let author: string | undefined; // Starts off undefined
  let tags: string[] = []; // We'll build up tags as we find them
  let tag: string[] = []; // We'll have to build up each tag individually
  let state = "search_term"; // The search term comes first.

  /**
   * There are a few different circumstances under which we'll join the
   * elements of `tag` and append them to `tags`, so we wrap this in its
   * own little method to reduce repetition and make our code more
   * readable.
   */
  const finishTag = () => {
    tags.push(tag.join(" "));
    tag = [];
  };

  /**
   * Entering a new state would just be a matter of setting the `state` string
   * to a new value, but we have some behavior we want to step through each
   * time we do that. Right now, that's calling our `finishTag` function, but
   * this also means we can expand that in the future if needs be.
   * @param {string} newState - The new state to enter.
   */
  const changeState = (newState: string) => {
    state = newState;
    finishTag();
  };

  for (let i = 0; i < tokens.length; i += 1) {
    // Airbnb ESLint rules forbid for (const token of tokens), so we have to
    // use the old ES5 syntax here.
    const token = tokens[i].trim();
    const tagMarker = "tag:";
    const authorMarker = "author:";

    if (token.startsWith(tagMarker)) {
      // "tag:" means that we're entering the tag state, in which we're
      // building tags.
      changeState("tag");
      if (token.length > tagMarker.length) {
        tag.push(token.substring(tagMarker.length));
      }
    } else if (token.startsWith("author:")) {
      // "author:" means that we're entering the author state. The words that
      // follow are interpreted as authors. Since we only take one author, each
      // successive one will replace any author we found before.
      changeState("author");
      if (token.length > authorMarker.length) {
        author = token.substring(authorMarker.length).replace("@", "");
      }
    } else if (state === "search_term") {
      // In the "search_term" state, tokens are appended to the `searchTerm`
      // array. At the end, we'll join these together to form a string.
      searchTerm.push(token);
    } else if (state === "tag") {
      // In the "tag" state, tokens are appended to the `tag` array. At the
      // end, we'll break them up by comma and semicolon and sort them into
      // a single array of strings.
      tag.push(token);
    } else if (state === "author") {
      // In the "author" state, each new token is set to be the author. If the
      // `@` character is in the string, we remove it, so that we get `jdoe`
      // and not `@jdoe`.
      author = token.replace("@", "");
    }
  }

  // One last call to `finishTag` to flush anything we might have in our array
  // when the loop finishes.
  finishTag();

  /**
   * Above, we used `finishTag` only when we exited the "tag:" state, whether
   * by entering a new state or by ending the loop entirely (being the two ways
   * that one can exit the "tag" state). If the user opted to individually tag
   * each tag (e.g., `tag: example tag: test`) then that's enough, but we're
   * also accepted tags separated by commas (e.g., `tag: example, test`) and
   * tags separated by semicolons (e.g., `tag: example; test`). This means that
   * at this point in the method, `tags` contains a combination of single tags,
   * tags separated by commas, and tags separated by semicolons. We want to
   * turn this into an array of single tags alone. That's what these options
   * do. First we split each item by any commas or semicolons that occur, then
   * we flatten the list, and finally we filter out any null strings that may
   * have occurred along the way.
   */
  tags = tags
    .map((t) => t.split(/[,;]\s*/))
    .flat()
    .filter((t) => t.length > 0);

  // Compared to tags, the search term is easy, since it's always the first
  // part of the search. We can just join those tokens together.
  const term = searchTerm.join(" ");

  // We now have all of our elements, so we can just put them together in the
  // shape of a SearchForQuestionsRequest object and return it.
  const req: SearchForQuestionsRequest = {
    search_term: term.length > 0 ? term : "",
  };
  if (tags.length > 0) req.tags = tags;
  if (author && author.length > 0) req.author = author;
  return req;
};

const QuestionSearchPageComponent = (props: QuestionSearchPageProps) => {
  const { questionsSearchUrl, createQuestionUrl, navbarProps } = props;

  const queryTag = getQueryParam("tag");

  const [loading, setLoading] = useState<boolean>(true);
  const [curPane, setCurPane] = useState<string>(secondaryNavigation[0].name);
  const [userSearchTerm, setUserSearchTerm] = useState<string>(
    queryTag ? `tag: ${queryTag}` : ""
  );
  const [placeholder] = useState<string>(generateRandomPlaceholder());
  const [questionsOffset, setQuestionsOffset] = useState<number>(0);
  const [questions, setQuestions] = useState<SearchForQuestionsResponse | null>(
    null
  );
  const [errorsList, setErrorsList] = useState<string[]>([]);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);

  const searchQuestions = async (
    questionsSearchTerm: string,
    offset: number,
    selfOnly: boolean
  ) => {
    setErrorsList([]);
    try {
      const query = parseQuestionSearchQuery(questionsSearchTerm);
      const response = await connector.searchQuestions({
        ...query,
        limit: PAGINATION_LIMIT,
        self_only: selfOnly,
        offset,
      });
      setQuestions(response.c!);
      setUserSearchTerm(questionsSearchTerm);
      setQuestionsOffset(offset);
      setLoading(false);
    } catch (e: any) {
      const parsed = await errorMessagesForKeyFromResponse(
        e,
        "search_term",
        true
      );
      setErrorsList(parsed);
    }
  };

  const goToPane = async (nextPane: string, clearSearchTerm: boolean) => {
    setLoading(true);
    setCurPane(nextPane);
    setErrorsList([]);
    let newSearchTerm = userSearchTerm;
    if (clearSearchTerm) {
      newSearchTerm = "";
    }
    // eslint-disable-next-line default-case
    switch (nextPane) {
      case "questions":
        await searchQuestions(newSearchTerm, 0, false);
        break;
      case "my questions":
        await searchQuestions(newSearchTerm, 0, true);
        break;
    }
    setLoading(false);
  };

  const getFilteredNavigation = (): NavigationItem[] => {
    const toReturn = [secondaryNavigation[0]];
    if (createQuestionUrl) {
      toReturn.push(secondaryNavigation[1]);
    }
    return toReturn;
  };

  return (
    <BaseNavbarPage navbarProps={{ ...navbarProps, showSearch: false }}>
      <SidebarNavScreen
        items={getFilteredNavigation().map((item) => ({
          ...item,
          current: item.name === curPane,
        }))}
        onNavItemClicked={(item) => goToPane(item.name, true)}
      >
        {curPane === "questions" && (
          <QuestionSearchScreen
            title="questions"
            loading={loading}
            response={questions}
            errors={errorsList}
            searchTerm={userSearchTerm}
            onSearchTermUpdated={(term: string) =>
              searchQuestions(term, 0, false)
            }
            onPageClicked={(page: number) =>
              searchQuestions(
                userSearchTerm,
                (page - 1) * PAGINATION_LIMIT,
                false
              )
            }
            offset={questionsOffset}
            pageSize={PAGINATION_LIMIT}
            searchBaseUrl={questionsSearchUrl}
            createQuestionUrl={createQuestionUrl}
            placeholder={placeholder}
          />
        )}
        {curPane === "my questions" && (
          <QuestionSearchScreen
            title="my questions"
            loading={loading}
            response={questions}
            errors={errorsList}
            searchTerm={userSearchTerm}
            onSearchTermUpdated={(term: string) =>
              searchQuestions(term, 0, true)
            }
            onPageClicked={(page: number) =>
              searchQuestions(
                userSearchTerm,
                (page - 1) * PAGINATION_LIMIT,
                true
              )
            }
            offset={questionsOffset}
            pageSize={PAGINATION_LIMIT}
            searchBaseUrl={questionsSearchUrl}
            createQuestionUrl={createQuestionUrl}
            placeholder={placeholder}
          />
        )}
      </SidebarNavScreen>
    </BaseNavbarPage>
  );
};

QuestionSearchPageComponent.defaultProps = {
  createQuestionUrl: null,
};

export default QuestionSearchPageComponent;
