/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface QuestionnaireInferenceDetail
 */
export interface QuestionnaireInferenceDetail {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof QuestionnaireInferenceDetail
   */
  readonly guid?: string;
  /**
   *
   * @type {string}
   * @memberof QuestionnaireInferenceDetail
   */
  source_id: string;
  /**
   *
   * @type {string}
   * @memberof QuestionnaireInferenceDetail
   */
  target_id: string;
  /**
   * The task ID for the Celery task that was enqueued to perform inference.
   * @type {string}
   * @memberof QuestionnaireInferenceDetail
   */
  task_id?: string | null;
  /**
   * Whether or not the referenced Celery task has been completed.
   * @type {boolean}
   * @memberof QuestionnaireInferenceDetail
   */
  is_task_completed?: boolean;
  /**
   * The time at which this record was created.
   * @type {Date}
   * @memberof QuestionnaireInferenceDetail
   */
  readonly time_created?: Date;
}

export function QuestionnaireInferenceDetailFromJSON(
  json: any
): QuestionnaireInferenceDetail {
  return QuestionnaireInferenceDetailFromJSONTyped(json, false);
}

export function QuestionnaireInferenceDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): QuestionnaireInferenceDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    source_id: json["source_id"],
    target_id: json["target_id"],
    task_id: !exists(json, "task_id") ? undefined : json["task_id"],
    is_task_completed: !exists(json, "is_task_completed")
      ? undefined
      : json["is_task_completed"],
    time_created: !exists(json, "time_created")
      ? undefined
      : new Date(json["time_created"]),
  };
}

export function QuestionnaireInferenceDetailToJSON(
  value?: QuestionnaireInferenceDetail | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    source_id: value.source_id,
    target_id: value.target_id,
    task_id: value.task_id,
    is_task_completed: value.is_task_completed,
  };
}
