import React, { useState } from "react";
import {
  CreateEmployerRequest,
  EmployerRelatedDetail,
} from "../../../../api/models";
import { useBusyWatcher } from "../../../../util/hooks";
import FormScreen from "../../../ui/screens/FormScreen";
import MInput from "../../../ui/MInput";

type EditEmployerScreenProps = {
  employer?: EmployerRelatedDetail | null;
  errors: { [key: string]: string[] };
  onSaveClicked: (employer: CreateEmployerRequest) => void;
  onBackClicked: () => void;
};

const EditEmployerScreenComponent = (props: EditEmployerScreenProps) => {
  const { employer, errors, onSaveClicked, onBackClicked } = props;

  const [name, setName] = useState<string>(employer?.name ?? "");
  const [busy, _] = useBusyWatcher();

  const canSubmit = (): boolean => {
    if (name.length > 128 || name.length === 0) {
      return false;
    }
    return true;
  };

  const submit = () => {
    onSaveClicked({ name });
  };

  return (
    <FormScreen
      title={employer ? "edit employer" : "add employer"}
      onNextClicked={submit}
      nextDisabled={busy || !canSubmit()}
      onBackClicked={onBackClicked}
      backDisabled={busy}
      nextText="save employer"
      fullWidth
    >
      <MInput
        value={name}
        onChange={(e) => setName(e.target.value)}
        onEnterPressed={() => canSubmit() && submit()}
        disabled={busy}
        errors={errors.name}
        label="Company Name"
        placeholder="ex: ACME Inc."
      />
    </FormScreen>
  );
};

EditEmployerScreenComponent.defaultProps = {
  employer: null,
};

export default EditEmployerScreenComponent;
