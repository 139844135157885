/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UpdateLinkRequest
 */
export interface UpdateLinkRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateLinkRequest
   */
  link: string;
  /**
   *
   * @type {string}
   * @memberof UpdateLinkRequest
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof UpdateLinkRequest
   */
  url: string;
}

export function UpdateLinkRequestFromJSON(json: any): UpdateLinkRequest {
  return UpdateLinkRequestFromJSONTyped(json, false);
}

export function UpdateLinkRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateLinkRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    link: json["link"],
    title: json["title"],
    url: json["url"],
  };
}

export function UpdateLinkRequestToJSON(value?: UpdateLinkRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    link: value.link,
    title: value.title,
    url: value.url,
  };
}
