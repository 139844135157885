/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ContactUserViaGlimpseRequest
 */
export interface ContactUserViaGlimpseRequest {
  /**
   *
   * @type {string}
   * @memberof ContactUserViaGlimpseRequest
   */
  glimpse: string;
  /**
   *
   * @type {string}
   * @memberof ContactUserViaGlimpseRequest
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof ContactUserViaGlimpseRequest
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof ContactUserViaGlimpseRequest
   */
  phone?: string | null;
  /**
   *
   * @type {string}
   * @memberof ContactUserViaGlimpseRequest
   */
  message: string;
}

export function ContactUserViaGlimpseRequestFromJSON(
  json: any
): ContactUserViaGlimpseRequest {
  return ContactUserViaGlimpseRequestFromJSONTyped(json, false);
}

export function ContactUserViaGlimpseRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ContactUserViaGlimpseRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    glimpse: json["glimpse"],
    name: !exists(json, "name") ? undefined : json["name"],
    email: !exists(json, "email") ? undefined : json["email"],
    phone: !exists(json, "phone") ? undefined : json["phone"],
    message: json["message"],
  };
}

export function ContactUserViaGlimpseRequestToJSON(
  value?: ContactUserViaGlimpseRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    glimpse: value.glimpse,
    name: value.name,
    email: value.email,
    phone: value.phone,
    message: value.message,
  };
}
