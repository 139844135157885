import { Configuration } from "./runtime";
import {
  AddResumeRequest,
  AddUserLinkRequest,
  ContactUserViaGlimpseRequest,
  CreateEmployerRequest,
  CreateGroupRequest,
  CreateOrUpdateSingleEntryRequest,
  CreateRoleRequest,
  CreateSingleUserQuestionInsightRequest,
  DeleteGenerationRequest,
  MarkNotificationsAsClearedRequest,
  MarkNotificationsAsNotClearedRequest,
  MarkNotificationsAsViewedRequest,
  PromptContent,
  RateLLMResultsRequest,
  RemoveConnectionRequest,
  RequestConnectionRequest,
  RespondToConnectionRequestRequest,
  SaveGenerationRequest,
  SetBasicInfo,
  SetGlimpseResumeVisibilityRequest,
  SetGlimpseVisibilityRequest,
  SetLinksForGlimpseRequest,
  SetProfileAccessRequest,
  SetPromptResponsePresentationVisibilityRequest,
  SetPromptResponsesForGlimpseRequest,
  SetPromptResponseVisibilityRequest,
  SetTargetResponsesRequest,
  SetUserVitalsRequest,
  SubmitOnboardingPromptResponseRequest,
  SubmitPromptResponseRequest,
  UpdateEmployerRequest,
  UpdateGlimpseContentRequest,
  UpdateGlimpsePitchAudienceRequest,
  UpdateGlimpsePitchContextRequest,
  UpdateGlimpseTitleRequest,
  UpdateGroupRequest,
  UpdateLinkRequest,
  UpdateRoleRequest,
  UpdateUserSettingsRequest,
  UsernameOrId,
  VoteOnQuestionRequest,
} from "./models";
import { UserApi } from "./apis/UserApi";
import ToastOnErrorMiddleware from "./toastMiddleware";
import {
  GetProfileRequest,
  GetProfileSummaryRequest,
  ProfileApi,
} from "./apis/ProfileApi";
import { AuthApi } from "./apis/AuthApi";
import {
  DeleteQuestionOperationRequest,
  QuestionOperationsRequest,
  QuestionsApi,
  SearchForQuestionsRequest,
  UpdateQuestionOperationRequest,
} from "./apis/QuestionsApi";
import { TasksApi } from "./apis/TasksApi";

import { CollabsApi, CollabsLastForUserRequest } from "./apis/CollabsApi";
import { CommCoachApi } from "./apis/CommCoachApi";
import { InsightsApi } from "./apis/InsightsApi";
import { AssessmentsApi } from "./apis/AssessmentsApi";
import { StripeApi } from "./apis/StripeApi";
import { OnboardingApi } from "./apis/OnboardingApi";
import { LinksApi } from "./apis/LinksApi";
import { WorkHistoryApi } from "./apis/WorkHistoryApi";
import {
  AiApi,
  DeleteInsightOperationRequest,
  SearchForInsightsRequest,
} from "./apis/AiApi";
import { PrivacyApi } from "./apis/PrivacyApi";
import { GlimpseApi } from "./apis/GlimpseApi";
import { EventsApi } from "./apis/EventsApi";
import {
  AddUsersRequest,
  AskQuestionAboutGroupRequest,
  DeleteGroupQnaRequest,
  GetGroupQnaRequest,
  GroupsApi,
  RemoveUsersRequest,
  SelectFilesRequest,
} from "./apis/GroupsApi";
import { ListNotificationsRequest, NotifsApi } from "./apis/NotifsApi";
import {
  ConnectionApi,
  SearchForConnectionRequest,
} from "./apis/ConnectionApi";

export class ManualAPIConnector {
  private readonly wrapper: <Type>(p: Promise<Type>) => Promise<Type>;

  public basePath = "http://localhost:8000/api";

  private user: UserApi = new UserApi();

  private profile: ProfileApi = new ProfileApi();

  private auth: AuthApi = new AuthApi();

  private questions: QuestionsApi = new QuestionsApi();

  private tasks: TasksApi = new TasksApi();

  private collabs: CollabsApi = new CollabsApi();

  private commCoachApi: CommCoachApi = new CommCoachApi();

  private insightsApi: InsightsApi = new InsightsApi();

  private assessmentsApi: AssessmentsApi = new AssessmentsApi();

  private stripeApi: StripeApi = new StripeApi();

  private onboardingApi: OnboardingApi = new OnboardingApi();

  private linksApi: LinksApi = new LinksApi();

  private workHistoryApi: WorkHistoryApi = new WorkHistoryApi();

  private ai: AiApi = new AiApi();

  private privacy: PrivacyApi = new PrivacyApi();

  private glimpse: GlimpseApi = new GlimpseApi();

  private groups: GroupsApi = new GroupsApi();

  private events: EventsApi = new EventsApi();

  private notifs: NotifsApi = new NotifsApi();

  private connection: ConnectionApi = new ConnectionApi();

  private getConfiguration = (): Configuration =>
    new Configuration({
      credentials: "include",
      basePath: this.basePath,
      middleware: [new ToastOnErrorMiddleware()],
    });

  private setApis = (): void => {
    const config = this.getConfiguration();
    this.user = new UserApi(config);
    this.profile = new ProfileApi(config);
    this.auth = new AuthApi(config);
    this.questions = new QuestionsApi(config);
    this.tasks = new TasksApi(config);
    this.collabs = new CollabsApi(config);
    this.commCoachApi = new CommCoachApi(config);
    this.insightsApi = new InsightsApi(config);
    this.assessmentsApi = new AssessmentsApi(config);
    this.stripeApi = new StripeApi(config);
    this.onboardingApi = new OnboardingApi(config);
    this.linksApi = new LinksApi(config);
    this.workHistoryApi = new WorkHistoryApi(config);
    this.ai = new AiApi(config);
    this.privacy = new PrivacyApi(config);
    this.glimpse = new GlimpseApi(config);
    this.groups = new GroupsApi(config);
    this.notifs = new NotifsApi(config);
    this.connection = new ConnectionApi(config);
  };

  constructor(wrpr: (<Type>(p: Promise<Type>) => Promise<Type>) | null = null) {
    this.setApis();
    if (wrpr) {
      this.wrapper = wrpr;
    } else {
      this.wrapper = async <Type>(promise: Promise<Type>): Promise<Type> =>
        promise;
    }
  }

  setApiBasePath = (path: string): void => {
    if (path !== this.basePath) {
      this.basePath = path;
      this.setApis();
    }
  };

  getProfilePresentationLayer = () =>
    this.wrapper(this.profile.getProfilePresentationLayer());

  setPromptResponseOrder = (presentation: string, order: number) =>
    this.wrapper(
      this.profile.updatePromptResponseOrder({
        data: { presentation, order },
      })
    );

  getResponseHistoryForPrompt = (prompt: string) =>
    this.wrapper(this.questions.getPromptResponseHistory({ prompt }));

  askUserQuestion = (user: string, question: string) =>
    this.wrapper(this.questions.askUserQuestion({ data: { user, question } }));

  getTaskStatus = (task: string) =>
    this.wrapper(this.tasks.getTaskStatus({ task }));

  getQuestionAndResponse = (qnr: string) =>
    this.wrapper(this.questions.getQnr({ qnr }));

  inviteEmail = (email: string) =>
    this.wrapper(this.user.userInvite({ data: { email } }));

  updateSettings = (data: UpdateUserSettingsRequest) =>
    this.wrapper(this.user.updateUserSettings({ data }));

  getOnboardingStage = () =>
    this.wrapper(this.onboardingApi.getOnboardingStage());

  getOnboardingBasics = () =>
    this.wrapper(this.onboardingApi.getOnboardingBasics());

  setOnboardingBasics = (data: SetBasicInfo) =>
    this.wrapper(this.onboardingApi.setOnboardingBasics({ data }));

  submitOnboardingPromptResponse = (
    data: SubmitOnboardingPromptResponseRequest
  ) => this.wrapper(this.onboardingApi.submitOnboardingResponse({ data }));

  setOnboardingUsername = (username: string) =>
    this.wrapper(
      this.onboardingApi.setOnboardingUsername({ data: { username } })
    );

  getQuestionnaire = (questionnaireId: string) =>
    this.wrapper(
      this.questions.getQuestionnaire({
        questionnaire: questionnaireId,
      })
    );

  getPrompt = (prompt: string) =>
    this.wrapper(this.questions.getPrompt({ prompt }));

  getPromptWithResponse = (prompt: string) =>
    this.wrapper(this.questions.getPromptWithResponse({ prompt }));

  submitPromptResponse = (data: SubmitPromptResponseRequest) =>
    this.wrapper(
      this.questions.respondToPrompt({
        data,
      })
    );

  getUsername = () => this.wrapper(this.profile.getUsername());

  startEmailAuth = (email: string, redirect: string | null) =>
    this.wrapper(this.auth.emailAuth({ data: { email, redirect } }));

  getProfile = (data: GetProfileRequest) =>
    this.wrapper(this.profile.getProfile(data));

  listAssessments = () => this.wrapper(this.assessmentsApi.listAssessments());

  getAssessment = (assessmentId: string) =>
    this.wrapper(
      this.assessmentsApi.getAssessment({ assessment: assessmentId })
    );

  getNextPromptForAssessment = (assessmentId: string) =>
    this.wrapper(
      this.assessmentsApi.getNextPromptForAssessment({
        assessment: assessmentId,
      })
    );

  getPromptForAssessment = (assessmentId: string, promptId: string) =>
    this.wrapper(
      this.assessmentsApi.getPromptForAssessment({
        assessment: assessmentId,
        prompt: promptId,
      })
    );

  processAssessment = (assessmentId: string) =>
    this.wrapper(
      this.assessmentsApi.processAssessment({
        data: { assessment: assessmentId },
      })
    );

  getAssessmentResult = (inferenceId: string) =>
    this.wrapper(
      this.assessmentsApi.getAssessmentResult({ inference: inferenceId })
    );

  submitAssessmentResults = (data: SetTargetResponsesRequest) =>
    this.wrapper(this.assessmentsApi.setAssessmentResponses({ data }));

  getSubscriptionTiers = () =>
    this.wrapper(this.stripeApi.getStripeSubscriptionTiers());

  getSubscription = () => this.wrapper(this.stripeApi.getStripeSubscription());

  uploadResume = (data: AddResumeRequest) =>
    this.wrapper(this.profile.addResume({ data }));

  getResume = () => this.wrapper(this.profile.getResume());

  deleteResume = () => this.wrapper(this.profile.deleteResume({ data: {} }));

  getAttachments = () => this.wrapper(this.profile.getAttachments());

  getVitals = () => this.wrapper(this.profile.getUserVitals());

  setVitals = (data: SetUserVitalsRequest) =>
    this.wrapper(this.profile.setUserVitals({ data }));

  listLinks = () => this.wrapper(this.linksApi.listLinks());

  addLink = (data: AddUserLinkRequest) =>
    this.wrapper(this.linksApi.addLink({ data }));

  updateLink = (data: UpdateLinkRequest) =>
    this.wrapper(this.linksApi.updateLink({ data }));

  deleteLink = (link: string) =>
    this.wrapper(this.linksApi.deleteLink({ data: { link } }));

  listEmployers = () => this.wrapper(this.workHistoryApi.listEmployers());

  createEmployer = (data: CreateEmployerRequest) =>
    this.wrapper(this.workHistoryApi.createEmployer({ data }));

  updateEmployer = (data: UpdateEmployerRequest) =>
    this.wrapper(this.workHistoryApi.updateEmployer({ data }));

  deleteEmployer = (employer: string) =>
    this.wrapper(this.workHistoryApi.deleteEmployer({ data: { employer } }));

  deleteRole = (role: string) =>
    this.wrapper(this.workHistoryApi.deleteEmployerRole({ data: { role } }));

  createRole = (data: CreateRoleRequest) =>
    this.wrapper(this.workHistoryApi.createEmployerRole({ data }));

  updateRole = (data: UpdateRoleRequest) =>
    this.wrapper(this.workHistoryApi.updateEmployerRole({ data }));

  clearProfilePhoto = () =>
    this.wrapper(this.profile.clearProfilePhoto({ data: {} }));

  getProfilePhoto = () => this.wrapper(this.profile.getProfilePhoto());

  uploadProfilePhoto = (dataUrl: string) =>
    this.wrapper(
      this.profile.setProfilePhoto({ data: { img_data_url: dataUrl } })
    );

  getProfileTagline = () => this.wrapper(this.profile.getProfileTagline());

  getGenTaglineTodos = () => this.wrapper(this.ai.genTaglineCheck());

  genTagline = () => this.wrapper(this.ai.genTagline({ data: {} }));

  getGenTagline = (tagline: string) =>
    this.wrapper(this.ai.getGenTagline({ tagline }));

  setHeadline = (headline: string | null) =>
    this.wrapper(this.profile.setProfileHeadline({ data: { headline } }));

  setBio = (bio: string | null) =>
    this.wrapper(this.profile.setProfileBio({ data: { bio } }));

  markAssessmentAsCompleted = (assessment: string) =>
    this.wrapper(
      this.assessmentsApi.markAssessmentAsCompleted({ data: { assessment } })
    );

  markQuestionnaireAsCompleted = (questionnaire: string) =>
    this.wrapper(
      this.questions.markQuestionnaireAsCompleted({ data: { questionnaire } })
    );

  listQuestionnaires = () => this.wrapper(this.questions.listQuestionnaires());

  listResponseDetails = (presentation: string | null = null) =>
    this.wrapper(
      this.questions.listResponseDetails(presentation ? { presentation } : {})
    );

  clearPromptResponse = (promptGuid: string) =>
    this.wrapper(
      this.questions.clearPromptResponse({ data: { prompt: promptGuid } })
    );

  setPromptResponseVisibility = (data: SetPromptResponseVisibilityRequest) =>
    this.wrapper(this.profile.setPromptResponseVisibility({ data }));

  setPromptResponsePresentationVisibility = (
    data: SetPromptResponsePresentationVisibilityRequest
  ) =>
    this.wrapper(
      this.profile.setPromptResponsePresentationVisibility({ data })
    );

  listProfileAccess = () => this.wrapper(this.privacy.listProfileAccess());

  deleteProfileAccess = (access: string) =>
    this.wrapper(this.privacy.deleteProfileAccess({ data: { access } }));

  setProfileAccess = (data: SetProfileAccessRequest) =>
    this.wrapper(this.privacy.setProfileAccess({ data }));

  searchForUser = (term: string) =>
    this.wrapper(this.user.userSearch({ term }));

  searchForInsights = (requestParameters: SearchForInsightsRequest) =>
    this.wrapper(this.ai.searchForInsights(requestParameters));

  deleteInsight = (requestParameters: DeleteInsightOperationRequest) =>
    this.wrapper(this.ai.deleteInsight(requestParameters));

  getUserProfileSummary = (request: GetProfileSummaryRequest) =>
    this.wrapper(this.profile.getProfileSummary(request));

  getSingleUserInsight = (insightId: string) =>
    this.wrapper(
      this.insightsApi.getSingleUserQuestionInsight({
        insight_id: insightId,
      })
    );

  askSingleUserInsight = (data: CreateSingleUserQuestionInsightRequest) =>
    this.wrapper(
      this.insightsApi.createSingleUserQuestionInsight({
        data,
      })
    );

  getCollabById = (collabId: string) =>
    this.wrapper(this.collabs.getCollabById({ collab_id: collabId }));

  createCollab = (data: UsernameOrId) =>
    this.wrapper(this.collabs.createCollab({ data }));

  getLastCollabForUser = (request: CollabsLastForUserRequest) =>
    this.wrapper(this.collabs.collabsLastForUser(request));

  getCommCoachSingleEntry = (entryId: string) =>
    this.wrapper(
      this.commCoachApi.getSingleCommCoachEntry({ entry_id: entryId })
    );

  createOrUpdateCommCoachSingleEntry = (
    data: CreateOrUpdateSingleEntryRequest
  ) =>
    this.wrapper(
      this.commCoachApi.createOrUpdateSingleCommCoachEntry({ data })
    );

  submitCommCoachSingleEntry = (entryId: string) =>
    this.wrapper(
      this.commCoachApi.submitSingleCommCoachEntry({
        data: { entry_id: entryId },
      })
    );

  getProfileBasics = () => this.wrapper(this.profile.getProfileBasicInfo());

  setProfileBasics = (data: SetBasicInfo) =>
    this.wrapper(this.profile.setProfileBasicInfo({ data }));

  getProfilePublicStatus = () => this.wrapper(this.profile.getProfilePublic());

  setProfilePublicStatus = (publicStatus: boolean) =>
    this.wrapper(
      this.profile.setProfilePublic({ data: { is_public: publicStatus } })
    );

  resetAccount = () => this.wrapper(this.user.resetAccount({ data: {} }));

  getGlimpse = (glimpse: string) =>
    this.wrapper(this.glimpse.getGlimpse({ glimpse }));

  listGlimpseTodos = () => this.wrapper(this.glimpse.getGlimpseTodos());

  createGlimpse = () => this.wrapper(this.glimpse.createGlimpse({ data: {} }));

  updateGlimpseTitle = (data: UpdateGlimpseTitleRequest) =>
    this.wrapper(this.glimpse.updateGlimpseTitle({ data }));

  updateGlimpsePitchContext = (data: UpdateGlimpsePitchContextRequest) =>
    this.wrapper(this.glimpse.updateGlimpsePitchContext({ data }));

  updateGlimpsePitchAudience = (data: UpdateGlimpsePitchAudienceRequest) =>
    this.wrapper(this.glimpse.updateGlimpsePitchAudience({ data }));

  listGlimpsePromptResponses = (glimpse: string) =>
    this.wrapper(this.glimpse.listGlimpsePromptResponses({ glimpse }));

  setGlimpsePromptResponses = (data: SetPromptResponsesForGlimpseRequest) =>
    this.wrapper(this.glimpse.setPromptResponsesForGlimpse({ data }));

  listGlimpseLinks = (glimpse: string) =>
    this.wrapper(this.glimpse.listGlimpseLinks({ glimpse }));

  setGlimpseLinks = (data: SetLinksForGlimpseRequest) =>
    this.wrapper(this.glimpse.setGlimpseLinks({ data }));

  getGlimpseProcessingReadyStatus = (glimpse: string) =>
    this.wrapper(this.glimpse.glimpseProcessingReadyStatus({ glimpse }));

  submitGlimpseForProcessing = (glimpse: string) =>
    this.wrapper(
      this.glimpse.submitGlimpseForProcessing({ data: { glimpse } })
    );

  updateGlimpseContent = (data: UpdateGlimpseContentRequest) =>
    this.wrapper(this.glimpse.updateGlimpseContent({ data }));

  listGlimpses = () => this.wrapper(this.glimpse.listGlimpses());

  setGlimpseVisibility = (data: SetGlimpseVisibilityRequest) =>
    this.wrapper(this.glimpse.setGlimpseVisibility({ data }));

  deleteGlimpse = (glimpse: string) =>
    this.wrapper(this.glimpse.deleteGlimpse({ data: { glimpse } }));

  viewGlimpse = (glimpse: string) =>
    this.wrapper(this.glimpse.viewGlimpse({ glimpse }));

  getGlimpseLimits = () => this.wrapper(this.glimpse.getGlimpseLimits());

  contactViaGlimpse = (data: ContactUserViaGlimpseRequest) =>
    this.wrapper(this.glimpse.contactUserViaGlimpse({ data }));

  logout = () => this.wrapper(this.user.logoutUser({ data: {} }));

  getSettings = () => this.wrapper(this.user.getUserSettings());

  listUserInvites = () => this.wrapper(this.user.listUserInvites());

  resendInvite = (invite: string) =>
    this.wrapper(this.user.resendInvite({ data: { invite } }));

  getGlimpseAttachmentVisibility = (glimpse: string) =>
    this.wrapper(this.glimpse.getGlimpseAttachmentVisibility({ glimpse }));

  setGlimpseResumeVisibility = (data: SetGlimpseResumeVisibilityRequest) =>
    this.wrapper(this.glimpse.setGlimpseResumeVisibility({ data }));

  listGroups = () => this.wrapper(this.groups.listUserGroups());

  getGroup = (group: string) => this.wrapper(this.groups.getGroup({ group }));

  deleteGroup = (group: string) =>
    this.wrapper(this.groups.deleteGroup({ data: { group } }));

  createGroup = (data: CreateGroupRequest) =>
    this.wrapper(this.groups.createGroup({ data }));

  updateGroup = (data: UpdateGroupRequest) =>
    this.wrapper(this.groups.updateGroup({ data }));

  addUsersToGroup = (data: AddUsersRequest) =>
    this.wrapper(this.groups.addUsers(data));

  removeUsersFromGroup = (data: RemoveUsersRequest) =>
    this.wrapper(this.groups.removeUsers(data));

  askQuestionAboutGroup = (data: AskQuestionAboutGroupRequest) =>
    this.wrapper(this.groups.askQuestionAboutGroup(data));

  getGroupQuestionAndAnswer = (data: GetGroupQnaRequest) =>
    this.wrapper(this.groups.getGroupQna(data));

  listGroupQuestionsAndAnswers = () => this.wrapper(this.groups.listGroupQna());

  deleteGroupQuestionAndAnswer = (data: DeleteGroupQnaRequest) =>
    this.wrapper(this.groups.deleteGroupQna(data));

  rateResponse = (data: RateLLMResultsRequest) =>
    this.wrapper(this.groups.rateLlmResult({ data }));

  getNotificationCounts = () =>
    this.wrapper(this.notifs.getNotificationCounts());

  listNotifications = (params: ListNotificationsRequest = {}) =>
    this.wrapper(this.notifs.listNotifications(params));

  markNotificationsAsCleared = (data: MarkNotificationsAsClearedRequest) =>
    this.wrapper(this.notifs.markNotificationsAsCleared({ data }));

  markNotificationsAsViewed = (data: MarkNotificationsAsViewedRequest) =>
    this.wrapper(this.notifs.markNotificationsAsViewed({ data }));

  markNotificationsAsNotCleared = (
    data: MarkNotificationsAsNotClearedRequest
  ) => this.wrapper(this.notifs.markNotificationsAsNotCleared({ data }));

  submitConnectionRequest = (data: RequestConnectionRequest) =>
    this.wrapper(this.connection.requestConnection({ data }));

  removeConnection = (data: RemoveConnectionRequest) =>
    this.wrapper(this.connection.removeConnection({ data }));

  respondToConnectionRequest = (data: RespondToConnectionRequestRequest) =>
    this.wrapper(this.connection.respondToConnectionRequest({ data }));

  searchConnections = (data: SearchForConnectionRequest) =>
    this.wrapper(this.connection.searchForConnection(data));

  retrieveDir = () => this.wrapper(this.groups.retrieveDir());

  selectFiles = (data: SelectFilesRequest) =>
    this.wrapper(this.groups.selectFiles(data));

  saveGeneration = (data: SaveGenerationRequest) =>
    this.wrapper(this.groups.saveGeneration({ data }));

  deleteGeneration = (data: DeleteGenerationRequest) =>
    this.wrapper(this.groups.deleteGeneration({ data }));

  listPendingSentConnectionRequests = () =>
    this.wrapper(this.connection.listSentConnectionRequests());

  listPendingReceivedConnectionRequests = () =>
    this.wrapper(this.connection.listReceivedConnectionRequests());

  createQuestion = (data: PromptContent) =>
    this.wrapper(this.questions.createQuestion({ data }));

  voteOnQuestion = (data: VoteOnQuestionRequest) =>
    this.wrapper(this.questions.voteOnQuestion({ data }));

  getQuestionOperations = (data: QuestionOperationsRequest) =>
    this.wrapper(this.questions.questionOperations(data));

  deleteQuestion = (data: DeleteQuestionOperationRequest) =>
    this.wrapper(this.questions.deleteQuestion(data));

  updateQuestion = (data: UpdateQuestionOperationRequest) =>
    this.wrapper(this.questions.updateQuestion(data));

  searchQuestions = (data: SearchForQuestionsRequest) =>
    this.wrapper(this.questions.searchForQuestions(data));

  searchQuestionTags = (term: string) =>
    this.wrapper(this.questions.searchValidQuestionTags({ search_term: term }));
}

export const connector = new ManualAPIConnector();

export const useWrappedConnector = (
  wrpr: <Type>(p: Promise<Type>) => Promise<Type>
): ManualAPIConnector => {
  const toReturn = new ManualAPIConnector(wrpr);
  toReturn.setApiBasePath(connector.basePath);
  return toReturn;
};

export default connector;
