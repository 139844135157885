/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UpdateGlimpseTitleRequest
 */
export interface UpdateGlimpseTitleRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateGlimpseTitleRequest
   */
  glimpse: string;
  /**
   *
   * @type {string}
   * @memberof UpdateGlimpseTitleRequest
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateGlimpseTitleRequest
   */
  description?: string | null;
}

export function UpdateGlimpseTitleRequestFromJSON(
  json: any
): UpdateGlimpseTitleRequest {
  return UpdateGlimpseTitleRequestFromJSONTyped(json, false);
}

export function UpdateGlimpseTitleRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateGlimpseTitleRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    glimpse: json["glimpse"],
    title: !exists(json, "title") ? undefined : json["title"],
    description: !exists(json, "description") ? undefined : json["description"],
  };
}

export function UpdateGlimpseTitleRequestToJSON(
  value?: UpdateGlimpseTitleRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    glimpse: value.glimpse,
    title: value.title,
    description: value.description,
  };
}
