import React, { ReactElement } from "react";
import {
  PromptWithResponse,
  PublishablePromptWithResponse,
  QuestionOperationsResponse,
} from "../../../api/models";
import PromptMultiSelectScreen from "./PromptRespondMultiSelectScreen";
import { MStepsCirclesProps } from "../MStepsCircles";
import PromptRespondChoicesScreen from "./PromptRespondChoicesScreen";
import PromptRespondFillInBlankScreen from "./PromptRespondFillInBlankScreen";
import PromptRespondEitherOrScreen from "./PromptRespondEitherOrScreen";
import PromptRespondFillInBlankListScreen from "./PromptRespondFillInBlankListScreen";

export type CommonPromptTypeScreenProps = {
  title: string;
  prompt: PublishablePromptWithResponse;
  questionOperations?: QuestionOperationsResponse | null;
  onVoteClicked?: (() => void) | null;
  onEditClicked?: (() => void) | null;
  onDeleteClicked?: ((toDelete: PromptWithResponse) => void) | null;
  onBackClicked?: (() => void) | null;
  onClearClicked?: (() => void) | null;
  onSkipClicked?: (() => void) | null;
  errors?: string[];
  steps?: MStepsCirclesProps | null;
  publishable: boolean;
  visible: boolean | null;
};

export type PromptRespondScreenProps = {
  prompt: PublishablePromptWithResponse;
  questionOperations?: QuestionOperationsResponse | null;
  onNextClicked: (results: any, visible: boolean | null) => void;
  onBackClicked?: (() => void) | null;
  onClearClicked?: (() => void) | null;
  onSkipClicked?: (() => void) | null;
  onVoteClicked?: (() => void) | null;
  onEditClicked?: (() => void) | null;
  onTagClicked?: ((tag: string) => void) | null;
  onDeleteClicked?: ((toDelete: PromptWithResponse) => void) | null;
  errors?: string[];
  steps?: MStepsCirclesProps | null;
};

const PromptRespondScreenComponent = (props: PromptRespondScreenProps) => {
  const { prompt } = props;

  const getBody = (): ReactElement => {
    switch (prompt.prompt.question_type) {
      case "multi_select": {
        return <PromptMultiSelectScreen {...props} />;
      }
      case "choices": {
        return <PromptRespondChoicesScreen {...props} />;
      }
      case "fill_in_blank": {
        return <PromptRespondFillInBlankScreen {...props} />;
      }
      case "either_or": {
        return <PromptRespondEitherOrScreen {...props} />;
      }
      case "fill_in_blank_list": {
        return <PromptRespondFillInBlankListScreen {...props} />;
      }
      default: {
        return <div>Not Supported {prompt.prompt.question_type}</div>;
      }
    }
  };

  return <div>{getBody()}</div>;
};

PromptRespondScreenComponent.defaultProps = {
  errors: [],
  questionOperations: null,
  onBackClicked: null,
  onClearClicked: null,
  onSkipClicked: null,
  onVoteClicked: null,
  onEditClicked: null,
  onDeleteClicked: null,
  onTagClicked: null,
  steps: null,
};

export default PromptRespondScreenComponent;
