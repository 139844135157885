import React, { useEffect, useState } from "react";
import { MNavProps } from "../../../ui/nav/MNav";
import {
  ContactUserViaGlimpseRequest,
  ViewGlimpseResponse,
} from "../../../../api/models";
import { useBusyWatcher } from "../../../../util/hooks";
import { useWrappedConnector } from "../../../../api/connector";
import ViewGlimpseScreen from "./ViewGlimpseScreen";
import {
  errorMessageFromResponse,
  errorMessagesFromResponse,
} from "../../../../api/helpers";

type ViewGlimpsePageProps = {
  listGlimpsesUrl: string;
  viewGlimpseUrl: string;
  editGlimpseUrl: string;
  forceOther: boolean;
  glimpse: string;
  navbarProps: MNavProps;
  isAuthed: boolean;
};

const ViewGlimpsePageComponent = (props: ViewGlimpsePageProps) => {
  const {
    listGlimpsesUrl,
    viewGlimpseUrl,
    editGlimpseUrl,
    forceOther,
    glimpse: glimpseInput,
    navbarProps,
    isAuthed,
  } = props;

  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [errorList, setErrorList] = useState<string[]>([]);
  const [glimpse, setGlimpse] = useState<ViewGlimpseResponse | null>(null);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);

  const loadGlimpse = async () => {
    const response = await connector.viewGlimpse(glimpseInput);
    setGlimpse(response.c!);
  };

  const deleteGlimpse = async () => {
    await connector.deleteGlimpse(glimpseInput);
    window.location.href = listGlimpsesUrl;
  };

  const toggleVisibility = async () => {
    await connector.setGlimpseVisibility({
      glimpse: glimpseInput,
      visible: !glimpse!.self!.visible,
    });
    await loadGlimpse();
  };

  const onContactSubmitted = async (
    toSend: ContactUserViaGlimpseRequest
  ): Promise<boolean> => {
    setErrors({});
    setErrorList([]);
    try {
      await connector.contactViaGlimpse(toSend);
    } catch (e: any) {
      const newErrors = await errorMessagesFromResponse(e);
      setErrors(newErrors);
      const newErrorMessage = await errorMessageFromResponse(e);
      if (newErrorMessage) {
        setErrorList([newErrorMessage]);
      } else {
        setErrorList([]);
      }
      return false;
    }
    return true;
  };

  useEffect(() => {
    loadGlimpse();
  }, []);

  return (
    <ViewGlimpseScreen
      viewGlimpseUrl={viewGlimpseUrl}
      editGlimpseUrl={editGlimpseUrl}
      forceOther={forceOther}
      navbarProps={navbarProps}
      glimpse={glimpse}
      glimpseId={glimpseInput}
      onDeleteGlimpse={deleteGlimpse}
      onToggleVisibility={toggleVisibility}
      errors={errors}
      errorList={errorList}
      isAuthed={isAuthed}
      onContactSubmitted={onContactSubmitted}
    />
  );
};

export default ViewGlimpsePageComponent;
