import React, { useEffect, useState } from "react";
import { GlimpseDetail, GlimpseLimits } from "../../../../api/models";
import FormScreen from "../../../ui/screens/FormScreen";
import { useBusyWatcher } from "../../../../util/hooks";
import MButton from "../../../ui/buttons/MButton";
import SubscriptionUpsellDialog from "../../../dialogs/SubscriptionUpsellDialog";
import GlimpseVisibilityToggle from "./GlimpseVisibilityToggle";

type PickGlimpseEditTypeScreenProps = {
  onEditTitleClicked: () => void;
  onViewContextClicked: () => void;
  onEditContentClicked: () => void;
  onEditQuestionsClicked: () => void;
  onEditLinksClicked: () => void;
  onEditAttachmentsClicked: () => void;
  onToggleVisibilityClicked: (newVisibility: boolean) => void;
  glimpse: GlimpseDetail;
  limits: GlimpseLimits;
  onDoneClicked: () => void;
};

const PickGlimpseEditTypeScreenComponent = (
  props: PickGlimpseEditTypeScreenProps
) => {
  const {
    onEditTitleClicked,
    onViewContextClicked,
    onEditContentClicked,
    onEditQuestionsClicked,
    onEditLinksClicked,
    onEditAttachmentsClicked,
    onToggleVisibilityClicked,
    glimpse,
    limits,
    onDoneClicked,
  } = props;

  const [upsellCopy, setUpsellCopy] = useState<string>("");
  const [showSubscriptionUpsell, setShowSubscriptionUpsell] =
    useState<boolean>(false);
  const [busy, _] = useBusyWatcher();

  useEffect(() => {
    setUpsellCopy(
      `you've reached your maximum number of live glimpses (${limits.max}). upgrade to a paid subscription to create more.`
    );
  }, [limits]);

  return (
    <>
      <FormScreen
        title={`edit glimpse '${glimpse.title ?? "untitled"}'`}
        onNextClicked={onDoneClicked}
        nextDisabled={busy}
        nextText="done"
        fullWidth
      >
        <div className="flex flex-row-reverse text-m-dark-gray">
          <GlimpseVisibilityToggle
            isVisible={glimpse.visible!}
            onToggleClicked={() => onToggleVisibilityClicked(!glimpse.visible!)}
            limits={limits}
          />
        </div>
        <MButton disabled={busy} onClick={onEditTitleClicked}>
          edit title + description
        </MButton>
        <MButton disabled={busy} onClick={onViewContextClicked}>
          view pitch context + audience
        </MButton>
        <MButton disabled={busy} onClick={onEditContentClicked}>
          edit content
        </MButton>
        <MButton disabled={busy} onClick={onEditQuestionsClicked}>
          edit questions
        </MButton>
        <MButton disabled={busy} onClick={onEditLinksClicked}>
          edit links
        </MButton>
        <MButton disabled={busy} onClick={onEditAttachmentsClicked}>
          edit attachments
        </MButton>
      </FormScreen>
      <SubscriptionUpsellDialog
        upsellText={upsellCopy}
        show={showSubscriptionUpsell}
        onClose={() => setShowSubscriptionUpsell(false)}
      />
    </>
  );
};

export default PickGlimpseEditTypeScreenComponent;
