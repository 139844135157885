/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  CommonSingleEntryResponseSerializer,
  CommonSingleEntryResponseSerializerFromJSON,
  CommonSingleEntryResponseSerializerToJSON,
  CreateOrUpdateSingleEntryRequest,
  CreateOrUpdateSingleEntryRequestFromJSON,
  CreateOrUpdateSingleEntryRequestToJSON,
  EmptyResponseWrapper,
  EmptyResponseWrapperFromJSON,
  EmptyResponseWrapperToJSON,
  GetSingleEntryResponseSerializer,
  GetSingleEntryResponseSerializerFromJSON,
  GetSingleEntryResponseSerializerToJSON,
  SubmitSingleEntryRequest,
  SubmitSingleEntryRequestFromJSON,
  SubmitSingleEntryRequestToJSON,
} from "../models";

export interface CreateOrUpdateSingleCommCoachEntryRequest {
  data: CreateOrUpdateSingleEntryRequest;
}

export interface GetSingleCommCoachEntryRequest {
  entry_id: string;
}

export interface SubmitSingleCommCoachEntryRequest {
  data: SubmitSingleEntryRequest;
}

/**
 *
 */
export class CommCoachApi extends runtime.BaseAPI {
  /**
   * Create or update a CommCoachSingleEntry record.
   */
  async createOrUpdateSingleCommCoachEntryRaw(
    requestParameters: CreateOrUpdateSingleCommCoachEntryRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<CommonSingleEntryResponseSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling createOrUpdateSingleCommCoachEntry."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/comm-coach/update-single-entry`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CreateOrUpdateSingleEntryRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CommonSingleEntryResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Create or update a CommCoachSingleEntry record.
   */
  async createOrUpdateSingleCommCoachEntry(
    requestParameters: CreateOrUpdateSingleCommCoachEntryRequest,
    initOverrides?: RequestInit
  ): Promise<CommonSingleEntryResponseSerializer> {
    const response = await this.createOrUpdateSingleCommCoachEntryRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get data about a single CommCoachSingleEntry.
   */
  async getSingleCommCoachEntryRaw(
    requestParameters: GetSingleCommCoachEntryRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetSingleEntryResponseSerializer>> {
    if (
      requestParameters.entry_id === null ||
      requestParameters.entry_id === undefined
    ) {
      throw new runtime.RequiredError(
        "entry_id",
        "Required parameter requestParameters.entry_id was null or undefined when calling getSingleCommCoachEntry."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.entry_id !== undefined) {
      queryParameters["entry_id"] = requestParameters.entry_id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/comm-coach/get-single-entry`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetSingleEntryResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get data about a single CommCoachSingleEntry.
   */
  async getSingleCommCoachEntry(
    requestParameters: GetSingleCommCoachEntryRequest,
    initOverrides?: RequestInit
  ): Promise<GetSingleEntryResponseSerializer> {
    const response = await this.getSingleCommCoachEntryRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Submit a CommCoachSingleEntry for processing to an AI backend.
   */
  async submitSingleCommCoachEntryRaw(
    requestParameters: SubmitSingleCommCoachEntryRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<CommonSingleEntryResponseSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling submitSingleCommCoachEntry."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/comm-coach/submit-single-entry`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SubmitSingleEntryRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CommonSingleEntryResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Submit a CommCoachSingleEntry for processing to an AI backend.
   */
  async submitSingleCommCoachEntry(
    requestParameters: SubmitSingleCommCoachEntryRequest,
    initOverrides?: RequestInit
  ): Promise<CommonSingleEntryResponseSerializer> {
    const response = await this.submitSingleCommCoachEntryRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
