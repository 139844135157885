import React from "react";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { CommonWellButtonProps } from "./common";
import MWellButton from "./MWellButton";

const MWellLinkButton = (props: CommonWellButtonProps) => (
  <MWellButton {...props} icon={<ArrowRightCircleIcon />} />
);

MWellLinkButton.defaultProps = {
  tooltip: "view",
};

export default MWellLinkButton;
