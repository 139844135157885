import React, { PropsWithChildren, ReactNode } from "react";
import { classNames } from "../../../../util/strings";

type SidebarSectionProps = {
  title: ReactNode;
  description?: ReactNode;
  cta?: ReactNode;
  divideY?: boolean;
};

const SidebarSectionComponent = (
  props: PropsWithChildren<SidebarSectionProps>
) => {
  const { title, description, children, divideY, cta } = props;

  return (
    <div>
      <div className="flex flex-col items-start sm:flex-row sm:items-end justify-between gap-3">
        <div>
          <h2 className="text-base font-semibold text-m-dark-gray leading-7">
            {title}
          </h2>
          {description && (
            <p className="mt-1 text-sm leading-6 text-m-gray">{description}</p>
          )}
        </div>
        {cta && cta}
      </div>
      <dl
        className={classNames(
          "mt-4 border-t border-m-light-gray text-sm leading-6",
          divideY ? "space-y-4 divide-y divide-m-light-gray" : null
        )}
      >
        {children}
      </dl>
    </div>
  );
};

SidebarSectionComponent.defaultProps = {
  description: null,
  divideY: true,
  cta: null,
};

export default SidebarSectionComponent;
