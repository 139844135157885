import React from "react";
import { PromptRelatedDetail } from "../../../api/models";
import Emote from "../Emote";
import MBadge from "../MBadge";
import MCard from "./MCard";

type QuestionCardProps = {
  question: PromptRelatedDetail;
  searchBaseUrl: string;
};

export const formatNumber = (n: number): string => {
  if (n < 1000) return n.toString();
  const marker = n < 1000000 ? "K" : "M";
  const denominator = n < 1000000 ? 1000 : 1000000;
  const fraction = n / denominator;
  const rounded = Math.round(fraction * 10) / 10;
  return `${rounded}${marker}`;
};

const QuestionCardComponent: React.FC<QuestionCardProps> = (
  props: QuestionCardProps
) => {
  const { question, searchBaseUrl } = props;
  const formattedResponses = formatNumber(question.num_responses ?? 0);
  const formattedVotes = formatNumber(question.num_votes ?? 0);
  const { author } = question;
  const tags = question.tags ? (question.tags as string[]) : [];

  return (
    <>
      <Emote action="asks:" user={author} />
      <MCard
        className="mt-2 has-[:hover]:shadow-blue-600"
        href={question.view_url}
        flush
        flushFooter
        footer={
          tags.length > 0 ? (
            <div className="p-2 flex gap-2">
              {tags.map((tag) => (
                <MBadge
                  content={tag}
                  key={tag}
                  onClick={() => {
                    window.location.href = `${searchBaseUrl}?tag=${tag}`;
                  }}
                />
              ))}
            </div>
          ) : null
        }
      >
        <div className="cursor-pointer hover:bg-primary-light px-4 py-5 sm:p-6">
          <p className="text-lg">{question.question}</p>
          <ul className="text-xs mt-4 *:inline">
            <li className="after:content-['/'] after:m-2">
              {formattedVotes} votes
            </li>
            <li>{formattedResponses} people responded</li>
          </ul>
        </div>
      </MCard>
    </>
  );
};

QuestionCardComponent.displayName = "QuestionCard";

export default QuestionCardComponent;
