/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  PublishablePromptWithResponse,
  PublishablePromptWithResponseFromJSON,
  PublishablePromptWithResponseFromJSONTyped,
  PublishablePromptWithResponseToJSON,
} from "./PublishablePromptWithResponse";
import {
  QuestionnaireDetail,
  QuestionnaireDetailFromJSON,
  QuestionnaireDetailFromJSONTyped,
  QuestionnaireDetailToJSON,
} from "./QuestionnaireDetail";

/**
 *
 * @export
 * @interface GetQuestionnaireWithResponsesResponse
 */
export interface GetQuestionnaireWithResponsesResponse {
  /**
   *
   * @type {QuestionnaireDetail}
   * @memberof GetQuestionnaireWithResponsesResponse
   */
  questionnaire: QuestionnaireDetail;
  /**
   *
   * @type {Array<PublishablePromptWithResponse>}
   * @memberof GetQuestionnaireWithResponsesResponse
   */
  prompts: Array<PublishablePromptWithResponse>;
  /**
   *
   * @type {number}
   * @memberof GetQuestionnaireWithResponsesResponse
   */
  response_count: number;
}

export function GetQuestionnaireWithResponsesResponseFromJSON(
  json: any
): GetQuestionnaireWithResponsesResponse {
  return GetQuestionnaireWithResponsesResponseFromJSONTyped(json, false);
}

export function GetQuestionnaireWithResponsesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetQuestionnaireWithResponsesResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    questionnaire: QuestionnaireDetailFromJSON(json["questionnaire"]),
    prompts: (json["prompts"] as Array<any>).map(
      PublishablePromptWithResponseFromJSON
    ),
    response_count: json["response_count"],
  };
}

export function GetQuestionnaireWithResponsesResponseToJSON(
  value?: GetQuestionnaireWithResponsesResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    questionnaire: QuestionnaireDetailToJSON(value.questionnaire),
    prompts: (value.prompts as Array<any>).map(
      PublishablePromptWithResponseToJSON
    ),
    response_count: value.response_count,
  };
}
