import React, { ReactElement } from "react";
import { LinkSummary } from "../../../../api/models";
import CenterColumnStickyButtonScreen from "../../../ui/screens/CenterColumnStickyButtonScreen";
import { useBusyWatcher } from "../../../../util/hooks";
import MPlusButton from "../../../ui/buttons/MPlusButton";
import MTitle from "../../../ui/MTitle";
import MSubtext from "../../../ui/MSubtext";
import LinkSummaryRow from "./LinkSummaryRow";
import MNextButton from "../../../ui/buttons/MNextButton";

type ListLinksScreenProps = {
  links: LinkSummary[];
  onEditLinkClicked: (link: LinkSummary) => void;
  onAddLinkClicked: () => void;
  onDeleteLinkClicked: (link: LinkSummary) => void;
  onDoneClicked: () => void;
};

const ListLinksScreenComponent = (props: ListLinksScreenProps) => {
  const {
    links,
    onEditLinkClicked,
    onAddLinkClicked,
    onDeleteLinkClicked,
    onDoneClicked,
  } = props;

  const [busy, _] = useBusyWatcher();

  const getButtons = (): ReactElement[] => [
    <MPlusButton
      kind="primary"
      onClick={onAddLinkClicked}
      disabled={busy}
      key="add-link-button"
      text="add a link"
    />,
    <MNextButton
      kind="secondary"
      onClick={onDoneClicked}
      disabled={busy}
      key="done-button"
      text="done"
    />,
  ];

  return (
    <CenterColumnStickyButtonScreen buttons={getButtons()} stacked fullWidth>
      <MTitle text="add links to your profile" className="text-center" />
      <MSubtext
        text="personal website, socials, portfolios, or just something you care about. you'll be able to remove or edit at any time."
        className="text-center"
      />
      <div className="flex flex-col gap-2">
        {links.map((link) => (
          <LinkSummaryRow
            key={link.guid!}
            link={link}
            onEditClicked={() => onEditLinkClicked(link)}
            onDeleteClicked={() => onDeleteLinkClicked(link)}
          />
        ))}
      </div>
    </CenterColumnStickyButtonScreen>
  );
};

export default ListLinksScreenComponent;
