import React from "react";
import ChatboxContainer from "./ChatboxContainer";
import connector from "../../../api/connector";
import sleep from "../../../util/sleep";

type AskUserQuestionPageProps = {
  user: string;
  maxWaitTimeS: number;
  checkIntervalS: number;
};

const AskUserQuestionPageComponent = (props: AskUserQuestionPageProps) => {
  const { user, maxWaitTimeS, checkIntervalS } = props;

  const newMessageReady = async (message: string): Promise<string> => {
    const askQuestionResponse = await connector.askUserQuestion(user, message);
    const qnrGuid = askQuestionResponse.c!.qnr.guid!;
    const taskGuid = askQuestionResponse.c!.qnr.task_id!;
    const startTime = Date.now();
    const maxWaitTime = maxWaitTimeS * 1000;
    let success = false;
    for (;;) {
      // eslint-disable-next-line no-await-in-loop
      await sleep(checkIntervalS * 1000);
      // eslint-disable-next-line no-await-in-loop
      const taskStatusResponse = await connector.getTaskStatus(taskGuid);
      if (taskStatusResponse.c!.ready) {
        success = true;
        break;
      }
      const elapsed = Date.now() - startTime;
      if (elapsed > maxWaitTime) {
        break;
      }
    }
    if (!success) {
      throw new Error(
        `Timed out waiting for response (${maxWaitTimeS} seconds)`
      );
    }
    const getQuestionResponse = await connector.getQuestionAndResponse(qnrGuid);
    return getQuestionResponse.c!.response!;
  };

  return (
    <div className="w-full h-80vh flex flex-row justify-center">
      <ChatboxContainer newMessageReady={newMessageReady} />
    </div>
  );
};

export default AskUserQuestionPageComponent;
