/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  PromptRelatedDetail,
  PromptRelatedDetailFromJSON,
  PromptRelatedDetailFromJSONTyped,
  PromptRelatedDetailToJSON,
} from "./PromptRelatedDetail";
import {
  PromptResponseDetail,
  PromptResponseDetailFromJSON,
  PromptResponseDetailFromJSONTyped,
  PromptResponseDetailToJSON,
} from "./PromptResponseDetail";

/**
 *
 * @export
 * @interface PublishablePromptWithResponse
 */
export interface PublishablePromptWithResponse {
  /**
   *
   * @type {PromptRelatedDetail}
   * @memberof PublishablePromptWithResponse
   */
  prompt: PromptRelatedDetail;
  /**
   *
   * @type {PromptResponseDetail}
   * @memberof PublishablePromptWithResponse
   */
  response?: PromptResponseDetail | null;
  /**
   *
   * @type {boolean}
   * @memberof PublishablePromptWithResponse
   */
  publishable: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PublishablePromptWithResponse
   */
  visible?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof PublishablePromptWithResponse
   */
  user_voted?: boolean | null;
}

export function PublishablePromptWithResponseFromJSON(
  json: any
): PublishablePromptWithResponse {
  return PublishablePromptWithResponseFromJSONTyped(json, false);
}

export function PublishablePromptWithResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PublishablePromptWithResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    prompt: PromptRelatedDetailFromJSON(json["prompt"]),
    response: !exists(json, "response")
      ? undefined
      : PromptResponseDetailFromJSON(json["response"]),
    publishable: json["publishable"],
    visible: !exists(json, "visible") ? undefined : json["visible"],
    user_voted: !exists(json, "user_voted") ? undefined : json["user_voted"],
  };
}

export function PublishablePromptWithResponseToJSON(
  value?: PublishablePromptWithResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    prompt: PromptRelatedDetailToJSON(value.prompt),
    response: PromptResponseDetailToJSON(value.response),
    publishable: value.publishable,
    visible: value.visible,
    user_voted: value.user_voted,
  };
}
