import React from "react";
import {
  InsightResponseEnvelope,
  InsightResponseEnvelopeInsightTypeEnum,
} from "../../../api/models";
import SingleUserInsightCard from "./SingleUserInsightCard";
import CommCoachSingleEntryCard from "./CommCoachSingleEntryCard";
import UserCollabCard from "./UserCollabCard";
import { CommonInsightCardProps } from "./common";
import MWellDeleteButton from "../../ui/wells/MWellDeleteButton";
import { useBusyWatcher } from "../../../util/hooks";

type InsightCardProps = CommonInsightCardProps & {
  insight: InsightResponseEnvelope;
  onDeleteClick: () => void;
};

const InsightCardComponent = (props: InsightCardProps) => {
  const { insight, onDeleteClick, ...rest } = props;

  const [busy, _] = useBusyWatcher();

  switch (insight.insight_type) {
    case InsightResponseEnvelopeInsightTypeEnum.single_user_insight: {
      return (
        <div className="flex flex-row items-center">
          <SingleUserInsightCard
            insight={insight.single_user_insight!}
            {...rest}
          />
          <MWellDeleteButton
            className="shrink ml-2 hover:text-red-500"
            disabled={busy}
            onClick={onDeleteClick}
          />
        </div>
      );
    }
    case InsightResponseEnvelopeInsightTypeEnum.single_comm_coach: {
      return (
        <div className="flex flex-row items-center">
          <CommCoachSingleEntryCard
            entry={insight.single_comm_coach!}
            {...rest}
          />
          <MWellDeleteButton
            className="shrink ml-2 hover:text-red-500"
            disabled={busy}
            onClick={onDeleteClick}
          />
        </div>
      );
    }
    case InsightResponseEnvelopeInsightTypeEnum.user_collab: {
      return (
        <div className="flex flex-row items-center">
          <UserCollabCard collab={insight.collab!} {...rest} />
          <MWellDeleteButton
            className="shrink ml-2 hover:text-red-500"
            disabled={busy}
            onClick={onDeleteClick}
          />
        </div>
      );
    }
    default: {
      throw new Error(`Unsupported insight type ${insight.insight_type}`);
    }
  }
};

export default InsightCardComponent;
