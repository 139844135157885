/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface QuestionnaireDetail
 */
export interface QuestionnaireDetail {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof QuestionnaireDetail
   */
  readonly guid?: string;
  /**
   * The contents to display at the top of a questionnaire
   * @type {string}
   * @memberof QuestionnaireDetail
   */
  title: string;
  /**
   * A longer text description of what this questionnaire intends to find out
   * @type {string}
   * @memberof QuestionnaireDetail
   */
  description: string;
  /**
   * A small piece of copy that is used on assessment target review pages.
   * @type {string}
   * @memberof QuestionnaireDetail
   */
  topic?: string | null;
  /**
   * The number of questions that are required for the questionnaire to be considered completed. If none, then all questions are considered necessary.
   * @type {number}
   * @memberof QuestionnaireDetail
   */
  num_answers_required?: number | null;
}

export function QuestionnaireDetailFromJSON(json: any): QuestionnaireDetail {
  return QuestionnaireDetailFromJSONTyped(json, false);
}

export function QuestionnaireDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): QuestionnaireDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    title: json["title"],
    description: json["description"],
    topic: !exists(json, "topic") ? undefined : json["topic"],
    num_answers_required: !exists(json, "num_answers_required")
      ? undefined
      : json["num_answers_required"],
  };
}

export function QuestionnaireDetailToJSON(
  value?: QuestionnaireDetail | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    title: value.title,
    description: value.description,
    topic: value.topic,
    num_answers_required: value.num_answers_required,
  };
}
