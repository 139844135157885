import React, { ReactElement, ReactNode } from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { usePostHog } from "posthog-js/react";
import { Attachments, GetEventTypeEventEnum } from "../../../api/models";
import { classNames } from "../../../util/strings";
import { clickableText, commentText } from "../../../util/style";
import MDivider from "../../ui/MDivider";
import MPlusButton from "../../ui/buttons/MPlusButton";
import MEditButton from "../../ui/buttons/MEditButton";
import { useBusyWatcher } from "../../../util/hooks";

type AttachmentsSectionProps = {
  attachments: Attachments;
  className?: string;
  editUrl?: string | null;
  title?: string;
  bgColor?: string | null;
  location: string;
};

const AttachmentsSectionComponent = (props: AttachmentsSectionProps) => {
  const { attachments, bgColor, className, editUrl, title, location } = props;

  const posthog = usePostHog();
  const [busy, _] = useBusyWatcher();

  const hasAnyAttachment = (): boolean => {
    if (attachments.resume) {
      return true;
    }
    return false;
  };

  const emitResumeClicked = () => {
    posthog.capture(GetEventTypeEventEnum.resume_viewed, {
      source: location,
    });
  };

  const getLinkRow = (
    key: string,
    url: string,
    linkTitle: string,
    onClick?: () => void
  ): ReactNode => (
    <a key={key} onClick={onClick} href={url} target="_blank" rel="noreferrer">
      <div
        className={classNames(
          "flex flex-row items-center gap-2 text-xl",
          clickableText
        )}
      >
        <span>{linkTitle}</span>
        <ArrowTopRightOnSquareIcon className="h-5 w-5" />
      </div>
    </a>
  );

  const getAddButton = (): ReactElement => {
    if (!hasAnyAttachment()) {
      return <MPlusButton href={editUrl!} disabled={busy} text={null} />;
    }
    return <MEditButton href={editUrl!} disabled={busy} text={null} />;
  };

  return (
    <div className={classNames("flex flex-col gap-2", className)}>
      <MDivider
        title={title}
        bgColor={bgColor}
        button={editUrl ? getAddButton() : null}
      />
      {hasAnyAttachment() &&
        attachments.resume &&
        getLinkRow("resume", attachments.resume, "resume", emitResumeClicked)}
      {!hasAnyAttachment() && (
        <div className={classNames(commentText, "my-3")}>
          you haven't added any attachments to your profile yet
        </div>
      )}
    </div>
  );
};

AttachmentsSectionComponent.defaultProps = {
  className: undefined,
  title: "attachments",
  editUrl: null,
  bgColor: null,
};

export default AttachmentsSectionComponent;
