import React, { ReactNode } from "react";

type SidebarThreeColumnRowProps = {
  start?: ReactNode;
  middle?: ReactNode;
  end?: ReactNode;
};

const SidebarThreeColumnRowComponent = (props: SidebarThreeColumnRowProps) => {
  const { start, middle, end } = props;

  return (
    <div className="pt-4 text-m-dark-gray grid grid-cols-1 sm:grid-cols-3 gap-3">
      <div className="place-self-start font-semibold flex flex-col justify-center h-full">
        {start}
      </div>
      <div className="place-self-start flex flex-col justify-center h-full">
        {middle}
      </div>
      <div className="place-self-start sm:place-self-end flex flex-col justify-center h-full">
        {end}
      </div>
    </div>
  );
};

SidebarThreeColumnRowComponent.defaultProps = {
  start: null,
  middle: null,
  end: null,
};

export default SidebarThreeColumnRowComponent;
