import React, { useState } from "react";
import FormScreen from "../../../ui/screens/FormScreen";
import { useBusyWatcher } from "../../../../util/hooks";
import MInput from "../../../ui/MInput";
import MTextArea from "../../../ui/MTextArea";
import { UserGroup, UpdateGroupRequest } from "../../../../api/models";

type UpdateGroupScreenProps = {
  group: UserGroup;
  errors: { [key: string]: string[] };
  onNextClicked: (request: UpdateGroupRequest) => void;
  onBackClicked: () => void;
};

const UpdateGroupScreenComponent = (props: UpdateGroupScreenProps) => {
  const {
    group,
    errors,
    onNextClicked: onNextClickedInput,
    onBackClicked,
  } = props;

  const [name, setName] = useState<string>(group.name);
  const [description, setDescription] = useState<string>(
    group.description ? group.description : ""
  );
  const [busy, _] = useBusyWatcher();

  const getNameErrors = (): string[] => {
    const toReturn: string[] = [];
    if (errors.name) {
      toReturn.push(...errors.name);
    }
    if (name.length > 64) {
      toReturn.push("name must be less than 64 characters");
    }
    return toReturn;
  };

  const getDescriptionErrors = (): string[] => {
    const toReturn: string[] = [];
    if (errors.description) {
      toReturn.push(...errors.description);
    }
    if (description.length > 256) {
      toReturn.push("description must be less than 256 characters");
    }
    return toReturn;
  };

  const canSubmit = () => {
    if (name.length > 64) {
      return false;
    }
    if (description.length > 256) {
      return false;
    }
    return true;
  };

  const onNextClicked = () => {
    const toSubmit: UpdateGroupRequest = {
      group: group.guid!,
      name,
      description,
    };
    onNextClickedInput(toSubmit);
  };

  return (
    <FormScreen
      title="set your group name"
      comment="this is optional and strictly for your own organization. other users will never see the group's name or description."
      nextDisabled={busy || !canSubmit()}
      backDisabled={busy}
      stacked
      onNextClicked={onNextClicked}
      onBackClicked={onBackClicked}
    >
      <MInput
        placeholder={'e.g. "my homies"'}
        label="name"
        value={name}
        disabled={busy}
        errors={getNameErrors()}
        onChange={(e) => setName(e.target.value)}
        onEnterPressed={() => canSubmit() && onNextClicked()}
      />
      <MTextArea
        placeholder="e.g. 'we share trauma from bad employers'"
        label="description"
        value={description}
        disabled={busy}
        onChange={(e) => setDescription(e.target.value)}
        errors={getDescriptionErrors()}
      />
    </FormScreen>
  );
};

export default UpdateGroupScreenComponent;
