import React, { useEffect, useState } from "react";
import { useBackTimer, useBusyWatcher } from "../../../../util/hooks";
import { useWrappedConnector } from "../../../../api/connector";
import WizardScreen, {
  WizardScreenPanel,
} from "../../../ui/screens/WizardScreen";
import LoadingScreen from "../../../ui/screens/LoadingScreen";
import BaseNavbarPage from "../../BaseNavbarPage";
import { MNavProps } from "../../../ui/nav/MNav";
import {
  Attachments,
  GlimpseAttachmentVisibilityDetail,
  GlimpseDetail,
  ListLinksForGlimpseResponse,
  ListPromptResponsesForGlimpseResponse,
  ManualTodo,
  SetLinksForGlimpseRequest,
  SetPromptResponsesForGlimpseRequest,
  UpdateGlimpseContentRequest,
  UpdateGlimpsePitchAudienceRequest,
  UpdateGlimpsePitchContextRequest,
  UpdateGlimpseTitleRequest,
} from "../../../../api/models";
import TodoScreen from "../../../ui/screens/TodoScreen";
import UpdateGlimpseTitleScreen from "../edit/UpdateGlimpseTitleScreen";
import {
  errorMessagesForKeyFromResponse,
  errorMessagesFromResponse,
} from "../../../../api/helpers";
import UpdateGlimpsePitchContextScreen from "./UpdateGlimpsePitchContextScreen";
import PickGlimpseQuestionsScreen from "../edit/PickGlimpseQuestionsScreen";
import PickGlimpseLinksScreen from "../edit/PickGlimpseLinksScreen";
import waitForTask from "../../../../util/tasks";
import ReviewGlimpseContentScreen from "../edit/ReviewGlimpseContentScreen";
import GlimpseCompletedScreen from "./GlimpseCompletedScreen";
import { addQueryParamToPath } from "../../../../util/url";
import UpdateGlimpsePitchAudienceScreen from "./UpdateGlimpsePitchAudienceScreen";
import UpdateGlimpseAttachmentsScreen from "../edit/UpdateGlimpseAttachmentsScreen";

type CreateGlimpsePageProps = {
  viewGlimpseUrl: string;
  navbarProps: MNavProps;
  glimpseId?: string | null;
};

enum CreateGlimpseStage {
  LOADING,
  VIEW_TODOS,
  UPDATE_TITLE,
  UPDATE_PITCH_CONTEXT,
  UPDATE_PITCH_AUDIENCE,
  PICK_QUESTIONS,
  PICK_LINKS,
  SET_ATTACHMENTS,
  PROCESSING,
  REVIEW_CONTENT,
  COMPLETED,
}

const CreateGlimpsePageComponent = (props: CreateGlimpsePageProps) => {
  const { viewGlimpseUrl, navbarProps, glimpseId: glimpseIdInput } = props;

  const [glimpseId, setGlimpseId] = useState<string | null>(glimpseIdInput!);
  const [glimpse, setGlimpse] = useState<GlimpseDetail | null>(null);
  const [todos, setTodos] = useState<ManualTodo[]>([]);
  const [selectedPromptResponses, setSelectedPromptResponses] =
    useState<ListPromptResponsesForGlimpseResponse | null>(null);
  const [selectedLinks, setSelectedLinks] =
    useState<ListLinksForGlimpseResponse | null>(null);
  const [attachments, setAttachments] = useState<Attachments | null>(null);
  const [attachmentVisibility, setAttachmentVisibility] =
    useState<GlimpseAttachmentVisibilityDetail | null>(null);
  const [stage, setStage] = useState<CreateGlimpseStage>(
    CreateGlimpseStage.LOADING
  );
  const [errors, setErrors] = useState<any>({});
  const [errorsList, setErrorsList] = useState<string[]>([]);
  const [isBack, asBack] = useBackTimer(stage);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);

  const submitGlimpseForProcessing = async () => {
    const check = await connector.getGlimpseProcessingReadyStatus(glimpseId!);
    if (!check.c!.is_ready) {
      throw new Error("Glimpse is not ready for processing");
    }
    const response = await connector.submitGlimpseForProcessing(glimpseId!);
    setGlimpse(response.c!);
  };

  const loadAttachments = async () => {
    const response = await connector.getAttachments();
    setAttachments(response.c!);
  };

  const loadAttachmentVisibility = async () => {
    const response = await connector.getGlimpseAttachmentVisibility(glimpseId!);
    setAttachmentVisibility(response.c!);
  };

  const loadAttachmentPageContent = async () => {
    const promises = [loadAttachments(), loadAttachmentVisibility()];
    await Promise.all(promises);
  };

  const loadSelectedLinks = async () => {
    const response = await connector.listGlimpseLinks(glimpseId!);
    setSelectedLinks(response.c!);
  };

  const loadSelectedPromptResponses = async () => {
    const response = await connector.listGlimpsePromptResponses(glimpseId!);
    setSelectedPromptResponses(response.c!);
  };

  const loadGlimpseTodos = async () => {
    const response = await connector.listGlimpseTodos();
    setTodos(response.c!.todos);
  };

  const loadGlimpse = async () => {
    const response = await connector.getGlimpse(glimpseId!);
    setGlimpse(response.c!);
  };

  const waitForProcessingDone = async () => {
    if (glimpse!.is_task_completed) {
      return;
    }
    const success = await waitForTask(glimpse!.task_id!, connector);
    if (!success) {
      throw new Error("Glimpse processing failed");
    }
  };

  const goToStage = async (nextStage: CreateGlimpseStage) => {
    setErrors({});
    setErrorsList([]);
    // eslint-disable-next-line default-case
    switch (nextStage) {
      case CreateGlimpseStage.VIEW_TODOS: {
        await loadGlimpseTodos();
        break;
      }
      case CreateGlimpseStage.UPDATE_TITLE: {
        await loadGlimpse();
        break;
      }
      case CreateGlimpseStage.UPDATE_PITCH_CONTEXT: {
        await loadGlimpse();
        break;
      }
      case CreateGlimpseStage.PICK_QUESTIONS: {
        await loadSelectedPromptResponses();
        break;
      }
      case CreateGlimpseStage.PICK_LINKS: {
        await loadSelectedLinks();
        break;
      }
      case CreateGlimpseStage.SET_ATTACHMENTS: {
        await loadAttachmentPageContent();
        break;
      }
      case CreateGlimpseStage.REVIEW_CONTENT: {
        await loadGlimpse();
        break;
      }
    }
    setStage(nextStage);
  };

  const goThroughProcessing = async () => {
    setErrorsList([]);
    setStage(CreateGlimpseStage.PROCESSING);
    await waitForProcessingDone();
    await goToStage(CreateGlimpseStage.REVIEW_CONTENT);
  };

  const onTodosNextClicked = async () => {
    if (glimpseId) {
      await goToStage(CreateGlimpseStage.UPDATE_TITLE);
    } else {
      const newGlimpse = await connector.createGlimpse();
      setGlimpseId(newGlimpse.c!.guid!);
      setGlimpse(newGlimpse.c!);
      setStage(CreateGlimpseStage.UPDATE_TITLE);
    }
  };

  const onUpdateTitleNextClicked = async (
    newTitle: UpdateGlimpseTitleRequest
  ) => {
    setErrors({});
    try {
      await connector.updateGlimpseTitle(newTitle);
    } catch (e: any) {
      const parsed = await errorMessagesFromResponse(e);
      setErrors(parsed);
      return;
    }
    await goToStage(CreateGlimpseStage.UPDATE_PITCH_CONTEXT);
  };

  const onUpdatePitchContextNextClicked = async (
    newContext: UpdateGlimpsePitchContextRequest
  ) => {
    setErrors({});
    try {
      await connector.updateGlimpsePitchContext(newContext);
    } catch (e: any) {
      const parsed = await errorMessagesFromResponse(e);
      setErrors(parsed);
      return;
    }
    await goToStage(CreateGlimpseStage.UPDATE_PITCH_AUDIENCE);
  };

  const onUpdatePitchAudienceNextClicked = async (
    newAudience: UpdateGlimpsePitchAudienceRequest
  ) => {
    setErrors({});
    try {
      await connector.updateGlimpsePitchAudience(newAudience);
      await submitGlimpseForProcessing();
    } catch (e: any) {
      const parsed = await errorMessagesFromResponse(e);
      setErrors(parsed);
      return;
    }
    await goToStage(CreateGlimpseStage.PICK_QUESTIONS);
  };

  const onPickQuestionsNextClicked = async (
    newSelected: SetPromptResponsesForGlimpseRequest
  ) => {
    setErrorsList([]);
    try {
      await connector.setGlimpsePromptResponses(newSelected);
    } catch (e: any) {
      const parsed = await errorMessagesForKeyFromResponse(
        e,
        "prompt_responses",
        true
      );
      setErrorsList(parsed);
      return;
    }
    await goToStage(CreateGlimpseStage.PICK_LINKS);
  };

  const onPickLinksNextClicked = async (
    newSelected: SetLinksForGlimpseRequest
  ) => {
    setErrorsList([]);
    try {
      await connector.setGlimpseLinks(newSelected);
    } catch (e: any) {
      const parsed = await errorMessagesForKeyFromResponse(e, "links", true);
      setErrorsList(parsed);
      return;
    }
    await goToStage(CreateGlimpseStage.SET_ATTACHMENTS);
  };

  const onResumeVisibilityToggled = async (newVisibility: boolean) => {
    await connector.setGlimpseResumeVisibility({
      glimpse: glimpseId!,
      visible: newVisibility,
    });
    await loadAttachmentPageContent();
  };

  const onAttachmentsNextClicked = async () => {
    await goThroughProcessing();
  };

  const onUpdateContentNextClicked = async (
    newContent: UpdateGlimpseContentRequest
  ) => {
    setErrorsList([]);
    try {
      await connector.updateGlimpseContent(newContent);
    } catch (e: any) {
      const parsed = await errorMessagesForKeyFromResponse(e, "content", true);
      setErrorsList(parsed);
      return;
    }
    await goToStage(CreateGlimpseStage.COMPLETED);
  };

  useEffect(() => {
    if (glimpseId) {
      goToStage(CreateGlimpseStage.UPDATE_TITLE);
    } else {
      goToStage(CreateGlimpseStage.VIEW_TODOS);
    }
  }, []);

  const getElements = (): WizardScreenPanel[] => [
    {
      stage: CreateGlimpseStage.LOADING,
      content: <LoadingScreen />,
    },
    {
      stage: CreateGlimpseStage.VIEW_TODOS,
      content: todos && (
        <TodoScreen
          todos={todos}
          onNextClicked={onTodosNextClicked}
          title="create a glimpse"
          onBackClicked={() => window.history.back()}
          outstandingCopy="the more context we have on you, the better we're able to generate a glimpse for you."
          completedCopy="you've already completed all the steps we recommend! click the 'continue' button below to start creating your glimpse."
        />
      ),
    },
    {
      stage: CreateGlimpseStage.UPDATE_TITLE,
      content: glimpse && (
        <UpdateGlimpseTitleScreen
          glimpse={glimpse}
          errors={errors}
          onNextClicked={onUpdateTitleNextClicked}
          onBackClicked={asBack(() => goToStage(CreateGlimpseStage.VIEW_TODOS))}
        />
      ),
    },
    {
      stage: CreateGlimpseStage.UPDATE_PITCH_CONTEXT,
      content: glimpse && (
        <UpdateGlimpsePitchContextScreen
          glimpse={glimpse}
          errors={errors.pitch_context ?? []}
          onNextClicked={onUpdatePitchContextNextClicked}
          onBackClicked={asBack(() =>
            goToStage(CreateGlimpseStage.UPDATE_TITLE)
          )}
        />
      ),
    },
    {
      stage: CreateGlimpseStage.UPDATE_PITCH_AUDIENCE,
      content: glimpse && (
        <UpdateGlimpsePitchAudienceScreen
          glimpse={glimpse}
          errors={errors.pitch_audience ?? []}
          onNextClicked={onUpdatePitchAudienceNextClicked}
          onBackClicked={asBack(() =>
            goToStage(CreateGlimpseStage.UPDATE_PITCH_CONTEXT)
          )}
        />
      ),
    },
    {
      stage: CreateGlimpseStage.PICK_QUESTIONS,
      content: selectedPromptResponses && (
        <PickGlimpseQuestionsScreen
          glimpseId={glimpseId!}
          selected={selectedPromptResponses}
          errors={errorsList}
          onNextClicked={onPickQuestionsNextClicked}
          onBackClicked={asBack(() =>
            goToStage(CreateGlimpseStage.UPDATE_PITCH_AUDIENCE)
          )}
        />
      ),
    },
    {
      stage: CreateGlimpseStage.PICK_LINKS,
      content: selectedLinks && (
        <PickGlimpseLinksScreen
          glimpseId={glimpseId!}
          selected={selectedLinks}
          errors={errorsList}
          onNextClicked={onPickLinksNextClicked}
          onBackClicked={asBack(() =>
            goToStage(CreateGlimpseStage.PICK_QUESTIONS)
          )}
        />
      ),
    },
    {
      stage: CreateGlimpseStage.SET_ATTACHMENTS,
      content: attachments && attachmentVisibility && (
        <UpdateGlimpseAttachmentsScreen
          attachments={attachments}
          visibility={attachmentVisibility}
          onResumeVisibilityChanged={onResumeVisibilityToggled}
          onNextClicked={onAttachmentsNextClicked}
          onBackClicked={asBack(() => goToStage(CreateGlimpseStage.PICK_LINKS))}
        />
      ),
    },
    {
      stage: CreateGlimpseStage.PROCESSING,
      content: (
        <LoadingScreen
          errors={errorsList}
          message="give us just a moment to create your glimpse..."
          onBackClicked={asBack(() => goToStage(CreateGlimpseStage.PICK_LINKS))}
        />
      ),
    },
    {
      stage: CreateGlimpseStage.REVIEW_CONTENT,
      content: glimpse && (
        <ReviewGlimpseContentScreen
          glimpse={glimpse}
          errors={errorsList}
          onNextClicked={onUpdateContentNextClicked}
        />
      ),
    },
    {
      stage: CreateGlimpseStage.COMPLETED,
      content: (
        <GlimpseCompletedScreen
          onNextClicked={() => {
            window.location.href = addQueryParamToPath(
              viewGlimpseUrl,
              "id",
              glimpseId!
            );
          }}
        />
      ),
    },
  ];

  return (
    <BaseNavbarPage navbarProps={navbarProps}>
      <WizardScreen elements={getElements()} stage={stage} isBack={isBack} />
    </BaseNavbarPage>
  );
};

CreateGlimpsePageComponent.defaultProps = {
  glimpseId: null,
};

export default CreateGlimpsePageComponent;
