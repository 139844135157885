import React, { forwardRef, useEffect, useState } from "react";
import { useUniqueId } from "../../util/hooks";
import MLabel from "./MLabel";
import { classNames } from "../../util/strings";
import MErrors from "./MErrors";
import { dateToYMD } from "../../util/date";

export type MDateInputProps = React.ComponentPropsWithoutRef<"input"> & {
  label?: string | null;
  errors?: string[];
  onEnterPressed?: (() => void) | null;
  className?: string;
  onDateChanged?: ((date: Date | null) => void) | null;
  dateValue?: Date | null;
};

const MDateInputComponent = forwardRef<HTMLInputElement, MDateInputProps>(
  (
    {
      label,
      errors: errorsInput,
      onEnterPressed,
      required,
      className,
      onDateChanged,
      dateValue,
      onBlur,
      ...props
    },
    ref
  ) => {
    const labelId = useUniqueId();

    const [value, setValue] = useState<string>("");
    const [innerErrors, setInnerErrors] = useState<string[]>([]);
    const [isValidDate, setIsValidDate] = useState<boolean>(Boolean(dateValue));

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" && onEnterPressed) {
        onEnterPressed();
      }
      if (props.onKeyDown) {
        props.onKeyDown(e);
      }
    };

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (!isValidDate && required) {
        setInnerErrors(["not a valid date"]);
      } else {
        setInnerErrors([]);
      }
      if (onBlur) {
        onBlur(e);
      }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
      const curIsValid = e.target.valueAsDate !== null;
      if (curIsValid && !isValidDate) {
        setInnerErrors([]);
      }
      setIsValidDate(curIsValid);
      if (onDateChanged) {
        onDateChanged(e.target.valueAsDate);
      }
      if (props.onChange) {
        props.onChange(e);
      }
    };

    const getErrors = () => {
      const toReturn = [];
      if (errorsInput) {
        toReturn.push(...errorsInput);
      }
      if (innerErrors) {
        toReturn.push(...innerErrors);
      }
      return toReturn;
    };

    const errors = getErrors();

    useEffect(() => {
      setValue(dateValue ? dateToYMD(dateValue) : "");
    }, [dateValue]);

    return (
      <div className={className}>
        <MLabel
          label={label}
          htmlFor={labelId}
          required={required}
          error={errors && errors.length > 0}
        />
        <div className="relative rounded-md shadow-sm">
          <input
            type="date"
            id={labelId}
            className={classNames(
              "block w-full rounded-md border-0 p-1.5 text-m-dark-gray",
              "ring-1 ring-inset placeholder:text-m-light-gray focus:ring-2",
              "focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6",
              errors && errors.length > 0 ? "ring-danger" : "ring-m-light-gray",
              props.disabled ? "cursor-not-allowed" : null
            )}
            ref={ref}
            onKeyDown={handleKeyDown}
            required={required}
            onChange={onChange}
            value={value}
            onBlur={handleOnBlur}
            {...props}
          />
        </div>
        <MErrors errors={errors} />
      </div>
    );
  }
);

MDateInputComponent.defaultProps = {
  label: null,
  errors: [],
  onEnterPressed: null,
  className: undefined,
  onDateChanged: null,
  dateValue: null,
};

export default MDateInputComponent;
