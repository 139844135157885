/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AskUserQuestionRequest
 */
export interface AskUserQuestionRequest {
  /**
   *
   * @type {string}
   * @memberof AskUserQuestionRequest
   */
  user: string;
  /**
   *
   * @type {string}
   * @memberof AskUserQuestionRequest
   */
  question: string;
}

export function AskUserQuestionRequestFromJSON(
  json: any
): AskUserQuestionRequest {
  return AskUserQuestionRequestFromJSONTyped(json, false);
}

export function AskUserQuestionRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AskUserQuestionRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    user: json["user"],
    question: json["question"],
  };
}

export function AskUserQuestionRequestToJSON(
  value?: AskUserQuestionRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    user: value.user,
    question: value.question,
  };
}
