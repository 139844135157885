/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SetPromptResponseVisibilityRequest
 */
export interface SetPromptResponseVisibilityRequest {
  /**
   *
   * @type {string}
   * @memberof SetPromptResponseVisibilityRequest
   */
  response: string;
  /**
   *
   * @type {string}
   * @memberof SetPromptResponseVisibilityRequest
   */
  presentation_layer?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SetPromptResponseVisibilityRequest
   */
  is_visible: boolean;
}

export function SetPromptResponseVisibilityRequestFromJSON(
  json: any
): SetPromptResponseVisibilityRequest {
  return SetPromptResponseVisibilityRequestFromJSONTyped(json, false);
}

export function SetPromptResponseVisibilityRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SetPromptResponseVisibilityRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    response: json["response"],
    presentation_layer: !exists(json, "presentation_layer")
      ? undefined
      : json["presentation_layer"],
    is_visible: json["is_visible"],
  };
}

export function SetPromptResponseVisibilityRequestToJSON(
  value?: SetPromptResponseVisibilityRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    response: value.response,
    presentation_layer: value.presentation_layer,
    is_visible: value.is_visible,
  };
}
