import React, { useState } from "react";
import { CreateGroupRequest } from "../../../../api/models";
import FormScreen from "../../../ui/screens/FormScreen";
import { useBusyWatcher } from "../../../../util/hooks";
import MInput from "../../../ui/MInput";
import MTextArea from "../../../ui/MTextArea";

type CreateGroupScreenProps = {
  errors: { [key: string]: string[] };
  onNextClicked: (request: CreateGroupRequest) => void;
  onBackClicked: () => void;
};

const CreateGroupScreenComponent = (props: CreateGroupScreenProps) => {
  const { errors, onNextClicked: onNextClickedInput, onBackClicked } = props;

  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [busy, _] = useBusyWatcher();

  const getNameErrors = (): string[] => {
    const toReturn: string[] = [];
    if (errors.name) {
      toReturn.push(...errors.name);
    }
    if (name.length > 64) {
      toReturn.push("name must be less than 64 characters");
    }
    return toReturn;
  };

  const getDescriptionErrors = (): string[] => {
    const toReturn: string[] = [];
    if (errors.description) {
      toReturn.push(...errors.description);
    }
    if (description.length > 256) {
      toReturn.push("description must be less than 256 characters");
    }
    return toReturn;
  };

  const canSubmit = () => {
    if (name.length > 64) {
      return false;
    }
    if (description.length > 256) {
      return false;
    }
    return true;
  };

  const onNextClicked = () => {
    const toSubmit: CreateGroupRequest = {
      name,
      description,
    };
    onNextClickedInput(toSubmit);
  };

  return (
    <FormScreen
      title="set your group name"
      comment="this is optional and strictly for your own organization. other users will never see the group's name or description."
      nextDisabled={busy || !canSubmit()}
      backDisabled={busy}
      stacked
      onNextClicked={onNextClicked}
      onBackClicked={onBackClicked}
    >
      <MInput
        placeholder={'e.g. "my homies"'}
        label="name"
        value={name}
        disabled={busy}
        errors={getNameErrors()}
        onChange={(e) => setName(e.target.value)}
        onEnterPressed={() => canSubmit() && onNextClicked()}
      />
      <MTextArea
        placeholder="e.g. 'we share trauma from bad employers'"
        label="description"
        value={description}
        disabled={busy}
        errors={getDescriptionErrors()}
        onChange={(e) => setDescription(e.target.value)}
      />
    </FormScreen>
  );
};

export default CreateGroupScreenComponent;
