import React from "react";
import {
  EmployerRelatedDetail,
  EmployerRoleDetail,
} from "../../../../api/models";
import Timeline from "./Timeline";
import EmployerRoleSummary from "./EmployerRoleSummary";
import MDivider from "../../../ui/MDivider";
import { commentText } from "../../../../util/style";
import { classNames } from "../../../../util/strings";
import { useBusyWatcher } from "../../../../util/hooks";
import MPlusButton from "../../../ui/buttons/MPlusButton";
import MInsetTitleCard from "../../../ui/cards/MInsetTitleCard";

type EmployerSummaryProps = {
  employer: EmployerRelatedDetail;
  onEditClicked?: (() => void) | null;
  onEditRoleClicked?: ((role: EmployerRoleDetail) => void) | null;
  onDeleteRoleClicked?: ((role: EmployerRoleDetail) => void) | null;
  onAddRoleClicked?: (() => void) | null;
  onDeleteClicked?: (() => void) | null;
  collapsible?: boolean;
};

const EmployerSummaryComponent = (props: EmployerSummaryProps) => {
  const {
    employer,
    onEditClicked,
    onEditRoleClicked,
    onDeleteRoleClicked,
    onAddRoleClicked,
    onDeleteClicked,
    collapsible,
  } = props;

  const showRoles = Boolean(onAddRoleClicked || employer.roles!.length > 0);
  const [busy, _] = useBusyWatcher();

  return (
    <MInsetTitleCard
      title={
        <div className="grow">
          {employer.name && employer.title ? (
            <>
              <div className="text-lg font-bold text-m-dark-gray">
                {employer.title!}
              </div>
              <div className="text-sm font-bold text-m-dark-gray">
                {employer.name}
              </div>
            </>
          ) : (
            <div className="text-lg font-bold text-m-dark-gray">
              {employer.name!}
            </div>
          )}

          <Timeline
            className="text-sm"
            start={employer.date_started}
            end={employer.date_ended}
          />
        </div>
      }
      onEditClicked={onEditClicked}
      editDisabled={busy}
      onDeleteClicked={onDeleteClicked}
      deleteDisabled={busy}
      className="bg-secondary"
      collapsible={collapsible}
    >
      <div className="flex flex-col">
        {showRoles && (
          <>
            <MDivider
              title="roles"
              button={
                onAddRoleClicked ? (
                  <MPlusButton
                    className="text-sm"
                    disabled={busy}
                    onClick={onAddRoleClicked}
                    text="role"
                    kind={
                      employer.roles!.length === 0 ? "primary" : "secondary"
                    }
                  />
                ) : null
              }
            />
            {employer.roles!.length > 0 ? (
              <div className="space-y-4">
                {employer.roles!.map((role) => (
                  <EmployerRoleSummary
                    summary={role}
                    onEditClicked={
                      onEditRoleClicked ? () => onEditRoleClicked(role) : null
                    }
                    onDeleteClicked={
                      onDeleteRoleClicked
                        ? () => onDeleteRoleClicked(role)
                        : null
                    }
                    key={role.guid!}
                  />
                ))}
              </div>
            ) : (
              <div
                className={classNames(
                  commentText,
                  "text-center mt-5 px-5 mb-3"
                )}
              >
                you haven't added any roles to this employer yet
              </div>
            )}
          </>
        )}
      </div>
    </MInsetTitleCard>
  );
};

EmployerSummaryComponent.defaultProps = {
  onEditClicked: null,
  onEditRoleClicked: null,
  onDeleteRoleClicked: null,
  onAddRoleClicked: null,
  onDeleteClicked: null,
  collapsible: false,
};

export default EmployerSummaryComponent;
