import React from "react";
import { GetQuestionnaireWithResponsesResponse } from "../../../api/models";
import CenterColumnScreen from "../../ui/screens/CenterColumnScreen";
import { commentText, paragraphText, titleTextSize } from "../../../util/style";
import MButton from "../../ui/buttons/MButton";
import { useBusyWatcher } from "../../../util/hooks";
import { classNames } from "../../../util/strings";

type AssessmentIntroScreenProps = {
  source: GetQuestionnaireWithResponsesResponse;
  target: GetQuestionnaireWithResponsesResponse;
  quizClicked: () => void;
  directClicked: () => void;
};

const AssessmentIntroScreenComponent = (props: AssessmentIntroScreenProps) => {
  const { source, target, quizClicked, directClicked } = props;

  const [busy, _] = useBusyWatcher();

  return (
    <CenterColumnScreen className="items-center text-center">
      <h2 className={classNames(titleTextSize, "mb-12")}>
        {source.questionnaire.title}
      </h2>
      <MButton kind="primary" onClick={quizClicked} disabled={busy}>
        take a lil' quiz
      </MButton>
      <div className={classNames(paragraphText, "mt-2")}>
        answer some multiple choice questions and we'll write a first draft for
        you!
      </div>
      <div className={classNames(commentText, "my-6")}>or</div>
      <MButton kind="secondary" onClick={directClicked} disabled={busy}>
        answer on your own
      </MButton>
      <div className={classNames(paragraphText, "mt-2")}>
        {target.prompts.length} simple question(s)
      </div>
    </CenterColumnScreen>
  );
};

export default AssessmentIntroScreenComponent;
