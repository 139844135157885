import React, { ReactElement } from "react";
import { GlimpseDetail } from "../../../../api/models";
import CenterColumnStickyButtonScreen from "../../../ui/screens/CenterColumnStickyButtonScreen";
import MBackButton from "../../../ui/buttons/MBackButton";
import { useBusyWatcher } from "../../../../util/hooks";
import MTitle from "../../../ui/MTitle";
import MSubtext from "../../../ui/MSubtext";
import MLabeledWell from "../../../ui/wells/MLabeledWell";

type ViewGlimpseAudienceScreenProps = {
  glimpse: GlimpseDetail;
  onBackClicked: () => void;
};

const ViewGlimpseAudienceScreenComponent = (
  props: ViewGlimpseAudienceScreenProps
) => {
  const { glimpse, onBackClicked } = props;

  const [busy, _] = useBusyWatcher();

  const getButtons = (): ReactElement[] => [
    <MBackButton
      key="back-button"
      disabled={busy}
      onClick={onBackClicked}
      className="w-full"
      kind="secondary"
    />,
  ];

  return (
    <CenterColumnStickyButtonScreen buttons={getButtons()} fullWidth stacked>
      <MTitle text="glimpse audience" className="text-center" />
      <MSubtext
        text="you cannot edit your glimpse context or audience after a glimpse has been generated. if you'd like to change your glimpse pitch context or audience we suggest creating a new glimpse."
        className="text-center"
      />
      <div className="flex flex-col gap-3">
        <MLabeledWell label="context">{glimpse.pitch_context!}</MLabeledWell>
        <MLabeledWell label="audience">{glimpse.pitch_audience!}</MLabeledWell>
      </div>
    </CenterColumnStickyButtonScreen>
  );
};

export default ViewGlimpseAudienceScreenComponent;
