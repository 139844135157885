/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  UserProfileSummary,
  UserProfileSummaryFromJSON,
  UserProfileSummaryFromJSONTyped,
  UserProfileSummaryToJSON,
} from "./UserProfileSummary";

/**
 *
 * @export
 * @interface UserInviteRelatedDetail
 */
export interface UserInviteRelatedDetail {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof UserInviteRelatedDetail
   */
  readonly guid?: string;
  /**
   * The time at which this record was created.
   * @type {Date}
   * @memberof UserInviteRelatedDetail
   */
  readonly time_created?: Date;
  /**
   * The email address that the invite was sent to.
   * @type {string}
   * @memberof UserInviteRelatedDetail
   */
  email: string;
  /**
   * The time at which the invite was accepted.
   * @type {Date}
   * @memberof UserInviteRelatedDetail
   */
  time_accepted?: Date | null;
  /**
   * The time at which the invite was resent.
   * @type {Date}
   * @memberof UserInviteRelatedDetail
   */
  time_resent?: Date | null;
  /**
   *
   * @type {boolean}
   * @memberof UserInviteRelatedDetail
   */
  readonly resend_available?: boolean;
  /**
   *
   * @type {UserProfileSummary}
   * @memberof UserInviteRelatedDetail
   */
  user?: UserProfileSummary;
}

export function UserInviteRelatedDetailFromJSON(
  json: any
): UserInviteRelatedDetail {
  return UserInviteRelatedDetailFromJSONTyped(json, false);
}

export function UserInviteRelatedDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserInviteRelatedDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    time_created: !exists(json, "time_created")
      ? undefined
      : new Date(json["time_created"]),
    email: json["email"],
    time_accepted: !exists(json, "time_accepted")
      ? undefined
      : json["time_accepted"] === null
      ? null
      : new Date(json["time_accepted"]),
    time_resent: !exists(json, "time_resent")
      ? undefined
      : json["time_resent"] === null
      ? null
      : new Date(json["time_resent"]),
    resend_available: !exists(json, "resend_available")
      ? undefined
      : json["resend_available"],
    user: !exists(json, "user")
      ? undefined
      : UserProfileSummaryFromJSON(json["user"]),
  };
}

export function UserInviteRelatedDetailToJSON(
  value?: UserInviteRelatedDetail | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    time_accepted:
      value.time_accepted === undefined
        ? undefined
        : value.time_accepted === null
        ? null
        : value.time_accepted.toISOString(),
    time_resent:
      value.time_resent === undefined
        ? undefined
        : value.time_resent === null
        ? null
        : value.time_resent.toISOString(),
    user: UserProfileSummaryToJSON(value.user),
  };
}
