import React, { useState } from "react";
import {
  GlimpseDetail,
  UpdateGlimpsePitchAudienceRequest,
} from "../../../../api/models";
import EditTextScreen from "../../../ui/screens/EditTextScreen";
import { clickableText } from "../../../../util/style";
import GlimpseExampleDialog from "./GlimpseExampleDialog";

type UpdateGlimpsePitchAudienceScreenProps = {
  glimpse: GlimpseDetail;
  errors: string[];
  onNextClicked: (newContext: UpdateGlimpsePitchAudienceRequest) => void;
  onBackClicked: () => void;
};

const UpdateGlimpsePitchAudienceScreenComponent = (
  props: UpdateGlimpsePitchAudienceScreenProps
) => {
  const {
    glimpse,
    errors,
    onNextClicked: onNextClickedInput,
    onBackClicked,
  } = props;

  const [showExampleDialog, setShowExampleDialog] = useState<boolean>(false);

  const onNextClicked = (newAudience: string) => {
    onNextClickedInput({
      glimpse: glimpse.guid!,
      pitch_audience: newAudience,
    });
  };

  return (
    <>
      <EditTextScreen
        title="set your glimpse audience"
        comment="who is the audience that you intend to share this glimpse with?"
        label="audience"
        onNextClicked={onNextClicked}
        onBackClicked={onBackClicked}
        content={glimpse.pitch_audience}
        errors={errors}
        maxLength={1024}
        extra={
          <div className="flex flex-row justify-start">
            <button
              type="button"
              onClick={() => setShowExampleDialog(true)}
              className={clickableText}
            >
              show me an example &gt;&gt;
            </button>
          </div>
        }
        required
        stacked
        long
      />
      <GlimpseExampleDialog
        show={showExampleDialog}
        onClose={() => setShowExampleDialog(false)}
      />
    </>
  );
};

export default UpdateGlimpsePitchAudienceScreenComponent;
