import Skeleton from "react-loading-skeleton";
import React from "react";
import MDateInput, { MDateInputProps } from "./MDateInput";

type MLoadingDateInputProps = MDateInputProps & {
  loading: boolean;
};

const MLoadingDateInputComponent = (props: MLoadingDateInputProps) => {
  const { loading, ...rest } = props;

  return loading ? (
    <Skeleton className="h-8 w-full" />
  ) : (
    <MDateInput {...rest} />
  );
};

export default MLoadingDateInputComponent;
