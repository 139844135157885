/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SubmitOnboardingPromptResponseRequest
 */
export interface SubmitOnboardingPromptResponseRequest {
  /**
   *
   * @type {string}
   * @memberof SubmitOnboardingPromptResponseRequest
   */
  prompt: string;
  /**
   *
   * @type {string}
   * @memberof SubmitOnboardingPromptResponseRequest
   */
  response: string;
  /**
   *
   * @type {boolean}
   * @memberof SubmitOnboardingPromptResponseRequest
   */
  publish?: boolean | null;
}

export function SubmitOnboardingPromptResponseRequestFromJSON(
  json: any
): SubmitOnboardingPromptResponseRequest {
  return SubmitOnboardingPromptResponseRequestFromJSONTyped(json, false);
}

export function SubmitOnboardingPromptResponseRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SubmitOnboardingPromptResponseRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    prompt: json["prompt"],
    response: json["response"],
    publish: !exists(json, "publish") ? undefined : json["publish"],
  };
}

export function SubmitOnboardingPromptResponseRequestToJSON(
  value?: SubmitOnboardingPromptResponseRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    prompt: value.prompt,
    response: value.response,
    publish: value.publish,
  };
}
