import React, { useEffect, useState } from "react";
import PromptRespondScreen, {
  PromptRespondScreenProps,
} from "./PromptRespondScreen";
import { PublishablePromptWithResponse } from "../../../api/models";
import LeftRightTransition from "../transitions/LeftRightTransition";
import {
  DEFAULT_TRANSITION_ENTER_DURATION,
  DEFAULT_TRANSITION_LEAVE_DURATION,
  DEFAULT_TRANSITION_PADDING,
} from "../../../util/constants";
import { useBackTimer } from "../../../util/hooks";
import { MStepsCirclesProps } from "../MStepsCircles";

type PromptRespondTransitionScreenProps = PromptRespondScreenProps & {
  enterMs?: number;
  leaveMs?: number;
  paddingMs?: number;
};

const PromptRespondTransitionScreenComponent = (
  props: PromptRespondTransitionScreenProps
) => {
  const {
    prompt,
    steps: stepsInput,
    enterMs,
    leaveMs,
    paddingMs,
    onBackClicked,
    ...rest
  } = props;

  const [prompt0, setPrompt0] = useState<PublishablePromptWithResponse | null>(
    prompt
  );
  const [prompt1, setPrompt1] = useState<PublishablePromptWithResponse | null>(
    null
  );
  const [steps, setSteps] = useState<MStepsCirclesProps | null>(
    stepsInput || null
  );
  const [promptIndex, setPromptIndex] = useState<number>(0);
  const [isBack, asBack] = useBackTimer(prompt.prompt.guid);

  useEffect(() => {
    if (promptIndex === -1) {
      throw new Error(
        "Prompt index should never be -1 when prompt GUID changes"
      );
    }

    // Handle the case of initial loading
    if (
      promptIndex === 0 &&
      prompt0 &&
      prompt0.prompt.guid! === prompt.prompt.guid!
    ) {
      return;
    }

    let nextIndex: number;
    if (promptIndex === 0) {
      setPrompt1(prompt);
      nextIndex = 1;
    } else {
      setPrompt0(prompt);
      nextIndex = 0;
    }
    setPromptIndex(-1);
    setTimeout(() => {
      setPromptIndex(nextIndex);
      setSteps(stepsInput || null);
    }, enterMs! + paddingMs!);
  }, [prompt.prompt.guid]);

  return (
    <>
      <LeftRightTransition
        enterMs={enterMs}
        leaveMs={leaveMs}
        show={promptIndex === 0}
        isBack={isBack}
        appear={false}
      >
        <PromptRespondScreen
          prompt={prompt0!}
          steps={steps}
          onBackClicked={onBackClicked ? asBack(onBackClicked) : null}
          {...rest}
        />
      </LeftRightTransition>
      <LeftRightTransition
        enterMs={enterMs}
        leaveMs={leaveMs}
        show={promptIndex === 1}
        isBack={isBack}
        appear={false}
      >
        <PromptRespondScreen
          prompt={prompt1!}
          steps={steps}
          onBackClicked={onBackClicked ? asBack(onBackClicked) : null}
          {...rest}
        />
      </LeftRightTransition>
    </>
  );
};

PromptRespondTransitionScreenComponent.defaultProps = {
  enterMs: DEFAULT_TRANSITION_ENTER_DURATION,
  leaveMs: DEFAULT_TRANSITION_LEAVE_DURATION,
  paddingMs: DEFAULT_TRANSITION_PADDING,
};

export default PromptRespondTransitionScreenComponent;
