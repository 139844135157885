import React from "react";
import { SearchForQuestionsResponse } from "../../../api/models";
import { useBusyWatcher } from "../../../util/hooks";
import LoadingQuestionCard from "../../ui/loading/LoadingQuestionCard";
import MPaginationRow from "../../ui/MPaginationRow";
import MSearchBox from "../../ui/MSearchBox";
import QuestionCards from "../../ui/cards/QuestionCards";
import SidebarCommentRow from "../../ui/screens/sidebarnav/SidebarCommentRow";
import SidebarSection from "../../ui/screens/sidebarnav/SidebarSection";
import MPlusButton from "../../ui/buttons/MPlusButton";

type QuestionSearchScreenProps = {
  title?: string;
  loading: boolean;
  response: SearchForQuestionsResponse | null;
  errors?: string[] | null;
  searchTerm: string;
  onSearchTermUpdated: (newTerm: string) => void;
  searchBaseUrl: string;
  createQuestionUrl?: string | null;
  onPageClicked: (page: number) => void;
  offset: number;
  pageSize: number;
  placeholder?: string;
};

const QuestionSearchScreenComponent = (props: QuestionSearchScreenProps) => {
  const {
    title,
    loading,
    response,
    errors,
    searchTerm,
    onSearchTermUpdated,
    searchBaseUrl,
    createQuestionUrl,
    onPageClicked,
    offset,
    pageSize,
    placeholder,
  } = props;

  const [busy, _] = useBusyWatcher();

  return (
    <SidebarSection
      title={title}
      divideY={false}
      cta={
        <div className="flex gap-3">
          {createQuestionUrl && (
            <MPlusButton
              href={createQuestionUrl}
              disabled={busy}
              kind="primary"
              text="create new"
              className="whitespace-nowrap"
            />
          )}
          <MSearchBox
            errors={errors}
            searchTerm={searchTerm}
            onSearchTermUpdated={onSearchTermUpdated}
            placeholder={placeholder}
          />
        </div>
      }
    >
      {loading && (
        <>
          <LoadingQuestionCard />
          <LoadingQuestionCard />
        </>
      )}
      {!loading && response && response.results.length > 0 && (
        <div className="py-8">
          <QuestionCards
            questions={response.results}
            searchBaseUrl={searchBaseUrl}
          />
          <div id="pagination" className="mt-3">
            <MPaginationRow
              pageSize={pageSize}
              startIndex={offset}
              totalCount={response?.count}
              onPageClicked={onPageClicked}
              disabled={busy}
              copy="questions"
            />
          </div>
        </div>
      )}
      {!loading && (!response || response.results.length === 0) && (
        <SidebarCommentRow comment="no questions found." />
      )}
    </SidebarSection>
  );
};

QuestionSearchScreenComponent.defaultProps = {
  title: "questions",
  errors: null,
  placeholder: "search terms",
  createQuestionUrl: null,
};

export default QuestionSearchScreenComponent;
export type { QuestionSearchScreenProps };
