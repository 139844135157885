import React from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { MNavProps } from "../ui/nav/MNav";
import BaseNavbarPage from "./BaseNavbarPage";

type MarkdownPageProps = {
  navbarProps: MNavProps;
  title: string;
  content: string;
  showSearch?: boolean;
};

const MarkdownPageComponent = (props: MarkdownPageProps) => {
  const { navbarProps, title, content, showSearch } = props;

  return (
    <BaseNavbarPage navbarProps={{ ...navbarProps, showSearch: showSearch! }}>
      <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8 mb-10">
        <div className="text-xl mb-6 p-2 font-semibold bg-primary/10">
          {title}
        </div>
        <div className="text-m-dark-gray markdown-container">
          <ReactMarkdown>{content}</ReactMarkdown>
        </div>
      </div>
    </BaseNavbarPage>
  );
};

MarkdownPageComponent.defaultProps = {
  showSearch: true,
};

export default MarkdownPageComponent;
