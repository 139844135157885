/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  InsightResponseEnvelope,
  InsightResponseEnvelopeFromJSON,
  InsightResponseEnvelopeFromJSONTyped,
  InsightResponseEnvelopeToJSON,
} from "./InsightResponseEnvelope";
import {
  InsightsLimits,
  InsightsLimitsFromJSON,
  InsightsLimitsFromJSONTyped,
  InsightsLimitsToJSON,
} from "./InsightsLimits";

/**
 *
 * @export
 * @interface SearchForInsightsResponse
 */
export interface SearchForInsightsResponse {
  /**
   *
   * @type {number}
   * @memberof SearchForInsightsResponse
   */
  offset?: number;
  /**
   *
   * @type {number}
   * @memberof SearchForInsightsResponse
   */
  limit?: number;
  /**
   *
   * @type {Array<InsightResponseEnvelope>}
   * @memberof SearchForInsightsResponse
   */
  insights: Array<InsightResponseEnvelope>;
  /**
   *
   * @type {number}
   * @memberof SearchForInsightsResponse
   */
  count: number;
  /**
   *
   * @type {InsightsLimits}
   * @memberof SearchForInsightsResponse
   */
  limits: InsightsLimits;
}

export function SearchForInsightsResponseFromJSON(
  json: any
): SearchForInsightsResponse {
  return SearchForInsightsResponseFromJSONTyped(json, false);
}

export function SearchForInsightsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SearchForInsightsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    offset: !exists(json, "offset") ? undefined : json["offset"],
    limit: !exists(json, "limit") ? undefined : json["limit"],
    insights: (json["insights"] as Array<any>).map(
      InsightResponseEnvelopeFromJSON
    ),
    count: json["count"],
    limits: InsightsLimitsFromJSON(json["limits"]),
  };
}

export function SearchForInsightsResponseToJSON(
  value?: SearchForInsightsResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    offset: value.offset,
    limit: value.limit,
    insights: (value.insights as Array<any>).map(InsightResponseEnvelopeToJSON),
    count: value.count,
    limits: InsightsLimitsToJSON(value.limits),
  };
}
