import React, { useState } from "react";
import * as EmailValidator from "email-validator";
import { XMarkIcon } from "@heroicons/react/24/outline";
import MSelect from "../../ui/MSelect";
import { useBusyWatcher } from "../../../util/hooks";
import MTooltipIcon from "../../ui/MTooltipIcon";
import {
  SetProfileAccessRequest,
  UserProfileSummary,
} from "../../../api/models";
import { useAsyncUserSearchDialog } from "../../dialogs/hooks";
import SettingsTextAddButton from "../../ui/screens/sidebarnav/SidebarTextAddButton";
import MPlusButton from "../../ui/buttons/MPlusButton";
import MInput from "../../ui/MInput";
import { classNames } from "../../../util/strings";

type SettingsAddPrivateProfileAccessRowProps = {
  onCompleted: (toAdd: SetProfileAccessRequest) => void;
  onCanceled: () => void;
};

const SettingsAddPrivateProfileAccessRowComponent = (
  props: SettingsAddPrivateProfileAccessRowProps
) => {
  const { onCompleted: inputOnCompleted, onCanceled } = props;

  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState<UserProfileSummary | null>(
    null
  );
  const [selectedEmail, setSelectedEmail] = useState<string>("");
  const [busy, _] = useBusyWatcher();
  const userSearchDialog = useAsyncUserSearchDialog();

  const onTypeSelected = (accessType: string | null) => {
    setSelectedUser(null);
    setSelectedEmail("");
    setSelectedType(accessType);
  };

  const onSelectUserClicked = async () => {
    const newSelectedUser = await userSearchDialog.selectUser({});
    if (newSelectedUser === null) {
      return;
    }
    setSelectedUser(newSelectedUser);
  };

  const canAdd = (): boolean => {
    if (selectedType === null) {
      return false;
    }
    if (selectedType === "user" && selectedUser === null) {
      return false;
    }
    if (selectedType === "email" && !EmailValidator.validate(selectedEmail)) {
      return false;
    }
    return true;
  };

  const showAddButton = (): boolean => {
    if (selectedType === "email") {
      return true;
    }
    if (selectedType === "user" && selectedUser !== null) {
      return true;
    }
    return false;
  };

  const onCompleted = () => {
    switch (selectedType) {
      case "user":
        inputOnCompleted({
          user: selectedUser!.id!,
          is_allowed: true,
        });
        break;
      case "email":
        inputOnCompleted({
          email: selectedEmail,
          is_allowed: true,
        });
        break;
      default:
        throw new Error(`invalid access type ${selectedType}`);
    }
  };

  return (
    <>
      <div className="pt-4 flex flex-col items-start gap-2 sm:flex-row sm:items-center border-t border-m-light-gray">
        <div className="flex flex-row items-center gap-2 w-full sm:w-auto">
          <MSelect
            options={[
              { name: "user", key: "user" },
              { name: "email", key: "email" },
            ]}
            onSelected={(option) => onTypeSelected(option?.name || null)}
            disabled={busy}
            placeholder="access type"
            className="w-44"
          />
          <div className="grow sm:grow-0 mr-6">
            <MTooltipIcon
              tooltip={
                "when adding private profile access, you have two options: user or email.\n\nif the person you want to grant access to is already on the platform with a public profile select 'user' and look them up.\n\nif the person you want to grant access to is not yet on the platform or has a private profile, select 'email' and add them via their account's email address."
              }
            />
          </div>
          <div className="flex flex-row gap-2 sm:hidden text-right">
            {showAddButton() && (
              <MPlusButton
                kind="primary"
                disabled={busy || !canAdd()}
                onClick={onCompleted}
              />
            )}
            <button type="button" onClick={onCanceled} aria-label="cancel">
              <XMarkIcon
                className="h-6 w-6 text-primary hover:brightness-110"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
        <div
          className={classNames(
            "h-8 flex flex-row items-center sm:grow",
            selectedType !== null ? "" : "hidden"
          )}
        >
          {selectedType === "user" && selectedUser === null && (
            <SettingsTextAddButton
              text="select a user"
              onClick={onSelectUserClicked}
            />
          )}
          {selectedType === "user" && selectedUser !== null && (
            <div>
              <a
                href={`/@${selectedUser.username}`}
                target="_blank"
                rel="noreferrer"
                className="text-primary hover:underline"
              >
                {selectedUser.name} (@{selectedUser.username})
              </a>
            </div>
          )}
          {selectedType === "email" && (
            <MInput
              value={selectedEmail}
              onChange={(e) => setSelectedEmail(e.target.value)}
              disabled={busy}
              placeholder="someone@sample.com"
              className="w-64"
              onEnterPressed={() => canAdd() && onCompleted()}
            />
          )}
        </div>
        {selectedType === null && <div className="grow" />}
        <div className="sm:flex flex-row hidden gap-3">
          {showAddButton() && (
            <MPlusButton
              kind="primary"
              disabled={busy || !canAdd()}
              onClick={onCompleted}
            />
          )}
          <button type="button" onClick={onCanceled} aria-label="cancel">
            <XMarkIcon
              className="h-6 w-6 text-primary hover:brightness-110"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
      {userSearchDialog.dialog}
    </>
  );
};

export default SettingsAddPrivateProfileAccessRowComponent;
