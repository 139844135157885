/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  EmptyResponseWrapper,
  EmptyResponseWrapperFromJSON,
  EmptyResponseWrapperToJSON,
  GetTaskStatusResponseSerializer,
  GetTaskStatusResponseSerializerFromJSON,
  GetTaskStatusResponseSerializerToJSON,
} from "../models";

export interface GetTaskStatusRequest {
  task: string;
}

/**
 *
 */
export class TasksApi extends runtime.BaseAPI {
  /**
   * Get the status of a task.
   */
  async getTaskStatusRaw(
    requestParameters: GetTaskStatusRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetTaskStatusResponseSerializer>> {
    if (
      requestParameters.task === null ||
      requestParameters.task === undefined
    ) {
      throw new runtime.RequiredError(
        "task",
        "Required parameter requestParameters.task was null or undefined when calling getTaskStatus."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.task !== undefined) {
      queryParameters["task"] = requestParameters.task;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/tasks/status`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetTaskStatusResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the status of a task.
   */
  async getTaskStatus(
    requestParameters: GetTaskStatusRequest,
    initOverrides?: RequestInit
  ): Promise<GetTaskStatusResponseSerializer> {
    const response = await this.getTaskStatusRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
