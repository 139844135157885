/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ProfileCompletionStage
 */
export interface ProfileCompletionStage {
  /**
   *
   * @type {string}
   * @memberof ProfileCompletionStage
   */
  stage: ProfileCompletionStageStageEnum;
  /**
   *
   * @type {string}
   * @memberof ProfileCompletionStage
   */
  comment: string;
  /**
   *
   * @type {string}
   * @memberof ProfileCompletionStage
   */
  button_text: string;
  /**
   *
   * @type {boolean}
   * @memberof ProfileCompletionStage
   */
  completed: boolean;
  /**
   *
   * @type {string}
   * @memberof ProfileCompletionStage
   */
  href: string;
  /**
   *
   * @type {boolean}
   * @memberof ProfileCompletionStage
   */
  skippable?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum ProfileCompletionStageStageEnum {
  assessment = "assessment",
  questionnaire = "questionnaire",
  vitals = "vitals",
  work_history = "work_history",
  profile_picture = "profile_picture",
  bio = "bio",
  links = "links",
}

export function ProfileCompletionStageFromJSON(
  json: any
): ProfileCompletionStage {
  return ProfileCompletionStageFromJSONTyped(json, false);
}

export function ProfileCompletionStageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProfileCompletionStage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    stage: json["stage"],
    comment: json["comment"],
    button_text: json["button_text"],
    completed: json["completed"],
    href: json["href"],
    skippable: !exists(json, "skippable") ? undefined : json["skippable"],
  };
}

export function ProfileCompletionStageToJSON(
  value?: ProfileCompletionStage | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    stage: value.stage,
    comment: value.comment,
    button_text: value.button_text,
    completed: value.completed,
    href: value.href,
    skippable: value.skippable,
  };
}
