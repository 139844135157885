import React, { useState } from "react";
import {
  GlimpseDetail,
  UpdateGlimpseContentRequest,
} from "../../../../api/models";
import { useBusyWatcher } from "../../../../util/hooks";
import MTextArea from "../../../ui/MTextArea";
import FormScreen from "../../../ui/screens/FormScreen";

type ReviewGlimpseContentScreenProps = {
  glimpse: GlimpseDetail;
  errors: string[];
  onNextClicked: (newContent: UpdateGlimpseContentRequest) => void;
  onBackClicked?: (() => void) | null;
};

const ReviewGlimpseContentScreenComponent = (
  props: ReviewGlimpseContentScreenProps
) => {
  const {
    glimpse,
    errors: errorsInput,
    onNextClicked: onNextClickedInput,
    onBackClicked,
  } = props;

  const [content, setContent] = useState<string>(glimpse.content ?? "");
  const [busy, _] = useBusyWatcher();

  const getContentErrors = (): string[] => {
    const toReturn: string[] = [...errorsInput];
    if (content.length > 4096) {
      toReturn.push("content must be less than 4096 characters");
    }
    return toReturn;
  };

  const canSubmit = (): boolean => {
    if (content.length === 0) {
      return false;
    }
    if (content.length > 4096) {
      return false;
    }
    return true;
  };

  const onNextClicked = () => {
    onNextClickedInput({ glimpse: glimpse.guid!, content });
  };

  return (
    <FormScreen
      title="set the content of your glimpse"
      comment="this is the content that will be shown to everyone that visits your glimpse."
      nextDisabled={busy || !canSubmit()}
      onNextClicked={onNextClicked}
      onBackClicked={onBackClicked}
      backDisabled={busy}
      stacked
    >
      <MTextArea
        label="content"
        value={content}
        onChange={(e) => setContent(e.target.value)}
        disabled={busy}
        errors={getContentErrors()}
        placeholder="my name is ____ and i am the best pick for ____..."
      />
    </FormScreen>
  );
};

ReviewGlimpseContentScreenComponent.defaultProps = {
  onBackClicked: null,
};

export default ReviewGlimpseContentScreenComponent;
