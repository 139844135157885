import React, { useEffect, useState } from "react";
import { PromptDisplayCardProps } from "./PromptDisplayCard";
import {
  EitherOrPromptMeta,
  EitherOrPromptResponseMeta,
} from "../../../../api/types";
import EmptyPromptResponseContent from "./EmptyPromptResponseContent";
import MInsetTitleCard from "../MInsetTitleCard";

type eitherOrEntry = {
  choice: string;
  other: string;
};

const EitherOrDisplayCardComponent = (props: PromptDisplayCardProps) => {
  const { prompt, ...rest } = props;

  const [title, setTitle] = useState<string>("");
  const [entries, setEntries] = useState<eitherOrEntry[]>([]);

  useEffect(() => {
    const promptMeta = prompt.prompt.meta as EitherOrPromptMeta;
    setTitle(prompt.prompt.statement);
    if (prompt.response) {
      const responseMeta = prompt.response.meta as EitherOrPromptResponseMeta;
      const newEntries: eitherOrEntry[] = [];
      for (let i = 0; i < promptMeta.choices.length; i += 1) {
        if (responseMeta.response.includes(promptMeta.choices[i][0])) {
          newEntries.push({
            choice: promptMeta.choices[i][0],
            other: promptMeta.choices[i][1],
          });
        } else if (responseMeta.response.includes(promptMeta.choices[i][1])) {
          newEntries.push({
            choice: promptMeta.choices[i][1],
            other: promptMeta.choices[i][0],
          });
        }
      }
      setEntries(newEntries);
    } else {
      setEntries([]);
    }
  }, [prompt.prompt.guid, prompt.response?.meta]);

  return (
    <MInsetTitleCard
      type={entries.length > 0 ? "regular" : "warning"}
      title={title}
      {...rest}
    >
      <div className="flex flex-col gap-3">
        {entries.length > 0 &&
          entries.map((entry) => (
            <div
              key={entry.choice + entry.other}
              className="grid grid-cols-9 gap-3"
            >
              <div className="font-bold text-right col-span-4 flex flex-col justify-center underline">
                {entry.choice}
              </div>
              <div className="col-span-1 w-full text-center flex flex-col justify-center text-m-gray">
                vs
              </div>
              <div className="col-span-4 flex flex-col justify-center text-m-gray line-through">
                {entry.other}
              </div>
            </div>
          ))}
        {entries.length === 0 && <EmptyPromptResponseContent />}
      </div>
    </MInsetTitleCard>
  );
};

export default EitherOrDisplayCardComponent;
