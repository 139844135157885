import React, { ReactNode } from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import MButton, { MButtonProps } from "./MButton";

type MPlusButtonProps = MButtonProps & {
  text?: string | null;
  icon?: ReactNode;
};

const MPlusButtonComponent = (props: MPlusButtonProps) => {
  const { text, ...rest } = props;

  return <MButton {...rest}>{text}</MButton>;
};

MPlusButtonComponent.defaultProps = {
  text: "add",
  icon: <PlusIcon className="h-6 w-5 flex-none" aria-hidden="true" />,
};

export default MPlusButtonComponent;
