import React, { ReactElement, useState } from "react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import {
  GetInferenceResultsResponse,
  TargetResponse,
} from "../../../api/models";
import CenterColumnStickyButtonScreen from "../../ui/screens/CenterColumnStickyButtonScreen";
import { classNames } from "../../../util/strings";
import { commentText } from "../../../util/style";
import MButton from "../../ui/buttons/MButton";
import PromptSuggestionCard from "./PromptSuggestionCard";
import { useBusyWatcher } from "../../../util/hooks";
import MTitle from "../../ui/MTitle";

type AssessmentViewSuggestionsScreenProps = {
  results: GetInferenceResultsResponse;
  sourceTopic: string;
  onEditClicked: () => void;
  onSaveClicked: (suggestions: TargetResponse[]) => void;
  onCancelClicked: () => void;
  errors: { [key: string]: string };
};

const AssessmentViewSuggestionsScreenComponent = (
  props: AssessmentViewSuggestionsScreenProps
) => {
  const {
    results,
    sourceTopic,
    onEditClicked,
    onSaveClicked: onSaveClickedInput,
    onCancelClicked,
    errors,
  } = props;

  const numberedArray = (length: number): number[] => {
    const arr: number[] = [];
    for (let i = 0; i < length; i += 1) {
      arr.push(i);
    }
    return arr;
  };

  const [included, setIncluded] = useState<number[]>(
    numberedArray(results.suggestions.length)
  );
  const [suggestions, setSuggestions] = useState<string[]>(results.suggestions);
  const [busy, _] = useBusyWatcher();

  const toggleInclusion = (index: number): void => {
    const newIncluded = [...included];
    if (included.includes(index)) {
      newIncluded.splice(newIncluded.indexOf(index), 1);
    } else {
      newIncluded.push(index);
    }
    setIncluded(newIncluded);
  };

  const onSuggestionUpdated = (index: number, newSuggestion: string): void => {
    const newSuggestions = [...suggestions];
    newSuggestions[index] = newSuggestion;
    setSuggestions(newSuggestions);
  };

  const onSaveClicked = (): void => {
    const newSuggestions: TargetResponse[] = [];
    for (let i = 0; i < included.length; i += 1) {
      newSuggestions.push({
        prompt: results.prompts[included[i]].prompt.guid!,
        response: suggestions[included[i]],
      });
    }
    onSaveClickedInput(newSuggestions);
  };

  const getButtons = (): ReactElement[] => [
    <MButton
      key="save"
      kind="primary"
      className="w-full"
      disabled={busy || included.length === 0}
      onClick={onSaveClicked}
    >
      save {included.length} responses to profile
    </MButton>,
    <MButton
      key="cancel"
      kind="warning"
      className="w-full"
      disabled={busy}
      onClick={onCancelClicked}
    >
      exit without saving
    </MButton>,
  ];

  return (
    <CenterColumnStickyButtonScreen buttons={getButtons()} stacked fullWidth>
      <div className="flex flex-col items-center">
        <MTitle text="here's a first draft!" />
        <div className={classNames("my-4 text-center", commentText)}>
          <div className="mb-2">
            based on your quiz responses, we wrote answers to{" "}
            {results.suggestions.length} question(s) about {sourceTopic}. take a
            look & edit below!
          </div>
          <div>
            when you're done, hit the "save" button at the bottom of this page.
          </div>
        </div>
        <div className="flex flex-col gap-3">
          {results.prompts.map((prompt, index) => (
            <PromptSuggestionCard
              key={prompt.prompt.guid!}
              prompt={prompt}
              suggestion={results.suggestions[index]}
              onIncludeClicked={() => toggleInclusion(index)}
              included={included.includes(index)}
              onSuggestionUpdated={(newSuggestion) =>
                onSuggestionUpdated(index, newSuggestion)
              }
              errors={
                errors[prompt.prompt.guid!] ? [errors[prompt.prompt.guid!]] : []
              }
            />
          ))}
        </div>
        <div className={classNames(commentText, "mt-4")}>not quite right?</div>
        <MButton
          icon={<PencilSquareIcon className="h-6 w-6" />}
          onClick={onEditClicked}
          className="mt-1 mb-3"
          kind="secondary"
          disabled={busy}
        >
          edit your quiz responses
        </MButton>
      </div>
    </CenterColumnStickyButtonScreen>
  );
};

export default AssessmentViewSuggestionsScreenComponent;
