/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MessageWithLinkUserNotificationSummary
 */
export interface MessageWithLinkUserNotificationSummary {
  /**
   * The message to display in the notification, markdown formatted.
   * @type {string}
   * @memberof MessageWithLinkUserNotificationSummary
   */
  message: string;
  /**
   * The text to display on the button in the notification.
   * @type {string}
   * @memberof MessageWithLinkUserNotificationSummary
   */
  button_text?: string | null;
  /**
   * The link to display in the notification.
   * @type {string}
   * @memberof MessageWithLinkUserNotificationSummary
   */
  href?: string | null;
  /**
   * Whether the link should be opened in a separate window.
   * @type {boolean}
   * @memberof MessageWithLinkUserNotificationSummary
   */
  separate_window?: boolean;
}

export function MessageWithLinkUserNotificationSummaryFromJSON(
  json: any
): MessageWithLinkUserNotificationSummary {
  return MessageWithLinkUserNotificationSummaryFromJSONTyped(json, false);
}

export function MessageWithLinkUserNotificationSummaryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MessageWithLinkUserNotificationSummary {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    message: json["message"],
    button_text: !exists(json, "button_text") ? undefined : json["button_text"],
    href: !exists(json, "href") ? undefined : json["href"],
    separate_window: !exists(json, "separate_window")
      ? undefined
      : json["separate_window"],
  };
}

export function MessageWithLinkUserNotificationSummaryToJSON(
  value?: MessageWithLinkUserNotificationSummary | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    message: value.message,
    button_text: value.button_text,
    href: value.href,
    separate_window: value.separate_window,
  };
}
