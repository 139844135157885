import React, { ReactNode, useEffect, useState } from "react";
import {
  EitherOr,
  EitherOrTagsEnum,
  PromptRelatedDetail,
} from "../../../../api/models";
import EitherOrQuestion from "../../../ui/forms/questions/EitherOrQuestion";
import { useBusyWatcher } from "../../../../util/hooks";
import MTextArea from "../../../ui/MTextArea";
import MLabel from "../../../ui/MLabel";
import MInput from "../../../ui/MInput";
import MWellDeleteButton from "../../../ui/wells/MWellDeleteButton";
import { clickableText } from "../../../../util/style";
import { classNames, hasDuplicateStrings } from "../../../../util/strings";
import TagSearchAndSelect from "../../../ui/TagSearchAndSelect";
import {
  CommonEditQuestionProps,
  maxEitherOrChoiceLength,
  maxEitherOrChoices,
  maxQuestionLength,
  maxStatementLength,
} from "./common";
import CommonEditQuestionScreen from "./CommonEditQuestionScreen";
import { EitherOrPromptMeta } from "../../../../api/types";

type EditEitherOrQuestionScreenProps = CommonEditQuestionProps & {
  onNextClicked: (result: EitherOr) => void;
};

const EditEitherOrQuestionScreenComponent = (
  props: EditEitherOrQuestionScreenProps
) => {
  const {
    onBackClicked,
    onNextClicked: onNextClickedInput,
    errors,
    errorMap,
    onTagSearchTermUpdated,
    tagsSearching,
    tags,
    prompt,
    isCreate,
  } = props;

  const [question, setQuestion] = useState<string>("");
  const [statement, setStatement] = useState<string>("");
  const [options, setOptions] = useState<[string, string][]>([["", ""]]);
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [rowCount, setRowCount] = useState<number>(1);
  const [busy, _] = useBusyWatcher();

  const getTitle = (): string => {
    const verb = isCreate ? "Create" : "Edit";
    return `${verb} 'Either Or' Question`;
  };

  const populateFromPrompt = (fromPrompt: PromptRelatedDetail) => {
    setQuestion(fromPrompt.question);
    setStatement(fromPrompt.statement);
    setSelectedTags(fromPrompt.tags);
    if (fromPrompt.meta) {
      const promptMeta = fromPrompt.meta as EitherOrPromptMeta;
      setOptions(promptMeta.choices ?? [["", ""]]);
      setRowCount(promptMeta.choices?.length ?? 1);
    }
  };

  const onNextClicked = () => {
    onNextClickedInput({
      question,
      statement,
      tags: selectedTags as EitherOrTagsEnum[],
      choices: options,
    });
  };

  const cellsAreValid = (): boolean => {
    for (let i = 0; i < options.length; i += 1) {
      const [a, b] = options[i];
      if (a.trim().length === 0 || b.trim().length === 0) {
        return false;
      }
      if (a.length > maxEitherOrChoiceLength) {
        return false;
      }
      if (b.length > maxEitherOrChoiceLength) {
        return false;
      }
    }
    if (hasDuplicateStrings(options.flat().map((o) => o.trim()))) {
      return false;
    }
    return true;
  };

  const canSubmit = (): boolean => {
    if (question.length === 0) {
      return false;
    }
    if (question.length > maxQuestionLength) {
      return false;
    }
    if (statement.length === 0) {
      return false;
    }
    if (statement.length > maxStatementLength) {
      return false;
    }
    if (options.length === 0) {
      return false;
    }
    if (options.length > maxEitherOrChoices) {
      return false;
    }
    if (!cellsAreValid()) {
      return false;
    }
    return true;
  };

  const onDeleteRowClicked = (row: number) => {
    const newOptions = [...options];
    newOptions.splice(row, 1);
    setOptions(newOptions);
    setRowCount(rowCount - 1);
  };

  useEffect(() => {
    if (prompt) {
      populateFromPrompt(prompt);
    }
  }, [prompt]);

  const getChoicesRow = (row: number): ReactNode => (
    <div key={row} className="flex flex-row gap-3 items-start">
      <MInput
        className="grow"
        type="text"
        maxLength={maxEitherOrChoiceLength}
        value={options[row][0]}
        onChange={(e) => {
          const newOptions = [...options];
          newOptions[row][0] = e.target.value;
          setOptions(newOptions);
        }}
        onEnterPressed={() => canSubmit() && onNextClicked()}
      />
      <div className="flex flex-row gap-3 grow items-center">
        <MInput
          className="grow"
          type="text"
          maxLength={maxEitherOrChoiceLength}
          value={options[row][1]}
          onChange={(e) => {
            const newOptions = [...options];
            newOptions[row][1] = e.target.value;
            setOptions(newOptions);
          }}
          onEnterPressed={() => canSubmit() && onNextClicked()}
        />
        <MWellDeleteButton
          onClick={() => onDeleteRowClicked(row)}
          disabled={rowCount === 1}
        />
      </div>
    </div>
  );

  const getChoicesRows = (): ReactNode => {
    const rows = [];
    for (let i = 0; i < rowCount; i += 1) {
      rows.push(getChoicesRow(i));
    }
    return rows;
  };

  return (
    <CommonEditQuestionScreen
      title={getTitle()}
      previewQuestion={
        <EitherOrQuestion
          options={
            options.length > 0
              ? options
              : [["sample choice 1", "sample choice 2"]]
          }
          question={question || "Your question text will show up here"}
          onUpdated={setSelected}
          selected={selected}
        />
      }
      previewResponse={{
        prompt: {
          question,
          statement:
            statement ||
            "your statement shows up here and is a rephrasing of your question in statement format",
          question_type: "either_or",
          tags: [],
          meta: {
            choices:
              options.length > 0
                ? options
                : [["sample choice 1", "sample choice 2"]],
          },
        },
        response: {
          meta: {
            response: selected,
          },
        },
      }}
      onNextClicked={onNextClicked}
      nextDisabled={busy || !canSubmit()}
      onBackClicked={onBackClicked}
      backDisabled={busy}
      errors={errors}
    >
      <MTextArea
        label="question"
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        characterLimit={maxQuestionLength}
        placeholder="which super heroes do you prefer?"
        errors={errorMap?.question}
        required
      />
      <MTextArea
        label="statement"
        value={statement}
        onChange={(e) => setStatement(e.target.value)}
        characterLimit={maxStatementLength}
        placeholder="my super hero choices are..."
        errors={errorMap?.statement}
        required
      />
      <div>
        <MLabel label="tags" />
        <TagSearchAndSelect
          selected={selectedTags}
          onSearchTermUpdated={onTagSearchTermUpdated}
          onSelectedTagsUpdated={setSelectedTags}
          tags={tags?.tags ?? []}
          loading={tagsSearching}
          disabled={busy}
        />
      </div>
      <div className="flex flex-col gap-2">
        <div>
          <MLabel label="choices" required />
          <div className="text-xs">
            all options must be unique (ie: no two choices can have the same
            value)
          </div>
        </div>
        {getChoicesRows()}
        <div>
          <button
            type="button"
            className={classNames(
              rowCount < maxEitherOrChoices ? clickableText : null,
              rowCount >= maxEitherOrChoices
                ? "line-through text-m-light-gray cursor-not-allowed"
                : null
            )}
            onClick={() => {
              setOptions([...options, ["", ""]]);
              setRowCount(rowCount + 1);
            }}
            disabled={rowCount >= maxEitherOrChoices}
          >
            + choice
          </button>
        </div>
      </div>
    </CommonEditQuestionScreen>
  );
};

export default EditEitherOrQuestionScreenComponent;
