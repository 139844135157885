import React from "react";
import MWellButton from "./MWellButton";
import { CommonWellButtonProps } from "./common";

type MWellVoteButtonProps = CommonWellButtonProps & {
  upvoted: boolean;
};

const MWellVoteButtonComponent = (props: MWellVoteButtonProps) => {
  const { upvoted } = props;

  const upvoteSolid = <i className="fa-solid fa-square-caret-up fa-lg" />;
  const upvoteOutline = <i className="fa-regular fa-square-caret-up fa-lg" />;
  const icon = upvoted ? upvoteSolid : upvoteOutline;

  return <MWellButton {...props} icon={icon} />;
};

export default MWellVoteButtonComponent;
