/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AssessmentDetail
 */
export interface AssessmentDetail {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof AssessmentDetail
   */
  readonly guid?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentDetail
   */
  readonly title?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentDetail
   */
  readonly description?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentDetail
   */
  source_id: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentDetail
   */
  target_id: string;
}

export function AssessmentDetailFromJSON(json: any): AssessmentDetail {
  return AssessmentDetailFromJSONTyped(json, false);
}

export function AssessmentDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssessmentDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    title: !exists(json, "title") ? undefined : json["title"],
    description: !exists(json, "description") ? undefined : json["description"],
    source_id: json["source_id"],
    target_id: json["target_id"],
  };
}

export function AssessmentDetailToJSON(value?: AssessmentDetail | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    source_id: value.source_id,
    target_id: value.target_id,
  };
}
