import React, { useMemo, useState } from "react";
import FormScreen from "../../../ui/screens/FormScreen";
import { useBusyWatcher } from "../../../../util/hooks";
import BioSuggestion from "./BioSuggestion";

type BioReviewSuggestionScreenProps = {
  onBackClicked: () => void;
  onSkipClicked: () => void;
  onNextClicked: (content: string) => void;
  title: string;
  context: string;
  existingComment: string;
  existing?: string | null;
  suggestedComment: string;
  suggested: string;
  errors: string[];
};

const BioReviewSuggestionScreenComponent = (
  props: BioReviewSuggestionScreenProps
) => {
  const {
    onBackClicked,
    onNextClicked,
    onSkipClicked,
    title,
    context,
    existingComment,
    existing: existingInput,
    suggestedComment,
    suggested: suggestedInput,
    errors,
  } = props;

  const [suggested, setSuggested] = useState<string>(suggestedInput);
  const [busy, _] = useBusyWatcher();

  const canSubmit = (): boolean => {
    if (suggested.length === 0) {
      return false;
    }
    return true;
  };

  const existing = useMemo(() => existingInput, []);

  return (
    <FormScreen
      title={title}
      comment={context}
      onNextClicked={() => onNextClicked(suggested)}
      nextDisabled={busy || !canSubmit()}
      onSkipClicked={onSkipClicked}
      skipDisabled={busy}
      onBackClicked={onBackClicked}
      backDisabled={busy}
    >
      <BioSuggestion
        existing={existing}
        existingComment={existingComment}
        suggestedComment={suggestedComment}
        suggested={suggested}
        onContentUpdated={setSuggested}
        errors={errors}
      />
    </FormScreen>
  );
};

BioReviewSuggestionScreenComponent.defaultProps = {
  existing: null,
};

export default BioReviewSuggestionScreenComponent;
