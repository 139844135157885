/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Choices,
  ChoicesFromJSON,
  ChoicesFromJSONTyped,
  ChoicesToJSON,
} from "./Choices";
import {
  EitherOr,
  EitherOrFromJSON,
  EitherOrFromJSONTyped,
  EitherOrToJSON,
} from "./EitherOr";
import {
  FillInBlank,
  FillInBlankFromJSON,
  FillInBlankFromJSONTyped,
  FillInBlankToJSON,
} from "./FillInBlank";
import {
  MultiSelect,
  MultiSelectFromJSON,
  MultiSelectFromJSONTyped,
  MultiSelectToJSON,
} from "./MultiSelect";

/**
 *
 * @export
 * @interface PromptContent
 */
export interface PromptContent {
  /**
   *
   * @type {FillInBlank}
   * @memberof PromptContent
   */
  fill_in_blank?: FillInBlank | null;
  /**
   *
   * @type {Choices}
   * @memberof PromptContent
   */
  choices?: Choices | null;
  /**
   *
   * @type {EitherOr}
   * @memberof PromptContent
   */
  either_or?: EitherOr | null;
  /**
   *
   * @type {MultiSelect}
   * @memberof PromptContent
   */
  multi_select?: MultiSelect | null;
}

export function PromptContentFromJSON(json: any): PromptContent {
  return PromptContentFromJSONTyped(json, false);
}

export function PromptContentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PromptContent {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    fill_in_blank: !exists(json, "fill_in_blank")
      ? undefined
      : FillInBlankFromJSON(json["fill_in_blank"]),
    choices: !exists(json, "choices")
      ? undefined
      : ChoicesFromJSON(json["choices"]),
    either_or: !exists(json, "either_or")
      ? undefined
      : EitherOrFromJSON(json["either_or"]),
    multi_select: !exists(json, "multi_select")
      ? undefined
      : MultiSelectFromJSON(json["multi_select"]),
  };
}

export function PromptContentToJSON(value?: PromptContent | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    fill_in_blank: FillInBlankToJSON(value.fill_in_blank),
    choices: ChoicesToJSON(value.choices),
    either_or: EitherOrToJSON(value.either_or),
    multi_select: MultiSelectToJSON(value.multi_select),
  };
}
