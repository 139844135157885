/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PromptDetail
 */
export interface PromptDetail {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof PromptDetail
   */
  readonly guid?: string;
  /**
   * The question content
   * @type {string}
   * @memberof PromptDetail
   */
  question: string;
  /**
   * The contents of the question but rephrased as a statement
   * @type {string}
   * @memberof PromptDetail
   */
  statement: string;
  /**
   * The type of question
   * @type {string}
   * @memberof PromptDetail
   */
  question_type: string;
  /**
   * Metadata associated with the question
   * @type {object}
   * @memberof PromptDetail
   */
  meta?: object | null;
  /**
   *
   * @type {Array<string>}
   * @memberof PromptDetail
   */
  tags: Array<string>;
  /**
   * The total number of responses to this prompt
   * @type {number}
   * @memberof PromptDetail
   */
  num_responses?: number;
  /**
   * The total number of votes on this prompt
   * @type {number}
   * @memberof PromptDetail
   */
  num_votes?: number;
  /**
   *
   * @type {string}
   * @memberof PromptDetail
   */
  readonly view_url?: string;
}

export function PromptDetailFromJSON(json: any): PromptDetail {
  return PromptDetailFromJSONTyped(json, false);
}

export function PromptDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PromptDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    question: json["question"],
    statement: json["statement"],
    question_type: json["question_type"],
    meta: !exists(json, "meta") ? undefined : json["meta"],
    tags: json["tags"],
    num_responses: !exists(json, "num_responses")
      ? undefined
      : json["num_responses"],
    num_votes: !exists(json, "num_votes") ? undefined : json["num_votes"],
    view_url: !exists(json, "view_url") ? undefined : json["view_url"],
  };
}

export function PromptDetailToJSON(value?: PromptDetail | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    question: value.question,
    statement: value.statement,
    question_type: value.question_type,
    meta: value.meta,
    tags: value.tags,
    num_responses: value.num_responses,
    num_votes: value.num_votes,
  };
}
