import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../util/strings";
import MErrors from "./MErrors";
import MLabel from "./MLabel";

export type MSelectOption = {
  name: string;
  key?: string;
  unavailable?: boolean;
};

type MSelectProps = {
  label?: string | null;
  options: MSelectOption[];
  errors?: string[];
  disabled?: boolean;
  selectedName?: string | null;
  onSelected: (option: MSelectOption | null) => void;
  required?: boolean;
  placeholder?: string | null;
  className?: string | null;
};

const MSelectComponent = (props: MSelectProps) => {
  const {
    label,
    options: optionsInput,
    disabled,
    onSelected: onSelectedInput,
    errors,
    selectedName,
    required,
    placeholder,
    className,
  } = props;

  const notSelectedOption: MSelectOption = {
    name: placeholder ?? "Select an option",
    key: "not_selected",
  };

  const [selectedOption, setSelectedOption] =
    useState<MSelectOption>(notSelectedOption);
  const options = useMemo(() => optionsInput, []);

  const onSelected = (option: MSelectOption) => {
    if (option.name === selectedOption.name) {
      setSelectedOption(notSelectedOption);
      onSelectedInput(null);
      return;
    }
    setSelectedOption(option);
    onSelectedInput(option);
  };

  useEffect(() => {
    if (selectedName !== undefined && selectedName !== null) {
      for (let i = 0; i < options.length; i += 1) {
        if (options[i].name === selectedName) {
          setSelectedOption(options[i]);
          break;
        }
      }
    }
  }, []);

  return (
    <div className={className ?? ""}>
      <Listbox disabled={disabled} value={selectedOption} onChange={onSelected}>
        {({ open }) => (
          <>
            {label && (
              <Listbox.Label>
                <MLabel label={label} required={required} />
              </Listbox.Label>
            )}
            <div className="relative">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-m-white py-1.5 pl-3 pr-10 text-left text-m-dark-gray shadow-sm ring-1 ring-inset ring-m-gray focus:outline-none focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6">
                <span className="block truncate">{selectedOption.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-m-gray"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-m-white py-1 text-base shadow-lg ring-1 ring-m-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.key ?? option.name}
                      className={({ active }) =>
                        classNames(
                          active
                            ? "bg-primary text-m-white"
                            : "bg-m-white text-m-dark-gray",
                          "relative cursor-default select-none py-2 pl-8 pr-4"
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {option.name}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-m-white" : "text-primary",
                                "absolute inset-y-0 left-0 flex items-center pl-1.5"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      <MErrors errors={errors} />
    </div>
  );
};

MSelectComponent.defaultProps = {
  label: null,
  errors: [],
  disabled: false,
  selectedName: null,
  required: false,
  placeholder: null,
  className: null,
};

export default MSelectComponent;
