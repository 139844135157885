/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  AddProfilePhotoRequest,
  AddProfilePhotoRequestFromJSON,
  AddProfilePhotoRequestToJSON,
  AddResumeRequest,
  AddResumeRequestFromJSON,
  AddResumeRequestToJSON,
  AttachmentsSerializer,
  AttachmentsSerializerFromJSON,
  AttachmentsSerializerToJSON,
  EmptyResponseWrapper,
  EmptyResponseWrapperFromJSON,
  EmptyResponseWrapperToJSON,
  GetBasicInfoSerializer,
  GetBasicInfoSerializerFromJSON,
  GetBasicInfoSerializerToJSON,
  GetProfilePhotoResponseSerializer,
  GetProfilePhotoResponseSerializerFromJSON,
  GetProfilePhotoResponseSerializerToJSON,
  GetProfilePublicResponseSerializer,
  GetProfilePublicResponseSerializerFromJSON,
  GetProfilePublicResponseSerializerToJSON,
  GetProfileResponseSerializer,
  GetProfileResponseSerializerFromJSON,
  GetProfileResponseSerializerToJSON,
  GetResumeResponseSerializer,
  GetResumeResponseSerializerFromJSON,
  GetResumeResponseSerializerToJSON,
  GetTaglineResponseSerializer,
  GetTaglineResponseSerializerFromJSON,
  GetTaglineResponseSerializerToJSON,
  GetUserVitalsResponseSerializer,
  GetUserVitalsResponseSerializerFromJSON,
  GetUserVitalsResponseSerializerToJSON,
  GetUsernameResponseSerializer,
  GetUsernameResponseSerializerFromJSON,
  GetUsernameResponseSerializerToJSON,
  ProfilePresentationLayerRelatedDetailSerializer,
  ProfilePresentationLayerRelatedDetailSerializerFromJSON,
  ProfilePresentationLayerRelatedDetailSerializerToJSON,
  SetBasicInfo,
  SetBasicInfoFromJSON,
  SetBasicInfoToJSON,
  SetBioRequest,
  SetBioRequestFromJSON,
  SetBioRequestToJSON,
  SetHeadlineRequest,
  SetHeadlineRequestFromJSON,
  SetHeadlineRequestToJSON,
  SetProfilePublicRequest,
  SetProfilePublicRequestFromJSON,
  SetProfilePublicRequestToJSON,
  SetPromptResponsePresentationVisibilityRequest,
  SetPromptResponsePresentationVisibilityRequestFromJSON,
  SetPromptResponsePresentationVisibilityRequestToJSON,
  SetPromptResponseVisibilityRequest,
  SetPromptResponseVisibilityRequestFromJSON,
  SetPromptResponseVisibilityRequestToJSON,
  SetUserVitalsRequest,
  SetUserVitalsRequestFromJSON,
  SetUserVitalsRequestToJSON,
  UpdatePromptResponseOrderRequest,
  UpdatePromptResponseOrderRequestFromJSON,
  UpdatePromptResponseOrderRequestToJSON,
  UserProfileSummarySerializer,
  UserProfileSummarySerializerFromJSON,
  UserProfileSummarySerializerToJSON,
} from "../models";

export interface AddResumeOperationRequest {
  data: AddResumeRequest;
}

export interface ClearProfilePhotoRequest {
  data: object;
}

export interface DeleteResumeRequest {
  data: object;
}

export interface GetProfileRequest {
  username?: string | null;
  user_id?: string | null;
}

export interface GetProfilePresentationLayerRequest {
  layer?: string;
}

export interface GetProfileSummaryRequest {
  username?: string | null;
  user_id?: string | null;
}

export interface SetProfileBasicInfoRequest {
  data: SetBasicInfo;
}

export interface SetProfileBioRequest {
  data: SetBioRequest;
}

export interface SetProfileHeadlineRequest {
  data: SetHeadlineRequest;
}

export interface SetProfilePhotoRequest {
  data: AddProfilePhotoRequest;
}

export interface SetProfilePublicOperationRequest {
  data: SetProfilePublicRequest;
}

export interface SetPromptResponsePresentationVisibilityOperationRequest {
  data: SetPromptResponsePresentationVisibilityRequest;
}

export interface SetPromptResponseVisibilityOperationRequest {
  data: SetPromptResponseVisibilityRequest;
}

export interface SetUserVitalsOperationRequest {
  data: SetUserVitalsRequest;
}

export interface UpdatePromptResponseOrderOperationRequest {
  data: UpdatePromptResponseOrderRequest;
}

/**
 *
 */
export class ProfileApi extends runtime.BaseAPI {
  /**
   * Set the resume for the requesting user.
   */
  async addResumeRaw(
    requestParameters: AddResumeOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling addResume."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/profile/add-resume`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: AddResumeRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Set the resume for the requesting user.
   */
  async addResume(
    requestParameters: AddResumeOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.addResumeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Clear the requesting user\'s profile photo if it exists.
   */
  async clearProfilePhotoRaw(
    requestParameters: ClearProfilePhotoRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling clearProfilePhoto."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/profile/clear-profile-photo`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.data as any,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Clear the requesting user\'s profile photo if it exists.
   */
  async clearProfilePhoto(
    requestParameters: ClearProfilePhotoRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.clearProfilePhotoRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Delete the requesting user\'s resume if it exists.
   */
  async deleteResumeRaw(
    requestParameters: DeleteResumeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling deleteResume."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/profile/delete-resume`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.data as any,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Delete the requesting user\'s resume if it exists.
   */
  async deleteResume(
    requestParameters: DeleteResumeRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.deleteResumeRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get the attachments for the requesting user.
   */
  async getAttachmentsRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<AttachmentsSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/profile/get-attachments`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AttachmentsSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the attachments for the requesting user.
   */
  async getAttachments(
    initOverrides?: RequestInit
  ): Promise<AttachmentsSerializer> {
    const response = await this.getAttachmentsRaw(initOverrides);
    return await response.value();
  }

  /**
   * Get the profile contents for the specified user.
   */
  async getProfileRaw(
    requestParameters: GetProfileRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetProfileResponseSerializer>> {
    const queryParameters: any = {};

    if (requestParameters.username !== undefined) {
      queryParameters["username"] = requestParameters.username;
    }

    if (requestParameters.user_id !== undefined) {
      queryParameters["user_id"] = requestParameters.user_id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/profile/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetProfileResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the profile contents for the specified user.
   */
  async getProfile(
    requestParameters: GetProfileRequest = {},
    initOverrides?: RequestInit
  ): Promise<GetProfileResponseSerializer> {
    const response = await this.getProfileRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get the basics information for the requesting user.
   */
  async getProfileBasicInfoRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetBasicInfoSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/profile/get-basic-info`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetBasicInfoSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the basics information for the requesting user.
   */
  async getProfileBasicInfo(
    initOverrides?: RequestInit
  ): Promise<GetBasicInfoSerializer> {
    const response = await this.getProfileBasicInfoRaw(initOverrides);
    return await response.value();
  }

  /**
   * Get the profile picture for the requesting user.
   */
  async getProfilePhotoRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetProfilePhotoResponseSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/profile/get-profile-photo`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetProfilePhotoResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the profile picture for the requesting user.
   */
  async getProfilePhoto(
    initOverrides?: RequestInit
  ): Promise<GetProfilePhotoResponseSerializer> {
    const response = await this.getProfilePhotoRaw(initOverrides);
    return await response.value();
  }

  /**
   * Get the specified profile presentation layer. If a layer is not specified, then return the default presentation layer for the requesting user.
   */
  async getProfilePresentationLayerRaw(
    requestParameters: GetProfilePresentationLayerRequest,
    initOverrides?: RequestInit
  ): Promise<
    runtime.ApiResponse<ProfilePresentationLayerRelatedDetailSerializer>
  > {
    const queryParameters: any = {};

    if (requestParameters.layer !== undefined) {
      queryParameters["layer"] = requestParameters.layer;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/profile/get-presentation-layer`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProfilePresentationLayerRelatedDetailSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the specified profile presentation layer. If a layer is not specified, then return the default presentation layer for the requesting user.
   */
  async getProfilePresentationLayer(
    requestParameters: GetProfilePresentationLayerRequest = {},
    initOverrides?: RequestInit
  ): Promise<ProfilePresentationLayerRelatedDetailSerializer> {
    const response = await this.getProfilePresentationLayerRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get the public profile status for the requesting user.
   */
  async getProfilePublicRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetProfilePublicResponseSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/profile/get-public`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetProfilePublicResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the public profile status for the requesting user.
   */
  async getProfilePublic(
    initOverrides?: RequestInit
  ): Promise<GetProfilePublicResponseSerializer> {
    const response = await this.getProfilePublicRaw(initOverrides);
    return await response.value();
  }

  /**
   * Get a small summary of the referenced user\'s profile.
   */
  async getProfileSummaryRaw(
    requestParameters: GetProfileSummaryRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<UserProfileSummarySerializer>> {
    const queryParameters: any = {};

    if (requestParameters.username !== undefined) {
      queryParameters["username"] = requestParameters.username;
    }

    if (requestParameters.user_id !== undefined) {
      queryParameters["user_id"] = requestParameters.user_id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/profile/get-summary`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserProfileSummarySerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get a small summary of the referenced user\'s profile.
   */
  async getProfileSummary(
    requestParameters: GetProfileSummaryRequest = {},
    initOverrides?: RequestInit
  ): Promise<UserProfileSummarySerializer> {
    const response = await this.getProfileSummaryRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get the bio and headline for the current user.
   */
  async getProfileTaglineRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetTaglineResponseSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/profile/get-tagline`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetTaglineResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the bio and headline for the current user.
   */
  async getProfileTagline(
    initOverrides?: RequestInit
  ): Promise<GetTaglineResponseSerializer> {
    const response = await this.getProfileTaglineRaw(initOverrides);
    return await response.value();
  }

  /**
   * Get resume for the requesting user.
   */
  async getResumeRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetResumeResponseSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/profile/get-resume`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetResumeResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get resume for the requesting user.
   */
  async getResume(
    initOverrides?: RequestInit
  ): Promise<GetResumeResponseSerializer> {
    const response = await this.getResumeRaw(initOverrides);
    return await response.value();
  }

  /**
   * Get the \'vitals\' details from a user\'s profile.
   */
  async getUserVitalsRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetUserVitalsResponseSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/profile/get-vitals`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetUserVitalsResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the \'vitals\' details from a user\'s profile.
   */
  async getUserVitals(
    initOverrides?: RequestInit
  ): Promise<GetUserVitalsResponseSerializer> {
    const response = await this.getUserVitalsRaw(initOverrides);
    return await response.value();
  }

  /**
   * Get the username for the requesting user.
   */
  async getUsernameRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetUsernameResponseSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/profile/get-username`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetUsernameResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the username for the requesting user.
   */
  async getUsername(
    initOverrides?: RequestInit
  ): Promise<GetUsernameResponseSerializer> {
    const response = await this.getUsernameRaw(initOverrides);
    return await response.value();
  }

  /**
   * Set basic information for the requesting user.
   */
  async setProfileBasicInfoRaw(
    requestParameters: SetProfileBasicInfoRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling setProfileBasicInfo."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/profile/set-basic-info`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SetBasicInfoToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Set basic information for the requesting user.
   */
  async setProfileBasicInfo(
    requestParameters: SetProfileBasicInfoRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.setProfileBasicInfoRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Set the bio for the requesting user.
   */
  async setProfileBioRaw(
    requestParameters: SetProfileBioRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling setProfileBio."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/profile/set-bio`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SetBioRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Set the bio for the requesting user.
   */
  async setProfileBio(
    requestParameters: SetProfileBioRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.setProfileBioRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Set the headline for the requesting user.
   */
  async setProfileHeadlineRaw(
    requestParameters: SetProfileHeadlineRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling setProfileHeadline."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/profile/set-headline`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SetHeadlineRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Set the headline for the requesting user.
   */
  async setProfileHeadline(
    requestParameters: SetProfileHeadlineRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.setProfileHeadlineRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Set the profile photo for the requesting user.
   */
  async setProfilePhotoRaw(
    requestParameters: SetProfilePhotoRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling setProfilePhoto."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/profile/set-profile-photo`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: AddProfilePhotoRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Set the profile photo for the requesting user.
   */
  async setProfilePhoto(
    requestParameters: SetProfilePhotoRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.setProfilePhotoRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Set the profile public status for the requesting user.
   */
  async setProfilePublicRaw(
    requestParameters: SetProfilePublicOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling setProfilePublic."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/profile/set-public`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SetProfilePublicRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Set the profile public status for the requesting user.
   */
  async setProfilePublic(
    requestParameters: SetProfilePublicOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.setProfilePublicRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Set the visibility of the referenced PromptResponsePresentation.
   */
  async setPromptResponsePresentationVisibilityRaw(
    requestParameters: SetPromptResponsePresentationVisibilityOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling setPromptResponsePresentationVisibility."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/profile/set-prompt-response-pres-visibility`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SetPromptResponsePresentationVisibilityRequestToJSON(
          requestParameters.data
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Set the visibility of the referenced PromptResponsePresentation.
   */
  async setPromptResponsePresentationVisibility(
    requestParameters: SetPromptResponsePresentationVisibilityOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.setPromptResponsePresentationVisibilityRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Set the visibility of the referenced PromptResponse.
   */
  async setPromptResponseVisibilityRaw(
    requestParameters: SetPromptResponseVisibilityOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling setPromptResponseVisibility."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/profile/set-prompt-response-visibility`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SetPromptResponseVisibilityRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Set the visibility of the referenced PromptResponse.
   */
  async setPromptResponseVisibility(
    requestParameters: SetPromptResponseVisibilityOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.setPromptResponseVisibilityRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Set the vitals for the requesting user\'s profile.
   */
  async setUserVitalsRaw(
    requestParameters: SetUserVitalsOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling setUserVitals."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/profile/set-vitals`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SetUserVitalsRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Set the vitals for the requesting user\'s profile.
   */
  async setUserVitals(
    requestParameters: SetUserVitalsOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.setUserVitalsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update the order of the referenced PromptResponsePresentation.
   */
  async updatePromptResponseOrderRaw(
    requestParameters: UpdatePromptResponseOrderOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling updatePromptResponseOrder."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/profile/update-prompt-response-order`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UpdatePromptResponseOrderRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Update the order of the referenced PromptResponsePresentation.
   */
  async updatePromptResponseOrder(
    requestParameters: UpdatePromptResponseOrderOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.updatePromptResponseOrderRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
