/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface EmailAuthRequest
 */
export interface EmailAuthRequest {
  /**
   *
   * @type {string}
   * @memberof EmailAuthRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof EmailAuthRequest
   */
  redirect?: string | null;
}

export function EmailAuthRequestFromJSON(json: any): EmailAuthRequest {
  return EmailAuthRequestFromJSONTyped(json, false);
}

export function EmailAuthRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EmailAuthRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json["email"],
    redirect: !exists(json, "redirect") ? undefined : json["redirect"],
  };
}

export function EmailAuthRequestToJSON(value?: EmailAuthRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    redirect: value.redirect,
  };
}
