import React from "react";
import MessageScreen from "../../ui/screens/MessageScreen";
import { useBusyWatcher } from "../../../util/hooks";

type IntroScreenProps = {
  onCreateClicked: () => void;
};

const IntroScreenComponent = (props: IntroScreenProps) => {
  const { onCreateClicked } = props;

  const [busy, _] = useBusyWatcher();

  return (
    <MessageScreen
      content="Manual is a how-to guide for working with you. setting it up takes less
        than five minutes."
      nextButtonText="create your Manual"
      onNextClicked={onCreateClicked}
      nextDisabled={busy}
    />
  );
};

export default IntroScreenComponent;
