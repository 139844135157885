/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  SubscriptionTier,
  SubscriptionTierFromJSON,
  SubscriptionTierFromJSONTyped,
  SubscriptionTierToJSON,
} from "./SubscriptionTier";

/**
 *
 * @export
 * @interface GetSubscriptionResponse
 */
export interface GetSubscriptionResponse {
  /**
   *
   * @type {SubscriptionTier}
   * @memberof GetSubscriptionResponse
   */
  subscription: SubscriptionTier | null;
  /**
   *
   * @type {string}
   * @memberof GetSubscriptionResponse
   */
  subscribe_link: string;
  /**
   *
   * @type {string}
   * @memberof GetSubscriptionResponse
   */
  manage_link: string;
}

export function GetSubscriptionResponseFromJSON(
  json: any
): GetSubscriptionResponse {
  return GetSubscriptionResponseFromJSONTyped(json, false);
}

export function GetSubscriptionResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetSubscriptionResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    subscription: SubscriptionTierFromJSON(json["subscription"]),
    subscribe_link: json["subscribe_link"],
    manage_link: json["manage_link"],
  };
}

export function GetSubscriptionResponseToJSON(
  value?: GetSubscriptionResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    subscription: SubscriptionTierToJSON(value.subscription),
    subscribe_link: value.subscribe_link,
    manage_link: value.manage_link,
  };
}
