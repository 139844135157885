import React from "react";

type MNumberedListProps = {
  body: string[];
};

const MNumberedListComponent = (props: MNumberedListProps) => {
  const { body } = props;

  return (
    <div className="flex flex-col gap-6">
      {body.map((item, i) => (
        <div key={item} className="flex flex-row gap-3">
          <div className="font-bold">{i + 1}.</div>
          <div>{item}</div>
        </div>
      ))}
    </div>
  );
};

export default MNumberedListComponent;
