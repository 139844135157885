import { toast } from "react-toastify";
import { Middleware, ResponseContext } from "./runtime";
import { GenericResponseBody } from "./types";

export default class ToastOnErrorMiddleware implements Middleware {
  // eslint-disable-next-line class-methods-use-this
  post(context: ResponseContext): Promise<Response | void> {
    return context.response.json().then((json) => {
      const parsed = json as GenericResponseBody;
      if (!parsed.s) {
        toast.error(parsed.m);
      }
      // THIS IS A TERRIBLE HACK TO LET JSON BE READ TWICE!
      context.response.json = () =>
        new Promise((resolve) => {
          resolve(json);
        });
      return context.response;
    });
  }
}
