/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GetGlimpseProcessingReadyStatusResponse
 */
export interface GetGlimpseProcessingReadyStatusResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof GetGlimpseProcessingReadyStatusResponse
   */
  issues: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof GetGlimpseProcessingReadyStatusResponse
   */
  is_ready: boolean;
}

export function GetGlimpseProcessingReadyStatusResponseFromJSON(
  json: any
): GetGlimpseProcessingReadyStatusResponse {
  return GetGlimpseProcessingReadyStatusResponseFromJSONTyped(json, false);
}

export function GetGlimpseProcessingReadyStatusResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetGlimpseProcessingReadyStatusResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    issues: json["issues"],
    is_ready: json["is_ready"],
  };
}

export function GetGlimpseProcessingReadyStatusResponseToJSON(
  value?: GetGlimpseProcessingReadyStatusResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    issues: value.issues,
    is_ready: value.is_ready,
  };
}
