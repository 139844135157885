import React, { useEffect, useState } from "react";
import FillInBlankQuestion from "../../../ui/forms/questions/FillInBlankQuestion";
import MInput from "../../../ui/MInput";
import { useBusyWatcher } from "../../../../util/hooks";
import MTextArea from "../../../ui/MTextArea";
import {
  FillInBlank,
  FillInBlankTagsEnum,
  PromptRelatedDetail,
} from "../../../../api/models";
import MLabel from "../../../ui/MLabel";
import TagSearchAndSelect from "../../../ui/TagSearchAndSelect";
import {
  CommonEditQuestionProps,
  maxPlaceholderLength,
  maxQuestionLength,
  maxResponseLength,
  maxStatementLength,
  minResponseLength,
} from "./common";
import CommonEditQuestionScreen from "./CommonEditQuestionScreen";
import { FillInBlankPromptMeta } from "../../../../api/types";

type EditFillInBlankQuestionScreenProps = CommonEditQuestionProps & {
  onNextClicked: (result: FillInBlank) => void;
};

const EditFillInBlankQuestionScreenComponent = (
  props: EditFillInBlankQuestionScreenProps
) => {
  const {
    onBackClicked,
    onNextClicked: onNextClickedInput,
    errors,
    errorMap,
    onTagSearchTermUpdated,
    tagsSearching,
    tags,
    prompt,
    isCreate,
  } = props;

  const [question, setQuestion] = useState<string>("");
  const [statement, setStatement] = useState<string>("");
  const [placeholder, setPlaceholder] = useState<string>("");
  const [selected, setSelected] = useState<string[]>([]);
  const [content, setContent] = useState<string>("");
  const [maxContentLength, setMaxContentLength] = useState<number>(500);
  const [busy, _] = useBusyWatcher();

  const getTitle = (): string => {
    const verb = isCreate ? "Create" : "Edit";
    return `${verb} 'Fill in the Blank' Question`;
  };

  const populateFromPrompt = (fromPrompt: PromptRelatedDetail) => {
    setQuestion(fromPrompt.question);
    setStatement(fromPrompt.statement);
    setSelected(fromPrompt.tags);
    if (fromPrompt.meta) {
      const promptMeta = fromPrompt.meta as FillInBlankPromptMeta;
      if (promptMeta.examples && promptMeta.examples.length > 0) {
        setPlaceholder(promptMeta.examples[0]);
      } else {
        setPlaceholder("");
      }
      if (promptMeta.max_chars) {
        setMaxContentLength(promptMeta.max_chars);
      }
    }
  };

  const onNextClicked = () => {
    onNextClickedInput({
      question,
      statement,
      placeholder,
      tags: selected as FillInBlankTagsEnum[],
      max_chars: maxContentLength,
    });
  };

  const canSubmit = (): boolean => {
    if (question.length === 0) {
      return false;
    }
    if (question.length > maxQuestionLength) {
      return false;
    }
    if (statement.length === 0) {
      return false;
    }
    if (statement.length > maxStatementLength) {
      return false;
    }
    if (placeholder.length > maxPlaceholderLength) {
      return false;
    }
    if (maxContentLength < minResponseLength) {
      return false;
    }
    if (maxContentLength > maxResponseLength) {
      return false;
    }
    return true;
  };

  const onContentLengthUpdated = (newContent: string) => {
    if (newContent === "") {
      setMaxContentLength(1);
      return;
    }
    const parsed = parseInt(newContent, 10);
    if (Number.isNaN(parsed)) {
      return;
    }
    setMaxContentLength(parsed);
  };

  const getContentLengthErrors = (): string[] => {
    const contentLengthErrors = [];
    if (errorMap?.max_chars) {
      contentLengthErrors.push(...errorMap.max_chars);
    }
    if (maxContentLength < minResponseLength) {
      contentLengthErrors.push(`must be at least ${minResponseLength}`);
    }
    if (maxContentLength > maxResponseLength) {
      contentLengthErrors.push(`must be at most ${maxResponseLength}`);
    }
    return contentLengthErrors;
  };

  useEffect(() => {
    if (prompt) {
      populateFromPrompt(prompt);
    }
  }, [prompt]);

  return (
    <CommonEditQuestionScreen
      title={getTitle()}
      previewQuestion={
        <FillInBlankQuestion
          content={content}
          maxContentLength={maxContentLength}
          placeholder={placeholder}
          question={question || "Your question text will show up here"}
          onUpdated={setContent}
        />
      }
      previewResponse={{
        prompt: {
          question,
          statement:
            statement ||
            "your statement shows up here and is a rephrasing of your question in statement format",
          question_type: "fill_in_blank",
          tags: [],
        },
        response: {
          meta: {
            response:
              content ||
              "this is an example response to your fill in the blank question. you can type in the box above to see other text will look.",
          },
        },
      }}
      errors={errors}
      onNextClicked={onNextClicked}
      nextDisabled={busy || !canSubmit()}
      onBackClicked={onBackClicked}
      backDisabled={busy}
    >
      <MTextArea
        label="question"
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        characterLimit={maxQuestionLength}
        placeholder="what is your favorite color?"
        errors={errorMap?.question}
        required
      />
      <MTextArea
        label="statement"
        value={statement}
        onChange={(e) => setStatement(e.target.value)}
        characterLimit={maxStatementLength}
        placeholder="my favorite color is..."
        errors={errorMap?.statement}
        required
      />
      <MTextArea
        label="placeholder"
        value={placeholder}
        onChange={(e) => setPlaceholder(e.target.value)}
        characterLimit={maxPlaceholderLength}
        placeholder="e.g. red, blue, green, etc."
        errors={errorMap?.placeholder}
      />
      <div>
        <MLabel label="tags" />
        <TagSearchAndSelect
          selected={selected}
          onSearchTermUpdated={onTagSearchTermUpdated}
          onSelectedTagsUpdated={setSelected}
          tags={tags?.tags ?? []}
          loading={tagsSearching}
          disabled={busy}
        />
      </div>
      <MInput
        label="max content length"
        type="number"
        value={maxContentLength}
        onChange={(e) => onContentLengthUpdated(e.target.value)}
        errors={getContentLengthErrors()}
        onEnterPressed={() => canSubmit() && onNextClicked()}
        required
      />
    </CommonEditQuestionScreen>
  );
};

export default EditFillInBlankQuestionScreenComponent;
