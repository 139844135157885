import React, { ReactNode } from "react";
import { classNames } from "../../util/strings";

type MPaginationNumbersProps = {
  curPage: number;
  maxPage: number;
  onPageClicked: (page: number) => void;
  disabled?: boolean;
};

const MPaginationNumbersComponent = (props: MPaginationNumbersProps) => {
  const { curPage, maxPage, onPageClicked, disabled } = props;

  const ellipsisThreshold = 6;
  const endLength = 3;
  const isAtStart = curPage <= endLength;
  const isAtEnd = curPage > maxPage - endLength;

  const getStartingPage = (): number => {
    // If the current page is at the beginning or end, then we want
    // to show endLength at each end of the range

    if (isAtStart || isAtEnd) {
      return 1;
    }

    // We only want to show an ellipsis if there are at least N pages
    // in which case the first page is always shown

    if (maxPage <= ellipsisThreshold) {
      return 1;
    }

    // Otherwise we start one before the current page so users can
    // go back

    return curPage - 1;
  };

  const startingPage = getStartingPage();
  const totalPages = maxPage - startingPage + 1;

  const getButtonForPage = (page: number): ReactNode => {
    const isCurrent = page === curPage;
    return (
      <button
        className={classNames(
          "inline-flex items-center px-3 py-2 m-1 text-sm font-medium rounded-lg",
          isCurrent ? "text-primary bg-primary-light" : "text-m-gray",
          disabled
            ? "cursor-not-allowed"
            : "cursor-pointer hover:text-primary hover:bg-primary-light"
        )}
        type="button"
        onClick={() => {
          if (page !== curPage) {
            onPageClicked(page);
          }
        }}
        disabled={disabled}
        key={page}
      >
        {page}
      </button>
    );
  };

  const getButtonsForAllPages = (): ReactNode => {
    const toReturn = [];
    for (let i = startingPage; i <= maxPage; i += 1) {
      toReturn.push(getButtonForPage(i));
    }
    return toReturn;
  };

  const getButtonsForFirstPages = (): ReactNode => {
    const toReturn = [];
    if (isAtStart && curPage > startingPage + 1) {
      for (let i = curPage - 1; i <= curPage + 1; i += 1) {
        toReturn.push(getButtonForPage(i));
      }
    } else {
      for (let i = startingPage; i <= startingPage + 2; i += 1) {
        toReturn.push(getButtonForPage(i));
      }
    }
    return toReturn;
  };

  const getButtonsForLastPages = (): ReactNode => {
    const toReturn = [];
    if (isAtEnd && curPage < maxPage - 1) {
      for (let i = curPage - 1; i <= curPage + 1; i += 1) {
        toReturn.push(getButtonForPage(i));
      }
    } else {
      for (let i = maxPage - 2; i <= maxPage; i += 1) {
        toReturn.push(getButtonForPage(i));
      }
    }
    return toReturn;
  };

  if (totalPages <= ellipsisThreshold) {
    return <div className="md:-mt-px md:flex">{getButtonsForAllPages()}</div>;
  }

  return (
    <div className="md:-mt-px md:flex">
      {getButtonsForFirstPages()}
      <span
        className={classNames(
          "inline-flex items-center border-transparent px-2 text-sm font-medium text-m-dark-gray"
        )}
      >
        ...
      </span>
      {getButtonsForLastPages()}
    </div>
  );
};

MPaginationNumbersComponent.defaultProps = {
  disabled: false,
};

export default MPaginationNumbersComponent;
