import React from "react";
import { classNames } from "../../util/strings";
import { titleTextSize } from "../../util/style";
import MCornerEditButton from "./buttons/MCornerEditButton";

type MAvatarSize = "regular" | "large" | "small" | "mini";

type MAvatarProps = {
  picUrl?: string | null;
  alt?: string | null;
  editUrl?: string | null;
  size?: MAvatarSize;
  className?: string | null;
};

const MAvatarComponent = (props: MAvatarProps) => {
  const { picUrl, alt, editUrl, size, className } = props;

  const dimensionsFromSize = (): string => {
    switch (size) {
      case "large":
        return "h-64 w-64";
      case "small":
        return "h-12 w-12";
      case "mini":
        return "h-9 w-9";
      case "regular":
      default:
        return "h-24 w-24";
    }
  };

  return (
    <div
      className={classNames(
        "relative inline-block rounded-md aspect-square",
        dimensionsFromSize(),
        className
      )}
    >
      {picUrl && (
        <img
          className="inline-block rounded-md w-full h-full"
          src={picUrl}
          alt={alt ?? "unknown"}
        />
      )}
      {!picUrl && (
        <div
          className={classNames(
            "bg-secondary text-primary rounded-md flex flex-col items-center justify-center",
            titleTextSize,
            dimensionsFromSize()
          )}
        >
          ?
        </div>
      )}
      {editUrl && (
        <MCornerEditButton
          editUrl={editUrl}
          tooltip={picUrl ? "edit photo" : "add photo"}
        />
      )}
    </div>
  );
};

MAvatarComponent.defaultProps = {
  picUrl: null,
  editUrl: null,
  alt: null,
  size: "regular",
  className: null,
};

export default MAvatarComponent;
