/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  PromptResponsePresentationRelatedDetail,
  PromptResponsePresentationRelatedDetailFromJSON,
  PromptResponsePresentationRelatedDetailFromJSONTyped,
  PromptResponsePresentationRelatedDetailToJSON,
} from "./PromptResponsePresentationRelatedDetail";

/**
 *
 * @export
 * @interface ProfilePresentationLayerRelatedDetail
 */
export interface ProfilePresentationLayerRelatedDetail {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof ProfilePresentationLayerRelatedDetail
   */
  readonly guid?: string;
  /**
   * A short name used to describe the presentation layer.
   * @type {string}
   * @memberof ProfilePresentationLayerRelatedDetail
   */
  name: string;
  /**
   * Whether this presentation layer should be used by default.
   * @type {boolean}
   * @memberof ProfilePresentationLayerRelatedDetail
   */
  is_default?: boolean;
  /**
   *
   * @type {Array<PromptResponsePresentationRelatedDetail>}
   * @memberof ProfilePresentationLayerRelatedDetail
   */
  readonly pr_presentations?: Array<PromptResponsePresentationRelatedDetail>;
}

export function ProfilePresentationLayerRelatedDetailFromJSON(
  json: any
): ProfilePresentationLayerRelatedDetail {
  return ProfilePresentationLayerRelatedDetailFromJSONTyped(json, false);
}

export function ProfilePresentationLayerRelatedDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProfilePresentationLayerRelatedDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    name: json["name"],
    is_default: !exists(json, "is_default") ? undefined : json["is_default"],
    pr_presentations: !exists(json, "pr_presentations")
      ? undefined
      : (json["pr_presentations"] as Array<any>).map(
          PromptResponsePresentationRelatedDetailFromJSON
        ),
  };
}

export function ProfilePresentationLayerRelatedDetailToJSON(
  value?: ProfilePresentationLayerRelatedDetail | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    is_default: value.is_default,
  };
}
