/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GetNotificationCountsResponse
 */
export interface GetNotificationCountsResponse {
  /**
   *
   * @type {number}
   * @memberof GetNotificationCountsResponse
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof GetNotificationCountsResponse
   */
  unread: number;
}

export function GetNotificationCountsResponseFromJSON(
  json: any
): GetNotificationCountsResponse {
  return GetNotificationCountsResponseFromJSONTyped(json, false);
}

export function GetNotificationCountsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetNotificationCountsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total: json["total"],
    unread: json["unread"],
  };
}

export function GetNotificationCountsResponseToJSON(
  value?: GetNotificationCountsResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total: value.total,
    unread: value.unread,
  };
}
