/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SetUserVitalsRequest
 */
export interface SetUserVitalsRequest {
  /**
   *
   * @type {string}
   * @memberof SetUserVitalsRequest
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof SetUserVitalsRequest
   */
  location?: string | null;
  /**
   *
   * @type {string}
   * @memberof SetUserVitalsRequest
   */
  current_title?: string | null;
  /**
   *
   * @type {string}
   * @memberof SetUserVitalsRequest
   */
  current_employer?: string | null;
  /**
   *
   * @type {string}
   * @memberof SetUserVitalsRequest
   */
  pronouns?: string | null;
  /**
   *
   * @type {string}
   * @memberof SetUserVitalsRequest
   */
  relationship?: SetUserVitalsRequestRelationshipEnum;
  /**
   *
   * @type {number}
   * @memberof SetUserVitalsRequest
   */
  kids?: number | null;
  /**
   *
   * @type {string}
   * @memberof SetUserVitalsRequest
   */
  beliefs?: SetUserVitalsRequestBeliefsEnum;
  /**
   *
   * @type {string}
   * @memberof SetUserVitalsRequest
   */
  sexual_orientation?: SetUserVitalsRequestSexualOrientationEnum;
  /**
   *
   * @type {string}
   * @memberof SetUserVitalsRequest
   */
  ethnicity?: string | null;
  /**
   *
   * @type {string}
   * @memberof SetUserVitalsRequest
   */
  mbti?: string | null;
  /**
   *
   * @type {string}
   * @memberof SetUserVitalsRequest
   */
  love_language?: string | null;
  /**
   *
   * @type {string}
   * @memberof SetUserVitalsRequest
   */
  enneagram?: string | null;
  /**
   *
   * @type {string}
   * @memberof SetUserVitalsRequest
   */
  zodiac?: string | null;
  /**
   *
   * @type {string}
   * @memberof SetUserVitalsRequest
   */
  human_design?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum SetUserVitalsRequestRelationshipEnum {
  single = "single",
  dating = "dating",
  committed = "committed",
  committed_poly = "committed poly",
  open_relationship = "open relationship",
  married = "married",
}
/**
 * @export
 * @enum {string}
 */
export enum SetUserVitalsRequestBeliefsEnum {
  agnostic = "agnostic",
  atheist = "atheist",
  buddhist = "buddhist",
  catholic = "catholic",
  christian = "christian",
  hindu = "hindu",
  jewish = "jewish",
  not_religious = "not religious",
  muslim = "muslim",
  sikh = "sikh",
  spiritual = "spiritual",
}
/**
 * @export
 * @enum {string}
 */
export enum SetUserVitalsRequestSexualOrientationEnum {
  straight = "straight",
  lgbtq = "lgbtq+",
  asexual = "asexual",
  bisexual = "bisexual",
  gay = "gay",
  pansexual = "pansexual",
  queer = "queer",
}

export function SetUserVitalsRequestFromJSON(json: any): SetUserVitalsRequest {
  return SetUserVitalsRequestFromJSONTyped(json, false);
}

export function SetUserVitalsRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SetUserVitalsRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, "name") ? undefined : json["name"],
    location: !exists(json, "location") ? undefined : json["location"],
    current_title: !exists(json, "current_title")
      ? undefined
      : json["current_title"],
    current_employer: !exists(json, "current_employer")
      ? undefined
      : json["current_employer"],
    pronouns: !exists(json, "pronouns") ? undefined : json["pronouns"],
    relationship: !exists(json, "relationship")
      ? undefined
      : json["relationship"],
    kids: !exists(json, "kids") ? undefined : json["kids"],
    beliefs: !exists(json, "beliefs") ? undefined : json["beliefs"],
    sexual_orientation: !exists(json, "sexual_orientation")
      ? undefined
      : json["sexual_orientation"],
    ethnicity: !exists(json, "ethnicity") ? undefined : json["ethnicity"],
    mbti: !exists(json, "mbti") ? undefined : json["mbti"],
    love_language: !exists(json, "love_language")
      ? undefined
      : json["love_language"],
    enneagram: !exists(json, "enneagram") ? undefined : json["enneagram"],
    zodiac: !exists(json, "zodiac") ? undefined : json["zodiac"],
    human_design: !exists(json, "human_design")
      ? undefined
      : json["human_design"],
  };
}

export function SetUserVitalsRequestToJSON(
  value?: SetUserVitalsRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    location: value.location,
    current_title: value.current_title,
    current_employer: value.current_employer,
    pronouns: value.pronouns,
    relationship: value.relationship,
    kids: value.kids,
    beliefs: value.beliefs,
    sexual_orientation: value.sexual_orientation,
    ethnicity: value.ethnicity,
    mbti: value.mbti,
    love_language: value.love_language,
    enneagram: value.enneagram,
    zodiac: value.zodiac,
    human_design: value.human_design,
  };
}
