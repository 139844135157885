/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CreateRoleRequest
 */
export interface CreateRoleRequest {
  /**
   *
   * @type {string}
   * @memberof CreateRoleRequest
   */
  employer: string;
  /**
   *
   * @type {string}
   * @memberof CreateRoleRequest
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof CreateRoleRequest
   */
  responsibilities?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateRoleRequest
   */
  impact?: string | null;
  /**
   *
   * @type {Date}
   * @memberof CreateRoleRequest
   */
  date_started: Date;
  /**
   *
   * @type {Date}
   * @memberof CreateRoleRequest
   */
  date_ended?: Date;
}

export function CreateRoleRequestFromJSON(json: any): CreateRoleRequest {
  return CreateRoleRequestFromJSONTyped(json, false);
}

export function CreateRoleRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateRoleRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    employer: json["employer"],
    title: json["title"],
    responsibilities: !exists(json, "responsibilities")
      ? undefined
      : json["responsibilities"],
    impact: !exists(json, "impact") ? undefined : json["impact"],
    date_started: new Date(json["date_started"]),
    date_ended: !exists(json, "date_ended")
      ? undefined
      : new Date(json["date_ended"]),
  };
}

export function CreateRoleRequestToJSON(value?: CreateRoleRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    employer: value.employer,
    title: value.title,
    responsibilities: value.responsibilities,
    impact: value.impact,
    date_started: value.date_started.toISOString().substr(0, 10),
    date_ended:
      value.date_ended === undefined
        ? undefined
        : value.date_ended.toISOString().substr(0, 10),
  };
}
