import React, { useState } from "react";
import {
  GlimpseDetail,
  UpdateGlimpsePitchContextRequest,
} from "../../../../api/models";
import EditTextScreen from "../../../ui/screens/EditTextScreen";
import { clickableText } from "../../../../util/style";
import GlimpseExampleDialog from "./GlimpseExampleDialog";

type UpdateGlimpsePitchContextScreenProps = {
  glimpse: GlimpseDetail;
  errors: string[];
  onNextClicked: (newContext: UpdateGlimpsePitchContextRequest) => void;
  onBackClicked: () => void;
};

const UpdateGlimpsePitchContextScreenComponent = (
  props: UpdateGlimpsePitchContextScreenProps
) => {
  const {
    glimpse,
    errors,
    onNextClicked: onNextClickedInput,
    onBackClicked,
  } = props;

  const [showExampleDialog, setShowExampleDialog] = useState<boolean>(false);

  const onNextClicked = (newContext: string) => {
    onNextClickedInput({
      glimpse: glimpse.guid!,
      pitch_context: newContext,
    });
  };

  return (
    <>
      <EditTextScreen
        title="set your glimpse context"
        comment="describe what you're using this glimpse to pitch (ex: a job description or the purpose for why you're going to be sharing this glimpse)."
        label="context"
        onNextClicked={onNextClicked}
        onBackClicked={onBackClicked}
        content={glimpse.pitch_context}
        errors={errors}
        maxLength={16384}
        extra={
          <div className="flex flex-row justify-start">
            <button
              type="button"
              onClick={() => setShowExampleDialog(true)}
              className={clickableText}
            >
              show me an example &gt;&gt;
            </button>
          </div>
        }
        required
        stacked
        long
      />
      <GlimpseExampleDialog
        show={showExampleDialog}
        onClose={() => setShowExampleDialog(false)}
      />
    </>
  );
};

export default UpdateGlimpsePitchContextScreenComponent;
