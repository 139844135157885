import React, { useEffect, useState } from "react";
import { PromptWithResponse } from "../../../api/models";
import MCard from "../../ui/cards/MCard";
import { FillInBlankPromptResponseMeta } from "../../../api/types";
import MTitleWell from "../../ui/wells/MTitleWell";
import MAIWell from "../../ui/wells/MAIWell";
import MTextArea from "../../ui/MTextArea";
import MToggle from "../../ui/MToggle";
import { classNames } from "../../../util/strings";

type PromptSuggestionCardProps = {
  prompt: PromptWithResponse;
  suggestion: string;
  onSuggestionUpdated?: ((suggestion: string) => void) | null;
  errors?: string[];
  included: boolean;
  onIncludeClicked: (included: boolean) => void;
};

const PromptSuggestionCardComponent = (props: PromptSuggestionCardProps) => {
  const {
    prompt,
    errors,
    included,
    onIncludeClicked,
    suggestion: suggestionInput,
    onSuggestionUpdated: onSuggestionUpdatedInput,
  } = props;

  const [existing, setExisting] = useState<string | null>(null);
  const [suggestion, setSuggestion] = useState<string>(suggestionInput);

  const onSuggestionUpdated = (newSuggestion: string): void => {
    setSuggestion(newSuggestion);
    if (onSuggestionUpdatedInput) {
      onSuggestionUpdatedInput(newSuggestion);
    }
  };

  useEffect(() => {
    if (prompt.response) {
      const parsedResponse = prompt.response
        .meta as FillInBlankPromptResponseMeta;
      setExisting(parsedResponse.response);
    } else {
      setExisting(null);
    }
  }, [prompt.prompt.guid, prompt.response?.meta]);

  return (
    <MCard
      className="bg-secondary"
      footer={
        <div className="flex flex-row gap-3 items-center">
          <div className="grow" />
          <div
            className={classNames(
              included ? "text-m-black" : "text-m-gray line-through",
              "text-sm"
            )}
          >
            include this answer
          </div>
          <MToggle isOn={included} onToggle={onIncludeClicked} />
        </div>
      }
    >
      <div className="flex flex-col gap-3">
        <div
          className={classNames(
            "font-bold text-m-dark-gray",
            !included ? "line-through" : null
          )}
        >
          {prompt.prompt.question}
        </div>
        {existing && (
          <MTitleWell
            className={!included ? "line-through opacity-80" : null}
            title="you answered this question previously"
            kind="white"
          >
            {existing}
          </MTitleWell>
        )}
        <MAIWell
          title="our suggested answer"
          className={!included ? "line-through opacity-80" : null}
        >
          <MTextArea
            value={suggestion}
            errors={errors}
            disabled={!included}
            onChange={(e) => onSuggestionUpdated(e.target.value)}
          />
        </MAIWell>
      </div>
    </MCard>
  );
};

PromptSuggestionCardComponent.defaultProps = {
  errors: [],
  onSuggestionUpdated: null,
};

export default PromptSuggestionCardComponent;
