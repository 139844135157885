/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface RequestConnectionRequest
 */
export interface RequestConnectionRequest {
  /**
   *
   * @type {string}
   * @memberof RequestConnectionRequest
   */
  user: string;
}

export function RequestConnectionRequestFromJSON(
  json: any
): RequestConnectionRequest {
  return RequestConnectionRequestFromJSONTyped(json, false);
}

export function RequestConnectionRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RequestConnectionRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    user: json["user"],
  };
}

export function RequestConnectionRequestToJSON(
  value?: RequestConnectionRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    user: value.user,
  };
}
