import React from "react";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../../util/strings";
import { UserProfileSummary } from "../../../api/models";
import MAvatar from "../../ui/MAvatar";
import { dateToEnglishMDY } from "../../../util/date";
import { clickableText } from "../../../util/style";

type InsightContainerProps = {
  buttonLabel: string;
  onClick?: (() => void) | null;
  disabled?: boolean;
  askingUser?: UserProfileSummary | null;
  aboutUser: UserProfileSummary;
  title: string;
  timeCreated: Date;
  body?: string | null;
  usersClickable?: boolean;
};

const InsightContainerComponent = (props: InsightContainerProps) => {
  const {
    buttonLabel,
    onClick,
    disabled,
    askingUser,
    aboutUser,
    title,
    timeCreated,
    body,
    usersClickable,
  } = props;

  const hasBothUsers = Boolean(askingUser && aboutUser);

  const showBothUsers = hasBothUsers && askingUser!.id !== aboutUser.id;

  const innards = (
    <div
      className={classNames(
        "rounded-lg bg-primary-light shadow shadow-gray-400",
        "flex flex-row items-center w-full sm:max-w-lg px-3 py-2 sm:p-3 gap-3",
        onClick && !disabled
          ? "cursor-pointer hover:shadow-blue-600 hover:bg-primary-light"
          : null,
        onClick && disabled ? "cursor-not-allowed" : null
      )}
    >
      {showBothUsers ? (
        <div className="h-16 w-16 relative shrink-0">
          <div className="absolute left-0 top-0 border-2 border-m-white rounded-md">
            <MAvatar
              picUrl={askingUser!.photo}
              alt={askingUser!.name}
              size="mini"
            />
          </div>
          <div className="absolute right-0 bottom-0 border-2 border-m-white rounded-md">
            <MAvatar
              picUrl={aboutUser.photo}
              alt={aboutUser.name}
              size="mini"
            />
          </div>
        </div>
      ) : (
        <div className="h-16 w-16 shrink-0 flex flex-row items-center justify-center">
          <MAvatar picUrl={aboutUser.photo} alt={aboutUser.name} size="small" />
        </div>
      )}
      <div className="flex flex-col items-start w-full">
        <div className="flex flex-row items-center justify-between w-full gap-3">
          <span className="text-m-dark-gray font-bold text-sm">{title}</span>
          <span className="text-xs">
            {dateToEnglishMDY(timeCreated).toUpperCase()}
          </span>
        </div>
        <div className="text-lg font-bold items-center mt-2">
          {showBothUsers ? (
            <div className="grid grid-cols-9 gap-2">
              <div className="col-span-4 flex flex-col items-end justify-center">
                {usersClickable ? (
                  <a
                    href={`/@${askingUser?.username}`}
                    target="_blank"
                    rel="noreferrer"
                    className={clickableText}
                  >
                    {askingUser!.name}
                  </a>
                ) : (
                  askingUser!.name
                )}
              </div>
              <div className="col-span-1 flex flex-col justify-center items-center">
                <ArrowPathIcon className="w-4 h-4" />
              </div>
              <div className="col-span-4 flex flex-col items-start justify-center text-left">
                {usersClickable ? (
                  <a
                    href={`/@${aboutUser?.username}`}
                    target="_blank"
                    rel="noreferrer"
                    className={clickableText}
                  >
                    {aboutUser!.name}
                  </a>
                ) : (
                  aboutUser!.name
                )}
              </div>
            </div>
          ) : (
            <div className="col-span-4 flex flex-col items-start justify-center text-left">
              {usersClickable ? (
                <a
                  href={`/@${aboutUser?.username}`}
                  target="_blank"
                  rel="noreferrer"
                  className={clickableText}
                >
                  {aboutUser!.name}
                </a>
              ) : (
                aboutUser!.name
              )}
            </div>
          )}
        </div>
        {body && (
          <div className="mt-2 line-clamp-2 w-full text-left">{body}</div>
        )}
      </div>
    </div>
  );

  if (onClick) {
    return (
      <button
        className="grow"
        type="button"
        disabled={disabled}
        onClick={onClick}
        aria-label={buttonLabel}
      >
        {innards}
      </button>
    );
  }

  return innards;
};

InsightContainerComponent.defaultProps = {
  onClick: null,
  disabled: false,
  askingUser: null,
  body: null,
  usersClickable: true,
};

export default InsightContainerComponent;
