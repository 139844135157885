/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AddSecondaryEmailRequest
 */
export interface AddSecondaryEmailRequest {
  /**
   *
   * @type {string}
   * @memberof AddSecondaryEmailRequest
   */
  email: string;
}

export function AddSecondaryEmailRequestFromJSON(
  json: any
): AddSecondaryEmailRequest {
  return AddSecondaryEmailRequestFromJSONTyped(json, false);
}

export function AddSecondaryEmailRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddSecondaryEmailRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json["email"],
  };
}

export function AddSecondaryEmailRequestToJSON(
  value?: AddSecondaryEmailRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
  };
}
