import React, { ReactElement } from "react";
import {
  EmployerRelatedDetail,
  EmployerRoleDetail,
  ListEmployersResponse,
} from "../../../../api/models";
import { useBusyWatcher } from "../../../../util/hooks";
import MPlusButton from "../../../ui/buttons/MPlusButton";
import MTitle from "../../../ui/MTitle";
import MSubtext from "../../../ui/MSubtext";
import CenterColumnStickyButtonScreen from "../../../ui/screens/CenterColumnStickyButtonScreen";
import EmployerSummary from "./EmployerSummary";
import MNextButton from "../../../ui/buttons/MNextButton";

type ListWorkHistoryScreenProps = {
  employers: ListEmployersResponse;
  onAddEmployerClicked: () => void;
  onEditEmployerClicked: (employer: EmployerRelatedDetail) => void;
  onDeleteEmployerClicked: (employer: EmployerRelatedDetail) => void;
  onAddRoleClicked: (employer: EmployerRelatedDetail) => void;
  onEditRoleClicked: (role: EmployerRoleDetail) => void;
  onDeleteRoleClicked: (role: EmployerRoleDetail) => void;
  onDoneClicked: () => void;
};

const ListWorkHistoryComponent = (props: ListWorkHistoryScreenProps) => {
  const {
    employers,
    onAddEmployerClicked,
    onEditEmployerClicked,
    onDeleteEmployerClicked,
    onAddRoleClicked,
    onEditRoleClicked,
    onDeleteRoleClicked,
    onDoneClicked,
  } = props;

  const [busy, _] = useBusyWatcher();

  const getButtons = (): ReactElement[] => [
    <MPlusButton
      kind="primary"
      onClick={onAddEmployerClicked}
      disabled={busy}
      key="add-employer-button"
      text="add an employer"
    />,
    <MNextButton
      kind="secondary"
      onClick={onDoneClicked}
      disabled={busy}
      key="done-button"
      text="done"
    />,
  ];

  return (
    <CenterColumnStickyButtonScreen buttons={getButtons()} stacked fullWidth>
      <MTitle text="add your work history" className="text-center" />
      <MSubtext
        text="you'll be able to edit or remove this info any time."
        className="text-center"
      />
      <div className="flex flex-col gap-2">
        {employers.employers.map((employer) => (
          <EmployerSummary
            employer={employer}
            onEditClicked={() => onEditEmployerClicked(employer)}
            onEditRoleClicked={onEditRoleClicked}
            onDeleteRoleClicked={onDeleteRoleClicked}
            onAddRoleClicked={() => onAddRoleClicked(employer)}
            onDeleteClicked={() => onDeleteEmployerClicked(employer)}
            key={employer.guid!}
          />
        ))}
      </div>
    </CenterColumnStickyButtonScreen>
  );
};

export default ListWorkHistoryComponent;
