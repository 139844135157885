/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  GenTaglineCheckResponse,
  GenTaglineCheckResponseFromJSON,
  GenTaglineCheckResponseFromJSONTyped,
  GenTaglineCheckResponseToJSON,
} from "./GenTaglineCheckResponse";

/**
 *
 * @export
 * @interface GenTaglineCheckResponseSerializer
 */
export interface GenTaglineCheckResponseSerializer {
  /**
   *
   * @type {string}
   * @memberof GenTaglineCheckResponseSerializer
   */
  m?: string;
  /**
   *
   * @type {boolean}
   * @memberof GenTaglineCheckResponseSerializer
   */
  s: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof GenTaglineCheckResponseSerializer
   */
  e?: { [key: string]: string } | null;
  /**
   *
   * @type {GenTaglineCheckResponse}
   * @memberof GenTaglineCheckResponseSerializer
   */
  c?: GenTaglineCheckResponse;
}

export function GenTaglineCheckResponseSerializerFromJSON(
  json: any
): GenTaglineCheckResponseSerializer {
  return GenTaglineCheckResponseSerializerFromJSONTyped(json, false);
}

export function GenTaglineCheckResponseSerializerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GenTaglineCheckResponseSerializer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    m: !exists(json, "m") ? undefined : json["m"],
    s: json["s"],
    e: !exists(json, "e") ? undefined : json["e"],
    c: !exists(json, "c")
      ? undefined
      : GenTaglineCheckResponseFromJSON(json["c"]),
  };
}

export function GenTaglineCheckResponseSerializerToJSON(
  value?: GenTaglineCheckResponseSerializer | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    m: value.m,
    s: value.s,
    e: value.e,
    c: GenTaglineCheckResponseToJSON(value.c),
  };
}
