import React, { RefObject, useRef, useState } from "react";
import Resizer from "react-image-file-resizer";
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PixelCrop,
} from "react-image-crop";
import MFileButton from "../../ui/buttons/MFileButton";
import MSpinner from "../../ui/MSpinner";
import { commentText } from "../../../util/style";
import MButton from "../../ui/buttons/MButton";
import { useBusyWatcher } from "../../../util/hooks";

const TO_RADIANS = Math.PI / 180;

const resizeFile = (file: Blob, maxWidth: number, maxHeight: number) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

const canvasPreview = (
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0
): void => {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  // eslint-disable-next-line no-param-reassign
  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  // eslint-disable-next-line no-param-reassign
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
};

const centerAspectCrop = (
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
): Crop =>
  centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        height: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );

type ImageCropAndUploadContainerProps = {
  onCroppedImageAvailable: (
    canvasRef: RefObject<HTMLCanvasElement> | null
  ) => void;
};

const ImageCropAndUploadContainerComponent = (
  props: ImageCropAndUploadContainerProps
) => {
  // https://codesandbox.io/s/react-image-crop-demo-with-react-hooks-y831o?file=/src/App.tsx:1084-1151

  const { onCroppedImageAvailable } = props;
  const scale = 1;
  const rotate = 0;
  const [crop, setCrop] = useState<Crop>();
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [aspect, __] = useState<number | undefined>(1);
  const [resizing, setResizing] = useState(false);
  const [busy, _] = useBusyWatcher();
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const onSelectFile = async (f: File) => {
    setCrop(undefined); // Makes crop preview update between images.
    setResizing(true);
    const resized = await resizeFile(f, 1024, 1024);
    setImgSrc(resized as string);
    setResizing(false);
  };

  const onCrop = (c: Crop) => {
    setCrop(c);
  };

  const onCropCompleted = (c: PixelCrop) => {
    if (c?.width && c?.height && imgRef.current && previewCanvasRef.current) {
      canvasPreview(imgRef.current, previewCanvasRef.current, c, scale, rotate);
      onCroppedImageAvailable(previewCanvasRef);
    } else {
      onCroppedImageAvailable(null);
    }
  };

  return (
    <div className="flex flex-col">
      {imgSrc && (
        <div className="mb-4 mx-auto">
          <ReactCrop
            crop={crop}
            onChange={(c) => onCrop(c)}
            aspect={aspect}
            onComplete={(c) => onCropCompleted(c)}
            ruleOfThirds
          >
            <img src={imgSrc} alt="crop" onLoad={onImageLoad} ref={imgRef} />
          </ReactCrop>
        </div>
      )}
      {resizing && (
        <div className="flex flex-col items-center my-6 gap-3">
          <div className="h-10 w-10">
            <MSpinner />
          </div>
          <div className={commentText}>resizing... hold please!</div>
        </div>
      )}
      {!imgSrc && (
        <MFileButton
          kind="primary"
          onFileSelected={onSelectFile}
          accept="image/*"
        />
      )}
      {imgSrc && (
        <MButton onClick={() => setImgSrc(null)} kind="warning" disabled={busy}>
          clear
        </MButton>
      )}
      <div className="hidden">
        <canvas
          ref={previewCanvasRef}
          style={{
            border: "1px solid black",
            objectFit: "contain",
            width: 512,
            height: 512,
          }}
        />
      </div>
    </div>
  );
};

export default ImageCropAndUploadContainerComponent;
