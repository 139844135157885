import React from "react";
import { classNames } from "../../util/strings";
import MSpinner from "./MSpinner";

type ClickableTagListProps = {
  tags: string[];
  onClick: (tag: string) => void;
  disabled?: boolean;
  loading?: boolean;
};

const ClickableTagListComponent = (props: ClickableTagListProps) => {
  const { tags, onClick, disabled, loading } = props;

  return (
    <div className="flex flex-col rounded-lg shadow shadow-m-light-gray min-w-32 text-sm text-m-gray bg-secondary">
      {loading && (
        <div className="px-3 py-2">
          <div className="h-6">
            <MSpinner />
          </div>
        </div>
      )}
      {!loading &&
        tags.length > 0 &&
        tags.map((tag) => (
          <button
            type="button"
            key={tag}
            disabled={disabled}
            onClick={() => onClick(tag)}
          >
            <div
              key={tag}
              className={classNames(
                "px-3 py-2 text-left",
                disabled
                  ? "cursor-not-allowed text-m-gray"
                  : "cursor-pointer hover:bg-primary-light hover:underline text-primary"
              )}
            >
              {tag}
            </div>
          </button>
        ))}
      {!loading && tags.length === 0 && (
        <div className="px-3 py-2">no matching tags found</div>
      )}
    </div>
  );
};

ClickableTagListComponent.defaultProps = {
  disabled: false,
  loading: false,
};

export default ClickableTagListComponent;
