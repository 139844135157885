/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MessageWithLinkUserNotificationSummary,
  MessageWithLinkUserNotificationSummaryFromJSON,
  MessageWithLinkUserNotificationSummaryFromJSONTyped,
  MessageWithLinkUserNotificationSummaryToJSON,
} from "./MessageWithLinkUserNotificationSummary";
import {
  UserConnectionRequestRelatedDetail,
  UserConnectionRequestRelatedDetailFromJSON,
  UserConnectionRequestRelatedDetailFromJSONTyped,
  UserConnectionRequestRelatedDetailToJSON,
} from "./UserConnectionRequestRelatedDetail";

/**
 *
 * @export
 * @interface UserNotificationRelatedDetail
 */
export interface UserNotificationRelatedDetail {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof UserNotificationRelatedDetail
   */
  readonly guid?: string;
  /**
   * Whether the user has seen this notification.
   * @type {boolean}
   * @memberof UserNotificationRelatedDetail
   */
  is_seen?: boolean;
  /**
   * Whether the user has cleared this notification.
   * @type {boolean}
   * @memberof UserNotificationRelatedDetail
   */
  is_cleared?: boolean;
  /**
   * The time at which this record was created.
   * @type {Date}
   * @memberof UserNotificationRelatedDetail
   */
  readonly time_created?: Date;
  /**
   *
   * @type {string}
   * @memberof UserNotificationRelatedDetail
   */
  notif_type: UserNotificationRelatedDetailNotifTypeEnum;
  /**
   * The URL of an image to display in the notification.
   * @type {string}
   * @memberof UserNotificationRelatedDetail
   */
  image_url?: string | null;
  /**
   *
   * @type {MessageWithLinkUserNotificationSummary}
   * @memberof UserNotificationRelatedDetail
   */
  message_with_link?: MessageWithLinkUserNotificationSummary;
  /**
   *
   * @type {UserConnectionRequestRelatedDetail}
   * @memberof UserNotificationRelatedDetail
   */
  connection_request?: UserConnectionRequestRelatedDetail;
}

/**
 * @export
 * @enum {string}
 */
export enum UserNotificationRelatedDetailNotifTypeEnum {
  message_with_link = "message_with_link",
  connection_request = "connection_request",
  unknown = "unknown",
}

export function UserNotificationRelatedDetailFromJSON(
  json: any
): UserNotificationRelatedDetail {
  return UserNotificationRelatedDetailFromJSONTyped(json, false);
}

export function UserNotificationRelatedDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserNotificationRelatedDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    is_seen: !exists(json, "is_seen") ? undefined : json["is_seen"],
    is_cleared: !exists(json, "is_cleared") ? undefined : json["is_cleared"],
    time_created: !exists(json, "time_created")
      ? undefined
      : new Date(json["time_created"]),
    notif_type: json["notif_type"],
    image_url: !exists(json, "image_url") ? undefined : json["image_url"],
    message_with_link: !exists(json, "message_with_link")
      ? undefined
      : MessageWithLinkUserNotificationSummaryFromJSON(
          json["message_with_link"]
        ),
    connection_request: !exists(json, "connection_request")
      ? undefined
      : UserConnectionRequestRelatedDetailFromJSON(json["connection_request"]),
  };
}

export function UserNotificationRelatedDetailToJSON(
  value?: UserNotificationRelatedDetail | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    is_seen: value.is_seen,
    is_cleared: value.is_cleared,
    notif_type: value.notif_type,
    image_url: value.image_url,
    message_with_link: MessageWithLinkUserNotificationSummaryToJSON(
      value.message_with_link
    ),
    connection_request: UserConnectionRequestRelatedDetailToJSON(
      value.connection_request
    ),
  };
}
