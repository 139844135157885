import React, { useEffect, useState } from "react";
import MButton from "../../buttons/MButton";
import CommonQuestion, { CommonQuestionProps } from "./CommonQuestion";

type MultiSelectQuestionProps = CommonQuestionProps & {
  minNumSelectable?: number | null;
  maxNumSelectable?: number | null;
  numSelectable?: number | null;
  options: string[];
  selected?: number[];
  onSelected: (selected: number[]) => void;
  disabled?: boolean;
};

const MultiSelectQuestionComponent = (props: MultiSelectQuestionProps) => {
  const {
    minNumSelectable,
    maxNumSelectable,
    numSelectable,
    options,
    selected,
    onSelected: onSelectedInput,
    disabled,
    comment,
    ...rest
  } = props;

  const [innerSelected, setInnerSelected] = useState<number[]>([]);

  const getComment = (): string => {
    if (comment) {
      return comment;
    }
    if (numSelectable !== null) {
      return `select ${numSelectable} option(s) below`;
    }
    if (
      maxNumSelectable !== null &&
      minNumSelectable !== null &&
      maxNumSelectable === minNumSelectable
    ) {
      return `select ${maxNumSelectable} option(s) below`;
    }
    if (
      maxNumSelectable !== null &&
      minNumSelectable !== null &&
      maxNumSelectable !== minNumSelectable
    ) {
      return `select between ${minNumSelectable} and ${maxNumSelectable} option(s) below`;
    }
    if (maxNumSelectable !== null) {
      return `select up to ${maxNumSelectable} option(s) below`;
    }
    if (minNumSelectable !== null) {
      return `select at least ${minNumSelectable} option(s) below`;
    }
    return `select at least 1 option(s) below`;
  };

  const onSelected = (i: number) => {
    if (maxNumSelectable === 1 || numSelectable === 1) {
      if (innerSelected.includes(i)) {
        onSelectedInput([]);
      } else {
        onSelectedInput([i]);
      }
    } else {
      const newSelected = [...innerSelected];
      if (innerSelected.includes(i)) {
        newSelected.splice(newSelected.indexOf(i), 1);
      } else {
        newSelected.push(i);
      }
      onSelectedInput(newSelected);
    }
  };

  useEffect(() => {
    if (selected) {
      setInnerSelected(selected);
    } else {
      setInnerSelected([]);
    }
  }, [selected]);

  const getKey = (content: string, index: number): string => {
    if (content === "") {
      return `empty-${index}`;
    }
    return content;
  };

  return (
    <CommonQuestion comment={getComment()} {...rest}>
      <div className="flex flex-col gap-1">
        {options.map((o, i) => (
          <MButton
            key={getKey(o, i)}
            onClick={() => {
              if (o.trim() === "") {
                return;
              }
              onSelected(i);
            }}
            className="w-full rounded-md"
            kind={innerSelected.includes(i) ? "primary" : "secondary"}
            disabled={disabled}
          >
            {o}
          </MButton>
        ))}
      </div>
    </CommonQuestion>
  );
};

MultiSelectQuestionComponent.defaultProps = {
  selected: undefined,
  minNumSelectable: null,
  maxNumSelectable: null,
  numSelectable: null,
  disabled: false,
};

export default MultiSelectQuestionComponent;
