import React from "react";
import { classNames } from "../../util/strings";
import { titleText } from "../../util/style";

type MTitleProps = {
  text: string;
  className?: string | null;
};

const MTitleComponent = (props: MTitleProps) => {
  const { text, className } = props;

  return <h2 className={classNames(className, titleText)}>{text}</h2>;
};

MTitleComponent.defaultProps = {
  className: null,
};

export default MTitleComponent;
