import React, { PropsWithChildren } from "react";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import MPopoverMenu from "./MPopoverMenu";

const MEllipsisMenuComponent = (props: PropsWithChildren<object>) => {
  const { children } = props;

  return (
    <MPopoverMenu
      button={
        <div className="text-primary rounded-lg bg-m-white hover:brightness-110 w-9 h-full flex flex-col items-center border-2 border-primary">
          <EllipsisHorizontalIcon
            className="w-full h-full"
            aria-hidden="true"
          />
        </div>
      }
      panel={children}
    />
  );
};

export default MEllipsisMenuComponent;
