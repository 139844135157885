/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  QuestionnaireDetail,
  QuestionnaireDetailFromJSON,
  QuestionnaireDetailFromJSONTyped,
  QuestionnaireDetailToJSON,
} from "./QuestionnaireDetail";

/**
 *
 * @export
 * @interface AssessmentRelatedDetail
 */
export interface AssessmentRelatedDetail {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof AssessmentRelatedDetail
   */
  readonly guid?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentRelatedDetail
   */
  readonly title?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentRelatedDetail
   */
  readonly description?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentRelatedDetail
   */
  source_id: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentRelatedDetail
   */
  target_id: string;
  /**
   *
   * @type {QuestionnaireDetail}
   * @memberof AssessmentRelatedDetail
   */
  src?: QuestionnaireDetail;
  /**
   *
   * @type {QuestionnaireDetail}
   * @memberof AssessmentRelatedDetail
   */
  target?: QuestionnaireDetail;
}

export function AssessmentRelatedDetailFromJSON(
  json: any
): AssessmentRelatedDetail {
  return AssessmentRelatedDetailFromJSONTyped(json, false);
}

export function AssessmentRelatedDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssessmentRelatedDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    title: !exists(json, "title") ? undefined : json["title"],
    description: !exists(json, "description") ? undefined : json["description"],
    source_id: json["source_id"],
    target_id: json["target_id"],
    src: !exists(json, "src")
      ? undefined
      : QuestionnaireDetailFromJSON(json["src"]),
    target: !exists(json, "target")
      ? undefined
      : QuestionnaireDetailFromJSON(json["target"]),
  };
}

export function AssessmentRelatedDetailToJSON(
  value?: AssessmentRelatedDetail | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    source_id: value.source_id,
    target_id: value.target_id,
    src: QuestionnaireDetailToJSON(value.src),
    target: QuestionnaireDetailToJSON(value.target),
  };
}
