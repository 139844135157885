/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SaveGenerationRequest
 */
export interface SaveGenerationRequest {
  /**
   *
   * @type {string}
   * @memberof SaveGenerationRequest
   */
  group_qna_guid: string;
}

export function SaveGenerationRequestFromJSON(
  json: any
): SaveGenerationRequest {
  return SaveGenerationRequestFromJSONTyped(json, false);
}

export function SaveGenerationRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SaveGenerationRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    group_qna_guid: json["group_qna_guid"],
  };
}

export function SaveGenerationRequestToJSON(
  value?: SaveGenerationRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    group_qna_guid: value.group_qna_guid,
  };
}
