/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GlimpseSummary
 */
export interface GlimpseSummary {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof GlimpseSummary
   */
  readonly guid?: string;
  /**
   * An optional name that a user can apply to a Glimpse.
   * @type {string}
   * @memberof GlimpseSummary
   */
  title?: string | null;
  /**
   * An optional description that a user can apply to a Glimpse.
   * @type {string}
   * @memberof GlimpseSummary
   */
  description?: string | null;
  /**
   * The time at which this record was created.
   * @type {Date}
   * @memberof GlimpseSummary
   */
  readonly time_created?: Date;
  /**
   * Whether or not this glimpse is visible to the public.
   * @type {boolean}
   * @memberof GlimpseSummary
   */
  visible?: boolean;
}

export function GlimpseSummaryFromJSON(json: any): GlimpseSummary {
  return GlimpseSummaryFromJSONTyped(json, false);
}

export function GlimpseSummaryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GlimpseSummary {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    title: !exists(json, "title") ? undefined : json["title"],
    description: !exists(json, "description") ? undefined : json["description"],
    time_created: !exists(json, "time_created")
      ? undefined
      : new Date(json["time_created"]),
    visible: !exists(json, "visible") ? undefined : json["visible"],
  };
}

export function GlimpseSummaryToJSON(value?: GlimpseSummary | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    title: value.title,
    description: value.description,
    visible: value.visible,
  };
}
