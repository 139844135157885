import React from "react";
import {
  PromptWithResponse,
  PublishablePromptWithResponse,
  QuestionOperationsResponse,
} from "../../../../api/models";
import PromptRespondScreen from "../../../ui/forms/PromptRespondScreen";

type ViewSingleQuestionScreenProps = {
  question: PublishablePromptWithResponse;
  questionOperations: QuestionOperationsResponse;
  errors: string[];
  onDeleteClicked: (toDelete: PromptWithResponse) => void;
  onVoteClicked: () => void;
  onEditClicked: () => void;
  onBackClicked: () => void;
  onTagClicked: (tag: string) => void;
  onClearResponseClicked: () => void;
  onUpdateResponseClicked: (results: any, visible: boolean | null) => void;
};

const ViewSingleQuestionScreenComponent = (
  props: ViewSingleQuestionScreenProps
) => {
  const {
    question,
    questionOperations,
    errors,
    onEditClicked,
    onDeleteClicked,
    onVoteClicked,
    onBackClicked,
    onTagClicked,
    onUpdateResponseClicked,
    onClearResponseClicked,
  } = props;

  return (
    <PromptRespondScreen
      prompt={question}
      questionOperations={questionOperations}
      onNextClicked={onUpdateResponseClicked}
      onVoteClicked={onVoteClicked}
      onEditClicked={onEditClicked}
      onDeleteClicked={onDeleteClicked}
      onClearClicked={onClearResponseClicked}
      onBackClicked={onBackClicked}
      onTagClicked={onTagClicked}
      errors={errors}
    />
  );
};

export default ViewSingleQuestionScreenComponent;
