import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

// Define the type for the event callback function
type EventCallback = (...args: any[]) => void;

export enum PubSubEvent {
  ConnectionChanged = "CONNECTION_CHANGED",
}

// Define the type for the events object
interface Events {
  [key: string]: EventCallback[];
}

// Define the PubSubContext type
interface PubSubContextType {
  subscribe: (eventName: string, callback: EventCallback) => void;
  unsubscribe: (eventName: string, callback: EventCallback) => void;
  publish: (eventName: string, ...args: any[]) => void;
}

// Create a new context for the pubsub system
const PubSubContext = createContext<PubSubContextType | undefined>(undefined);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PubSubProviderProps {}

// Define a PubSubProvider component
export const PubSubProvider: FC<PubSubProviderProps> = ({
  children,
}: PropsWithChildren<PubSubProviderProps>) => {
  const [events, setEvents] = useState<Events>({});

  // Function to subscribe to an event
  const subscribe = (eventName: string, callback: EventCallback) => {
    setEvents((prevEvents) => ({
      ...prevEvents,
      [eventName]: [...(prevEvents[eventName] || []), callback],
    }));
  };

  // Function to unsubscribe from an event
  const unsubscribe = (eventName: string, callback: EventCallback) => {
    setEvents((prevEvents) => ({
      ...prevEvents,
      [eventName]: (prevEvents[eventName] || []).filter(
        (cb) => cb !== callback
      ),
    }));
  };

  // Function to publish an event
  const publish = (eventName: string, ...args: any[]) => {
    const callbacks = events[eventName] || [];
    callbacks.forEach((callback) => callback(...args));
  };

  // Provide the PubSubContext value to its children
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <PubSubContext.Provider value={{ subscribe, unsubscribe, publish }}>
      {children}
    </PubSubContext.Provider>
  );
};

// Custom hook to access the pubsub functionality
export const usePubSub = () => {
  const context = useContext(PubSubContext);
  if (!context) {
    throw new Error("usePubSub must be used within a PubSubProvider");
  }
  return context;
};

export const useSubscribe = (eventName: string, callback: EventCallback) => {
  const { subscribe, unsubscribe } = usePubSub();

  useEffect(() => {
    subscribe(eventName, callback);
    return () => {
      unsubscribe(eventName, callback);
    };
  }, []);
};
