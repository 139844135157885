import React, { useEffect, useState } from "react";
import { ProfileCompletionStage } from "../../../api/models";
import { classNames } from "../../../util/strings";
import MStepsCircles, { Step, StepStatus } from "../../ui/MStepsCircles";
import MButton from "../../ui/buttons/MButton";
import { commentText } from "../../../util/style";
import { addNextToUrl } from "../../../util/url";
import { PROFILE_COMPLETION_STAGE_KEY } from "../../../util/constants";

type ProfileCompletionPushdownProps = {
  completionStages: ProfileCompletionStage[];
};

const ProfileCompletionPushdownComponent = (
  props: ProfileCompletionPushdownProps
) => {
  const { completionStages } = props;

  const [curStage, setCurStage] = useState<number>(-1);
  const [curVisibleStage, setCurVisibleStage] = useState<number>(-1);

  const getCurStage = (): number => {
    // If nothing has been set before, then we default to the first stage that
    // has not yet been completed

    const existingStage = localStorage.getItem(PROFILE_COMPLETION_STAGE_KEY);
    if (existingStage === null) {
      const firstNotCompleted = completionStages.findIndex(
        (cs) => !cs.completed
      );
      if (firstNotCompleted === -1) {
        return completionStages.length - 1;
      }
      return firstNotCompleted;
    }

    // If something has been set before then we check to see if that stage is now
    // completed and, if so, we progress to the next uncompleted stage

    const parsedStage = parseInt(existingStage, 10);
    if (completionStages[parsedStage].completed) {
      let found = false;
      let foundIndex = -1;
      for (let i = parsedStage; i < completionStages.length; i += 1) {
        if (!completionStages[i].completed) {
          found = true;
          foundIndex = i;
          break;
        }
      }
      if (found) {
        return foundIndex;
      }
      return completionStages.length - 1;
    }

    // The current stage has not been completed so the value localStorage is
    // still valid.

    return parsedStage;
  };

  const statusFromStage = (index: number): StepStatus => {
    if (curVisibleStage === index) {
      return "current";
    }
    if (curVisibleStage > index) {
      return "complete";
    }
    return "upcoming";
  };

  const steps: Step[] = completionStages.map((cs, i) => ({
    name: cs.button_text,
    status: statusFromStage(i),
    key: cs.button_text + cs.comment,
  }));

  const show = (): boolean =>
    completionStages !== undefined && completionStages.length > 0;

  const onSkipClicked = (): void => {
    if (curVisibleStage === completionStages!.length - 1) {
      return;
    }
    const nextStage = curVisibleStage + 1;
    setCurStage(nextStage);
    setCurVisibleStage(nextStage);
    localStorage.setItem(PROFILE_COMPLETION_STAGE_KEY, nextStage.toString());
  };

  const onGoToStageClicked = (index: number) => {
    setCurVisibleStage(index);
  };

  useEffect(() => {
    const cur = getCurStage();
    localStorage.setItem(PROFILE_COMPLETION_STAGE_KEY, cur.toString());
    setCurStage(cur);
    setCurVisibleStage(cur);
  }, []);

  return (
    <div
      className={classNames(
        "flex flex-col items-center w-full",
        !show() ? "hidden" : null
      )}
    >
      {curVisibleStage !== -1 && (
        <>
          <div>
            <MStepsCircles
              steps={steps}
              maxClickableIndex={curStage}
              onStepClicked={onGoToStageClicked}
            />
          </div>
          <div className="flex flex-row gap-2 justify-center w-full mt-2">
            <MButton
              href={addNextToUrl(completionStages[curVisibleStage].href)}
              kind="primary"
              className="grow max-w-md"
            >
              {completionStages[curVisibleStage].button_text}
            </MButton>
            {completionStages[curVisibleStage].skippable && (
              <MButton onClick={onSkipClicked} kind="secondary">
                skip
              </MButton>
            )}
          </div>
          <div className={classNames("text-sm", commentText)}>
            {completionStages[curVisibleStage].comment}
          </div>
        </>
      )}
    </div>
  );
};

export default ProfileCompletionPushdownComponent;
