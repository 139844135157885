/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CommCoachSingleEntryRelatedDetail,
  CommCoachSingleEntryRelatedDetailFromJSON,
  CommCoachSingleEntryRelatedDetailFromJSONTyped,
  CommCoachSingleEntryRelatedDetailToJSON,
} from "./CommCoachSingleEntryRelatedDetail";
import {
  SingleUserQuestionInsightRelatedDetail,
  SingleUserQuestionInsightRelatedDetailFromJSON,
  SingleUserQuestionInsightRelatedDetailFromJSONTyped,
  SingleUserQuestionInsightRelatedDetailToJSON,
} from "./SingleUserQuestionInsightRelatedDetail";
import {
  UserCollabRelatedDetail,
  UserCollabRelatedDetailFromJSON,
  UserCollabRelatedDetailFromJSONTyped,
  UserCollabRelatedDetailToJSON,
} from "./UserCollabRelatedDetail";

/**
 *
 * @export
 * @interface InsightResponseEnvelope
 */
export interface InsightResponseEnvelope {
  /**
   *
   * @type {string}
   * @memberof InsightResponseEnvelope
   */
  insight_type: InsightResponseEnvelopeInsightTypeEnum;
  /**
   *
   * @type {UserCollabRelatedDetail}
   * @memberof InsightResponseEnvelope
   */
  collab?: UserCollabRelatedDetail;
  /**
   *
   * @type {SingleUserQuestionInsightRelatedDetail}
   * @memberof InsightResponseEnvelope
   */
  single_user_insight?: SingleUserQuestionInsightRelatedDetail;
  /**
   *
   * @type {CommCoachSingleEntryRelatedDetail}
   * @memberof InsightResponseEnvelope
   */
  single_comm_coach?: CommCoachSingleEntryRelatedDetail;
}

/**
 * @export
 * @enum {string}
 */
export enum InsightResponseEnvelopeInsightTypeEnum {
  user_collab = "user_collab",
  single_user_insight = "single_user_insight",
  single_comm_coach = "single_comm_coach",
}

export function InsightResponseEnvelopeFromJSON(
  json: any
): InsightResponseEnvelope {
  return InsightResponseEnvelopeFromJSONTyped(json, false);
}

export function InsightResponseEnvelopeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InsightResponseEnvelope {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    insight_type: json["insight_type"],
    collab: !exists(json, "collab")
      ? undefined
      : UserCollabRelatedDetailFromJSON(json["collab"]),
    single_user_insight: !exists(json, "single_user_insight")
      ? undefined
      : SingleUserQuestionInsightRelatedDetailFromJSON(
          json["single_user_insight"]
        ),
    single_comm_coach: !exists(json, "single_comm_coach")
      ? undefined
      : CommCoachSingleEntryRelatedDetailFromJSON(json["single_comm_coach"]),
  };
}

export function InsightResponseEnvelopeToJSON(
  value?: InsightResponseEnvelope | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    insight_type: value.insight_type,
    collab: UserCollabRelatedDetailToJSON(value.collab),
    single_user_insight: SingleUserQuestionInsightRelatedDetailToJSON(
      value.single_user_insight
    ),
    single_comm_coach: CommCoachSingleEntryRelatedDetailToJSON(
      value.single_comm_coach
    ),
  };
}
