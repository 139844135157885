/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  LLMInvocation,
  LLMInvocationFromJSON,
  LLMInvocationFromJSONTyped,
  LLMInvocationToJSON,
} from "./LLMInvocation";
import {
  UserGroup,
  UserGroupFromJSON,
  UserGroupFromJSONTyped,
  UserGroupToJSON,
} from "./UserGroup";

/**
 *
 * @export
 * @interface GroupCollab
 */
export interface GroupCollab {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof GroupCollab
   */
  readonly guid?: string;
  /**
   * The user that initiated the collab
   * @type {number}
   * @memberof GroupCollab
   */
  asking_user?: number | null;
  /**
   * The questions that were asked during the group collab (list of strings in JSON format).
   * @type {object}
   * @memberof GroupCollab
   */
  questions?: object | null;
  /**
   *
   * @type {LLMInvocation}
   * @memberof GroupCollab
   */
  llm_invocation?: LLMInvocation;
  /**
   * Whether or not the referenced Celery task has been completed.
   * @type {boolean}
   * @memberof GroupCollab
   */
  is_task_completed?: boolean;
  /**
   * The task ID for the Celery task that was enqueued to perform the group collab.
   * @type {string}
   * @memberof GroupCollab
   */
  task_id?: string | null;
  /**
   *
   * @type {UserGroup}
   * @memberof GroupCollab
   */
  group?: UserGroup;
}

export function GroupCollabFromJSON(json: any): GroupCollab {
  return GroupCollabFromJSONTyped(json, false);
}

export function GroupCollabFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GroupCollab {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    asking_user: !exists(json, "asking_user") ? undefined : json["asking_user"],
    questions: !exists(json, "questions") ? undefined : json["questions"],
    llm_invocation: !exists(json, "llm_invocation")
      ? undefined
      : LLMInvocationFromJSON(json["llm_invocation"]),
    is_task_completed: !exists(json, "is_task_completed")
      ? undefined
      : json["is_task_completed"],
    task_id: !exists(json, "task_id") ? undefined : json["task_id"],
    group: !exists(json, "group")
      ? undefined
      : UserGroupFromJSON(json["group"]),
  };
}

export function GroupCollabToJSON(value?: GroupCollab | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    asking_user: value.asking_user,
    questions: value.questions,
    llm_invocation: LLMInvocationToJSON(value.llm_invocation),
    is_task_completed: value.is_task_completed,
    task_id: value.task_id,
    group: UserGroupToJSON(value.group),
  };
}
