import React from "react";
import { Switch } from "@headlessui/react";
import { classNames } from "../../util/strings";

type MToggleProps = {
  isOn: boolean;
  onToggle: (newValue: boolean) => void;
  disabled?: boolean;
};

const MToggleComponent = (props: MToggleProps) => {
  const { isOn, onToggle, disabled } = props;

  const onClicked = () => {
    if (disabled) {
      return;
    }
    onToggle(!isOn);
  };

  const getSwitchClassName = (): string => {
    if (disabled) {
      return "!bg-m-light-gray/50 cursor-not-allowed";
    }
    if (isOn) {
      return "!bg-primary cursor-pointer";
    }
    return "!bg-m-light-gray cursor-pointer";
  };

  return (
    <div className="flex flex-row items-center h-6">
      <Switch
        checked={isOn}
        onChange={onClicked}
        className={classNames(
          getSwitchClassName(),
          "relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
        )}
      >
        <span className="sr-only">Use setting</span>
        <span
          className={classNames(
            isOn ? "translate-x-5" : "translate-x-0",
            "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-m-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        >
          <span
            className={classNames(
              isOn
                ? "opacity-0 duration-100 ease-out"
                : "opacity-100 duration-200 ease-in",
              "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
            )}
            aria-hidden="true"
          >
            <svg
              className={classNames(
                "h-3 w-3",
                disabled ? "text-m-dark-gray" : "text-m-gray"
              )}
              fill="none"
              viewBox="0 0 12 12"
            >
              <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span
            className={classNames(
              isOn
                ? "opacity-100 duration-200 ease-in"
                : "opacity-0 duration-100 ease-out",
              "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
            )}
            aria-hidden="true"
          >
            <svg
              className={classNames(
                "h-3 w-3",
                disabled ? "text-m-dark-gray" : "text-primary"
              )}
              fill="currentColor"
              viewBox="0 0 12 12"
            >
              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
            </svg>
          </span>
        </span>
      </Switch>
    </div>
  );
};

MToggleComponent.defaultProps = {
  disabled: false,
};

export default MToggleComponent;
