import React from "react";
import { UserCollabRelatedDetail } from "../../../api/models";
import InsightContainer from "./InsightContainer";
import { CommonInsightCardProps } from "./common";

type UserCollabCardProps = CommonInsightCardProps & {
  collab: UserCollabRelatedDetail;
};

const UserCollabCardComponent = (props: UserCollabCardProps) => {
  const { collab, ...rest } = props;

  return (
    <InsightContainer
      buttonLabel={`relationship analysis for ${collab.about_user?.name} and ${collab.asking_user?.name}`}
      askingUser={collab.asking_user}
      aboutUser={collab.about_user!}
      title="RELATIONSHIP ANALYSIS"
      timeCreated={collab.time_created!}
      {...rest}
    />
  );
};

export default UserCollabCardComponent;
