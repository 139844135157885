import React from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { useUniqueId } from "../../util/hooks";
import MTooltip from "./MTooltip";

type MTooltipIconProps = {
  tooltip: string;
};

const MTooltipIconComponent = (props: MTooltipIconProps) => {
  const { tooltip } = props;
  const tooltipId = useUniqueId();

  return (
    <>
      <QuestionMarkCircleIcon
        className="h-5 w-5 text-primary"
        data-tooltip-id={tooltipId}
        data-tooltip-content={tooltip}
      />
      <MTooltip id={tooltipId} place="bottom" />
    </>
  );
};

export default MTooltipIconComponent;
