import React, { ReactNode } from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import MButton, { MButtonProps } from "./MButton";

type MClearButtonProps = MButtonProps & {
  text?: string;
  icon?: ReactNode;
};

const MClearButtonComponent = (props: MClearButtonProps) => {
  const { text, ...rest } = props;

  return <MButton {...rest}>{text}</MButton>;
};

MClearButtonComponent.defaultProps = {
  text: "clear",
  icon: <XMarkIcon className="h-6 w-5 flex-none" aria-hidden="true" />,
};

export default MClearButtonComponent;
