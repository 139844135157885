import React, { useEffect, useState } from "react";
import CreateGroupScreen from "./CreateGroupScreen";
import GroupCompletedScreen from "./GroupCreatedScreen";
import { useBackTimer, useBusyWatcher } from "../../../../util/hooks";
import { useWrappedConnector } from "../../../../api/connector";
import WizardScreen, {
  WizardScreenPanel,
} from "../../../ui/screens/WizardScreen";
import LoadingScreen from "../../../ui/screens/LoadingScreen";
import BaseNavbarPage from "../../BaseNavbarPage";
import { MNavProps } from "../../../ui/nav/MNav";
import { errorMessagesFromResponse } from "../../../../api/helpers";
import EditGroupMembersScreen from "../edit/EditGroupMembersScreen";
import { addQueryParamToPath } from "../../../../util/url";
import {
  AddUsersRequest,
  RemoveUsersRequest,
} from "../../../../api/apis/GroupsApi";
import {
  UserGroup,
  SearchForConnectionResponse,
  CreateGroupRequest,
  UserProfileSummary,
} from "../../../../api/models";

type CreateGroupPageProps = {
  navbarProps: MNavProps;
  listGroupsUrl: string;
};

enum CreateGroupStage {
  LOADING,
  CREATE_GROUP,
  UPDATE_MEMBERS,
  COMPLETED,
}

const CreateGroupPageComponent = (props: CreateGroupPageProps) => {
  const { navbarProps, listGroupsUrl } = props;

  const [stage, setStage] = useState<CreateGroupStage>(
    CreateGroupStage.LOADING
  );
  const [groupId, setGroupId] = useState<string | null>(null);
  const [group, setGroup] = useState<UserGroup | null>(null);
  const [connections, setConnections] =
    useState<SearchForConnectionResponse | null>(null);
  const [userSearchTerm, setUserSearchTerm] = useState<string>("");
  const [errors, setErrors] = useState<any>({});
  const [isBack, asBack] = useBackTimer(stage);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);

  const loadGroup = async () => {
    if (!groupId) {
      return;
    }
    const response = await connector.getGroup(groupId!);
    setGroup(response.c!);
    setGroupId(response.c!.guid!);
  };

  const loadConnections = async (connectionsSearchTerm: string) => {
    setErrors({});
    try {
      const response = await connector.searchConnections({
        search_term: connectionsSearchTerm,
      });
      setConnections(response.c!);
      setUserSearchTerm(connectionsSearchTerm);
    } catch (e: any) {
      const newErrors = await errorMessagesFromResponse(e);
      setErrors(newErrors);
    }
  };

  const goToStage = async (nextStage: CreateGroupStage) => {
    setErrors({});
    // eslint-disable-next-line default-case
    if (nextStage === CreateGroupStage.UPDATE_MEMBERS) {
      await loadGroup();
    }
    setStage(nextStage);
  };

  const onCreateGroupNextClicked = async (request: CreateGroupRequest) => {
    setErrors({});
    try {
      const newGroup = await connector.createGroup(request);
      setGroup(newGroup.c!);
      setGroupId(newGroup.c!.guid!);
      setStage(CreateGroupStage.UPDATE_MEMBERS);
    } catch (e: any) {
      const parsed = await errorMessagesFromResponse(e);
      setErrors(parsed);
    }
  };

  const addUsers = async (usersToAdd: AddUsersRequest) => {
    setErrors({});
    if (usersToAdd.data.user_guids.length === 0) {
      return;
    }
    await connector.addUsersToGroup(usersToAdd);
  };

  const removeUsers = async (usersToRemove: RemoveUsersRequest) => {
    if (usersToRemove.data.user_guids.length === 0) {
      return;
    }
    await connector.removeUsersFromGroup(usersToRemove);
  };

  const onUpdateMembersNextClicked = async (
    usersToAdd: UserProfileSummary[],
    usersToRemove: UserProfileSummary[]
  ) => {
    try {
      const userGuidsToAdd = usersToAdd.map((user) => user.id!);
      await addUsers({
        data: { group: group!.guid!, user_guids: userGuidsToAdd },
      });
      const userGuidsToRemove = usersToRemove.map((user) => user.id!);
      await removeUsers({
        data: { group: group!.guid!, user_guids: userGuidsToRemove },
      });
    } catch (e: any) {
      const newErrors = await errorMessagesFromResponse(e);
      setErrors(newErrors);
      return;
    }
    await setStage(CreateGroupStage.COMPLETED);
  };

  useEffect(() => {
    goToStage(CreateGroupStage.CREATE_GROUP);
  }, []);

  const getElements = (): WizardScreenPanel[] => [
    {
      stage: CreateGroupStage.LOADING,
      content: <LoadingScreen />,
    },
    {
      stage: CreateGroupStage.CREATE_GROUP,
      content: (
        <CreateGroupScreen
          errors={errors}
          onNextClicked={onCreateGroupNextClicked}
          onBackClicked={asBack(() => {
            window.location.href = addQueryParamToPath(
              listGroupsUrl,
              "next",
              "my-groups"
            );
          })}
        />
      ),
    },
    {
      stage: CreateGroupStage.UPDATE_MEMBERS,
      content: group && (
        <EditGroupMembersScreen
          group={group}
          connections={connections}
          searchTerm={userSearchTerm}
          errors={errors}
          onSearchTermUpdated={loadConnections}
          onNextClicked={onUpdateMembersNextClicked}
          onBackClicked={asBack(() => goToStage(CreateGroupStage.COMPLETED))}
        />
      ),
    },
    {
      stage: CreateGroupStage.COMPLETED,
      content: group && (
        <GroupCompletedScreen
          groupName={group.name!}
          onNextClicked={() => {
            window.location.href = addQueryParamToPath(
              listGroupsUrl,
              "next",
              "my-groups"
            );
          }}
        />
      ),
    },
  ];

  return (
    <BaseNavbarPage navbarProps={navbarProps}>
      <WizardScreen elements={getElements()} stage={stage} isBack={isBack} />
    </BaseNavbarPage>
  );
};

export default CreateGroupPageComponent;
