import { PromptDetail } from "./models";

export const titleFromPromptDetail = (prompt: PromptDetail): string =>
  prompt.statement;

export interface ErrorResponse {
  m?: string;
  s: boolean;
  e?: { [key: string]: string[] | object[] } | null;
}

export const errorMessageForField = (
  body: ErrorResponse,
  key: string
): string[] => {
  if (!body.e) {
    return [];
  }
  if (!body.e[key]) {
    return [];
  }
  return body.e[key].map((e) => e as string);
};

export const errorMessagesForFields = (
  body: ErrorResponse,
  fields: string[]
): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};
  fields.forEach((field) => {
    errors[field] = errorMessageForField(body, field);
  });
  return errors;
};

export const errorMessagesFromErrorResponse = (
  body: ErrorResponse
): { [key: string]: string[] } => {
  if (!body.e) {
    return {};
  }
  return errorMessagesForFields(body, Object.keys(body.e));
};

export const errorMessagesFromResponse = async (
  response: Response
): Promise<{ [key: string]: string[] }> => {
  const jsonBody = await response.json();
  const parsed = jsonBody as ErrorResponse;
  return errorMessagesFromErrorResponse(parsed);
};

export const errorMessageFromResponse = async (
  response: Response
): Promise<string | undefined> => {
  const jsonBody = await response.json();
  const parsed = jsonBody as ErrorResponse;
  return parsed.m;
};

export const errorMessagesForKeyFromErrorResponse = (
  response: ErrorResponse,
  key: string,
  includeMessage = false
): string[] => {
  if (response.e && response.e[key]) {
    return response.e[key] as string[];
  }
  if (includeMessage && response.m) {
    return [response.m];
  }
  return [];
};

export const errorMessagesForKeyFromResponse = async (
  response: Response,
  key: string,
  includeMessage = false
): Promise<string[]> => {
  const jsonBody = await response.json();
  const parsed = jsonBody as ErrorResponse;
  return errorMessagesForKeyFromErrorResponse(parsed, key, includeMessage);
};
