import React from "react";
import { ITooltip, Tooltip } from "react-tooltip";

const MTooltipComponent = (props: ITooltip) => {
  const innerProps = { ...props };
  innerProps.className =
    "!bg-m-white !text-primary rounded-md p-2 shadow z-30 max-w-sm whitespace-pre-line";

  return <Tooltip {...innerProps} />;
};

export default MTooltipComponent;
