/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  PromptResponseRelatedDetail,
  PromptResponseRelatedDetailFromJSON,
  PromptResponseRelatedDetailFromJSONTyped,
  PromptResponseRelatedDetailToJSON,
} from "./PromptResponseRelatedDetail";

/**
 *
 * @export
 * @interface PromptWithSelected
 */
export interface PromptWithSelected {
  /**
   *
   * @type {PromptResponseRelatedDetail}
   * @memberof PromptWithSelected
   */
  prompt_response: PromptResponseRelatedDetail;
  /**
   *
   * @type {boolean}
   * @memberof PromptWithSelected
   */
  selected: boolean;
}

export function PromptWithSelectedFromJSON(json: any): PromptWithSelected {
  return PromptWithSelectedFromJSONTyped(json, false);
}

export function PromptWithSelectedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PromptWithSelected {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    prompt_response: PromptResponseRelatedDetailFromJSON(
      json["prompt_response"]
    ),
    selected: json["selected"],
  };
}

export function PromptWithSelectedToJSON(
  value?: PromptWithSelected | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    prompt_response: PromptResponseRelatedDetailToJSON(value.prompt_response),
    selected: value.selected,
  };
}
