/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  UserInviteRelatedDetail,
  UserInviteRelatedDetailFromJSON,
  UserInviteRelatedDetailFromJSONTyped,
  UserInviteRelatedDetailToJSON,
} from "./UserInviteRelatedDetail";

/**
 *
 * @export
 * @interface ListUserInvitesResponse
 */
export interface ListUserInvitesResponse {
  /**
   *
   * @type {Array<UserInviteRelatedDetail>}
   * @memberof ListUserInvitesResponse
   */
  invites: Array<UserInviteRelatedDetail>;
}

export function ListUserInvitesResponseFromJSON(
  json: any
): ListUserInvitesResponse {
  return ListUserInvitesResponseFromJSONTyped(json, false);
}

export function ListUserInvitesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ListUserInvitesResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    invites: (json["invites"] as Array<any>).map(
      UserInviteRelatedDetailFromJSON
    ),
  };
}

export function ListUserInvitesResponseToJSON(
  value?: ListUserInvitesResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    invites: (value.invites as Array<any>).map(UserInviteRelatedDetailToJSON),
  };
}
