import React, { useEffect, useState } from "react";
import {
  LinkSummary,
  ListLinksForGlimpseResponse,
  SetLinksForGlimpseRequest,
} from "../../../../api/models";
import MErrors from "../../../ui/MErrors";
import FormScreen from "../../../ui/screens/FormScreen";
import MSubtext from "../../../ui/MSubtext";
import LinkSummaryRow from "../../profile_v2/links/LinkSummaryRow";

type PickGlimpseLinksScreenProps = {
  glimpseId: string;
  selected: ListLinksForGlimpseResponse;
  errors: string[];
  onNextClicked: (newSelected: SetLinksForGlimpseRequest) => void;
  onBackClicked: () => void;
};

const PickGlimpseLinksScreenComponent = (
  props: PickGlimpseLinksScreenProps
) => {
  const {
    glimpseId,
    selected: selectedInput,
    errors: errorsInput,
    onNextClicked: onNextClickedInput,
    onBackClicked,
  } = props;

  const [possible, setPossible] = useState<LinkSummary[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [busy, _] = useState<boolean>(false);

  const onNextClicked = () => {
    onNextClickedInput({
      glimpse: glimpseId,
      links: selected,
    });
  };

  const onToggleLinkClicked = (link: LinkSummary) => {
    const newSelected: string[] = [...selected];
    const toggleGuid = link.guid!;
    if (newSelected.includes(toggleGuid)) {
      newSelected.splice(newSelected.indexOf(toggleGuid), 1);
    } else {
      newSelected.push(toggleGuid);
    }
    setSelected(newSelected);
  };

  useEffect(() => {
    const newPossible: LinkSummary[] = [];
    const newSelected: string[] = [];
    for (let i = 0; i < selectedInput.links.length; i += 1) {
      newPossible.push(selectedInput.links[i].link);
      if (selectedInput.links[i].selected) {
        newSelected.push(selectedInput.links[i].link.guid!);
      }
    }
    setPossible(newPossible);
    setSelected(newSelected);
  }, []);

  return (
    <FormScreen
      title="select links"
      comment={`pick any links that you'd like to be displayed on your glimpse`}
      nextDisabled={busy}
      onNextClicked={onNextClicked}
      backDisabled={busy}
      onBackClicked={onBackClicked}
      stacked
    >
      <MErrors errors={errorsInput} />
      <div className="flex flex-col gap-2">
        {possible.length > 0 ? (
          possible.map((link) => (
            <LinkSummaryRow
              link={link}
              key={link.guid!}
              onToggleVisibilityClicked={() => onToggleLinkClicked(link)}
              isVisible={selected.includes(link.guid!)}
              toggleVisibilityDisabled={busy}
            />
          ))
        ) : (
          <MSubtext
            text="you haven't added any links to your profile. once you do, they will be listed here."
            className="text-center"
          />
        )}
      </div>
    </FormScreen>
  );
};

export default PickGlimpseLinksScreenComponent;
