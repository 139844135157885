import React, { useState } from "react";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import MInput from "../../ui/MInput";
import MButton from "../../ui/buttons/MButton";

type ChatboxMessageBarProps = {
  onMessageReady: (message: string) => Promise<any>;
  busy: boolean;
};

const ChatboxMessageBarComponent = (props: ChatboxMessageBarProps) => {
  const { onMessageReady, busy } = props;

  const [message, setMessage] = useState<string>("");

  const isMessageValid = (): boolean => {
    if (message.length === 0) {
      return false;
    }
    return true;
  };

  const canSubmit = (): boolean => {
    if (busy) {
      return false;
    }
    if (!isMessageValid()) {
      return false;
    }
    return true;
  };

  const handleOnNewMessageReady = () => {
    onMessageReady(message).then(() => setMessage(""));
  };

  return (
    <div className="flex flex-row gap-2 w-full p-2 items-center">
      <div className="grow">
        <MInput
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          disabled={busy}
          placeholder="ask a question"
          onEnterPressed={() => canSubmit() && handleOnNewMessageReady()}
        />
      </div>
      <div>
        <MButton
          kind="primary"
          icon={<PaperAirplaneIcon className="w-5 h-5" />}
          disabled={!canSubmit()}
          onClick={handleOnNewMessageReady}
        >
          send
        </MButton>
      </div>
    </div>
  );
};

export default ChatboxMessageBarComponent;
