import React from "react";
import { PromptDetail } from "../../../api/models";
import QuestionCard from "./QuestionCard";

type QuestionCardsProps = {
  questions: Array<PromptDetail>;
  searchBaseUrl: string;
};

const QuestionCardsComponent: React.FC<QuestionCardsProps> = (
  props: QuestionCardsProps
) => {
  const { questions, searchBaseUrl } = props;
  return (
    <div className="space-y-8">
      {questions.map((question) => (
        <article id={`question-${question.guid}`} key={question.guid}>
          <QuestionCard question={question} searchBaseUrl={searchBaseUrl} />
        </article>
      ))}
    </div>
  );
};

QuestionCardsComponent.displayName = "QuestionCards";

export default QuestionCardsComponent;
