import React, { useEffect, useState } from "react";
import {
  GetAssessmentResponse,
  GetInferenceResultsResponse,
  GetNextPromptForAssessmentResponse,
  GetQuestionnaireWithResponsesResponse,
  PublishablePromptWithResponse,
  TargetResponse,
} from "../../../api/models";
import AssessmentIntroScreen from "./AssessmentIntroScreen";
import { errorMessagesForKeyFromResponse } from "../../../api/helpers";
import waitForTask from "../../../util/tasks";
import AssessmentViewSuggestionsScreen from "./AssessmentViewSuggestionsScreen";
import { MNavProps } from "../../ui/nav/MNav";
import BaseNavbarPage from "../BaseNavbarPage";
import { useBackTimer, useBusyWatcher } from "../../../util/hooks";
import { useWrappedConnector } from "../../../api/connector";
import LoadingScreen from "../../ui/screens/LoadingScreen";
import { Step, StepStatus } from "../../ui/MStepsCircles";
import { useAsyncConfirmationDialog } from "../../dialogs/hooks";
import WizardScreen, { WizardScreenPanel } from "../../ui/screens/WizardScreen";
import PromptRespondTransitionScreen from "../../ui/forms/PromptRespondTransitionScreen";
import QuestionnaireReviewScreen from "../questionnaire/QuestionnaireReviewScreen";

type AssessmentPageProps = {
  assessmentId: string;
  nextUrl: string;
  navbarProps: MNavProps;
};

enum AssessmentStage {
  LOADING,
  INTRODUCTION,
  QUIZ_RESPOND,
  QUIZ_REVIEW,
  QUIZ_UPDATE,
  PROCESSING_ASSESSMENT,
  SUGGESTIONS_REVIEW,
  DIRECT_RESPOND,
  DIRECT_REVIEW,
  DIRECT_UPDATE,
}

const AssessmentPageComponent = (props: AssessmentPageProps) => {
  const { assessmentId, nextUrl, navbarProps } = props;

  // Assessment data

  const [assessment, setAssessment] = useState<GetAssessmentResponse>();
  const [sourceQuestionnaire, setSourceQuestionnaire] =
    useState<GetQuestionnaireWithResponsesResponse>();
  const [targetQuestionnaire, setTargetQuestionnaire] =
    useState<GetQuestionnaireWithResponsesResponse>();
  const [nextQuizPrompt, setNextQuizPrompt] =
    useState<GetNextPromptForAssessmentResponse>();
  const [quizPrompts, setQuizPrompts] = useState<string[]>([]);
  const [suggestions, setSuggestions] = useState<GetInferenceResultsResponse>();
  const [suggestionEditIndex, setSuggestionsEditIndex] = useState<number>(0);
  const [nextTargetPrompt, setNextTargetPrompt] =
    useState<PublishablePromptWithResponse>();

  // General state management

  const [error, setError] = useState<string>("");
  const [stage, setStage] = useState<AssessmentStage>(AssessmentStage.LOADING);
  const [responseErrors, setResponseErrors] = useState<string[]>([]);
  const [generated, setGenerated] = useState<boolean>(false);
  const [suggestionErrors, setSuggestionErrors] = useState<{
    [key: string]: string;
  }>({});
  const [isBack, asBack] = useBackTimer(stage);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);
  const confirmDialog = useAsyncConfirmationDialog();

  // API integrations

  const loadTargetPrompt = async (promptId: string) => {
    const promptResponse = await connector.getPromptWithResponse(promptId);
    setNextTargetPrompt(promptResponse.c!);
  };

  const loadTargetPromptByIndex = async (index: number) => {
    const promptId = targetQuestionnaire!.prompts[index].prompt.guid!;
    return loadTargetPrompt(promptId);
  };

  const loadAssessment = async (): Promise<void> => {
    const assessmentResponse = await connector.getAssessment(assessmentId);
    setAssessment(assessmentResponse.c!);
    const targetResponse = await connector.getQuestionnaire(
      assessmentResponse.c!.assessment.target_id
    );
    setTargetQuestionnaire(targetResponse.c!);
    const sourceResponse = await connector.getQuestionnaire(
      assessmentResponse.c!.assessment.source_id
    );
    setSourceQuestionnaire(sourceResponse.c!);
  };

  const loadSourceAssessment = async (): Promise<void> => {
    const sourceResponse = await connector.getQuestionnaire(
      assessment!.assessment.source_id
    );
    setSourceQuestionnaire(sourceResponse.c!);
  };

  const loadTargetAssessment = async (): Promise<void> => {
    const targetResponse = await connector.getQuestionnaire(
      assessment!.assessment.target_id
    );
    setTargetQuestionnaire(targetResponse.c!);
  };

  const loadNextPrompt = async (
    setNextPrompt = true
  ): Promise<GetNextPromptForAssessmentResponse> => {
    const nextPromptResponse = await connector.getNextPromptForAssessment(
      assessmentId
    );
    if (setNextPrompt) {
      setNextQuizPrompt(nextPromptResponse.c!);
    }
    return nextPromptResponse.c!;
  };

  const loadPreviousPrompt =
    async (): Promise<GetNextPromptForAssessmentResponse> => {
      const lastPrompt = quizPrompts[quizPrompts.length - 1];
      const previousResponse = await connector.getPromptForAssessment(
        assessmentId,
        lastPrompt
      );
      setNextQuizPrompt(previousResponse.c!);
      setQuizPrompts(quizPrompts.slice(0, quizPrompts.length - 1));
      return previousResponse.c!;
    };

  const loadQuizPrompt = async (promptId: string) => {
    const promptResponse = await connector.getPromptForAssessment(
      assessmentId,
      promptId
    );
    setNextQuizPrompt(promptResponse.c!);
  };

  const answerQuizPrompt = async (response: any) =>
    connector.submitPromptResponse({
      prompt: nextQuizPrompt!.prompt_response.prompt.guid!,
      response,
      questionnaire: assessment!.assessment.source_id,
    });

  const fetchSuggestions = async (inferenceId: string) => {
    const suggestionsResponse = await connector.getAssessmentResult(
      inferenceId
    );
    setSuggestions(suggestionsResponse.c!);
    setGenerated(true);
  };

  // UI Interaction

  const goToStage = async (newStage: AssessmentStage) => {
    let saveStage = true;
    // eslint-disable-next-line default-case
    switch (newStage) {
      case AssessmentStage.QUIZ_RESPOND: {
        const nextPrompt = await loadNextPrompt();
        if (nextPrompt.assessment_completed) {
          await goToStage(AssessmentStage.QUIZ_REVIEW);
          saveStage = false;
        }
        break;
      }
      case AssessmentStage.DIRECT_RESPOND: {
        if (stage === AssessmentStage.INTRODUCTION) {
          await loadTargetPromptByIndex(0);
          setSuggestionsEditIndex(0);
        } else if (stage === AssessmentStage.DIRECT_REVIEW) {
          const index = targetQuestionnaire!.prompts!.length - 1;
          await loadTargetPromptByIndex(index);
          setSuggestionsEditIndex(index);
        }
        break;
      }
      case AssessmentStage.QUIZ_REVIEW: {
        await loadSourceAssessment();
        setQuizPrompts([]);
        break;
      }
      case AssessmentStage.DIRECT_REVIEW: {
        await loadTargetAssessment();
        break;
      }
    }
    if (saveStage) {
      setStage(newStage);
    }
  };

  const onBackQuizRespondClicked = async () => {
    if (quizPrompts.length > 0) {
      await loadPreviousPrompt();
    } else {
      await goToStage(AssessmentStage.INTRODUCTION);
    }
  };

  const onBackDirectRespondClicked = async () => {
    if (suggestionEditIndex === 0) {
      await goToStage(AssessmentStage.INTRODUCTION);
    } else {
      await loadTargetPromptByIndex(suggestionEditIndex - 1);
      setSuggestionsEditIndex(suggestionEditIndex - 1);
    }
  };

  const goToNextDirectRespond = async () => {
    if (suggestionEditIndex === targetQuestionnaire!.prompts.length - 1) {
      await goToStage(AssessmentStage.DIRECT_REVIEW);
    } else {
      await loadTargetPromptByIndex(suggestionEditIndex + 1);
      setSuggestionsEditIndex(suggestionEditIndex + 1);
    }
  };

  const saveDirectResponse = async (response: any, visible: boolean | null) => {
    setResponseErrors([]);
    try {
      await connector.submitPromptResponse({
        prompt: nextTargetPrompt!.prompt.guid!,
        response,
        questionnaire: assessment!.assessment.target_id,
        publish: visible,
      });
      return true;
    } catch (r: any) {
      const parsed = await errorMessagesForKeyFromResponse(r, "response", true);
      setResponseErrors(parsed);
      return false;
    }
  };

  const onSaveDirectRespondClicked = async (
    response: any,
    visible: boolean | null
  ) => {
    const success = await saveDirectResponse(response, visible);
    if (!success) {
      return;
    }
    await goToNextDirectRespond();
  };

  const onUpdateDirectRespondClicked = async (
    response: any,
    visible: boolean | null
  ) => {
    const success = await saveDirectResponse(response, visible);
    if (!success) {
      return;
    }
    await goToStage(AssessmentStage.DIRECT_REVIEW);
  };

  const onClearDirectRespondClicked = async () => {
    await connector.clearPromptResponse(nextTargetPrompt!.prompt.guid!);
    return goToStage(AssessmentStage.DIRECT_REVIEW);
  };

  const getQuizRespondSteps = (): Step[] => {
    const steps: Step[] = [];
    const promptsLeft = sourceQuestionnaire!.prompts.filter(
      (prompt) => !prompt.response
    );
    const curPromptIndex = promptsLeft.findIndex(
      (prompt) =>
        prompt.prompt.guid === nextQuizPrompt!.prompt_response.prompt.guid
    );

    for (let i = 0; i < promptsLeft.length; i += 1) {
      let status: StepStatus;
      if (i < curPromptIndex) {
        status = "complete";
      } else if (i === curPromptIndex) {
        status = "current";
      } else {
        status = "upcoming";
      }
      steps.push({
        name: sourceQuestionnaire!.prompts[i].prompt.question,
        key: sourceQuestionnaire!.prompts[i].prompt.question,
        status,
      });
    }
    return steps;
  };

  const getDirectRespondSteps = (): Step[] => {
    const steps: Step[] = [];
    for (let i = 0; i < targetQuestionnaire!.prompts.length; i += 1) {
      let status: StepStatus;
      if (i < suggestionEditIndex) {
        status = "complete";
      } else if (i === suggestionEditIndex) {
        status = "current";
      } else {
        status = "upcoming";
      }
      steps.push({
        name: targetQuestionnaire!.prompts[i].prompt.question,
        key: targetQuestionnaire!.prompts[i].prompt.question,
        status,
      });
    }
    return steps;
  };

  const onSaveQuizResponseClicked = async (
    response: any,
    visible: boolean | null,
    shouldLoadNext = true
  ): Promise<any> => {
    const curPrompt = nextQuizPrompt!.prompt_response!.prompt.guid!;
    try {
      await answerQuizPrompt(response);
    } catch (e: any) {
      const parsed = await errorMessagesForKeyFromResponse(e, "response", true);
      setResponseErrors(parsed);
      return;
    }
    if (!shouldLoadNext) {
      await goToStage(AssessmentStage.QUIZ_REVIEW);
      return;
    }
    const nextResponse = await loadNextPrompt(false);
    if (nextResponse.assessment_completed) {
      await goToStage(AssessmentStage.QUIZ_REVIEW);
      return;
    }
    setNextQuizPrompt(nextResponse);
    setQuizPrompts([...quizPrompts, curPrompt]);
  };

  const onProcessAssessmentClicked = async () => {
    const processResponse = await connector.processAssessment(assessmentId);
    if (processResponse.c!.is_task_completed) {
      await fetchSuggestions(processResponse.c!.guid!);
      setStage(AssessmentStage.SUGGESTIONS_REVIEW);
      return;
    }
    setStage(AssessmentStage.PROCESSING_ASSESSMENT);
    const success = await waitForTask(processResponse.c!.task_id!, connector);
    if (!success) {
      setError(
        "something broke while processing your assessment :( try again in a little bit"
      );
      return;
    }
    await fetchSuggestions(processResponse.c!.guid!);
    setStage(AssessmentStage.SUGGESTIONS_REVIEW);
  };

  const onEditSourceResponseClicked = (prompt: string): void => {
    loadQuizPrompt(prompt).then(() => goToStage(AssessmentStage.QUIZ_UPDATE));
  };

  const onClearQuizResponseClicked = () => {
    connector
      .clearPromptResponse(nextQuizPrompt!.prompt_response.prompt.guid!)
      .then(() => goToStage(AssessmentStage.QUIZ_REVIEW));
  };

  const onEditDirectResponseClicked = (prompt: string): void => {
    loadTargetPrompt(prompt).then(() =>
      goToStage(AssessmentStage.DIRECT_UPDATE)
    );
  };

  const onCompleted = async () => {
    await connector.markAssessmentAsCompleted(assessmentId);
    window.location.href = nextUrl;
  };

  const onSaveSuggestionsClicked = async (results: TargetResponse[]) => {
    setSuggestionErrors({});
    try {
      await connector.submitAssessmentResults({
        assessment: assessmentId,
        responses: results,
      });
    } catch (e: any) {
      const parsed = await errorMessagesForKeyFromResponse(
        e,
        "responses",
        true
      );
      const newErrors: { [key: string]: string } = {};
      for (let i = 0; i < results.length; i += 1) {
        if (parsed[i]) {
          newErrors[results[i].prompt] = parsed[i];
        }
      }
      setSuggestionErrors(newErrors);
      return;
    }
    await onCompleted();
  };

  const onCancelSuggestionsClicked = async () => {
    const result = await confirmDialog.confirm({
      title: "exit assessment?",
      body: "are you sure you want to exit this assessment? your quiz answers have been saved, so you can come back any time.",
    });
    if (!result) {
      return;
    }
    window.location.href = nextUrl;
  };

  useEffect(() => {
    loadAssessment()
      .then(() => goToStage(AssessmentStage.INTRODUCTION))
      .catch(() => {
        setError(
          "we weren't able to load information about that assessment :("
        );
      });
  }, []);

  const getElements = (): WizardScreenPanel[] => [
    {
      stage: AssessmentStage.LOADING,
      content: (
        <LoadingScreen
          errors={error !== "" ? [error] : []}
          onBackClicked={() => window.history.back()}
        />
      ),
    },
    {
      stage: AssessmentStage.INTRODUCTION,
      content: sourceQuestionnaire && targetQuestionnaire && (
        <AssessmentIntroScreen
          source={sourceQuestionnaire}
          target={targetQuestionnaire}
          quizClicked={() => goToStage(AssessmentStage.QUIZ_RESPOND)}
          directClicked={() => goToStage(AssessmentStage.DIRECT_RESPOND)}
        />
      ),
    },
    {
      stage: AssessmentStage.QUIZ_RESPOND,
      content: sourceQuestionnaire && nextQuizPrompt && (
        <PromptRespondTransitionScreen
          steps={{ steps: getQuizRespondSteps() }}
          prompt={nextQuizPrompt.prompt_response}
          onNextClicked={(r, v) => onSaveQuizResponseClicked(r, v)}
          errors={responseErrors}
          onBackClicked={asBack(onBackQuizRespondClicked)}
        />
      ),
    },
    {
      stage: AssessmentStage.QUIZ_UPDATE,
      content: nextQuizPrompt && (
        <PromptRespondTransitionScreen
          prompt={nextQuizPrompt.prompt_response}
          onNextClicked={(r, v) => onSaveQuizResponseClicked(r, v, false)}
          errors={responseErrors}
          onBackClicked={asBack(() => goToStage(AssessmentStage.QUIZ_REVIEW))}
          onClearClicked={onClearQuizResponseClicked}
        />
      ),
    },
    {
      stage: AssessmentStage.QUIZ_REVIEW,
      content: sourceQuestionnaire && (
        <QuestionnaireReviewScreen
          title="review your quiz answers"
          content={sourceQuestionnaire!}
          onBackClicked={
            !generated
              ? asBack(() => goToStage(AssessmentStage.INTRODUCTION))
              : null
          }
          onNextClicked={onProcessAssessmentClicked}
          onEditClicked={onEditSourceResponseClicked}
          completedFlavorText={
            'review your answers below and if everything looks good click the "generate responses" button at the bottom of this page'
          }
          nextText="generate responses"
        />
      ),
    },
    {
      stage: AssessmentStage.PROCESSING_ASSESSMENT,
      content: (
        <LoadingScreen
          errors={error !== "" ? [error] : []}
          message="give us just a moment to process your results and come up with some snazzy suggestions for you..."
          onBackClicked={asBack(() => goToStage(AssessmentStage.QUIZ_REVIEW))}
        />
      ),
    },
    {
      stage: AssessmentStage.SUGGESTIONS_REVIEW,
      content: suggestions && sourceQuestionnaire && (
        <AssessmentViewSuggestionsScreen
          results={suggestions}
          sourceTopic={sourceQuestionnaire.questionnaire.topic!}
          onEditClicked={() => goToStage(AssessmentStage.QUIZ_REVIEW)}
          onSaveClicked={onSaveSuggestionsClicked}
          onCancelClicked={onCancelSuggestionsClicked}
          errors={suggestionErrors}
        />
      ),
    },
    {
      stage: AssessmentStage.DIRECT_RESPOND,
      content: nextTargetPrompt && targetQuestionnaire && (
        <PromptRespondTransitionScreen
          prompt={nextTargetPrompt}
          onNextClicked={onSaveDirectRespondClicked}
          onBackClicked={asBack(onBackDirectRespondClicked)}
          onSkipClicked={goToNextDirectRespond}
          errors={responseErrors}
          steps={{ steps: getDirectRespondSteps() }}
        />
      ),
    },
    {
      stage: AssessmentStage.DIRECT_UPDATE,
      content: nextTargetPrompt && (
        <PromptRespondTransitionScreen
          prompt={nextTargetPrompt}
          onNextClicked={onUpdateDirectRespondClicked}
          errors={responseErrors}
          onBackClicked={asBack(() => goToStage(AssessmentStage.DIRECT_REVIEW))}
          onClearClicked={onClearDirectRespondClicked}
        />
      ),
    },
    {
      stage: AssessmentStage.DIRECT_REVIEW,
      content: targetQuestionnaire && (
        <QuestionnaireReviewScreen
          title="review your answers"
          content={targetQuestionnaire!}
          onNextClicked={onCompleted}
          nextText="finish assessment"
          onEditClicked={onEditDirectResponseClicked}
          completedFlavorText={
            'if you\'re happy with your responses click the "finish assessment" button below'
          }
        />
      ),
    },
  ];

  return (
    <BaseNavbarPage
      navbarProps={{
        ...navbarProps,
        forceLoading: stage === AssessmentStage.PROCESSING_ASSESSMENT,
      }}
    >
      {confirmDialog.dialog}
      <WizardScreen elements={getElements()} isBack={isBack} stage={stage} />
    </BaseNavbarPage>
  );
};

export default AssessmentPageComponent;
