/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  UserConnectionRequestDetail,
  UserConnectionRequestDetailFromJSON,
  UserConnectionRequestDetailFromJSONTyped,
  UserConnectionRequestDetailToJSON,
} from "./UserConnectionRequestDetail";

/**
 *
 * @export
 * @interface ConnectionStatus
 */
export interface ConnectionStatus {
  /**
   *
   * @type {string}
   * @memberof ConnectionStatus
   */
  status: ConnectionStatusStatusEnum;
  /**
   *
   * @type {UserConnectionRequestDetail}
   * @memberof ConnectionStatus
   */
  request: UserConnectionRequestDetail | null;
  /**
   *
   * @type {string}
   * @memberof ConnectionStatus
   */
  collab: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum ConnectionStatusStatusEnum {
  not_connected = "not_connected",
  request_sent = "request_sent",
  awaiting_response = "awaiting_response",
  connected = "connected",
}

export function ConnectionStatusFromJSON(json: any): ConnectionStatus {
  return ConnectionStatusFromJSONTyped(json, false);
}

export function ConnectionStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ConnectionStatus {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    status: json["status"],
    request: UserConnectionRequestDetailFromJSON(json["request"]),
    collab: json["collab"],
  };
}

export function ConnectionStatusToJSON(value?: ConnectionStatus | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    status: value.status,
    request: UserConnectionRequestDetailToJSON(value.request),
    collab: value.collab,
  };
}
