/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  AssessmentRelatedDetail,
  AssessmentRelatedDetailFromJSON,
  AssessmentRelatedDetailFromJSONTyped,
  AssessmentRelatedDetailToJSON,
} from "./AssessmentRelatedDetail";

/**
 *
 * @export
 * @interface GetAssessmentResponse
 */
export interface GetAssessmentResponse {
  /**
   *
   * @type {AssessmentRelatedDetail}
   * @memberof GetAssessmentResponse
   */
  assessment: AssessmentRelatedDetail;
  /**
   *
   * @type {number}
   * @memberof GetAssessmentResponse
   */
  num_prompts: number;
  /**
   *
   * @type {number}
   * @memberof GetAssessmentResponse
   */
  num_answers_required: number;
  /**
   *
   * @type {number}
   * @memberof GetAssessmentResponse
   */
  num_answers: number;
  /**
   *
   * @type {boolean}
   * @memberof GetAssessmentResponse
   */
  response_available: boolean;
}

export function GetAssessmentResponseFromJSON(
  json: any
): GetAssessmentResponse {
  return GetAssessmentResponseFromJSONTyped(json, false);
}

export function GetAssessmentResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAssessmentResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    assessment: AssessmentRelatedDetailFromJSON(json["assessment"]),
    num_prompts: json["num_prompts"],
    num_answers_required: json["num_answers_required"],
    num_answers: json["num_answers"],
    response_available: json["response_available"],
  };
}

export function GetAssessmentResponseToJSON(
  value?: GetAssessmentResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    assessment: AssessmentRelatedDetailToJSON(value.assessment),
    num_prompts: value.num_prompts,
    num_answers_required: value.num_answers_required,
    num_answers: value.num_answers,
    response_available: value.response_available,
  };
}
