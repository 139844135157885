import React, { useEffect, useState } from "react";
import { CommonPromptTypeScreenProps } from "./PromptRespondScreen";
import { useBusyWatcher } from "../../../util/hooks";
import FillInBlankQuestion from "./questions/FillInBlankQuestion";
import CommonQuestionScreen from "./CommonQuestionScreen";

type FillInBlankScreenProps = CommonPromptTypeScreenProps & {
  onNextClicked: (content: string, visible: boolean | null) => void;
  content?: string;
  maxContentLength?: number | null;
  placeholder?: string;
  comment?: string | null;
};

const FillInBlankScreenComponent = (props: FillInBlankScreenProps) => {
  const {
    title,
    errors,
    publishable,
    visible: visibleInput,
    onNextClicked,
    content: contentInput,
    maxContentLength,
    placeholder,
    comment,
    ...rest
  } = props;

  const [visible, setVisible] = useState<boolean | null>(visibleInput);
  const [content, setContent] = useState<string>("");
  const [busy, _] = useBusyWatcher();

  const canSubmit = (): boolean => {
    if (maxContentLength && content.length >= maxContentLength) {
      return false;
    }
    return content.length > 0;
  };

  const submit = () => {
    onNextClicked(content, visible);
  };

  const updatePromptResponseVisibility = () => {
    if (rest.prompt.response) {
      onNextClicked(content, !visible);
    }
    setVisible(!visible); // This needs to be set after the call because setState is async
  };

  useEffect(() => {
    if (contentInput !== undefined) {
      setContent(contentInput);
    } else {
      setContent("");
    }
  }, [contentInput]);

  return (
    <CommonQuestionScreen {...rest} canSubmit={canSubmit()} submit={submit}>
      <FillInBlankQuestion
        question={title}
        onUpdated={setContent}
        onVisibilityUpdated={updatePromptResponseVisibility}
        visible={visible}
        showVisibilityToggle
        errors={errors}
        publishable={publishable}
        comment={comment}
        placeholder={placeholder}
        disabled={busy}
        content={content}
        maxContentLength={maxContentLength}
      />
    </CommonQuestionScreen>
  );
};

FillInBlankScreenComponent.defaultProps = {
  maxContentLength: null,
  content: undefined,
  placeholder: undefined,
  comment: null,
};

export default FillInBlankScreenComponent;
