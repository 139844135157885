import React, { ReactElement, useState } from "react";
import { MStepsCirclesProps } from "../../ui/MStepsCircles";
import { UserProfileSummary } from "../../../api/models";
import { useBusyWatcher } from "../../../util/hooks";
import MNextButton from "../../ui/buttons/MNextButton";
import MBackButton from "../../ui/buttons/MBackButton";
import CommCoachFormScreen from "./CommCoachFormScreen";
import MSelectManyButtons from "../../ui/MSelectManyButtons";

type CommCoachGoalsScreenProps = {
  progress: MStepsCirclesProps;
  user: UserProfileSummary;
  onEditUserClicked: () => void;
  topic: string;
  onEditTopicClicked: () => void;
  options: string[];
  goals?: string[] | null;
  onGoalsSet: (goals: string[]) => void;
  onBackClicked: () => void;
};

const CommCoachGoalsScreenComponent = (props: CommCoachGoalsScreenProps) => {
  const {
    progress,
    user,
    onEditUserClicked,
    topic,
    onEditTopicClicked,
    goals: goalsInput,
    onGoalsSet,
    onBackClicked,
    options,
  } = props;

  const [goals, setGoals] = useState<string[]>(goalsInput ?? []);
  const [busy, _] = useBusyWatcher();

  const getButtons = (): ReactElement[] => [
    <MNextButton
      kind="primary"
      key="next-button"
      onClick={() => onGoalsSet(goals)}
      disabled={busy}
    />,
    <MBackButton
      kind="secondary"
      key="back-button"
      disabled={busy}
      onClick={onBackClicked}
    />,
  ];

  return (
    <CommCoachFormScreen
      progress={progress}
      buttons={getButtons()}
      user={user}
      onEditUserClicked={onEditUserClicked}
      topic={topic}
      onEditTopicClicked={onEditTopicClicked}
    >
      <div>what's your goal in the conversation? (optional)</div>
      <MSelectManyButtons
        options={options}
        onChange={setGoals}
        selected={goals}
        maxCount={3}
      />
    </CommCoachFormScreen>
  );
};

CommCoachGoalsScreenComponent.defaultProps = {
  goals: null,
};

export default CommCoachGoalsScreenComponent;
