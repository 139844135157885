import React, { ReactNode } from "react";
import CommonQuestion, { CommonQuestionProps } from "./CommonQuestion";
import MButton from "../../buttons/MButton";
import { classNames } from "../../../../util/strings";
import { getUniqueId } from "../../../../util/random";

type EitherOrQuestionProps = CommonQuestionProps & {
  options: [string, string][];
  selected?: string[] | null;
  disabled?: boolean;
  onUpdated: (selected: string[]) => void;
};

const EitherOrQuestionComponent = (props: EitherOrQuestionProps) => {
  const { options, selected, onUpdated, disabled, ...rest } = props;

  const updateSelected = (newEntry: string, clearEntry: string) => {
    const newSelected = [];
    if (selected) {
      newSelected.push(...selected);
    }
    if (newSelected.includes(newEntry)) {
      newSelected.splice(newSelected.indexOf(newEntry), 1);
    } else {
      newSelected.push(newEntry);
    }
    if (newSelected.includes(clearEntry)) {
      newSelected.splice(newSelected.indexOf(clearEntry), 1);
    }
    onUpdated(newSelected);
  };

  const getButtonClass = (
    isSelected: boolean,
    eitherSelected: boolean
  ): string | null => {
    if (isSelected) {
      return "font-bold";
    }
    if (eitherSelected) {
      return "line-through opacity-50";
    }
    return null;
  };

  const getKey = (left: string, right: string, index: number): string => {
    let leftKey = left;
    let rightKey = right;
    if (left === "") {
      leftKey = getUniqueId();
    }
    if (right === "") {
      rightKey = getUniqueId();
    }
    return `${leftKey}-${rightKey}-${index}`;
  };

  const getRow = (entry: [string, string], index: number): ReactNode => {
    const [left, right] = entry;
    const leftSelected = selected ? selected.includes(left) : false;
    const rightSelected = selected ? selected.includes(right) : false;
    const eitherSelected = leftSelected || rightSelected;
    const rowKey = getKey(left, right, index);
    return (
      <div key={rowKey} className="grid grid-cols-9">
        <MButton
          kind={leftSelected ? "primary" : "secondary"}
          className={classNames(
            "col-span-4 rounded-md min-h-[2.5rem]",
            getButtonClass(leftSelected, eitherSelected)
          )}
          onClick={() => {
            if (left === "" || right === "") {
              return;
            }
            updateSelected(left, right);
          }}
          disabled={disabled}
        >
          {left === "" ? " " : left}
        </MButton>
        <div className="col-span-1 w-full text-center flex flex-col justify-center text-m-gray">
          vs
        </div>
        <MButton
          kind={rightSelected ? "primary" : "secondary"}
          className={classNames(
            "col-span-4 rounded-md min-h-[2.5rem]",
            getButtonClass(rightSelected, eitherSelected)
          )}
          onClick={() => {
            if (right === "" || right === "") {
              return;
            }
            updateSelected(right, left);
          }}
          disabled={disabled}
        >
          {right === "" ? " " : right}
        </MButton>
      </div>
    );
  };

  return (
    <CommonQuestion comment="pick 1 from each row below" {...rest}>
      <div className="flex flex-col gap-2">
        {options.map((o, i) => getRow(o, i))}
      </div>
    </CommonQuestion>
  );
};

EitherOrQuestionComponent.defaultProps = {
  selected: null,
  disabled: false,
};

export default EitherOrQuestionComponent;
