import React, { useEffect, useState } from "react";
import {
  ListPromptResponsesForGlimpseResponse,
  PromptResponseRelatedDetail,
  SetPromptResponsesForGlimpseRequest,
} from "../../../../api/models";
import { useBusyWatcher } from "../../../../util/hooks";
import FormScreen from "../../../ui/screens/FormScreen";
import MErrors from "../../../ui/MErrors";
import MSubtext from "../../../ui/MSubtext";
import PromptDisplayCard from "../../../ui/cards/prompt_display/PromptDisplayCard";

type PickGlimpseQuestionsScreenProps = {
  glimpseId: string;
  selected: ListPromptResponsesForGlimpseResponse;
  errors: string[];
  onNextClicked: (newSelected: SetPromptResponsesForGlimpseRequest) => void;
  onBackClicked: () => void;
};

const PickGlimpseQuestionsScreenComponent = (
  props: PickGlimpseQuestionsScreenProps
) => {
  const {
    glimpseId,
    selected: selectedInput,
    errors: errorsInput,
    onNextClicked: onNextClickedInput,
    onBackClicked,
  } = props;

  const [possible, setPossible] = useState<PromptResponseRelatedDetail[]>([]);
  const [max, setMax] = useState<number>(0);
  const [selected, setSelected] = useState<string[]>([]);
  const [busy, _] = useBusyWatcher();

  const onNextClicked = () => {
    onNextClickedInput({
      glimpse: glimpseId,
      prompt_responses: selected,
    });
  };

  const onToggleVisibilityClicked = (toggled: PromptResponseRelatedDetail) => {
    const newSelected: string[] = [...selected];
    const toggleGuid = toggled.guid!;
    if (newSelected.includes(toggleGuid)) {
      newSelected.splice(newSelected.indexOf(toggleGuid), 1);
    } else {
      newSelected.push(toggleGuid);
    }
    setSelected(newSelected);
  };

  useEffect(() => {
    const newPossible: PromptResponseRelatedDetail[] = [];
    const newSelected: string[] = [];
    for (let i = 0; i < selectedInput.prompt_responses.length; i += 1) {
      newPossible.push(selectedInput.prompt_responses[i].prompt_response);
      if (selectedInput.prompt_responses[i].selected) {
        newSelected.push(
          selectedInput.prompt_responses[i].prompt_response.guid!
        );
      }
    }
    setPossible(newPossible);
    setSelected(newSelected);
    setMax(selectedInput.max_selected_count);
  }, []);

  return (
    <FormScreen
      title="select questions"
      comment={`you can pick up to ${max} responses to display on your glimpse`}
      nextDisabled={busy}
      onNextClicked={onNextClicked}
      backDisabled={busy}
      onBackClicked={onBackClicked}
      stacked
    >
      <MErrors errors={errorsInput} />
      <div className="flex flex-col gap-2">
        {possible.length > 0 ? (
          possible.map((promptResponse) => (
            <PromptDisplayCard
              prompt={{
                prompt: promptResponse.prompt!,
                response: promptResponse,
              }}
              key={promptResponse.guid}
              onToggleVisibilityClicked={() =>
                onToggleVisibilityClicked(promptResponse)
              }
              isVisible={selected.includes(promptResponse.guid!)}
              visibilityCopy="include on glimpse"
              toggleVisibilityDisabled={
                busy ||
                (selected.length >= max &&
                  !selected.includes(promptResponse.guid!))
              }
              visibilityToggleDisabledTooltip={
                selected.length >= max
                  ? `you can only select up to ${max} questions`
                  : null
              }
            />
          ))
        ) : (
          <MSubtext
            text="you haven't answered any questions. once you do, they will be listed here."
            className="text-center"
          />
        )}
      </div>
    </FormScreen>
  );
};

export default PickGlimpseQuestionsScreenComponent;
