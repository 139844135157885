import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { MNavProps } from "../../../ui/nav/MNav";
import { useBusyWatcher } from "../../../../util/hooks";
import { useWrappedConnector } from "../../../../api/connector";
import { useAsyncConfirmationDialog } from "../../../dialogs/hooks";
import BaseNavbarPage from "../../BaseNavbarPage";
import {
  PromptWithResponse,
  PublishablePromptWithResponse,
  QuestionOperationsResponse,
} from "../../../../api/models";
import { errorMessagesForKeyFromResponse } from "../../../../api/helpers";
import ViewSingleQuestionScreen from "./ViewSingleQuestionScreen";
import { addQueryParamToPath } from "../../../../util/url";

type QuestionsPageProps = {
  navbarProps: MNavProps;
  myQuestionsUrl: string;
  editQuestionUrl: string;
  promptId: string;
};

const ViewSingleQuestionPageComponent = (props: QuestionsPageProps) => {
  const { navbarProps, myQuestionsUrl, editQuestionUrl, promptId } = props;

  const [errors, setErrors] = useState<string[]>([]);
  const [question, setQuestion] =
    useState<PublishablePromptWithResponse | null>(null);
  const [questionOperations, setQuestionOperations] =
    useState<QuestionOperationsResponse | null>(null);

  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);
  const confirmDialog = useAsyncConfirmationDialog();

  const loadQuestionOperations = async () => {
    const response = await connector.getQuestionOperations({
      prompt: promptId,
    });
    setQuestionOperations(response.c!);
  };

  const loadQuestionWithResponse = async () => {
    const response = await connector.getPromptWithResponse(promptId);
    setQuestion(response.c!);
  };

  const loadQuestion = async () => {
    setErrors([]);
    try {
      await Promise.all([loadQuestionWithResponse(), loadQuestionOperations()]);
    } catch (r: any) {
      const parsed = await errorMessagesForKeyFromResponse(r, "prompt", true);
      setErrors(parsed);
    }
  };

  const onDeleteQuestionClicked = async (
    promptResponse: PromptWithResponse
  ) => {
    if (!questionOperations?.can_delete && !questionOperations?.can_anonymize) {
      toast.error("you do not have permission to delete this question");
      return;
    }
    const result = await confirmDialog.confirm({
      title: "delete question?",
      body: `are you sure you want to delete your question "${promptResponse.prompt.question}"? this action cannot be undone.`,
    });
    if (!result) {
      return;
    }
    try {
      await connector.deleteQuestion({
        data: { prompt: promptResponse.prompt.guid! },
      });
      window.location.href = myQuestionsUrl;
    } catch (r: any) {
      const parsed = await errorMessagesForKeyFromResponse(r, "prompt", true);
      setErrors(parsed);
    }
  };

  const onVoteClicked = async () => {
    const userVoted = question?.user_voted;
    try {
      await connector.voteOnQuestion({
        prompt: promptId,
        is_upvote: !userVoted,
      });
      await loadQuestion();
    } catch (r: any) {
      const parsed = await errorMessagesForKeyFromResponse(r, "prompt", true);
      setErrors(parsed);
    }
  };

  const onEditQuestionClicked = async () => {
    if (!questionOperations?.can_update) {
      toast.error("you do not have permission to edit this response");
      return;
    }
    window.location.href = addQueryParamToPath(
      editQuestionUrl,
      "prompt",
      promptId ?? ""
    );
  };

  const onUpdateResponseClicked = async (
    response: any,
    visible: boolean | null
  ) => {
    setErrors([]);
    try {
      await connector.submitPromptResponse({
        prompt: promptId,
        publish: visible,
        response,
      });
      toast.success("response submitted");
      await loadQuestion();
    } catch (r: any) {
      const parsed = await errorMessagesForKeyFromResponse(r, "response", true);
      setErrors(parsed);
    }
  };

  const onClearResponseClicked = async () => {
    try {
      await connector.clearPromptResponse(promptId);
      toast.success("cleared response");
      await loadQuestion();
    } catch (r: any) {
      const parsed = await errorMessagesForKeyFromResponse(r, "response", true);
      setErrors(parsed);
    }
  };

  const onTagClicked = (tag: string) => {
    window.location.href = addQueryParamToPath(
      myQuestionsUrl,
      "tag",
      tag ?? ""
    );
  };

  useEffect(() => {
    loadQuestion();
  }, []);

  return (
    <BaseNavbarPage navbarProps={navbarProps}>
      {confirmDialog.dialog}
      {question && questionOperations ? (
        <ViewSingleQuestionScreen
          question={question}
          questionOperations={questionOperations}
          onEditClicked={onEditQuestionClicked}
          onDeleteClicked={onDeleteQuestionClicked}
          onVoteClicked={onVoteClicked}
          onUpdateResponseClicked={onUpdateResponseClicked}
          onClearResponseClicked={onClearResponseClicked}
          onTagClicked={onTagClicked}
          onBackClicked={() => window.history.back()}
          errors={errors}
        />
      ) : (
        <Skeleton className="flex flex-col gap-1" />
      )}
    </BaseNavbarPage>
  );
};

export default ViewSingleQuestionPageComponent;
