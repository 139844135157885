/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CommCoachSingleEntryRelatedDetail,
  CommCoachSingleEntryRelatedDetailFromJSON,
  CommCoachSingleEntryRelatedDetailFromJSONTyped,
  CommCoachSingleEntryRelatedDetailToJSON,
} from "./CommCoachSingleEntryRelatedDetail";

/**
 *
 * @export
 * @interface CommonSingleEntryResponse
 */
export interface CommonSingleEntryResponse {
  /**
   *
   * @type {CommCoachSingleEntryRelatedDetail}
   * @memberof CommonSingleEntryResponse
   */
  entry: CommCoachSingleEntryRelatedDetail;
  /**
   *
   * @type {boolean}
   * @memberof CommonSingleEntryResponse
   */
  ready_for_submission: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof CommonSingleEntryResponse
   */
  submission_errors: Array<string>;
}

export function CommonSingleEntryResponseFromJSON(
  json: any
): CommonSingleEntryResponse {
  return CommonSingleEntryResponseFromJSONTyped(json, false);
}

export function CommonSingleEntryResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommonSingleEntryResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    entry: CommCoachSingleEntryRelatedDetailFromJSON(json["entry"]),
    ready_for_submission: json["ready_for_submission"],
    submission_errors: json["submission_errors"],
  };
}

export function CommonSingleEntryResponseToJSON(
  value?: CommonSingleEntryResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    entry: CommCoachSingleEntryRelatedDetailToJSON(value.entry),
    ready_for_submission: value.ready_for_submission,
    submission_errors: value.submission_errors,
  };
}
