import React, { ReactNode } from "react";
import MWellDeleteButton from "../../../ui/wells/MWellDeleteButton";
import { useBusyWatcher } from "../../../../util/hooks";
import MCard from "../../../ui/cards/MCard";
import { titleText } from "../../../../util/style";
import MShowMoreText from "../../../ui/MShowMoreText";
import MWellViewButton from "../../../ui/wells/MWellViewButton";
import MAvatar from "../../../ui/MAvatar";
import { GroupQuestionAndAnswer, UserGroup } from "../../../../api/models";

type GroupInsightCardProps = {
  groupInsight: GroupQuestionAndAnswer;
  group: UserGroup;
  simplify: boolean;
  hideDeleteButton: boolean;
  onViewInsightClick: () => void;
  onDeleteClick: () => void;
};

const GroupInsightCardComponent = (props: GroupInsightCardProps) => {
  const {
    groupInsight,
    group,
    simplify,
    hideDeleteButton,
    onDeleteClick,
    onViewInsightClick,
  } = props;

  const [busy, _] = useBusyWatcher();

  const buttons: ReactNode = hideDeleteButton
    ? []
    : [
        <MWellDeleteButton
          className="shrink ml-2 hover:text-red-500"
          disabled={busy}
          onClick={onDeleteClick}
        />,
      ];

  return (
    <MCard
      footer={
        hideDeleteButton ? null : (
          <div className="flex flex-row gap-3 items-center">
            {buttons}
            <div className="grow" />
          </div>
        )
      }
    >
      <div className="flex flex-row gap-2">
        <div className="flex flex-col gap-2 grow">
          <div className="flex flex-row justify-between items-start gap-2">
            <div className="flex flex-col gap-2">
              <div className={titleText}>{group.name}</div>
              {!simplify && (
                <>
                  <div className="text-xs font-light">
                    {group.members.length} members
                  </div>
                  <div className="flex flex-row gap-2">
                    {group.members &&
                      group.members.map((user) => (
                        <MAvatar
                          picUrl={user.summary.photo || null}
                          alt={
                            user.summary.name ||
                            user.summary.username ||
                            "user picture"
                          }
                          size="mini"
                        />
                      ))}
                  </div>
                </>
              )}
            </div>
          </div>
          {groupInsight.question && (
            <div className="text-m">
              <MShowMoreText content={groupInsight.question} />
            </div>
          )}
        </div>
        <MWellViewButton
          onClick={onViewInsightClick}
          tooltip={`view insight about '${group.name}'`}
        />
      </div>
    </MCard>
  );
};

export default GroupInsightCardComponent;
