import React, { ReactElement, useState } from "react";
import { UserProfileSummary } from "../../../api/models";
import { MStepsCirclesProps } from "../../ui/MStepsCircles";
import CommCoachFormScreen from "./CommCoachFormScreen";
import MNextButton from "../../ui/buttons/MNextButton";
import { useBusyWatcher } from "../../../util/hooks";
import MBackButton from "../../ui/buttons/MBackButton";
import MTextArea from "../../ui/MTextArea";

type CommCoachTopicScreenProps = {
  progress: MStepsCirclesProps;
  user: UserProfileSummary;
  onEditUserClicked: () => void;
  topic?: string | null;
  onTopicSet: (topic: string) => void;
  onBackClicked: () => void;
};

const CommCoachTopicScreenComponent = (props: CommCoachTopicScreenProps) => {
  const {
    progress,
    user,
    onEditUserClicked,
    topic: topicInput,
    onTopicSet,
    onBackClicked,
  } = props;

  const [busy, _] = useBusyWatcher();
  const [topic, setTopic] = useState<string>(topicInput || "");
  const canSubmit = (): boolean => {
    if (topic.length === 0) {
      return false;
    }
    if (topic.length > 1024) {
      return false;
    }
    return true;
  };

  const getErrors = (): string[] => {
    const toReturn: string[] = [];
    if (topic.length > 1024) {
      toReturn.push("topic must be less than 1024 characters");
    }
    return toReturn;
  };

  const getButtons = (): ReactElement[] => [
    <MNextButton
      kind="primary"
      key="next-button"
      onClick={() => onTopicSet(topic)}
      disabled={busy || !canSubmit()}
    />,
    <MBackButton
      kind="secondary"
      key="back-button"
      disabled={busy}
      onClick={onBackClicked}
    />,
  ];

  return (
    <CommCoachFormScreen
      progress={progress}
      buttons={getButtons()}
      user={user}
      onEditUserClicked={onEditUserClicked}
    >
      <MTextArea
        value={topic}
        disabled={busy}
        errors={getErrors()}
        onChange={(e) => setTopic(e.target.value)}
        label="about..."
        placeholder="ex: I need to give some feedback about a recent project, but I don’t want to dampen their enthusiasm."
      />
    </CommCoachFormScreen>
  );
};

CommCoachTopicScreenComponent.defaultProps = {
  topic: null,
};

export default CommCoachTopicScreenComponent;
