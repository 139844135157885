import React, { ReactNode, useEffect, useState } from "react";
import { UserGroupIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import {
  ConnectionStatusStatusEnum,
  GetProfileResponse,
} from "../../../api/models";
import ProfileCard from "../../ui/cards/ProfileCard";
import MButton from "../../ui/buttons/MButton";
import ProfileVitals from "./VitalsSection";
import ProfileLinks from "./LinksSection";
import ProfilePromptResponses from "./PromptResponsesSection";
import { useBusyWatcher, useUniqueId } from "../../../util/hooks";
import BaseNavbarPage from "../BaseNavbarPage";
import { MNavProps } from "../../ui/nav/MNav";
import ProfileCompletionPushdown from "./ProfileCompletionPushdown";
import ProfileWorkHistory from "./WorkHistorySection";
import ProfileTagline from "./ProfileTagline";
import { addNextToUrl, addQueryParamToPath } from "../../../util/url";
import { userDetailsHasVitals } from "../../../util/user";
import ProfileLayoutScreen from "../../ui/screens/ProfileLayoutScreen";
import { PROFILE_WELCOME_SEEN_KEY } from "../../../util/constants";
import ProfileNextStepsDialog from "./ProfileNextStepsDialog";
import AttachmentsSection from "./AttachmentsSection";
import MEllipsisMenu from "../../ui/MEllipsisMenu";
import MTooltip from "../../ui/MTooltip";

type ProfileV2ScreenProps = {
  navbarProps: MNavProps;
  insightsUrl: string;
  askQuestionUrl: string;
  isAuthed: boolean;
  loginUrl: string;
  response?: GetProfileResponse | null;
  editPictureUrl?: string | null;
  editWorkHistoryUrl?: string | null;
  linksUrl?: string | null;
  vitalsUrl?: string | null;
  questionsUrl?: string | null;
  taglineUrl?: string | null;
  editBasicsUrl?: string | null;
  listGlimpseUrl: string;
  listInsightsUrl: string;
  glimpseLoomUrl: string;
  insightsLoomUrl: string;
  attachmentsUrl?: string | null;
  onAddToMyPeopleClicked: () => void;
  onRespondToConnectRequestClicked: () => void;
  onRemoveFromMyPeopleClicked: () => void;
};

const ProfileV2ScreenComponent = (props: ProfileV2ScreenProps) => {
  const {
    response,
    editPictureUrl,
    navbarProps,
    editWorkHistoryUrl,
    linksUrl,
    vitalsUrl,
    questionsUrl,
    taglineUrl,
    editBasicsUrl,
    insightsUrl,
    askQuestionUrl,
    isAuthed,
    loginUrl,
    listGlimpseUrl,
    listInsightsUrl,
    glimpseLoomUrl,
    insightsLoomUrl,
    attachmentsUrl,
    onAddToMyPeopleClicked,
    onRespondToConnectRequestClicked,
    onRemoveFromMyPeopleClicked,
  } = props;

  const [showWelcomeDialog, setShowWelcomeDialog] = useState<boolean>(false);
  const [busy, _] = useBusyWatcher();

  const isSelf = Boolean(response && response.self);
  const uniqueTooltipId = useUniqueId();

  const welcomeSeen = Boolean(localStorage.getItem(PROFILE_WELCOME_SEEN_KEY));

  useEffect(() => {
    if (response) {
      setShowWelcomeDialog(isSelf && !welcomeSeen);
    }
  }, [response]);

  const isInProfileCompletion = Boolean(
    isSelf &&
      response!.self!.completion_stages &&
      response!.self!.completion_stages.length > 0
  );

  const onWelcomeDialogClosed = (dontShow: boolean) => {
    if (dontShow) {
      localStorage.setItem(PROFILE_WELCOME_SEEN_KEY, "true");
    }
    setShowWelcomeDialog(false);
  };

  const hasProfileButtons = !isSelf || !isInProfileCompletion;

  const getPushdown = (): ReactNode => {
    if (!isInProfileCompletion) {
      return null;
    }
    return (
      <ProfileCompletionPushdown
        completionStages={response!.self!.completion_stages!}
      />
    );
  };

  const showPromptResponses = (): boolean => {
    if (isSelf && !isInProfileCompletion) {
      return true;
    }
    if (response?.profile?.sections && response.profile.sections.length > 0) {
      return true;
    }
    return false;
  };

  const showWorkHistory = (): boolean => {
    if (isSelf && !isInProfileCompletion) {
      return true;
    }
    if (
      response?.profile?.work_history &&
      response.profile.work_history.length > 0
    ) {
      return true;
    }
    return false;
  };

  const showLinks = (): boolean => {
    if (isSelf && !isInProfileCompletion) {
      return true;
    }
    if (response?.profile?.socials && response.profile.socials.length > 0) {
      return true;
    }
    return false;
  };

  const showTagline = (): boolean => {
    if (isSelf && !isInProfileCompletion) {
      return true;
    }
    if (response?.profile?.user.headline || response?.profile?.user.bio) {
      return true;
    }
    return false;
  };

  const showVitals = (): boolean => {
    if (isSelf) {
      return true;
    }
    if (response && userDetailsHasVitals(response.profile.user)) {
      return true;
    }
    return false;
  };

  const showAttachments = (): boolean => {
    if (isSelf && !isInProfileCompletion) {
      return true;
    }
    if (response && response.profile.attachments.resume) {
      return true;
    }
    return false;
  };

  const getProfileCard = (): ReactNode =>
    response && (
      <ProfileCard
        user={response.profile.user}
        editPictureUrl={editPictureUrl}
        editBasicsUrl={editBasicsUrl}
      />
    );

  const getButtonContents = (): ReactNode => {
    if (!response) {
      return null;
    }

    // If the user is viewing their own profile and they have completed
    // their profile, we allow them to ask questions about themselves.

    if (isSelf) {
      if (!isInProfileCompletion) {
        return (
          <MButton
            kind="primary"
            className="grow"
            disabled={busy}
            href={askQuestionUrl}
          >
            ask about yourself
          </MButton>
        );
      }
      return null;
    }

    if (!response.is_authed) {
      return (
        <MButton
          kind="primary"
          className="grow"
          disabled={busy}
          href={addNextToUrl(loginUrl)}
          icon={
            <UserPlusIcon className="h-6 w-5 flex-none" aria-hidden="true" />
          }
        >
          add to my people
        </MButton>
      );
    }

    if (!response.is_onboarded) {
      return (
        <MButton
          kind="primary"
          className="grow"
          disabled
          data-tooltip-id={uniqueTooltipId}
          data-tooltip-content="you can't add anyone until you've finished onboarding"
          icon={
            <UserPlusIcon className="h-6 w-5 flex-none" aria-hidden="true" />
          }
        >
          add to my people
        </MButton>
      );
    }

    // If the response included connection information then we want to show
    // buttons based on connection status.

    if (response!.connection) {
      switch (response.connection.status) {
        case ConnectionStatusStatusEnum.not_connected: {
          return (
            <MButton
              kind="primary"
              className="grow"
              disabled={busy}
              onClick={onAddToMyPeopleClicked}
              icon={
                <UserPlusIcon
                  className="h-6 w-5 flex-none"
                  aria-hidden="true"
                />
              }
            >
              add to my people
            </MButton>
          );
        }
        case ConnectionStatusStatusEnum.connected: {
          return (
            <>
              <MButton
                kind="primary"
                className="grow"
                disabled={busy}
                href={isAuthed ? insightsUrl : addNextToUrl(loginUrl)}
              >
                ask about {response?.profile.user.name}
              </MButton>
              <MEllipsisMenu>
                <div className="flex flex-col items-center text-m-dark-gray text-sm">
                  {response?.connection?.collab ? (
                    <a
                      type="button"
                      className="hover:bg-primary-light p-2 w-full text-left"
                      href={addQueryParamToPath(
                        listInsightsUrl,
                        "collab",
                        response.connection!.collab!
                      )}
                    >
                      view relationship analysis
                    </a>
                  ) : null}
                  <button
                    type="button"
                    className="hover:bg-primary-light p-2 w-full text-left"
                    onClick={onRemoveFromMyPeopleClicked}
                  >
                    remove from my people
                  </button>
                </div>
              </MEllipsisMenu>
            </>
          );
        }
        case ConnectionStatusStatusEnum.request_sent: {
          return (
            <MButton
              kind="secondary"
              className="grow"
              disabled
              icon={
                <UserPlusIcon
                  className="h-6 w-5 flex-none"
                  aria-hidden="true"
                />
              }
            >
              requested
            </MButton>
          );
        }
        case ConnectionStatusStatusEnum.awaiting_response: {
          return (
            <MButton
              kind="primary"
              className="grow"
              disabled={busy}
              onClick={onRespondToConnectRequestClicked}
              icon={
                <UserGroupIcon
                  className="h-6 w-5 flex-none"
                  aria-hidden="true"
                />
              }
            >
              respond to request
            </MButton>
          );
        }
        default: {
          throw new Error(
            `Unsupported connection status ${response.connection.status}`
          );
        }
      }
    }

    return null;

    // If we've gotten to this point then we know...
    // - The user is not viewing their own profile
    // - We have no connection information
    // Thus we show the default "ask about {name}" button.
    // We will turn this into the "add to my people" button once we're fully
    // launching
  };

  const getProfileButtonsSection = (): ReactNode =>
    hasProfileButtons && (
      <div className="flex flex-row gap-3 items-center">
        {getButtonContents()}
      </div>
    );

  const getAttachmentsSection = (): ReactNode =>
    showAttachments() && (
      <AttachmentsSection
        className="my-3"
        attachments={response!.profile.attachments}
        editUrl={attachmentsUrl}
        location="profile"
      />
    );

  const getVitalsSection = (): ReactNode =>
    showVitals() && (
      <ProfileVitals
        className="my-3"
        user={response!.profile.user}
        editUrl={vitalsUrl}
      />
    );

  const getLinksSection = (): ReactNode =>
    showLinks() && (
      <ProfileLinks
        className="my-3"
        links={response!.profile.socials}
        editUrl={linksUrl}
      />
    );

  const getTaglineSection = (): ReactNode =>
    showTagline() && (
      <ProfileTagline
        bio={response?.profile.user.bio}
        headline={response?.profile.user.headline}
        editUrl={taglineUrl}
      />
    );

  const getWorkHistorySection = (): ReactNode =>
    showWorkHistory() && (
      <ProfileWorkHistory
        className="my-3"
        workHistory={response!.profile.work_history}
        editUrl={editWorkHistoryUrl}
      />
    );

  const getQuestionsSection = (): ReactNode =>
    showPromptResponses() && (
      <ProfilePromptResponses
        className="my-3"
        promptResponses={response!.profile.sections}
        editUrl={questionsUrl}
      />
    );

  return (
    <>
      <BaseNavbarPage
        navbarProps={{ ...navbarProps, showSearch: true }}
        pushdown={getPushdown()}
        pushdownClassname="bg-m-white"
        fullWidth
      >
        <ProfileLayoutScreen
          loading={!response}
          leftColumn={
            <>
              {getProfileCard()}
              {getProfileButtonsSection()}
              {getVitalsSection()}
              {getLinksSection()}
              {getAttachmentsSection()}
            </>
          }
          rightTopColumn={getTaglineSection()}
          bottomLeftColumn={getWorkHistorySection()}
          bottomRightColumn={getQuestionsSection()}
          mobileColumn={
            <>
              {getProfileCard()}
              {getProfileButtonsSection()}
              {getVitalsSection()}
              {getLinksSection()}
              {getAttachmentsSection()}
              {getTaglineSection()}
              {getWorkHistorySection()}
              {getQuestionsSection()}
            </>
          }
        />
      </BaseNavbarPage>
      <ProfileNextStepsDialog
        show={showWelcomeDialog}
        onClose={onWelcomeDialogClosed}
        listGlimpseUrl={listGlimpseUrl}
        listInsightsUrl={listInsightsUrl}
        glimpseLoomUrl={glimpseLoomUrl}
        insightsLoomUrl={insightsLoomUrl}
      />
      <MTooltip place="bottom" id={uniqueTooltipId} />
    </>
  );
};

ProfileV2ScreenComponent.defaultProps = {
  response: null,
  editPictureUrl: null,
  editWorkHistoryUrl: null,
  linksUrl: null,
  vitalsUrl: null,
  questionsUrl: null,
  taglineUrl: null,
  editBasicsUrl: null,
  attachmentsUrl: null,
};

export default ProfileV2ScreenComponent;
