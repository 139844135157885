import React from "react";
import MEditIconButton from "./MEditIconButton";
import MTooltip from "../MTooltip";
import { classNames } from "../../../util/strings";
import { useUniqueId } from "../../../util/hooks";

type MCornerEditButtonProps = {
  editUrl?: string | null;
  tooltip?: string | null;
};

const MCornerEditButtonComponent = (props: MCornerEditButtonProps) => {
  const { editUrl, tooltip } = props;
  const uniqueId = useUniqueId();

  return (
    <div
      className={classNames(
        "absolute right-0 top-0 block h-6 w-6",
        "-translate-y-1/3 translate-x-1/3 transform rounded-full shadow",
        editUrl ? null : "invisible"
      )}
    >
      <MEditIconButton
        kind="primary"
        href={editUrl ?? ""}
        data-tooltip-id={uniqueId}
        data-tooltip-content={tooltip}
      />
      <MTooltip place="bottom" id={uniqueId} />
    </div>
  );
};

MCornerEditButtonComponent.defaultProps = {
  editUrl: null,
  tooltip: null,
};

export default MCornerEditButtonComponent;
