import React, { ReactNode } from "react";
import { PromptWithResponse } from "../../../../api/models";
import MultiSelectDisplayCard from "./MultiSelectDisplayCard";
import FillInBlankDisplayCard from "./FillInBlankDisplayCard";
import FillInBlankListDisplayCard from "./FillInBlankListDisplayCard";
import EitherOrDisplayCard from "./EitherOrDisplayCard";
import ChoicesDisplayCard from "./ChoicesDisplayCard";
import { MCardType } from "../MCard";

export type PromptDisplayCardProps = {
  prompt: PromptWithResponse;
  onEditClicked?: (() => void) | null;
  editDisabled?: boolean;
  onDeleteClicked?: (() => void) | null;
  deleteDisabled?: boolean;
  onToggleVisibilityClicked?: (() => void) | null;
  toggleVisibilityDisabled?: boolean;
  visibilityCopy?: string;
  isVisible?: boolean;
  visibilityToggleDisabledTooltip?: string | null;
  type?: MCardType;
  collapsible?: boolean;
  visibleStatus?: ReactNode;
};

const PromptDisplayCardComponent = (props: PromptDisplayCardProps) => {
  const { prompt } = props;

  switch (prompt.prompt.question_type) {
    case "multi_select": {
      return <MultiSelectDisplayCard {...props} />;
    }
    case "fill_in_blank": {
      return <FillInBlankDisplayCard {...props} />;
    }
    case "fill_in_blank_list": {
      return <FillInBlankListDisplayCard {...props} />;
    }
    case "either_or": {
      return <EitherOrDisplayCard {...props} />;
    }
    case "choices": {
      return <ChoicesDisplayCard {...props} />;
    }
    default: {
      return <div>Not Supported {prompt.prompt.question_type}</div>;
    }
  }
};

PromptDisplayCardComponent.defaultProps = {
  onEditClicked: null,
  editDisabled: false,
  onDeleteClicked: null,
  deleteDisabled: false,
  onToggleVisibilityClicked: null,
  toggleVisibilityDisabled: false,
  type: "regular" as MCardType,
  isVisible: true,
  collapsible: false,
  visibilityCopy: "show on profile",
  visibilityToggleDisabledTooltip: null,
  visibleStatus: null,
};

export default PromptDisplayCardComponent;
