import React from "react";

type MPaginationDescriptionProps = {
  startIndex: number;
  pageSize: number;
  total: number;
  copy?: string;
};

const MPaginationDescriptionComponent = (
  props: MPaginationDescriptionProps
) => {
  const { startIndex, pageSize, total, copy } = props;

  const getToNumber = (): number => {
    const toNumber = startIndex + pageSize;
    return toNumber > total ? total : toNumber;
  };

  return (
    <div>
      <p className="text-sm text-m-dark-gray">
        showing&nbsp;
        <span className="font-semibold">{startIndex + 1}</span>
        &nbsp;to&nbsp;
        <span className="font-semibold">{getToNumber()}</span>
        &nbsp;of&nbsp;
        <span className="font-semibold">{total}</span>
        &nbsp;{copy}
      </p>
    </div>
  );
};

MPaginationDescriptionComponent.defaultProps = {
  copy: "results",
};

export default MPaginationDescriptionComponent;
