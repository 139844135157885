import React, { ReactElement } from "react";
import { CommonSingleUserQuestionInsightResponse } from "../../../api/models";
import { useBusyWatcher } from "../../../util/hooks";
import MBackButton from "../../ui/buttons/MBackButton";
import CenterColumnStickyButtonScreen from "../../ui/screens/CenterColumnStickyButtonScreen";
import SingleUserInsightCard from "./SingleUserInsightCard";
import MNextButton from "../../ui/buttons/MNextButton";
import MMarkdown from "../../ui/MMarkdown";

type ViewSingleUserInsightScreenProps = {
  insight: CommonSingleUserQuestionInsightResponse;
  onDoneClicked?: (() => void) | null;
  onBackClicked?: (() => void) | null;
};

const ViewSingleUserInsightScreenComponent = (
  props: ViewSingleUserInsightScreenProps
) => {
  const { insight, onDoneClicked, onBackClicked } = props;

  const [busy, _] = useBusyWatcher();

  const getButtons = (): ReactElement[] => {
    const toReturn: ReactElement[] = [];
    if (onDoneClicked) {
      toReturn.push(
        <MNextButton
          kind="primary"
          key="done-button"
          onClick={onDoneClicked}
          disabled={busy}
          text="done"
        />
      );
    }
    if (onBackClicked) {
      toReturn.push(
        <MBackButton
          kind="secondary"
          key="back-button"
          onClick={onBackClicked}
          disabled={busy}
        />
      );
    }
    return toReturn;
  };

  return (
    <CenterColumnStickyButtonScreen buttons={getButtons()} stacked fullWidth>
      <div className="flex flex-col gap-5">
        <SingleUserInsightCard insight={insight.insight} />
        <div>
          <div className="font-bold mb-1">{insight.insight.question}</div>
          <MMarkdown content={insight.content!} />
        </div>
      </div>
    </CenterColumnStickyButtonScreen>
  );
};

ViewSingleUserInsightScreenComponent.defaultProps = {
  onDoneClicked: null,
  onBackClicked: null,
};

export default ViewSingleUserInsightScreenComponent;
