import React from "react";
import { classNames } from "../../../../util/strings";
import { dateToYMD } from "../../../../util/date";

type TimelineProps = {
  start?: Date | null;
  end?: Date | null;
  className?: string | null;
};

const TimelineComponent = (props: TimelineProps) => {
  const { start, end, className } = props;

  const hasDate = start || end;

  return (
    <div
      className={classNames(
        "flex flex-row gap-2 text-m-dark-gray",
        className,
        !hasDate ? "hidden" : null
      )}
    >
      <div>{start ? dateToYMD(start) : "present"}</div>
      <div>to</div>
      <div>{end ? dateToYMD(end) : "present"}</div>
    </div>
  );
};

TimelineComponent.defaultProps = {
  start: null,
  end: null,
  className: null,
};

export default TimelineComponent;
