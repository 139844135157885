import React from "react";
import TimeAgo from "react-timeago";

type MTimeAgoProps = {
  date: Date;
  className?: string;
};

const MTimeAgoComponent = (props: MTimeAgoProps) => {
  const { date, className } = props;
  return (
    <div className={className}>
      <TimeAgo date={date} />
    </div>
  );
};

MTimeAgoComponent.defaultProps = {
  className: undefined,
};

export default MTimeAgoComponent;
