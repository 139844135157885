import React, { useEffect, useState } from "react";
import { PromptRespondScreenProps } from "./PromptRespondScreen";
import {
  MultiSelectPromptMeta,
  MultiSelectPromptResponseMeta,
} from "../../../api/types";
import MultiSelectScreen from "./MultiSelectScreen";

const PromptRespondMultiSelectScreenComponent = (
  props: PromptRespondScreenProps
) => {
  const { prompt, onNextClicked, ...rest } = props;

  const [title, setTitle] = useState<string>("");
  const [minNumSelectable, setMinNumSelectable] = useState<number | null>(null);
  const [maxNumSelectable, setMaxNumSelectable] = useState<number | null>(null);
  const [numSelectable, setNumSelectable] = useState<number | null>(null);
  const [options, setOptions] = useState<string[]>([]);
  const [selected, setSelected] = useState<number[]>([]);

  useEffect(() => {
    const promptMeta = prompt.prompt.meta as MultiSelectPromptMeta;
    setTitle(prompt.prompt.question);
    if (promptMeta.min_answer_count) {
      setMinNumSelectable(promptMeta.min_answer_count);
    }
    if (promptMeta.max_answer_count) {
      setMaxNumSelectable(promptMeta.max_answer_count);
    }
    if (promptMeta.answer_count) {
      setNumSelectable(promptMeta.answer_count);
    }
    setOptions(promptMeta.choices);
    if (prompt.response) {
      const promptResponseMeta = prompt.response
        .meta as MultiSelectPromptResponseMeta;
      const newSelected: number[] = [];
      for (let i = 0; i < promptMeta.choices.length; i += 1) {
        if (promptResponseMeta.response.includes(promptMeta.choices[i])) {
          newSelected.push(i);
        }
      }
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  }, [prompt.prompt.guid, prompt.response?.meta]);

  const innerOnNextClicked = (
    newSelected: number[],
    visible: boolean | null
  ) => {
    const selectedOptions: string[] = [];
    for (let i = 0; i < newSelected.length; i += 1) {
      selectedOptions.push(options[newSelected[i]]);
    }
    onNextClicked(selectedOptions, visible);
  };

  return (
    <MultiSelectScreen
      title={title}
      minNumSelectable={minNumSelectable}
      maxNumSelectable={maxNumSelectable}
      numSelectable={numSelectable}
      options={options}
      onNextClicked={innerOnNextClicked}
      selected={selected}
      visible={prompt.visible ?? true}
      publishable={prompt.publishable}
      prompt={prompt}
      {...rest}
    />
  );
};

export default PromptRespondMultiSelectScreenComponent;
