import React, { useState } from "react";

import Skeleton from "react-loading-skeleton";
import { GlimpseSummary, ListGlimpsesResponse } from "../../../../api/models";
import { MNavProps } from "../../../ui/nav/MNav";
import BaseNavbarPage from "../../BaseNavbarPage";
import MTitle from "../../../ui/MTitle";
import CenterColumnScreen from "../../../ui/screens/CenterColumnScreen";
import { clickableText, commentText } from "../../../../util/style";
import { classNames } from "../../../../util/strings";
import MPlusButton from "../../../ui/buttons/MPlusButton";
import { useBusyWatcher } from "../../../../util/hooks";
import GlimpseSummaryCard from "./GlimpseSummaryCard";
import { useAsyncConfirmationDialog } from "../../../dialogs/hooks";
import SubscriptionUpsellDialog from "../../../dialogs/SubscriptionUpsellDialog";

type ListGlimpsesScreenProps = {
  glimpses: ListGlimpsesResponse | null;
  navbarProps: MNavProps;
  createGlimpseUrl: string;
  viewGlimpseUrl: string;
  editGlimpseUrl: string;
  onDeleteGlimpse: (toDelete: GlimpseSummary) => void;
  onToggleVisibilityClicked: (toToggle: GlimpseSummary) => void;
};

const ListGlimpsesScreenComponent = (props: ListGlimpsesScreenProps) => {
  const {
    glimpses,
    navbarProps,
    createGlimpseUrl,
    viewGlimpseUrl,
    editGlimpseUrl,
    onDeleteGlimpse: onDeleteGlimpseInput,
    onToggleVisibilityClicked: onToggleVisibilityClickedInput,
  } = props;

  const [showSubscriptionUpsell, setShowSubscriptionUpsell] =
    useState<boolean>(false);
  const [upsellCopy, setUpsellCopy] = useState<string>("");
  const confirmDialog = useAsyncConfirmationDialog();
  const [busy, _] = useBusyWatcher();

  const onDeleteGlimpseClicked = async (toDelete: GlimpseSummary) => {
    const result = await confirmDialog.confirm({
      title: `delete glimpse '${toDelete.title ?? "untitled"}'?`,
      body: "are you sure you want to delete this glimpse? this action cannot be undone.",
    });
    if (!result) {
      return;
    }
    onDeleteGlimpseInput(toDelete);
  };

  const showUpsellDialog = (
    copy = "gain access to more features through a Manual subscription"
  ) => {
    setUpsellCopy(copy);
    setShowSubscriptionUpsell(true);
  };

  const onToggleVisibilityClicked = (toToggle: GlimpseSummary) => {
    if (!toToggle.visible! && glimpses!.limits.remaining <= 0) {
      showUpsellDialog(
        `you've reached your maximum number of live glimpses (${
          glimpses!.limits.max
        }). upgrade to a paid subscription to create more.`
      );
      return;
    }
    onToggleVisibilityClickedInput(toToggle);
  };

  return (
    <BaseNavbarPage navbarProps={navbarProps}>
      {confirmDialog.dialog}
      <CenterColumnScreen fullWidth>
        <MTitle
          text="your glimpses"
          className="text-center mb-5 text-primary"
        />
        <div className="flex flex-col items-center gap-3 mb-3">
          <div
            className={`flex flex-row items-end w-full ${
              glimpses && glimpses.limits!.max < 2000
                ? "justify-between"
                : "justify-center"
            }`}
          >
            {glimpses && glimpses.limits.max < 2000 && (
              <div className="text-sm flex flex-col items-start gap-1">
                <span>
                  {glimpses?.limits.current}/{glimpses?.limits.max} glimpses
                  live
                </span>
                <button
                  type="button"
                  onClick={() => showUpsellDialog()}
                  className={clickableText}
                >
                  + get more
                </button>
              </div>
            )}
            <div>
              <MPlusButton
                href={createGlimpseUrl}
                disabled={busy}
                kind="primary"
                text="create new"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 mt-3">
          {!glimpses && (
            <>
              <Skeleton className="h-32" />
              <Skeleton className="h-32" />
              <Skeleton className="h-32" />
              <Skeleton className="h-32" />
            </>
          )}
          {glimpses && glimpses.glimpses.length === 0 && (
            <div className={classNames("text-center my-6", commentText)}>
              you haven't created any glimpses yet. create one now!
            </div>
          )}
          {glimpses &&
            glimpses.glimpses.length > 0 &&
            glimpses.glimpses.map((glimpse) => (
              <GlimpseSummaryCard
                key={glimpse.guid!}
                glimpse={glimpse}
                viewGlimpseUrl={viewGlimpseUrl}
                editGlimpseUrl={editGlimpseUrl}
                onToggleVisibilityClicked={() =>
                  onToggleVisibilityClicked(glimpse)
                }
                onDeleteClicked={() => onDeleteGlimpseClicked(glimpse)}
              />
            ))}
        </div>
      </CenterColumnScreen>
      <SubscriptionUpsellDialog
        upsellText={upsellCopy}
        show={showSubscriptionUpsell}
        onClose={() => setShowSubscriptionUpsell(false)}
      />
    </BaseNavbarPage>
  );
};

export default ListGlimpsesScreenComponent;
