/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  GetTaglineResponse,
  GetTaglineResponseFromJSON,
  GetTaglineResponseFromJSONTyped,
  GetTaglineResponseToJSON,
} from "./GetTaglineResponse";

/**
 *
 * @export
 * @interface GetTaglineResponseSerializer
 */
export interface GetTaglineResponseSerializer {
  /**
   *
   * @type {string}
   * @memberof GetTaglineResponseSerializer
   */
  m?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetTaglineResponseSerializer
   */
  s: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof GetTaglineResponseSerializer
   */
  e?: { [key: string]: string } | null;
  /**
   *
   * @type {GetTaglineResponse}
   * @memberof GetTaglineResponseSerializer
   */
  c?: GetTaglineResponse;
}

export function GetTaglineResponseSerializerFromJSON(
  json: any
): GetTaglineResponseSerializer {
  return GetTaglineResponseSerializerFromJSONTyped(json, false);
}

export function GetTaglineResponseSerializerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetTaglineResponseSerializer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    m: !exists(json, "m") ? undefined : json["m"],
    s: json["s"],
    e: !exists(json, "e") ? undefined : json["e"],
    c: !exists(json, "c") ? undefined : GetTaglineResponseFromJSON(json["c"]),
  };
}

export function GetTaglineResponseSerializerToJSON(
  value?: GetTaglineResponseSerializer | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    m: value.m,
    s: value.s,
    e: value.e,
    c: GetTaglineResponseToJSON(value.c),
  };
}
