/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  GetGenTaglineResponse,
  GetGenTaglineResponseFromJSON,
  GetGenTaglineResponseFromJSONTyped,
  GetGenTaglineResponseToJSON,
} from "./GetGenTaglineResponse";

/**
 *
 * @export
 * @interface GetGenTaglineResponseSerializer
 */
export interface GetGenTaglineResponseSerializer {
  /**
   *
   * @type {string}
   * @memberof GetGenTaglineResponseSerializer
   */
  m?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetGenTaglineResponseSerializer
   */
  s: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof GetGenTaglineResponseSerializer
   */
  e?: { [key: string]: string } | null;
  /**
   *
   * @type {GetGenTaglineResponse}
   * @memberof GetGenTaglineResponseSerializer
   */
  c?: GetGenTaglineResponse;
}

export function GetGenTaglineResponseSerializerFromJSON(
  json: any
): GetGenTaglineResponseSerializer {
  return GetGenTaglineResponseSerializerFromJSONTyped(json, false);
}

export function GetGenTaglineResponseSerializerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetGenTaglineResponseSerializer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    m: !exists(json, "m") ? undefined : json["m"],
    s: json["s"],
    e: !exists(json, "e") ? undefined : json["e"],
    c: !exists(json, "c")
      ? undefined
      : GetGenTaglineResponseFromJSON(json["c"]),
  };
}

export function GetGenTaglineResponseSerializerToJSON(
  value?: GetGenTaglineResponseSerializer | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    m: value.m,
    s: value.s,
    e: value.e,
    c: GetGenTaglineResponseToJSON(value.c),
  };
}
