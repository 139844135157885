import React, { ReactNode } from "react";
import { InformationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import MMarkdown from "../MMarkdown";
import { useBusyWatcher } from "../../../util/hooks";
import { classNames } from "../../../util/strings";
import MPulse from "../MPulse";
import MTimeAgo from "../MTimeAgo";

type NotificationBoxProps = {
  icon?: ReactNode;
  imageHref?: string | null;
  body: string;
  footer: ReactNode;
  onClearClicked?: (() => void) | null;
  isNew: boolean;
  timeCreated: Date;
};

const NotificationBoxComponent = (props: NotificationBoxProps) => {
  const { icon, imageHref, body, footer, onClearClicked, isNew, timeCreated } =
    props;

  const [busy, _] = useBusyWatcher();

  const getDisplayIcon = (): ReactNode => {
    if (imageHref) {
      return <img className="h-10 w-10 rounded-full" src={imageHref} alt="" />;
    }
    let iconContent: ReactNode = icon;
    if (!iconContent) {
      iconContent = <InformationCircleIcon />;
    }
    return (
      <div className="h-10 w-10 rounded-full flex flex-col items-center justify-center text-m-dark-gray">
        {iconContent}
      </div>
    );
  };

  return (
    <div className="pointer-events-auto">
      <div className="p-3">
        <div className="flex items-start">
          <div className="flex-shrink-0 flex flex-col items-center pt-0.5">
            {getDisplayIcon()}
          </div>
          <div className="ml-3 w-0 flex-1">
            <MMarkdown
              className="text-m-dark-gray notif-markdown"
              content={body}
            />
            {footer && <div className="mt-4 flex gap-3">{footer}</div>}
            <MTimeAgo
              className="text-xs text-primary mt-2"
              date={timeCreated}
            />
          </div>
          <div className="ml-4 flex flex-col items-center gap-2 flex-shrink-0">
            {onClearClicked && (
              <button
                type="button"
                className={classNames(
                  "inline-flex rounded-md bg-m-white text-m-gray",
                  "hover:brightness-110 focus:outline-none focus:ring-2",
                  "focus:ring-primary focus:ring-offset-2",
                  busy ? "opacity-50 cursor-not-allowed" : ""
                )}
                onClick={onClearClicked}
                disabled={busy}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}
            {isNew && <MPulse />}
          </div>
        </div>
      </div>
    </div>
  );
};

NotificationBoxComponent.defaultProps = {
  imageHref: null,
  icon: null,
  onClearClicked: null,
};

export default NotificationBoxComponent;
