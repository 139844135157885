/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ListUserInvitesResponse,
  ListUserInvitesResponseFromJSON,
  ListUserInvitesResponseFromJSONTyped,
  ListUserInvitesResponseToJSON,
} from "./ListUserInvitesResponse";

/**
 *
 * @export
 * @interface ListUserInvitesResponseSerializer
 */
export interface ListUserInvitesResponseSerializer {
  /**
   *
   * @type {string}
   * @memberof ListUserInvitesResponseSerializer
   */
  m?: string;
  /**
   *
   * @type {boolean}
   * @memberof ListUserInvitesResponseSerializer
   */
  s: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ListUserInvitesResponseSerializer
   */
  e?: { [key: string]: string } | null;
  /**
   *
   * @type {ListUserInvitesResponse}
   * @memberof ListUserInvitesResponseSerializer
   */
  c?: ListUserInvitesResponse;
}

export function ListUserInvitesResponseSerializerFromJSON(
  json: any
): ListUserInvitesResponseSerializer {
  return ListUserInvitesResponseSerializerFromJSONTyped(json, false);
}

export function ListUserInvitesResponseSerializerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ListUserInvitesResponseSerializer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    m: !exists(json, "m") ? undefined : json["m"],
    s: json["s"],
    e: !exists(json, "e") ? undefined : json["e"],
    c: !exists(json, "c")
      ? undefined
      : ListUserInvitesResponseFromJSON(json["c"]),
  };
}

export function ListUserInvitesResponseSerializerToJSON(
  value?: ListUserInvitesResponseSerializer | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    m: value.m,
    s: value.s,
    e: value.e,
    c: ListUserInvitesResponseToJSON(value.c),
  };
}
