/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SetBioRequest
 */
export interface SetBioRequest {
  /**
   *
   * @type {string}
   * @memberof SetBioRequest
   */
  bio: string | null;
}

export function SetBioRequestFromJSON(json: any): SetBioRequest {
  return SetBioRequestFromJSONTyped(json, false);
}

export function SetBioRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SetBioRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    bio: json["bio"],
  };
}

export function SetBioRequestToJSON(value?: SetBioRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    bio: value.bio,
  };
}
