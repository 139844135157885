import React from "react";

type SidebarTextAddButtonProps = {
  text: string;
  onClick: () => void;
};

const SidebarTextAddButtonComponent = (props: SidebarTextAddButtonProps) => {
  const { text, onClick } = props;

  return (
    <button
      type="button"
      className="text-sm font-semibold leading-6 text-primary hover:brightness-110"
      onClick={onClick}
    >
      <span aria-hidden="true">+</span> {text}
    </button>
  );
};

export default SidebarTextAddButtonComponent;
