import React, {
  FocusEvent,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MLabel from "./MLabel";
import { classNames } from "../../util/strings";
import MErrors from "./MErrors";
import MCharacterLimit from "./MCharacterLimit";

type MTextAreaProps = React.ComponentPropsWithoutRef<"textarea"> & {
  label?: string;
  errors?: string[];
  resize?: boolean;
  characterLimit?: number | null;
};

const MTextAreaComponent = forwardRef<HTMLTextAreaElement, MTextAreaProps>(
  (
    { label, resize, required, errors, value, characterLimit, ...props },
    ref
  ) => {
    const labelId = useMemo(() => Math.random().toString(36), []);

    const innerRef = useRef<HTMLTextAreaElement | null>(null);
    const [initialHeight, setInitialHeight] = useState<number | null>(null);

    // Taken from
    // https://medium.com/@oherterich/creating-a-textarea-with-dynamic-height-using-react-and-typescript-5ed2d78d9848

    const onFocus = (e: FocusEvent<HTMLTextAreaElement>) => {
      e.target.select();
      if (props.onFocus) {
        props.onFocus(e);
      }
    };

    useEffect(() => {
      if (!resize) {
        return;
      }
      if (innerRef && innerRef.current) {
        let innerInitialHeight: number;
        if (initialHeight === null) {
          innerInitialHeight = innerRef.current.offsetHeight;
          setInitialHeight(innerInitialHeight);
        } else {
          innerInitialHeight = initialHeight;
        }
        // We need to reset the height momentarily to get the correct scrollHeight for the textarea
        innerRef.current.style.height = "0px";
        const { scrollHeight } = innerRef.current;

        if (scrollHeight > innerInitialHeight) {
          // We then set the height directly, outside the render loop
          // Trying to set this with state or a ref will produce an incorrect value.
          innerRef.current.style.height = `${scrollHeight}px`;
        } else {
          innerRef.current.style.height = `${innerInitialHeight}px`;
        }
      }
    }, [innerRef, value]);

    const [text, setText] = useState(value || "");

    useEffect(() => {
      setText(value || "");
    }, [value]);

    const textLength = typeof text === "string" ? text.length : 0;
    const charactersRemaining = characterLimit
      ? characterLimit - textLength
      : 0;
    const isOverLimit = characterLimit && charactersRemaining < 0;
    const inError = Boolean((errors && errors.length > 0) || isOverLimit);

    return (
      <div className="w-full">
        <MLabel
          label={label}
          htmlFor={labelId}
          required={required}
          error={inError}
        />
        <div className="relative rounded-md shadow-sm">
          <textarea
            id={labelId}
            className={classNames(
              "block w-full rounded-md border-0 p-1.5 text-m-black ring-1 ring-inset h-36",
              "placeholder:text-m-light-gray focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6",
              inError ? "ring-danger" : "ring-m-light-gray",
              props.disabled ? "cursor-not-allowed" : null
            )}
            ref={(node) => {
              innerRef.current = node;
              if (typeof ref === "function") {
                ref(node);
              } else if (ref) {
                // eslint-disable-next-line no-param-reassign
                ref.current = node;
              }
            }}
            required={required}
            value={value}
            onFocus={onFocus}
            onChange={(event) => {
              setText(event.target.value);
              if (props.onChange) props.onChange(event);
            }}
            {...props}
          />
        </div>
        <div
          className={classNames(
            "flex flex-row items-start w-full gap-3",
            errors && errors.length > 0 ? "justify-between" : "justify-end"
          )}
        >
          <MErrors errors={errors} />
          <MCharacterLimit
            maxLength={characterLimit ?? null}
            curLength={typeof text === "string" ? text.length : 0}
          />
        </div>
      </div>
    );
  }
);

MTextAreaComponent.defaultProps = {
  label: undefined,
  errors: undefined,
  resize: true,
  characterLimit: null,
};

MTextAreaComponent.displayName = "MTextArea";

export default MTextAreaComponent;
