/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  UserSearchResponse,
  UserSearchResponseFromJSON,
  UserSearchResponseFromJSONTyped,
  UserSearchResponseToJSON,
} from "./UserSearchResponse";

/**
 *
 * @export
 * @interface UserSearchResponseSerializer
 */
export interface UserSearchResponseSerializer {
  /**
   *
   * @type {string}
   * @memberof UserSearchResponseSerializer
   */
  m?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserSearchResponseSerializer
   */
  s: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof UserSearchResponseSerializer
   */
  e?: { [key: string]: string } | null;
  /**
   *
   * @type {UserSearchResponse}
   * @memberof UserSearchResponseSerializer
   */
  c?: UserSearchResponse;
}

export function UserSearchResponseSerializerFromJSON(
  json: any
): UserSearchResponseSerializer {
  return UserSearchResponseSerializerFromJSONTyped(json, false);
}

export function UserSearchResponseSerializerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserSearchResponseSerializer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    m: !exists(json, "m") ? undefined : json["m"],
    s: json["s"],
    e: !exists(json, "e") ? undefined : json["e"],
    c: !exists(json, "c") ? undefined : UserSearchResponseFromJSON(json["c"]),
  };
}

export function UserSearchResponseSerializerToJSON(
  value?: UserSearchResponseSerializer | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    m: value.m,
    s: value.s,
    e: value.e,
    c: UserSearchResponseToJSON(value.c),
  };
}
