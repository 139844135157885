import React, { ReactElement } from "react";
import { UserProfileSummary } from "../../../api/models";
import { useBusyWatcher } from "../../../util/hooks";
import MBackButton from "../buttons/MBackButton";
import CenterColumnStickyButtonScreen from "./CenterColumnStickyButtonScreen";
import MTitle from "../MTitle";
import { commentText } from "../../../util/style";
import { classNames } from "../../../util/strings";
import ProfileCard from "../cards/ProfileCard";
import MSearchBox from "../MSearchBox";

type UserSearchScreenProps = {
  title?: string | null;
  searchTerm?: string | null;
  searchResults?: UserProfileSummary[] | null;
  onSearchTermUpdated: (newTerm: string) => void;
  onBackClicked: () => void;
  onUserClicked: (user: UserProfileSummary) => void;
  errors: string[];
  searchPlaceholder?: string;
  startSearchingPlaceholder?: string;
};

const UserSearchScreenComponent = (props: UserSearchScreenProps) => {
  const {
    title,
    searchTerm,
    searchResults,
    onSearchTermUpdated,
    onBackClicked,
    onUserClicked,
    errors,
    searchPlaceholder,
    startSearchingPlaceholder,
  } = props;

  const [busy, _] = useBusyWatcher();

  const getButtons = (): ReactElement[] => [
    <MBackButton
      kind="secondary"
      key="back-button"
      onClick={onBackClicked}
      disabled={busy}
    />,
  ];

  return (
    <CenterColumnStickyButtonScreen buttons={getButtons()} stacked fullWidth>
      {title && <MTitle text={title} className="text-center mb-5" />}
      <MSearchBox
        searchTerm={searchTerm}
        onSearchTermUpdated={onSearchTermUpdated}
        errors={errors}
        className="mb-3 mx-auto"
        placeholder={searchPlaceholder}
      />
      {searchResults && searchResults.length > 0 && (
        <div className="flex flex-col gap-2">
          {searchResults.map((user) => (
            <ProfileCard
              key={user.id!}
              user={user}
              disabled={busy}
              onClick={() => onUserClicked(user)}
            />
          ))}
        </div>
      )}
      {!searchResults && (
        <div
          className={classNames(commentText, "text-center whitespace-pre-line")}
        >
          {startSearchingPlaceholder}
        </div>
      )}
      {searchResults?.length === 0 && (
        <div className={classNames(commentText, "text-center")}>
          no results found
        </div>
      )}
    </CenterColumnStickyButtonScreen>
  );
};

UserSearchScreenComponent.defaultProps = {
  title: null,
  searchTerm: null,
  searchResults: null,
  searchPlaceholder: "search by username or name",
  startSearchingPlaceholder:
    "start typing in the box above to search through all users. your results will be displayed here.",
};

export default UserSearchScreenComponent;
