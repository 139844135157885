import React, { useState } from "react";
import {
  GetProfilePublicResponse,
  ListProfileAccessResponse,
  SetProfileAccessRequest,
  UserProfileAccessRelatedDetail,
} from "../../../api/models";
import SidebarSection from "../../ui/screens/sidebarnav/SidebarSection";
import SettingsToggleRow from "../../ui/screens/sidebarnav/SidebarToggleRow";
import SettingsAddRow from "../../ui/screens/sidebarnav/SidebarAddRow";
import SettingsCommentRow from "../../ui/screens/sidebarnav/SidebarCommentRow";
import SettingsAddPrivateProfileAccessRow from "./SettingsAddPrivateProfileAccessRow";
import SettingsPrivateProfileAccessRow from "./SettingsPrivateProfileAccessRow";

type SettingsPrivacyScreenProps = {
  loading: boolean;
  publicProfileResponse: GetProfilePublicResponse | null;
  profileAccessList: ListProfileAccessResponse | null;
  onPublicProfileToggled: (isPublic: boolean) => void;
  onNewAccessRuleReady: (newRule: SetProfileAccessRequest) => Promise<boolean>;
  onDeleteAccessRuleClicked: (toDelete: UserProfileAccessRelatedDetail) => void;
};

const SettingsPrivacyScreenComponent = (props: SettingsPrivacyScreenProps) => {
  const {
    loading,
    publicProfileResponse,
    profileAccessList,
    onPublicProfileToggled,
    onNewAccessRuleReady,
    onDeleteAccessRuleClicked,
  } = props;

  const [showAddAccessRule, setShowAddAccessRule] = useState<boolean>(false);

  const onAddAccessRuleClicked = () => {
    setShowAddAccessRule(true);
  };

  const onAccessRuleReady = async (newRule: SetProfileAccessRequest) => {
    const success = await onNewAccessRuleReady(newRule);
    if (success) {
      setShowAddAccessRule(false);
    }
  };

  return (
    <>
      <SidebarSection
        title="Profile Visibility"
        description="You can control whether your profile is visible and discoverable via search."
      >
        <SettingsToggleRow
          label="Public profile"
          tooltip="When enabled, your profile will be visible to other users and discoverable via search. When disabled, your profile will only be visible and searchable by yourself and anyone you add to the private profile access list below."
          onToggleClicked={onPublicProfileToggled}
          isOn={loading ? false : publicProfileResponse!.is_public}
        />
      </SidebarSection>
      <SidebarSection
        title="Private Profile Access"
        description="Rules that specify who can still view and search for your profile when you set it to private. These rules have no effect when your profile is set to public."
      >
        <>
          {profileAccessList && profileAccessList.access_list.length > 0 ? (
            profileAccessList.access_list.map((accessRule) => (
              <SettingsPrivateProfileAccessRow
                entry={accessRule}
                key={accessRule.guid!}
                onDeleteClicked={() => onDeleteAccessRuleClicked(accessRule)}
              />
            ))
          ) : (
            <SettingsCommentRow comment="you haven't added any access rules yet" />
          )}
          {showAddAccessRule ? (
            <SettingsAddPrivateProfileAccessRow
              onCompleted={onAccessRuleReady}
              onCanceled={() => setShowAddAccessRule(false)}
            />
          ) : (
            <SettingsAddRow
              label="Add access rule"
              onClick={onAddAccessRuleClicked}
            />
          )}
        </>
      </SidebarSection>
    </>
  );
};

export default SettingsPrivacyScreenComponent;
