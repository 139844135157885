/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  QuestionnaireCompletion,
  QuestionnaireCompletionFromJSON,
  QuestionnaireCompletionFromJSONTyped,
  QuestionnaireCompletionToJSON,
} from "./QuestionnaireCompletion";

/**
 *
 * @export
 * @interface ListQuestionnairesResponse
 */
export interface ListQuestionnairesResponse {
  /**
   *
   * @type {Array<QuestionnaireCompletion>}
   * @memberof ListQuestionnairesResponse
   */
  questionnaires: Array<QuestionnaireCompletion>;
}

export function ListQuestionnairesResponseFromJSON(
  json: any
): ListQuestionnairesResponse {
  return ListQuestionnairesResponseFromJSONTyped(json, false);
}

export function ListQuestionnairesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ListQuestionnairesResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    questionnaires: (json["questionnaires"] as Array<any>).map(
      QuestionnaireCompletionFromJSON
    ),
  };
}

export function ListQuestionnairesResponseToJSON(
  value?: ListQuestionnairesResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    questionnaires: (value.questionnaires as Array<any>).map(
      QuestionnaireCompletionToJSON
    ),
  };
}
