import React, { ReactElement } from "react";
import { GetProfilePhotoResponse } from "../../../api/models";
import { useBusyWatcher } from "../../../util/hooks";
import MButton from "../../ui/buttons/MButton";
import MTitle from "../../ui/MTitle";
import CenterColumnStickyButtonScreen from "../../ui/screens/CenterColumnStickyButtonScreen";
import MAvatar from "../../ui/MAvatar";

type EditProfilePictureScreenProps = {
  photoResponse: GetProfilePhotoResponse;
  onDoneClicked: () => void;
  onClearClicked: () => void;
  onAddClicked: () => void;
};

const EditProfilePictureScreenComponent = (
  props: EditProfilePictureScreenProps
) => {
  const { photoResponse, onDoneClicked, onClearClicked, onAddClicked } = props;

  const [busy, _] = useBusyWatcher();

  const getButtons = (): ReactElement[] => {
    const toReturn: ReactElement[] = [];

    if (photoResponse.pic_url) {
      toReturn.push(
        ...[
          <MButton
            kind="primary"
            onClick={onAddClicked}
            disabled={busy}
            key="change-button"
          >
            change
          </MButton>,
          <MButton
            kind="warning"
            onClick={onClearClicked}
            disabled={busy}
            key="clear-button"
          >
            delete
          </MButton>,
        ]
      );
    } else {
      toReturn.push(
        <MButton
          kind="primary"
          onClick={onAddClicked}
          disabled={busy}
          key="add-button"
        >
          add a picture
        </MButton>
      );
    }
    toReturn.push(
      <MButton
        kind="secondary"
        onClick={onDoneClicked}
        disabled={busy}
        key="done-button"
      >
        done
      </MButton>
    );
    return toReturn;
  };

  return (
    <CenterColumnStickyButtonScreen buttons={getButtons()} stacked fullWidth>
      <MTitle
        text={
          photoResponse.pic_url
            ? "edit your profile picture"
            : "add a profile picture"
        }
        className="text-center"
      />
      <MAvatar
        className="mx-auto my-24 sm:my-10 shadow shadow-gray-400"
        picUrl={photoResponse.pic_url}
        size="large"
        alt="profile picture"
      />
    </CenterColumnStickyButtonScreen>
  );
};

export default EditProfilePictureScreenComponent;
