/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GroupSummaryRequest
 */
export interface GroupSummaryRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof GroupSummaryRequest
   */
  user_guids?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof GroupSummaryRequest
   */
  group_guid?: string;
}

export function GroupSummaryRequestFromJSON(json: any): GroupSummaryRequest {
  return GroupSummaryRequestFromJSONTyped(json, false);
}

export function GroupSummaryRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GroupSummaryRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    user_guids: !exists(json, "user_guids") ? undefined : json["user_guids"],
    group_guid: !exists(json, "group_guid") ? undefined : json["group_guid"],
  };
}

export function GroupSummaryRequestToJSON(
  value?: GroupSummaryRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    user_guids: value.user_guids,
    group_guid: value.group_guid,
  };
}
