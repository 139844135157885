/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ViewGlimpseModel
 */
export interface ViewGlimpseModel {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof ViewGlimpseModel
   */
  readonly guid?: string;
  /**
   * The contents (initially generated by LLM) of this glimpse.
   * @type {string}
   * @memberof ViewGlimpseModel
   */
  content?: string | null;
}

export function ViewGlimpseModelFromJSON(json: any): ViewGlimpseModel {
  return ViewGlimpseModelFromJSONTyped(json, false);
}

export function ViewGlimpseModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ViewGlimpseModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    content: !exists(json, "content") ? undefined : json["content"],
  };
}

export function ViewGlimpseModelToJSON(value?: ViewGlimpseModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    content: value.content,
  };
}
