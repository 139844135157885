/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SubmitGlimpseForProcessingRequest
 */
export interface SubmitGlimpseForProcessingRequest {
  /**
   *
   * @type {string}
   * @memberof SubmitGlimpseForProcessingRequest
   */
  glimpse: string;
}

export function SubmitGlimpseForProcessingRequestFromJSON(
  json: any
): SubmitGlimpseForProcessingRequest {
  return SubmitGlimpseForProcessingRequestFromJSONTyped(json, false);
}

export function SubmitGlimpseForProcessingRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SubmitGlimpseForProcessingRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    glimpse: json["glimpse"],
  };
}

export function SubmitGlimpseForProcessingRequestToJSON(
  value?: SubmitGlimpseForProcessingRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    glimpse: value.glimpse,
  };
}
