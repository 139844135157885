import React from "react";
import { classNames } from "../../util/strings";

type MBadgeProps = {
  content: string;
  onClick?: (() => void) | null;
  onCancelClick?: (() => void) | null;
};

const MBadgeComponent = (props: MBadgeProps) => {
  const { content, onClick, onCancelClick } = props;

  const toReturn = (
    <span
      className={classNames(
        "inline-flex items-center rounded-lg px-2 py-1 text-xs font-medium",
        onClick
          ? "hover:brightness-95 hover:underline bg-primary-light text-primary ring-1 ring-inset ring-primary/20"
          : "bg-primary-light/50 text-m-dark-gray"
      )}
    >
      {content}
      {onCancelClick && (
        <button
          type="button"
          className="group relative ml-1 -mr-1 h-3.5 w-3.5 rounded-md hover:bg-m-dark-gray/20"
          onClick={onCancelClick}
        >
          <span className="sr-only">Remove</span>
          <svg
            viewBox="0 0 14 14"
            className="h-3.5 w-3.5 stroke-m-dark-gray/50 group-hover:stroke-m-dark-gray/75"
          >
            <path d="M4 4l6 6m0-6l-6 6" />
          </svg>
          <span className="absolute -inset-1" />
        </button>
      )}
    </span>
  );

  if (onClick) {
    return (
      <button type="button" onClick={onClick}>
        {toReturn}
      </button>
    );
  }

  return toReturn;
};

MBadgeComponent.defaultProps = {
  onClick: null,
  onCancelClick: null,
};

export default MBadgeComponent;
