import React, { ReactNode } from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import VisibilityExplanationDialog from "./VisibilityExplanationDialog";

type PromptVisibilityInfoProps = {
  publishable: boolean;
  visible?: boolean | null;
};

const PromptVisibilityInfoComponent = (props: PromptVisibilityInfoProps) => {
  const { publishable, visible } = props;

  const [showExplanation, setShowExplanation] = React.useState<boolean>(false);

  const getCopy = (): ReactNode => {
    if (!publishable) {
      return (
        <>
          <span className="font-bold">can't be shown</span>on profile
        </>
      );
    }
    if (visible) {
      return (
        <>
          <span className="font-bold">visible</span>on profile
        </>
      );
    }
    return (
      <>
        <span className="font-bold">not visible</span>on profile
      </>
    );
  };

  return (
    <>
      <div className="flex flex-row items-center gap-2 text-xs">
        {getCopy()}
        <button
          type="button"
          onClick={() => setShowExplanation(true)}
          aria-label="show question visibility explanation"
        >
          <QuestionMarkCircleIcon className="h-4 w-4 text-primary hover:brightness-110" />
        </button>
      </div>
      <VisibilityExplanationDialog
        show={showExplanation}
        onClose={() => setShowExplanation(false)}
      />
    </>
  );
};

PromptVisibilityInfoComponent.defaultProps = {
  visible: null,
};

export default PromptVisibilityInfoComponent;
