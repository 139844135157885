import React, { useEffect, useState } from "react";
import { MNavProps } from "../../ui/nav/MNav";
import { useBackTimer, useBusyWatcher } from "../../../util/hooks";
import { useWrappedConnector } from "../../../api/connector";
import { useAsyncConfirmationDialog } from "../../dialogs/hooks";
import WizardScreen, { WizardScreenPanel } from "../../ui/screens/WizardScreen";
import LoadingScreen from "../../ui/screens/LoadingScreen";
import BaseNavbarPage from "../BaseNavbarPage";
import QuestionsLandingScreen from "./QuestionsLandingScreen";
import {
  AnsweredPromptDetail,
  GetAnsweredPromptDetailsResponse,
  GetAssessmentsResponse,
  ListQuestionnairesResponse,
  PromptWithResponse,
} from "../../../api/models";
import ListAssessmentsScreen from "./ListAssessmentsScreen";
import ListQuestionnairesScreen from "./ListQuestionnairesScreen";
import ListResponsesScreen from "./ListResponsesScreen";
import PromptRespondScreen from "../../ui/forms/PromptRespondScreen";
import { errorMessagesForKeyFromResponse } from "../../../api/helpers";

type QuestionsPageProps = {
  navbarProps: MNavProps;
  nextUrl: string;
  questionnaireUrl: string;
  assessmentUrl: string;
};

enum QuestionsStage {
  LOADING,
  LANDING,
  ASSESSMENTS,
  QUESTIONNAIRES,
  QUESTIONS,
  EDIT_RESPONSE,
}

const QuestionsPageComponent = (props: QuestionsPageProps) => {
  const { navbarProps, nextUrl, questionnaireUrl, assessmentUrl } = props;

  const [errors, setErrors] = useState<string[]>([]);
  const [stage, setStage] = useState<QuestionsStage>(QuestionsStage.LOADING);
  const [assessments, setAssessments] = useState<GetAssessmentsResponse | null>(
    null
  );
  const [questionnaires, setQuestionnaires] =
    useState<ListQuestionnairesResponse | null>(null);
  const [questions, setQuestions] =
    useState<GetAnsweredPromptDetailsResponse | null>(null);
  const [editResponse, setEditResponse] = useState<AnsweredPromptDetail | null>(
    null
  );
  const [isBack, asBack] = useBackTimer(stage);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);
  const confirmDialog = useAsyncConfirmationDialog();

  const goToStage = async (nextStage: QuestionsStage) => {
    setErrors([]);
    // eslint-disable-next-line default-case
    switch (nextStage) {
      case QuestionsStage.ASSESSMENTS: {
        const response = await connector.listAssessments();
        setAssessments(response.c!);
        break;
      }
      case QuestionsStage.QUESTIONNAIRES: {
        const response = await connector.listQuestionnaires();
        setQuestionnaires(response.c!);
        break;
      }
      case QuestionsStage.QUESTIONS: {
        const response = await connector.listResponseDetails();
        setQuestions(response.c!);
        break;
      }
    }
    setStage(nextStage);
  };

  const onDeleteResponseClicked = async (
    promptResponse: PromptWithResponse
  ) => {
    const result = await confirmDialog.confirm({
      title: "delete response?",
      body: `are you sure you want to delete your response to the question "${promptResponse.prompt.question}"? this action cannot be undone.`,
    });
    if (!result) {
      return;
    }
    await connector.clearPromptResponse(promptResponse.prompt.guid!);
    const response = await connector.listResponseDetails();
    setQuestions(response.c!);
  };

  const onToggleVisibilityClicked = async (
    promptResponse: AnsweredPromptDetail
  ) => {
    await connector.setPromptResponsePresentationVisibility({
      presentation: promptResponse.presentation_id!,
      is_visible: !promptResponse.is_visible,
    });
    const response = await connector.listResponseDetails();
    setQuestions(response.c!);
  };

  const onEditResponseClicked = async (
    promptResponse: AnsweredPromptDetail
  ) => {
    setEditResponse(promptResponse);
    await goToStage(QuestionsStage.EDIT_RESPONSE);
  };

  const onUpdateResponseClicked = async (
    response: any,
    visible: boolean | null
  ) => {
    setErrors([]);
    try {
      await connector.submitPromptResponse({
        prompt: editResponse!.prompt_response.prompt.guid!,
        publish: visible,
        response,
      });
    } catch (r: any) {
      const parsed = await errorMessagesForKeyFromResponse(r, "response", true);
      setErrors(parsed);
    }
    await goToStage(QuestionsStage.QUESTIONS);
  };

  useEffect(() => {
    goToStage(QuestionsStage.LANDING);
  }, []);

  const getElements = (): WizardScreenPanel[] => [
    {
      stage: QuestionsStage.LOADING,
      content: (
        <LoadingScreen
          errors={errors}
          onBackClicked={() => window.history.back()}
        />
      ),
    },
    {
      stage: QuestionsStage.LANDING,
      content: (
        <QuestionsLandingScreen
          onAssessmentsClicked={() => goToStage(QuestionsStage.ASSESSMENTS)}
          onQuestionnairesClicked={() =>
            goToStage(QuestionsStage.QUESTIONNAIRES)
          }
          onQuestionsClicked={() => goToStage(QuestionsStage.QUESTIONS)}
          onDoneClicked={() => {
            window.location.href = nextUrl;
          }}
        />
      ),
    },
    {
      stage: QuestionsStage.ASSESSMENTS,
      content: assessments && (
        <ListAssessmentsScreen
          assessments={assessments}
          assessmentUrl={assessmentUrl}
          onBackClicked={asBack(() => goToStage(QuestionsStage.LANDING))}
        />
      ),
    },
    {
      stage: QuestionsStage.QUESTIONNAIRES,
      content: questionnaires && (
        <ListQuestionnairesScreen
          questionnaires={questionnaires}
          questionnaireUrl={questionnaireUrl}
          onBackClicked={asBack(() => goToStage(QuestionsStage.LANDING))}
        />
      ),
    },
    {
      stage: QuestionsStage.QUESTIONS,
      content: questions && (
        <ListResponsesScreen
          responses={questions}
          onEditClicked={onEditResponseClicked}
          onDeleteClicked={onDeleteResponseClicked}
          onToggleVisibilityClicked={onToggleVisibilityClicked}
          onBackClicked={asBack(() => goToStage(QuestionsStage.LANDING))}
          onDoneClicked={() => {
            window.location.href = nextUrl;
          }}
        />
      ),
    },
    {
      stage: QuestionsStage.EDIT_RESPONSE,
      content: editResponse && (
        <PromptRespondScreen
          prompt={{
            ...editResponse!.prompt_response,
            publishable: editResponse!.can_be_visible,
          }}
          onNextClicked={onUpdateResponseClicked}
          onBackClicked={asBack(() => goToStage(QuestionsStage.QUESTIONS))}
          errors={errors}
        />
      ),
    },
  ];

  return (
    <BaseNavbarPage navbarProps={navbarProps}>
      {confirmDialog.dialog}
      <WizardScreen isBack={isBack} elements={getElements()} stage={stage} />
    </BaseNavbarPage>
  );
};

export default QuestionsPageComponent;
