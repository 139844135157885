import React from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import VisibilityExplanationDialog from "./VisibilityExplanationDialog";
import MVisibilityToggle from "../MVisibilityToggle";

type PromptVisibilityToggleProps = {
  publishable: boolean;
  visible?: boolean;
  onToggleClicked: () => void;
};

const PromptVisibilityToggleComponent = (
  props: PromptVisibilityToggleProps
) => {
  const { publishable, visible, onToggleClicked } = props;

  const [showExplanation, setShowExplanation] = React.useState<boolean>(false);

  return (
    <>
      <div className="w-full flex flex-row justify-end">
        <div className="flex flex-col items-end gap-2">
          <div className="flex flex-row items-center text-xs gap-2">
            {publishable ? (
              <MVisibilityToggle
                onClick={onToggleClicked}
                isVisible={visible}
                copy="show on profile"
              />
            ) : (
              <span className="font-semibold">
                won't be displayed on profile
              </span>
            )}
            <button
              type="button"
              onClick={() => setShowExplanation(true)}
              aria-label="show question visibility explanation"
            >
              <QuestionMarkCircleIcon className="h-4 w-4 text-primary hover:brightness-110" />
            </button>
          </div>
        </div>
      </div>
      <VisibilityExplanationDialog
        show={showExplanation}
        onClose={() => setShowExplanation(false)}
      />
    </>
  );
};

PromptVisibilityToggleComponent.defaultProps = {
  visible: true,
};

export default PromptVisibilityToggleComponent;
