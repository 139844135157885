import React, { useState } from "react";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { UserProfileSummary, UserSearchResponse } from "../../api/models";
import BaseDialog from "./BaseDialog";
import MButton from "../ui/buttons/MButton";
import MSearchBox from "../ui/MSearchBox";
import { useBusyWatcher } from "../../util/hooks";
import { useWrappedConnector } from "../../api/connector";
import { commentText } from "../../util/style";
import ProfileCard from "../ui/cards/ProfileCard";
import { errorMessagesForKeyFromResponse } from "../../api/helpers";
import InviteUserDialog from "./InviteUserDialog";

export type UserSearchDialogProps = {
  title?: string;
  placeholder?: string;
};

type innerProps = UserSearchDialogProps & {
  onUserSelected: (user: UserProfileSummary) => void;
  onCancel?: (() => void) | null;
};

const UserSearchDialogComponent = (props: innerProps) => {
  const {
    title,
    onUserSelected: onUserSelectedInput,
    onCancel,
    placeholder,
  } = props;

  const [_, busyWatcher] = useBusyWatcher();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] = useState<UserSearchResponse | null>(
    null
  );
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [showInvite, setShowInvite] = useState<boolean>(false);
  const [inviteSent, setInviteSent] = useState<boolean>(false);
  const [inviteErrors, setInviteErrors] = useState<string[]>([]);
  const connector = useWrappedConnector(busyWatcher);

  const refreshSearchResults = async (newSearchTerm: string) => {
    if (newSearchTerm === searchTerm) {
      return;
    }
    setSearchTerm(newSearchTerm);
    if (newSearchTerm === "") {
      setSearchResults(null);
    } else {
      const result = await connector.searchForUser(newSearchTerm);
      setSearchResults(result.c!);
    }
  };

  const cancel = () => {
    setIsOpen(false);
    if (onCancel) {
      onCancel();
    }
  };

  const onUserSelected = (user: UserProfileSummary) => {
    setIsOpen(false);
    onUserSelectedInput(user);
  };

  const onShowInviteClicked = () => {
    setIsOpen(false);
    setInviteErrors([]);
    setInviteSent(false);
    setShowInvite(true);
  };

  const onInviteEmailSubmitted = async (inviteEmail: string) => {
    setInviteErrors([]);
    try {
      await connector.inviteEmail(inviteEmail);
      setInviteSent(true);
    } catch (e: any) {
      const parsed = await errorMessagesForKeyFromResponse(e, "email", true);
      setInviteErrors(parsed);
      return;
    }
    setInviteSent(true);
  };

  const onInviteAnotherClicked = () => {
    setInviteErrors([]);
    setInviteSent(false);
  };

  return (
    <>
      <BaseDialog show={isOpen} title={title!} onClose={cancel}>
        <div className="flex flex-col items-center gap-4">
          {searchResults && searchResults.users.length > 0 ? (
            searchResults.users.map((user) => (
              <ProfileCard
                className="w-full"
                key={user.id!}
                user={user}
                onClick={() => onUserSelected(user)}
              />
            ))
          ) : (
            <div className={commentText}>
              {searchResults && searchResults.users.length === 0
                ? "no users found"
                : "search by name or username"}
            </div>
          )}
          <MSearchBox
            onSearchTermUpdated={refreshSearchResults}
            searchTerm={searchTerm}
            placeholder={placeholder}
            className="w-full max-w-lg"
            autoFocus
          />
        </div>
        <div className="flex flex-row justify-between w-full gap-4">
          <MButton kind="secondary" onClick={() => cancel()}>
            cancel
          </MButton>
          <MButton
            kind="primary"
            onClick={onShowInviteClicked}
            icon={<UserPlusIcon className="h-5 w-5" />}
          >
            invite to Manual
          </MButton>
        </div>
      </BaseDialog>
      <InviteUserDialog
        show={showInvite}
        onClose={() => setShowInvite(false)}
        errors={inviteErrors}
        onEmailSubmitted={onInviteEmailSubmitted}
        sent={inviteSent}
        onInviteAnotherClicked={onInviteAnotherClicked}
      />
    </>
  );
};

UserSearchDialogComponent.defaultProps = {
  title: "Select a user",
  placeholder: "search",
  onCancel: null,
};

export default UserSearchDialogComponent;
