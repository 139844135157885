import React from "react";
import { EmployerRoleDetail } from "../../../../api/models";
import MWell from "../../../ui/wells/MWell";
import MDivider from "../../../ui/MDivider";
import MWellEditButton from "../../../ui/wells/MWellEditButton";
import { useBusyWatcher } from "../../../../util/hooks";
import MWellDeleteButton from "../../../ui/wells/MWellDeleteButton";
import Timeline from "./Timeline";

type EmployerRoleSummaryProps = {
  summary: EmployerRoleDetail;
  onEditClicked?: (() => void) | null;
  onDeleteClicked?: (() => void) | null;
};

const EmployerRoleSummaryComponent = (props: EmployerRoleSummaryProps) => {
  const { summary, onEditClicked, onDeleteClicked } = props;

  const [busy, _] = useBusyWatcher();

  return (
    <MWell kind="white" className="text-m-dark-gray">
      <div className="flex flex-col gap-3">
        <div className="flex flex-row gap-3">
          <div>
            <div className="font-bold text-m-dark-gray">{summary.title!}</div>
            <Timeline
              className="text-sm"
              start={summary.date_started}
              end={summary.date_ended}
            />
          </div>
          <div className="grow" />
          {onEditClicked && (
            <MWellEditButton onClick={onEditClicked} disabled={busy} />
          )}
          {onDeleteClicked && (
            <MWellDeleteButton onClick={onDeleteClicked} disabled={busy} />
          )}
        </div>
        {summary.impact && (
          <div>
            <MDivider title="impact" />
            <div className="whitespace-pre-line text-sm">{summary.impact}</div>
          </div>
        )}
        {summary.responsibilities && (
          <div>
            <MDivider title="responsibilities" />
            <div className="whitespace-pre-line text-sm">
              {summary.responsibilities}
            </div>
          </div>
        )}
      </div>
    </MWell>
  );
};

EmployerRoleSummaryComponent.defaultProps = {
  onEditClicked: null,
  onDeleteClicked: null,
};

export default EmployerRoleSummaryComponent;
