import React from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { classNames } from "../../util/strings";

type MMarkdownProps = {
  className?: string | null;
  content: string;
};

const MMardownComponent = (props: MMarkdownProps) => {
  const { className, content } = props;

  return (
    <div className={classNames("markdown-container", className)}>
      <ReactMarkdown>{content}</ReactMarkdown>
    </div>
  );
};

MMardownComponent.defaultProps = {
  className: null,
};

export default MMardownComponent;
