/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GetResumeResponse
 */
export interface GetResumeResponse {
  /**
   *
   * @type {string}
   * @memberof GetResumeResponse
   */
  resume_url: string | null;
}

export function GetResumeResponseFromJSON(json: any): GetResumeResponse {
  return GetResumeResponseFromJSONTyped(json, false);
}

export function GetResumeResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetResumeResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    resume_url: json["resume_url"],
  };
}

export function GetResumeResponseToJSON(value?: GetResumeResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    resume_url: value.resume_url,
  };
}
