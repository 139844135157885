import React from "react";
import { toast } from "react-toastify";
import { CommonWellButtonProps } from "./common";
import MWellLinkButton from "./MWellLinkButton";

type MWellCopyLinkButtonProps = Omit<CommonWellButtonProps, "onClick"> & {
  url: string;
  toastCopy?: string;
};

const MWellCopyLinkButtonComponent = (props: MWellCopyLinkButtonProps) => {
  const { url, toastCopy, ...rest } = props;

  const onCopyLinkClicked = async () => {
    await navigator.clipboard.writeText(url);
    toast.success(toastCopy);
  };

  return <MWellLinkButton onClick={onCopyLinkClicked} {...rest} />;
};

MWellCopyLinkButtonComponent.defaultProps = {
  toastCopy: "link copied to clipboard",
};

export default MWellCopyLinkButtonComponent;
