import React, { Fragment, ReactNode, useState } from "react";
import { Transition } from "@headlessui/react";
import {
  UserNotificationRelatedDetail,
  UserNotificationRelatedDetailNotifTypeEnum,
} from "../../../api/models";
import MSpinner from "../MSpinner";
import ConnectionRequestNotificationBox from "./ConnectionRequestNotificationBox";
import MessageWithLinkNotificationBox from "./MessageWithLinkNotificationBox";

type NotificationsDropdownProps = {
  notifications: UserNotificationRelatedDetail[] | null;
  onClearClicked: (notif: string) => Promise<boolean>;
  onUnclearClicked: (notif: string) => Promise<boolean>;
  onAcceptConnectionRequest: (connection: string) => Promise<boolean>;
  onRejectConnectionRequest: (connection: string) => Promise<boolean>;
  moreLoading?: boolean;
};

const NotificationsDropdownComponent = (props: NotificationsDropdownProps) => {
  const {
    notifications,
    onClearClicked: onClearClickedInput,
    onUnclearClicked: onUnclearClickedInput,
    onAcceptConnectionRequest: onAcceptConnectionRequestInput,
    onRejectConnectionRequest: onRejectConnectionRequestInput,
    moreLoading,
  } = props;

  const [clearedNotifs, setClearedNotifs] = useState<number[]>([]);
  const [clearedUndos, setClearedUndos] = useState<number[]>([]);
  const [connectionRequestAccepts, setConnectionRequestAccepts] = useState<{
    [key: string]: boolean;
  }>({});

  const onUnclearClicked = async (notif: string, index: number) => {
    const success = await onUnclearClickedInput(notif);
    if (success) {
      setClearedNotifs((prev) => prev.filter((x) => x !== index));
    }
  };

  const onClearClicked = async (notif: string, index: number) => {
    if (clearedNotifs.includes(index)) {
      setClearedUndos((prev) => [...prev, index]);
      return;
    }
    const success = await onClearClickedInput(notif);
    if (success) {
      setClearedNotifs((prev) => [...prev, index]);
    }
  };

  const onAcceptConnectionRequest = async (
    notif: UserNotificationRelatedDetail,
    index: number
  ) => {
    await onAcceptConnectionRequestInput(notif.connection_request!.guid!);
    setClearedNotifs((prev) => [...prev, index]);
    setConnectionRequestAccepts((prev) => ({
      ...prev,
      [notif.guid!]: true,
    }));
  };

  const onRejectConnectionRequest = async (
    notif: UserNotificationRelatedDetail,
    index: number
  ) => {
    await onRejectConnectionRequestInput(notif.connection_request!.guid!);
    setClearedNotifs((prev) => [...prev, index]);
    setConnectionRequestAccepts((prev) => ({
      ...prev,
      [notif.guid!]: false,
    }));
  };

  const getNotifRow = (
    notif: UserNotificationRelatedDetail,
    index: number
  ): ReactNode => {
    if (clearedUndos.includes(index)) {
      return null;
    }
    switch (notif.notif_type) {
      case UserNotificationRelatedDetailNotifTypeEnum.connection_request: {
        return (
          <ConnectionRequestNotificationBox
            notif={notif}
            isCleared={clearedNotifs.includes(index)}
            isAccepted={connectionRequestAccepts[notif.guid!]}
            onClearClicked={() => onClearClicked(notif.guid!, index)}
            onAcceptClicked={() => onAcceptConnectionRequest(notif, index)}
            onRejectClicked={() => onRejectConnectionRequest(notif, index)}
            key={notif.guid!}
          />
        );
      }
      case UserNotificationRelatedDetailNotifTypeEnum.message_with_link: {
        return (
          <MessageWithLinkNotificationBox
            notif={notif}
            isCleared={clearedNotifs.includes(index)}
            onClearClicked={() => onClearClicked(notif.guid!, index)}
            onUndoClicked={() => onUnclearClicked(notif.guid!, index)}
            key={notif.guid!}
          />
        );
      }
      default: {
        throw new Error(`Unsupported notif type ${notif.notif_type}}`);
      }
    }
  };

  return (
    <>
      <div className="divide-y divide-m-light-gray">
        {notifications === null && (
          <div className="p-3 flex flex-row items-center justify-center h-12 w-full overflow-hidden">
            <MSpinner />
          </div>
        )}
        {notifications !== null &&
          (notifications.length === 0 ||
            notifications.length === clearedUndos.length) && (
            <div className="p-3 text-sm flex flex-row items-center justify-center">
              no notifications
            </div>
          )}
        {notifications !== null &&
          notifications.length > 0 &&
          notifications.map((notif, index) => getNotifRow(notif, index))}
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        show={moreLoading}
      >
        <div className="absolute w-full bottom-0 left-0 z-50 flex flex-row justify-center items-center mb-2">
          <div className="w-8 h-8">
            <MSpinner />
          </div>
        </div>
      </Transition>
    </>
  );
};

NotificationsDropdownComponent.defaultProps = {
  moreLoading: false,
};

export default NotificationsDropdownComponent;
