import React, { useEffect, useState } from "react";
import { GetBasicInfo, SetBasicInfo } from "../../../api/models";
import SidebarTextInputRow from "../../ui/screens/sidebarnav/SidebarTextInputRow";
import SettingsDateInputRow from "../../ui/screens/sidebarnav/SidebarDateInputRow";
import { areDatesEquivalent } from "../../../util/date";
import SidebarSection from "../../ui/screens/sidebarnav/SidebarSection";
import SidebarButtonRow from "../../ui/screens/sidebarnav/SidebarButtonRow";
import MButton from "../../ui/buttons/MButton";
import { useBusyWatcher } from "../../../util/hooks";

type SettingsBasicsScreenProps = {
  loading: boolean;
  basics: GetBasicInfo | null;
  onBasicsUpdated: (basics: SetBasicInfo) => void;
  errors: { [key: string]: string[] };
  onResetProfileClicked: () => void;
};

const SettingsBasicsScreenComponent = (props: SettingsBasicsScreenProps) => {
  const {
    loading,
    basics,
    onBasicsUpdated: onBasicsUpdatedInput,
    errors,
    onResetProfileClicked,
  } = props;

  const [name, setName] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [employer, setEmployer] = useState<string>("");
  const [dob, setDob] = useState<Date | null>(null);
  const [busy, _] = useBusyWatcher();

  const onBasicsUpdated = () => {
    onBasicsUpdatedInput({
      name,
      location,
      title,
      employer,
      dob: dob!,
    });
  };

  useEffect(() => {
    if (basics !== null) {
      setName(basics.name ?? "");
      setLocation(basics.location ?? "");
      setTitle(basics.title ?? "");
      setEmployer(basics.employer ?? "");
      setDob(basics.dob);
    }
  }, [basics]);

  return (
    <>
      <SidebarSection
        title="Basic Information"
        description={
          <>
            Update the basic information we have on record about you. Note that
            everything here <b>except for date of birth</b> is displayed on your
            profile.
          </>
        }
      >
        <>
          <SidebarTextInputRow
            label="Full name"
            value={name}
            onValueUpdated={setName}
            onUpdateClicked={onBasicsUpdated}
            loading={loading}
            placeholder="name"
            updateDisabled={name === basics?.name}
            errors={errors.name}
            tooltip="this is an awesome test thank you"
          />
          <SidebarTextInputRow
            label="Location"
            value={location}
            onValueUpdated={setLocation}
            onUpdateClicked={onBasicsUpdated}
            loading={loading}
            placeholder="city, state"
            updateDisabled={location === basics?.location}
            errors={errors.location}
          />
          <SidebarTextInputRow
            label="Title"
            value={title}
            onValueUpdated={setTitle}
            onUpdateClicked={onBasicsUpdated}
            loading={loading}
            placeholder="cool person"
            updateDisabled={title === basics?.title}
            errors={errors.title}
          />
          <SidebarTextInputRow
            label="Employer"
            value={employer}
            onValueUpdated={setEmployer}
            onUpdateClicked={onBasicsUpdated}
            loading={loading}
            placeholder="ACME, co."
            updateDisabled={employer === basics?.employer}
            errors={errors.employer}
          />
          <SettingsDateInputRow
            label="Date of birth"
            loading={loading}
            updateDisabled={
              areDatesEquivalent(dob, basics?.dob ?? null) || dob === null
            }
            errors={errors.dob}
            value={dob}
            onValueUpdated={setDob}
            onUpdateClicked={onBasicsUpdated}
          />
        </>
      </SidebarSection>
      <SidebarSection
        title={<div className="text-danger">Danger zone</div>}
        description="Functions in this section can seriously change/harm your account if used accidentally. Proceed with caution!"
      >
        <SidebarButtonRow
          label="Reset account"
          tooltip="This will completely reset your account and returns you to the onboarding screen. All questions you have answered and profile content you have set will be deleted."
          button={
            <MButton
              kind="danger"
              disabled={busy}
              onClick={onResetProfileClicked}
            >
              reset your account
            </MButton>
          }
        />
      </SidebarSection>
    </>
  );
};

export default SettingsBasicsScreenComponent;
