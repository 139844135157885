/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ManualTodo
 */
export interface ManualTodo {
  /**
   *
   * @type {string}
   * @memberof ManualTodo
   */
  description: string;
  /**
   *
   * @type {boolean}
   * @memberof ManualTodo
   */
  completed: boolean;
  /**
   *
   * @type {string}
   * @memberof ManualTodo
   */
  href: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ManualTodo
   */
  is_required: boolean | null;
}

export function ManualTodoFromJSON(json: any): ManualTodo {
  return ManualTodoFromJSONTyped(json, false);
}

export function ManualTodoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ManualTodo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    description: json["description"],
    completed: json["completed"],
    href: json["href"],
    is_required: json["is_required"],
  };
}

export function ManualTodoToJSON(value?: ManualTodo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    description: value.description,
    completed: value.completed,
    href: value.href,
    is_required: value.is_required,
  };
}
