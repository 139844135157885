import React, { forwardRef } from "react";
import { useUniqueId } from "../../util/hooks";
import { classNames } from "../../util/strings";

type MCheckboxProps = React.ComponentPropsWithoutRef<"input"> & {
  label?: string | null;
};

const MCheckboxComponent = forwardRef<HTMLInputElement, MCheckboxProps>(
  ({ label, ...props }, ref) => {
    const uniqueId = useUniqueId();

    return (
      <div className="relative flex flex-row gap-3 items-center">
        {label && (
          <div className="text-sm leading-6">
            <label
              htmlFor={uniqueId}
              className="select-none font-medium text-m-dark-gray"
            >
              {label}
            </label>
          </div>
        )}
        <div className="flex h-6 items-center">
          <input
            ref={ref}
            id={uniqueId}
            type="checkbox"
            className={classNames(
              "h-6 w-6 rounded border-m-gray",
              props.disabled
                ? "cursor-not-allowed text-m-gray focus:ring-m-gray"
                : "text-primary focus:ring-primary cursor-pointer"
            )}
            {...props}
          />
        </div>
      </div>
    );
  }
);

MCheckboxComponent.defaultProps = {
  label: null,
};

export default MCheckboxComponent;
