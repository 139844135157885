/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  EmptyResponseWrapper,
  EmptyResponseWrapperFromJSON,
  EmptyResponseWrapperToJSON,
  GetAssessmentResponseSerializer,
  GetAssessmentResponseSerializerFromJSON,
  GetAssessmentResponseSerializerToJSON,
  GetAssessmentsResponseSerializer,
  GetAssessmentsResponseSerializerFromJSON,
  GetAssessmentsResponseSerializerToJSON,
  GetInferenceResultsResponseSerializer,
  GetInferenceResultsResponseSerializerFromJSON,
  GetInferenceResultsResponseSerializerToJSON,
  GetNextPromptForAssessmentResponseSerializer,
  GetNextPromptForAssessmentResponseSerializerFromJSON,
  GetNextPromptForAssessmentResponseSerializerToJSON,
  GetPromptForAssessmentResponseSerializer,
  GetPromptForAssessmentResponseSerializerFromJSON,
  GetPromptForAssessmentResponseSerializerToJSON,
  MarkAssessmentAsCompletedRequest,
  MarkAssessmentAsCompletedRequestFromJSON,
  MarkAssessmentAsCompletedRequestToJSON,
  ProcessAssessmentRequest,
  ProcessAssessmentRequestFromJSON,
  ProcessAssessmentRequestToJSON,
  QuestionnaireInferenceDetailSerializer,
  QuestionnaireInferenceDetailSerializerFromJSON,
  QuestionnaireInferenceDetailSerializerToJSON,
  SetTargetResponsesRequest,
  SetTargetResponsesRequestFromJSON,
  SetTargetResponsesRequestToJSON,
} from "../models";

export interface GetAssessmentRequest {
  assessment: string;
}

export interface GetAssessmentResultRequest {
  inference: string;
}

export interface GetNextPromptForAssessmentRequest {
  assessment: string;
}

export interface GetPromptForAssessmentRequest {
  assessment: string;
  prompt: string;
}

export interface MarkAssessmentAsCompletedOperationRequest {
  data: MarkAssessmentAsCompletedRequest;
}

export interface ProcessAssessmentOperationRequest {
  data: ProcessAssessmentRequest;
}

export interface SetAssessmentResponsesRequest {
  data: SetTargetResponsesRequest;
}

/**
 *
 */
export class AssessmentsApi extends runtime.BaseAPI {
  /**
   * Get the specified assessment if it\'s owned by the requesting user.
   */
  async getAssessmentRaw(
    requestParameters: GetAssessmentRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetAssessmentResponseSerializer>> {
    if (
      requestParameters.assessment === null ||
      requestParameters.assessment === undefined
    ) {
      throw new runtime.RequiredError(
        "assessment",
        "Required parameter requestParameters.assessment was null or undefined when calling getAssessment."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.assessment !== undefined) {
      queryParameters["assessment"] = requestParameters.assessment;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/assessments/get`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetAssessmentResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the specified assessment if it\'s owned by the requesting user.
   */
  async getAssessment(
    requestParameters: GetAssessmentRequest,
    initOverrides?: RequestInit
  ): Promise<GetAssessmentResponseSerializer> {
    const response = await this.getAssessmentRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get the generated results from the referenced questionnaire inference.
   */
  async getAssessmentResultRaw(
    requestParameters: GetAssessmentResultRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetInferenceResultsResponseSerializer>> {
    if (
      requestParameters.inference === null ||
      requestParameters.inference === undefined
    ) {
      throw new runtime.RequiredError(
        "inference",
        "Required parameter requestParameters.inference was null or undefined when calling getAssessmentResult."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.inference !== undefined) {
      queryParameters["inference"] = requestParameters.inference;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/assessments/get-result`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetInferenceResultsResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the generated results from the referenced questionnaire inference.
   */
  async getAssessmentResult(
    requestParameters: GetAssessmentResultRequest,
    initOverrides?: RequestInit
  ): Promise<GetInferenceResultsResponseSerializer> {
    const response = await this.getAssessmentResultRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get the next prompt for the referenced assessment and requesting user. The purpose of this endpoint is to easily return the next question that the user should answer.
   */
  async getNextPromptForAssessmentRaw(
    requestParameters: GetNextPromptForAssessmentRequest,
    initOverrides?: RequestInit
  ): Promise<
    runtime.ApiResponse<GetNextPromptForAssessmentResponseSerializer>
  > {
    if (
      requestParameters.assessment === null ||
      requestParameters.assessment === undefined
    ) {
      throw new runtime.RequiredError(
        "assessment",
        "Required parameter requestParameters.assessment was null or undefined when calling getNextPromptForAssessment."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.assessment !== undefined) {
      queryParameters["assessment"] = requestParameters.assessment;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/assessments/get-next-prompt`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetNextPromptForAssessmentResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the next prompt for the referenced assessment and requesting user. The purpose of this endpoint is to easily return the next question that the user should answer.
   */
  async getNextPromptForAssessment(
    requestParameters: GetNextPromptForAssessmentRequest,
    initOverrides?: RequestInit
  ): Promise<GetNextPromptForAssessmentResponseSerializer> {
    const response = await this.getNextPromptForAssessmentRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get the prompt with the referenced ID for the referenced assessment and requesting user.
   */
  async getPromptForAssessmentRaw(
    requestParameters: GetPromptForAssessmentRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetPromptForAssessmentResponseSerializer>> {
    if (
      requestParameters.assessment === null ||
      requestParameters.assessment === undefined
    ) {
      throw new runtime.RequiredError(
        "assessment",
        "Required parameter requestParameters.assessment was null or undefined when calling getPromptForAssessment."
      );
    }

    if (
      requestParameters.prompt === null ||
      requestParameters.prompt === undefined
    ) {
      throw new runtime.RequiredError(
        "prompt",
        "Required parameter requestParameters.prompt was null or undefined when calling getPromptForAssessment."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.assessment !== undefined) {
      queryParameters["assessment"] = requestParameters.assessment;
    }

    if (requestParameters.prompt !== undefined) {
      queryParameters["prompt"] = requestParameters.prompt;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/assessments/get-prompt`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetPromptForAssessmentResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the prompt with the referenced ID for the referenced assessment and requesting user.
   */
  async getPromptForAssessment(
    requestParameters: GetPromptForAssessmentRequest,
    initOverrides?: RequestInit
  ): Promise<GetPromptForAssessmentResponseSerializer> {
    const response = await this.getPromptForAssessmentRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get all the assessments available for the authenticated user.
   */
  async listAssessmentsRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetAssessmentsResponseSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/assessments/list`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetAssessmentsResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get all the assessments available for the authenticated user.
   */
  async listAssessments(
    initOverrides?: RequestInit
  ): Promise<GetAssessmentsResponseSerializer> {
    const response = await this.listAssessmentsRaw(initOverrides);
    return await response.value();
  }

  /**
   * Mark the referenced assessment as completed by the requesting user.
   */
  async markAssessmentAsCompletedRaw(
    requestParameters: MarkAssessmentAsCompletedOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling markAssessmentAsCompleted."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/assessments/mark-as-completed`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: MarkAssessmentAsCompletedRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Mark the referenced assessment as completed by the requesting user.
   */
  async markAssessmentAsCompleted(
    requestParameters: MarkAssessmentAsCompletedOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.markAssessmentAsCompletedRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Process the referenced assessment for the requesting user. This will create a new questionnaire inference if one does not already exist.
   */
  async processAssessmentRaw(
    requestParameters: ProcessAssessmentOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<QuestionnaireInferenceDetailSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling processAssessment."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/assessments/process`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ProcessAssessmentRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      QuestionnaireInferenceDetailSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Process the referenced assessment for the requesting user. This will create a new questionnaire inference if one does not already exist.
   */
  async processAssessment(
    requestParameters: ProcessAssessmentOperationRequest,
    initOverrides?: RequestInit
  ): Promise<QuestionnaireInferenceDetailSerializer> {
    const response = await this.processAssessmentRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Set answers to the prompts in the target questionnaire for the referenced assessment.
   */
  async setAssessmentResponsesRaw(
    requestParameters: SetAssessmentResponsesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling setAssessmentResponses."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/assessments/set-responses`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SetTargetResponsesRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Set answers to the prompts in the target questionnaire for the referenced assessment.
   */
  async setAssessmentResponses(
    requestParameters: SetAssessmentResponsesRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.setAssessmentResponsesRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
