import React, { ReactElement, useEffect, useState } from "react";
import MSubtext from "../../../ui/MSubtext";
import MSearchBox from "../../../ui/MSearchBox";
import ProfileCard from "../../../ui/cards/ProfileCard";
import { classNames } from "../../../../util/strings";
import { commentText } from "../../../../util/style";
import MBackButton from "../../../ui/buttons/MBackButton";
import MTitle from "../../../ui/MTitle";
import CenterColumnStickyButtonScreen from "../../../ui/screens/CenterColumnStickyButtonScreen";
import MNextButton from "../../../ui/buttons/MNextButton";
import {
  UserGroup,
  SearchForConnectionResponse,
  UserProfileSummary,
} from "../../../../api/models";

type EditGroupMembersScreenProps = {
  group: UserGroup;
  searchTerm?: string | null;
  connections: SearchForConnectionResponse | null;
  errors: string[];
  onSearchTermUpdated: (newTerm: string) => void;
  onNextClicked: (
    usersToAdd: UserProfileSummary[],
    usersToRemove: UserProfileSummary[]
  ) => void;
  onBackClicked: () => void;
};

const EditGroupMembersScreenComponent = (
  props: EditGroupMembersScreenProps
) => {
  const {
    group,
    searchTerm,
    connections,
    errors: errorsInput,
    onSearchTermUpdated,
    onNextClicked,
    onBackClicked,
  } = props;

  const [selectedUsers, setSelectedUsers] = useState<UserProfileSummary[]>([]);
  const [unselectedUsers, setUnselectedUsers] = useState<UserProfileSummary[]>(
    []
  );
  const [busy, _] = useState<boolean>(false);

  // Updates selected and unselected users as input to add/remove members
  const onSelectUserClicked = (selectedUser: UserProfileSummary) => {
    if (selectedUser.id === group.group_owner.id) {
      return;
    }
    const indexOfUsersSelected = selectedUsers.findIndex(
      (user) => user.id === selectedUser.id
    );
    const indexOfUsersToRemove = unselectedUsers.findIndex(
      (user) => user.id === selectedUser.id
    );

    const selectedUsersCopy = [...selectedUsers];
    const unselectedUsersCopy = [...unselectedUsers];

    if (indexOfUsersSelected === -1) {
      selectedUsersCopy.push(selectedUser);
      if (indexOfUsersToRemove !== -1) {
        unselectedUsersCopy.splice(indexOfUsersToRemove, 1);
      }
    } else {
      selectedUsersCopy.splice(indexOfUsersSelected, 1);
      if (indexOfUsersToRemove === -1) {
        unselectedUsersCopy.push(selectedUser);
      }
    }
    setSelectedUsers([...selectedUsersCopy]);
    setUnselectedUsers([...unselectedUsersCopy]);
  };

  const getButtons = (): ReactElement[] => [
    <MNextButton onClick={() => onNextClicked(selectedUsers, unselectedUsers)}>
      save group members
    </MNextButton>,
    <MBackButton
      kind="secondary"
      key="back-button"
      onClick={onBackClicked}
      disabled={busy}
    />,
  ];

  useEffect(() => {
    if (group) {
      const memberSummaries = group.members.map((member) => member.summary);
      setSelectedUsers(memberSummaries);
    }
  }, []);

  return (
    <CenterColumnStickyButtonScreen buttons={getButtons()} stacked fullWidth>
      <MTitle text="select your people" className="text-center" />
      <MSubtext
        text="users to include (max 8 in a group)"
        className="text-center"
      />
      <div className="flex flex-col gap-2">
        {selectedUsers.length > 0 ? (
          selectedUsers.map((user) => (
            <ProfileCard
              user={user}
              key={user.id!}
              raised={unselectedUsers.findIndex((u) => u.id === user.id) !== -1}
              onClick={() => onSelectUserClicked(user)}
            />
          ))
        ) : (
          <MSubtext
            text="you haven't added any users to your group. once you do, they will be listed here."
            className="text-center"
          />
        )}
      </div>
      <MSearchBox
        className="mt-3 mb-1 mx-auto"
        searchTerm={searchTerm}
        onSearchTermUpdated={onSearchTermUpdated}
        errors={errorsInput}
        placeholder="search your people by username or name"
      />
      <MSubtext
        text="your people (click to add/remove)"
        className="text-center"
      />
      <div className="flex flex-col gap-2">
        {connections &&
          connections.results.length > 0 &&
          connections.results.map((user) => (
            <ProfileCard
              user={user}
              key={user.id}
              disabled={busy}
              onClick={() => onSelectUserClicked(user)}
              separateWindow={false}
            />
          ))}
        {(!connections || connections.results.length === 0) && (
          <div
            className={classNames(
              "flex w-full flex-row justify-center my-4",
              commentText
            )}
          >
            <div className="max-w-xl text-center">
              {searchTerm === ""
                ? "you haven't added anyone to your people yet. once you do they'll all be listed here."
                : "no results found."}
            </div>
          </div>
        )}
      </div>
    </CenterColumnStickyButtonScreen>
  );
};

EditGroupMembersScreenComponent.defaultProps = {
  searchTerm: null,
};

export default EditGroupMembersScreenComponent;
