import React, { useEffect, useState } from "react";
import { MNavProps } from "../../../ui/nav/MNav";
import { useBackTimer, useBusyWatcher } from "../../../../util/hooks";
import { useWrappedConnector } from "../../../../api/connector";
import WizardScreen, {
  WizardScreenPanel,
} from "../../../ui/screens/WizardScreen";
import LoadingScreen from "../../../ui/screens/LoadingScreen";
import {
  errorMessagesForKeyFromResponse,
  errorMessagesFromResponse,
} from "../../../../api/helpers";
import BaseNavbarPage from "../../BaseNavbarPage";
import { AddUserLinkRequest, LinkSummary } from "../../../../api/models";
import ListLinksScreen from "./ListLinksScreen";
import { useAsyncConfirmationDialog } from "../../../dialogs/hooks";
import EditLinkScreen from "./EditLinkScreen";

type LinksPageProps = {
  nextUrl: string;
  navbarProps: MNavProps;
};

enum LinksStage {
  LOADING,
  LIST,
  ADD,
  EDIT,
}

const LinksPageComponent = (props: LinksPageProps) => {
  const { nextUrl, navbarProps } = props;

  const [errors, setErrors] = useState<string[]>([]);
  const [stage, setStage] = useState<LinksStage>(LinksStage.LOADING);
  const [links, setLinks] = useState<LinkSummary[]>([]);
  const [link, setLink] = useState<LinkSummary | null>(null);
  const [linkErrors, setLinkErrors] = useState<{ [key: string]: string[] }>({});
  const [isBack, asBack] = useBackTimer(stage);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);
  const confirmDialog = useAsyncConfirmationDialog();

  const goToStage = async (nextStage: LinksStage) => {
    setErrors([]);
    setLinkErrors({});
    // eslint-disable-next-line default-case
    switch (nextStage) {
      case LinksStage.LIST: {
        setErrors([]);
        try {
          const response = await connector.listLinks();
          setLinks(response.c!.links);
        } catch (e: any) {
          const parsed = await errorMessagesForKeyFromResponse(e, "", true);
          setErrors(parsed);
        }
        break;
      }
    }
    setStage(nextStage);
  };

  const onEditLinkClicked = async (editLink: LinkSummary) => {
    setLink(editLink);
    await goToStage(LinksStage.EDIT);
  };

  const onAddLinkClicked = async () => {
    setLink(null);
    await goToStage(LinksStage.ADD);
  };

  const onDeleteLinkClicked = async (deleteLink: LinkSummary) => {
    const result = await confirmDialog.confirm({
      title: "delete link?",
      body: "are you sure you want to delete this link? this action cannot be undone.",
    });
    if (!result) {
      return;
    }
    await connector.deleteLink(deleteLink.guid!);
    const newLinks = await connector.listLinks();
    setLinks(newLinks.c!.links);
  };

  const onSaveLinkClicked = async (saveLink: AddUserLinkRequest) => {
    setLinkErrors({});
    try {
      await connector.addLink(saveLink);
    } catch (e: any) {
      const responseErrors = await errorMessagesFromResponse(e);
      setLinkErrors(responseErrors);
      return;
    }
    await goToStage(LinksStage.LIST);
  };

  const onUpdateLinkClicked = async (updateLink: AddUserLinkRequest) => {
    setLinkErrors({});
    try {
      await connector.updateLink({
        link: link!.guid!,
        ...updateLink,
      });
    } catch (e: any) {
      const responseErrors = await errorMessagesFromResponse(e);
      setLinkErrors(responseErrors);
      return;
    }
    await goToStage(LinksStage.LIST);
  };

  const getElements = (): WizardScreenPanel[] => [
    {
      stage: LinksStage.LOADING,
      content: (
        <LoadingScreen
          errors={errors}
          onBackClicked={() => window.history.back()}
        />
      ),
    },
    {
      stage: LinksStage.LIST,
      content: links && (
        <ListLinksScreen
          links={links}
          onEditLinkClicked={onEditLinkClicked}
          onAddLinkClicked={onAddLinkClicked}
          onDeleteLinkClicked={onDeleteLinkClicked}
          onDoneClicked={() => {
            window.location.href = nextUrl;
          }}
        />
      ),
    },
    {
      stage: LinksStage.EDIT,
      content: link && (
        <EditLinkScreen
          link={link}
          errors={linkErrors}
          onSaveClicked={onUpdateLinkClicked}
          onBackClicked={asBack(() => goToStage(LinksStage.LIST))}
        />
      ),
    },
    {
      stage: LinksStage.ADD,
      content: (
        <EditLinkScreen
          errors={linkErrors}
          onSaveClicked={onSaveLinkClicked}
          onBackClicked={asBack(() => goToStage(LinksStage.LIST))}
        />
      ),
    },
  ];

  useEffect(() => {
    goToStage(LinksStage.LIST);
  }, []);

  return (
    <BaseNavbarPage navbarProps={navbarProps}>
      {confirmDialog.dialog}
      <WizardScreen isBack={isBack} elements={getElements()} stage={stage} />
    </BaseNavbarPage>
  );
};

export default LinksPageComponent;
