import React from "react";
import { GlimpseSummary } from "../../../../api/models";
import MCard from "../../../ui/cards/MCard";
import { titleText } from "../../../../util/style";
import { addOriginToPath, addQueryParamToPath } from "../../../../util/url";
import MShowMoreText from "../../../ui/MShowMoreText";
import VisibilityFooter from "../../../ui/cards/VisibilityFooter";
import { useBusyWatcher } from "../../../../util/hooks";
import MWellDeleteButton from "../../../ui/wells/MWellDeleteButton";
import MWellLeaveButton from "../../../ui/wells/MWellLeaveButton";
import MWellCopyLinkButton from "../../../ui/wells/MWellCopyLinkButton";
import MWellEditButton from "../../../ui/wells/MWellEditButton";

type GlimpseSummaryCardProps = {
  glimpse: GlimpseSummary;
  viewGlimpseUrl: string;
  editGlimpseUrl: string;
  onToggleVisibilityClicked: () => void;
  onDeleteClicked: () => void;
};

const GlimpseSummaryCardComponent = (props: GlimpseSummaryCardProps) => {
  const {
    glimpse,
    viewGlimpseUrl,
    onToggleVisibilityClicked,
    onDeleteClicked,
    editGlimpseUrl,
  } = props;

  const viewGlimpseLink = addOriginToPath(
    addQueryParamToPath(viewGlimpseUrl, "id", glimpse.guid!)
  );
  const editGlimpseLink = addQueryParamToPath(
    editGlimpseUrl,
    "id",
    glimpse.guid!
  );
  const glimpseTitle = glimpse.title ?? "untitled";
  const [busy, _] = useBusyWatcher();

  return (
    <MCard
      footer={
        <VisibilityFooter
          onToggleVisibilityClicked={onToggleVisibilityClicked}
          visibilityCopy="live"
          toggleVisibilityDisabled={busy}
          isVisible={glimpse.visible}
          buttons={[
            <MWellCopyLinkButton key="copy" url={viewGlimpseLink} />,
            <MWellEditButton key="edit" href={editGlimpseLink} />,
            <MWellDeleteButton
              key="delete"
              disabled={busy}
              onClick={onDeleteClicked}
            />,
          ]}
        />
      }
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between items-start gap-2">
          <div className="flex flex-col">
            <div className={titleText}>{glimpseTitle}</div>
            <div className="text-xs font-light">
              created {glimpse.time_created!.toLocaleString()}
            </div>
          </div>
          <div>
            <MWellLeaveButton
              href={viewGlimpseLink}
              label={`view '${glimpseTitle}' glimpse`}
            />
          </div>
        </div>
        {glimpse.description && (
          <div className="text-sm">
            <MShowMoreText content={glimpse.description} />
          </div>
        )}
      </div>
    </MCard>
  );
};

export default GlimpseSummaryCardComponent;
