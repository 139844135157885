/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  AIQuestionAndResponseDetail,
  AIQuestionAndResponseDetailFromJSON,
  AIQuestionAndResponseDetailFromJSONTyped,
  AIQuestionAndResponseDetailToJSON,
} from "./AIQuestionAndResponseDetail";

/**
 *
 * @export
 * @interface AskUserQuestionResponse
 */
export interface AskUserQuestionResponse {
  /**
   *
   * @type {AIQuestionAndResponseDetail}
   * @memberof AskUserQuestionResponse
   */
  qnr: AIQuestionAndResponseDetail;
}

export function AskUserQuestionResponseFromJSON(
  json: any
): AskUserQuestionResponse {
  return AskUserQuestionResponseFromJSONTyped(json, false);
}

export function AskUserQuestionResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AskUserQuestionResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    qnr: AIQuestionAndResponseDetailFromJSON(json["qnr"]),
  };
}

export function AskUserQuestionResponseToJSON(
  value?: AskUserQuestionResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    qnr: AIQuestionAndResponseDetailToJSON(value.qnr),
  };
}
