import React, { ReactElement } from "react";
import { classNames } from "../../util/strings";
import { useUniqueId } from "../../util/hooks";
import MTooltip from "./MTooltip";
import { MAX_STEP_TOOLTIP_LENGTH } from "../../util/constants";

export type StepStatus = "complete" | "current" | "upcoming";
export type Kind = "regular" | "light";

export type Step = {
  name: string;
  status: StepStatus;
  key: string;
};

export type MStepsCirclesProps = {
  steps: Step[];
  className?: string | null;
  onStepClicked?: ((stepIndex: number) => void) | null;
  minClickableIndex?: number | null;
  maxClickableIndex?: number | null;
  kind?: Kind;
  maxTooltipLength?: number;
};

const MStepsCirclesComponent = (props: MStepsCirclesProps) => {
  const {
    steps,
    className,
    onStepClicked,
    minClickableIndex: minClickableIndexInput,
    maxClickableIndex: maxClickableIndexInput,
    kind,
    maxTooltipLength,
  } = props;

  const tooltipId = useUniqueId();
  const minClickableIndex = minClickableIndexInput ?? 0;
  const maxClickableIndex = maxClickableIndexInput ?? steps.length - 1;

  const maybeButton = (step: Step, index: number): ReactElement => {
    const toReturn = (
      <>
        {step.status === "complete" && (
          <div
            className={classNames(
              "block h-2.5 w-2.5 rounded-full",
              kind === "regular"
                ? "bg-primary hover:brightness-95"
                : "bg-primary brightness-75 hover:bg-m-white hover:brightness-100"
            )}
          >
            <span className="sr-only">{step.name}</span>
          </div>
        )}
        {step.status === "current" && (
          <div
            className="relative flex items-center justify-center"
            aria-current="step"
          >
            <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
              <span
                className={classNames(
                  "h-full w-full rounded-full",
                  kind === "regular" ? "bg-primary/30" : "bg-secondary"
                )}
              />
            </span>
            <span
              className="relative block h-2.5 w-2.5 rounded-full bg-primary"
              aria-hidden="true"
            />
            <span className="sr-only">{step.name}</span>
          </div>
        )}
        {step.status === "upcoming" && (
          <div className="block h-2.5 w-2.5 rounded-full bg-m-light-gray hover:brightness-95">
            <span className="sr-only">{step.name}</span>
          </div>
        )}
      </>
    );
    let stepName = step.name;
    if (stepName.length > maxTooltipLength!) {
      stepName = `${stepName.substring(0, maxTooltipLength! - 3)}...`;
    }
    if (!onStepClicked) {
      return (
        <li
          key={step.key}
          data-tooltip-id={tooltipId}
          data-tooltip-content={stepName}
        >
          {toReturn}
        </li>
      );
    }
    const canBeClicked =
      index >= minClickableIndex && index <= maxClickableIndex;
    return (
      <li
        key={step.key}
        data-tooltip-id={tooltipId}
        data-tooltip-content={stepName}
      >
        <button
          type="button"
          disabled={!canBeClicked}
          onClick={() => onStepClicked(index)}
          className={canBeClicked ? "cursor-pointer" : "cursor-not-allowed"}
        >
          {toReturn}
        </button>
      </li>
    );
  };

  return (
    <nav
      className={classNames("flex items-center justify-center", className)}
      aria-label="Progress"
    >
      <p className="text-sm font-medium">
        {steps.findIndex((step) => step.status === "current") + 1} of{" "}
        {steps.length}
      </p>
      <ol className="ml-6 flex items-center space-x-3">
        {steps.map(maybeButton)}
      </ol>
      <MTooltip id={tooltipId} place="bottom" />
    </nav>
  );
};

MStepsCirclesComponent.defaultProps = {
  className: null,
  onStepClicked: null,
  minClickableIndex: null,
  maxClickableIndex: null,
  kind: "regular" as Kind,
  maxTooltipLength: MAX_STEP_TOOLTIP_LENGTH,
};

export default MStepsCirclesComponent;
