/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  CreateGroupCollabRequest,
  CreateGroupCollabRequestFromJSON,
  CreateGroupCollabRequestToJSON,
  CreateGroupRequest,
  CreateGroupRequestFromJSON,
  CreateGroupRequestToJSON,
  DeleteGenerationRequest,
  DeleteGenerationRequestFromJSON,
  DeleteGenerationRequestToJSON,
  DeleteGroupQuestionAndAnswerRequest,
  DeleteGroupQuestionAndAnswerRequestFromJSON,
  DeleteGroupQuestionAndAnswerRequestToJSON,
  DeleteGroupRequest,
  DeleteGroupRequestFromJSON,
  DeleteGroupRequestToJSON,
  EmptyResponseWrapper,
  EmptyResponseWrapperFromJSON,
  EmptyResponseWrapperToJSON,
  GroupCollabSerializer,
  GroupCollabSerializerFromJSON,
  GroupCollabSerializerToJSON,
  GroupQuestionAndAnswerRequest,
  GroupQuestionAndAnswerRequestFromJSON,
  GroupQuestionAndAnswerRequestToJSON,
  GroupQuestionAndAnswerResponseSerializer,
  GroupQuestionAndAnswerResponseSerializerFromJSON,
  GroupQuestionAndAnswerResponseSerializerToJSON,
  GroupSummaryRequest,
  GroupSummaryRequestFromJSON,
  GroupSummaryRequestToJSON,
  GroupSummarySerializer,
  GroupSummarySerializerFromJSON,
  GroupSummarySerializerToJSON,
  ListGroupCollabsResponseSerializer,
  ListGroupCollabsResponseSerializerFromJSON,
  ListGroupCollabsResponseSerializerToJSON,
  ListGroupQuestionAndAnswerResponseSerializer,
  ListGroupQuestionAndAnswerResponseSerializerFromJSON,
  ListGroupQuestionAndAnswerResponseSerializerToJSON,
  ListGroupSummaryResponseSerializer,
  ListGroupSummaryResponseSerializerFromJSON,
  ListGroupSummaryResponseSerializerToJSON,
  ListGroupsResponseSerializer,
  ListGroupsResponseSerializerFromJSON,
  ListGroupsResponseSerializerToJSON,
  RateLLMResultsRequest,
  RateLLMResultsRequestFromJSON,
  RateLLMResultsRequestToJSON,
  RetrieveDirResponseSerializer,
  RetrieveDirResponseSerializerFromJSON,
  RetrieveDirResponseSerializerToJSON,
  SaveGenerationRequest,
  SaveGenerationRequestFromJSON,
  SaveGenerationRequestToJSON,
  SelectFilesResponseSerializer,
  SelectFilesResponseSerializerFromJSON,
  SelectFilesResponseSerializerToJSON,
  UpdateGroupRequest,
  UpdateGroupRequestFromJSON,
  UpdateGroupRequestToJSON,
  UpdateUsersInGroupRequest,
  UpdateUsersInGroupRequestFromJSON,
  UpdateUsersInGroupRequestToJSON,
  UserGroupSerializer,
  UserGroupSerializerFromJSON,
  UserGroupSerializerToJSON,
} from "../models";

export interface AddUsersRequest {
  data: UpdateUsersInGroupRequest;
}

export interface AskQuestionAboutGroupRequest {
  data: GroupQuestionAndAnswerRequest;
}

export interface CreateGroupOperationRequest {
  data: CreateGroupRequest;
}

export interface CreateGroupCollabOperationRequest {
  data: CreateGroupCollabRequest;
}

export interface DeleteGenerationOperationRequest {
  data: DeleteGenerationRequest;
}

export interface DeleteGroupOperationRequest {
  data: DeleteGroupRequest;
}

export interface DeleteGroupQnaRequest {
  data: DeleteGroupQuestionAndAnswerRequest;
}

export interface GetGroupRequest {
  group: string;
}

export interface GetGroupCollabRequest {
  group_collab_guid: string;
}

export interface GetGroupQnaRequest {
  group_qna_guid: string;
}

export interface GetGroupSummaryRequest {
  group_summary_guid: string;
}

export interface RateLlmResultRequest {
  data: RateLLMResultsRequest;
}

export interface RemoveUsersRequest {
  data: UpdateUsersInGroupRequest;
}

export interface SaveGenerationOperationRequest {
  data: SaveGenerationRequest;
}

export interface SelectFilesRequest {
  filename1: string;
  filename2: string;
}

export interface SummarizeGroupRequest {
  data: GroupSummaryRequest;
}

export interface UpdateGroupOperationRequest {
  data: UpdateGroupRequest;
}

/**
 *
 */
export class GroupsApi extends runtime.BaseAPI {
  /**
   * Adds users to specified group if they are connected.
   */
  async addUsersRaw(
    requestParameters: AddUsersRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling addUsers."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/groups/add-users`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateUsersInGroupRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Adds users to specified group if they are connected.
   */
  async addUsers(
    requestParameters: AddUsersRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.addUsersRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Ask a question about a group of users
   */
  async askQuestionAboutGroupRaw(
    requestParameters: AskQuestionAboutGroupRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GroupQuestionAndAnswerResponseSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling askQuestionAboutGroup."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/groups/ai/ask-question-about-group`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: GroupQuestionAndAnswerRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GroupQuestionAndAnswerResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Ask a question about a group of users
   */
  async askQuestionAboutGroup(
    requestParameters: AskQuestionAboutGroupRequest,
    initOverrides?: RequestInit
  ): Promise<GroupQuestionAndAnswerResponseSerializer> {
    const response = await this.askQuestionAboutGroupRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create a new group with calling user as owner.
   */
  async createGroupRaw(
    requestParameters: CreateGroupOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<UserGroupSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling createGroup."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/groups/create`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CreateGroupRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserGroupSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Create a new group with calling user as owner.
   */
  async createGroup(
    requestParameters: CreateGroupOperationRequest,
    initOverrides?: RequestInit
  ): Promise<UserGroupSerializer> {
    const response = await this.createGroupRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create an group collab based on 2 groups of user and a list of questions
   */
  async createGroupCollabRaw(
    requestParameters: CreateGroupCollabOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GroupCollabSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling createGroupCollab."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/groups/ai/create-group-collab`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CreateGroupCollabRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GroupCollabSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Create an group collab based on 2 groups of user and a list of questions
   */
  async createGroupCollab(
    requestParameters: CreateGroupCollabOperationRequest,
    initOverrides?: RequestInit
  ): Promise<GroupCollabSerializer> {
    const response = await this.createGroupCollabRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Delete the referenced Group QNA generation.
   */
  async deleteGenerationRaw(
    requestParameters: DeleteGenerationOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling deleteGeneration."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/groups/delete-generation`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: DeleteGenerationRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Delete the referenced Group QNA generation.
   */
  async deleteGeneration(
    requestParameters: DeleteGenerationOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.deleteGenerationRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Delete the referenced group.
   */
  async deleteGroupRaw(
    requestParameters: DeleteGroupOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling deleteGroup."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/groups/delete`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: DeleteGroupRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Delete the referenced group.
   */
  async deleteGroup(
    requestParameters: DeleteGroupOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.deleteGroupRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get an group question & answer based on group of users and a question
   */
  async deleteGroupQnaRaw(
    requestParameters: DeleteGroupQnaRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling deleteGroupQna."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/groups/ai/delete-group-qna`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: DeleteGroupQuestionAndAnswerRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Get an group question & answer based on group of users and a question
   */
  async deleteGroupQna(
    requestParameters: DeleteGroupQnaRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.deleteGroupQnaRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get the referenced group.
   */
  async getGroupRaw(
    requestParameters: GetGroupRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<UserGroupSerializer>> {
    if (
      requestParameters.group === null ||
      requestParameters.group === undefined
    ) {
      throw new runtime.RequiredError(
        "group",
        "Required parameter requestParameters.group was null or undefined when calling getGroup."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.group !== undefined) {
      queryParameters["group"] = requestParameters.group;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/groups/get`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserGroupSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the referenced group.
   */
  async getGroup(
    requestParameters: GetGroupRequest,
    initOverrides?: RequestInit
  ): Promise<UserGroupSerializer> {
    const response = await this.getGroupRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get an group collab based on 2 groups of user and a list of questions
   */
  async getGroupCollabRaw(
    requestParameters: GetGroupCollabRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GroupCollabSerializer>> {
    if (
      requestParameters.group_collab_guid === null ||
      requestParameters.group_collab_guid === undefined
    ) {
      throw new runtime.RequiredError(
        "group_collab_guid",
        "Required parameter requestParameters.group_collab_guid was null or undefined when calling getGroupCollab."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.group_collab_guid !== undefined) {
      queryParameters["group_collab_guid"] =
        requestParameters.group_collab_guid;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/groups/ai/get-group-collab`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GroupCollabSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get an group collab based on 2 groups of user and a list of questions
   */
  async getGroupCollab(
    requestParameters: GetGroupCollabRequest,
    initOverrides?: RequestInit
  ): Promise<GroupCollabSerializer> {
    const response = await this.getGroupCollabRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get an group question & answer based on group of users and a question
   */
  async getGroupQnaRaw(
    requestParameters: GetGroupQnaRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GroupQuestionAndAnswerResponseSerializer>> {
    if (
      requestParameters.group_qna_guid === null ||
      requestParameters.group_qna_guid === undefined
    ) {
      throw new runtime.RequiredError(
        "group_qna_guid",
        "Required parameter requestParameters.group_qna_guid was null or undefined when calling getGroupQna."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.group_qna_guid !== undefined) {
      queryParameters["group_qna_guid"] = requestParameters.group_qna_guid;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/groups/ai/get-group-qna`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GroupQuestionAndAnswerResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get an group question & answer based on group of users and a question
   */
  async getGroupQna(
    requestParameters: GetGroupQnaRequest,
    initOverrides?: RequestInit
  ): Promise<GroupQuestionAndAnswerResponseSerializer> {
    const response = await this.getGroupQnaRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get an group question & answer based on group of users and a question
   */
  async getGroupSummaryRaw(
    requestParameters: GetGroupSummaryRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GroupSummarySerializer>> {
    if (
      requestParameters.group_summary_guid === null ||
      requestParameters.group_summary_guid === undefined
    ) {
      throw new runtime.RequiredError(
        "group_summary_guid",
        "Required parameter requestParameters.group_summary_guid was null or undefined when calling getGroupSummary."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.group_summary_guid !== undefined) {
      queryParameters["group_summary_guid"] =
        requestParameters.group_summary_guid;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/groups/ai/get-group-summary`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GroupSummarySerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get an group question & answer based on group of users and a question
   */
  async getGroupSummary(
    requestParameters: GetGroupSummaryRequest,
    initOverrides?: RequestInit
  ): Promise<GroupSummarySerializer> {
    const response = await this.getGroupSummaryRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * List group collabs based on 2 groups of user and a list of questions
   */
  async listGroupCollabsRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ListGroupCollabsResponseSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/groups/ai/list-group-collabs`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ListGroupCollabsResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * List group collabs based on 2 groups of user and a list of questions
   */
  async listGroupCollabs(
    initOverrides?: RequestInit
  ): Promise<ListGroupCollabsResponseSerializer> {
    const response = await this.listGroupCollabsRaw(initOverrides);
    return await response.value();
  }

  /**
   * List group question and answers
   */
  async listGroupQnaRaw(
    initOverrides?: RequestInit
  ): Promise<
    runtime.ApiResponse<ListGroupQuestionAndAnswerResponseSerializer>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/groups/ai/list-group-qna`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ListGroupQuestionAndAnswerResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * List group question and answers
   */
  async listGroupQna(
    initOverrides?: RequestInit
  ): Promise<ListGroupQuestionAndAnswerResponseSerializer> {
    const response = await this.listGroupQnaRaw(initOverrides);
    return await response.value();
  }

  /**
   * List group summaries
   */
  async listGroupSummariesRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ListGroupSummaryResponseSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/groups/ai/list-group-summaries`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ListGroupSummaryResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * List group summaries
   */
  async listGroupSummaries(
    initOverrides?: RequestInit
  ): Promise<ListGroupSummaryResponseSerializer> {
    const response = await this.listGroupSummariesRaw(initOverrides);
    return await response.value();
  }

  /**
   * Get a list of groups for the given user.
   */
  async listUserGroupsRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ListGroupsResponseSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/groups/list`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ListGroupsResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get a list of groups for the given user.
   */
  async listUserGroups(
    initOverrides?: RequestInit
  ): Promise<ListGroupsResponseSerializer> {
    const response = await this.listUserGroupsRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async rateLlmResultRaw(
    requestParameters: RateLlmResultRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling rateLlmResult."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/groups/ai/rate-llm-result`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: RateLLMResultsRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   */
  async rateLlmResult(
    requestParameters: RateLlmResultRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.rateLlmResultRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Remove users from specified group.
   */
  async removeUsersRaw(
    requestParameters: RemoveUsersRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling removeUsers."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/groups/remove-users`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateUsersInGroupRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Remove users from specified group.
   */
  async removeUsers(
    requestParameters: RemoveUsersRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.removeUsersRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Retrieve the filenames in the generations directory.
   */
  async retrieveDirRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<RetrieveDirResponseSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/groups/retrieve-dir`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RetrieveDirResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Retrieve the filenames in the generations directory.
   */
  async retrieveDir(
    initOverrides?: RequestInit
  ): Promise<RetrieveDirResponseSerializer> {
    const response = await this.retrieveDirRaw(initOverrides);
    return await response.value();
  }

  /**
   * Save Group QNA generation as JSON file.
   */
  async saveGenerationRaw(
    requestParameters: SaveGenerationOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling saveGeneration."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/groups/save-generation`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SaveGenerationRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Save Group QNA generation as JSON file.
   */
  async saveGeneration(
    requestParameters: SaveGenerationOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.saveGenerationRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Return the JSON file contents of 2 selected files.
   */
  async selectFilesRaw(
    requestParameters: SelectFilesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<SelectFilesResponseSerializer>> {
    if (
      requestParameters.filename1 === null ||
      requestParameters.filename1 === undefined
    ) {
      throw new runtime.RequiredError(
        "filename1",
        "Required parameter requestParameters.filename1 was null or undefined when calling selectFiles."
      );
    }

    if (
      requestParameters.filename2 === null ||
      requestParameters.filename2 === undefined
    ) {
      throw new runtime.RequiredError(
        "filename2",
        "Required parameter requestParameters.filename2 was null or undefined when calling selectFiles."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.filename1 !== undefined) {
      queryParameters["filename1"] = requestParameters.filename1;
    }

    if (requestParameters.filename2 !== undefined) {
      queryParameters["filename2"] = requestParameters.filename2;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/groups/select-files`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SelectFilesResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Return the JSON file contents of 2 selected files.
   */
  async selectFiles(
    requestParameters: SelectFilesRequest,
    initOverrides?: RequestInit
  ): Promise<SelectFilesResponseSerializer> {
    const response = await this.selectFilesRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Summarize the users in a group.
   */
  async summarizeGroupRaw(
    requestParameters: SummarizeGroupRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GroupSummarySerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling summarizeGroup."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/groups/ai/summarize-group`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: GroupSummaryRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GroupSummarySerializerFromJSON(jsonValue)
    );
  }

  /**
   * Summarize the users in a group.
   */
  async summarizeGroup(
    requestParameters: SummarizeGroupRequest,
    initOverrides?: RequestInit
  ): Promise<GroupSummarySerializer> {
    const response = await this.summarizeGroupRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update the referenced group with the given data.
   */
  async updateGroupRaw(
    requestParameters: UpdateGroupOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<UserGroupSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling updateGroup."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/groups/update`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateGroupRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserGroupSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Update the referenced group with the given data.
   */
  async updateGroup(
    requestParameters: UpdateGroupOperationRequest,
    initOverrides?: RequestInit
  ): Promise<UserGroupSerializer> {
    const response = await this.updateGroupRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
