import React from "react";
import { MStepsCirclesProps } from "../../ui/MStepsCircles";
import { UserProfileSummary } from "../../../api/models";
import CommCoachHeader from "./CommCoachHeader";
import UserSearchScreen from "../../ui/screens/UserSearchScreen";
import { middleColumnContent, middleColumnWrapper } from "../../../util/style";

type CommCoachSelectUserScreenProps = {
  progress: MStepsCirclesProps;
  searchTerm?: string | null;
  searchResults?: UserProfileSummary[] | null;
  onSearchTermUpdated: (newTerm: string) => void;
  onBackClicked: () => void;
  onUserClicked: (user: UserProfileSummary) => void;
  errors: string[];
};

const CommCoachSelectUserScreenComponent = (
  props: CommCoachSelectUserScreenProps
) => {
  const { progress, ...rest } = props;

  return (
    <>
      <div className={middleColumnWrapper}>
        <div className={middleColumnContent}>
          <CommCoachHeader progress={progress} />
          <div className="text-center pt-3">
            Who do you want to plan a conversation with?
          </div>
        </div>
      </div>
      <UserSearchScreen
        searchPlaceholder="search my people"
        startSearchingPlaceholder={
          "start typing above to search through your people by name or username.\n\nnote that only accounts that have been added to 'my people' will be shown here."
        }
        {...rest}
      />
    </>
  );
};

CommCoachSelectUserScreenComponent.defaultProps = {
  searchTerm: null,
  searchResults: null,
};

export default CommCoachSelectUserScreenComponent;
