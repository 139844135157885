/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SetHeadlineRequest
 */
export interface SetHeadlineRequest {
  /**
   *
   * @type {string}
   * @memberof SetHeadlineRequest
   */
  headline: string | null;
}

export function SetHeadlineRequestFromJSON(json: any): SetHeadlineRequest {
  return SetHeadlineRequestFromJSONTyped(json, false);
}

export function SetHeadlineRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SetHeadlineRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    headline: json["headline"],
  };
}

export function SetHeadlineRequestToJSON(
  value?: SetHeadlineRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    headline: value.headline,
  };
}
