import React, { ReactNode } from "react";
import { UserProfileAccessRelatedDetail } from "../../../api/models";
import SettingsLabelRow from "../../ui/screens/sidebarnav/SidebarLabelRow";
import { useBusyWatcher } from "../../../util/hooks";
import MButton from "../../ui/buttons/MButton";

type SettingsPrivateProfileAccessRowProps = {
  entry: UserProfileAccessRelatedDetail;
  onDeleteClicked: () => void;
};

const SettingsPrivateProfileAccessRowComponent = (
  props: SettingsPrivateProfileAccessRowProps
) => {
  const { entry, onDeleteClicked } = props;

  const [busy, _] = useBusyWatcher();

  const getBody = (): ReactNode => {
    switch (entry.access_type) {
      case "user":
        return (
          <a
            href={`/@${entry.other_user!.username}`}
            target="_blank"
            rel="noreferrer"
            className="text-primary hover:underline"
          >
            {entry.other_user!.name} (@{entry.other_user!.username})
          </a>
        );
      case "email":
        return entry.email!;
      default:
        throw new Error(`Invalid access type ${entry.access_type}`);
    }
  };

  return (
    <SettingsLabelRow
      label={entry.access_type!}
      middle={getBody()}
      end={
        <MButton kind="warning" onClick={onDeleteClicked} disabled={busy}>
          delete
        </MButton>
      }
    />
  );
};

export default SettingsPrivateProfileAccessRowComponent;
