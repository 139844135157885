/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  PromptWithResponse,
  PromptWithResponseFromJSON,
  PromptWithResponseFromJSONTyped,
  PromptWithResponseToJSON,
} from "./PromptWithResponse";
import {
  QuestionnaireInferenceDetail,
  QuestionnaireInferenceDetailFromJSON,
  QuestionnaireInferenceDetailFromJSONTyped,
  QuestionnaireInferenceDetailToJSON,
} from "./QuestionnaireInferenceDetail";

/**
 *
 * @export
 * @interface GetInferenceResultsResponse
 */
export interface GetInferenceResultsResponse {
  /**
   *
   * @type {QuestionnaireInferenceDetail}
   * @memberof GetInferenceResultsResponse
   */
  inference: QuestionnaireInferenceDetail;
  /**
   *
   * @type {Array<PromptWithResponse>}
   * @memberof GetInferenceResultsResponse
   */
  prompts: Array<PromptWithResponse>;
  /**
   *
   * @type {Array<string>}
   * @memberof GetInferenceResultsResponse
   */
  suggestions: Array<string>;
}

export function GetInferenceResultsResponseFromJSON(
  json: any
): GetInferenceResultsResponse {
  return GetInferenceResultsResponseFromJSONTyped(json, false);
}

export function GetInferenceResultsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetInferenceResultsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    inference: QuestionnaireInferenceDetailFromJSON(json["inference"]),
    prompts: (json["prompts"] as Array<any>).map(PromptWithResponseFromJSON),
    suggestions: json["suggestions"],
  };
}

export function GetInferenceResultsResponseToJSON(
  value?: GetInferenceResultsResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    inference: QuestionnaireInferenceDetailToJSON(value.inference),
    prompts: (value.prompts as Array<any>).map(PromptWithResponseToJSON),
    suggestions: value.suggestions,
  };
}
