import React, { useEffect, useState } from "react";
import BaseDialog from "./BaseDialog";
import SubscriptionChoicesView from "../pages/settings/SubscriptionChoicesView";
import {
  GetSubscriptionTiersResponse,
  SubscriptionTierTierEnum,
} from "../../api/models";
import connector from "../../api/connector";
import MButton from "../ui/buttons/MButton";

type SubscriptionUpsellDialogProps = {
  upsellTinyLabel?: string;
  upsellText: string;
  show: boolean;
  onClose: () => void;
  highlightTier?: SubscriptionTierTierEnum;
};

const SubscriptionUpsellDialogComponent = (
  props: SubscriptionUpsellDialogProps
) => {
  const { upsellTinyLabel, upsellText, show, onClose, highlightTier } = props;

  const [response, setResponse] = useState<GetSubscriptionTiersResponse>();

  useEffect(() => {
    connector.getSubscriptionTiers().then((r) => {
      setResponse(r.c);
    });
  }, []);

  return (
    <BaseDialog title="Subscribe to Manual" show={show} onClose={onClose}>
      <div className="flex flex-col items-center justify-center gap-4">
        <SubscriptionChoicesView
          highlightTier={highlightTier}
          highlightReason={upsellTinyLabel}
          response={response}
        />
        <p className="max-w-lg text-m-black">{upsellText}</p>
        <MButton onClick={onClose} kind="primary">
          cancel
        </MButton>
      </div>
    </BaseDialog>
  );
};

SubscriptionUpsellDialogComponent.defaultProps = {
  highlightTier: undefined,
  upsellTinyLabel: undefined,
};

export default SubscriptionUpsellDialogComponent;
