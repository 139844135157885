import React, { useEffect, useState } from "react";
import { PromptDisplayCardProps } from "./PromptDisplayCard";
import { FillInBlankListPromptResponseMeta } from "../../../../api/types";
import MInsetTitleCard from "../MInsetTitleCard";
import MNumberedList from "../../MNumberedList";
import EmptyPromptResponseContent from "./EmptyPromptResponseContent";

const FillInBlankListDisplayCardComponent = (props: PromptDisplayCardProps) => {
  const { prompt, ...rest } = props;

  const [title, setTitle] = useState<string>("");
  const [body, setBody] = useState<string[]>([]);

  useEffect(() => {
    setTitle(prompt.prompt.statement);
    if (prompt.response) {
      const responseMeta = prompt.response!
        .meta as FillInBlankListPromptResponseMeta;
      setBody(responseMeta.response);
    } else {
      setBody([]);
    }
  }, [prompt.prompt.guid, prompt.response?.meta]);

  return (
    <MInsetTitleCard
      type={body.length > 0 ? "regular" : "warning"}
      title={title}
      {...rest}
    >
      {body.length > 0 ? (
        <MNumberedList body={body} />
      ) : (
        <EmptyPromptResponseContent />
      )}
    </MInsetTitleCard>
  );
};

export default FillInBlankListDisplayCardComponent;
