import React, { ReactElement } from "react";
import {
  ArrowTopRightOnSquareIcon,
  DocumentPlusIcon,
} from "@heroicons/react/24/outline";
import { GetResumeResponse } from "../../../../api/models";
import CenterColumnStickyButtonScreen from "../../../ui/screens/CenterColumnStickyButtonScreen";
import MTitle from "../../../ui/MTitle";
import MSubtext from "../../../ui/MSubtext";
import { clickableText } from "../../../../util/style";
import { classNames } from "../../../../util/strings";
import MClearButton from "../../../ui/buttons/MClearButton";
import MBackButton from "../../../ui/buttons/MBackButton";
import MButton from "../../../ui/buttons/MButton";
import { useBusyWatcher } from "../../../../util/hooks";

type ViewResumeScreenProps = {
  resume: GetResumeResponse;
  onBackClicked: () => void;
  onClearClicked: () => void;
  onUpdateClicked: () => void;
};

const ViewResumeScreenComponent = (props: ViewResumeScreenProps) => {
  const { resume, onBackClicked, onClearClicked, onUpdateClicked } = props;

  const [busy, _] = useBusyWatcher();

  const getButtons = (): ReactElement[] => [
    <MButton
      key="update-button"
      className="w-full"
      disabled={busy}
      icon={<DocumentPlusIcon className="h-5 w-5" />}
      kind="secondary"
      onClick={onUpdateClicked}
    >
      change resume
    </MButton>,
    <MClearButton
      key="clear-button"
      disabled={busy}
      onClick={onClearClicked}
      className="w-full"
      kind="secondary"
      text="remove resume"
    />,
    <MBackButton
      key="back-button"
      onClick={onBackClicked}
      className="w-full"
      kind="secondary"
    />,
  ];

  return (
    <CenterColumnStickyButtonScreen buttons={getButtons()} fullWidth>
      <MTitle text="your resume" className="text-center" />
      <MSubtext
        text="you can view, remove, or update your resume below"
        className="text-center"
      />
      <div className="flex flex-row items-center justify-center">
        <a
          href={resume.resume_url!}
          className={classNames(
            clickableText,
            "text-xl flex flex-row items-center gap-2"
          )}
          target="_blank"
          rel="noreferrer"
        >
          <span>view resume</span>
          <ArrowTopRightOnSquareIcon className="h-5 w-5" />
        </a>
      </div>
    </CenterColumnStickyButtonScreen>
  );
};

export default ViewResumeScreenComponent;
