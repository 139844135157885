import React from "react";
import { CommonRowProps } from "./common";
import SettingsLabelRow from "./SidebarLabelRow";
import { useBusyWatcher } from "../../../../util/hooks";
import MToggle from "../../MToggle";

type SidebarToggleRowProps = CommonRowProps & {
  onToggleClicked: (isOn: boolean) => void;
  isOn: boolean;
};

const SidebarToggleRowComponent = (props: SidebarToggleRowProps) => {
  const { label, onToggleClicked, isOn, tooltip } = props;

  const [busy, _] = useBusyWatcher();

  return (
    <SettingsLabelRow
      label={label}
      tooltip={tooltip}
      end={<MToggle isOn={isOn} disabled={busy} onToggle={onToggleClicked} />}
    />
  );
};

export default SidebarToggleRowComponent;
