import React, { useMemo, useState } from "react";
import FormScreen from "../../ui/screens/FormScreen";
import { useBusyWatcher } from "../../../util/hooks";
import MInput from "../../ui/MInput";
import { Step } from "../../ui/MStepsCircles";

type UsernameScreenProps = {
  username?: string;
  onNextClicked: (username: string) => void;
  errors: string[];
  onBackClicked: () => void;
  steps: Step[];
};

const UsernameScreenComponent = (props: UsernameScreenProps) => {
  const {
    username,
    onNextClicked,
    onBackClicked,
    steps: stepsInput,
    errors,
  } = props;

  const [innerUsername, setInnerUsername] = useState<string>(username || "");
  const [busy, _] = useBusyWatcher();
  const steps = useMemo(() => stepsInput, []);

  const canSubmit = (): boolean => {
    if (innerUsername === "") {
      return false;
    }
    return true;
  };

  const submit = () => {
    onNextClicked(innerUsername);
  };

  return (
    <FormScreen
      title="pick a username"
      comment="you can change it later!"
      nextDisabled={busy || !canSubmit()}
      onNextClicked={submit}
      backDisabled={busy}
      onBackClicked={onBackClicked}
      steps={{ steps }}
    >
      <MInput
        label="username"
        type="text"
        placeholder="username"
        value={innerUsername}
        onChange={(e) => setInnerUsername(e.target.value)}
        disabled={busy}
        onEnterPressed={() => canSubmit() && submit()}
        errors={errors}
        required
        autoFocus
      />
    </FormScreen>
  );
};

UsernameScreenComponent.defaultProps = {
  username: undefined,
};

export default UsernameScreenComponent;
