/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  GroupQuestionAndAnswer,
  GroupQuestionAndAnswerFromJSON,
  GroupQuestionAndAnswerFromJSONTyped,
  GroupQuestionAndAnswerToJSON,
} from "./GroupQuestionAndAnswer";

/**
 *
 * @export
 * @interface ListGroupQuestionAndAnswerResponse
 */
export interface ListGroupQuestionAndAnswerResponse {
  /**
   *
   * @type {Array<GroupQuestionAndAnswer>}
   * @memberof ListGroupQuestionAndAnswerResponse
   */
  group_qna: Array<GroupQuestionAndAnswer>;
}

export function ListGroupQuestionAndAnswerResponseFromJSON(
  json: any
): ListGroupQuestionAndAnswerResponse {
  return ListGroupQuestionAndAnswerResponseFromJSONTyped(json, false);
}

export function ListGroupQuestionAndAnswerResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ListGroupQuestionAndAnswerResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    group_qna: (json["group_qna"] as Array<any>).map(
      GroupQuestionAndAnswerFromJSON
    ),
  };
}

export function ListGroupQuestionAndAnswerResponseToJSON(
  value?: ListGroupQuestionAndAnswerResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    group_qna: (value.group_qna as Array<any>).map(
      GroupQuestionAndAnswerToJSON
    ),
  };
}
