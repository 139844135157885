import React, { forwardRef, ReactElement } from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import {
  ButtonButtonProps,
  getKindClass,
  MButtonKind,
  MButtonProps,
} from "./MButton";
import { classNames } from "../../../util/strings";

export type MIconButtonProps = MButtonProps & {
  kind?: MButtonKind;
  icon?: ReactElement;
};

const MIconButtonComponent = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  MIconButtonProps
>(({ icon, kind, className: classNameInput, disabled, ...props }, ref) => {
  const className = classNames(
    "rounded-full shadow-sm p-0.5 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 border-2",
    getKindClass(kind, disabled),
    classNameInput
  );

  if (props.href) {
    return (
      <div className={className}>
        <a ref={ref as React.Ref<HTMLAnchorElement>} {...props}>
          {icon}
        </a>
      </div>
    );
  }

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      ref={ref as React.Ref<HTMLButtonElement>}
      className={className}
      {...(props as ButtonButtonProps)}
    >
      {icon}
    </button>
  );
});

MIconButtonComponent.defaultProps = {
  kind: "secondary",
  icon: (
    <QuestionMarkCircleIcon
      className="h-full w-full flex-none"
      aria-hidden="true"
    />
  ),
};

export default MIconButtonComponent;
