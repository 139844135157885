import React, { PropsWithChildren } from "react";
import { SparklesIcon } from "@heroicons/react/24/outline";
import MTitleWell from "./MTitleWell";
import { WellKind } from "./MWell";

type MAIWellProps = {
  title: string;
  kind?: WellKind;
  className?: string | null;
};

const MAIWellComponent = (props: PropsWithChildren<MAIWellProps>) => {
  const { title, kind, className, children } = props;

  return (
    <MTitleWell
      title={title}
      icon={<SparklesIcon />}
      className={className}
      kind={kind}
    >
      {children}
    </MTitleWell>
  );
};

MAIWellComponent.defaultProps = {
  kind: "magical",
  className: null,
};

export default MAIWellComponent;
