/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  LinkWithSelected,
  LinkWithSelectedFromJSON,
  LinkWithSelectedFromJSONTyped,
  LinkWithSelectedToJSON,
} from "./LinkWithSelected";

/**
 *
 * @export
 * @interface ListLinksForGlimpseResponse
 */
export interface ListLinksForGlimpseResponse {
  /**
   *
   * @type {Array<LinkWithSelected>}
   * @memberof ListLinksForGlimpseResponse
   */
  links: Array<LinkWithSelected>;
  /**
   *
   * @type {number}
   * @memberof ListLinksForGlimpseResponse
   */
  cur_selected_count: number;
}

export function ListLinksForGlimpseResponseFromJSON(
  json: any
): ListLinksForGlimpseResponse {
  return ListLinksForGlimpseResponseFromJSONTyped(json, false);
}

export function ListLinksForGlimpseResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ListLinksForGlimpseResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    links: (json["links"] as Array<any>).map(LinkWithSelectedFromJSON),
    cur_selected_count: json["cur_selected_count"],
  };
}

export function ListLinksForGlimpseResponseToJSON(
  value?: ListLinksForGlimpseResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    links: (value.links as Array<any>).map(LinkWithSelectedToJSON),
    cur_selected_count: value.cur_selected_count,
  };
}
