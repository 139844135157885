/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  DeleteProfileAccessRequest,
  DeleteProfileAccessRequestFromJSON,
  DeleteProfileAccessRequestToJSON,
  EmptyResponseWrapper,
  EmptyResponseWrapperFromJSON,
  EmptyResponseWrapperToJSON,
  ListProfileAccessResponseSerializer,
  ListProfileAccessResponseSerializerFromJSON,
  ListProfileAccessResponseSerializerToJSON,
  SetProfileAccessRequest,
  SetProfileAccessRequestFromJSON,
  SetProfileAccessRequestToJSON,
  UserProfileAccessRelatedDetailSerializer,
  UserProfileAccessRelatedDetailSerializerFromJSON,
  UserProfileAccessRelatedDetailSerializerToJSON,
} from "../models";

export interface DeleteProfileAccessOperationRequest {
  data: DeleteProfileAccessRequest;
}

export interface SetProfileAccessOperationRequest {
  data: SetProfileAccessRequest;
}

/**
 *
 */
export class PrivacyApi extends runtime.BaseAPI {
  /**
   * Delete the referenced UserProfileAccess entry if it is owned by the requesting user.
   */
  async deleteProfileAccessRaw(
    requestParameters: DeleteProfileAccessOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling deleteProfileAccess."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/privacy/delete-profile-access`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: DeleteProfileAccessRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Delete the referenced UserProfileAccess entry if it is owned by the requesting user.
   */
  async deleteProfileAccess(
    requestParameters: DeleteProfileAccessOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.deleteProfileAccessRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * List all the UserProfileAccess entries that the requesting user owns.
   */
  async listProfileAccessRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ListProfileAccessResponseSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/privacy/list-profile-access`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ListProfileAccessResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * List all the UserProfileAccess entries that the requesting user owns.
   */
  async listProfileAccess(
    initOverrides?: RequestInit
  ): Promise<ListProfileAccessResponseSerializer> {
    const response = await this.listProfileAccessRaw(initOverrides);
    return await response.value();
  }

  /**
   * Set the access for a specified user to the requesting user\'s profile.
   */
  async setProfileAccessRaw(
    requestParameters: SetProfileAccessOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<UserProfileAccessRelatedDetailSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling setProfileAccess."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/privacy/set-profile-access`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SetProfileAccessRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserProfileAccessRelatedDetailSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Set the access for a specified user to the requesting user\'s profile.
   */
  async setProfileAccess(
    requestParameters: SetProfileAccessOperationRequest,
    initOverrides?: RequestInit
  ): Promise<UserProfileAccessRelatedDetailSerializer> {
    const response = await this.setProfileAccessRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
