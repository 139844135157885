import React from "react";
import { BothUsersConnectionRequestRelatedDetail } from "../../../api/models";
import SidebarThreeColumnRow from "../../ui/screens/sidebarnav/SidebarThreeColumnRow";
import { clickableText } from "../../../util/style";

type SentConnectionRequestRowProps = {
  request: BothUsersConnectionRequestRelatedDetail;
};

const SentConnectionRequestRowComponent = (
  props: SentConnectionRequestRowProps
) => {
  const { request } = props;

  return (
    <SidebarThreeColumnRow
      start={
        <a
          href={request.recipient_user.profile_url}
          target="_blank"
          rel="noreferrer"
          className={clickableText}
        >
          {request.recipient_user.name} (@{request.recipient_user.username})
        </a>
      }
      middle={<span>sent {request.time_created!.toLocaleString()}</span>}
    />
  );
};

export default SentConnectionRequestRowComponent;
