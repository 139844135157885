import {
  ExportResponseContentTypeEnum,
  UserProfileSummary,
} from "../api/models";

export const getQueryParam = (key: string): string | null => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  return params.get(key);
};

type TrackingUrlParams = {
  user: UserProfileSummary;
  visitType?: ExportResponseContentTypeEnum;
  medium?: string;
  includeOrigin?: boolean;
};

export const getProfileTrackingUrl = (params: TrackingUrlParams): string => {
  const { user, visitType, medium, includeOrigin } = params;
  const queryElements: string[] = [];
  if (visitType) {
    queryElements.push(`utm_source=${visitType}`);
  }
  if (medium) {
    queryElements.push(`utm_medium=${medium}`);
  }
  let toReturn: string;
  if (queryElements.length === 0) {
    toReturn = user.profile_url!;
  } else {
    toReturn = `${user.profile_url!}?${queryElements.join("&")}`;
  }
  if (includeOrigin) {
    return `${window.location.origin}${toReturn}`;
  }
  return toReturn;
};

export const addQueryParamToPath = (
  path: string,
  key: string,
  value: any
): string => {
  let toReturn: string;
  if (path.includes("?")) {
    toReturn = `${path}&${key}=${encodeURIComponent(value)}`;
  } else {
    toReturn = `${path}?${key}=${encodeURIComponent(value)}`;
  }
  return toReturn;
};

export const getCurrentPathWithQuery = (): string => {
  const { pathname, search } = window.location;
  return `${pathname}${search}`;
};

export const addNextToUrl = (toAdd: string): string =>
  addQueryParamToPath(toAdd, "next", getCurrentPathWithQuery());

export const addUserIdToUrl = (toAdd: string, userId: any): string =>
  addQueryParamToPath(toAdd, "user", userId);

export const retainNextWithUrl = (toAdd: string): string => {
  const next = getQueryParam("next");
  if (!next) {
    return toAdd;
  }
  return addQueryParamToPath(toAdd, "next", next);
};

export const addOriginToPath = (path: string): string => {
  let parsedPath: string;
  if (!path.startsWith("/")) {
    parsedPath = `/${path}`;
  } else {
    parsedPath = path;
  }
  return `${window.location.origin}${parsedPath}`;
};
