/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  EmptyResponseWrapper,
  EmptyResponseWrapperFromJSON,
  EmptyResponseWrapperToJSON,
  GetBasicInfoSerializer,
  GetBasicInfoSerializerFromJSON,
  GetBasicInfoSerializerToJSON,
  GetOnboardingStageResponseSerializer,
  GetOnboardingStageResponseSerializerFromJSON,
  GetOnboardingStageResponseSerializerToJSON,
  SetBasicInfo,
  SetBasicInfoFromJSON,
  SetBasicInfoToJSON,
  SetOnboardingUsernameRequest,
  SetOnboardingUsernameRequestFromJSON,
  SetOnboardingUsernameRequestToJSON,
  SubmitOnboardingPromptResponseRequest,
  SubmitOnboardingPromptResponseRequestFromJSON,
  SubmitOnboardingPromptResponseRequestToJSON,
} from "../models";

export interface SetOnboardingBasicsRequest {
  data: SetBasicInfo;
}

export interface SetOnboardingUsernameOperationRequest {
  data: SetOnboardingUsernameRequest;
}

export interface SubmitOnboardingResponseRequest {
  data: SubmitOnboardingPromptResponseRequest;
}

/**
 *
 */
export class OnboardingApi extends runtime.BaseAPI {
  /**
   * Get the basics information for the requesting user.
   */
  async getOnboardingBasicsRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetBasicInfoSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/onboarding/get-basics`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetBasicInfoSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the basics information for the requesting user.
   */
  async getOnboardingBasics(
    initOverrides?: RequestInit
  ): Promise<GetBasicInfoSerializer> {
    const response = await this.getOnboardingBasicsRaw(initOverrides);
    return await response.value();
  }

  /**
   * Get the current stage of onboarding for the requesting user.
   */
  async getOnboardingStageRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetOnboardingStageResponseSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/onboarding/get-stage`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetOnboardingStageResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the current stage of onboarding for the requesting user.
   */
  async getOnboardingStage(
    initOverrides?: RequestInit
  ): Promise<GetOnboardingStageResponseSerializer> {
    const response = await this.getOnboardingStageRaw(initOverrides);
    return await response.value();
  }

  /**
   * Set basic information for the requesting user.
   */
  async setOnboardingBasicsRaw(
    requestParameters: SetOnboardingBasicsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling setOnboardingBasics."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/onboarding/set-basics`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SetBasicInfoToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Set basic information for the requesting user.
   */
  async setOnboardingBasics(
    requestParameters: SetOnboardingBasicsRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.setOnboardingBasicsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Set the username for the requesting user.
   */
  async setOnboardingUsernameRaw(
    requestParameters: SetOnboardingUsernameOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling setOnboardingUsername."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/onboarding/api/onboarding/set-username`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SetOnboardingUsernameRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Set the username for the requesting user.
   */
  async setOnboardingUsername(
    requestParameters: SetOnboardingUsernameOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.setOnboardingUsernameRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Submit a response to an onboarding prompt for the given user.
   */
  async submitOnboardingResponseRaw(
    requestParameters: SubmitOnboardingResponseRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling submitOnboardingResponse."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/onboarding/submit-response`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SubmitOnboardingPromptResponseRequestToJSON(
          requestParameters.data
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Submit a response to an onboarding prompt for the given user.
   */
  async submitOnboardingResponse(
    requestParameters: SubmitOnboardingResponseRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.submitOnboardingResponseRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
