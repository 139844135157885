import React from "react";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { UserProfileSummary } from "../../../api/models";
import { commentText } from "../../../util/style";
import ProfileCard from "../cards/ProfileCard";
import { useBusyWatcher } from "../../../util/hooks";
import { classNames } from "../../../util/strings";
import MSpinner from "../MSpinner";
import MButton from "../buttons/MButton";

type NavUserSearchResultsProps = {
  users: UserProfileSummary[];
  onUserClicked: (user: UserProfileSummary) => void;
  onInviteUserClicked: () => void;
  className?: string;
  searching?: boolean;
  searchTerm: string;
  showInviteButton?: boolean;
};

const NavUserSearchResultsComponent = (props: NavUserSearchResultsProps) => {
  const {
    users,
    onUserClicked,
    onInviteUserClicked,
    className,
    searching,
    searchTerm,
    showInviteButton,
  } = props;

  const [busy, _] = useBusyWatcher();

  return (
    <div
      className={classNames(
        "rounded-lg shadow shadow-m-gray border-2 border-secondary bg-m-white flex flex-col items-center gap-2",
        users.length > 0 ? "p-2" : "px-4 py-3 text-center",
        className
      )}
    >
      {searching && (
        <div className="h-6 w-44">
          <MSpinner />
        </div>
      )}
      {!searching &&
        users.length > 0 &&
        users.map((user) => (
          <ProfileCard
            className="w-full min-w-64"
            key={user.id}
            user={user}
            disabled={busy}
            raised={false}
            onClick={() => onUserClicked(user)}
          />
        ))}
      {!searching && users.length === 0 && (
        <div className={classNames(commentText, "whitespace-pre")}>
          {searchTerm === "" ? "search by name or username" : "no users found"}
        </div>
      )}
      {!searching && showInviteButton && (
        <MButton
          kind="primary"
          onClick={onInviteUserClicked}
          icon={<UserPlusIcon className="h-5 w-5" />}
        >
          invite to Manual
        </MButton>
      )}
    </div>
  );
};

NavUserSearchResultsComponent.defaultProps = {
  className: undefined,
  searching: false,
  showInviteButton: false,
};

export default NavUserSearchResultsComponent;
