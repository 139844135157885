import React, { useEffect, useState } from "react";
import { CommonPromptTypeScreenProps } from "./PromptRespondScreen";
import { useBusyWatcher } from "../../../util/hooks";
import CommonQuestionScreen from "./CommonQuestionScreen";
import FillInBlankListQuestion from "./questions/FillInBlankListQuestion";

type FillInBlankListScreenProps = CommonPromptTypeScreenProps & {
  onNextClicked: (content: string[], visible: boolean | null) => void;
  numAnswers: number;
  content?: string[] | null;
  examples?: string[] | null;
  maxContentLength?: number | null;
};

const FillInBlankListScreenComponent = (props: FillInBlankListScreenProps) => {
  const {
    title,
    errors,
    publishable,
    visible: visibleInput,
    onNextClicked,
    numAnswers,
    content: contentInput,
    examples,
    maxContentLength,
    ...rest
  } = props;

  const [busy, _] = useBusyWatcher();
  const [content, setContent] = useState<string[]>([]);
  const [visible, setVisible] = useState<boolean | null>(visibleInput);

  const canSubmit = (): boolean => {
    for (let i = 0; i < content.length; i += 1) {
      if (content[i].length === 0) {
        return false;
      }
      if (maxContentLength && content[i].length > maxContentLength) {
        return false;
      }
    }
    return true;
  };

  const submit = () => {
    onNextClicked(content, visible);
  };

  const updatePromptResponseVisibility = () => {
    if (rest.prompt.response) {
      onNextClicked(content, !visible);
    }
    setVisible(!visible); // This needs to be set after the call because setState is async
  };

  useEffect(() => {
    if (contentInput && contentInput.length > 0) {
      setContent(contentInput);
    } else {
      const newContent: string[] = [];
      for (let i = 0; i < numAnswers; i += 1) {
        newContent.push("");
      }
      setContent(newContent);
    }
  }, [contentInput]);

  return (
    <CommonQuestionScreen {...rest} canSubmit={canSubmit()} submit={submit}>
      <FillInBlankListQuestion
        errors={errors}
        question={title}
        showVisibilityToggle
        onVisibilityUpdated={updatePromptResponseVisibility}
        visible={visible}
        publishable={publishable}
        numAnswers={numAnswers}
        content={content}
        placeholders={examples}
        maxContentLength={maxContentLength ?? 500}
        disabled={busy}
        onUpdated={setContent}
      />
    </CommonQuestionScreen>
  );
};

FillInBlankListScreenComponent.defaultProps = {
  content: null,
  examples: null,
  maxContentLength: null,
};

export default FillInBlankListScreenComponent;
