/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  UserProfileAccessRelatedDetail,
  UserProfileAccessRelatedDetailFromJSON,
  UserProfileAccessRelatedDetailFromJSONTyped,
  UserProfileAccessRelatedDetailToJSON,
} from "./UserProfileAccessRelatedDetail";

/**
 *
 * @export
 * @interface ListProfileAccessResponse
 */
export interface ListProfileAccessResponse {
  /**
   *
   * @type {Array<UserProfileAccessRelatedDetail>}
   * @memberof ListProfileAccessResponse
   */
  access_list: Array<UserProfileAccessRelatedDetail>;
}

export function ListProfileAccessResponseFromJSON(
  json: any
): ListProfileAccessResponse {
  return ListProfileAccessResponseFromJSONTyped(json, false);
}

export function ListProfileAccessResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ListProfileAccessResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    access_list: (json["access_list"] as Array<any>).map(
      UserProfileAccessRelatedDetailFromJSON
    ),
  };
}

export function ListProfileAccessResponseToJSON(
  value?: ListProfileAccessResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    access_list: (value.access_list as Array<any>).map(
      UserProfileAccessRelatedDetailToJSON
    ),
  };
}
