import React, { useState } from "react";
import FormScreen from "../../../ui/screens/FormScreen";
import { useBusyWatcher } from "../../../../util/hooks";
import { useAsyncFileSelectDialog } from "../../../dialogs/hooks";
import MButton from "../../../ui/buttons/MButton";
import MSpinner from "../../../ui/MSpinner";
import { commentText } from "../../../../util/style";

type UploadResumeScreenProps = {
  onBackClicked: () => void;
  onUploadClicked: (content: [string, string]) => void;
};

const UploadResumeScreenComponent = (props: UploadResumeScreenProps) => {
  const { onBackClicked, onUploadClicked } = props;

  const fileSelectDialog = useAsyncFileSelectDialog();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [busy, _] = useBusyWatcher();

  const onSelectFileClicked = async () => {
    const [fileContent, fileName] = await fileSelectDialog.selectFile({
      allowImages: true,
      allowPdfs: true,
      allowText: true,
      maxFiles: 1,
      maxSize: 2 ** 21, // 2MB
      title: "pick your resume",
    });
    if (fileContent === null) return;
    setIsUploading(true);
    onUploadClicked([fileContent, fileName!]);
  };

  return (
    <>
      <FormScreen
        title="upload resume"
        onBackClicked={onBackClicked}
        backDisabled={busy}
        fullWidth
      >
        {isUploading ? (
          <div className="flex flex-col items-center my-6 gap-3">
            <div className="h-10 w-10">
              <MSpinner />
            </div>
            <div className={commentText}>uploading... hold please</div>
          </div>
        ) : (
          <MButton disabled={busy} kind="primary" onClick={onSelectFileClicked}>
            select a file
          </MButton>
        )}
      </FormScreen>
      {fileSelectDialog.dialog}
    </>
  );
};

export default UploadResumeScreenComponent;
