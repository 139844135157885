/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SetPromptResponsesForGlimpseRequest
 */
export interface SetPromptResponsesForGlimpseRequest {
  /**
   *
   * @type {string}
   * @memberof SetPromptResponsesForGlimpseRequest
   */
  glimpse: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SetPromptResponsesForGlimpseRequest
   */
  prompt_responses: Array<string>;
}

export function SetPromptResponsesForGlimpseRequestFromJSON(
  json: any
): SetPromptResponsesForGlimpseRequest {
  return SetPromptResponsesForGlimpseRequestFromJSONTyped(json, false);
}

export function SetPromptResponsesForGlimpseRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SetPromptResponsesForGlimpseRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    glimpse: json["glimpse"],
    prompt_responses: json["prompt_responses"],
  };
}

export function SetPromptResponsesForGlimpseRequestToJSON(
  value?: SetPromptResponsesForGlimpseRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    glimpse: value.glimpse,
    prompt_responses: value.prompt_responses,
  };
}
