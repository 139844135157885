/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CreateOrUpdateSingleEntryRequest
 */
export interface CreateOrUpdateSingleEntryRequest {
  /**
   *
   * @type {string}
   * @memberof CreateOrUpdateSingleEntryRequest
   */
  entry_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateOrUpdateSingleEntryRequest
   */
  about_username?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateOrUpdateSingleEntryRequest
   */
  conversation?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateOrUpdateSingleEntryRequest
   */
  goal?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateOrUpdateSingleEntryRequest
   */
  concerns?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateOrUpdateSingleEntryRequest
   */
  relationship?: string | null;
}

export function CreateOrUpdateSingleEntryRequestFromJSON(
  json: any
): CreateOrUpdateSingleEntryRequest {
  return CreateOrUpdateSingleEntryRequestFromJSONTyped(json, false);
}

export function CreateOrUpdateSingleEntryRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateOrUpdateSingleEntryRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    entry_id: !exists(json, "entry_id") ? undefined : json["entry_id"],
    about_username: !exists(json, "about_username")
      ? undefined
      : json["about_username"],
    conversation: !exists(json, "conversation")
      ? undefined
      : json["conversation"],
    goal: !exists(json, "goal") ? undefined : json["goal"],
    concerns: !exists(json, "concerns") ? undefined : json["concerns"],
    relationship: !exists(json, "relationship")
      ? undefined
      : json["relationship"],
  };
}

export function CreateOrUpdateSingleEntryRequestToJSON(
  value?: CreateOrUpdateSingleEntryRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    entry_id: value.entry_id,
    about_username: value.about_username,
    conversation: value.conversation,
    goal: value.goal,
    concerns: value.concerns,
    relationship: value.relationship,
  };
}
