import React from "react";
import { PencilIcon } from "@heroicons/react/20/solid";
import MIconButtonComponent, { MIconButtonProps } from "./MIconButton";

const MEditIconButtonComponent = (props: MIconButtonProps) => {
  const editProps = {
    ...props,
  };

  editProps.icon = <PencilIcon className="w-full h-full" aria-hidden="true" />;

  return <MIconButtonComponent {...editProps} />;
};

export default MEditIconButtonComponent;
