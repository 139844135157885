/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  PublishablePromptWithResponse,
  PublishablePromptWithResponseFromJSON,
  PublishablePromptWithResponseFromJSONTyped,
  PublishablePromptWithResponseToJSON,
} from "./PublishablePromptWithResponse";

/**
 *
 * @export
 * @interface GetPromptForAssessmentResponse
 */
export interface GetPromptForAssessmentResponse {
  /**
   *
   * @type {PublishablePromptWithResponse}
   * @memberof GetPromptForAssessmentResponse
   */
  prompt_response: PublishablePromptWithResponse;
  /**
   *
   * @type {number}
   * @memberof GetPromptForAssessmentResponse
   */
  prompt_index: number;
  /**
   *
   * @type {number}
   * @memberof GetPromptForAssessmentResponse
   */
  num_prompts: number;
  /**
   *
   * @type {number}
   * @memberof GetPromptForAssessmentResponse
   */
  num_answers_required: number;
  /**
   *
   * @type {number}
   * @memberof GetPromptForAssessmentResponse
   */
  num_answers: number;
  /**
   *
   * @type {boolean}
   * @memberof GetPromptForAssessmentResponse
   */
  assessment_completed: boolean;
}

export function GetPromptForAssessmentResponseFromJSON(
  json: any
): GetPromptForAssessmentResponse {
  return GetPromptForAssessmentResponseFromJSONTyped(json, false);
}

export function GetPromptForAssessmentResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetPromptForAssessmentResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    prompt_response: PublishablePromptWithResponseFromJSON(
      json["prompt_response"]
    ),
    prompt_index: json["prompt_index"],
    num_prompts: json["num_prompts"],
    num_answers_required: json["num_answers_required"],
    num_answers: json["num_answers"],
    assessment_completed: json["assessment_completed"],
  };
}

export function GetPromptForAssessmentResponseToJSON(
  value?: GetPromptForAssessmentResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    prompt_response: PublishablePromptWithResponseToJSON(value.prompt_response),
    prompt_index: value.prompt_index,
    num_prompts: value.num_prompts,
    num_answers_required: value.num_answers_required,
    num_answers: value.num_answers,
    assessment_completed: value.assessment_completed,
  };
}
