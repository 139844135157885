/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UpdateGlimpseContentRequest
 */
export interface UpdateGlimpseContentRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateGlimpseContentRequest
   */
  glimpse: string;
  /**
   *
   * @type {string}
   * @memberof UpdateGlimpseContentRequest
   */
  content: string;
}

export function UpdateGlimpseContentRequestFromJSON(
  json: any
): UpdateGlimpseContentRequest {
  return UpdateGlimpseContentRequestFromJSONTyped(json, false);
}

export function UpdateGlimpseContentRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateGlimpseContentRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    glimpse: json["glimpse"],
    content: json["content"],
  };
}

export function UpdateGlimpseContentRequestToJSON(
  value?: UpdateGlimpseContentRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    glimpse: value.glimpse,
    content: value.content,
  };
}
