/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Choices
 */
export interface Choices {
  /**
   *
   * @type {string}
   * @memberof Choices
   */
  question: string;
  /**
   *
   * @type {string}
   * @memberof Choices
   */
  statement: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Choices
   */
  tags?: Array<ChoicesTagsEnum>;
  /**
   *
   * @type {Array<string>}
   * @memberof Choices
   */
  choices: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum ChoicesTagsEnum {
  deep = "deep",
  weird = "weird",
  awkward = "awkward",
  would_you_rather = "would you rather",
  practicals = "practicals",
  daily = "daily",
  other = "other",
}

export function ChoicesFromJSON(json: any): Choices {
  return ChoicesFromJSONTyped(json, false);
}

export function ChoicesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Choices {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    question: json["question"],
    statement: json["statement"],
    tags: !exists(json, "tags") ? undefined : json["tags"],
    choices: json["choices"],
  };
}

export function ChoicesToJSON(value?: Choices | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    question: value.question,
    statement: value.statement,
    tags: value.tags,
    choices: value.choices,
  };
}
