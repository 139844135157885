import React, { ReactElement, useState } from "react";
import GroupInsightCard from "./GroupInsightCard";
import MBackButton from "../../../ui/buttons/MBackButton";
import CenterColumnStickyButtonScreen from "../../../ui/screens/CenterColumnStickyButtonScreen";
import { useBusyWatcher } from "../../../../util/hooks";
import { classNames } from "../../../../util/strings";
import { commentText } from "../../../../util/style";
import MTextArea from "../../../ui/MTextArea";
import GroupSummaryCard from "../GroupSummaryCard";
import { GroupQuestionAndAnswer, UserGroup } from "../../../../api/models";
import { useAsyncConfirmationDialog } from "../../../dialogs/hooks";
import MSubtitle from "../../../ui/MSubtitle";
import MButton from "../../../ui/buttons/MButton";

type GroupInsightScreenProps = {
  group: UserGroup;
  groupInsights: GroupQuestionAndAnswer[];
  question?: string | null;
  viewGroupUrl: string;
  editGroupUrl: string;
  errors: string[];
  onInsightClicked: (insight: GroupQuestionAndAnswer) => void;
  onDeleteInsight: (insightId: string, groupId: string) => void;
  onQuestionSelected: (question: string) => void;
  onBackClicked: () => void;
};

const GroupInsightScreenComponent = (props: GroupInsightScreenProps) => {
  const {
    group,
    groupInsights,
    question: questionInput,
    viewGroupUrl,
    editGroupUrl,
    errors,
    onInsightClicked: onViewInsightInput,
    onDeleteInsight: onDeleteInsightInput,
    onQuestionSelected,
    onBackClicked,
  } = props;

  const [question, setQuestion] = useState<string>(questionInput ?? "");
  const [busy, _] = useBusyWatcher();
  const confirmDialog = useAsyncConfirmationDialog();

  const onDeleteGroupInsightClicked = async (
    groupInsight: GroupQuestionAndAnswer
  ) => {
    const result = await confirmDialog.confirm({
      title: "delete insight?",
      body: "are you sure you want to delete this insight? this action cannot be undone.",
    });
    if (!result) {
      return;
    }
    onDeleteInsightInput(groupInsight.guid!, group.guid!);
  };

  const onViewInsightClicked = async (groupInsight: GroupQuestionAndAnswer) => {
    onViewInsightInput(groupInsight);
  };

  const canSubmit = (): boolean => question !== "";

  const getButtons = (): ReactElement[] => [
    <MBackButton
      kind="secondary"
      onClick={onBackClicked}
      disabled={busy}
      key="back-button"
      text="my groups"
    />,
  ];

  return (
    <>
      {confirmDialog.dialog}
      <CenterColumnStickyButtonScreen buttons={getButtons()} stacked fullWidth>
        <GroupSummaryCard
          group={group}
          key={group.guid!}
          viewGroupUrl={viewGroupUrl}
          editGroupUrl={editGroupUrl}
          hideDeleteButton
          hideLeaveButton
        />
        <div className="font-bold text-left mb-3 mt-6">
          what do you want to know about the members of '
          {group.name ?? "this group"}'?
        </div>
        <div className={classNames(commentText, "text-xs mb-3 text-left")}>
          the more specific you are in your question, the better we can answer.
          (group members will never see your question and answers)
        </div>
        <MTextArea
          disabled={busy}
          value={question}
          placeholder={
            "some ideas: \nwhere should we plan an offsite?\nanalyze group dynamics\nhow should we divide work for a group project?"
          }
          onChange={(e) => setQuestion(e.target.value)}
          errors={errors}
        />
        <MButton
          className="mt-3 w-full"
          disabled={busy || !canSubmit()}
          onClick={() => onQuestionSelected(question)}
          kind="primary"
        >
          ask question
        </MButton>
        <MSubtitle
          text={`insights about '${group.name}'`}
          className="text-left mt-6 mb-3"
        />
        {groupInsights && groupInsights.length > 0 ? (
          <div className="flex flex-col gap-2">
            {groupInsights.map((groupInsight) => (
              <GroupInsightCard
                key={groupInsight.guid!}
                groupInsight={groupInsight}
                group={group}
                hideDeleteButton={false}
                simplify
                onViewInsightClick={() => onViewInsightClicked(groupInsight)}
                onDeleteClick={() => onDeleteGroupInsightClicked(groupInsight)}
              />
            ))}
          </div>
        ) : (
          <div className={classNames(commentText, "text-center")}>
            you don't have any insights yet! you can ask questions about group
            dynamics, mediate conflicts, and more.
          </div>
        )}
      </CenterColumnStickyButtonScreen>
    </>
  );
};

GroupInsightScreenComponent.defaultProps = {
  question: null,
};

export default GroupInsightScreenComponent;
