/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SetBasicInfo
 */
export interface SetBasicInfo {
  /**
   *
   * @type {string}
   * @memberof SetBasicInfo
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof SetBasicInfo
   */
  location: string;
  /**
   *
   * @type {string}
   * @memberof SetBasicInfo
   */
  title: string | null;
  /**
   *
   * @type {string}
   * @memberof SetBasicInfo
   */
  employer: string | null;
  /**
   *
   * @type {Date}
   * @memberof SetBasicInfo
   */
  dob: Date;
}

export function SetBasicInfoFromJSON(json: any): SetBasicInfo {
  return SetBasicInfoFromJSONTyped(json, false);
}

export function SetBasicInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SetBasicInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    location: json["location"],
    title: json["title"],
    employer: json["employer"],
    dob: new Date(json["dob"]),
  };
}

export function SetBasicInfoToJSON(value?: SetBasicInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    location: value.location,
    title: value.title,
    employer: value.employer,
    dob: value.dob.toISOString().substr(0, 10),
  };
}
