import React, { PropsWithChildren, ReactElement } from "react";
import MWell, { MWellProps, WellKind } from "./MWell";
import { classNames } from "../../../util/strings";

type MTitleWellProps = MWellProps & {
  title: string;
  icon?: ReactElement | null;
  kind?: WellKind;
};

const MTitleWellComponent = (props: PropsWithChildren<MTitleWellProps>) => {
  const { title, icon, kind, children, ...rest } = props;

  const getClassFromKind = (): string => {
    switch (kind) {
      case "magical":
        return "text-primary";
      default:
        return "text-m-dark-gray";
    }
  };

  return (
    <MWell kind={kind} {...rest}>
      <div
        className={classNames(
          "flex flex-row items-center gap-1 text-sm mb-3",
          getClassFromKind()
        )}
      >
        {icon && <div className="mr-2 w-5 h-5">{icon}</div>}
        <div>{title}</div>
      </div>
      {children}
    </MWell>
  );
};

MTitleWellComponent.defaultProps = {
  kind: "regular" as WellKind,
  icon: null,
};

export default MTitleWellComponent;
