import React, { ReactNode } from "react";
import MVisibilityToggle from "../MVisibilityToggle";

export type VisibilityFooterProps = {
  onToggleVisibilityClicked: () => void;
  toggleVisibilityDisabled?: boolean;
  visibilityCopy?: string;
  isVisible?: boolean;
  disabledTooltip?: string | null;
  buttons?: ReactNode[] | null;
};

const VisibilityFooterComponent = (props: VisibilityFooterProps) => {
  const {
    onToggleVisibilityClicked,
    toggleVisibilityDisabled,
    visibilityCopy,
    isVisible,
    disabledTooltip,
    buttons,
  } = props;

  return (
    <div className="flex flex-row gap-3 items-center">
      {buttons}
      <div className="grow" />
      <MVisibilityToggle
        onClick={onToggleVisibilityClicked}
        disabled={toggleVisibilityDisabled}
        copy={visibilityCopy}
        isVisible={isVisible}
        disabledTooltip={disabledTooltip}
      />
    </div>
  );
};

VisibilityFooterComponent.defaultProps = {
  toggleVisibilityDisabled: false,
  visibilityCopy: "show on profile",
  isVisible: true,
  disabledTooltip: null,
  buttons: null,
};

export default VisibilityFooterComponent;
