import React, { useEffect, useState } from "react";
import { MNavProps } from "../../../ui/nav/MNav";
import { useBackTimer, useBusyWatcher } from "../../../../util/hooks";
import { useWrappedConnector } from "../../../../api/connector";
import { useAsyncConfirmationDialog } from "../../../dialogs/hooks";
import WizardScreen, {
  WizardScreenPanel,
} from "../../../ui/screens/WizardScreen";
import LoadingScreen from "../../../ui/screens/LoadingScreen";
import BaseNavbarPage from "../../BaseNavbarPage";
import {
  GenTaglineCheckResponse,
  GetGenTaglineResponse,
  GetTaglineResponse,
} from "../../../../api/models";
import waitForTask from "../../../../util/tasks";
import BioReviewSuggestionScreen from "./BioReviewSuggestionScreen";
import { errorMessagesForKeyFromResponse } from "../../../../api/helpers";
import BioViewScreen from "./BioViewScreen";
import EditTextScreen from "../../../ui/screens/EditTextScreen";
import TodoScreen from "../../../ui/screens/TodoScreen";

type BioPageProps = {
  nextUrl: string;
  navbarProps: MNavProps;
};

enum BioStage {
  LOADING,
  GEN_INTRO,
  PROCESSING_GEN,
  REVIEW_GEN_HEADLINE,
  REVIEW_GEN_BIO,
  VIEW,
  EDIT_HEADLINE,
  EDIT_BIO,
}

const BioPageComponent = (props: BioPageProps) => {
  const { nextUrl, navbarProps } = props;

  const [errors, setErrors] = useState<string[]>([]);
  const [stage, setStage] = useState<BioStage>(BioStage.LOADING);
  const [tagline, setTagline] = useState<GetTaglineResponse | null>(null);
  const [checks, setChecks] = useState<GenTaglineCheckResponse | null>(null);
  const [genTagline, setGenTagline] = useState<GetGenTaglineResponse | null>(
    null
  );
  const [hasGen, setHasGen] = useState<boolean>(false);
  const [isBack, asBack] = useBackTimer(stage);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);
  const confirmDialog = useAsyncConfirmationDialog();

  const goToStage = async (nextStage: BioStage) => {
    setErrors([]);
    // eslint-disable-next-line default-case
    switch (nextStage) {
      case BioStage.GEN_INTRO: {
        const checksResponse = await connector.getGenTaglineTodos();
        setChecks(checksResponse.c!);
        break;
      }
      case BioStage.REVIEW_GEN_HEADLINE: {
        const taglineResponse = await connector.getProfileTagline();
        setTagline(taglineResponse.c!);
        break;
      }
      case BioStage.REVIEW_GEN_BIO: {
        const taglineResponse = await connector.getProfileTagline();
        setTagline(taglineResponse.c!);
        break;
      }
      case BioStage.VIEW: {
        const taglineResponse = await connector.getProfileTagline();
        setTagline(taglineResponse.c!);
        break;
      }
    }
    setStage(nextStage);
  };

  const load = async () => {
    const taglineResponse = await connector.getProfileTagline();
    setTagline(taglineResponse.c!);
    if (taglineResponse.c!.tagline_ever_set) {
      setStage(BioStage.VIEW);
    } else {
      setHasGen(true);
      await goToStage(BioStage.GEN_INTRO);
    }
  };

  const onSaveHeadlineClicked = async (newHeadline: string) => {
    setErrors([]);
    try {
      await connector.setHeadline(newHeadline);
    } catch (e: any) {
      const parsed = await errorMessagesForKeyFromResponse(e, "headline");
      setErrors(parsed);
      return;
    }
    if (stage === BioStage.REVIEW_GEN_HEADLINE) {
      await goToStage(BioStage.REVIEW_GEN_BIO);
    } else {
      await goToStage(BioStage.VIEW);
    }
  };

  const onSaveBioClicked = async (newBio: string) => {
    setErrors([]);
    try {
      await connector.setBio(newBio);
    } catch (e: any) {
      const parsed = await errorMessagesForKeyFromResponse(e, "bio");
      setErrors(parsed);
      return;
    }
    await goToStage(BioStage.VIEW);
  };

  const onGenerateBioClicked = async () => {
    const genResponse = await connector.genTagline();
    if (genResponse.c!.is_task_completed) {
      const genTaglineResponse = await connector.getGenTagline(
        genResponse.c!.guid!
      );
      setGenTagline(genTaglineResponse.c!);
      await goToStage(BioStage.REVIEW_GEN_HEADLINE);
      return;
    }
    setStage(BioStage.PROCESSING_GEN);
    const success = await waitForTask(genResponse.c!.task_id!, connector);
    if (!success) {
      setErrors([
        "something broke while generating your tagline :( try again in a little bit",
      ]);
      return;
    }
    const genTaglineResponse = await connector.getGenTagline(
      genResponse.c!.guid!
    );
    setGenTagline(genTaglineResponse.c!);
    await goToStage(BioStage.REVIEW_GEN_HEADLINE);
  };

  const onClearBioClicked = async () => {
    const result = await confirmDialog.confirm({
      title: "delete bio?",
      body: "are you sure you want to delete your bio? this can't be undone.",
    });
    if (!result) {
      return;
    }
    await connector.setBio(null);
    if (stage === BioStage.VIEW) {
      const newTagline = await connector.getProfileTagline();
      setTagline(newTagline.c!);
    } else {
      await goToStage(BioStage.VIEW);
    }
  };

  const onClearHeadlineClicked = async () => {
    const result = await confirmDialog.confirm({
      title: "delete headline?",
      body: "are you sure you want to delete your headline? this can't be undone.",
    });
    if (!result) {
      return;
    }
    await connector.setHeadline(null);
    if (stage === BioStage.VIEW) {
      const newTagline = await connector.getProfileTagline();
      setTagline(newTagline.c!);
    } else {
      await goToStage(BioStage.VIEW);
    }
  };

  const getElements = (): WizardScreenPanel[] => [
    {
      stage: BioStage.LOADING,
      content: (
        <LoadingScreen
          errors={errors}
          onBackClicked={() => window.history.back()}
        />
      ),
    },
    {
      stage: BioStage.GEN_INTRO,
      content: checks && (
        <TodoScreen
          todos={checks.todos}
          onNextClicked={onGenerateBioClicked}
          title="generate your tagline"
          outstandingCopy="the more we know about you, the better we can generate a tagline for you! we recommend finishing all the items on the list below before continuing."
          completedCopy="you've completed all the steps we recommend and your profile looks great! click the 'continue' button below to see your tagline."
          onBackClicked={() => window.history.back()}
        />
      ),
    },
    {
      stage: BioStage.PROCESSING_GEN,
      content: (
        <LoadingScreen
          errors={errors}
          message="give us just a moment to generate a snazzy tagline for you..."
          onBackClicked={asBack(() => goToStage(BioStage.GEN_INTRO))}
        />
      ),
    },
    {
      stage: BioStage.REVIEW_GEN_HEADLINE,
      content: tagline && genTagline && (
        <BioReviewSuggestionScreen
          onBackClicked={asBack(() => goToStage(BioStage.GEN_INTRO))}
          onNextClicked={onSaveHeadlineClicked}
          onSkipClicked={() => goToStage(BioStage.REVIEW_GEN_BIO)}
          title="review your headline"
          context="we think this is a pretty good headline to introduce yourself with. if you want to change it, you can do so below."
          existingComment="your current headline"
          existing={tagline.headline}
          suggestedComment="our suggested headline"
          suggested={genTagline.headline}
          errors={errors}
        />
      ),
    },
    {
      stage: BioStage.REVIEW_GEN_BIO,
      content: tagline && genTagline && (
        <BioReviewSuggestionScreen
          onBackClicked={asBack(() => goToStage(BioStage.REVIEW_GEN_HEADLINE))}
          onNextClicked={onSaveBioClicked}
          onSkipClicked={() => goToStage(BioStage.VIEW)}
          title="review your bio"
          context="we think this is a good pretty description to follow your headline. if you want to change it, you can do so below."
          existingComment="your current bio"
          existing={tagline.bio}
          suggestedComment="our suggested bio"
          suggested={genTagline.bio}
          errors={errors}
        />
      ),
    },
    {
      stage: BioStage.VIEW,
      content: tagline && (
        <BioViewScreen
          tagline={tagline}
          onEditHeadlineClicked={() => goToStage(BioStage.EDIT_HEADLINE)}
          onClearHeadlineClicked={onClearHeadlineClicked}
          onEditBioClicked={() => goToStage(BioStage.EDIT_BIO)}
          onClearBioClicked={onClearBioClicked}
          onBackClicked={
            hasGen ? asBack(() => goToStage(BioStage.REVIEW_GEN_BIO)) : null
          }
          onDoneClicked={() => {
            window.location.href = nextUrl;
          }}
        />
      ),
    },
    {
      stage: BioStage.EDIT_BIO,
      content: tagline && (
        <EditTextScreen
          onNextClicked={onSaveBioClicked}
          onBackClicked={asBack(() => goToStage(BioStage.VIEW))}
          onClearClicked={tagline.bio ? onClearBioClicked : null}
          title="edit your bio"
          label="bio"
          content={tagline.bio}
          errors={errors}
          placeholder={
            "I've got three cats and I love to cook! On weekends I ..."
          }
          long
        />
      ),
    },
    {
      stage: BioStage.EDIT_HEADLINE,
      content: tagline && (
        <EditTextScreen
          onNextClicked={onSaveHeadlineClicked}
          onBackClicked={asBack(() => goToStage(BioStage.VIEW))}
          onClearClicked={tagline.headline ? onClearHeadlineClicked : null}
          title="edit your headline"
          label="headline"
          content={tagline.headline}
          errors={errors}
          placeholder={"Hi! I'm ... and I like ..."}
          long
        />
      ),
    },
  ];

  useEffect(() => {
    load();
  }, []);

  return (
    <BaseNavbarPage
      navbarProps={{
        ...navbarProps,
        forceLoading: stage === BioStage.PROCESSING_GEN,
      }}
    >
      {confirmDialog.dialog}
      <WizardScreen isBack={isBack} elements={getElements()} stage={stage} />
    </BaseNavbarPage>
  );
};

export default BioPageComponent;
