import React, { useEffect, useState } from "react";
import { MNavProps } from "../../ui/nav/MNav";
import { useBackTimer, useBusyWatcher } from "../../../util/hooks";
import { useWrappedConnector } from "../../../api/connector";
import {
  CommonSingleUserQuestionInsightResponse,
  SearchForConnectionResponse,
  UserProfileSummary,
} from "../../../api/models";
import WizardScreen, { WizardScreenPanel } from "../../ui/screens/WizardScreen";
import LoadingScreen from "../../ui/screens/LoadingScreen";
import BaseNavbarPage from "../BaseNavbarPage";
import { errorMessagesForKeyFromResponse } from "../../../api/helpers";
import UserSearchScreen from "../../ui/screens/UserSearchScreen";
import EnterQuestionScreen from "./EnterQuestionScreen";
import waitForTask from "../../../util/tasks";
import ViewSingleUserInsightScreen from "../insights/ViewSingleUserInsightScreen";

type AskQuestionPageProps = {
  navbarProps: MNavProps;
  nextUrl: string;
  requestingUserGuid: string;
  userGuid?: string | null;
};

enum AskQuestionStage {
  LOADING,
  PICK_USER,
  ASK_QUESTION,
  PROCESSING,
  VIEW_RESULT,
}

const AskQuestionPageComponent = (props: AskQuestionPageProps) => {
  const { navbarProps, nextUrl, requestingUserGuid, userGuid } = props;

  const [errors, setErrors] = useState<string[]>([]);
  const [stage, setStage] = useState<AskQuestionStage>(
    AskQuestionStage.LOADING
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] =
    useState<SearchForConnectionResponse | null>(null);
  const [user, setUser] = useState<UserProfileSummary | null>(null);
  const [question, setQuestion] = useState<string>("");
  const [insightResponse, setInsightResponse] =
    useState<CommonSingleUserQuestionInsightResponse | null>(null);
  const [isBack, asBack] = useBackTimer(stage);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);

  const goToStage = async (nextStage: AskQuestionStage) => {
    setErrors([]);
    // eslint-disable-next-line default-case
    switch (nextStage) {
      case AskQuestionStage.PICK_USER: {
        setSearchTerm("");
        setSearchResults(null);
        break;
      }
    }
    setStage(nextStage);
  };

  const refreshSearchResults = async (newSearchTerm: string) => {
    if (newSearchTerm === searchTerm) {
      return;
    }
    setSearchTerm(newSearchTerm);
    if (newSearchTerm === "") {
      setSearchResults(null);
    } else {
      const result = await connector.searchConnections({
        search_term: newSearchTerm,
      });
      setSearchResults(result.c!);
    }
  };

  const loadUserFromGuid = async () => {
    try {
      const response = await connector.getUserProfileSummary({
        user_id: userGuid!,
      });
      setUser(response.c!);
      await goToStage(AskQuestionStage.ASK_QUESTION);
    } catch (e: any) {
      const parsed = await errorMessagesForKeyFromResponse(e, "user_id", true);
      setErrors(parsed);
    }
  };

  const onSelectUserClicked = async (newUser: UserProfileSummary) => {
    setUser(newUser);
    await goToStage(AskQuestionStage.ASK_QUESTION);
  };

  const onQuestionSelected = async (newQuestion: string) => {
    setErrors([]);
    setQuestion(newQuestion);
    setStage(AskQuestionStage.PROCESSING);
    let content: CommonSingleUserQuestionInsightResponse;
    try {
      const response = await connector.askSingleUserInsight({
        username: user!.username!,
        question: newQuestion,
      });
      content = response.c!;
    } catch (e: any) {
      const parsed = await errorMessagesForKeyFromResponse(e, "question", true);
      setErrors(parsed);
      return;
    }
    if (content.insight.is_task_completed) {
      const response = await connector.getSingleUserInsight(
        content.insight.guid!
      );
      setInsightResponse(response.c!);
      await goToStage(AskQuestionStage.VIEW_RESULT);
      return;
    }
    const success = await waitForTask(content.insight.task_id!, connector);
    if (!success) {
      setErrors([
        "something broke while processing your question :( try again in a little bit",
      ]);
      return;
    }
    const response = await connector.getSingleUserInsight(
      content.insight.guid!
    );
    setInsightResponse(response.c!);
    await goToStage(AskQuestionStage.VIEW_RESULT);
  };

  useEffect(() => {
    if (userGuid) {
      loadUserFromGuid();
    } else {
      goToStage(AskQuestionStage.PICK_USER);
    }
  }, []);

  const getElements = (): WizardScreenPanel[] => [
    {
      stage: AskQuestionStage.LOADING,
      content: (
        <LoadingScreen
          errors={errors}
          onBackClicked={() => window.history.back()}
        />
      ),
    },
    {
      stage: AskQuestionStage.PICK_USER,
      content: (
        <UserSearchScreen
          title="who do you want to ask a question about?"
          searchTerm={searchTerm}
          searchResults={searchResults?.results ?? null}
          onSearchTermUpdated={refreshSearchResults}
          onBackClicked={() => window.history.back()}
          onUserClicked={onSelectUserClicked}
          errors={errors}
          searchPlaceholder="search my people"
          startSearchingPlaceholder={
            "start typing above to search through your people by name or username.\n\nnote that only accounts that have been added to 'my people' will be shown here."
          }
        />
      ),
    },
    {
      stage: AskQuestionStage.ASK_QUESTION,
      content: user && (
        <EnterQuestionScreen
          user={user}
          question={question}
          onQuestionSelected={onQuestionSelected}
          errors={errors}
          isSelf={user.id === requestingUserGuid}
          onBackClicked={asBack(() => goToStage(AskQuestionStage.PICK_USER))}
        />
      ),
    },
    {
      stage: AskQuestionStage.PROCESSING,
      content: (
        <LoadingScreen
          errors={errors}
          onBackClicked={asBack(() => goToStage(AskQuestionStage.ASK_QUESTION))}
          message="processing your question..."
        />
      ),
    },
    {
      stage: AskQuestionStage.VIEW_RESULT,
      content: insightResponse && (
        <ViewSingleUserInsightScreen
          insight={insightResponse}
          onDoneClicked={() => {
            window.location.href = nextUrl;
          }}
        />
      ),
    },
  ];

  return (
    <BaseNavbarPage
      navbarProps={{
        ...navbarProps,
        forceLoading: stage === AskQuestionStage.PROCESSING,
      }}
    >
      <WizardScreen isBack={isBack} elements={getElements()} stage={stage} />
    </BaseNavbarPage>
  );
};

AskQuestionPageComponent.defaultProps = {
  userGuid: null,
};

export default AskQuestionPageComponent;
