import React, { Fragment, ReactNode, useEffect, useRef, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import LoadingBar, { LoadingBarRef } from "react-top-loading-bar";
import { usePostHog } from "posthog-js/react";
import { classNames } from "../../../util/strings";
import logoUrl from "../../../assets/images/small_logo.png";
import defaultProfilePic from "../../../assets/images/default_pfp.png";
import {
  PAGINATION_LIMIT,
  PROFILE_COMPLETION_STAGE_KEY,
  TEXT_BLUR_DELAY,
} from "../../../util/constants";
import { useBusyWatcher } from "../../../util/hooks";
import MButton from "../buttons/MButton";
import MSearchBox from "../MSearchBox";
import {
  GetEventTypeEventEnum,
  GetNotificationCountsResponse,
  UserNotificationRelatedDetail,
  UserProfileSummary,
  UserSearchResponse,
} from "../../../api/models";
import { useWrappedConnector } from "../../../api/connector";
import { errorMessagesForKeyFromResponse } from "../../../api/helpers";
import NavUserSearchResults from "./NavUserSearchResults";
import { middleColumnContent } from "../../../util/style";
import InviteUserDialog from "../../dialogs/InviteUserDialog";
import MNotifBubble from "../MNotifBubble";
import NotificationsDropdown from "./NotificationsDropdown";
import { isScrollEventAtBottom } from "../../../util/scroll";
import { PubSubEvent, usePubSub } from "../../../util/usePubSub";

type MNavItem = {
  name: string;
  href: string;
  current: boolean;
  primary: boolean;
};

export type MNavProps = {
  navItems: MNavItem[];
  settingsUrl: string;
  profileUrl?: string | null;
  isAuthenticated: boolean;
  profilePicUrl?: string | null;
  profilePicName?: string | null;
  loginUrl?: string | null;
  showSearch?: boolean;
  email?: string | null;
  forceLoading?: boolean;
  pushdown?: ReactNode;
  pushdownClassname?: string | null;
  fullWidth?: boolean;
};

const MNav = (props: MNavProps) => {
  const {
    navItems,
    settingsUrl,
    loginUrl,
    profilePicUrl,
    profilePicName,
    isAuthenticated,
    showSearch,
    email,
    forceLoading,
    pushdown,
    pushdownClassname,
    profileUrl,
    fullWidth,
  } = props;

  const { publish } = usePubSub();
  const [busy, busyWatcher] = useBusyWatcher();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<UserSearchResponse | null>(
    null
  );
  const [showInvite, setShowInvite] = useState<boolean>(false);
  const [inviteSent, setInviteSent] = useState<boolean>(false);
  const [inviteErrors, setInviteErrors] = useState<string[]>([]);
  const [searching, setSearching] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [loadingBarRunning, setLoadingBarRunning] = useState<boolean>(false);
  const [showNotifs, setShowNotifs] = useState<boolean>(false);
  const [notifCounts, setNotifCounts] =
    useState<GetNotificationCountsResponse | null>(null);
  const [notifs, setNotifs] = useState<UserNotificationRelatedDetail[] | null>(
    null
  );
  const [moreNotifsLoading, setMoreNotifsLoading] = useState<boolean>(false);
  const connector = useWrappedConnector(busyWatcher);
  const loadingBarRef = useRef<LoadingBarRef>(null);
  const navBarRef = useRef<HTMLElement>(null);
  const pushDownRef = useRef<HTMLDivElement>(null);
  const posthog = usePostHog();

  const loadNotifCounts = async () => {
    const response = await connector.getNotificationCounts();
    setNotifCounts(response.c!);
  };

  const loadNotifs = async (): Promise<UserNotificationRelatedDetail[]> => {
    const response = await connector.listNotifications();
    setNotifs(response.c!.notifs);
    return response.c!.notifs;
  };

  const loadMoreNotifs = async (): Promise<UserNotificationRelatedDetail[]> => {
    const response = await connector.listNotifications({
      offset: notifs!.length,
      limit: PAGINATION_LIMIT,
    });
    const newNotifs = [...notifs!, ...response.c!.notifs];
    setNotifs(newNotifs);
    return response.c!.notifs;
  };

  useEffect(() => {
    if ((busy || forceLoading) && !loadingBarRunning) {
      loadingBarRef.current!.continuousStart();
      setLoadingBarRunning(true);
    }
    if (!busy && !forceLoading && loadingBarRunning) {
      loadingBarRef.current!.complete();
      setLoadingBarRunning(false);
    }
  }, [busy, forceLoading]);

  const signOutClicked = async () => {
    await connector.logout();
    localStorage.removeItem(PROFILE_COMPLETION_STAGE_KEY);
    window.location.href = "/";
  };

  const searchForUsers = async (newSearchTerm: string) => {
    if (newSearchTerm === "") {
      setSearchResults(null);
      setSearchTerm(newSearchTerm);
      return;
    }
    setSearching(true);
    setSearchTerm(newSearchTerm);
    try {
      const response = await connector.searchForUser(newSearchTerm);
      setSearchResults(response.c!);
    } catch (e: any) {
      const parsed = await errorMessagesForKeyFromResponse(e, "term", true);
      setErrors(parsed);
    } finally {
      setSearching(false);
    }
  };

  const getTopForSearchOverlay = (): number => {
    if (pushDownRef.current) {
      return pushDownRef.current.getBoundingClientRect().bottom;
    }
    return navBarRef.current!.getBoundingClientRect().bottom;
  };

  const getHeightForSearchOverlay = (): number =>
    window.innerHeight - getTopForSearchOverlay();

  const getTopNavItemCount = (): number => navItems.length;

  const getBottomNavItemCount = (): number => {
    if (!isAuthenticated) {
      return 0;
    }
    return 2;
  };

  const hasNavItems: boolean =
    getTopNavItemCount() + getBottomNavItemCount() > 0;

  const onShowInviteClicked = () => {
    setInviteErrors([]);
    setInviteSent(false);
    setShowInvite(true);
  };

  const onInviteEmailSubmitted = async (inviteEmail: string) => {
    setInviteErrors([]);
    try {
      await connector.inviteEmail(inviteEmail);
      setInviteSent(true);
    } catch (e: any) {
      const parsed = await errorMessagesForKeyFromResponse(e, "email", true);
      setInviteErrors(parsed);
      return;
    }
    setInviteSent(true);
  };

  const onInviteAnotherClicked = () => {
    setInviteErrors([]);
    setInviteSent(false);
  };

  const onClearNotifClicked = async (notif: string): Promise<boolean> => {
    await connector.markNotificationsAsCleared({ notifs: [notif] });
    return true;
  };

  const onUnclearNotifClicked = async (notif: string): Promise<boolean> => {
    await connector.markNotificationsAsNotCleared({ notifs: [notif] });
    return true;
  };

  const onShowNotifsClicked = async () => {
    if (showNotifs) {
      setShowNotifs(false);
    } else {
      posthog.capture(GetEventTypeEventEnum.notifs_opened);
      setShowNotifs(true);
      const loadedNotifs = await loadNotifs();
      const newSeenNotifs = loadedNotifs
        .filter((n) => !n.is_seen)
        .map((n) => n.guid!);
      if (newSeenNotifs.length === 0) {
        return;
      }
      await connector.markNotificationsAsViewed({ notifs: newSeenNotifs });
      await loadNotifCounts();
    }
  };

  const onDropdownBottomHit = async () => {
    if (notifs!.length === notifCounts!.total) {
      return;
    }
    setMoreNotifsLoading(true);
    const loadedNotifs = await loadMoreNotifs();
    setMoreNotifsLoading(false);
    const newSeenNotifs = loadedNotifs
      .filter((n) => !n.is_seen)
      .map((n) => n.guid!);
    if (newSeenNotifs.length === 0) {
      return;
    }
    await connector.markNotificationsAsViewed({ notifs: newSeenNotifs });
    await loadNotifCounts();
  };

  const onNotifsScrolled = async (e: React.UIEvent<HTMLElement>) => {
    if (isScrollEventAtBottom(e)) {
      await onDropdownBottomHit();
    }
  };

  const onConnectionAccepted = async (connection: string) => {
    await connector.respondToConnectionRequest({
      request: connection,
      accepted: true,
    });
    publish(PubSubEvent.ConnectionChanged);
    return true;
  };

  const onConnectionRejected = async (connection: string) => {
    await connector.respondToConnectionRequest({
      request: connection,
      accepted: false,
    });
    publish(PubSubEvent.ConnectionChanged);
    return true;
  };

  const getNotifElement = (): ReactNode => (
    <Menu as="div" className="relative flex-shrink-0">
      <Menu.Button
        onClick={onShowNotifsClicked}
        className="rounded-full bg-m-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
      >
        <span className="absolute -inset-1.5" />
        <span className="sr-only">View notifications</span>
        <BellIcon className="h-6 w-6" aria-hidden="true" />
        {notifCounts !== null && notifCounts.unread > 0 && (
          <MNotifBubble count={notifCounts.unread} />
        )}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        afterLeave={() => {
          setNotifs(null);
          setShowNotifs(false);
        }}
      >
        <Menu.Items
          className={classNames(
            "fixed w-screen flex flex-col items-center sm:w-auto sm:absolute origin-top-right right-0"
          )}
        >
          <div
            className={classNames(
              "mt-2 w-screen max-w-[96vw] sm:max-w-md max-h-[92vh] sm:max-h-[60vh]",
              "rounded-lg bg-m-white shadow-lg",
              "ring-1 ring-m-light-gray focus:outline-none text-m-dark-gray overflow-auto"
            )}
            onScroll={onNotifsScrolled}
          >
            <NotificationsDropdown
              notifications={notifs}
              onClearClicked={onClearNotifClicked}
              onUnclearClicked={onUnclearNotifClicked}
              moreLoading={moreNotifsLoading}
              onAcceptConnectionRequest={onConnectionAccepted}
              onRejectConnectionRequest={onConnectionRejected}
            />
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );

  useEffect(() => {
    if (isAuthenticated) {
      loadNotifCounts();
    }
  }, []);

  return (
    <>
      <LoadingBar
        height={4}
        shadow={false}
        color="#4289ed"
        ref={loadingBarRef}
      />
      <Disclosure
        ref={navBarRef}
        as="nav"
        className={classNames("bg-m-white", !pushdown ? "mb-3 shadow" : null)}
      >
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
              <div className="flex h-16 justify-between">
                <div className="flex px-2 lg:px-0">
                  <div className="flex flex-shrink-0 items-center">
                    <a href="/">
                      <img className="h-8 w-auto" src={logoUrl} alt="Manual" />
                    </a>
                  </div>
                  <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                    {navItems.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          "inline-flex items-center border-b-4 px-1 pt-2 text-sm font-medium",
                          item.current
                            ? "border-primary text-m-dark-gray font-semibold"
                            : "border-transparent text-m-gray hover:border-primary/50 hover:text-m-dark-gray"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
                <div className="relative flex flex-1 items-center justify-center px-2 lg:ml-6 lg:justify-end">
                  {showSearch && (
                    <>
                      <MSearchBox
                        onFocus={() => setShowSearchResults(true)}
                        onBlur={() =>
                          setTimeout(() => {
                            setShowSearchResults(false);
                          }, TEXT_BLUR_DELAY)
                        }
                        onSearchTermUpdated={searchForUsers}
                        errors={errors}
                        placeholder="search users"
                      />
                      {showSearchResults && (
                        <>
                          <div className="fixed left-0 w-full lg:left-auto lg:absolute lg:w-auto lg:right-2 z-20 top-14 flex flex-row justify-center">
                            <NavUserSearchResults
                              users={searchResults?.users || []}
                              onUserClicked={(
                                clickedUser: UserProfileSummary
                              ) => {
                                window.location.href = `/@${clickedUser.username}`;
                              }}
                              searching={searching}
                              searchTerm={searchTerm}
                              onInviteUserClicked={onShowInviteClicked}
                              showInviteButton={isAuthenticated}
                            />
                          </div>
                          <div
                            className="fixed left-0 w-screen h-20 top-10 bg-m-dark-gray opacity-30 z-10"
                            style={{
                              top: getTopForSearchOverlay(),
                              height: getHeightForSearchOverlay(),
                            }}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
                {!isAuthenticated && loginUrl && (
                  <div className="flex flex-col justify-center">
                    <MButton
                      className="rounded-lg mx-3"
                      href={loginUrl}
                      kind="primary"
                    >
                      log in
                    </MButton>
                  </div>
                )}
                {isAuthenticated && (
                  <div className="flex items-center ml-2 mr-1 lg:mr-0">
                    {getNotifElement()}
                  </div>
                )}
                {hasNavItems && (
                  <div className="flex items-center lg:hidden px-2">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                )}
                <div className="hidden lg:flex lg:items-center">
                  {/* Profile dropdown */}
                  {isAuthenticated && (
                    <Menu as="div" className="relative ml-4 flex-shrink-0">
                      <div>
                        <Menu.Button className="relative flex rounded-full bg-m-white text-sm focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full bg-m-gray"
                            src={profilePicUrl || defaultProfilePic}
                            alt={profilePicName!}
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-m-white shadow-lg ring-1 ring-m-light-gray focus:outline-none">
                          {profileUrl && (
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href={profileUrl}
                                  className={classNames(
                                    active ? "bg-primary/20 font-semibold" : "",
                                    "block px-4 py-2 text-sm text-m-dark-gray"
                                  )}
                                >
                                  profile
                                </a>
                              )}
                            </Menu.Item>
                          )}
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href={settingsUrl}
                                className={classNames(
                                  active ? "bg-primary/20 font-semibold" : "",
                                  "block px-4 py-2 text-sm text-m-dark-gray"
                                )}
                              >
                                settings
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                type="button"
                                onClick={signOutClicked}
                                className={classNames(
                                  active ? "bg-primary/20 font-semibold" : "",
                                  "block px-4 py-2 text-sm text-m-dark-gray w-full text-left"
                                )}
                              >
                                sign out
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}
                </div>
              </div>
            </div>

            <Disclosure.Panel className="lg:hidden">
              <div className="space-y-1 pb-3 pt-2 bg-m-white">
                {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800" */}
                {navItems.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      "block border-l-4 py-2 pl-3 pr-4 text-base font-medium",
                      item.current
                        ? "border-primary bg-primary-light text-primary font-semibold"
                        : "border-transparent text-m-dark-gray hover:border-primary/50 hover:bg-primary/5 hover:text-m-black"
                    )}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              {isAuthenticated && (
                <div className="border-t border-gray-200 pb-3 pt-4">
                  <div className="flex items-center px-4">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full bg-m-gray"
                        src={profilePicUrl || defaultProfilePic}
                        alt={profilePicName!}
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-m-dark-gray">
                        {profilePicName}
                      </div>
                      <div className="text-sm font-medium text-m-dark-gray">
                        {email}
                      </div>
                    </div>
                    <button
                      type="button"
                      className="hidden relative ml-auto flex-shrink-0 rounded-full bg-m-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="space-y-1 mt-3">
                    <Disclosure.Button
                      as="a"
                      href={settingsUrl}
                      className="block px-4 py-2 text-base font-medium text-m-dark-gray hover:bg-primary/5 hover:text-m-black"
                    >
                      settings
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="button"
                      onClick={signOutClicked}
                      className="w-full text-left block px-4 py-2 text-base font-medium text-m-dark-gray hover:bg-primary/5 hover:text-m-black"
                    >
                      sign out
                    </Disclosure.Button>
                  </div>
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      {pushdown && (
        <div
          ref={pushDownRef}
          className={classNames(
            !fullWidth ? "px-4 lg:px-6" : null,
            "py-2 sm:mb-2 shadow",
            pushdownClassname
          )}
        >
          <div
            className={classNames(
              middleColumnContent,
              fullWidth ? "lg:max-w-7xl px-4 sm:px-0 lg:px-8" : null
            )}
          >
            {pushdown}
          </div>
        </div>
      )}
      <InviteUserDialog
        show={showInvite}
        onClose={() => setShowInvite(false)}
        errors={inviteErrors}
        onEmailSubmitted={onInviteEmailSubmitted}
        settingsUrl={settingsUrl}
        sent={inviteSent}
        onInviteAnotherClicked={onInviteAnotherClicked}
      />
    </>
  );
};

MNav.defaultProps = {
  profilePicUrl: null,
  profilePicName: null,
  loginUrl: null,
  showSearch: true,
  email: null,
  forceLoading: false,
  pushdown: null,
  pushdownClassname: null,
  profileUrl: null,
  fullWidth: false,
};

export default MNav;
