import React, { useCallback, useState } from "react";
import ConfirmationDialog, {
  ConfirmationDialogProps,
} from "./ConfirmationDialog";
import UserSearchDialog, { UserSearchDialogProps } from "./UserSearchDialog";
import { UserProfileSummary } from "../../api/models";
import FileSelectDialog, { FileSelectDialogProps } from "./FileSelectDialog";
import { useBusyWatcher } from "../../util/hooks";
import getDataUri from "../../util/base64";

export const useAsyncFileSelectDialog = () => {
  const [show, setShow] = useState<boolean>(false);

  const [_, busyWatcher] = useBusyWatcher();
  const [props, setProps] = useState<FileSelectDialogProps>({});
  const [promiseResolve, setPromiseResolve] = useState<
    ((content: [string | null, string | null]) => void) | null
  >(null);

  const selectFile = useCallback(async (innerProps: FileSelectDialogProps) => {
    setShow(true);
    setProps(innerProps);

    return new Promise<[string | null, string | null]>((resolve) => {
      // NOTE: If you pass a callback to setState, React will call that callback,
      // so that's why this is wrapped in a pointless arrow function.
      setPromiseResolve(() => resolve);
    });
  }, []);

  const onFileSelected = async (files: File[] | null) => {
    if (files === null || files.length === 0) {
      promiseResolve?.([null, null]);
      setShow(false);
      return;
    }
    const encoded = await busyWatcher(getDataUri(files[0]));
    promiseResolve?.([encoded, files[0].name]);
    setShow(false);
  };

  return {
    dialog: (
      <FileSelectDialog onClose={onFileSelected} show={show} {...props} />
    ),
    selectFile,
  };
};

export const useAsyncUserSearchDialog = () => {
  const [show, setShow] = useState<boolean>(false);

  const [props, setProps] = useState<UserSearchDialogProps>({});

  const [promiseResolve, setPromiseResolve] = useState<
    ((selected: UserProfileSummary | null) => void) | null
  >(null);

  const selectUser = useCallback(async (innerProps: UserSearchDialogProps) => {
    setShow(true);
    setProps(innerProps);

    return new Promise<UserProfileSummary | null>((resolve) => {
      // NOTE: If you pass a callback to setState, React will call that callback,
      // so that's why this is wrapped in a pointless arrow function.
      setPromiseResolve(() => resolve);
    });
  }, []);

  return {
    dialog: show ? (
      <UserSearchDialog
        onUserSelected={(user: UserProfileSummary) => {
          setShow(false);
          promiseResolve?.(user);
        }}
        onCancel={() => {
          setShow(false);
          promiseResolve?.(null);
        }}
        {...props}
      />
    ) : null,
    selectUser,
  };
};

export const useAsyncConfirmationDialog = () => {
  const [show, setShow] = useState<boolean>(false);

  const [props, setProps] = useState<ConfirmationDialogProps>({});

  const [promiseResolve, setPromiseResolve] = useState<
    ((confirmed: boolean) => void) | null
  >(null);

  const confirm = useCallback(async (innerProps: ConfirmationDialogProps) => {
    setShow(true);
    setProps(innerProps);

    return new Promise<boolean>((resolve) => {
      // NOTE: If you pass a callback to setState, React will call that callback,
      // so that's why this is wrapped in a pointless arrow function.
      setPromiseResolve(() => resolve);
    });
  }, []);

  return {
    dialog: show ? (
      <ConfirmationDialog
        onConfirm={() => {
          setShow(false);
          promiseResolve?.(true);
        }}
        onCancel={() => {
          setShow(false);
          promiseResolve?.(false);
        }}
        {...props}
      />
    ) : null,
    confirm,
  };
};
