/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Attachments,
  AttachmentsFromJSON,
  AttachmentsFromJSONTyped,
  AttachmentsToJSON,
} from "./Attachments";
import {
  EmployerRelatedDetail,
  EmployerRelatedDetailFromJSON,
  EmployerRelatedDetailFromJSONTyped,
  EmployerRelatedDetailToJSON,
} from "./EmployerRelatedDetail";
import {
  LinkSummary,
  LinkSummaryFromJSON,
  LinkSummaryFromJSONTyped,
  LinkSummaryToJSON,
} from "./LinkSummary";
import {
  PromptWithResponse,
  PromptWithResponseFromJSON,
  PromptWithResponseFromJSONTyped,
  PromptWithResponseToJSON,
} from "./PromptWithResponse";
import {
  UserProfileDetail,
  UserProfileDetailFromJSON,
  UserProfileDetailFromJSONTyped,
  UserProfileDetailToJSON,
} from "./UserProfileDetail";

/**
 *
 * @export
 * @interface CommonProfileContents
 */
export interface CommonProfileContents {
  /**
   *
   * @type {UserProfileDetail}
   * @memberof CommonProfileContents
   */
  user: UserProfileDetail;
  /**
   *
   * @type {Array<LinkSummary>}
   * @memberof CommonProfileContents
   */
  socials: Array<LinkSummary>;
  /**
   *
   * @type {Array<EmployerRelatedDetail>}
   * @memberof CommonProfileContents
   */
  work_history: Array<EmployerRelatedDetail>;
  /**
   *
   * @type {Array<PromptWithResponse>}
   * @memberof CommonProfileContents
   */
  sections: Array<PromptWithResponse>;
  /**
   *
   * @type {Attachments}
   * @memberof CommonProfileContents
   */
  attachments: Attachments;
}

export function CommonProfileContentsFromJSON(
  json: any
): CommonProfileContents {
  return CommonProfileContentsFromJSONTyped(json, false);
}

export function CommonProfileContentsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommonProfileContents {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    user: UserProfileDetailFromJSON(json["user"]),
    socials: (json["socials"] as Array<any>).map(LinkSummaryFromJSON),
    work_history: (json["work_history"] as Array<any>).map(
      EmployerRelatedDetailFromJSON
    ),
    sections: (json["sections"] as Array<any>).map(PromptWithResponseFromJSON),
    attachments: AttachmentsFromJSON(json["attachments"]),
  };
}

export function CommonProfileContentsToJSON(
  value?: CommonProfileContents | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    user: UserProfileDetailToJSON(value.user),
    socials: (value.socials as Array<any>).map(LinkSummaryToJSON),
    work_history: (value.work_history as Array<any>).map(
      EmployerRelatedDetailToJSON
    ),
    sections: (value.sections as Array<any>).map(PromptWithResponseToJSON),
    attachments: AttachmentsToJSON(value.attachments),
  };
}
