import React, { useEffect, useState } from "react";
import { MNavProps } from "../../ui/nav/MNav";
import { useBackTimer, useBusyWatcher } from "../../../util/hooks";
import { useWrappedConnector } from "../../../api/connector";
import WizardScreen, { WizardScreenPanel } from "../../ui/screens/WizardScreen";
import LoadingScreen from "../../ui/screens/LoadingScreen";
import { useAsyncConfirmationDialog } from "../../dialogs/hooks";
import BaseNavbarPage from "../BaseNavbarPage";
import { GetProfilePhotoResponse } from "../../../api/models";
import AddProfilePictureScreen from "./AddProfilePictureScreen";
import EditProfilePictureScreen from "./EditProfilePictureScreen";

type ProfilePicturePageProps = {
  nextUrl: string;
  navbarProps: MNavProps;
};

enum ProfilePictureStage {
  LOADING,
  VIEW,
  ADD,
}

const ProfilePicturePageComponent = (props: ProfilePicturePageProps) => {
  const { nextUrl, navbarProps } = props;

  const [errors, setErrors] = useState<string[]>([]);
  const [stage, setStage] = useState<ProfilePictureStage>(
    ProfilePictureStage.LOADING
  );
  const [photoResponse, setPhotoResponse] =
    useState<GetProfilePhotoResponse | null>(null);
  const [isBack, asBack] = useBackTimer(stage);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);
  const confirmDialog = useAsyncConfirmationDialog();

  const goToStage = async (nextStage: ProfilePictureStage) => {
    setErrors([]);
    // eslint-disable-next-line default-case
    switch (nextStage) {
      case ProfilePictureStage.VIEW: {
        const response = await connector.getProfilePhoto();
        setPhotoResponse(response.c!);
        break;
      }
    }
    setStage(nextStage);
  };

  const onClearPhotoClicked = async () => {
    const result = await confirmDialog.confirm({
      title: `delete profile picture?`,
      body: `are you sure you want to delete your profile picture? this action cannot be undone.`,
    });
    if (!result) {
      return;
    }
    await connector.clearProfilePhoto();
    const newResponse = await connector.getProfilePhoto();
    setPhotoResponse(newResponse.c!);
  };

  const onSavePhotoClicked = async (dataUrl: string) => {
    await connector.uploadProfilePhoto(dataUrl);
    await goToStage(ProfilePictureStage.VIEW);
  };

  const getElements = (): WizardScreenPanel[] => [
    {
      stage: ProfilePictureStage.LOADING,
      content: (
        <LoadingScreen
          errors={errors}
          onBackClicked={() => window.history.back()}
        />
      ),
    },
    {
      stage: ProfilePictureStage.VIEW,
      content: photoResponse && (
        <EditProfilePictureScreen
          photoResponse={photoResponse}
          onDoneClicked={() => {
            window.location.href = nextUrl;
          }}
          onClearClicked={onClearPhotoClicked}
          onAddClicked={() => goToStage(ProfilePictureStage.ADD)}
        />
      ),
    },
    {
      stage: ProfilePictureStage.ADD,
      content: (
        <AddProfilePictureScreen
          onBackClicked={asBack(() => goToStage(ProfilePictureStage.VIEW))}
          onSaveClicked={onSavePhotoClicked}
        />
      ),
    },
  ];

  useEffect(() => {
    goToStage(ProfilePictureStage.VIEW);
  }, []);

  return (
    <BaseNavbarPage navbarProps={navbarProps}>
      {confirmDialog.dialog}
      <WizardScreen isBack={isBack} elements={getElements()} stage={stage} />
    </BaseNavbarPage>
  );
};

export default ProfilePicturePageComponent;
