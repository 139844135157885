/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  UserProfileSummary,
  UserProfileSummaryFromJSON,
  UserProfileSummaryFromJSONTyped,
  UserProfileSummaryToJSON,
} from "./UserProfileSummary";

/**
 *
 * @export
 * @interface PromptRelatedDetail
 */
export interface PromptRelatedDetail {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof PromptRelatedDetail
   */
  readonly guid?: string;
  /**
   * The question content
   * @type {string}
   * @memberof PromptRelatedDetail
   */
  question: string;
  /**
   * The contents of the question but rephrased as a statement
   * @type {string}
   * @memberof PromptRelatedDetail
   */
  statement: string;
  /**
   * The type of question
   * @type {string}
   * @memberof PromptRelatedDetail
   */
  question_type: string;
  /**
   * Metadata associated with the question
   * @type {object}
   * @memberof PromptRelatedDetail
   */
  meta?: object | null;
  /**
   *
   * @type {Array<string>}
   * @memberof PromptRelatedDetail
   */
  tags: Array<string>;
  /**
   * The total number of responses to this prompt
   * @type {number}
   * @memberof PromptRelatedDetail
   */
  num_responses?: number;
  /**
   * The total number of votes on this prompt
   * @type {number}
   * @memberof PromptRelatedDetail
   */
  num_votes?: number;
  /**
   *
   * @type {string}
   * @memberof PromptRelatedDetail
   */
  readonly view_url?: string;
  /**
   *
   * @type {UserProfileSummary}
   * @memberof PromptRelatedDetail
   */
  author?: UserProfileSummary;
}

export function PromptRelatedDetailFromJSON(json: any): PromptRelatedDetail {
  return PromptRelatedDetailFromJSONTyped(json, false);
}

export function PromptRelatedDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PromptRelatedDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    question: json["question"],
    statement: json["statement"],
    question_type: json["question_type"],
    meta: !exists(json, "meta") ? undefined : json["meta"],
    tags: json["tags"],
    num_responses: !exists(json, "num_responses")
      ? undefined
      : json["num_responses"],
    num_votes: !exists(json, "num_votes") ? undefined : json["num_votes"],
    view_url: !exists(json, "view_url") ? undefined : json["view_url"],
    author: !exists(json, "author")
      ? undefined
      : UserProfileSummaryFromJSON(json["author"]),
  };
}

export function PromptRelatedDetailToJSON(
  value?: PromptRelatedDetail | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    question: value.question,
    statement: value.statement,
    question_type: value.question_type,
    meta: value.meta,
    tags: value.tags,
    num_responses: value.num_responses,
    num_votes: value.num_votes,
    author: UserProfileSummaryToJSON(value.author),
  };
}
