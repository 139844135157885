import {
  UserGroupIcon,
  UserIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { MNavProps } from "../../ui/nav/MNav";
import SidebarNavScreen, {
  NavigationItem,
} from "../../ui/screens/sidebarnav/SidebarNavScreen";
import { useBusyWatcher } from "../../../util/hooks";
import { useWrappedConnector } from "../../../api/connector";
import BaseNavbarPage from "../BaseNavbarPage";
import {
  GroupSummary,
  ListGroupsResponse,
  ListPendingReceivedConnectionRequestsResponse,
  ListPendingSentConnectionRequestsResponse,
  SearchForConnectionResponse,
  UserConnectionRequestRelatedDetail,
} from "../../../api/models";
import ListMyPeopleScreen from "./ListMyPeopleScreen";
import { errorMessagesForKeyFromResponse } from "../../../api/helpers";
import ListGroupsScreen from "./ListGroupsScreen";
import { getQueryParam } from "../../../util/url";
import ListPendingRequestsScreen from "./ListPendingRequestsScreen";
import { PAGINATION_LIMIT } from "../../../util/constants";

const secondaryNavigation: NavigationItem[] = [
  { name: "My People", icon: UserIcon },
  { name: "My Groups", icon: UserGroupIcon },
  { name: "Requests", icon: UserPlusIcon },
];

type MyPeoplePageProps = {
  viewGroupUrl: string;
  createGroupUrl: string;
  editGroupUrl: string;
  navbarProps: MNavProps;
};

const MyPeoplePageComponent = (props: MyPeoplePageProps) => {
  const { viewGroupUrl, createGroupUrl, editGroupUrl, navbarProps } = props;

  const [loading, setLoading] = useState<boolean>(true);
  const [curPane, setCurPane] = useState<string>(secondaryNavigation[0].name);
  const [userSearchTerm, setUserSearchTerm] = useState<string>("");
  const [connectionsOffset, setConnectionsOffset] = useState<number>(0);
  const [connections, setConnections] =
    useState<SearchForConnectionResponse | null>(null);
  const [sentConnectionRequests, setSentConnectionRequests] =
    useState<ListPendingSentConnectionRequestsResponse | null>(null);
  const [receivedConnectionRequests, setReceivedConnectionRequests] =
    useState<ListPendingReceivedConnectionRequestsResponse | null>(null);
  const [groups, setGroups] = useState<ListGroupsResponse | null>(null);
  const [errorsList, setErrorsList] = useState<string[]>([]);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);

  const loadSentConnectionRequests = async () => {
    const response = await connector.listPendingSentConnectionRequests();
    setSentConnectionRequests(response.c!);
  };

  const loadReceivedConnectionRequests = async () => {
    const response = await connector.listPendingReceivedConnectionRequests();
    setReceivedConnectionRequests(response.c!);
  };

  const loadConnectionRequests = async () => {
    await Promise.all([
      loadSentConnectionRequests(),
      loadReceivedConnectionRequests(),
    ]);
  };

  const loadConnections = async (
    connectionsSearchTerm: string,
    offset: number
  ) => {
    setErrorsList([]);
    try {
      const response = await connector.searchConnections({
        search_term: connectionsSearchTerm,
        limit: PAGINATION_LIMIT,
        offset,
      });
      setConnections(response.c!);
      setUserSearchTerm(connectionsSearchTerm);
      setConnectionsOffset(offset);
    } catch (e: any) {
      const parsed = await errorMessagesForKeyFromResponse(
        e,
        "search_term",
        true
      );
      setErrorsList(parsed);
    }
  };

  const loadGroups = async () => {
    const response = await connector.listGroups();
    setGroups(response.c!);
  };

  const onDeleteGroup = async (toDelete: GroupSummary) => {
    await connector.deleteGroup(toDelete.guid!);
    await loadGroups();
  };

  const onAcceptClicked = async (
    request: UserConnectionRequestRelatedDetail
  ) => {
    await connector.respondToConnectionRequest({
      request: request.guid!,
      accepted: true,
    });
    toast.success("accepted connection request");
    await loadReceivedConnectionRequests();
  };

  const onRejectClicked = async (
    request: UserConnectionRequestRelatedDetail
  ) => {
    await connector.respondToConnectionRequest({
      request: request.guid!,
      accepted: false,
    });
    toast.success("rejected connection request");
    await loadReceivedConnectionRequests();
  };

  const goToPane = async (nextPane: string) => {
    setLoading(true);
    setCurPane(nextPane);
    setErrorsList([]);
    // eslint-disable-next-line default-case
    switch (nextPane) {
      case "My People":
        await loadConnections("", 0);
        break;
      case "My Groups":
        await loadGroups();
        break;
      case "Requests":
        await loadConnectionRequests();
        break;
    }
    setLoading(false);
  };

  useEffect(() => {
    const redirect = getQueryParam("next");
    if (redirect === "my-groups") {
      goToPane("My Groups");
    } else {
      goToPane("My People");
    }
  }, []);

  return (
    <BaseNavbarPage navbarProps={{ ...navbarProps, showSearch: false }}>
      <SidebarNavScreen
        items={secondaryNavigation.map((item) => ({
          ...item,
          current: item.name === curPane,
        }))}
        onNavItemClicked={(item) => goToPane(item.name)}
      >
        {curPane === "My People" && (
          <ListMyPeopleScreen
            loading={loading}
            response={connections}
            errors={errorsList}
            searchTerm={userSearchTerm}
            onSearchTermUpdated={(term: string) => loadConnections(term, 0)}
            onPageClicked={(page: number) =>
              loadConnections(userSearchTerm, (page - 1) * PAGINATION_LIMIT)
            }
            offset={connectionsOffset}
            pageSize={PAGINATION_LIMIT}
          />
        )}
        {curPane === "My Groups" && (
          <ListGroupsScreen
            loading={loading}
            groups={groups}
            createGroupUrl={createGroupUrl}
            viewGroupUrl={viewGroupUrl}
            editGroupUrl={editGroupUrl}
            onDeleteGroup={onDeleteGroup}
          />
        )}
        {curPane === "Requests" && (
          <ListPendingRequestsScreen
            loading={loading}
            pendingSentRequests={sentConnectionRequests}
            pendingReceivedRequests={receivedConnectionRequests}
            onAcceptClicked={onAcceptClicked}
            onRejectClicked={onRejectClicked}
          />
        )}
      </SidebarNavScreen>
    </BaseNavbarPage>
  );
};

export default MyPeoplePageComponent;
