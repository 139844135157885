import { TrashIcon } from "@heroicons/react/24/outline";
import React from "react";
import MWellButton from "./MWellButton";
import { CommonWellButtonProps } from "./common";

const MWellDeleteButtonComponent = (props: CommonWellButtonProps) => (
  <MWellButton {...props} icon={<TrashIcon />} />
);

MWellDeleteButtonComponent.defaultProps = {
  disabled: false,
  tooltip: "delete",
};

export default MWellDeleteButtonComponent;
