import React, { ReactNode, useState } from "react";
import FormScreen from "./FormScreen";
import { useBusyWatcher } from "../../../util/hooks";
import MTextArea from "../MTextArea";
import MInput from "../MInput";

type EditTextScreenProps = {
  onNextClicked: (newContent: string) => void;
  onBackClicked?: (() => void) | null;
  onClearClicked?: (() => void) | null;
  title: string;
  comment?: string | null;
  label: string;
  content?: string | null;
  long?: boolean;
  errors: string[];
  placeholder?: string | null;
  maxLength?: number | null;
  required?: boolean | null;
  extra?: ReactNode;
  stacked?: boolean;
};

const EditTextScreenComponent = (props: EditTextScreenProps) => {
  const {
    onNextClicked,
    onBackClicked,
    onClearClicked,
    title,
    label,
    content: contentInput,
    long,
    errors: errorsInput,
    placeholder,
    comment,
    maxLength,
    required,
    extra,
    stacked,
  } = props;

  const [content, setContent] = useState<string>(contentInput || "");
  const [busy, _] = useBusyWatcher();

  const canSubmit = (): boolean => {
    if (required && content.length === 0) {
      return false;
    }
    if (maxLength && content.length > maxLength) {
      return false;
    }
    return true;
  };

  const getErrors = (): string[] => {
    const toReturn: string[] = [...errorsInput];
    if (maxLength && content.length > maxLength) {
      toReturn.push(`content must be less than ${maxLength} characters`);
    }
    return toReturn;
  };

  return (
    <FormScreen
      title={title}
      comment={comment}
      onNextClicked={() => onNextClicked(content)}
      nextDisabled={busy || !canSubmit()}
      onBackClicked={onBackClicked}
      backDisabled={busy}
      onClearClicked={onClearClicked}
      clearDisabled={busy}
      stacked={stacked}
      fullWidth
    >
      {extra}
      {long ? (
        <MTextArea
          disabled={busy}
          label={label}
          value={content}
          required={required ?? false}
          onChange={(e) => setContent(e.target.value)}
          errors={getErrors()}
          placeholder={placeholder || ""}
        />
      ) : (
        <MInput
          disabled={busy}
          label={label}
          value={content}
          required={required ?? false}
          onChange={(e) => setContent(e.target.value)}
          errors={getErrors()}
          placeholder={placeholder || ""}
        />
      )}
    </FormScreen>
  );
};

EditTextScreenComponent.defaultProps = {
  onBackClicked: null,
  onClearClicked: null,
  content: null,
  long: false,
  placeholder: null,
  comment: null,
  maxLength: null,
  required: null,
  extra: null,
  stacked: false,
};

export default EditTextScreenComponent;
