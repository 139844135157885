/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UserProfileDetail
 */
export interface UserProfileDetail {
  /**
   *
   * @type {string}
   * @memberof UserProfileDetail
   */
  username?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserProfileDetail
   */
  readonly photo?: string;
  /**
   *
   * @type {string}
   * @memberof UserProfileDetail
   */
  readonly id?: string;
  /**
   *
   * @type {string}
   * @memberof UserProfileDetail
   */
  readonly profile_url?: string;
  /**
   *
   * @type {string}
   * @memberof UserProfileDetail
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserProfileDetail
   */
  pronouns?: string | null;
  /**
   * The user's current title at their current employer.
   * @type {string}
   * @memberof UserProfileDetail
   */
  current_title?: string | null;
  /**
   * The user's current employer.
   * @type {string}
   * @memberof UserProfileDetail
   */
  current_employer?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserProfileDetail
   */
  location?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserProfileDetail
   */
  relationship?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserProfileDetail
   */
  beliefs?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserProfileDetail
   */
  sexual_orientation?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserProfileDetail
   */
  ethnicity?: string | null;
  /**
   *
   * @type {number}
   * @memberof UserProfileDetail
   */
  kids?: number | null;
  /**
   * A longer form description of the user.
   * @type {string}
   * @memberof UserProfileDetail
   */
  bio?: string | null;
  /**
   * A single sentence tagline to describe the user.
   * @type {string}
   * @memberof UserProfileDetail
   */
  headline?: string | null;
  /**
   * The user's Myers-Briggs Type Indicator.
   * @type {string}
   * @memberof UserProfileDetail
   */
  mbti?: string | null;
  /**
   * The user's love language.
   * @type {string}
   * @memberof UserProfileDetail
   */
  love_language?: string | null;
  /**
   * The user's enneagram type.
   * @type {string}
   * @memberof UserProfileDetail
   */
  enneagram?: string | null;
  /**
   * The user's zodiac sign.
   * @type {string}
   * @memberof UserProfileDetail
   */
  zodiac?: string | null;
  /**
   * The user's human design type.
   * @type {string}
   * @memberof UserProfileDetail
   */
  human_design?: string | null;
}

export function UserProfileDetailFromJSON(json: any): UserProfileDetail {
  return UserProfileDetailFromJSONTyped(json, false);
}

export function UserProfileDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserProfileDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    username: !exists(json, "username") ? undefined : json["username"],
    photo: !exists(json, "photo") ? undefined : json["photo"],
    id: !exists(json, "id") ? undefined : json["id"],
    profile_url: !exists(json, "profile_url") ? undefined : json["profile_url"],
    name: !exists(json, "name") ? undefined : json["name"],
    pronouns: !exists(json, "pronouns") ? undefined : json["pronouns"],
    current_title: !exists(json, "current_title")
      ? undefined
      : json["current_title"],
    current_employer: !exists(json, "current_employer")
      ? undefined
      : json["current_employer"],
    location: !exists(json, "location") ? undefined : json["location"],
    relationship: !exists(json, "relationship")
      ? undefined
      : json["relationship"],
    beliefs: !exists(json, "beliefs") ? undefined : json["beliefs"],
    sexual_orientation: !exists(json, "sexual_orientation")
      ? undefined
      : json["sexual_orientation"],
    ethnicity: !exists(json, "ethnicity") ? undefined : json["ethnicity"],
    kids: !exists(json, "kids") ? undefined : json["kids"],
    bio: !exists(json, "bio") ? undefined : json["bio"],
    headline: !exists(json, "headline") ? undefined : json["headline"],
    mbti: !exists(json, "mbti") ? undefined : json["mbti"],
    love_language: !exists(json, "love_language")
      ? undefined
      : json["love_language"],
    enneagram: !exists(json, "enneagram") ? undefined : json["enneagram"],
    zodiac: !exists(json, "zodiac") ? undefined : json["zodiac"],
    human_design: !exists(json, "human_design")
      ? undefined
      : json["human_design"],
  };
}

export function UserProfileDetailToJSON(value?: UserProfileDetail | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    username: value.username,
    name: value.name,
    pronouns: value.pronouns,
    current_title: value.current_title,
    current_employer: value.current_employer,
    location: value.location,
    relationship: value.relationship,
    beliefs: value.beliefs,
    sexual_orientation: value.sexual_orientation,
    ethnicity: value.ethnicity,
    kids: value.kids,
    bio: value.bio,
    headline: value.headline,
    mbti: value.mbti,
    love_language: value.love_language,
    enneagram: value.enneagram,
    zodiac: value.zodiac,
    human_design: value.human_design,
  };
}
