import React, { useEffect, useState } from "react";
import { usePostHog } from "posthog-js/react";
import { MNavProps } from "../../ui/nav/MNav";
import { useBackTimer, useBusyWatcher } from "../../../util/hooks";
import { useWrappedConnector } from "../../../api/connector";
import WizardScreen, { WizardScreenPanel } from "../../ui/screens/WizardScreen";
import LoadingScreen from "../../ui/screens/LoadingScreen";
import BaseNavbarPage from "../BaseNavbarPage";
import ListInsightsScreen from "./ListInsightsScreen";
import {
  CommonSingleUserQuestionInsightResponse,
  DeleteInsightRequestInsightTypeEnum,
  GetCollabByIdResponse,
  GetEventTypeEventEnum,
  GetProfileResponse,
  GetSingleEntryResponse,
  InsightResponseEnvelope,
  InsightResponseEnvelopeInsightTypeEnum,
  SearchForInsightsResponse,
} from "../../../api/models";
import { errorMessagesForKeyFromResponse } from "../../../api/helpers";
import NewQuestionScreen from "./NewQuestionScreen";
import ViewCollabScreen from "./ViewCollabScreen";
import ViewCommCoachSingleScreen from "./ViewCommCoachSingleScreen";
import ViewSingleUserInsightScreen from "./ViewSingleUserInsightScreen";

type InsightsPageProps = {
  navbarProps: MNavProps;
  singleUserInsightUrl: string;
  collabUrl: string;
  singleUserCommCoachUrl: string;
  userGuid?: string | null;
  collabGuid?: string | null;
};

enum InsightsStage {
  LOADING,
  SEARCH,
  CREATE_NEW,
  VIEW_COLLAB,
  VIEW_COMM_COACH_SINGLE,
  VIEW_SINGLE_USER_INSIGHT,
}

const InsightsPageComponent = (props: InsightsPageProps) => {
  const {
    navbarProps,
    singleUserInsightUrl,
    collabUrl,
    singleUserCommCoachUrl,
    userGuid,
    collabGuid,
  } = props;

  const [profile, setProfile] = useState<GetProfileResponse | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [stage, setStage] = useState<InsightsStage>(InsightsStage.LOADING);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] =
    useState<SearchForInsightsResponse | null>(null);
  const [collab, setCollab] = useState<GetCollabByIdResponse | null>(null);
  const [commCoach, setCommCoach] = useState<GetSingleEntryResponse | null>(
    null
  );
  const [singleInsight, setSingleInsight] =
    useState<CommonSingleUserQuestionInsightResponse | null>(null);
  const [isBack, asBack] = useBackTimer(stage);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);
  const posthog = usePostHog();

  const searchForTerm = async (term: string, forceSearch = false) => {
    if (term === searchTerm && !forceSearch) {
      return;
    }
    setSearchTerm(term);
    try {
      const results = await connector.searchForInsights({
        search: term,
      });
      setSearchResults(results.c!);
    } catch (e: any) {
      const parsed = await errorMessagesForKeyFromResponse(e, "search", true);
      setErrors(parsed);
      setSearchResults(null);
    }
  };

  const goToStage = async (nextStage: InsightsStage) => {
    setErrors([]);
    // eslint-disable-next-line default-case
    switch (nextStage) {
      case InsightsStage.SEARCH: {
        await searchForTerm("", true);
        break;
      }
      case InsightsStage.VIEW_SINGLE_USER_INSIGHT: {
        posthog.capture(GetEventTypeEventEnum.user_insight_viewed);
        break;
      }
      case InsightsStage.VIEW_COLLAB: {
        posthog.capture(GetEventTypeEventEnum.user_collab_viewed);
        break;
      }
      case InsightsStage.VIEW_COMM_COACH_SINGLE: {
        posthog.capture(GetEventTypeEventEnum.comm_coach_viewed);
        break;
      }
    }
    setStage(nextStage);
  };

  const fetchProfileAndGoToSelect = async () => {
    const response = await connector.getProfile({ user_id: userGuid! });
    setProfile(response.c!);
    await goToStage(InsightsStage.CREATE_NEW);
  };

  const fetchCollabAndGoToView = async () => {
    const response = await connector.getCollabById(collabGuid!);
    setCollab(response.c!);
    await goToStage(InsightsStage.VIEW_COLLAB);
  };

  const onInsightClicked = async (insight: InsightResponseEnvelope) => {
    switch (insight.insight_type) {
      case InsightResponseEnvelopeInsightTypeEnum.user_collab: {
        const response = await connector.getCollabById(insight.collab!.guid!);
        setCollab(response.c!);
        await goToStage(InsightsStage.VIEW_COLLAB);
        break;
      }
      case InsightResponseEnvelopeInsightTypeEnum.single_comm_coach: {
        const response = await connector.getCommCoachSingleEntry(
          insight.single_comm_coach!.guid!
        );
        setCommCoach(response.c!);
        await goToStage(InsightsStage.VIEW_COMM_COACH_SINGLE);
        break;
      }
      case InsightResponseEnvelopeInsightTypeEnum.single_user_insight: {
        const response = await connector.getSingleUserInsight(
          insight.single_user_insight!.guid!
        );
        setSingleInsight(response.c!);
        await goToStage(InsightsStage.VIEW_SINGLE_USER_INSIGHT);
        break;
      }
      default: {
        throw new Error(`Unsupported insight type ${insight.insight_type}`);
      }
    }
  };

  const onDeleteInsight = async (insight: InsightResponseEnvelope) => {
    let insightGuid: string;
    switch (insight.insight_type) {
      case InsightResponseEnvelopeInsightTypeEnum.user_collab: {
        insightGuid = insight.collab!.guid!;
        break;
      }
      case InsightResponseEnvelopeInsightTypeEnum.single_comm_coach: {
        insightGuid = insight.single_comm_coach!.guid!;
        break;
      }
      case InsightResponseEnvelopeInsightTypeEnum.single_user_insight: {
        insightGuid = insight.single_user_insight!.guid!;
        break;
      }
      default: {
        throw new Error(`Unsupported insight type ${insight.insight_type}`);
      }
    }
    await connector.deleteInsight({
      data: {
        insight_guid: insightGuid,
        insight_type:
          insight.insight_type as string as DeleteInsightRequestInsightTypeEnum,
      },
    });
    await searchForTerm("", true);
  };

  useEffect(() => {
    if (userGuid) {
      fetchProfileAndGoToSelect();
    } else if (collabGuid) {
      fetchCollabAndGoToView();
    } else {
      goToStage(InsightsStage.SEARCH);
    }
  }, []);

  const getElements = (): WizardScreenPanel[] => [
    {
      stage: InsightsStage.LOADING,
      content: (
        <LoadingScreen
          errors={errors}
          onBackClicked={() => window.history.back()}
        />
      ),
    },
    {
      stage: InsightsStage.SEARCH,
      content: (
        <ListInsightsScreen
          searchTerm={searchTerm}
          onSearchTermUpdated={searchForTerm}
          insights={searchResults}
          onInsightClicked={onInsightClicked}
          onDeleteInsight={onDeleteInsight}
          onNewQuestionClicked={() => goToStage(InsightsStage.CREATE_NEW)}
          errors={errors}
        />
      ),
    },
    {
      stage: InsightsStage.CREATE_NEW,
      content: (
        <NewQuestionScreen
          askAboutSelfUrl={`${singleUserInsightUrl}?self=true`}
          askAboutOtherUrl={singleUserInsightUrl}
          commCoachUrl={singleUserCommCoachUrl}
          collabUrl={collabUrl}
          onBackClicked={asBack(() => goToStage(InsightsStage.SEARCH))}
          aboutUser={profile}
        />
      ),
    },
    {
      stage: InsightsStage.VIEW_COLLAB,
      content: collab && (
        <ViewCollabScreen
          collab={collab}
          onBackClicked={asBack(() => goToStage(InsightsStage.SEARCH))}
        />
      ),
    },
    {
      stage: InsightsStage.VIEW_COMM_COACH_SINGLE,
      content: commCoach && (
        <ViewCommCoachSingleScreen
          entry={commCoach}
          onBackClicked={asBack(() => goToStage(InsightsStage.SEARCH))}
        />
      ),
    },
    {
      stage: InsightsStage.VIEW_SINGLE_USER_INSIGHT,
      content: singleInsight && (
        <ViewSingleUserInsightScreen
          insight={singleInsight}
          onBackClicked={asBack(() => goToStage(InsightsStage.SEARCH))}
        />
      ),
    },
  ];

  return (
    <BaseNavbarPage navbarProps={navbarProps}>
      <WizardScreen isBack={isBack} elements={getElements()} stage={stage} />
    </BaseNavbarPage>
  );
};

InsightsPageComponent.defaultProps = {
  userGuid: null,
  collabGuid: null,
};

export default InsightsPageComponent;
