/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  LLMInvocation,
  LLMInvocationFromJSON,
  LLMInvocationFromJSONTyped,
  LLMInvocationToJSON,
} from "./LLMInvocation";
import {
  UserGroup,
  UserGroupFromJSON,
  UserGroupFromJSONTyped,
  UserGroupToJSON,
} from "./UserGroup";

/**
 *
 * @export
 * @interface GroupQuestionAndAnswer
 */
export interface GroupQuestionAndAnswer {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof GroupQuestionAndAnswer
   */
  readonly guid?: string;
  /**
   * The contents of the question being asked.
   * @type {string}
   * @memberof GroupQuestionAndAnswer
   */
  question?: string | null;
  /**
   *
   * @type {LLMInvocation}
   * @memberof GroupQuestionAndAnswer
   */
  llm_invocation?: LLMInvocation;
  /**
   * Whether or not the referenced Celery task has been completed.
   * @type {boolean}
   * @memberof GroupQuestionAndAnswer
   */
  is_task_completed?: boolean;
  /**
   * The task ID for the Celery task that was enqueued to perform the group Q&A.
   * @type {string}
   * @memberof GroupQuestionAndAnswer
   */
  task_id?: string | null;
  /**
   *
   * @type {UserGroup}
   * @memberof GroupQuestionAndAnswer
   */
  group?: UserGroup;
  /**
   *
   * @type {Array<string>}
   * @memberof GroupQuestionAndAnswer
   */
  featured_questions?: Array<string>;
}

export function GroupQuestionAndAnswerFromJSON(
  json: any
): GroupQuestionAndAnswer {
  return GroupQuestionAndAnswerFromJSONTyped(json, false);
}

export function GroupQuestionAndAnswerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GroupQuestionAndAnswer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    question: !exists(json, "question") ? undefined : json["question"],
    llm_invocation: !exists(json, "llm_invocation")
      ? undefined
      : LLMInvocationFromJSON(json["llm_invocation"]),
    is_task_completed: !exists(json, "is_task_completed")
      ? undefined
      : json["is_task_completed"],
    task_id: !exists(json, "task_id") ? undefined : json["task_id"],
    group: !exists(json, "group")
      ? undefined
      : UserGroupFromJSON(json["group"]),
    featured_questions: !exists(json, "featured_questions")
      ? undefined
      : json["featured_questions"],
  };
}

export function GroupQuestionAndAnswerToJSON(
  value?: GroupQuestionAndAnswer | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    question: value.question,
    llm_invocation: LLMInvocationToJSON(value.llm_invocation),
    is_task_completed: value.is_task_completed,
    task_id: value.task_id,
    group: UserGroupToJSON(value.group),
    featured_questions: value.featured_questions,
  };
}
