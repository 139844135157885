import React from "react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
import { classNames } from "../../util/strings";
import { ManualTodo } from "../../api/models";
import { addNextToUrl } from "../../util/url";

type TodoListProps = {
  entries: ManualTodo[];
  className?: string | null;
};

const TodoListComponent = (props: TodoListProps) => {
  const { entries, className } = props;

  return (
    <div className={classNames("flex flex-col items-start gap-1", className)}>
      {entries.map((entry) => {
        const element = (
          <div
            key={entry.description}
            className={classNames(
              "flex flex-row items-center gap-1",
              entry.completed ? "text-primary" : "text-warning"
            )}
          >
            <div className="h-6 w-6 font-bold">
              {entry.completed ? (
                <CheckCircleIcon aria-hidden="true" />
              ) : (
                <ExclamationCircleIcon aria-hidden="true" />
              )}
            </div>
            <span className="">{entry.description}</span>
          </div>
        );

        if (entry.href && !entry.completed) {
          return (
            <a
              className={classNames(
                "hover:underline hover:brightness-110",
                entry.completed ? "decoration-primary" : "decoration-warning"
              )}
              href={addNextToUrl(entry.href)}
            >
              {element}
            </a>
          );
        }

        return element;
      })}
    </div>
  );
};

TodoListComponent.defaultProps = {
  className: null,
};

export default TodoListComponent;
