import { toast } from "react-toastify";
import ReactDiffViewer from "react-diff-viewer";
import React, { useEffect, useState } from "react";
import ChatboxContainer from "./ChatboxContainer";
import SimpleMenu from "./DropdownMenu";
import connector from "../../../api/connector";
import sleep from "../../../util/sleep";
import MButton from "../../ui/buttons/MButton";
import { errorMessagesForKeyFromResponse } from "../../../api/helpers";
import MSubtitle from "../../ui/MSubtitle";
import MTitle from "../../ui/MTitle";
import MSelect from "../../ui/MSelect";
import { GroupQuestionAndAnswerRequestModelNameEnum } from "../../../api/models";

type AskGroupQuestionPageProps = {
  group: string;
  maxWaitTimeS: number;
  checkIntervalS: number;
};

interface GroupInsightResult {
  results?: string;
}

const AskGroupQuestionPageComponent = (props: AskGroupQuestionPageProps) => {
  const { group, maxWaitTimeS, checkIntervalS } = props;

  const [files, setFiles] = useState<string[]>([]);
  const [selectedFile1, setSelectedFile1] = useState<string>("");
  const [selectedFile2, setSelectedFile2] = useState<string>("");
  const [jsonData1, setJsonData1] = useState<string>("");
  const [jsonData2, setJsonData2] = useState<string>("");
  const [groupQnaGuid, setGroupQnaGuid] = useState<string>("");
  const [modelName, setModelName] = useState<string>(
    GroupQuestionAndAnswerRequestModelNameEnum.gpt_3_5_turbo_1106
  );

  const [_, setErrors] = useState<string[]>([]);

  const loadDirectory = async () => {
    const response = await connector.retrieveDir();
    setFiles(response.c!.files);
  };

  const selectFiles = async () => {
    const response = await connector.selectFiles({
      filename1: selectedFile1,
      filename2: selectedFile2,
    });
    setJsonData1(JSON.stringify(response.c!.json_data1, null, 2));
    setJsonData2(JSON.stringify(response.c!.json_data2, null, 2));
  };

  const saveGeneration = async () => {
    try {
      await connector.saveGeneration({ group_qna_guid: groupQnaGuid });
      toast.success("Saved generation to server");
      await loadDirectory();
    } catch (e: any) {
      const parsed = await errorMessagesForKeyFromResponse(e, "question", true);
      setErrors(parsed);
      return;
    }
  };

  const deleteGeneration = async (filename: string) => {
    try {
      await connector.deleteGeneration({ filename });
      toast.success("Deleted generation from server");
      await loadDirectory();
    } catch (e: any) {
      const parsed = await errorMessagesForKeyFromResponse(e, "question", true);
      setErrors(parsed);
      return;
    }
  };

  const onModelSelected = (model: string | null) => {
    if (model) {
      setModelName(model as GroupQuestionAndAnswerRequestModelNameEnum);
    }
  };

  const optionsFromEnum = () => {
    const options: any[] = [];
    Object.values(GroupQuestionAndAnswerRequestModelNameEnum).forEach(
      (value) => {
        options.push({ name: value, key: value });
      }
    );
    return options;
  };

  const newMessageReady = async (message: string): Promise<string> => {
    const askQuestionResponse = await connector.askQuestionAboutGroup({
      data: {
        question: message,
        group_guid: group,
        model_name: modelName as GroupQuestionAndAnswerRequestModelNameEnum,
      },
    });
    const content = askQuestionResponse.c!.group_qna;
    const qnrGuid = content.guid!;
    setGroupQnaGuid(qnrGuid);
    const taskGuid = content.task_id!;
    const startTime = Date.now();
    const maxWaitTime = maxWaitTimeS * 1000;
    let success = false;
    for (;;) {
      // eslint-disable-next-line no-await-in-loop
      await sleep(checkIntervalS * 1000);
      // eslint-disable-next-line no-await-in-loop
      const taskStatusResponse = await connector.getTaskStatus(taskGuid);
      if (taskStatusResponse.c!.ready) {
        success = true;
        break;
      }
      const elapsed = Date.now() - startTime;
      if (elapsed > maxWaitTime) {
        break;
      }
    }
    if (!success) {
      throw new Error(
        `Timed out waiting for response (${maxWaitTimeS} seconds)`
      );
    }
    const response = await connector.getGroupQuestionAndAnswer({
      group_qna_guid: qnrGuid,
    });

    return (response.c!.group_qna.llm_invocation?.results as GroupInsightResult)
      .results!;
  };

  useEffect(() => {
    loadDirectory();
  }, []);

  useEffect(() => {
    if (selectedFile1 && selectedFile2) {
      selectFiles();
    }
  }, [selectedFile1, selectedFile2]);

  return (
    <div className="flex flex-col justify-center gap-5">
      <MTitle text="Group Question Admin Tool" />
      <MSubtitle text="Select 2 AI generation files to compare, upon selection, the diff will automatically load (might take a sec). Generations are not specific to this group." />
      <div className="flex flex-row justify-center gap-5">
        <SimpleMenu
          items={files}
          onSelectFile={(f: string) => setSelectedFile1(f)}
        />
        <SimpleMenu
          items={files}
          onSelectFile={(f: string) => setSelectedFile2(f)}
        />
      </div>
      <div className="w-full h-40vh flex justify-center">
        <ReactDiffViewer oldValue={jsonData1} newValue={jsonData2} splitView />
      </div>
      <MSelect
        options={optionsFromEnum()}
        onSelected={(option) => onModelSelected(option?.name || null)}
        placeholder="Select LLM"
      />
      <div className="w-full h-40vh flex flex-row justify-center">
        <ChatboxContainer newMessageReady={newMessageReady} />
      </div>
      <div className="w-full flex flex-col justify-center gap-5">
        <MSubtitle text="Please delete what you saved when you're done." />
        <MButton onClick={() => saveGeneration()}>
          Save this generation? (Only works on the latest one, will save
          immediately)
        </MButton>
        <SimpleMenu
          label="Select file to delete (Caution, file will be deleted upon selection)"
          items={files}
          onSelectFile={(f: string) => deleteGeneration(f)}
        />
      </div>
    </div>
  );
};

export default AskGroupQuestionPageComponent;
