import React, { useEffect, useState } from "react";
import { PromptRespondScreenProps } from "./PromptRespondScreen";
import {
  EitherOrPromptMeta,
  EitherOrPromptResponseMeta,
} from "../../../api/types";
import EitherOrScreen from "./EitherOrScreen";

const PromptRespondEitherOrScreenComponent = (
  props: PromptRespondScreenProps
) => {
  const { prompt, ...rest } = props;

  const [title, setTitle] = useState<string>("");
  const [choices, setChoices] = useState<[string, string][]>([]);
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    const promptMeta = prompt.prompt.meta as EitherOrPromptMeta;
    setTitle(prompt.prompt.question);
    setChoices(promptMeta.choices);
    if (prompt.response) {
      const responseMeta = prompt.response.meta as EitherOrPromptResponseMeta;
      setSelected(responseMeta.response);
    } else {
      setSelected([]);
    }
  }, [prompt.prompt.guid, prompt.response?.meta]);

  return (
    <EitherOrScreen
      title={title}
      options={choices}
      selected={selected}
      publishable={prompt.publishable}
      visible={prompt.visible ?? true}
      prompt={prompt}
      {...rest}
    />
  );
};

export default PromptRespondEitherOrScreenComponent;
