/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CreateSingleUserQuestionInsightRequest
 */
export interface CreateSingleUserQuestionInsightRequest {
  /**
   *
   * @type {string}
   * @memberof CreateSingleUserQuestionInsightRequest
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof CreateSingleUserQuestionInsightRequest
   */
  question: string;
}

export function CreateSingleUserQuestionInsightRequestFromJSON(
  json: any
): CreateSingleUserQuestionInsightRequest {
  return CreateSingleUserQuestionInsightRequestFromJSONTyped(json, false);
}

export function CreateSingleUserQuestionInsightRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateSingleUserQuestionInsightRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    username: json["username"],
    question: json["question"],
  };
}

export function CreateSingleUserQuestionInsightRequestToJSON(
  value?: CreateSingleUserQuestionInsightRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    username: value.username,
    question: value.question,
  };
}
