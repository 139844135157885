import React from "react";
import SettingsAddTextButton from "./SidebarTextAddButton";

type SidebarAddRowProps = {
  label: string;
  onClick: () => void;
};

const SidebarAddRowComponent = (props: SidebarAddRowProps) => {
  const { label, onClick } = props;

  return (
    <div className="flex border-t border-m-light-gray pt-4">
      <SettingsAddTextButton text={label} onClick={onClick} />
    </div>
  );
};

export default SidebarAddRowComponent;
