/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SetPromptResponsePresentationVisibilityRequest
 */
export interface SetPromptResponsePresentationVisibilityRequest {
  /**
   *
   * @type {string}
   * @memberof SetPromptResponsePresentationVisibilityRequest
   */
  presentation: string;
  /**
   *
   * @type {boolean}
   * @memberof SetPromptResponsePresentationVisibilityRequest
   */
  is_visible: boolean;
}

export function SetPromptResponsePresentationVisibilityRequestFromJSON(
  json: any
): SetPromptResponsePresentationVisibilityRequest {
  return SetPromptResponsePresentationVisibilityRequestFromJSONTyped(
    json,
    false
  );
}

export function SetPromptResponsePresentationVisibilityRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SetPromptResponsePresentationVisibilityRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    presentation: json["presentation"],
    is_visible: json["is_visible"],
  };
}

export function SetPromptResponsePresentationVisibilityRequestToJSON(
  value?: SetPromptResponsePresentationVisibilityRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    presentation: value.presentation,
    is_visible: value.is_visible,
  };
}
