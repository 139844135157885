import React, { useState } from "react";
import * as EmailValidator from "email-validator";
import MInput from "../ui/MInput";
import MButton from "../ui/buttons/MButton";
import { getQueryParam } from "../../util/url";
import { useWrappedConnector } from "../../api/connector";
import { errorMessagesForKeyFromResponse } from "../../api/helpers";
import {
  clickableText,
  middleFormContent,
  middleFormWrapper,
} from "../../util/style";
import { classNames } from "../../util/strings";
import { useBusyWatcher } from "../../util/hooks";
import { MNavProps } from "../ui/nav/MNav";
import BaseNavbarPage from "./BaseNavbarPage";
import MBackButton from "../ui/buttons/MBackButton";

type LoginPageProps = {
  navbarProps: MNavProps;
  privacyUrl: string;
  termsUrl: string;
};

const LoginPageComponent = (props: LoginPageProps) => {
  const { navbarProps, privacyUrl, termsUrl } = props;

  const [sent, setSent] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [errors, setErrors] = useState<string[]>([]);
  const [busy, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);

  const canSubmit = (): boolean => {
    if (email.length === 0) {
      return false;
    }
    if (!EmailValidator.validate(email)) {
      return false;
    }
    return true;
  };

  const submit = () => {
    setErrors([]);
    const redirect = getQueryParam("next");
    connector
      .startEmailAuth(email, redirect)
      .then(() => setSent(true))
      .catch((e) =>
        errorMessagesForKeyFromResponse(e, "email", true).then(setErrors)
      );
  };

  const reset = () => {
    setSent(false);
    setEmail("");
    setErrors([]);
  };

  return (
    <BaseNavbarPage navbarProps={{ ...navbarProps, showSearch: false }}>
      <div className={middleFormWrapper}>
        <div className={middleFormContent}>
          <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-m-black">
            {sent ? "Link sent!" : "Sign in to your account"}
          </h2>
        </div>

        <div
          className={classNames(
            "mt-10 space-y-6 text-m-black",
            middleFormContent
          )}
        >
          {!sent && (
            <>
              <MInput
                label="Email address"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={busy}
                onEnterPressed={() => canSubmit() && submit()}
                errors={errors}
              />
              <MButton
                kind="primary"
                className="w-full"
                disabled={busy || !canSubmit()}
                onClick={submit}
              >
                send me a link
              </MButton>
              <p className="mt-10 text-center text-sm text-m-gray">
                Don't have an account yet? No worries! Enter your email and
                we'll send you a link that automatically creates one for you.
              </p>
            </>
          )}
          {sent && (
            <>
              <div className="text-center">
                Check your email for a link to sign in to your account.
              </div>
              <div className="text-center">
                The link will only be valid for 5 minutes, so hurry!
              </div>
              <div className="flex flex-row justify-center">
                <MBackButton
                  kind="secondary"
                  onClick={reset}
                  disabled={busy}
                  text="whoops! go back"
                />
              </div>
            </>
          )}
          <div className="flex flex-row justify-center gap-6 mt-32">
            <a
              href={privacyUrl}
              className={clickableText}
              target="_blank"
              rel="noreferrer"
            >
              privacy policy
            </a>
            <a
              href={termsUrl}
              className={clickableText}
              target="_blank"
              rel="noreferrer"
            >
              terms of service
            </a>
          </div>
        </div>
      </div>
    </BaseNavbarPage>
  );
};

export default LoginPageComponent;
