/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ListPromptResponsesForGlimpseResponse,
  ListPromptResponsesForGlimpseResponseFromJSON,
  ListPromptResponsesForGlimpseResponseFromJSONTyped,
  ListPromptResponsesForGlimpseResponseToJSON,
} from "./ListPromptResponsesForGlimpseResponse";

/**
 *
 * @export
 * @interface ListPromptResponsesForGlimpseResponseSerializer
 */
export interface ListPromptResponsesForGlimpseResponseSerializer {
  /**
   *
   * @type {string}
   * @memberof ListPromptResponsesForGlimpseResponseSerializer
   */
  m?: string;
  /**
   *
   * @type {boolean}
   * @memberof ListPromptResponsesForGlimpseResponseSerializer
   */
  s: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ListPromptResponsesForGlimpseResponseSerializer
   */
  e?: { [key: string]: string } | null;
  /**
   *
   * @type {ListPromptResponsesForGlimpseResponse}
   * @memberof ListPromptResponsesForGlimpseResponseSerializer
   */
  c?: ListPromptResponsesForGlimpseResponse;
}

export function ListPromptResponsesForGlimpseResponseSerializerFromJSON(
  json: any
): ListPromptResponsesForGlimpseResponseSerializer {
  return ListPromptResponsesForGlimpseResponseSerializerFromJSONTyped(
    json,
    false
  );
}

export function ListPromptResponsesForGlimpseResponseSerializerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ListPromptResponsesForGlimpseResponseSerializer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    m: !exists(json, "m") ? undefined : json["m"],
    s: json["s"],
    e: !exists(json, "e") ? undefined : json["e"],
    c: !exists(json, "c")
      ? undefined
      : ListPromptResponsesForGlimpseResponseFromJSON(json["c"]),
  };
}

export function ListPromptResponsesForGlimpseResponseSerializerToJSON(
  value?: ListPromptResponsesForGlimpseResponseSerializer | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    m: value.m,
    s: value.s,
    e: value.e,
    c: ListPromptResponsesForGlimpseResponseToJSON(value.c),
  };
}
