import { sampleSize } from "lodash";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import GroupInsightsScreen from "./GroupInsightsScreen";
import ViewGroupInsightScreen from "./ViewGroupInsightScreen";
import GroupInsightLoadingScreen from "./GroupInsightLoadingScreen";
import { MNavProps } from "../../../ui/nav/MNav";

import { useBackTimer, useBusyWatcher } from "../../../../util/hooks";
import { useWrappedConnector } from "../../../../api/connector";
import LoadingScreen from "../../../ui/screens/LoadingScreen";
import WizardScreen, {
  WizardScreenPanel,
} from "../../../ui/screens/WizardScreen";
import BaseNavbarPage from "../../BaseNavbarPage";
import { errorMessagesForKeyFromResponse } from "../../../../api/helpers";
import waitForTask from "../../../../util/tasks";
import { addQueryParamToPath } from "../../../../util/url";
import {
  GroupQuestionAndAnswer,
  PromptWithResponse,
  UserGroup,
} from "../../../../api/models";
import waitForResponseDataAvailable from "../../../../util/waitForGroupInsightPromptResponse";

type ViewGroupPageProps = {
  groupId: string;
  listGroupsUrl: string;
  editGroupUrl: string;
  viewGroupUrl: string;
  navbarProps: MNavProps;
};

enum ViewGroupStage {
  LOADING,
  ASK_QUESTION,
  PROCESSING,
  VIEW_RESULT,
}

const ViewGroupPageComponent = (props: ViewGroupPageProps) => {
  const {
    groupId: groupIdInput,
    listGroupsUrl,
    editGroupUrl,
    viewGroupUrl,
    navbarProps,
  } = props;

  const [group, setGroup] = useState<UserGroup | null>(null);
  const [question, setQuestion] = useState<string>("");
  const [groupInsight, setGroupInsight] =
    useState<GroupQuestionAndAnswer | null>(null);
  const [groupInsights, setGroupInsights] = useState<
    GroupQuestionAndAnswer[] | null
  >(null);
  const [relevantPromptResponses, setRelevantPromptResponses] = useState<
    PromptWithResponse[] | null
  >(null);
  const [featuredQuestions, setFeaturedQuestions] = useState<string[] | null>(
    null
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [stage, setStage] = useState<ViewGroupStage>(ViewGroupStage.LOADING);
  const [isBack, asBack] = useBackTimer(stage);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);

  const loadGroup = async () => {
    const response = await connector.getGroup(groupIdInput);
    setGroup(response.c!);
    return response.c!;
  };

  const loadGroupInsights = async (groupGuid?: string) => {
    const response = await connector.listGroupQuestionsAndAnswers();
    const qnaList = response.c!;
    const filteredInsights = qnaList.group_qna.filter((qna) => {
      if ((qna.group && qna.group.guid) === (groupGuid || group?.guid)) {
        return true;
      }
      return false;
    });
    setGroupInsights(filteredInsights);
  };

  const onDeleteInsight = async (toDeleteId: string, groupId: string) => {
    await connector.deleteGroupQuestionAndAnswer({
      data: { group_qna_guid: toDeleteId },
    });
    await loadGroupInsights(groupId);
  };

  const goToStage = async (nextStage: ViewGroupStage) => {
    setErrors([]);
    // eslint-disable-next-line default-case
    switch (nextStage) {
      case ViewGroupStage.ASK_QUESTION: {
        const result = await loadGroup();
        await loadGroupInsights(result.guid);
        break;
      }
      case ViewGroupStage.VIEW_RESULT: {
        await loadGroup();
        break;
      }
    }
    setStage(nextStage);
  };

  const onViewInsight = async (insight: GroupQuestionAndAnswer) => {
    setGroupInsight(insight);
    setQuestion(insight.question!);
    await goToStage(ViewGroupStage.VIEW_RESULT);
  };

  const viewGroupQuestionAndAnswer = async (guid: string) => {
    const response = await connector.getGroupQuestionAndAnswer({
      group_qna_guid: guid,
    });
    setGroupInsight(response.c!.group_qna);
    toast.success("your result is ready!");
  };

  const askGroupQuestion = async (newQuestion: string) => {
    if (!group) {
      return;
    }

    setErrors([]);
    setQuestion(newQuestion);
    setStage(ViewGroupStage.PROCESSING);

    // Ask question and set featured questions for display
    let content: GroupQuestionAndAnswer;
    try {
      const response = await connector.askQuestionAboutGroup({
        data: {
          question: newQuestion,
          group_guid: group.guid,
        },
      });
      content = response.c!.group_qna;
      setFeaturedQuestions(
        sampleSize(response.c!.group_qna.featured_questions!, 5)
      );
    } catch (e: any) {
      const parsed = await errorMessagesForKeyFromResponse(e, "question", true);
      setErrors(parsed);
      return;
    }

    // Check result
    if (content.is_task_completed) {
      await viewGroupQuestionAndAnswer(content.guid!);
      return;
    }

    // Poll API for relevant prompt responses and set when available for display
    const promptResponses = await waitForResponseDataAvailable(
      content.guid!,
      connector
    );
    if (promptResponses && promptResponses.length > 0) {
      setRelevantPromptResponses(sampleSize(promptResponses, 5));
    }

    // Poll task execution to view final answer when ready
    const success = await waitForTask(content.task_id!, connector);
    if (!success) {
      setErrors([
        "something broke while processing your question :( try again in a little bit",
      ]);
      return;
    }
    await viewGroupQuestionAndAnswer(content.guid!);
  };

  const rateResponse = async (
    guid: string,
    rating: number,
    callType: string
  ) => {
    if (!guid || !callType) {
      return;
    }
    await connector.rateResponse({
      guid,
      rating,
      call_type: callType,
    });
    toast.success("rating submitted!");
  };

  useEffect(() => {
    goToStage(ViewGroupStage.ASK_QUESTION);
  }, []);

  const getElements = (): WizardScreenPanel[] => [
    {
      stage: ViewGroupStage.LOADING,
      content: <LoadingScreen />,
    },
    {
      stage: ViewGroupStage.ASK_QUESTION,
      content: group && groupInsights && (
        <GroupInsightsScreen
          group={group}
          question={question}
          groupInsights={groupInsights}
          errors={errors}
          viewGroupUrl={viewGroupUrl}
          editGroupUrl={editGroupUrl}
          onInsightClicked={onViewInsight}
          onDeleteInsight={onDeleteInsight}
          onQuestionSelected={askGroupQuestion}
          onBackClicked={asBack(() => {
            window.location.href = addQueryParamToPath(
              listGroupsUrl,
              "next",
              "my-groups"
            );
          })}
        />
      ),
    },
    {
      stage: ViewGroupStage.PROCESSING,
      content: (
        <GroupInsightLoadingScreen
          message="processing your question..."
          featuredQuestions={featuredQuestions!}
          relevantPromptResponses={relevantPromptResponses!}
          errors={errors}
          showNextButton={groupInsight !== null}
          onNextClicked={() => goToStage(ViewGroupStage.VIEW_RESULT)}
          onBackClicked={asBack(() => goToStage(ViewGroupStage.ASK_QUESTION))}
        />
      ),
    },
    {
      stage: ViewGroupStage.VIEW_RESULT,
      content: groupInsight && group && (
        <ViewGroupInsightScreen
          group={group}
          groupInsight={groupInsight}
          question={question}
          editGroupUrl={editGroupUrl}
          viewGroupUrl={viewGroupUrl}
          rateResponse={rateResponse}
          onDoneClicked={() => {
            window.location.href = addQueryParamToPath(
              viewGroupUrl,
              "id",
              group!.guid!
            );
          }}
        />
      ),
    },
  ];

  return (
    <BaseNavbarPage navbarProps={navbarProps}>
      <WizardScreen elements={getElements()} stage={stage} isBack={isBack} />
    </BaseNavbarPage>
  );
};

export default ViewGroupPageComponent;
