/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface VoteOnQuestionRequest
 */
export interface VoteOnQuestionRequest {
  /**
   *
   * @type {string}
   * @memberof VoteOnQuestionRequest
   */
  prompt: string;
  /**
   *
   * @type {boolean}
   * @memberof VoteOnQuestionRequest
   */
  is_upvote: boolean;
}

export function VoteOnQuestionRequestFromJSON(
  json: any
): VoteOnQuestionRequest {
  return VoteOnQuestionRequestFromJSONTyped(json, false);
}

export function VoteOnQuestionRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VoteOnQuestionRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    prompt: json["prompt"],
    is_upvote: json["is_upvote"],
  };
}

export function VoteOnQuestionRequestToJSON(
  value?: VoteOnQuestionRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    prompt: value.prompt,
    is_upvote: value.is_upvote,
  };
}
