import React, { useEffect, useState } from "react";
import { CommonPromptTypeScreenProps } from "./PromptRespondScreen";
import { useBusyWatcher } from "../../../util/hooks";
import CommonQuestionScreen from "./CommonQuestionScreen";
import ChoicesQuestion from "./questions/ChoicesQuestion";

type ChoicesScreenProps = CommonPromptTypeScreenProps & {
  options: string[];
  onNextClicked: (selected: number, visible: boolean | null) => void;
  selected?: number;
};

const ChoicesScreenComponent = (props: ChoicesScreenProps) => {
  const {
    title,
    publishable,
    visible: visibleInput,
    options,
    onNextClicked,
    selected: selectedInput,
    ...rest
  } = props;

  const [selected, setSelected] = useState<number>(-1);
  const [visible, setVisible] = useState<boolean>(visibleInput ?? true);
  const [busy, _] = useBusyWatcher();

  const canSubmit = (): boolean => selected !== -1;

  const submit = () => {
    onNextClicked(selected, visible);
  };

  const updatePromptResponseVisibility = () => {
    if (rest.prompt.response) {
      onNextClicked(selected, !visible);
    }
    setVisible(!visible); // This needs to be set after the call because setState is async
  };

  const updateSelected = (i: number): void => {
    if (selected === i) {
      setSelected(-1);
    } else {
      setSelected(i);
    }
  };

  useEffect(() => {
    if (selectedInput !== undefined) {
      setSelected(selectedInput);
    }
  }, [selectedInput]);

  return (
    <CommonQuestionScreen {...rest} canSubmit={canSubmit()} submit={submit}>
      <ChoicesQuestion
        comment="pick 1 below"
        question={title}
        options={options}
        showVisibilityToggle
        onSelectedUpdated={updateSelected}
        selected={selected}
        onVisibilityUpdated={updatePromptResponseVisibility}
        publishable={publishable}
        disabled={busy}
        visible={visible}
      />
    </CommonQuestionScreen>
  );
};

ChoicesScreenComponent.defaultProps = {
  selected: undefined,
};

export default ChoicesScreenComponent;
