/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  UserProfileSummary,
  UserProfileSummaryFromJSON,
  UserProfileSummaryFromJSONTyped,
  UserProfileSummaryToJSON,
} from "./UserProfileSummary";

/**
 *
 * @export
 * @interface SearchForConnectionResponse
 */
export interface SearchForConnectionResponse {
  /**
   *
   * @type {Array<UserProfileSummary>}
   * @memberof SearchForConnectionResponse
   */
  results: Array<UserProfileSummary>;
  /**
   *
   * @type {number}
   * @memberof SearchForConnectionResponse
   */
  count: number;
}

export function SearchForConnectionResponseFromJSON(
  json: any
): SearchForConnectionResponse {
  return SearchForConnectionResponseFromJSONTyped(json, false);
}

export function SearchForConnectionResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SearchForConnectionResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    results: (json["results"] as Array<any>).map(UserProfileSummaryFromJSON),
    count: json["count"],
  };
}

export function SearchForConnectionResponseToJSON(
  value?: SearchForConnectionResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    results: (value.results as Array<any>).map(UserProfileSummaryToJSON),
    count: value.count,
  };
}
