/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  SingleUserQuestionInsightRelatedDetail,
  SingleUserQuestionInsightRelatedDetailFromJSON,
  SingleUserQuestionInsightRelatedDetailFromJSONTyped,
  SingleUserQuestionInsightRelatedDetailToJSON,
} from "./SingleUserQuestionInsightRelatedDetail";

/**
 *
 * @export
 * @interface CommonSingleUserQuestionInsightResponse
 */
export interface CommonSingleUserQuestionInsightResponse {
  /**
   *
   * @type {SingleUserQuestionInsightRelatedDetail}
   * @memberof CommonSingleUserQuestionInsightResponse
   */
  insight: SingleUserQuestionInsightRelatedDetail;
  /**
   *
   * @type {string}
   * @memberof CommonSingleUserQuestionInsightResponse
   */
  content: string | null;
}

export function CommonSingleUserQuestionInsightResponseFromJSON(
  json: any
): CommonSingleUserQuestionInsightResponse {
  return CommonSingleUserQuestionInsightResponseFromJSONTyped(json, false);
}

export function CommonSingleUserQuestionInsightResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommonSingleUserQuestionInsightResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    insight: SingleUserQuestionInsightRelatedDetailFromJSON(json["insight"]),
    content: json["content"],
  };
}

export function CommonSingleUserQuestionInsightResponseToJSON(
  value?: CommonSingleUserQuestionInsightResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    insight: SingleUserQuestionInsightRelatedDetailToJSON(value.insight),
    content: value.content,
  };
}
