/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  EmptyResponseWrapper,
  EmptyResponseWrapperFromJSON,
  EmptyResponseWrapperToJSON,
  GetCollabByIdResponseSerializer,
  GetCollabByIdResponseSerializerFromJSON,
  GetCollabByIdResponseSerializerToJSON,
  GetLastCollabForUserResponseSerializer,
  GetLastCollabForUserResponseSerializerFromJSON,
  GetLastCollabForUserResponseSerializerToJSON,
  UserCollabRelatedDetailSerializer,
  UserCollabRelatedDetailSerializerFromJSON,
  UserCollabRelatedDetailSerializerToJSON,
  UsernameOrId,
  UsernameOrIdFromJSON,
  UsernameOrIdToJSON,
} from "../models";

export interface CollabsLastForUserRequest {
  username?: string | null;
  user_id?: string | null;
}

export interface CreateCollabRequest {
  data: UsernameOrId;
}

export interface GetCollabByIdRequest {
  collab_id: string;
}

/**
 *
 */
export class CollabsApi extends runtime.BaseAPI {
  /**
   * Get the most recent collab for the specified user, if it exists.
   */
  async collabsLastForUserRaw(
    requestParameters: CollabsLastForUserRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetLastCollabForUserResponseSerializer>> {
    const queryParameters: any = {};

    if (requestParameters.username !== undefined) {
      queryParameters["username"] = requestParameters.username;
    }

    if (requestParameters.user_id !== undefined) {
      queryParameters["user_id"] = requestParameters.user_id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/collabs/last-for-user`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetLastCollabForUserResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the most recent collab for the specified user, if it exists.
   */
  async collabsLastForUser(
    requestParameters: CollabsLastForUserRequest = {},
    initOverrides?: RequestInit
  ): Promise<GetLastCollabForUserResponseSerializer> {
    const response = await this.collabsLastForUserRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create a new collab for the specified user and return it.
   */
  async createCollabRaw(
    requestParameters: CreateCollabRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<UserCollabRelatedDetailSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling createCollab."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/collabs/create`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UsernameOrIdToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserCollabRelatedDetailSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Create a new collab for the specified user and return it.
   */
  async createCollab(
    requestParameters: CreateCollabRequest,
    initOverrides?: RequestInit
  ): Promise<UserCollabRelatedDetailSerializer> {
    const response = await this.createCollabRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get the collab with the specified ID, if it exists.
   */
  async getCollabByIdRaw(
    requestParameters: GetCollabByIdRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetCollabByIdResponseSerializer>> {
    if (
      requestParameters.collab_id === null ||
      requestParameters.collab_id === undefined
    ) {
      throw new runtime.RequiredError(
        "collab_id",
        "Required parameter requestParameters.collab_id was null or undefined when calling getCollabById."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.collab_id !== undefined) {
      queryParameters["collab_id"] = requestParameters.collab_id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/collabs/by-id`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetCollabByIdResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the collab with the specified ID, if it exists.
   */
  async getCollabById(
    requestParameters: GetCollabByIdRequest,
    initOverrides?: RequestInit
  ): Promise<GetCollabByIdResponseSerializer> {
    const response = await this.getCollabByIdRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
