import React, { useEffect, useState } from "react";
import CommonQuestion, { CommonQuestionProps } from "./CommonQuestion";
import MTextArea from "../../MTextArea";

type FillInBlankListQuestionProps = CommonQuestionProps & {
  numAnswers: number;
  content?: string[] | null;
  placeholders?: string[] | null;
  maxContentLength?: number;
  disabled?: boolean;
  onUpdated: (content: string[]) => void;
};

const FillInBlankListQuestionComponent = (
  props: FillInBlankListQuestionProps
) => {
  const {
    content: contentInput,
    placeholders,
    maxContentLength,
    onUpdated,
    disabled,
    numAnswers,
    ...rest
  } = props;

  const [content, setContent] = useState<string[]>([]);

  const getPlaceholderAtIndex = (index: number): string => {
    if (placeholders && placeholders.length > index) {
      return placeholders[index];
    }
    return "";
  };

  const updateContent = (newContent: string, index: number) => {
    const newContentCopy = [...content];
    newContentCopy[index] = newContent;
    setContent(newContentCopy);
    onUpdated(newContentCopy);
  };

  useEffect(() => {
    if (contentInput && contentInput.length > 0) {
      setContent(contentInput);
    } else {
      const newContent: string[] = [];
      for (let i = 0; i < numAnswers; i += 1) {
        newContent.push("");
      }
      setContent(newContent);
    }
  }, [contentInput]);

  return (
    <CommonQuestion {...rest}>
      <div className="flex flex-col gap-3">
        {content.map((c, i) => (
          <MTextArea
            key={`${i.toString()}-input`}
            label={`response ${i + 1}`}
            disabled={disabled}
            onChange={(e) => updateContent(e.target.value, i)}
            placeholder={getPlaceholderAtIndex(i)}
            value={c}
            resize={content.length <= 2} // Resizing below the fold causes the screen to jump
            characterLimit={maxContentLength}
          />
        ))}
      </div>
    </CommonQuestion>
  );
};

FillInBlankListQuestionComponent.defaultProps = {
  content: null,
  placeholders: null,
  maxContentLength: undefined,
  disabled: false,
};

export default FillInBlankListQuestionComponent;
