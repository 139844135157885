/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SetGlimpseVisibilityRequest
 */
export interface SetGlimpseVisibilityRequest {
  /**
   *
   * @type {string}
   * @memberof SetGlimpseVisibilityRequest
   */
  glimpse: string;
  /**
   *
   * @type {boolean}
   * @memberof SetGlimpseVisibilityRequest
   */
  visible: boolean;
}

export function SetGlimpseVisibilityRequestFromJSON(
  json: any
): SetGlimpseVisibilityRequest {
  return SetGlimpseVisibilityRequestFromJSONTyped(json, false);
}

export function SetGlimpseVisibilityRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SetGlimpseVisibilityRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    glimpse: json["glimpse"],
    visible: json["visible"],
  };
}

export function SetGlimpseVisibilityRequestToJSON(
  value?: SetGlimpseVisibilityRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    glimpse: value.glimpse,
    visible: value.visible,
  };
}
