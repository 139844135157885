import React from "react";
import { SparklesIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../../util/strings";
import { clickableText } from "../../../util/style";

type MWellSparklesButtonProps = {
  href: string;
  label: string;
  otherWindow?: boolean;
};

const MWellSparklesButton = (props: MWellSparklesButtonProps) => {
  const { href, label, otherWindow } = props;

  return (
    <a
      //  className="w-6 h-6 bg-primary rounded-full"
      href={href}
      target={otherWindow ? "_blank" : "_self"}
      rel="noreferrer"
      aria-label={label}
    >
      <div className="w-10 h-10 bg-primary rounded-full flex justify-center items-center hover:bg-primary-light">
        <SparklesIcon
          className={classNames(
            "w-6 h-6 text-secondary hover:brightness-110",
            clickableText
          )}
        />
      </div>
    </a>
  );
};

MWellSparklesButton.defaultProps = {
  otherWindow: false,
};

export default MWellSparklesButton;
