import React, { ReactNode } from "react";
import Skeleton from "react-loading-skeleton";
import CenterColumnScreen from "./CenterColumnScreen";

type ProfileLayoutScreenProps = {
  loading: boolean;
  leftColumn: ReactNode;
  rightTopColumn: ReactNode;
  bottomLeftColumn: ReactNode;
  bottomRightColumn: ReactNode;
  mobileColumn: ReactNode;
};

const ProfileLayoutScreenComponent = (props: ProfileLayoutScreenProps) => {
  const {
    loading,
    leftColumn,
    rightTopColumn,
    bottomLeftColumn,
    bottomRightColumn,
    mobileColumn,
  } = props;

  return (
    <>
      <div className="hidden lg:block pt-3">
        {!loading ? (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
            <div className="col-span-1 flex flex-col gap-3">{leftColumn}</div>
            <div className="col-span-2 flex flex-col gap-3">
              {rightTopColumn}
              <div className="grid grid-cols-2 gap-3">
                {bottomLeftColumn}
                {bottomRightColumn}
              </div>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
            <div className="col-span-1 flex flex-col gap-3">
              <Skeleton className="h-32" />
              <Skeleton className="h-16" />
              <Skeleton className="h-16" />
            </div>
            <div className="col-span-2 flex flex-col gap-3">
              <Skeleton className="h-32" />
              <Skeleton className="h-16" />
              <Skeleton className="h-16" />
              <div className="grid grid-cols-2 gap-3">
                <Skeleton className="h-32" />
                <Skeleton className="h-32" />
                <Skeleton className="h-32" />
                <Skeleton className="h-32" />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="lg:hidden">
        <CenterColumnScreen fullWidth>
          <div className="flex flex-col gap-3">
            {!loading ? (
              mobileColumn
            ) : (
              <>
                <Skeleton className="h-32" />
                <Skeleton className="h-16" />
                <Skeleton className="h-16" />
                <Skeleton className="h-16" />
                <Skeleton className="h-16" />
                <Skeleton className="h-16" />
              </>
            )}
          </div>
        </CenterColumnScreen>
      </div>
    </>
  );
};

export default ProfileLayoutScreenComponent;
