import React, { PropsWithChildren } from "react";
import MWellEditButton from "../../ui/wells/MWellEditButton";
import { useBusyWatcher } from "../../../util/hooks";

type CommCoachTopicRowProps = {
  title: string;
  onEditClicked?: (() => void) | null;
};

const CommCoachTopicRowComponent = (
  props: PropsWithChildren<CommCoachTopicRowProps>
) => {
  const { title, onEditClicked, children } = props;

  const [busy, _] = useBusyWatcher();

  return (
    <div>
      <div className="flex flex-row items-center gap-2 mb-1 text-primary">
        <div className="font-bold text-xs">{title}</div>
        {onEditClicked && (
          <MWellEditButton
            onClick={onEditClicked}
            disabled={busy}
            kind="small"
          />
        )}
      </div>
      <div className="text-xs text-m-dark-gray line-clamp-2">{children}</div>
    </div>
  );
};

CommCoachTopicRowComponent.defaultProps = {
  onEditClicked: null,
};

export default CommCoachTopicRowComponent;
