import React, { ReactElement } from "react";
import { ListQuestionnairesResponse } from "../../../api/models";
import MBackButton from "../../ui/buttons/MBackButton";
import { useBusyWatcher } from "../../../util/hooks";
import MTitle from "../../ui/MTitle";
import { retainNextWithUrl } from "../../../util/url";
import { classNames } from "../../../util/strings";
import { commentText } from "../../../util/style";
import CenterColumnStickyButtonScreen from "../../ui/screens/CenterColumnStickyButtonScreen";
import QuestionnaireCard from "./QuestionnaireCard";

type ListQuestionnairesScreenProps = {
  questionnaires: ListQuestionnairesResponse;
  questionnaireUrl: string;
  onBackClicked: () => void;
};

const ListQuestionnairesScreenComponent = (
  props: ListQuestionnairesScreenProps
) => {
  const { questionnaires, questionnaireUrl, onBackClicked } = props;

  const [busy, _] = useBusyWatcher();

  const getButtons = (): ReactElement[] => [
    <MBackButton
      kind="secondary"
      onClick={onBackClicked}
      disabled={busy}
      key="back-button"
    />,
  ];

  return (
    <CenterColumnStickyButtonScreen buttons={getButtons()} stacked fullWidth>
      <MTitle text="quizzes" className="text-center mb-5" />
      <div className="flex flex-col gap-3">
        {questionnaires.questionnaires.map((questionnaire) => (
          <QuestionnaireCard
            key={questionnaire.questionnaire.guid!}
            questionnaire={questionnaire}
            editUrl={retainNextWithUrl(
              `${questionnaireUrl}?questionnaire=${questionnaire.questionnaire.guid}`
            )}
          />
        ))}
      </div>
      {questionnaires.questionnaires.length === 0 && (
        <div className={classNames(commentText, "text-center")}>
          no questionnaires available as this time. please check back later!
        </div>
      )}
    </CenterColumnStickyButtonScreen>
  );
};

export default ListQuestionnairesScreenComponent;
