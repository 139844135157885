import React from "react";
import Skeleton from "react-loading-skeleton";
import MInput, { MInputProps } from "./MInput";

type MLoadingInputProps = MInputProps & {
  loading: boolean;
};

const MLoadingInputComponent = (props: MLoadingInputProps) => {
  const { loading, ...rest } = props;

  return loading ? <Skeleton className="h-8 w-full" /> : <MInput {...rest} />;
};

export default MLoadingInputComponent;
