/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UpdateUserSettingsRequest
 */
export interface UpdateUserSettingsRequest {
  /**
   *
   * @type {boolean}
   * @memberof UpdateUserSettingsRequest
   */
  enroll_in_marketing_emails?: boolean | null;
}

export function UpdateUserSettingsRequestFromJSON(
  json: any
): UpdateUserSettingsRequest {
  return UpdateUserSettingsRequestFromJSONTyped(json, false);
}

export function UpdateUserSettingsRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateUserSettingsRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    enroll_in_marketing_emails: !exists(json, "enroll_in_marketing_emails")
      ? undefined
      : json["enroll_in_marketing_emails"],
  };
}

export function UpdateUserSettingsRequestToJSON(
  value?: UpdateUserSettingsRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    enroll_in_marketing_emails: value.enroll_in_marketing_emails,
  };
}
