/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface DeleteRoleRequest
 */
export interface DeleteRoleRequest {
  /**
   *
   * @type {string}
   * @memberof DeleteRoleRequest
   */
  role: string;
}

export function DeleteRoleRequestFromJSON(json: any): DeleteRoleRequest {
  return DeleteRoleRequestFromJSONTyped(json, false);
}

export function DeleteRoleRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeleteRoleRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    role: json["role"],
  };
}

export function DeleteRoleRequestToJSON(value?: DeleteRoleRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    role: value.role,
  };
}
