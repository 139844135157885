import React from "react";
import { UserProfileSummary } from "../../api/models";
import { classNames } from "../../util/strings";
import { clickableText } from "../../util/style";
import MAvatar from "./MAvatar";

type EmoteProps = {
  action: string;
  user?: UserProfileSummary | null;
};

const EmoteComponent = (props: EmoteProps) => {
  const { action, user } = props;
  if (!user) return <div>Anonymous {action}</div>;

  return (
    <div>
      <a href={user.profile_url}>
        <MAvatar
          picUrl={user.photo}
          alt={user.name || user.username || "user picture"}
          size="mini"
        />
      </a>
      <a
        href={user.profile_url}
        className={classNames("mx-2 font-bold", clickableText)}
      >
        {user.name}
      </a>
      {action}
    </div>
  );
};

EmoteComponent.defaultProps = {
  user: null,
};

EmoteComponent.displayName = "Emote";
export default EmoteComponent;
