import React, { useEffect, useState } from "react";
import { PromptRespondScreenProps } from "./PromptRespondScreen";
import {
  ChoicesPromptMeta,
  ChoicesPromptResponseMeta,
} from "../../../api/types";
import ChoicesScreen from "./ChoicesScreen";

const PromptRespondChoicesScreenComponent = (
  props: PromptRespondScreenProps
) => {
  const { prompt, onNextClicked: inputOnNextClicked, ...rest } = props;

  const [title, setTitle] = useState<string>("");
  const [options, setOptions] = useState<string[]>([]);
  const [selected, setSelected] = useState<number>(-1);

  const onNextClicked = (newSelected: number, visible: boolean | null) => {
    setSelected(newSelected);
    inputOnNextClicked(options[newSelected], visible);
  };

  useEffect(() => {
    const promptMeta = prompt.prompt.meta as ChoicesPromptMeta;
    setTitle(prompt.prompt.question);
    setOptions(promptMeta.choices);
    if (prompt.response) {
      const responseMeta = prompt.response.meta as ChoicesPromptResponseMeta;
      for (let i = 0; i < promptMeta.choices.length; i += 1) {
        if (responseMeta.response === promptMeta.choices[i]) {
          setSelected(i);
          break;
        }
      }
    } else {
      setSelected(-1);
    }
  }, [prompt.prompt.guid, prompt.response?.meta]);

  return (
    <ChoicesScreen
      title={title}
      options={options}
      onNextClicked={onNextClicked}
      selected={selected}
      publishable={prompt.publishable}
      visible={prompt.visible ?? true}
      prompt={prompt}
      {...rest}
    />
  );
};

export default PromptRespondChoicesScreenComponent;
