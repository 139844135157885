/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface DeleteQuestionRequest
 */
export interface DeleteQuestionRequest {
  /**
   *
   * @type {string}
   * @memberof DeleteQuestionRequest
   */
  prompt: string;
}

export function DeleteQuestionRequestFromJSON(
  json: any
): DeleteQuestionRequest {
  return DeleteQuestionRequestFromJSONTyped(json, false);
}

export function DeleteQuestionRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeleteQuestionRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    prompt: json["prompt"],
  };
}

export function DeleteQuestionRequestToJSON(
  value?: DeleteQuestionRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    prompt: value.prompt,
  };
}
