import React from "react";
import MessageScreen from "../../../ui/screens/MessageScreen";
import { useBusyWatcher } from "../../../../util/hooks";

type GlimpseCompletedScreenProps = {
  onNextClicked: () => void;
};

const GlimpseCompletedScreenComponent = (
  props: GlimpseCompletedScreenProps
) => {
  const { onNextClicked } = props;

  const [busy, _] = useBusyWatcher();

  return (
    <MessageScreen
      title="your glimpse is ready!"
      content="you'll have the option to make it publicly visible on the next page (you're the only one that can see it currently)."
      nextButtonText="view glimpse"
      onNextClicked={onNextClicked}
      nextDisabled={busy}
    />
  );
};

export default GlimpseCompletedScreenComponent;
