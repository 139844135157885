/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SubmitSingleEntryRequest
 */
export interface SubmitSingleEntryRequest {
  /**
   *
   * @type {string}
   * @memberof SubmitSingleEntryRequest
   */
  entry_id: string;
}

export function SubmitSingleEntryRequestFromJSON(
  json: any
): SubmitSingleEntryRequest {
  return SubmitSingleEntryRequestFromJSONTyped(json, false);
}

export function SubmitSingleEntryRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SubmitSingleEntryRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    entry_id: json["entry_id"],
  };
}

export function SubmitSingleEntryRequestToJSON(
  value?: SubmitSingleEntryRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    entry_id: value.entry_id,
  };
}
