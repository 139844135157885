import React, { useEffect, useState } from "react";
import {
  ArrowDownTrayIcon,
  CreditCardIcon,
  EyeSlashIcon,
  UserCircleIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import { usePostHog } from "posthog-js/react";
import { MNavProps } from "../../ui/nav/MNav";
import BaseNavbarPage from "../BaseNavbarPage";
import {
  GetBasicInfo,
  GetEventTypeEventEnum,
  GetProfilePublicResponse,
  GetSubscriptionResponse,
  ListProfileAccessResponse,
  ListUserInvitesResponse,
  SetBasicInfo,
  SetProfileAccessRequest,
  UserProfileAccessRelatedDetail,
} from "../../../api/models";
import { useBusyWatcher } from "../../../util/hooks";
import { useWrappedConnector } from "../../../api/connector";
import SettingsBasicsScreen from "./SettingsBasicsScreen";
import SettingsExportsScreen from "./SettingsExportsScreen";
import { errorMessagesFromResponse } from "../../../api/helpers";
import { useAsyncConfirmationDialog } from "../../dialogs/hooks";
import SettingsPrivacyScreen from "./SettingsPrivacyScreen";
import SettingsSubscriptionScreen from "./SettingsSubscriptionScreen";
import SettingsInvitesScreen from "./SettingsInvitesScreen";
import SidebarNavScreen, {
  NavigationItem,
} from "../../ui/screens/sidebarnav/SidebarNavScreen";

const secondaryNavigation: NavigationItem[] = [
  { name: "General", icon: UserCircleIcon },
  { name: "Invites", icon: UserPlusIcon },
  { name: "Privacy", icon: EyeSlashIcon },
  { name: "Billing", icon: CreditCardIcon },
  { name: "Export", icon: ArrowDownTrayIcon },
];

type SettingsPageProps = {
  navbarProps: MNavProps;
  manageSubscriptionUrl: string;
};

const SettingsPageComponent = (props: SettingsPageProps) => {
  const { navbarProps, manageSubscriptionUrl } = props;

  const [loading, setLoading] = useState<boolean>(true);
  const [curPane, setCurPane] = useState<string>("General");
  const [basics, setBasics] = useState<GetBasicInfo | null>(null);
  const [publicStatus, setPublicStatus] =
    useState<GetProfilePublicResponse | null>(null);
  const [profileAccess, setProfileAccess] =
    useState<ListProfileAccessResponse | null>(null);
  const [subscription, setSubscription] =
    useState<GetSubscriptionResponse | null>(null);
  const [invites, setInvites] = useState<ListUserInvitesResponse | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [errorsList, setErrorsList] = useState<string[]>([]);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);
  const confirmDialog = useAsyncConfirmationDialog();
  const posthog = usePostHog();

  const loadGeneralPage = async () => {
    const response = await connector.getProfileBasics();
    setBasics(response.c!);
  };

  const loadProfilePublicStatus = async () => {
    const response = await connector.getProfilePublicStatus();
    setPublicStatus(response.c!);
  };

  const loadProfileAccess = async () => {
    const response = await connector.listProfileAccess();
    setProfileAccess(response.c!);
  };

  const loadPrivacyPage = async () => {
    await Promise.all([loadProfilePublicStatus(), loadProfileAccess()]);
  };

  const loadSubscriptionPage = async () => {
    const response = await connector.getSubscription();
    setSubscription(response.c!);
  };

  const loadInvitesPage = async () => {
    const response = await connector.listUserInvites();
    setInvites(response.c!);
  };

  const updateBasics = async (newBasics: SetBasicInfo) => {
    setErrors({});
    try {
      await connector.setProfileBasics(newBasics);
    } catch (e: any) {
      const newErrors = await errorMessagesFromResponse(e as Response);
      setErrors(newErrors);
      return;
    }
    const response = await connector.getProfileBasics();
    setBasics(response.c!);
    toast.success("basic information successfully updated");
  };

  const goToPane = async (nextPane: string) => {
    setLoading(true);
    setCurPane(nextPane);
    setErrors({});
    setErrorsList([]);
    // eslint-disable-next-line default-case
    switch (nextPane) {
      case "General":
        await loadGeneralPage();
        break;
      case "Privacy":
        await loadPrivacyPage();
        break;
      case "Billing":
        await loadSubscriptionPage();
        break;
      case "Invites":
        posthog.capture(GetEventTypeEventEnum.invites_screen_visited);
        await loadInvitesPage();
        break;
    }
    setLoading(false);
  };

  const onResetAccountClicked = async () => {
    const result = await confirmDialog.confirm({
      title: "reset account?",
      body: "this will delete all your profile data and return you to onboarding. this cannot be undone.",
      kind: "danger",
    });
    if (!result) {
      return;
    }
    await connector.resetAccount();
    window.location.href = "/";
  };

  const onPublicProfileToggled = async (newPublicStatus: boolean) => {
    await connector.setProfilePublicStatus(newPublicStatus);
    await loadProfilePublicStatus();
  };

  const onNewAccessRuleReady = async (newRule: SetProfileAccessRequest) => {
    await connector.setProfileAccess(newRule);
    await loadProfileAccess();
    return true;
  };

  const onDeleteAccessRuleClicked = async (
    toDelete: UserProfileAccessRelatedDetail
  ) => {
    let result = false;
    if (toDelete.access_type === "email") {
      result = await confirmDialog.confirm({
        title: "delete email's profile access?",
        body: `are you sure you want to delete the access granted to the email address "${toDelete.email}"? you can always add it back to your private profile access list in the future.`,
      });
    }
    if (toDelete.access_type === "user") {
      result = await confirmDialog.confirm({
        title: "delete user's profile access?",
        body: `are you sure you want to delete the access granted to "@${
          toDelete.other_user!.username
        }"? you can always add them back to your private profile access list in the future.`,
      });
    }
    if (!result) {
      return;
    }
    await connector.deleteProfileAccess(toDelete.guid!);
    await loadProfileAccess();
  };

  const onInviteEmail = async (email: string): Promise<boolean> => {
    await connector.inviteEmail(email);
    await loadInvitesPage();
    return true;
  };

  const onResendInvite = async (inviteId: string) => {
    await connector.resendInvite(inviteId);
    toast.success("invite resent successfully!");
    await loadInvitesPage();
  };

  useEffect(() => {
    goToPane("General");
  }, []);

  return (
    <BaseNavbarPage navbarProps={navbarProps}>
      {confirmDialog.dialog}
      <SidebarNavScreen
        items={secondaryNavigation.map((item) => ({
          ...item,
          current: item.name === curPane,
        }))}
        onNavItemClicked={(item) => goToPane(item.name)}
      >
        {curPane === "General" && (
          <SettingsBasicsScreen
            loading={loading}
            basics={basics}
            onBasicsUpdated={updateBasics}
            errors={errors}
            onResetProfileClicked={onResetAccountClicked}
          />
        )}
        {curPane === "Privacy" && (
          <SettingsPrivacyScreen
            loading={loading}
            publicProfileResponse={publicStatus}
            profileAccessList={profileAccess}
            onPublicProfileToggled={onPublicProfileToggled}
            onNewAccessRuleReady={onNewAccessRuleReady}
            onDeleteAccessRuleClicked={onDeleteAccessRuleClicked}
          />
        )}
        {curPane === "Billing" && (
          <SettingsSubscriptionScreen
            loading={loading}
            subscription={subscription}
            manageSubscriptionUrl={manageSubscriptionUrl}
          />
        )}
        {curPane === "Export" && <SettingsExportsScreen />}
        {curPane === "Invites" && (
          <SettingsInvitesScreen
            loading={loading}
            errors={errorsList}
            invites={invites}
            onInviteEmail={onInviteEmail}
            onResendInvite={onResendInvite}
          />
        )}
      </SidebarNavScreen>
    </BaseNavbarPage>
  );
};

export default SettingsPageComponent;
