/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface DeleteGroupQuestionAndAnswerRequest
 */
export interface DeleteGroupQuestionAndAnswerRequest {
  /**
   *
   * @type {string}
   * @memberof DeleteGroupQuestionAndAnswerRequest
   */
  group_qna_guid: string;
}

export function DeleteGroupQuestionAndAnswerRequestFromJSON(
  json: any
): DeleteGroupQuestionAndAnswerRequest {
  return DeleteGroupQuestionAndAnswerRequestFromJSONTyped(json, false);
}

export function DeleteGroupQuestionAndAnswerRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeleteGroupQuestionAndAnswerRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    group_qna_guid: json["group_qna_guid"],
  };
}

export function DeleteGroupQuestionAndAnswerRequestToJSON(
  value?: DeleteGroupQuestionAndAnswerRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    group_qna_guid: value.group_qna_guid,
  };
}
