/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface DeleteInsightRequest
 */
export interface DeleteInsightRequest {
  /**
   *
   * @type {string}
   * @memberof DeleteInsightRequest
   */
  insight_guid?: string | null;
  /**
   *
   * @type {string}
   * @memberof DeleteInsightRequest
   */
  insight_type: DeleteInsightRequestInsightTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum DeleteInsightRequestInsightTypeEnum {
  user_collab = "user_collab",
  single_user_insight = "single_user_insight",
  single_comm_coach = "single_comm_coach",
}

export function DeleteInsightRequestFromJSON(json: any): DeleteInsightRequest {
  return DeleteInsightRequestFromJSONTyped(json, false);
}

export function DeleteInsightRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeleteInsightRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    insight_guid: !exists(json, "insight_guid")
      ? undefined
      : json["insight_guid"],
    insight_type: json["insight_type"],
  };
}

export function DeleteInsightRequestToJSON(
  value?: DeleteInsightRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    insight_guid: value.insight_guid,
    insight_type: value.insight_type,
  };
}
