import React from "react";
import { classNames } from "../../util/strings";
import { subtitleText } from "../../util/style";

type MSubtitleProps = {
  text: string;
  className?: string | null;
};

const MSubtitleComponent = (props: MSubtitleProps) => {
  const { text, className } = props;

  return <h2 className={classNames(className, subtitleText)}>{text}</h2>;
};

MSubtitleComponent.defaultProps = {
  className: null,
};

export default MSubtitleComponent;
