import React, { useEffect, useState } from "react";
import { PromptRespondScreenProps } from "./PromptRespondScreen";
import {
  FillInBlankPromptMeta,
  FillInBlankPromptResponseMeta,
} from "../../../api/types";
import FillInBlankScreen from "./FillInBlankScreen";

const PromptRespondFillInBlankScreenComponent = (
  props: PromptRespondScreenProps
) => {
  const { prompt, onNextClicked: inputOnNextClicked, ...rest } = props;

  const [title, setTitle] = useState<string>("");
  const [placeholder, setPlaceholder] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [maxContentLength, setMaxContentLength] = useState<number | null>(null);

  const onNextClicked = (newContent: string, visible: boolean | null) => {
    setContent(newContent);
    inputOnNextClicked(newContent, visible);
  };

  useEffect(() => {
    setTitle(prompt.prompt.question);
    const promptMeta = prompt.prompt.meta as FillInBlankPromptMeta;
    if (promptMeta.max_chars) {
      setMaxContentLength(promptMeta.max_chars);
    }
    if (promptMeta.examples) {
      setPlaceholder(promptMeta.examples[0]);
    }
    if (prompt.response) {
      const responseMeta = prompt.response
        .meta as FillInBlankPromptResponseMeta;
      setContent(responseMeta.response);
    } else {
      setContent("");
    }
  }, [prompt.prompt.guid, prompt.response?.meta]);

  return (
    <FillInBlankScreen
      title={title}
      onNextClicked={onNextClicked}
      placeholder={placeholder}
      content={content}
      maxContentLength={maxContentLength}
      visible={prompt.visible ?? true}
      publishable={prompt.publishable}
      prompt={prompt}
      {...rest}
    />
  );
};

export default PromptRespondFillInBlankScreenComponent;
