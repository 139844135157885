/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  TargetResponse,
  TargetResponseFromJSON,
  TargetResponseFromJSONTyped,
  TargetResponseToJSON,
} from "./TargetResponse";

/**
 *
 * @export
 * @interface SetTargetResponsesRequest
 */
export interface SetTargetResponsesRequest {
  /**
   *
   * @type {string}
   * @memberof SetTargetResponsesRequest
   */
  assessment: string;
  /**
   *
   * @type {Array<TargetResponse>}
   * @memberof SetTargetResponsesRequest
   */
  responses: Array<TargetResponse>;
}

export function SetTargetResponsesRequestFromJSON(
  json: any
): SetTargetResponsesRequest {
  return SetTargetResponsesRequestFromJSONTyped(json, false);
}

export function SetTargetResponsesRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SetTargetResponsesRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    assessment: json["assessment"],
    responses: (json["responses"] as Array<any>).map(TargetResponseFromJSON),
  };
}

export function SetTargetResponsesRequestToJSON(
  value?: SetTargetResponsesRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    assessment: value.assessment,
    responses: (value.responses as Array<any>).map(TargetResponseToJSON),
  };
}
