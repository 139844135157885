/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UpdateGlimpsePitchContextRequest
 */
export interface UpdateGlimpsePitchContextRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateGlimpsePitchContextRequest
   */
  glimpse: string;
  /**
   *
   * @type {string}
   * @memberof UpdateGlimpsePitchContextRequest
   */
  pitch_context: string;
}

export function UpdateGlimpsePitchContextRequestFromJSON(
  json: any
): UpdateGlimpsePitchContextRequest {
  return UpdateGlimpsePitchContextRequestFromJSONTyped(json, false);
}

export function UpdateGlimpsePitchContextRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateGlimpsePitchContextRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    glimpse: json["glimpse"],
    pitch_context: json["pitch_context"],
  };
}

export function UpdateGlimpsePitchContextRequestToJSON(
  value?: UpdateGlimpsePitchContextRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    glimpse: value.glimpse,
    pitch_context: value.pitch_context,
  };
}
