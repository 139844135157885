import React, { ReactElement, useMemo } from "react";
import { LinkSummary } from "../../../api/models";
import { classNames } from "../../../util/strings";
import MDivider from "../../ui/MDivider";
import MPlusButton from "../../ui/buttons/MPlusButton";
import MEditButton from "../../ui/buttons/MEditButton";
import { useBusyWatcher } from "../../../util/hooks";
import { commentText } from "../../../util/style";

type LinksSectionProps = {
  links: LinkSummary[];
  className?: string;
  editUrl?: string | null;
  title?: string;
  bgColor?: string | null;
};

const defaultTitle = "no title";

const LinksSectionComponent = (props: LinksSectionProps) => {
  const { links, className, editUrl, title, bgColor } = props;

  const [busy, _] = useBusyWatcher();

  const getLinkElements = (): ReactElement[] => {
    const toProcess = [...links].sort(
      (a, b) => (a.title?.length ?? 0) - (b.title?.length ?? 0)
    );
    const toReturn: ReactElement[] = [];
    while (toProcess.length > 0) {
      let takeTwo = true;
      for (let i = 0; i < 2; i += 1) {
        const curTitle = toProcess[i]?.title ?? defaultTitle;
        if (curTitle.length > 13) {
          takeTwo = false;
        }
      }
      if (takeTwo && toProcess.length > 1) {
        const elements = toProcess.splice(0, 2);
        toReturn.push(
          <div
            key={elements[0].guid! + elements[1].guid!}
            className="w-full grid grid-cols-2 gap-2"
          >
            <a
              href={elements[0].url}
              className="w-full"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="flex flex-row w-full items-center text-primary text-sm">
                <div className="w-6 text-center">
                  <i
                    className={`${
                      elements[0].fa_icon ?? "fa-solid fa-link"
                    } fa-lg`}
                  />
                </div>
                <span className="underline">
                  {elements[0].title ?? defaultTitle}
                  {" >>"}
                </span>
              </div>
            </a>
            <a
              href={elements[1].url}
              className="w-full"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="flex flex-row w-full items-center text-primary text-sm">
                <div className="w-6">
                  <i
                    className={`${
                      elements[1].fa_icon ?? "fa-solid fa-link"
                    } fa-lg`}
                  />
                </div>
                <span className="underline">
                  {elements[1].title ?? defaultTitle}
                  {" >>"}
                </span>
              </div>
            </a>
          </div>
        );
      } else {
        const element = toProcess.splice(0, 1)[0];
        toReturn.push(
          <a
            key={element.guid!}
            className="w-full"
            href={element.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex flex-row w-full items-center text-primary text-sm">
              <div className="w-6">
                <i
                  className={`${element.fa_icon ?? "fa-solid fa-link"} fa-lg`}
                />
              </div>
              <span className="underline">
                {element.title ?? defaultTitle}
                {" >>"}
              </span>
            </div>
          </a>
        );
      }
    }
    return toReturn;
  };

  const elements = useMemo(() => getLinkElements(), [links]);

  const getAddButton = (): ReactElement => {
    if (links.length === 0) {
      return <MPlusButton href={editUrl!} disabled={busy} text={null} />;
    }
    return <MEditButton href={editUrl!} disabled={busy} text={null} />;
  };

  return (
    <div className={classNames("flex flex-col gap-2", className)}>
      <MDivider
        title={title}
        bgColor={bgColor}
        button={editUrl ? getAddButton() : null}
      />
      {elements}
      {links.length === 0 && (
        <div className={classNames(commentText, "my-3")}>
          you haven't added any links to your profile yet
        </div>
      )}
    </div>
  );
};

LinksSectionComponent.defaultProps = {
  className: undefined,
  editUrl: null,
  title: "links",
  bgColor: null,
};

export default LinksSectionComponent;
