/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  GlimpseLimits,
  GlimpseLimitsFromJSON,
  GlimpseLimitsFromJSONTyped,
  GlimpseLimitsToJSON,
} from "./GlimpseLimits";

/**
 *
 * @export
 * @interface SelfGlimpseContent
 */
export interface SelfGlimpseContent {
  /**
   *
   * @type {boolean}
   * @memberof SelfGlimpseContent
   */
  visible: boolean;
  /**
   *
   * @type {GlimpseLimits}
   * @memberof SelfGlimpseContent
   */
  limits: GlimpseLimits;
}

export function SelfGlimpseContentFromJSON(json: any): SelfGlimpseContent {
  return SelfGlimpseContentFromJSONTyped(json, false);
}

export function SelfGlimpseContentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SelfGlimpseContent {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    visible: json["visible"],
    limits: GlimpseLimitsFromJSON(json["limits"]),
  };
}

export function SelfGlimpseContentToJSON(
  value?: SelfGlimpseContent | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    visible: value.visible,
    limits: GlimpseLimitsToJSON(value.limits),
  };
}
