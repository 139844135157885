/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  PromptWithResponse,
  PromptWithResponseFromJSON,
  PromptWithResponseFromJSONTyped,
  PromptWithResponseToJSON,
} from "./PromptWithResponse";

/**
 *
 * @export
 * @interface AnsweredPromptDetail
 */
export interface AnsweredPromptDetail {
  /**
   *
   * @type {PromptWithResponse}
   * @memberof AnsweredPromptDetail
   */
  prompt_response: PromptWithResponse;
  /**
   *
   * @type {string}
   * @memberof AnsweredPromptDetail
   */
  presentation_id: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AnsweredPromptDetail
   */
  is_visible: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnsweredPromptDetail
   */
  can_be_visible: boolean;
  /**
   *
   * @type {number}
   * @memberof AnsweredPromptDetail
   */
  answered_count: number;
  /**
   *
   * @type {number}
   * @memberof AnsweredPromptDetail
   */
  order: number | null;
}

export function AnsweredPromptDetailFromJSON(json: any): AnsweredPromptDetail {
  return AnsweredPromptDetailFromJSONTyped(json, false);
}

export function AnsweredPromptDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AnsweredPromptDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    prompt_response: PromptWithResponseFromJSON(json["prompt_response"]),
    presentation_id: json["presentation_id"],
    is_visible: json["is_visible"],
    can_be_visible: json["can_be_visible"],
    answered_count: json["answered_count"],
    order: json["order"],
  };
}

export function AnsweredPromptDetailToJSON(
  value?: AnsweredPromptDetail | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    prompt_response: PromptWithResponseToJSON(value.prompt_response),
    presentation_id: value.presentation_id,
    is_visible: value.is_visible,
    can_be_visible: value.can_be_visible,
    answered_count: value.answered_count,
    order: value.order,
  };
}
