import React, { ReactElement, useState } from "react";
import { UserProfileSummary } from "../../../api/models";
import MBackButton from "../../ui/buttons/MBackButton";
import CenterColumnStickyButtonScreen from "../../ui/screens/CenterColumnStickyButtonScreen";
import { useBusyWatcher } from "../../../util/hooks";
import ProfileCard from "../../ui/cards/ProfileCard";
import { classNames } from "../../../util/strings";
import { commentText } from "../../../util/style";
import MTextArea from "../../ui/MTextArea";
import MNextButton from "../../ui/buttons/MNextButton";

type EnterQuestionScreenProps = {
  user: UserProfileSummary;
  question?: string | null;
  onQuestionSelected: (question: string) => void;
  errors: string[];
  onBackClicked: () => void;
  isSelf: boolean;
};

const EnterQuestionScreenComponent = (props: EnterQuestionScreenProps) => {
  const {
    user,
    question: questionInput,
    onQuestionSelected,
    errors,
    onBackClicked,
    isSelf,
  } = props;

  const [question, setQuestion] = useState<string>(questionInput ?? "");
  const [busy, _] = useBusyWatcher();

  const canSubmit = (): boolean => {
    if (question === "") {
      return false;
    }
    return true;
  };

  const getButtons = (): ReactElement[] => [
    <MNextButton
      kind="primary"
      onClick={() => onQuestionSelected(question)}
      disabled={busy || !canSubmit()}
      key="next-button"
      text="ask question"
    />,
    <MBackButton
      kind="secondary"
      onClick={onBackClicked}
      disabled={busy}
      key="back-button"
    />,
  ];

  return (
    <CenterColumnStickyButtonScreen buttons={getButtons()} stacked fullWidth>
      <ProfileCard user={user} className="sm:max-w-lg" asAnchor />
      <div className="font-bold text-center mt-3">
        what do you want to ask about {isSelf ? "yourself" : user.name}?
      </div>
      <div className={classNames(commentText, "text-xs mb-4 text-center")}>
        {isSelf
          ? "our response will be based on the contents of your profile. the more " +
            "content your profile contains, the better our response will be."
          : `${user.name} will never see your question! we will instead provide you
        with a response based on the contents of ${user.name}'s profile.`}
      </div>
      <MTextArea
        disabled={busy}
        label="question"
        value={question}
        placeholder={`what is the best way to ... with ${user.name}?`}
        onChange={(e) => setQuestion(e.target.value)}
        errors={errors}
      />
    </CenterColumnStickyButtonScreen>
  );
};

EnterQuestionScreenComponent.defaultProps = {
  question: null,
};

export default EnterQuestionScreenComponent;
