import React, { useEffect, useState } from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import BaseDialog from "../../../dialogs/BaseDialog";
import MButton from "../../../ui/buttons/MButton";
import MDivider from "../../../ui/MDivider";
import MWellCollapseButton from "../../../ui/wells/MWellCollapseButton";
import { classNames } from "../../../../util/strings";
import { clickableText } from "../../../../util/style";
import MLabeledWell from "../../../ui/wells/MLabeledWell";

type GlimpseExample = {
  title: string;
  description: string;
  context: string;
  audience: string;
  result?: string | null;
};

const examples: GlimpseExample[] = [
  {
    title: "land a job",
    description:
      "looking to stand out in a job application? use a Glimpse to show off your skills and experience.",
    context: `I'm applying to this job:

[PASTE A JOB DESCRIPTION FROM LEVER / WHEREVER ELSE HERE]`.trim(),
    audience:
      "I will share this with the recruiter as well as the hiring manager for the role.".trim(),
    result:
      "https://app.manual.me/glimpse/?id=930a9165-3b27-478f-94c7-19fa2553cecd",
  },
  {
    title: "convince an investor",
    description:
      "have an investor you're trying to close? try sending them a Glimpse that pitches them on why they should invest in your startup.",
    context:
      `I am the head of product and engineering at a startup that is building an AI platform
in service of building deeper connection between people. We are currently raising money
and I want to potential investors to feel comfortable investing in my company based on my career
trajectory and the skills I've learned along the way.`.trim(),
    audience:
      `Early-stage investors (either angels or pre-seed) who are interested in investing in:

1. Future of work
2. AI`.trim(),
    result:
      "https://app.manual.me/glimpse/?id=13dad96a-5fa3-45e3-a306-f4bfcb2ddd13",
  },
  {
    title: "hire a new employee",
    description:
      "trying to hire a new employee? send them a Glimpse to convince them to join your team.",
    context:
      `I have an open head count for a product engineer to join my team at a startup called Manual. I want to pitch my audience on (1) my leadership style and (2) what it would be like to work with me.

The startup is focused on building technology that uses AI in service of human connection, and I want candidates that would both (1) work well having me as their manager and (2) are deeply interested in the future of work space.`.trim(),
    audience: `everyone that would consider working for me as a product engineer.`,
    result:
      "https://app.manual.me/glimpse/?id=6d336bb3-728b-4160-a7ff-e2a923a97941",
  },
  {
    title: "speak on a panel",
    description: "make yourself shine when applying to speak on a panel.",
    context:
      "I'm applying to speak at this conference / on this panel [PASTE INFORMATION FROM PANEL / INFO ABOUT THE CONFERENCE]",
    audience:
      "i'll send it to the committee that judges applicants as a link in the application package ",
    result:
      "https://app.manual.me/glimpse/?id=514dcccf-2c22-4084-8ba2-195a0fe53198",
  },
];

type GlimpseExampleDialogProps = {
  show: boolean;
  onClose: () => void;
};

const GlimpseExampleDialogComponent = (props: GlimpseExampleDialogProps) => {
  const { show, onClose } = props;

  const [collapsed, setCollapsed] = useState<boolean[]>([]);

  const toggleCollapsed = (index: number) => {
    const newCollapsed = [...collapsed];
    newCollapsed[index] = !newCollapsed[index];
    setCollapsed(newCollapsed);
  };

  useEffect(() => {
    const newCollapsed = [];
    for (let i = 0; i < examples.length; i += 1) {
      newCollapsed.push(true);
    }
    setCollapsed(newCollapsed);
  }, []);

  return (
    <BaseDialog
      title="configuring your glimpse"
      show={show}
      onClose={onClose}
      className="max-w-3xl"
    >
      <div className="text-xs text-left">
        a good Glimpse requires detailed information about both (1) your wants
        and needs as well as (2) the context of what you're pitching and who you
        intend to share the Glimpse with. here are some examples of context,
        audience, and the results that they gave.
      </div>
      {examples.map((example, i) => (
        <div>
          <MDivider
            bgColor="bg-secondary"
            title={example.title}
            button={
              <div className="flex flex-row justify-center w-10 bg-secondary">
                <MWellCollapseButton
                  collapsed={collapsed[i]}
                  onClick={() => toggleCollapsed(i)}
                />
              </div>
            }
          />
          {!collapsed[i] && (
            <div className="flex flex-col gap-2 text-left">
              <div className="text-xs">{example.description}</div>
              {example.result && (
                <a href={example.result} target="_blank" rel="noreferrer">
                  <div
                    className={classNames(
                      "flex flex-row gap-1 items-center text-xs",
                      clickableText
                    )}
                  >
                    <span>see the result</span>
                    <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                  </div>
                </a>
              )}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                <MLabeledWell label="context">{example.context}</MLabeledWell>
                <MLabeledWell label="audience">{example.audience}</MLabeledWell>
              </div>
            </div>
          )}
        </div>
      ))}
      <div className="flex flex-row-reverse">
        <MButton onClick={onClose} kind="primary">
          done
        </MButton>
      </div>
    </BaseDialog>
  );
};

export default GlimpseExampleDialogComponent;
