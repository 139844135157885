import React, { ReactElement } from "react";
import { useBusyWatcher } from "../../../../util/hooks";
import MBackButton from "../../../ui/buttons/MBackButton";
import CenterColumnStickyButtonScreen from "../../../ui/screens/CenterColumnStickyButtonScreen";
import MNextButton from "../../../ui/buttons/MNextButton";
import MMarkdown from "../../../ui/MMarkdown";
import GroupSummaryCard from "../GroupSummaryCard";
import MThumbsUpOrDownButtons from "../../../ui/MThumbsUpOrDownButtons";
import { GroupQuestionAndAnswer, UserGroup } from "../../../../api/models";

interface GroupInsightResult {
  results?: string;
}

type ViewGroupInsightScreenProps = {
  groupInsight: GroupQuestionAndAnswer;
  group: UserGroup;
  question: string;
  viewGroupUrl: string;
  editGroupUrl: string;
  rateResponse: (guid: string, rating: number, callType: string) => void;
  onDoneClicked?: (() => void) | null;
  onBackClicked?: (() => void) | null;
};

const ViewGroupInsightScreenComponent = (
  props: ViewGroupInsightScreenProps
) => {
  const {
    groupInsight,
    group,
    question,
    viewGroupUrl,
    editGroupUrl,
    rateResponse: rateResponseInput,
    onDoneClicked,
    onBackClicked,
  } = props;

  const [busy, _] = useBusyWatcher();

  const onThumbsClicked = (rating: number) => {
    if (!groupInsight || !groupInsight.llm_invocation) {
      return;
    }
    rateResponseInput(
      groupInsight.guid!,
      rating,
      groupInsight.llm_invocation!.call_type!
    );
  };

  const getButtons = (): ReactElement[] => {
    const toReturn: ReactElement[] = [];
    if (onDoneClicked) {
      toReturn.push(
        <MNextButton
          kind="primary"
          key="done-button"
          onClick={onDoneClicked}
          disabled={busy}
          text="done"
        />
      );
    }
    if (onBackClicked) {
      toReturn.push(
        <MBackButton
          kind="secondary"
          key="back-button"
          onClick={onBackClicked}
          disabled={busy}
        />
      );
    }
    return toReturn;
  };

  return (
    <CenterColumnStickyButtonScreen buttons={getButtons()} stacked fullWidth>
      <div className="flex flex-col gap-5">
        <GroupSummaryCard
          group={group}
          hideDeleteButton
          hideLeaveButton
          viewGroupUrl={viewGroupUrl}
          editGroupUrl={editGroupUrl}
        />
        <div>
          <div className="font-bold mb-1">{question}</div>
          {groupInsight && groupInsight.llm_invocation && (
            <MMarkdown
              content={
                (groupInsight.llm_invocation?.results as GroupInsightResult)
                  .results!
              }
            />
          )}
        </div>
        <MThumbsUpOrDownButtons
          label="was this response useful?"
          onThumbsUpClick={() => onThumbsClicked(1)}
          onThumbsDownClick={() => onThumbsClicked(0)}
        />
      </div>
    </CenterColumnStickyButtonScreen>
  );
};

ViewGroupInsightScreenComponent.defaultProps = {
  onDoneClicked: null,
  onBackClicked: null,
};

export default ViewGroupInsightScreenComponent;
