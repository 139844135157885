import React from "react";
import { classNames } from "../../../../util/strings";
import { commentText } from "../../../../util/style";

type SidebarCommentRowProps = {
  comment: string;
};

const SidebarCommentRowComponent = (props: SidebarCommentRowProps) => {
  const { comment } = props;

  return (
    <div
      className={classNames("pt-4 items-center sm:flex text-sm", commentText)}
    >
      {comment}
    </div>
  );
};

export default SidebarCommentRowComponent;
