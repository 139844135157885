import React, { ReactNode } from "react";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import MButton, { MButtonProps } from "./MButton";

type MBackButtonProps = MButtonProps & {
  text?: string;
  icon?: ReactNode;
};

const MBackButtonComponent = (props: MBackButtonProps) => {
  const { text, ...rest } = props;

  return <MButton {...rest}>{text}</MButton>;
};

MBackButtonComponent.defaultProps = {
  text: "back",
  icon: <ChevronLeftIcon className="h-6 w-5 flex-none" aria-hidden="true" />,
};

export default MBackButtonComponent;
