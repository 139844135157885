import React from "react";
import { classNames } from "../../util/strings";

type MCharacterLimitProps = {
  maxLength: number | null;
  curLength: number;
};

const MCharacterLimitComponent = (props: MCharacterLimitProps) => {
  const { maxLength, curLength } = props;

  const charactersRemaining = maxLength ? maxLength - curLength : 0;
  const msgRemaining =
    charactersRemaining < 0
      ? `${charactersRemaining * -1} over`
      : `${charactersRemaining} remaining`;
  const isOverLimit = maxLength !== null && charactersRemaining < 0;

  const getCharacterLimitMessage = (): string => {
    let toReturn = `${curLength}/${maxLength} characters`;
    if (isOverLimit) {
      toReturn += ` (${msgRemaining})`;
    }
    return toReturn;
  };

  return maxLength ? (
    <div
      className={classNames(
        "text-xs text-right",
        isOverLimit ? "text-danger" : "text-m-dark-gray"
      )}
    >
      {getCharacterLimitMessage()}
    </div>
  ) : null;
};

export default MCharacterLimitComponent;
