import React from "react";
import { classNames } from "../../util/strings";

type MLabelProps = {
  label?: string | null;
  htmlFor?: string;
  required?: boolean;
  error?: boolean;
};

const MLabelComponent = (props: MLabelProps) => {
  const { label, required, htmlFor, error } = props;

  return (
    <label
      htmlFor={htmlFor}
      className={classNames(
        "block mb-2 text-sm leading-3 font-semibold text-left",
        error ? "text-danger" : "text-m-dark-gray",
        !label ? "hidden" : null
      )}
    >
      {label}
      {required && <span className="text-primary font-bold">*</span>}
    </label>
  );
};

MLabelComponent.defaultProps = {
  htmlFor: undefined,
  required: undefined,
  label: null,
  error: false,
};

export default MLabelComponent;
