import React, { useEffect, useState } from "react";
import { usePostHog } from "posthog-js/react";
import { MNavProps } from "../../ui/nav/MNav";
import {
  GetEventTypeEventEnum,
  GetQuestionnaireWithResponsesResponse,
  PublishablePromptWithResponse,
} from "../../../api/models";
import WizardScreen, { WizardScreenPanel } from "../../ui/screens/WizardScreen";
import LoadingScreen from "../../ui/screens/LoadingScreen";
import QuestionnaireIntroScreen from "./QuestionnaireIntroScreen";
import { useBackTimer, useBusyWatcher } from "../../../util/hooks";
import { useWrappedConnector } from "../../../api/connector";
import BaseNavbarPage from "../BaseNavbarPage";
import PromptRespondTransitionScreen from "../../ui/forms/PromptRespondTransitionScreen";
import { errorMessagesForKeyFromResponse } from "../../../api/helpers";
import { Step, StepStatus } from "../../ui/MStepsCircles";
import QuestionnaireReviewScreen from "./QuestionnaireReviewScreen";

type QuestionnairePageProps = {
  questionnaireId: string;
  nextUrl: string;
  navbarProps: MNavProps;
};

enum QuestionnaireStage {
  LOADING,
  INTRODUCTION,
  RESPOND,
  REVIEW,
  UPDATE,
}

const QuestionnairePageComponent = (props: QuestionnairePageProps) => {
  const { questionnaireId, nextUrl, navbarProps } = props;

  const [questionnaire, setQuestionnaire] =
    useState<GetQuestionnaireWithResponsesResponse | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [stage, setStage] = useState<QuestionnaireStage>(
    QuestionnaireStage.LOADING
  );
  const [prompt, setPrompt] = useState<PublishablePromptWithResponse | null>(
    null
  );
  const [promptIndex, setPromptIndex] = useState<number>(0);
  const [maxPromptIndex, setMaxPromptIndex] = useState<number>(0);
  const [isBack, asBack] = useBackTimer(stage);
  const [_, busyWatcher] = useBusyWatcher();
  const connector = useWrappedConnector(busyWatcher);
  const posthog = usePostHog();

  useEffect(() => {
    if (promptIndex > maxPromptIndex) {
      setMaxPromptIndex(promptIndex);
    }
  }, [promptIndex]);

  const goToStage = async (nextStage: QuestionnaireStage) => {
    // eslint-disable-next-line default-case
    switch (nextStage) {
      case QuestionnaireStage.INTRODUCTION: {
        const response = await connector.getQuestionnaire(questionnaireId);
        setQuestionnaire(response.c!);
        break;
      }
      case QuestionnaireStage.RESPOND: {
        const promptGuid = questionnaire!.prompts[0].prompt.guid!;
        const response = await connector.getPromptWithResponse(promptGuid);
        setPrompt(response.c!);
        setPromptIndex(0);
        setErrors([]);
        break;
      }
      case QuestionnaireStage.REVIEW: {
        const response = await connector.getQuestionnaire(questionnaireId);
        setQuestionnaire(response.c!);
        break;
      }
    }
    setStage(nextStage);
  };

  const submitResponse = async (
    response: any,
    visible: boolean | null
  ): Promise<boolean> => {
    setErrors([]);
    try {
      await connector.submitPromptResponse({
        prompt: prompt!.prompt.guid!,
        response,
        questionnaire: questionnaireId,
        publish: visible,
      });
      return true;
    } catch (r: any) {
      const parsed = await errorMessagesForKeyFromResponse(r, "response", true);
      setErrors(parsed);
      return false;
    }
  };

  const loadPromptAtIndex = async (index: number) => {
    const nextPromptGuid = questionnaire!.prompts[index].prompt.guid!;
    const nextPrompt = await connector.getPromptWithResponse(nextPromptGuid);
    setPrompt(nextPrompt.c!);
    setPromptIndex(index);
  };

  const onSaveResponseClicked = async (
    response: any,
    visible: boolean | null
  ) => {
    const success = await submitResponse(response, visible);
    if (!success) {
      return;
    }
    if (promptIndex === questionnaire!.prompts.length - 1) {
      await goToStage(QuestionnaireStage.REVIEW);
    } else {
      await loadPromptAtIndex(promptIndex + 1);
    }
  };

  const onUpdateResponseClicked = async (
    response: any,
    visible: boolean | null
  ) => {
    const success = await submitResponse(response, visible);
    if (!success) {
      return;
    }
    await goToStage(QuestionnaireStage.REVIEW);
  };

  const onSkipPromptClicked = async () => {
    if (promptIndex === questionnaire!.prompts.length - 1) {
      await goToStage(QuestionnaireStage.REVIEW);
    } else {
      await loadPromptAtIndex(promptIndex + 1);
    }
    posthog.capture(GetEventTypeEventEnum.questionnaire_question_skipped, {
      questionnaire_guid: questionnaire?.questionnaire.guid,
      prompt_guid: questionnaire?.prompts[promptIndex].prompt.guid,
    });
  };

  const onEditResponseClicked = async (promptGuid: string) => {
    setErrors([]);
    const nextPrompt = await connector.getPromptWithResponse(promptGuid);
    setPrompt(nextPrompt.c!);
    await goToStage(QuestionnaireStage.UPDATE);
  };

  const getSteps = (): Step[] => {
    if (!questionnaire) {
      return [];
    }

    const statusFromPromptIndex = (index: number): StepStatus => {
      if (promptIndex > index) {
        return "complete";
      }
      if (promptIndex === index) {
        return "current";
      }
      return "upcoming";
    };

    const toReturn: Step[] = [];
    for (let i = 0; i < questionnaire.prompts.length; i += 1) {
      toReturn.push({
        name: questionnaire!.prompts[i].prompt.question,
        status: statusFromPromptIndex(i),
        key: questionnaire!.prompts[i].prompt.guid!,
      });
    }
    return toReturn;
  };

  const onBackQuizClicked = async () => {
    if (promptIndex === 0) {
      await goToStage(QuestionnaireStage.INTRODUCTION);
    } else {
      await loadPromptAtIndex(promptIndex - 1);
    }
  };

  const onDoneClicked = async () => {
    await connector.markQuestionnaireAsCompleted(questionnaireId);
    window.location.href = nextUrl;
  };

  const getElements = (): WizardScreenPanel[] => [
    {
      stage: QuestionnaireStage.LOADING,
      content: (
        <LoadingScreen
          errors={errors}
          onBackClicked={() => window.history.back()}
        />
      ),
    },
    {
      stage: QuestionnaireStage.INTRODUCTION,
      content: questionnaire && (
        <QuestionnaireIntroScreen
          questionnaire={questionnaire}
          startClicked={() => goToStage(QuestionnaireStage.RESPOND)}
        />
      ),
    },
    {
      stage: QuestionnaireStage.RESPOND,
      content: prompt && (
        <PromptRespondTransitionScreen
          prompt={prompt}
          onNextClicked={onSaveResponseClicked}
          onBackClicked={asBack(onBackQuizClicked)}
          onSkipClicked={onSkipPromptClicked}
          errors={errors}
          steps={{
            steps: getSteps(),
            onStepClicked: loadPromptAtIndex,
            minClickableIndex: 0,
            maxClickableIndex: maxPromptIndex,
          }}
        />
      ),
    },
    {
      stage: QuestionnaireStage.REVIEW,
      content: questionnaire && (
        <QuestionnaireReviewScreen
          title="review your answers"
          content={questionnaire!}
          onNextClicked={onDoneClicked}
          onEditClicked={onEditResponseClicked}
          completedFlavorText={
            'review your answers below and if everything looks good "finish" at the bottom of the page'
          }
          nextText="finish"
        />
      ),
    },
    {
      stage: QuestionnaireStage.UPDATE,
      content: prompt && (
        <PromptRespondTransitionScreen
          prompt={prompt}
          onNextClicked={onUpdateResponseClicked}
          onBackClicked={asBack(() => goToStage(QuestionnaireStage.REVIEW))}
        />
      ),
    },
  ];

  useEffect(() => {
    goToStage(QuestionnaireStage.INTRODUCTION);
  }, []);

  return (
    <BaseNavbarPage navbarProps={navbarProps}>
      <WizardScreen elements={getElements()} isBack={isBack} stage={stage} />
    </BaseNavbarPage>
  );
};

export default QuestionnairePageComponent;
