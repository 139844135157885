/* eslint-disable react/button-has-type */
import React, { useState } from "react";
import DropDown from "./SimpleDropdown";
import MButton from "../../ui/buttons/MButton";

type SimpleMenuProps = {
  items: string[];
  label?: string;
  onSelectFile: (file: string) => void;
};

const SimpleMenu = (props: SimpleMenuProps) => {
  const { items, label, onSelectFile } = props;

  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const [selectItem, setSelectItem] = useState<string>("");

  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  const dismissHandler = (event: React.FocusEvent<HTMLButtonElement>): void => {
    if (event.currentTarget === event.target) {
      setShowDropDown(false);
    }
  };

  const itemSelection = (item: string): void => {
    setSelectItem(item);
    onSelectFile(item);
  };

  return (
    <MButton
      className={showDropDown ? "active" : undefined}
      onClick={() => toggleDropDown()}
      onBlur={(e: React.FocusEvent<HTMLButtonElement>): void =>
        dismissHandler(e)
      }
    >
      {selectItem ? `Selected: ${selectItem}` : label}
      {showDropDown && <DropDown items={items} itemSelection={itemSelection} />}
    </MButton>
  );
};

SimpleMenu.defaultProps = {
  label: "Select File to Compare",
};

export default SimpleMenu;
