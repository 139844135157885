/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  AddSecondaryEmailRequest,
  AddSecondaryEmailRequestFromJSON,
  AddSecondaryEmailRequestToJSON,
  EmptyResponseWrapper,
  EmptyResponseWrapperFromJSON,
  EmptyResponseWrapperToJSON,
  ListUserInvitesResponseSerializer,
  ListUserInvitesResponseSerializerFromJSON,
  ListUserInvitesResponseSerializerToJSON,
  ResendInviteRequest,
  ResendInviteRequestFromJSON,
  ResendInviteRequestToJSON,
  UpdateUserSettingsRequest,
  UpdateUserSettingsRequestFromJSON,
  UpdateUserSettingsRequestToJSON,
  UserInviteRequest,
  UserInviteRequestFromJSON,
  UserInviteRequestToJSON,
  UserSearchResponseSerializer,
  UserSearchResponseSerializerFromJSON,
  UserSearchResponseSerializerToJSON,
  UserSettingsDetailSerializer,
  UserSettingsDetailSerializerFromJSON,
  UserSettingsDetailSerializerToJSON,
} from "../models";

export interface AddSecondaryEmailOperationRequest {
  data: AddSecondaryEmailRequest;
}

export interface LogoutUserRequest {
  data: object;
}

export interface ResendInviteOperationRequest {
  data: ResendInviteRequest;
}

export interface ResetAccountRequest {
  data: object;
}

export interface UpdateUserSettingsOperationRequest {
  data: UpdateUserSettingsRequest;
}

export interface UserInviteOperationRequest {
  data: UserInviteRequest;
}

export interface UserSearchRequest {
  term: string;
}

/**
 *
 */
export class UserApi extends runtime.BaseAPI {
  /**
   * Add a secondary email address for the requesting user.
   */
  async addSecondaryEmailRaw(
    requestParameters: AddSecondaryEmailOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling addSecondaryEmail."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/user/add-email`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: AddSecondaryEmailRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Add a secondary email address for the requesting user.
   */
  async addSecondaryEmail(
    requestParameters: AddSecondaryEmailOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.addSecondaryEmailRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get a user\'s settings.
   */
  async getUserSettingsRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<UserSettingsDetailSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/user/get-settings`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserSettingsDetailSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get a user\'s settings.
   */
  async getUserSettings(
    initOverrides?: RequestInit
  ): Promise<UserSettingsDetailSerializer> {
    const response = await this.getUserSettingsRaw(initOverrides);
    return await response.value();
  }

  /**
   * Get a list of invites for the requesting user.
   */
  async listUserInvitesRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ListUserInvitesResponseSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/user/list-invites`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ListUserInvitesResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get a list of invites for the requesting user.
   */
  async listUserInvites(
    initOverrides?: RequestInit
  ): Promise<ListUserInvitesResponseSerializer> {
    const response = await this.listUserInvitesRaw(initOverrides);
    return await response.value();
  }

  /**
   * Log out the requesting user.
   */
  async logoutUserRaw(
    requestParameters: LogoutUserRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling logoutUser."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/user/logout`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.data as any,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Log out the requesting user.
   */
  async logoutUser(
    requestParameters: LogoutUserRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.logoutUserRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Resend an invite to a user.
   */
  async resendInviteRaw(
    requestParameters: ResendInviteOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling resendInvite."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/user/resend-invite`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ResendInviteRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Resend an invite to a user.
   */
  async resendInvite(
    requestParameters: ResendInviteOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.resendInviteRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Reset the requesting user\'s account.
   */
  async resetAccountRaw(
    requestParameters: ResetAccountRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling resetAccount."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/user/reset`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.data as any,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Reset the requesting user\'s account.
   */
  async resetAccount(
    requestParameters: ResetAccountRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.resetAccountRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update a user\'s settings.
   */
  async updateUserSettingsRaw(
    requestParameters: UpdateUserSettingsOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling updateUserSettings."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/user/update-settings`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateUserSettingsRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Update a user\'s settings.
   */
  async updateUserSettings(
    requestParameters: UpdateUserSettingsOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.updateUserSettingsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Attempt to invite someone new to Manual via email.
   */
  async userInviteRaw(
    requestParameters: UserInviteOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling userInvite."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/user/invite`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UserInviteRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Attempt to invite someone new to Manual via email.
   */
  async userInvite(
    requestParameters: UserInviteOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.userInviteRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Search for a user based on a query and return the top N results.
   */
  async userSearchRaw(
    requestParameters: UserSearchRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<UserSearchResponseSerializer>> {
    if (
      requestParameters.term === null ||
      requestParameters.term === undefined
    ) {
      throw new runtime.RequiredError(
        "term",
        "Required parameter requestParameters.term was null or undefined when calling userSearch."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.term !== undefined) {
      queryParameters["term"] = requestParameters.term;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/user/search`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserSearchResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Search for a user based on a query and return the top N results.
   */
  async userSearch(
    requestParameters: UserSearchRequest,
    initOverrides?: RequestInit
  ): Promise<UserSearchResponseSerializer> {
    const response = await this.userSearchRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
