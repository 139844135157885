/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GetUserVitalsResponse
 */
export interface GetUserVitalsResponse {
  /**
   *
   * @type {string}
   * @memberof GetUserVitalsResponse
   */
  name: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUserVitalsResponse
   */
  location: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUserVitalsResponse
   */
  current_title: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUserVitalsResponse
   */
  current_employer: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUserVitalsResponse
   */
  pronouns: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUserVitalsResponse
   */
  relationship: string | null;
  /**
   *
   * @type {number}
   * @memberof GetUserVitalsResponse
   */
  kids: number | null;
  /**
   *
   * @type {string}
   * @memberof GetUserVitalsResponse
   */
  beliefs: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUserVitalsResponse
   */
  sexual_orientation: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUserVitalsResponse
   */
  ethnicity: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUserVitalsResponse
   */
  mbti: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUserVitalsResponse
   */
  love_language: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUserVitalsResponse
   */
  enneagram: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUserVitalsResponse
   */
  zodiac: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUserVitalsResponse
   */
  human_design: string | null;
}

export function GetUserVitalsResponseFromJSON(
  json: any
): GetUserVitalsResponse {
  return GetUserVitalsResponseFromJSONTyped(json, false);
}

export function GetUserVitalsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetUserVitalsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    location: json["location"],
    current_title: json["current_title"],
    current_employer: json["current_employer"],
    pronouns: json["pronouns"],
    relationship: json["relationship"],
    kids: json["kids"],
    beliefs: json["beliefs"],
    sexual_orientation: json["sexual_orientation"],
    ethnicity: json["ethnicity"],
    mbti: json["mbti"],
    love_language: json["love_language"],
    enneagram: json["enneagram"],
    zodiac: json["zodiac"],
    human_design: json["human_design"],
  };
}

export function GetUserVitalsResponseToJSON(
  value?: GetUserVitalsResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    location: value.location,
    current_title: value.current_title,
    current_employer: value.current_employer,
    pronouns: value.pronouns,
    relationship: value.relationship,
    kids: value.kids,
    beliefs: value.beliefs,
    sexual_orientation: value.sexual_orientation,
    ethnicity: value.ethnicity,
    mbti: value.mbti,
    love_language: value.love_language,
    enneagram: value.enneagram,
    zodiac: value.zodiac,
    human_design: value.human_design,
  };
}
