/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Attachments,
  AttachmentsFromJSON,
  AttachmentsFromJSONTyped,
  AttachmentsToJSON,
} from "./Attachments";
import {
  EmployerRelatedDetail,
  EmployerRelatedDetailFromJSON,
  EmployerRelatedDetailFromJSONTyped,
  EmployerRelatedDetailToJSON,
} from "./EmployerRelatedDetail";
import {
  LinkSummary,
  LinkSummaryFromJSON,
  LinkSummaryFromJSONTyped,
  LinkSummaryToJSON,
} from "./LinkSummary";
import {
  PromptWithResponse,
  PromptWithResponseFromJSON,
  PromptWithResponseFromJSONTyped,
  PromptWithResponseToJSON,
} from "./PromptWithResponse";
import {
  SelfGlimpseContent,
  SelfGlimpseContentFromJSON,
  SelfGlimpseContentFromJSONTyped,
  SelfGlimpseContentToJSON,
} from "./SelfGlimpseContent";
import {
  UserProfileDetail,
  UserProfileDetailFromJSON,
  UserProfileDetailFromJSONTyped,
  UserProfileDetailToJSON,
} from "./UserProfileDetail";
import {
  ViewGlimpseModel,
  ViewGlimpseModelFromJSON,
  ViewGlimpseModelFromJSONTyped,
  ViewGlimpseModelToJSON,
} from "./ViewGlimpseModel";

/**
 *
 * @export
 * @interface ViewGlimpseResponse
 */
export interface ViewGlimpseResponse {
  /**
   *
   * @type {ViewGlimpseModel}
   * @memberof ViewGlimpseResponse
   */
  glimpse: ViewGlimpseModel;
  /**
   *
   * @type {UserProfileDetail}
   * @memberof ViewGlimpseResponse
   */
  user: UserProfileDetail;
  /**
   *
   * @type {Array<PromptWithResponse>}
   * @memberof ViewGlimpseResponse
   */
  prompts: Array<PromptWithResponse>;
  /**
   *
   * @type {Array<LinkSummary>}
   * @memberof ViewGlimpseResponse
   */
  links: Array<LinkSummary>;
  /**
   *
   * @type {Array<EmployerRelatedDetail>}
   * @memberof ViewGlimpseResponse
   */
  work_history: Array<EmployerRelatedDetail>;
  /**
   *
   * @type {Attachments}
   * @memberof ViewGlimpseResponse
   */
  attachments: Attachments;
  /**
   *
   * @type {boolean}
   * @memberof ViewGlimpseResponse
   */
  can_be_contacted: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ViewGlimpseResponse
   */
  profile_can_be_viewed: boolean;
  /**
   *
   * @type {SelfGlimpseContent}
   * @memberof ViewGlimpseResponse
   */
  self?: SelfGlimpseContent;
}

export function ViewGlimpseResponseFromJSON(json: any): ViewGlimpseResponse {
  return ViewGlimpseResponseFromJSONTyped(json, false);
}

export function ViewGlimpseResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ViewGlimpseResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    glimpse: ViewGlimpseModelFromJSON(json["glimpse"]),
    user: UserProfileDetailFromJSON(json["user"]),
    prompts: (json["prompts"] as Array<any>).map(PromptWithResponseFromJSON),
    links: (json["links"] as Array<any>).map(LinkSummaryFromJSON),
    work_history: (json["work_history"] as Array<any>).map(
      EmployerRelatedDetailFromJSON
    ),
    attachments: AttachmentsFromJSON(json["attachments"]),
    can_be_contacted: json["can_be_contacted"],
    profile_can_be_viewed: json["profile_can_be_viewed"],
    self: !exists(json, "self")
      ? undefined
      : SelfGlimpseContentFromJSON(json["self"]),
  };
}

export function ViewGlimpseResponseToJSON(
  value?: ViewGlimpseResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    glimpse: ViewGlimpseModelToJSON(value.glimpse),
    user: UserProfileDetailToJSON(value.user),
    prompts: (value.prompts as Array<any>).map(PromptWithResponseToJSON),
    links: (value.links as Array<any>).map(LinkSummaryToJSON),
    work_history: (value.work_history as Array<any>).map(
      EmployerRelatedDetailToJSON
    ),
    attachments: AttachmentsToJSON(value.attachments),
    can_be_contacted: value.can_be_contacted,
    profile_can_be_viewed: value.profile_can_be_viewed,
    self: SelfGlimpseContentToJSON(value.self),
  };
}
