import React, { useState } from "react";
import BaseDialog from "../../dialogs/BaseDialog";
import { clickableText } from "../../../util/style";
import { classNames } from "../../../util/strings";
import MNextButton from "../../ui/buttons/MNextButton";
import MCheckbox from "../../ui/MCheckbox";
import { useBusyWatcher } from "../../../util/hooks";

type ProfileNextStepsDialogProps = {
  show: boolean;
  onClose: (dontShow: boolean) => void;
  listGlimpseUrl: string;
  listInsightsUrl: string;
  glimpseLoomUrl: string;
  insightsLoomUrl: string;
};

const ProfileNextStepsDialogComponent = (
  props: ProfileNextStepsDialogProps
) => {
  const {
    show,
    onClose,
    glimpseLoomUrl,
    insightsLoomUrl,
    listGlimpseUrl,
    listInsightsUrl,
  } = props;

  const [busy, _] = useBusyWatcher();
  const [dontShow, setDontShow] = useState<boolean>(false);

  return (
    <BaseDialog
      title="welcome + next steps"
      show={show}
      onClose={() => onClose(dontShow)}
    >
      <div className="max-w-xl text-left text-sm flex flex-col gap-3">
        <div>
          welcome to your Manual! This is your home base. You can edit the
          information any time in Settings or by clicking the button in the top
          right-hand corner on your profile.
        </div>
        <div>
          <span className="font-semibold">First things first</span>, keep adding
          more information and answering more questions. There's a handy to-do
          list at the top of your profile page that will walk you through all
          the content you can add, including short assessments to help you write
          about yourself.
        </div>
        <div>
          Once your Manual is full of interesting context about you, try asking
          a question in <span className="font-semibold">Insights</span> or
          creating a <span className="font-semibold">Glimpse</span>.
        </div>
        <div>
          <a
            className={classNames(clickableText, "font-semibold")}
            href={listGlimpseUrl}
          >
            Glimpse
          </a>{" "}
          is a new way to tell the story of who you are — not just where you've
          worked. You can generate a custom profile, tailored to pitching
          yourself for something, that combines all the best parts of a resume
          and cover letter.
          <div className={clickableText}>
            <a href={glimpseLoomUrl} target="_blank" rel="noreferrer">
              learn more &gt;&gt;
            </a>
          </div>
        </div>
        <div>
          <a
            className={classNames(clickableText, "font-semibold")}
            href={listInsightsUrl}
          >
            Insights
          </a>{" "}
          is a place to ask every kind of question about you, your team, and how
          we exist in the workplace. Learn more about your own career
          possibilities, understand your work style, get curious about others,
          prepare for tough conversations, and so much more.
          <div className={clickableText}>
            <a href={insightsLoomUrl} target="_blank" rel="noreferrer">
              learn more &gt;&gt;
            </a>
          </div>
        </div>
        <div className="flex flex-row items-center gap-2">
          <MCheckbox
            onChange={(e) => setDontShow(e.target.checked)}
            disabled={busy}
          />
          <div>don't show this again</div>
          <div className="grow" />
          <MNextButton
            kind="primary"
            onClick={() => onClose(dontShow)}
            text="done"
          />
        </div>
      </div>
    </BaseDialog>
  );
};

export default ProfileNextStepsDialogComponent;
