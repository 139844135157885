import React from "react";
import { SingleUserQuestionInsightRelatedDetail } from "../../../api/models";
import InsightContainer from "./InsightContainer";
import { CommonInsightCardProps } from "./common";

type SingleUserInsightCardProps = CommonInsightCardProps & {
  insight: SingleUserQuestionInsightRelatedDetail;
};

const SingleUserInsightCardComponent = (props: SingleUserInsightCardProps) => {
  const { insight, ...rest } = props;

  return (
    <InsightContainer
      buttonLabel={`question about ${insight.about_user?.name} from ${insight.asking_user?.name}`}
      askingUser={insight.asking_user}
      aboutUser={insight.about_user!}
      title="QUESTION"
      timeCreated={insight.time_created!}
      body={insight.question}
      {...rest}
    />
  );
};

export default SingleUserInsightCardComponent;
