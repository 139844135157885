export const DEFAULT_TRANSITION_ENTER_DURATION = 250;
export const DEFAULT_TRANSITION_LEAVE_DURATION = 150;
export const DEFAULT_TRANSITION_PADDING = 50;
export const PROFILE_COMPLETION_STAGE_KEY = "profile_completion_stage";

export const PROFILE_WELCOME_SEEN_KEY = "profile_welcome_seen";

export const MAX_STEP_TOOLTIP_LENGTH = 50;

export const PAGINATION_LIMIT = 10;

export const TEXT_BLUR_DELAY = 150;
