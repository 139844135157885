import React, { PropsWithChildren, ReactNode } from "react";
import { classNames } from "../../../util/strings";

export type WellKind = "regular" | "magical" | "white";

export type MWellProps = {
  kind?: WellKind;
  className?: string | null;
  footer?: ReactNode | null;
};

const MWellComponent = (props: PropsWithChildren<MWellProps>) => {
  const { kind, children, className, footer } = props;

  const getClassFromKind = (): string => {
    switch (kind) {
      case "magical":
        return "bg-primary-light";
      case "white":
        return "bg-m-white";
      default:
        return "bg-m-light-gray/50";
    }
  };

  return (
    <div
      className={classNames(
        "overflow-hidden rounded-lg divide-y divide-m-light-gray",
        getClassFromKind(),
        className
      )}
    >
      <div className="p-2 sm:p-3">{children}</div>
      {footer && <div className="p-2 sm:p-3 bg-m-secondary">{footer}</div>}
    </div>
  );
};

MWellComponent.defaultProps = {
  kind: "regular" as WellKind,
  className: null,
  footer: null,
};

export default MWellComponent;
