/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CollabQnaEntry
 */
export interface CollabQnaEntry {
  /**
   *
   * @type {string}
   * @memberof CollabQnaEntry
   */
  question: string;
  /**
   *
   * @type {string}
   * @memberof CollabQnaEntry
   */
  answer: string;
}

export function CollabQnaEntryFromJSON(json: any): CollabQnaEntry {
  return CollabQnaEntryFromJSONTyped(json, false);
}

export function CollabQnaEntryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CollabQnaEntry {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    question: json["question"],
    answer: json["answer"],
  };
}

export function CollabQnaEntryToJSON(value?: CollabQnaEntry | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    question: value.question,
    answer: value.answer,
  };
}
