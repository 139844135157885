/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  EmployerRelatedDetail,
  EmployerRelatedDetailFromJSON,
  EmployerRelatedDetailFromJSONTyped,
  EmployerRelatedDetailToJSON,
} from "./EmployerRelatedDetail";

/**
 *
 * @export
 * @interface ListEmployersResponse
 */
export interface ListEmployersResponse {
  /**
   *
   * @type {Array<EmployerRelatedDetail>}
   * @memberof ListEmployersResponse
   */
  employers: Array<EmployerRelatedDetail>;
  /**
   *
   * @type {number}
   * @memberof ListEmployersResponse
   */
  count: number;
}

export function ListEmployersResponseFromJSON(
  json: any
): ListEmployersResponse {
  return ListEmployersResponseFromJSONTyped(json, false);
}

export function ListEmployersResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ListEmployersResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    employers: (json["employers"] as Array<any>).map(
      EmployerRelatedDetailFromJSON
    ),
    count: json["count"],
  };
}

export function ListEmployersResponseToJSON(
  value?: ListEmployersResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    employers: (value.employers as Array<any>).map(EmployerRelatedDetailToJSON),
    count: value.count,
  };
}
