/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  UserProfileSummary,
  UserProfileSummaryFromJSON,
  UserProfileSummaryFromJSONTyped,
  UserProfileSummaryToJSON,
} from "./UserProfileSummary";

/**
 *
 * @export
 * @interface CommCoachSingleEntryRelatedDetail
 */
export interface CommCoachSingleEntryRelatedDetail {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof CommCoachSingleEntryRelatedDetail
   */
  readonly guid?: string;
  /**
   * The task ID for the Celery task that was enqueued to perform the communication coach flow.
   * @type {string}
   * @memberof CommCoachSingleEntryRelatedDetail
   */
  task_id?: string | null;
  /**
   * Whether or not the referenced Celery task has been completed.
   * @type {boolean}
   * @memberof CommCoachSingleEntryRelatedDetail
   */
  is_task_completed?: boolean;
  /**
   * The topic of conversation that the user wants guidance on.
   * @type {string}
   * @memberof CommCoachSingleEntryRelatedDetail
   */
  conversation?: string | null;
  /**
   * The goal for having the conversation.
   * @type {string}
   * @memberof CommCoachSingleEntryRelatedDetail
   */
  goal?: string | null;
  /**
   * The concerns that the user has about the conversation.
   * @type {string}
   * @memberof CommCoachSingleEntryRelatedDetail
   */
  concerns?: string | null;
  /**
   * The relationship that the asking user has with the about user.
   * @type {string}
   * @memberof CommCoachSingleEntryRelatedDetail
   */
  relationship?: string | null;
  /**
   * Whether or not the user has submitted the communication coach flow for processing.
   * @type {boolean}
   * @memberof CommCoachSingleEntryRelatedDetail
   */
  submitted?: boolean;
  /**
   * The time at which this record was created.
   * @type {Date}
   * @memberof CommCoachSingleEntryRelatedDetail
   */
  readonly time_created?: Date;
  /**
   *
   * @type {UserProfileSummary}
   * @memberof CommCoachSingleEntryRelatedDetail
   */
  asking_user?: UserProfileSummary;
  /**
   *
   * @type {UserProfileSummary}
   * @memberof CommCoachSingleEntryRelatedDetail
   */
  about_user?: UserProfileSummary;
}

export function CommCoachSingleEntryRelatedDetailFromJSON(
  json: any
): CommCoachSingleEntryRelatedDetail {
  return CommCoachSingleEntryRelatedDetailFromJSONTyped(json, false);
}

export function CommCoachSingleEntryRelatedDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommCoachSingleEntryRelatedDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    task_id: !exists(json, "task_id") ? undefined : json["task_id"],
    is_task_completed: !exists(json, "is_task_completed")
      ? undefined
      : json["is_task_completed"],
    conversation: !exists(json, "conversation")
      ? undefined
      : json["conversation"],
    goal: !exists(json, "goal") ? undefined : json["goal"],
    concerns: !exists(json, "concerns") ? undefined : json["concerns"],
    relationship: !exists(json, "relationship")
      ? undefined
      : json["relationship"],
    submitted: !exists(json, "submitted") ? undefined : json["submitted"],
    time_created: !exists(json, "time_created")
      ? undefined
      : new Date(json["time_created"]),
    asking_user: !exists(json, "asking_user")
      ? undefined
      : UserProfileSummaryFromJSON(json["asking_user"]),
    about_user: !exists(json, "about_user")
      ? undefined
      : UserProfileSummaryFromJSON(json["about_user"]),
  };
}

export function CommCoachSingleEntryRelatedDetailToJSON(
  value?: CommCoachSingleEntryRelatedDetail | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    task_id: value.task_id,
    is_task_completed: value.is_task_completed,
    conversation: value.conversation,
    goal: value.goal,
    concerns: value.concerns,
    relationship: value.relationship,
    submitted: value.submitted,
    asking_user: UserProfileSummaryToJSON(value.asking_user),
    about_user: UserProfileSummaryToJSON(value.about_user),
  };
}
