import React, { FC, PropsWithChildren } from "react";
import { classNames } from "../../../../util/strings";

export type NavigationItem = {
  name: string;
  icon: FC<any>;
};

type NavigationItemWithCurrent = NavigationItem & {
  current: boolean;
};

type SidebarNavScreenProps = {
  items: NavigationItemWithCurrent[];
  onNavItemClicked: (item: NavigationItem) => void;
};

const SidebarNavScreenComponent = (
  props: PropsWithChildren<SidebarNavScreenProps>
) => {
  const { items, onNavItemClicked, children } = props;

  return (
    <div className="lg:flex lg:gap-x-16 lg:px-8">
      <aside className="flex overflow-x-auto border-b border-m-dark-gray/20 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:pt-16">
        <nav className="flex-none px-4 sm:px-6 lg:px-0">
          <ul className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
            {items.map((item) => (
              <li key={item.name}>
                <button
                  type="button"
                  onClick={() => onNavItemClicked(item)}
                  className={classNames(
                    item.current
                      ? "bg-m-light-gray/30 text-primary"
                      : "text-m-dark-gray hover:text-primary hover:bg-primary-light",
                    "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold w-full"
                  )}
                >
                  <item.icon
                    className={classNames(
                      item.current
                        ? "text-primary"
                        : "text-m-dark-gray group-hover:text-primary",
                      "h-6 w-6 shrink-0"
                    )}
                    aria-hidden="true"
                  />
                  {item.name}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </aside>
      <main className="p-4 pb-16 sm:px-6 lg:flex-auto lg:px-0">
        <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
          {children}
        </div>
      </main>
    </div>
  );
};

export default SidebarNavScreenComponent;
