import React, { ReactElement, useMemo } from "react";
import { UserProfileDetail } from "../../../api/models";
import MTooltip from "../../ui/MTooltip";
import { classNames } from "../../../util/strings";
import MDivider from "../../ui/MDivider";
import MPlusButton from "../../ui/buttons/MPlusButton";
import MEditButton from "../../ui/buttons/MEditButton";
import { useBusyWatcher } from "../../../util/hooks";
import { commentText } from "../../../util/style";

type vitalEntry = {
  icon: string;
  title: string;
  tooltip: string;
};

type VitalsSectionProps = {
  user: UserProfileDetail;
  className?: string;
  editUrl?: string | null;
  bgColor?: string | null;
};

const VitalsSectionComponent = (props: VitalsSectionProps) => {
  const { user, className, editUrl, bgColor } = props;

  const getVitals = (): vitalEntry[] => {
    const toReturn: vitalEntry[] = [];
    if (user.location) {
      toReturn.push({
        icon: "fa-solid fa-map-marker-alt",
        title: user.location,
        tooltip: "location",
      });
    }
    if (user.relationship) {
      toReturn.push({
        icon: "fa-solid fa-heart",
        title: user.relationship,
        tooltip: "relationship status",
      });
    }
    if (user.beliefs) {
      toReturn.push({
        icon: "fa-solid fa-pray",
        title: user.beliefs,
        tooltip: "religious beliefs",
      });
    }
    if (user.sexual_orientation) {
      toReturn.push({
        icon: "fa-solid fa-venus-mars",
        title: user.sexual_orientation,
        tooltip: "sexual orientation",
      });
    }
    if (user.ethnicity) {
      toReturn.push({
        icon: "fa-solid fa-house",
        title: user.ethnicity,
        tooltip: "ethnicity",
      });
    }
    if (user.kids) {
      toReturn.push({
        icon: "fa-solid fa-child",
        title: `${user.kids} kids`,
        tooltip: "number of kids",
      });
    }
    if (user.mbti) {
      toReturn.push({
        icon: "fa-solid fa-brain",
        title: user.mbti,
        tooltip: "myers-briggs type indicator",
      });
    }
    if (user.love_language) {
      toReturn.push({
        icon: "fa-solid fa-heart-pulse",
        title: user.love_language,
        tooltip: "love language",
      });
    }
    if (user.enneagram) {
      toReturn.push({
        icon: "fa-solid fa-dice-d20",
        title: user.enneagram,
        tooltip: "enneagram",
      });
    }
    if (user.zodiac) {
      toReturn.push({
        icon: "fa-solid fa-star",
        title: user.zodiac,
        tooltip: "zodiac sign",
      });
    }
    if (user.human_design) {
      toReturn.push({
        icon: "fa-solid fa-atom",
        title: user.human_design,
        tooltip: "human design",
      });
    }
    return toReturn;
  };

  const getVitalsElements = (): ReactElement[] => {
    const toProcess = [...getVitals()].sort(
      (a, b) => a.title.length - b.title.length
    );
    const toReturn: ReactElement[] = [];
    while (toProcess.length > 0) {
      let takeTwo = true;
      for (let i = 0; i < 2; i += 1) {
        if (toProcess[i]?.title.length > 13) {
          takeTwo = false;
        }
      }
      if (takeTwo && toProcess.length > 1) {
        const elements = toProcess.splice(0, 2);
        toReturn.push(
          <div
            key={elements[0].tooltip + elements[1].tooltip}
            className="w-full grid grid-cols-2 gap-2 text-sm sm:text-base"
          >
            <div className="flex flex-row items-center">
              <div
                data-tooltip-id="vitals-tooltip"
                data-tooltip-content={elements[0].tooltip}
                className="w-6"
              >
                <i className={elements[0].icon} />
              </div>
              <div>{elements[0].title}</div>
            </div>
            <div className="flex flex-row items-center">
              <div
                data-tooltip-id="vitals-tooltip"
                data-tooltip-content={elements[1].tooltip}
                className="w-6"
              >
                <i className={elements[1].icon} />
              </div>
              <div>{elements[1].title}</div>
            </div>
          </div>
        );
      } else {
        const element = toProcess.splice(0, 1)[0];
        toReturn.push(
          <div
            key={element.tooltip}
            className="flex flex-row items-center w-full text-sm sm:text-base"
          >
            <div
              data-tooltip-id="vitals-tooltip"
              data-tooltip-content={element.tooltip}
              className="w-6"
            >
              <i className={element.icon} />
            </div>
            <div>{element.title}</div>
          </div>
        );
      }
    }
    return toReturn;
  };

  const hasVitals = useMemo(() => getVitals().length > 0, [user]);
  const elements = useMemo(() => getVitalsElements(), [user]);
  const [busy, _] = useBusyWatcher();

  const getAddButton = (): ReactElement => {
    if (!hasVitals) {
      return <MPlusButton href={editUrl!} disabled={busy} text={null} />;
    }
    return <MEditButton href={editUrl!} disabled={busy} text={null} />;
  };

  return (
    <div className={classNames("flex flex-col gap-2 text-m-black", className)}>
      <MDivider
        title="vitals"
        bgColor={bgColor}
        button={editUrl ? getAddButton() : null}
      />
      {elements}
      {!hasVitals && (
        <div className={classNames(commentText, "my-3")}>
          you haven't added any vitals to your profile yet
        </div>
      )}
      <MTooltip id="vitals-tooltip" place="bottom" />
    </div>
  );
};

VitalsSectionComponent.defaultProps = {
  className: undefined,
  editUrl: null,
  bgColor: null,
};

export default VitalsSectionComponent;
