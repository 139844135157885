import React, { useEffect, useMemo, useState } from "react";
import MInput from "../../../ui/MInput";
import FormScreen from "../../../ui/screens/FormScreen";
import { useBusyWatcher } from "../../../../util/hooks";
import { Step } from "../../../ui/MStepsCircles";
import { GetBasicInfo, SetBasicInfo } from "../../../../api/models";
import MDateInput from "../../../ui/MDateInput";

type BasicScreenProps = {
  onNextClicked: (data: SetBasicInfo) => void;
  errors: { [key: string]: string[] };
  basics: GetBasicInfo;
  steps?: Step[] | null;
  formTitle: string;
  formComment?: string | null;
};

const BasicScreenComponent = (props: BasicScreenProps) => {
  const {
    onNextClicked,
    basics,
    steps: stepsInput,
    errors,
    formTitle,
    formComment,
  } = props;

  const [name, setName] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [employer, setEmployer] = useState<string>("");
  const [dob, setDob] = useState<Date | null>(null);
  const [busy, _] = useBusyWatcher();
  const steps = useMemo(() => stepsInput, []);

  const canSubmit = (): boolean => {
    if (name === "") {
      return false;
    }
    if (location === "") {
      return false;
    }
    if (!dob) {
      return false;
    }
    return true;
  };

  const submit = () => {
    onNextClicked({
      name,
      location,
      title,
      employer,
      dob: dob!,
    });
  };

  useEffect(() => {
    setName(basics.name || "");
    setLocation(basics.location || "");
    setTitle(basics.title || "");
    setEmployer(basics.employer || "");
    setDob(basics.dob);
  }, [basics]);

  return (
    <FormScreen
      title={formTitle}
      comment={formComment}
      nextDisabled={busy || !canSubmit()}
      onNextClicked={() => canSubmit() && submit()}
      steps={steps ? { steps } : null}
    >
      <MInput
        label="name"
        type="text"
        placeholder="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        disabled={busy}
        onEnterPressed={() => canSubmit() && submit()}
        errors={errors.name}
        required
      />
      <MInput
        label="location"
        type="text"
        placeholder="city, state"
        value={location}
        onChange={(e) => setLocation(e.target.value)}
        disabled={busy}
        onEnterPressed={() => canSubmit() && submit()}
        errors={errors.location}
        required
      />
      <MInput
        label="title"
        type="text"
        placeholder="cool person"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        disabled={busy}
        onEnterPressed={() => canSubmit() && submit()}
        errors={errors.title}
      />
      <MInput
        label="employer"
        type="text"
        placeholder="ACME, co."
        value={employer}
        onChange={(e) => setEmployer(e.target.value)}
        disabled={busy}
        onEnterPressed={() => canSubmit() && submit()}
        errors={errors.employer}
      />
      <MDateInput
        onDateChanged={setDob}
        errors={errors.dob}
        disabled={busy}
        label="date of birth"
        dateValue={dob}
        onEnterPressed={() => canSubmit() && submit()}
        required
      />
    </FormScreen>
  );
};

BasicScreenComponent.defaultProps = {
  steps: null,
  formComment: null,
};

export default BasicScreenComponent;
