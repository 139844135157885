/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  EmployerRoleDetail,
  EmployerRoleDetailFromJSON,
  EmployerRoleDetailFromJSONTyped,
  EmployerRoleDetailToJSON,
} from "./EmployerRoleDetail";

/**
 *
 * @export
 * @interface EmployerRelatedDetail
 */
export interface EmployerRelatedDetail {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof EmployerRelatedDetail
   */
  readonly guid?: string;
  /**
   * The name of the employer.
   * @type {string}
   * @memberof EmployerRelatedDetail
   */
  name?: string | null;
  /**
   * The title that the user had in association with this employer
   * @type {string}
   * @memberof EmployerRelatedDetail
   */
  title?: string | null;
  /**
   * The date on which the user started working for this employer
   * @type {Date}
   * @memberof EmployerRelatedDetail
   */
  date_started?: Date | null;
  /**
   * The date at which the user ended working for this employer
   * @type {Date}
   * @memberof EmployerRelatedDetail
   */
  date_ended?: Date | null;
  /**
   *
   * @type {Array<EmployerRoleDetail>}
   * @memberof EmployerRelatedDetail
   */
  readonly roles?: Array<EmployerRoleDetail>;
}

export function EmployerRelatedDetailFromJSON(
  json: any
): EmployerRelatedDetail {
  return EmployerRelatedDetailFromJSONTyped(json, false);
}

export function EmployerRelatedDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EmployerRelatedDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    name: !exists(json, "name") ? undefined : json["name"],
    title: !exists(json, "title") ? undefined : json["title"],
    date_started: !exists(json, "date_started")
      ? undefined
      : json["date_started"] === null
      ? null
      : new Date(json["date_started"]),
    date_ended: !exists(json, "date_ended")
      ? undefined
      : json["date_ended"] === null
      ? null
      : new Date(json["date_ended"]),
    roles: !exists(json, "roles")
      ? undefined
      : (json["roles"] as Array<any>).map(EmployerRoleDetailFromJSON),
  };
}

export function EmployerRelatedDetailToJSON(
  value?: EmployerRelatedDetail | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    title: value.title,
    date_started:
      value.date_started === undefined
        ? undefined
        : value.date_started === null
        ? null
        : value.date_started.toISOString().substr(0, 10),
    date_ended:
      value.date_ended === undefined
        ? undefined
        : value.date_ended === null
        ? null
        : value.date_ended.toISOString().substr(0, 10),
  };
}
