/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GroupQuestionAndAnswerRequest
 */
export interface GroupQuestionAndAnswerRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof GroupQuestionAndAnswerRequest
   */
  user_guids?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof GroupQuestionAndAnswerRequest
   */
  group_guid?: string;
  /**
   *
   * @type {string}
   * @memberof GroupQuestionAndAnswerRequest
   */
  question: string;
  /**
   *
   * @type {string}
   * @memberof GroupQuestionAndAnswerRequest
   */
  model_name?: GroupQuestionAndAnswerRequestModelNameEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum GroupQuestionAndAnswerRequestModelNameEnum {
  gpt_4 = "gpt-4",
  gpt_3_5_turbo_1106 = "gpt-3.5-turbo-1106",
  claude_3_opus_20240229 = "claude-3-opus-20240229",
  claude_3_sonnet_20240229 = "claude-3-sonnet-20240229",
}

export function GroupQuestionAndAnswerRequestFromJSON(
  json: any
): GroupQuestionAndAnswerRequest {
  return GroupQuestionAndAnswerRequestFromJSONTyped(json, false);
}

export function GroupQuestionAndAnswerRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GroupQuestionAndAnswerRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    user_guids: !exists(json, "user_guids") ? undefined : json["user_guids"],
    group_guid: !exists(json, "group_guid") ? undefined : json["group_guid"],
    question: json["question"],
    model_name: !exists(json, "model_name") ? undefined : json["model_name"],
  };
}

export function GroupQuestionAndAnswerRequestToJSON(
  value?: GroupQuestionAndAnswerRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    user_guids: value.user_guids,
    group_guid: value.group_guid,
    question: value.question,
    model_name: value.model_name,
  };
}
