/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  UserProfileSummary,
  UserProfileSummaryFromJSON,
  UserProfileSummaryFromJSONTyped,
  UserProfileSummaryToJSON,
} from "./UserProfileSummary";

/**
 *
 * @export
 * @interface GroupMembers
 */
export interface GroupMembers {
  /**
   *
   * @type {UserProfileSummary}
   * @memberof GroupMembers
   */
  summary: UserProfileSummary;
}

export function GroupMembersFromJSON(json: any): GroupMembers {
  return GroupMembersFromJSONTyped(json, false);
}

export function GroupMembersFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GroupMembers {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    summary: UserProfileSummaryFromJSON(json["summary"]),
  };
}

export function GroupMembersToJSON(value?: GroupMembers | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    summary: UserProfileSummaryToJSON(value.summary),
  };
}
