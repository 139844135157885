import React from "react";
import SettingsSection from "../../ui/screens/sidebarnav/SidebarSection";
import qrUrl from "../../../assets/images/generate-pass.qr.png";

const SettingsExportsScreen = () => (
  <SettingsSection
    title="Apple Wallet"
    description="If you have an iOS device, you can add a pass to your Apple Wallet that will link to your Manual. From your iOS device, either scan the QR code below or click the link to create your pass."
  >
    <div className="flex items-center">
      <img src={qrUrl} alt="QR Code" className="w-48 mr-4" />
      <div>
        <a
          href="/passes/apple-wallet/generate"
          className="underline text-primary"
        >
          Add pass to Apple Wallet
        </a>
      </div>
    </div>
  </SettingsSection>
);

export default SettingsExportsScreen;
