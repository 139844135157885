/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SearchValidTagsResponse
 */
export interface SearchValidTagsResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof SearchValidTagsResponse
   */
  tags: Array<string>;
}

export function SearchValidTagsResponseFromJSON(
  json: any
): SearchValidTagsResponse {
  return SearchValidTagsResponseFromJSONTyped(json, false);
}

export function SearchValidTagsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SearchValidTagsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tags: json["tags"],
  };
}

export function SearchValidTagsResponseToJSON(
  value?: SearchValidTagsResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    tags: value.tags,
  };
}
