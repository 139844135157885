/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  CreateEmployerRequest,
  CreateEmployerRequestFromJSON,
  CreateEmployerRequestToJSON,
  CreateRoleRequest,
  CreateRoleRequestFromJSON,
  CreateRoleRequestToJSON,
  DeleteEmployerRequest,
  DeleteEmployerRequestFromJSON,
  DeleteEmployerRequestToJSON,
  DeleteRoleRequest,
  DeleteRoleRequestFromJSON,
  DeleteRoleRequestToJSON,
  EmployerRelatedDetailSerializer,
  EmployerRelatedDetailSerializerFromJSON,
  EmployerRelatedDetailSerializerToJSON,
  EmployerRoleDetailSerializer,
  EmployerRoleDetailSerializerFromJSON,
  EmployerRoleDetailSerializerToJSON,
  EmptyResponseWrapper,
  EmptyResponseWrapperFromJSON,
  EmptyResponseWrapperToJSON,
  ListEmployersResponseSerializer,
  ListEmployersResponseSerializerFromJSON,
  ListEmployersResponseSerializerToJSON,
  UpdateEmployerRequest,
  UpdateEmployerRequestFromJSON,
  UpdateEmployerRequestToJSON,
  UpdateRoleRequest,
  UpdateRoleRequestFromJSON,
  UpdateRoleRequestToJSON,
} from "../models";

export interface CreateEmployerOperationRequest {
  data: CreateEmployerRequest;
}

export interface CreateEmployerRoleRequest {
  data: CreateRoleRequest;
}

export interface DeleteEmployerOperationRequest {
  data: DeleteEmployerRequest;
}

export interface DeleteEmployerRoleRequest {
  data: DeleteRoleRequest;
}

export interface GetEmployerRequest {
  employer: string;
}

export interface ListEmployersRequest {
  offset?: number;
  limit?: number;
}

export interface UpdateEmployerOperationRequest {
  data: UpdateEmployerRequest;
}

export interface UpdateEmployerRoleRequest {
  data: UpdateRoleRequest;
}

/**
 *
 */
export class WorkHistoryApi extends runtime.BaseAPI {
  /**
   * Create an employer for the requesting user.
   */
  async createEmployerRaw(
    requestParameters: CreateEmployerOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmployerRelatedDetailSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling createEmployer."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/work-history/create-employer`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CreateEmployerRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmployerRelatedDetailSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Create an employer for the requesting user.
   */
  async createEmployer(
    requestParameters: CreateEmployerOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmployerRelatedDetailSerializer> {
    const response = await this.createEmployerRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create a role for the employer specified by a given ID.
   */
  async createEmployerRoleRaw(
    requestParameters: CreateEmployerRoleRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmployerRoleDetailSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling createEmployerRole."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/work-history/create-role`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CreateRoleRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmployerRoleDetailSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Create a role for the employer specified by a given ID.
   */
  async createEmployerRole(
    requestParameters: CreateEmployerRoleRequest,
    initOverrides?: RequestInit
  ): Promise<EmployerRoleDetailSerializer> {
    const response = await this.createEmployerRoleRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Delete the employer specified by a given ID.
   */
  async deleteEmployerRaw(
    requestParameters: DeleteEmployerOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling deleteEmployer."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/work-history/delete-employer`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: DeleteEmployerRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Delete the employer specified by a given ID.
   */
  async deleteEmployer(
    requestParameters: DeleteEmployerOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.deleteEmployerRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Delete the role specified by a given ID.
   */
  async deleteEmployerRoleRaw(
    requestParameters: DeleteEmployerRoleRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling deleteEmployerRole."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/work-history/delete-role`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: DeleteRoleRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Delete the role specified by a given ID.
   */
  async deleteEmployerRole(
    requestParameters: DeleteEmployerRoleRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.deleteEmployerRoleRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get the employer specified by a given ID.
   */
  async getEmployerRaw(
    requestParameters: GetEmployerRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmployerRelatedDetailSerializer>> {
    if (
      requestParameters.employer === null ||
      requestParameters.employer === undefined
    ) {
      throw new runtime.RequiredError(
        "employer",
        "Required parameter requestParameters.employer was null or undefined when calling getEmployer."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.employer !== undefined) {
      queryParameters["employer"] = requestParameters.employer;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/work-history/get-employer`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmployerRelatedDetailSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the employer specified by a given ID.
   */
  async getEmployer(
    requestParameters: GetEmployerRequest,
    initOverrides?: RequestInit
  ): Promise<EmployerRelatedDetailSerializer> {
    const response = await this.getEmployerRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * List all the employers for the requesting user.
   */
  async listEmployersRaw(
    requestParameters: ListEmployersRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ListEmployersResponseSerializer>> {
    const queryParameters: any = {};

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/work-history/list-employers`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ListEmployersResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * List all the employers for the requesting user.
   */
  async listEmployers(
    requestParameters: ListEmployersRequest = {},
    initOverrides?: RequestInit
  ): Promise<ListEmployersResponseSerializer> {
    const response = await this.listEmployersRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update the employer specified by a given ID.
   */
  async updateEmployerRaw(
    requestParameters: UpdateEmployerOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmployerRelatedDetailSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling updateEmployer."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/work-history/update-employer`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateEmployerRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmployerRelatedDetailSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Update the employer specified by a given ID.
   */
  async updateEmployer(
    requestParameters: UpdateEmployerOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmployerRelatedDetailSerializer> {
    const response = await this.updateEmployerRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update the role specified by a given ID.
   */
  async updateEmployerRoleRaw(
    requestParameters: UpdateEmployerRoleRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmployerRoleDetailSerializer>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling updateEmployerRole."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/work-history/update-role`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateRoleRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmployerRoleDetailSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Update the role specified by a given ID.
   */
  async updateEmployerRole(
    requestParameters: UpdateEmployerRoleRequest,
    initOverrides?: RequestInit
  ): Promise<EmployerRoleDetailSerializer> {
    const response = await this.updateEmployerRoleRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
