/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface EmployerRoleDetail
 */
export interface EmployerRoleDetail {
  /**
   * A more or less 'secondary' primary key to reference this DB object.
   * @type {string}
   * @memberof EmployerRoleDetail
   */
  readonly guid?: string;
  /**
   * The title of the role that the user held
   * @type {string}
   * @memberof EmployerRoleDetail
   */
  title?: string | null;
  /**
   * The responsibilities the user had in association with this role
   * @type {string}
   * @memberof EmployerRoleDetail
   */
  responsibilities?: string | null;
  /**
   * The impact that the user had in association with this role
   * @type {string}
   * @memberof EmployerRoleDetail
   */
  impact?: string | null;
  /**
   * The date on which the user started this role
   * @type {Date}
   * @memberof EmployerRoleDetail
   */
  date_started?: Date | null;
  /**
   * The date at which the user ended this role
   * @type {Date}
   * @memberof EmployerRoleDetail
   */
  date_ended?: Date | null;
}

export function EmployerRoleDetailFromJSON(json: any): EmployerRoleDetail {
  return EmployerRoleDetailFromJSONTyped(json, false);
}

export function EmployerRoleDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EmployerRoleDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    guid: !exists(json, "guid") ? undefined : json["guid"],
    title: !exists(json, "title") ? undefined : json["title"],
    responsibilities: !exists(json, "responsibilities")
      ? undefined
      : json["responsibilities"],
    impact: !exists(json, "impact") ? undefined : json["impact"],
    date_started: !exists(json, "date_started")
      ? undefined
      : json["date_started"] === null
      ? null
      : new Date(json["date_started"]),
    date_ended: !exists(json, "date_ended")
      ? undefined
      : json["date_ended"] === null
      ? null
      : new Date(json["date_ended"]),
  };
}

export function EmployerRoleDetailToJSON(
  value?: EmployerRoleDetail | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    title: value.title,
    responsibilities: value.responsibilities,
    impact: value.impact,
    date_started:
      value.date_started === undefined
        ? undefined
        : value.date_started === null
        ? null
        : value.date_started.toISOString().substr(0, 10),
    date_ended:
      value.date_ended === undefined
        ? undefined
        : value.date_ended === null
        ? null
        : value.date_ended.toISOString().substr(0, 10),
  };
}
