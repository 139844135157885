/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";

type DropDownProps = {
  items: string[];
  itemSelection: (item: string) => void;
};

const DropDown = (props: DropDownProps) => {
  const { items, itemSelection } = props;

  const [showDropDown, setShowDropDown] = useState<boolean>(false);

  const onClickHandler = (item: string): void => {
    itemSelection(item);
  };

  useEffect(() => {
    setShowDropDown(showDropDown);
  }, [showDropDown]);

  return (
    <div className={showDropDown ? "dropdown" : "dropdown active"}>
      {items.map((item: string) => (
        <p
          key={item}
          onClick={(): void => {
            onClickHandler(item);
          }}
        >
          {item}
        </p>
      ))}
    </div>
  );
};

export default DropDown;
