/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  GroupCollab,
  GroupCollabFromJSON,
  GroupCollabFromJSONTyped,
  GroupCollabToJSON,
} from "./GroupCollab";

/**
 *
 * @export
 * @interface ListGroupCollabsResponse
 */
export interface ListGroupCollabsResponse {
  /**
   *
   * @type {Array<GroupCollab>}
   * @memberof ListGroupCollabsResponse
   */
  group_collabs: Array<GroupCollab>;
}

export function ListGroupCollabsResponseFromJSON(
  json: any
): ListGroupCollabsResponse {
  return ListGroupCollabsResponseFromJSONTyped(json, false);
}

export function ListGroupCollabsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ListGroupCollabsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    group_collabs: (json["group_collabs"] as Array<any>).map(
      GroupCollabFromJSON
    ),
  };
}

export function ListGroupCollabsResponseToJSON(
  value?: ListGroupCollabsResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    group_collabs: (value.group_collabs as Array<any>).map(GroupCollabToJSON),
  };
}
