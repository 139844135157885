/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GetTaglineResponse
 */
export interface GetTaglineResponse {
  /**
   *
   * @type {string}
   * @memberof GetTaglineResponse
   */
  bio: string | null;
  /**
   *
   * @type {string}
   * @memberof GetTaglineResponse
   */
  headline: string | null;
  /**
   *
   * @type {boolean}
   * @memberof GetTaglineResponse
   */
  tagline_ever_set: boolean;
}

export function GetTaglineResponseFromJSON(json: any): GetTaglineResponse {
  return GetTaglineResponseFromJSONTyped(json, false);
}

export function GetTaglineResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetTaglineResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    bio: json["bio"],
    headline: json["headline"],
    tagline_ever_set: json["tagline_ever_set"],
  };
}

export function GetTaglineResponseToJSON(
  value?: GetTaglineResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    bio: value.bio,
    headline: value.headline,
    tagline_ever_set: value.tagline_ever_set,
  };
}
