/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  PromptRelatedDetail,
  PromptRelatedDetailFromJSON,
  PromptRelatedDetailFromJSONTyped,
  PromptRelatedDetailToJSON,
} from "./PromptRelatedDetail";
import {
  PromptResponseDetail,
  PromptResponseDetailFromJSON,
  PromptResponseDetailFromJSONTyped,
  PromptResponseDetailToJSON,
} from "./PromptResponseDetail";

/**
 *
 * @export
 * @interface PromptWithResponse
 */
export interface PromptWithResponse {
  /**
   *
   * @type {PromptRelatedDetail}
   * @memberof PromptWithResponse
   */
  prompt: PromptRelatedDetail;
  /**
   *
   * @type {PromptResponseDetail}
   * @memberof PromptWithResponse
   */
  response?: PromptResponseDetail | null;
}

export function PromptWithResponseFromJSON(json: any): PromptWithResponse {
  return PromptWithResponseFromJSONTyped(json, false);
}

export function PromptWithResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PromptWithResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    prompt: PromptRelatedDetailFromJSON(json["prompt"]),
    response: !exists(json, "response")
      ? undefined
      : PromptResponseDetailFromJSON(json["response"]),
  };
}

export function PromptWithResponseToJSON(
  value?: PromptWithResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    prompt: PromptRelatedDetailToJSON(value.prompt),
    response: PromptResponseDetailToJSON(value.response),
  };
}
