import React from "react";
import BaseDialog from "../../dialogs/BaseDialog";
import MNextButton from "../buttons/MNextButton";

type VisibilityExplanationDialogProps = {
  show: boolean;
  onClose: () => void;
};

const VisibilityExplanationDialogComponent = (
  props: VisibilityExplanationDialogProps
) => {
  const { show, onClose } = props;

  return (
    <BaseDialog title="Question Visibility" show={show} onClose={onClose}>
      <div className="max-w-lg flex flex-col gap-4 text-left text-sm">
        <div>
          Manual is powered by the responses that you provide to questions we
          ask. Not every question that we ask can be displayed on your profile.
        </div>
        <div>
          <span className="font-bold">
            Questions that we use as input to our assessments
          </span>{" "}
          cannot be displayed on your profile.
        </div>
        <div>
          <span className="font-bold">All other questions</span> can
          (optionally) be displayed on your profile. When a question{" "}
          <span className="italic">can</span> be displayed on your profile,
          we'll give you the option to display it.
        </div>
        <div className="flex flex-row justify-end text-base">
          <MNextButton kind="primary" text="done" onClick={onClose} />
        </div>
      </div>
    </BaseDialog>
  );
};

export default VisibilityExplanationDialogComponent;
