import React, { ReactNode } from "react";
import { CommonRowProps } from "./common";
import SettingsLabelRow from "./SidebarLabelRow";

type SidebarButtonRowProps = CommonRowProps & {
  button: ReactNode;
};

const SidebarButtonRowComponent = (props: SidebarButtonRowProps) => {
  const { label, button, tooltip } = props;

  return <SettingsLabelRow label={label} tooltip={tooltip} end={button} />;
};

export default SidebarButtonRowComponent;
