/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  EmptyResponseWrapper,
  EmptyResponseWrapperFromJSON,
  EmptyResponseWrapperToJSON,
  GetNotificationCountsResponseSerializer,
  GetNotificationCountsResponseSerializerFromJSON,
  GetNotificationCountsResponseSerializerToJSON,
  ListNotificationsResponseSerializer,
  ListNotificationsResponseSerializerFromJSON,
  ListNotificationsResponseSerializerToJSON,
  MarkNotificationsAsClearedRequest,
  MarkNotificationsAsClearedRequestFromJSON,
  MarkNotificationsAsClearedRequestToJSON,
  MarkNotificationsAsNotClearedRequest,
  MarkNotificationsAsNotClearedRequestFromJSON,
  MarkNotificationsAsNotClearedRequestToJSON,
  MarkNotificationsAsViewedRequest,
  MarkNotificationsAsViewedRequestFromJSON,
  MarkNotificationsAsViewedRequestToJSON,
  UserNotificationRelatedDetailSerializer,
  UserNotificationRelatedDetailSerializerFromJSON,
  UserNotificationRelatedDetailSerializerToJSON,
} from "../models";

export interface GetNotificationRequest {
  notif: string;
}

export interface ListNotificationsRequest {
  offset?: number;
  limit?: number;
}

export interface MarkNotificationsAsClearedOperationRequest {
  data: MarkNotificationsAsClearedRequest;
}

export interface MarkNotificationsAsNotClearedOperationRequest {
  data: MarkNotificationsAsNotClearedRequest;
}

export interface MarkNotificationsAsViewedOperationRequest {
  data: MarkNotificationsAsViewedRequest;
}

/**
 *
 */
export class NotifsApi extends runtime.BaseAPI {
  /**
   * Get the notification with the specified ID, if it exists.
   */
  async getNotificationRaw(
    requestParameters: GetNotificationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<UserNotificationRelatedDetailSerializer>> {
    if (
      requestParameters.notif === null ||
      requestParameters.notif === undefined
    ) {
      throw new runtime.RequiredError(
        "notif",
        "Required parameter requestParameters.notif was null or undefined when calling getNotification."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.notif !== undefined) {
      queryParameters["notif"] = requestParameters.notif;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/notifs/get`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserNotificationRelatedDetailSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the notification with the specified ID, if it exists.
   */
  async getNotification(
    requestParameters: GetNotificationRequest,
    initOverrides?: RequestInit
  ): Promise<UserNotificationRelatedDetailSerializer> {
    const response = await this.getNotificationRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get the notification counts for the given user.
   */
  async getNotificationCountsRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetNotificationCountsResponseSerializer>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/notifs/counts`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetNotificationCountsResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get the notification counts for the given user.
   */
  async getNotificationCounts(
    initOverrides?: RequestInit
  ): Promise<GetNotificationCountsResponseSerializer> {
    const response = await this.getNotificationCountsRaw(initOverrides);
    return await response.value();
  }

  /**
   * Get a list of notifications for the given user.
   */
  async listNotificationsRaw(
    requestParameters: ListNotificationsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ListNotificationsResponseSerializer>> {
    const queryParameters: any = {};

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/notifs/list`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ListNotificationsResponseSerializerFromJSON(jsonValue)
    );
  }

  /**
   * Get a list of notifications for the given user.
   */
  async listNotifications(
    requestParameters: ListNotificationsRequest = {},
    initOverrides?: RequestInit
  ): Promise<ListNotificationsResponseSerializer> {
    const response = await this.listNotificationsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Mark the referenced notifications as having been cleared by the given user.
   */
  async markNotificationsAsClearedRaw(
    requestParameters: MarkNotificationsAsClearedOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling markNotificationsAsCleared."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/notifs/mark-as-cleared`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: MarkNotificationsAsClearedRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Mark the referenced notifications as having been cleared by the given user.
   */
  async markNotificationsAsCleared(
    requestParameters: MarkNotificationsAsClearedOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.markNotificationsAsClearedRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Mark the referenced notifications as not having been cleared by the given user.
   */
  async markNotificationsAsNotClearedRaw(
    requestParameters: MarkNotificationsAsNotClearedOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling markNotificationsAsNotCleared."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/notifs/mark-as-not-cleared`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: MarkNotificationsAsNotClearedRequestToJSON(
          requestParameters.data
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Mark the referenced notifications as not having been cleared by the given user.
   */
  async markNotificationsAsNotCleared(
    requestParameters: MarkNotificationsAsNotClearedOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.markNotificationsAsNotClearedRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Mark the referenced notifications as having been viewed by the given user.
   */
  async markNotificationsAsViewedRaw(
    requestParameters: MarkNotificationsAsViewedOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmptyResponseWrapper>> {
    if (
      requestParameters.data === null ||
      requestParameters.data === undefined
    ) {
      throw new runtime.RequiredError(
        "data",
        "Required parameter requestParameters.data was null or undefined when calling markNotificationsAsViewed."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " +
        btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/notifs/mark-as-viewed`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: MarkNotificationsAsViewedRequestToJSON(requestParameters.data),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmptyResponseWrapperFromJSON(jsonValue)
    );
  }

  /**
   * Mark the referenced notifications as having been viewed by the given user.
   */
  async markNotificationsAsViewed(
    requestParameters: MarkNotificationsAsViewedOperationRequest,
    initOverrides?: RequestInit
  ): Promise<EmptyResponseWrapper> {
    const response = await this.markNotificationsAsViewedRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
