/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SubmitPromptResponseRequest
 */
export interface SubmitPromptResponseRequest {
  /**
   *
   * @type {string}
   * @memberof SubmitPromptResponseRequest
   */
  prompt: string;
  /**
   *
   * @type {string}
   * @memberof SubmitPromptResponseRequest
   */
  response: string;
  /**
   *
   * @type {string}
   * @memberof SubmitPromptResponseRequest
   */
  questionnaire?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SubmitPromptResponseRequest
   */
  publish?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof SubmitPromptResponseRequest
   */
  presentation_layer?: string | null;
}

export function SubmitPromptResponseRequestFromJSON(
  json: any
): SubmitPromptResponseRequest {
  return SubmitPromptResponseRequestFromJSONTyped(json, false);
}

export function SubmitPromptResponseRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SubmitPromptResponseRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    prompt: json["prompt"],
    response: json["response"],
    questionnaire: !exists(json, "questionnaire")
      ? undefined
      : json["questionnaire"],
    publish: !exists(json, "publish") ? undefined : json["publish"],
    presentation_layer: !exists(json, "presentation_layer")
      ? undefined
      : json["presentation_layer"],
  };
}

export function SubmitPromptResponseRequestToJSON(
  value?: SubmitPromptResponseRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    prompt: value.prompt,
    response: value.response,
    questionnaire: value.questionnaire,
    publish: value.publish,
    presentation_layer: value.presentation_layer,
  };
}
