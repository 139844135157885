import React, { useEffect, useState } from "react";
import { CommonPromptTypeScreenProps } from "./PromptRespondScreen";
import EitherOrQuestion from "./questions/EitherOrQuestion";
import CommonQuestionScreen from "./CommonQuestionScreen";
import { useBusyWatcher } from "../../../util/hooks";

type EitherOrScreenProps = CommonPromptTypeScreenProps & {
  options: [string, string][];
  selected?: string[] | null;
  onNextClicked: (selected: string[], visible: boolean | null) => void;
};

const EitherOrScreenComponent = (props: EitherOrScreenProps) => {
  const {
    title,
    publishable,
    visible: visibleInput,
    options,
    selected: selectedInput,
    onNextClicked,
    ...rest
  } = props;

  const [selected, setSelected] = useState<string[]>([]);
  const [visible, setVisible] = useState<boolean>(visibleInput ?? true);
  const [busy, _] = useBusyWatcher();

  const canSubmit = (): boolean => selected.length > 0;

  const submit = () => {
    onNextClicked(selected, visible);
  };

  const updatePromptResponseVisibility = () => {
    if (rest.prompt.response) {
      onNextClicked(selected, !visible);
    }
    setVisible(!visible); // This needs to be set after the call because setState is async
  };

  useEffect(() => {
    setSelected(selectedInput ?? []);
  }, [selectedInput]);

  return (
    <CommonQuestionScreen {...rest} canSubmit={canSubmit()} submit={submit}>
      <EitherOrQuestion
        comment="pick 1 from each pair below"
        question={title}
        options={options}
        showVisibilityToggle
        onUpdated={setSelected}
        selected={selected}
        onVisibilityUpdated={updatePromptResponseVisibility}
        publishable={publishable}
        disabled={busy}
        visible={visible}
      />
    </CommonQuestionScreen>
  );
};

EitherOrScreenComponent.defaultProps = {
  selected: null,
};

export default EitherOrScreenComponent;
