import React from "react";
import { classNames } from "../../util/strings";

type MErrors = {
  errors?: string[];
};

const MErrorsComponent = (props: MErrors) => {
  const { errors } = props;

  return (
    <div
      className={classNames(
        "flex flex-col gap-1",
        !errors || errors.length === 0 ? "hidden" : null
      )}
    >
      {errors &&
        errors.length > 0 &&
        errors.map((e) => (
          <div className="text-danger text-left text-xs" key={e}>
            {e}
          </div>
        ))}
    </div>
  );
};

MErrorsComponent.defaultProps = {
  errors: [],
};

export default MErrorsComponent;
