import React from "react";
import {
  ArrowTopRightOnSquareIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { LinkSummary } from "../../../../api/models";
import MWell from "../../../ui/wells/MWell";
import { clickableText } from "../../../../util/style";
import MTooltip from "../../../ui/MTooltip";
import { useUniqueId } from "../../../../util/hooks";
import { classNames } from "../../../../util/strings";
import VisibilityFooter from "../../../ui/cards/VisibilityFooter";

type LinkSummaryRowProps = {
  link: LinkSummary;
  onEditClicked?: (() => void) | null;
  onDeleteClicked?: (() => void) | null;
  onToggleVisibilityClicked?: (() => void) | null;
  toggleVisibilityDisabled?: boolean;
  isVisible?: boolean;
};

const LinkSummaryRowComponent = (props: LinkSummaryRowProps) => {
  const {
    link,
    onEditClicked,
    onDeleteClicked,
    onToggleVisibilityClicked,
    ...rest
  } = props;
  const tooltipId = useUniqueId();

  return (
    <MWell
      footer={
        onToggleVisibilityClicked && (
          <VisibilityFooter
            onToggleVisibilityClicked={onToggleVisibilityClicked}
            visibilityCopy="show on glimpse"
            {...rest}
          />
        )
      }
    >
      <div className="flex flex-row text-m-dark-gray items-center gap-3">
        <div className="grow truncate">
          <i className={classNames(link.fa_icon!, "mr-2")} />
          {link.title}
        </div>
        <a
          className={clickableText}
          href={link.url}
          target="_blank"
          rel="noreferrer"
          aria-label="Open Page"
        >
          <ArrowTopRightOnSquareIcon
            className="h-6 w-5 flex-none"
            aria-hidden="true"
          />
        </a>
        {onEditClicked && (
          <button
            className={clickableText}
            type="button"
            data-tooltip-id={tooltipId}
            data-tooltip-content="edit"
            onClick={onEditClicked}
            aria-label="Edit Link"
          >
            <PencilSquareIcon
              className="h-6 w-5 flex-none"
              aria-hidden="true"
            />
          </button>
        )}
        {onDeleteClicked && (
          <button
            type="button"
            className={clickableText}
            data-tooltip-id={tooltipId}
            data-tooltip-content="delete"
            onClick={onDeleteClicked}
            aria-label="Delete Link"
          >
            <TrashIcon className="h-6 w-5 flex-none" aria-hidden="true" />
          </button>
        )}
      </div>
      <MTooltip id={tooltipId} place="top" />
    </MWell>
  );
};

LinkSummaryRowComponent.defaultProps = {
  onEditClicked: null,
  onDeleteClicked: null,
  onToggleVisibilityClicked: null,
  toggleVisibilityDisabled: false,
  isVisible: true,
};

export default LinkSummaryRowComponent;
