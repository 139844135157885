/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  LinkSummary,
  LinkSummaryFromJSON,
  LinkSummaryFromJSONTyped,
  LinkSummaryToJSON,
} from "./LinkSummary";

/**
 *
 * @export
 * @interface GetUserLinksResponse
 */
export interface GetUserLinksResponse {
  /**
   *
   * @type {Array<LinkSummary>}
   * @memberof GetUserLinksResponse
   */
  links: Array<LinkSummary>;
}

export function GetUserLinksResponseFromJSON(json: any): GetUserLinksResponse {
  return GetUserLinksResponseFromJSONTyped(json, false);
}

export function GetUserLinksResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetUserLinksResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    links: (json["links"] as Array<any>).map(LinkSummaryFromJSON),
  };
}

export function GetUserLinksResponseToJSON(
  value?: GetUserLinksResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    links: (value.links as Array<any>).map(LinkSummaryToJSON),
  };
}
