/* tslint:disable */
/* eslint-disable */
/**
 * Service API
 * Service description
 *
 * The version of the OpenAPI document: v0.1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SetProfileAccessRequest
 */
export interface SetProfileAccessRequest {
  /**
   *
   * @type {string}
   * @memberof SetProfileAccessRequest
   */
  user?: string | null;
  /**
   *
   * @type {string}
   * @memberof SetProfileAccessRequest
   */
  email?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SetProfileAccessRequest
   */
  is_allowed: boolean;
}

export function SetProfileAccessRequestFromJSON(
  json: any
): SetProfileAccessRequest {
  return SetProfileAccessRequestFromJSONTyped(json, false);
}

export function SetProfileAccessRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SetProfileAccessRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    user: !exists(json, "user") ? undefined : json["user"],
    email: !exists(json, "email") ? undefined : json["email"],
    is_allowed: json["is_allowed"],
  };
}

export function SetProfileAccessRequestToJSON(
  value?: SetProfileAccessRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    user: value.user,
    email: value.email,
    is_allowed: value.is_allowed,
  };
}
